import request from 'superagent';
import { submitObjectChildMapping } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';
import { toast } from 'react-hot-toast';
import Success from '../modules/notify/Success';
import Delete from '../modules/notify/Delete';

const submitChildObjectMapping = async ({ payload, query }) => {
  const res = await request
    .put(submitObjectChildMapping)
    .send(payload)
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${keycloak.token()}`)
    .query(query);

  if (res.body.status.code == 'SUCCESS') {
    toast.custom((t) => <Success t={t} message={'Object Mapping is Submittted SuccessFully'} />, {
      duration: 4000
    });
    console.log('Form Submit successfully');
  } else {
    toast.custom((t) => <Delete t={t} message={'Object Mapping is Submission Failed'} />, {
      duration: 4000
    });
    console.log(res.body.status.message);
  }

  return res;
};

export default submitChildObjectMapping;
