import { noop } from 'lodash';
import FormContainer from '../../common/form/container';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import formKeys from '../../common/form/constants/formKeys';
import {
  clearFormState,
  updateFormData,
  updateFormError,
  updateFormState
} from '../../common/form/slices/formSlice';
import userFormSchema from '../../../schema/form_schema/userFormSchema';
import fetchUserList from '../../../services/fetchUserList';
import addNewUser from '../../../services/addNewUser';

const UserFormContainer = ({ onCloseModal = noop, isModal=false, modalKey='' }) => {
  const mutation = useMutation(addNewUser);
  const app_code = useSelector((store) => store.store?.application?.addUserData?.code);

  const { data: userList } = useQuery(
    ['userList'],
    async () => {
      const fetchedData = await fetchUserList(app_code);
      return fetchedData;
    },
    {
      refetchOnWindowFocus: false
    }
  );
  const userListArray = userList?.body?.primary;

  const schema = userFormSchema(userListArray, app_code);

  const dispatch = useDispatch();

  const formStore = useSelector((store) => store.store.form[formKeys.addUserForm]) || {};

  const formData = formStore.formData || {};

  const formError = formStore.errorState || {};

  const formState = formStore.formState || 'stale';

  const handleChange = (event) => {
    if (formData.formState !== 'stale') {
      dispatch(
        updateFormState({
          formKey: formKeys.addUserForm,
          formState: 'stale'
        })
      );
    }

    const { name, value } = event.target;
    const fieldValue = value;
    dispatch(
      updateFormData({
        formKey: formKeys.addUserForm,
        formField: name,
        formData: fieldValue
      })
    );
  };

  const getEmail = (userName) => {
    const user = userListArray.find((item) => item.name === userName);
    return user ? user.email : null;
  };

  const getAppCode = (userName) => {
    const user = userListArray.find((item) => item.name === userName);
    return user ? user.app_code : null;
  };

  const submitForm = async (formData) => {
    const payload = {
      username: formData['user name'].value,
      email: getEmail(formData['user name'].value),
      firstName: formData['user name'].value,
      roles: formData.role.value,
      app_code: getAppCode(formData['user name'].value)
    };

    try {
      dispatch(
        updateFormState({
          formKey: formKeys.addUserForm,
          formState: 'pending'
        })
      );
      const response = await mutation.mutateAsync(payload);
      if (response.body.status.code == 'Success') {
        dispatch(
          updateFormState({
            formKey: formKeys.addUserForm,
            formState: 'success'
          })
        );
        onCloseModal();
        dispatch(
          clearFormState({
            formKey: formKeys.addUserForm
          })
        );
      } else {
        dispatch(
          updateFormState({
            formKey: formKeys.addUserForm,
            formState: 'failed'
          })
        );
        dispatch(
          updateFormError({
            formKey: formKeys.addUserForm,
            errorMessage: response.body.status.message
          })
        );
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (
    <FormContainer
      handleChange={handleChange}
      modalKey={modalKey}
      isModal={isModal}
      submitForm={submitForm}
      schema={schema}
      formData={formData}
      formError={formError}
      formKey={formKeys.editApplicationForm}
      formState={formState}
    />
  );
};

export default UserFormContainer;
