import request from 'superagent';
import keycloak from '../Keycloak';

async function fetchAllStateObjectsData(app_code) {
  const reviewedData = async () =>
    await request
      .get(`${process.env.REACT_APP_CONFIG_API}/data-objects/review`)
      .query({ appCode: app_code, flag: 'Review' })
      .set('Authorization', `Bearer ${keycloak.token()}`);
  const rejectedData = async () =>
    await request
      .get(`${process.env.REACT_APP_CONFIG_API}/data-objects/status`)
      .query({ appCode: app_code, flag: 'Rejected' })
      .set('Authorization', `Bearer ${keycloak.token()}`);
  const approvedData = async () =>
    await request
      .get(`${process.env.REACT_APP_CONFIG_API}/data-objects/status`)
      .query({ appCode: app_code, flag: 'Approved' })
      .set('Authorization', `Bearer ${keycloak.token()}`);

  console.log(reviewedData, rejectedData, approvedData, 'yyyyyyyyyyyyy');

  return Promise.all([reviewedData(), rejectedData(), approvedData()]);
  return new Promise((resolve, reject) => {
    return resolve({
      review: [1, 3, 4],
      rejected: [4, 5, 6],
      approved: [5, 6, 7, 3, 8]
    });
  });
}

export default fetchAllStateObjectsData;
