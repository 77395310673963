const featureRoleMappingSchema = (userList = [], app_code) => {
  const userListData = (userList) => {
    const data = [];
    userList.forEach((item) => {
      data.push({
        name: item.name,
        value: item.name
      });
    });
    return data;
  };
  const userListOptions = userListData(userList);
  return {
    title: 'User Form',
    formSchema: [
      {
        name: 'user name',
        label: 'User Name',
        type: 'dropdown',
        options: userListOptions,
        required: false
      },
      {
        name: 'role',
        label: 'Role',
        type: 'multiselect-dropdown',
        options: [],
        required: false
      },
      {
        name: 'app code',
        label: 'App code',
        type: 'text',
        required: true,
        defaultValue: app_code,
        isFormDisabled: true
      }
    ]
  };
};

export default featureRoleMappingSchema;
