import classNames from 'classnames';
import { noop } from 'lodash';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConformationContainer from '../../common/conformationModal/containers';
import {
  applicationTilesData,
  connectionFrameworkData,
  reviewFrameworkData
} from '../constants/applicationTilesData';

function getCurrentContextData({ context }) {
  switch (context) {
    case 'review':
      return reviewFrameworkData;
    case 'connection':
      return connectionFrameworkData;
    default:
      return applicationTilesData;
  }
}

function getIfSubheader({ currentContext }) {
  switch (currentContext) {
    case 'review':
    case 'connection':
      return true;
    default:
      return false;
  }
}

const ApplicationTiles = ({ changeContext, ApplicationCountData, context = '' }) => {
  const navigate = useNavigate();
  const { app_code = '' } = useParams();
  ApplicationCountData = ApplicationCountData ? ApplicationCountData[0] : {};

  const applicationNameToCountMapping = {
    FEATURES: ApplicationCountData.featuresCount,
    'OBJECT MAPPING': ApplicationCountData.objectMappingCount,
    ORCHESTRATION: ApplicationCountData.orchestrationCount,
    'DATA OBJECT': ApplicationCountData.objectsCount
  };
  function titleToCamel(titleCase) {
    const words = titleCase.toLowerCase().split(' ');
    const camelCase = [words[0]];

    for (let i = 1; i < words.length; i++) {
      camelCase.push(words[i][0].toUpperCase() + words[i].slice(1));
    }

    return camelCase.join('');
  }

  const extendTileNameUrl = {
    Objects: 'review',
    'Object Mapping': 'reviewObjectMapping'
  };

  const renameMapping = {
    'data object': 'objects',
    orchestration: 'orchestrations',
    'object mapping': 'object mappings',
    connections: 'Connections',
    review: 'Reviews',
    trace: 'Traces',
    'Audit Trails': 'Audit Trails',
    objects: 'Objects',
    objectMapping: 'Object Mapping'
  };

  const subHeader = {
    application: 'Applications',
    connectionFramework: 'Connection Framework'
  };

  const subHeaderClassName = (val) =>
    classNames('w-[50%] text-center py-4 px-2 rounded-[12px] cursor-pointer font-semibold', {
      'bg-white shadow-lg': val,
      '': !val
    });

  const navigateSubHeader = (code) => {
    switch (code) {
      case 'application': {
        navigate(`/apps/${app_code}/features`);
        break;
      }
      case 'connectionFramework': {
        navigate(`/apps/${app_code}/connections`);
        break;
      }
    }
  };

  const currentContextData = getCurrentContextData({ context });

  // className={subHeaderClassName(getIfSubheader({ currentContext: context }))}

  return (
    <div>
      <div className="pl-[28px] mb-[32px]">
        <div>
          <div className="font-bold my-[20px] text-black flex flex-col">
            <div className="text-start text-[36px]">{app_code}</div>
          </div>
        </div>
        <div className="flex mt-4 w-[400px] bg-[#E4E4E5] justify-between text-[24px] rounded-[12px] shadow-lg">
          <div
            className={subHeaderClassName(!getIfSubheader({ currentContext: context }))}
            onClick={() => {
              navigateSubHeader('application');
            }}>
            Application
          </div>
          <div
            className={subHeaderClassName(getIfSubheader({ currentContext: context }))}
            onClick={() => {
              navigateSubHeader('connectionFramework');
            }}>
            Extensions
          </div>
        </div>
      </div>
      <div className="flex justify-around items-center flex-row border-2 border-[#E6EDFF] h-[142px] rounded-[12px] ml-[28px] bg-white mr-[12px]">
        {currentContextData.map((tile) => {
          const tileNameInCamelCase = extendTileNameUrl[tile.name] || titleToCamel(tile.name);
          const name = tile.name.toLocaleLowerCase();
          return (
            <>
              <div
                onClick={() => {
                  changeContext(tile.name, () =>
                    navigate(`/apps/${app_code}/${tileNameInCamelCase}`)
                  );
                }}
                className="cursor-pointer w-[25%] h-[80%] border-r-2 border-[#E6EDFF] hover:shadow-lg transition-all delay-100">
                <div className="w-[210px] pl-[24px]">
                  <div className="flex">
                    {tile.noCount ? null : (
                      <div className="text-[28px] font-semibold">
                        {(applicationNameToCountMapping[tile.name] &&
                          applicationNameToCountMapping[tile.name]) ||
                          0}
                      </div>
                    )}
                    <div>
                      <img height={'52px'} width={'52px'} src={tile.image} alt="" srcset="" />
                    </div>
                  </div>
                  <div className="text-[20px] font-medium">
                    {(renameMapping[name] || name).toUpperCase()}
                  </div>
                </div>
              </div>
              <ConformationContainer
                modalId={tile.name}
                cancelObject={{ text: 'Cancel', callback: noop }}
                conformObject={{
                  text: 'Discard Changes',
                  callback: () => {
                    changeContext(
                      tile.name,
                      () => navigate(`/apps/${app_code}/${tileNameInCamelCase}`),
                      true
                    );
                  }
                }}
                modalText={{
                  header: 'Discard Changes',
                  desc: 'Your Data is Unsaved, do you really want to discard changes !'
                }}
              />
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ApplicationTiles;
