import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import logger from 'redux-logger';

const middlewares = [logger];

export default configureStore({
  reducer: {
    store: rootReducer
  },
  middleware: (previousMiddleware) => {
    return previousMiddleware().concat(middlewares);
  }
});
