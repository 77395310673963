import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import keycloak from '../Keycloak';
 
function PrivateRoute({ children }) {
  const navigate = useNavigate();
 
  useEffect(() => {
    const isAuthenticated = keycloak.getIfAuthenticated();
    const expireTime = sessionStorage.getItem("expiretokenTime");
 
    if (!isAuthenticated || !expireTime || Date.now() > parseInt(expireTime)) {
      sessionStorage.clear(); // Clear session storage
      alert("Your Session has Expired.Again LogIn")
      navigate('/'); // Redirect to login
    }
  }, [navigate]);
  return children;
}
 
// Using PropTypes to validate the types of the properties passed in
PrivateRoute.propTypes = {
  children: PropTypes.node
};
 
// Setting default props in case they aren't provided
PrivateRoute.defaultProps = {
  children: null
};
 
export default PrivateRoute;

// import PropTypes from 'prop-types';
// import { useNavigate } from 'react-router-dom';
// import keycloak from '../Keycloak';
// import { useEffect } from 'react';

// function PrivateRoute({ children }) {
//   const navigate = useNavigate();
//   const isLoggedIn = keycloak.getIfAuthenticated();
//   if (isLoggedIn) {
//     console.log("checking to logout....")
//     return children;
//   } else {
//     window.location = "/"
//   }
// }

// PrivateRoute.propTypes = {
//   children: PropTypes.node
// };

// PrivateRoute.defaultProps = {
//   children: null
// };

// export default PrivateRoute;
