import { element } from "prop-types";

const reverseMapping = {
  email: 'Mail',
  sftp: 'SFTP'
};

const connectionFrameworkBasicInfoSchema = ({
  partyIds = [],
  isPollingNeeded = true,
  basicInfoData = {},
  sourcePartner = [],
  actionId = false
}) => {
  // Map the party IDs and source partners to the required format
  const mappedPartyIds = partyIds.map((element) => {
    return { name: element.org_name, value: element.org_id };
  });

  const mappedSourcePartner = sourcePartner.map((element) => {
    console.log(basicInfoData,"basic info")
    return { name: element.org_name, value: element.org_id };
  });
  console.log(sourcePartner,"source partner")
  return {
    title: 'Connection Framework',
    hideTitle: true,
    formSchema: [
      {
        name: 'name',
        label: 'Name *',
        type: 'text',
        required: true,
        defaultValue: basicInfoData.name || '',
        disabled: !!actionId
      },
      {
        name: 'description',
        label: 'Description',
        type: 'text',
        required: true,
        defaultValue: basicInfoData.description || '',
        disabled: !!actionId
      },
      {
        name: 'mode',
        label: 'Mode *',
        type: 'dropdown',
        defaultValue: basicInfoData.mode || '',
        options: [
          { name: 'Inbound', value: 'Inbound' },
          { name: 'Outbound', value: 'Outbound' }
        ],
        disabled: !!actionId,
        required: true,
      },
      {
        name: 'orgId',
        label: 'Party Id *',
        type: 'dropdown',
        options: mappedPartyIds,
        defaultValue: basicInfoData.org_id || '',
        disabled: !!actionId
      },
      {
        name: 'sourcePartner',
        label: 'Source Partner *',
        type: 'dropdown',
        options: mappedSourcePartner,
        defaultValue: basicInfoData.source_partner_id || '',
        disabled: !!actionId
      },
      {
        name: 'type',
        label: 'Type *',
        type: 'dropdown',
        defaultValue:
          basicInfoData.type in reverseMapping
            ? reverseMapping[basicInfoData.type]
            : basicInfoData.type || '',
        options: [
          { name: 'Mail', value: 'Mail' },
          { name: 'SFTP', value: 'SFTP' },
          { name: 'DB', value: 'db' },
          { name: 'WebHooks', value: 'webhook' },
          { name: 'HTTP', value: 'http' },
          { name: 'Optical Character Recognition', value: 'ocr' }
        ],
        required: true,
        disabled: !!actionId
      },
      isPollingNeeded
        ? {
            defaultValue: basicInfoData.pollingInterval || 30,
            name: 'polling',
            label: 'Polling Interval (minutes) *',
            type: 'number',
            min: 30,
            max: 10000,
            required: true,
            disabled: !!actionId
          }
        : {}
    ]
  };
};

export default connectionFrameworkBasicInfoSchema;
