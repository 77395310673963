import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataObjectDataAction } from '../slices/applicationSlice';
import { useParams } from 'react-router-dom';
import fetchDataObjectData from '../../../services/fetchDataObjectData';
import DataObjectGrid from '../component/DataObjectGrid';

const DataObjectGridContainer = ({ deleteDataObjectClick }) => {
  const dispatch = useDispatch();
  const { app_code = '' } = useParams();
  const { refetch } = useQuery('data-object-data', fetchDataObjectData({ appCode: app_code }), {
    enabled: false
  });
  const storeObjectDataStore = useSelector((store) => store.store.moreApplication.dataObjectData);

  const fetchDataObjectDatas = async () => {
    const refetchData = await refetch();
    const { data, isSuccess } = refetchData;
    if (isSuccess) {
      dispatch(fetchDataObjectDataAction({ fetchDataObjectData: data?.body?.dataObjects }));
    }
    return data?.body?.dataObjectData;
  };

  return (
    <DataObjectGrid
      fetchDataObjectData={fetchDataObjectDatas}
      dataObjectData={storeObjectDataStore}
      deleteDataObjectClick={deleteDataObjectClick}
    />
  );
};

export default DataObjectGridContainer;
