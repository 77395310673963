import React from 'react';
import GenericModal from '../components/GenericModal';
import { useDispatch, useSelector } from 'react-redux';
import modalKeys from '../constants/GenericModalKeys';
import { genericModalStateUpdate } from '../../slices/genericModalSlice';

const GenericModalContainer = ({ children, modalKey, bgClr }) => {
  const isModalOpen = useSelector(
    (store) => store.store.genericModal.modalKeys[modalKeys[modalKey] || modalKey]
  );
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(
      genericModalStateUpdate({
        modalKey: modalKey,
        isOpen: false
      })
    );
  };

  return (
    <GenericModal closeModal={closeModal} isModalOpen={isModalOpen} bgClr={bgClr}>
      {children}
    </GenericModal>
  );
};

export default GenericModalContainer;
