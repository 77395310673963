import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fetchConnectionGridData from '../../../services/fetchConnectionGridData';
import { setConnectionFrameworkGridData } from '../slices/connectionFrameworkSlice';
import { useQuery } from 'react-query';
import ConnectionFrameworkGrid from '../components/ConnectionFrameworkGrid';

const ConnectionFrameworkGridContainer = () => {
  const dispatch = useDispatch();
  const { refetch } = useQuery('connection-framework-data', fetchConnectionGridData(), {
    enabled: false
  });
  const connectionFrameworkStoreData =
    useSelector((store) => store.store?.connectionFramework?.gridData) || [];

  const fetchConnectionFrameworkData = async () => {
    const refetchData = await refetch();
    const { data, isSuccess } = refetchData;

    if (isSuccess) {
      dispatch(setConnectionFrameworkGridData({ data: data?.body?.Result }));
    }
    return data?.body;
  };

  useEffect(() => {
    fetchConnectionFrameworkData();
  }, []);

  return (
    <ConnectionFrameworkGrid
      title="Connection Profile"
      fetchConnectionFrameworkData={fetchConnectionFrameworkData}
      connectionFrameworkData={connectionFrameworkStoreData}
    />
  );
};

export default ConnectionFrameworkGridContainer;
