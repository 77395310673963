import React, { useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { noop } from 'lodash';
import { CircularProgress } from '@mui/material';
import moment from 'moment';

const AuditLogObjectMappingGrid = ({
  objectMappingAuditData = [],
  fetchObjectMappingAuditData = noop,
  onDigitIdClick = noop,
  onObjectMappingClick = noop
}) => {
  const gridRef = useRef();
  const columnDefs = [
    {
      headerName: 'MAPPING ID',
      field: 'mappingId',
      cellRenderer: (params) => {
        return (
          <button
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            onClick={() => {
              cellClicked(params, 'mappingId');
            }}>
            {params?.data?.mappingId}
          </button>
        );
      }
    },
    { headerName: 'VERSION', field: 'version' },
    { headerName: 'CREATED DATE', field: 'createdDate' },
    { headerName: 'APROVED DATE', field: 'approvedDate' },
    { headerName: 'TRANSACTION ID', field: 'transactionId' },
    {
      headerName: 'DIGIT ID',
      field: 'digitId',
      cellRenderer: (params) => {
        return (
          <button
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            onClick={() => {
              cellClicked(params, 'digitId');
            }}>
            {/* {params?.data?.digitId} */}
            Verify
          </button>
        );
      }
    }
  ];

  const defaultColDef = {
    sortable: true,
    flex: 1,
    floatingFilter: true,
    filter: true,
    resizable: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function onShowLoading() {
    gridOptions.api.showLoadingOverlay();
  }

  function onHide() {
    gridOptions.api.hideOverlay();
  }

  let gridApi;

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };

  function convertToCustomFormat(dateTimeString) {
    const dateTime = moment(dateTimeString);

    const customFormat = dateTime.format('YYYY-MM-DD HH:mm:ss');

    return customFormat;
  }

  const rowData = objectMappingAuditData.map((auditData) => {
    const formattedCreatedDate = convertToCustomFormat(auditData?.record?.timeStamps?.createdAt);
    return {
      mappingId: auditData?.record?.id,
      version: auditData?.record?.auditInfo?.version,
      createdDate: formattedCreatedDate,
      transactionId: auditData?.record?.auditInfo?.uid,
      digitId: auditData?.hlfTrace?.digitId,
      auditId: auditData?.auditId
    };
  });

  async function onGridReady(params) {
    gridApi = params?.api;
    params?.api?.showLoadingOverlay();
    gridApi?.sizeColumnsToFit();
    params.columnApi.autoSizeAllColumns();
    const fetchData = await fetchObjectMappingAuditData();
  }
  function cellClicked(params, columnName) {
    const { data } = params;
    switch (columnName) {
      case 'digitId': {
        onDigitIdClick(data);
        break;
      }
      case 'mappingId': {
        onObjectMappingClick(data);
        break;
      }
    }
  }

  return (
    <div className="ag-theme-alpine h-[100%] px-[24px] mt-[50px] p-[20px] m-[20px] border-2 border-[#E6EDFF] rounded-[10px] !bg-white flex flex-col">
      <div className="flex-1">
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          onCellClicked={cellClicked}
          rowData={rowData}
        />
      </div>
    </div>
  );
};

export default AuditLogObjectMappingGrid;
