import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataObjectValidationModal from '../component/DataObjectValidationModal';
import { updateField } from '../../common/form/slices/formSlice';
import { noop } from 'lodash';
import { useParams } from 'react-router-dom';

const DataObjectValidationModalContainer = ({ onCloseModal = noop, editDataObjectsData = {} }) => {
  const dispatch = useDispatch();
  const { action = '' } = useParams();

  const modalStore = useSelector((store) => store?.store?.genericModal?.modalKeys);
  const { propertyIndex } = modalStore;
  const formStore = useSelector((store) => store?.store?.form?.formData);
  var dataObjectValue;
  var arrayCheck;
  switch (action) {
    case 'edit': {
      dataObjectValue = formStore?.['Edit Object']?.[propertyIndex]?.['Data Type'];
      break;
    }
    case 'view': {
      dataObjectValue = formStore?.[editDataObjectsData?.name]?.[propertyIndex]?.['Data Type'];
      break;
    }
    case 'duplicate': {
      dataObjectValue = formStore?.['Duplicate Data Object']?.[propertyIndex]?.['Data Type'];
      break;
    }
    default: {
      dataObjectValue = formStore?.['New Object']?.[propertyIndex]?.['Data Type'];
      arrayCheck = formStore?.['New Object']?.[propertyIndex]?.['isArray'];
      break;
    }
  }

  var validationList = [];
  var errorMessage = '';

  switch (dataObjectValue) {
    case 'DOI000001': {
      validationList.push(
        'minimum',
        'exclusiveMinimum',
        'maximum',
        'exclusiveMaximum',
        'multipleOf'
      );
      if (arrayCheck == 'YES') {
        validationList.push('minItems', 'maxItems');
      }
      break;
    }
    case 'DOI000002': {
      validationList.push('minLength', 'maxLength', 'pattern');
      if (arrayCheck == 'YES') {
        validationList.push('minItems', 'maxItems');
      }
      break;
    }
    default: {
      errorMessage = 'Please Select Data type as Number or String to add Validation Rules';
      break;
    }
  }

  const [items, setItems] = useState(
    validationList.map((text) => ({
      text,
      inputText: formStore?.[`validationRule-${propertyIndex}`]?.[text]?.value || '',
      showTextBox: formStore?.[`validationRule-${propertyIndex}`]?.[text]?.checked || false
    }))
  );

  useEffect(() => {
    validationList.map((rule) => {
      dispatch(
        updateField({
          path: [`validationRule-${propertyIndex}`, `${rule}`],
          value: {
            checked: formStore?.[`validationRule-${propertyIndex}`]?.[rule]?.checked || false,
            value: formStore?.[`validationRule-${propertyIndex}`]?.[rule]?.value || ''
          }
        })
      );
    });
  }, [JSON.stringify(validationList)]);

  const handleCheckboxChange = (index) => {
    const updatedItems = [...items];
    updatedItems[index].showTextBox = !updatedItems[index].showTextBox;
    setItems(updatedItems);
    dispatch(
      updateField({
        path: [`validationRule-${propertyIndex}`, `${updatedItems[index].text}`],
        value: {
          checked: updatedItems[index].showTextBox || false,
          value: updatedItems[index].inputText || ''
        }
      })
    );
  };

  const handleTextBoxChange = (index, value) => {
    const updatedItems = [...items];
    updatedItems[index].inputText = value;
    setItems(updatedItems);
    dispatch(
      updateField({
        path: [`validationRule-${propertyIndex}`, `${updatedItems[index].text}`],
        value: {
          checked: updatedItems[index].showTextBox || false,
          value: value
        }
      })
    );
  };

  return (
    <DataObjectValidationModal
      validationList={items}
      errorMessage={errorMessage}
      handleCheckboxChange={handleCheckboxChange}
      handleTextBoxChange={handleTextBoxChange}
      onCloseModal={onCloseModal}
    />
  );
};

export default DataObjectValidationModalContainer;
