import React, { useEffect, useState } from 'react';
import DurationPieChart from './durationPieChart.component';
import request from 'superagent';
import DurationPieChartGrid from './durationGrid.component';

const DurationComponent = ({app_code}) => {
  const [donutChartData, setDonutChartData] = useState();
  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    (async function () {
      const responseData = await request
        .post(
          `${process.env.REACT_APP_GENRIC_API}/generic/feature/chart/featureTraceCharts`
        )
        .send({app_code : app_code});
      setDonutChartData(responseData?.body?.response?.response);
    })();
  }, []);

  const headerMappingData = {
    response_codes: 'Response Codes',
    responseTime: 'Response Time'
  };

  return (
    <>
      <div className="flex pt-[100px] w-full">
        {donutChartData?.map((chartData) => {
          return (
            <div className="w-[50%] border-r-2 last:border-none border-black border-solid">
              <div className="text-[24px] pl-[24px] mb-4">
                {headerMappingData[chartData?.chartType]}
              </div>
              <DurationPieChart
                type={chartData?.chartType}
                chartData={chartData}
                setGridData={setGridData}
                app_code = {app_code}
              />
            </div>
          );
        })}
      </div>
      {gridData.length ? <DurationPieChartGrid gridData={gridData} /> : null}
    </>
  );
};

export default DurationComponent;
