import request from 'superagent';
import {  fetchDataObject } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const fetchDataObjectData = ({ appCode = '' }) => {
  return async () => {
    const res = await request
      .get(fetchDataObject)
      .query({ appCode: appCode })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

export default fetchDataObjectData;
