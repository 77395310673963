import { noop } from 'lodash';
import React, { useMemo, useState } from 'react';
import FormV2Container from '../../common/formV2/container/FormV2Container';
import { useDispatch, useSelector } from 'react-redux';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';
import { Tooltip } from '@mui/material';
import { Info, InfoTwoTone } from '@material-ui/icons';
import { clearFormState } from '../../common/form/slices/formSlice';

const VariablesForm = ({ fetchVariableGridData }) => {
  const dispatch = useDispatch();
  const [formKey, setFormKey] = useState(0);
  const initialSchema = [
    {
      name: 'name',
      label: 'Variable Name',
      type: 'text',
      defaultValue: '',
      classname: '!inline-block',
      startAdornment: '$',
      info: 'Validations : - Cannot start with Number , No Special Characters are allowed except _(underscore) and No spaces are allowed'
    },
    {
      name: 'value',
      label: 'Value',
      type: 'text',
      defaultValue: '',
      classname: '!inline-block',
      options: [true, false]
    },
    {
      name: 'static',
      label: 'Is Static',
      type: 'switch',
      defaultValue: false,
      classname: '!inline-block !relative !top-2'
    },
    {
      name: 'type',
      label: 'Data Type',
      type: 'dropdown',
      classname: '!inline-block',
      defaultValue: '',
      options: [
        { name: 'INTEGER', value: 'INTEGER' },
        { name: 'STRING', value: 'STRING' },
        { name: 'DOUBLE', value: 'DOUBLE' }
      ]
    }
  ];

  const { app_code } = useParams();
  const formStore = useSelector((store) => store.store.form) || {};

  const formData = formStore?.formData?.['Variables'] || {};

  const { name, static: staticData, value, type } = formData;

  const [formSchema, setFormSchema] = useState(initialSchema);

  const onFormSubmit = async () => {
    const payload = {
      name: '$' + name,
      static: staticData,
      value,
      type
    };

    const response = await request
      .post(
        `${process.env.REACT_APP_CONFIG_API}/type/connectors/globalvariables/add?appCode=${app_code}`
      )
      .send(payload)
      .set('Authorization', `Bearer ${keycloak.token()}`);

    if (response.body.status.code == 'SUCCESS') {
      toast.custom((t) => <Success t={t} message={response.body.status.message} />, {
        duration: 4000
      });
      fetchVariableGridData();
      setFormSchema(initialSchema);
      dispatch(
        clearFormState({
          formKey: 'Variables'
        })
      );
      setFormKey(Math.random(1) * 10);
    } else {
      toast.custom((t) => <ErrorNotify t={t} message={response.body.status.message} />, {
        duration: 4000
      });
    }
  };

  const isAddUser = useMemo(() => {
    // ^        // Match at the beginning
    // [_a-z]   // Match either "_", or "a-z" at the beginning
    // \\w*     // Match zero or more of characters - [a-zA-Z0-9_], after the beginning
    // $        // Till the end
    const reg = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
    return !reg.test(name) || !name || !type;
  }, [name, type]);

  return (
    <div className="bg-white m-8 rounded-md">
      <div className="pl-[48px] py-4 text-3xl rounded-t-md bg-[#00306A] font-semibold text-white">
        Variables
      </div>
      <FormV2Container
        key={formKey}
        submitButtonName="Add Variable"
        onSubmitForm={onFormSubmit}
        disabledClick={isAddUser}
        className="!p-4"
        submitButtonClassName="!inline-block !ml-8 !w-auto"
        schema={{
          title: 'Variables',
          hideTitle: true,
          formSchema: formSchema
        }}
      />
    </div>
  );
};

export default VariablesForm;
