
function onSideNavigationClick(componentData, navigate) {
  switch (componentData.value) {
    case 'users': {
      navigate('/users');
      break;
    }
    case 'newUser': {
      navigate('/users/new');
      break;
    }
  }
}

function getUserNavigationSchema({ users = false, newUser = false } = {}) {
  return [
    {
      name: 'Users',
      value: 'users',
      onClick: onSideNavigationClick,
      isSelected: users,
      isDisabled: false,
      imageUrl: 'https://img.icons8.com/ios-glyphs/100/group.png'
    },
    {
      name: 'New User',
      value: 'newUser',
      onClick: onSideNavigationClick,
      isSelected: newUser,
      isDisabled: false,
      imageUrl: 'https://img.icons8.com/ios/100/new--v1.png'
    }
  ];
}

export default getUserNavigationSchema;
