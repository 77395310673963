import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Button,
  Paper,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  AppBar,
  Toolbar
} from '@mui/material';
import React from 'react';
import { useForm, SubmitHandler, Controller, useFieldArray } from 'react-hook-form';
import OrganisationCreation from './CreateOrganisation';
import CreateSubOrganisation from './CreateSubOrganisation';
import CreateUser from './CreateUser';
import CreateParties from './CreateParties';

 export default function OrgTenet() {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      Organisation: [
        {
          organisationCode: '',
          organisationType: '',
          organisationID: '',
          cin: '',
          gstin: '',
          state: '',
          district: '',
          area: '',
          address: '',
          designation: '',
          mobileNumber: '',
          email: '',
          realm: '',
          image: ''
        }
      ],
      partyType: [{ partType: '' }]
    }
  });

  const { fields: data } = useFieldArray({
    control,
    name: 'Organisation'
  });
  const {
    fields: types,
    append: appendPartType,
    remove: removePartType
  } = useFieldArray({
    control,
    name: 'partyType'
  });
  const [value, setvalue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setvalue(newValue);
  };
  return (
    // <Box>
    //     <AppBar>
    //         <Toolbar sx={{backgroundColor:'#e4e3ff'}}>
    //             <Typography  sx={{color:'black',fontWeight:'bold'}}>New Organisation</Typography>
    //             </Toolbar>
    //     </AppBar>
    // <form style={{marginTop:70}}>
    //   <Paper elevation={3} sx={{ py: 3, px: 3 }}>
    //     {data.map((field, index) => (
    //       <Box key={field.id} sx={{ margin: 2 }}>
    //         <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
    //           {/* <Typography>Organisation Info :</Typography> */}
    //           <TextField
    //             label="Organisation Code"
    //             variant="outlined"
    //             key={index}
    //             {...register(`Organisation.${index}.organisationCode`)}
    //             size="small"
    //             sx={{ marginRight: 2 }}
    //           />

    //           <TextField
    //             label="Organisation Type"
    //             variant="outlined"
    //             {...register(`Organisation.${index}.organisationType`)}
    //             size="small"
    //             sx={{ marginRight: 2 }}
    //           />
    //           <TextField
    //             label="Organisation ID"
    //             variant="outlined"
    //             {...register(`Organisation.${index}.organisationID`)}
    //             size="small"
    //             sx={{ marginRight: 2 }}
    //           />
    //            <Box sx={{width:223}}/>
    //         </Box>
    //         <Box
    //           sx={{
    //             display: 'flex',
    //             flexDirection: 'row',
    //             justifyContent: 'space-between',
    //             mb: 4,
    //             mt: 4
    //           }}>
    //           <TextField
    //             label="CID"
    //             variant="outlined"
    //             {...register(`Organisation.${index}.cid`)}
    //             size="small"
    //           />
    //           <TextField
    //             label="GSTIN"
    //             variant="outlined"
    //             {...register(`Organisation.${index}.gstin`)}
    //             size="small"
    //           />
    //           <FormControl>
    //             <InputLabel id="demo-simple-select-label">State</InputLabel>
    //             <Select
    //               labelId="demo-simple-select-label"
    //               id="demo-simple-select"
    //               {...register(`Organisation.${index}.state`)}
    //               //   value={age}
    //               size="small"
    //               label="District"
    //               sx={{ width: 223 }}
    //               //   onChange={handleChange}>
    //             >
    //               <MenuItem value={10}>Andhra Pradesh</MenuItem>
    //               <MenuItem value={20}>Telangana</MenuItem>
    //               <MenuItem value={30}>Tamilnadu</MenuItem>
    //             </Select>
    //           </FormControl>
    //           <FormControl>
    //             <InputLabel id="demo-simple-select-label">District</InputLabel>
    //             <Select
    //               labelId="demo-simple-select-label"
    //               id="demo-simple-select"
    //               {...register(`Organisation.${index}.district`)}
    //               //   value={age}
    //               label="District"
    //               size="small"
    //               sx={{ width: 223 }}
    //               //   onChange={handleChange}>
    //             >
    //               <MenuItem value={10}>Prakasham</MenuItem>
    //               <MenuItem value={20}>Medak</MenuItem>
    //               <MenuItem value={30}>Kadapa</MenuItem>
    //             </Select>
    //           </FormControl>
    //         </Box>
    //         <Box
    //           sx={{
    //             display: 'flex',
    //             flexDirection: 'row',
    //             justifyContent: 'space-between',
    //             mb: 4,
    //             mt: 4
    //           }}>
    //           {/* <TextField
    //           label="STATE"
    //           variant="outlined"
    //           {...register(`Organisation.${index}.state`)}
    //           size="small"
    //           sx={{ marginRight: 2 }}
    //         /> */}

    //           {/* <TextField
    //           label="DISTRICT"
    //           variant="outlined"
    //           {...register(`Organisation.${index}.district`)}
    //           size="small"
    //           sx={{ marginRight: 2 }}
    //         /> */}
    //           <TextField
    //             label="AREA"
    //             variant="outlined"
    //             {...register(`Organisation.${index}.area`)}
    //             size="small"
    //             //   sx={{ marginRight: 2 }}
    //           />
    //           <TextField
    //             label="Address"
    //             variant="outlined"
    //             {...register(`Organisation.${index}.address`)}
    //             size="small"
    //             // sx={{ marginRight: 2 }}
    //           />
    //           <TextField
    //             label="Designatin"
    //             variant="outlined"
    //             {...register(`Organisation.${index}.designation`)}
    //             size="small"
    //             // sx={{ marginRight: 2 }}
    //           />
    //           <TextField
    //             label="Mobile Number"
    //             variant="outlined"
    //             {...register(`Organisation.${index}.mobileNumber`)}
    //             size="small"
    //             // sx={{ marginRight: 2 }}
    //           />
    //         </Box>
    //         <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
    //           <TextField
    //             label="Email"
    //             variant="outlined"
    //             {...register(`Organisation.${index}.email`)}
    //             size="small"
    //             sx={{ marginRight: 2 }}
    //           />
    //           <TextField
    //             label="Realm"
    //             variant="outlined"
    //             {...register(`Organisation.${index}.realm`)}
    //             size="small"
    //             sx={{ marginRight: 2 }}
    //           />
    //           <TextField
    //             label="Image"
    //             variant="outlined"
    //             {...register(`Organisation.${index}.image`)}
    //             size="small"
    //             sx={{ marginRight: 2 }}
    //           />
    //            <Box sx={{width:223}}/>
    //             {/* label="Email"
    //             variant="outlined"
    //             {...register(`Organisation.${index}.email`)}
    //             size="small"
    //             sx={{ display:'' }}
    //           /> */}
    //         </Box>
    //       </Box>
    //     ))}
    //   </Paper>
    //   <div>
    //     <Paper elevation={3} sx={{ py: 3, px: 3 }}>
    //       {types.map((field, index) => (
    //         <Box key={index} sx={{ margin: 2 }}>
    //           <TextField
    //             label="Part Type"
    //             variant="outlined"
    //             {...register(`partyType.${index}.partyType`)}
    //             size="small"
    //             sx={{ marginRight: 2 }}
    //           />

    //           <Button variant="contained" onClick={() => appendPartType({ partType: '' })}>
    //             ADD
    //           </Button>
    //           {/* <Button variant="contained" onClick={() => removePartType({artType: ''})}>
    //             Remove
    //           </Button> */}
    //         </Box>
    //       ))}
    //     </Paper>
    //   </div>

    //   <Button type="submit">Save</Button>
    // </form>
    // </Box>
    <TabContext value={value}>
  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
  <AppBar position="static">
    <TabList onChange={handleChange} aria-label="lab API tabs example"  indicatorColor="secondary"  textColor="white">
      <Tab label=" Organisation" value="1" />
      <Tab label="Partner Type" value="4" />
      <Tab label=" Partner" value="2" />
      <Tab label=" User" value="3" />
    </TabList>
    </AppBar>
  </Box>
  <TabPanel value="1">
    <OrganisationCreation/>
  </TabPanel>
  <TabPanel value="4"><CreateParties/></TabPanel>
  <TabPanel value="2"><CreateSubOrganisation/></TabPanel>
  <TabPanel value="3"><CreateUser/></TabPanel>

</TabContext>
  );
}
