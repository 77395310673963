import React, { useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { noop } from 'lodash';
import LinkIcon from '@mui/icons-material/Link';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import MenuContainer from '../../common/menu/containers/MenuContainer';
import { toast } from 'react-hot-toast';
import Success from '../../../modules/notify/Success';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';

const AuditLogTransformationGrid = ({
  transformationAuditData = [],
  fetchTransformationAuditData = noop,
  onDigitIdClick = noop,
  onSourceObjectClick = noop,
  onTransformedObjectClick=noop
}) => {
  const gridRef = useRef();
  const columnDefs = [
    {
      headerName: 'SOURCE OBJECT NAME',
      field: 'sourceObjectName',
      cellRenderer: (params) => {
        return (
          <button
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            onClick={() => {
              cellClicked(params, 'sourceObject');
            }}>
            {params?.data?.sourceObjectName}
          </button>
        );
      }
    },
    {
      headerName: 'TRANSFORMED OBJECT NAME',
      field: 'transformedObjectName',
      cellRenderer: (params) => {
        return (
          <button
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            onClick={() => {
              cellClicked(params, 'transformedObject');
            }}>
            {params?.data?.transformedObjectName}
          </button>
        );
      }
    },
    { headerName: 'TRANSACTION DATE', field: 'transactionDate' },
    { headerName: 'TRANSACTION ID', field: 'transactionId' },
    {
      headerName: 'DIGIT ID',
      field: 'digitId',
      cellRenderer: (params) => {
        return (
          <button
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            onClick={() => {
              cellClicked(params, 'digitId');
            }}>
            {/* {params?.data?.digitId} */}
            Verify
          </button>
        );
      }
    },
    { headerName: 'MAPPING VERSION USED', field: 'mappingVersion' }
  ];

  const defaultColDef = {
    sortable: true,
    flex: 1,
    floatingFilter: true,
    filter: true,
    resizable: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  let gridApi;

  const CustomLoader = CircularProgress;

  const gridOptions = {
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };

  function convertToCustomFormat(dateTimeString = '') {
    const dateTime = moment(dateTimeString);

    const customFormat = dateTime.format('YYYY-MM-DD HH:mm:ss');

    return customFormat;
  }

  const rowData = transformationAuditData.map((auditData) => {
    const formattedCreatedDate = convertToCustomFormat(auditData?.createdAt);
    return {
      sourceObjectName: 'Source Object',
      transformedObjectName: 'Transform Object',
      transactionDate: formattedCreatedDate,
      transactionId: auditData?.uid,
      digitId: auditData?.hlfTrace?.digitId,
      mappingVersion: auditData?.record?.mappingVersion,
      auditId: auditData?.auditId
    };
  });

  // const autoSizeAll = useCallback((skipHeader) => {
  //   const allColumnIds = [];
  //   gridRef.current.columnApi.getColumns().forEach((column) => {
  //     allColumnIds.push(column.getId());
  //   });
  //   gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  // }, []);

  async function onGridReady(params) {
    gridApi = params?.api;
    params?.api?.showLoadingOverlay();
    gridApi?.sizeColumnsToFit();
    params.columnApi.autoSizeAllColumns();
    const fetchData = await fetchTransformationAuditData();
    // autoSizeAll(false);
  }

  const onExportClick = () => {
    gridApi = params?.api;
    gridApi?.exportDataAsCsv();
  };

  function cellClicked(params, columnName) {
    const { data } = params;
    switch (columnName) {
      case 'digitId': {
        onDigitIdClick(data);
        break;
      }
      case 'sourceObject': {
        onSourceObjectClick(data);
        break;
      }
      case 'transformedObject': {
        onTransformedObjectClick(data);
        break;
      }
    }
  }

  return (
    <div className="ag-theme-alpine h-[100%] px-[24px] mt-[50px] p-[10px] m-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      {/* <div className="text-[24px]">{title}</div> */}
      <div className="flex-1">
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          onCellClicked={cellClicked}
          rowData={rowData}
        />
      </div>
    </div>
  );
};

export default AuditLogTransformationGrid;
