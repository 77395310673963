import React from 'react';
import ApplicationTilesContainer from '../container/ApplicationTiles';
import FeatureGridContainer from '../container/FeatureGridContainer';
import GenericModalContainer from '../../modals/genericModal/container';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { useSelector } from 'react-redux';
import DeleteFeatureModalContainer from '../container/DeleteFeatureModalContainer';
import SideBar from '../../ApplicationV2/component/SideBar';
import { useNavigate, useParams } from 'react-router-dom';
import TopCardSubheader from '../../../layouts/TopCardSubheader';
import getExtensionSubheaderSchema from '../../../utils/navigationUtils/getExtensionSubheaderSchema';
import getApplicationTopNavigationSchema from '../../../utils/navigationUtils/getApplicationTopNavigationSchema';
import fetchApplicationCount from '../../../services/fetchApplicationCount';
import formatFeatureCountData from '../../../utils/formatFeatureCountData';

const Features = ({ deleteFeatureClick }) => {
  const { app_code = '' } = useParams();
  const navigate = useNavigate();
  const isModalOpen = useSelector(
    (store) => store.store.genericModal?.modalKeys?.deleteFeatureModal
  );
  const onMenuItemClick = ({ title }) => {
    switch (title) {
      case 'New Feature': {
        navigate(`/apps/${app_code}/features/new`);
        break;
      }
      case 'Variable Management': {
        navigate(`/apps/variables/${app_code}`);
        break;
      }
      case 'Bulk Processing': {
        // navigate(`/apps/excelMapping/${app_code}`);
        navigate(`/apps/bulkUploadList/${app_code}`);
        break;
      }
    }
  };
  return (
    <div className="flex">
      <SideBar
        page={'features'}
        onMenuItemClick={onMenuItemClick}
        currentContext={{ features: 'Features' }}
      />
      <div className="flex-1 flex flex-col bg-[#F4F3FA]">
        {/* <ApplicationTilesContainer /> */}
        <TopCardSubheader
          topCardNavigationSchema={getApplicationTopNavigationSchema({ features: true })}
          getCountService={fetchApplicationCount(app_code)}
          formatCountData={formatFeatureCountData}
          subheaderNavigationSchema={getExtensionSubheaderSchema({ application: true })}>
          <GenericModalContainer modalKey={ModalKeys.deleteFeatureModal}>
            <DeleteFeatureModalContainer />
          </GenericModalContainer>
          <FeatureGridContainer deleteFeatureClick={deleteFeatureClick} />
        </TopCardSubheader>
      </div>
    </div>
  );
};

export default Features;
