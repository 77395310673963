import { Box, Button, CircularProgress, Container } from '@mui/material';
import React, { useState } from 'react';
import FormField from '../component';
import classnames from 'classnames';

const DuplicateForm = ({
  removeDuplicateForm,
  currentFormNumber,
  formClassName,
  handleSubmit,
  formErrorMessage,
  formSchema,
  formData,
  formKey: formKey,
  removeSubmitButton,
  showLoader,
  initialDuplicateFieldObject,
  overrideHandleChange
}) => {
  const [duplicateFieldObject, setDuplicateFieldObject] = useState(initialDuplicateFieldObject);
  const [duplicateGroupObject, setDuplicateGroupObject] = useState({});

  const addField = (name) => {
    const currentFieldObject = duplicateFieldObject[name];
    currentFieldObject.fields = [...currentFieldObject.fields, currentFieldObject.count];
    currentFieldObject.count = currentFieldObject.count + 1;
    setDuplicateFieldObject({ ...duplicateFieldObject, [name]: currentFieldObject });
  };

  const addGroup = (name) => {
    const currentGroupObject = duplicateGroupObject[name] || [];
    currentGroupObject.fields = [
      ...(currentGroupObject.fields || []),
      currentGroupObject.count || 0
    ];
    currentGroupObject.count = (currentGroupObject.count || 0) + 1;
    setDuplicateGroupObject({ ...duplicateGroupObject, [name]: currentGroupObject });
  };

  const removeGroup = (name) => {
    const currentGroupObject = duplicateGroupObject[name];
  
    if (currentGroupObject) {
      const { count, ...rest } = currentGroupObject;
      if (count > 1) {
        // If count is greater than 1, decrement the count and update the state
        const updatedGroupObject = { ...duplicateGroupObject };
        updatedGroupObject[name] = { ...currentGroupObject, count: count - 1 };
        setDuplicateGroupObject(updatedGroupObject);
      } else {
        // If count is 1, remove the group object entirely
        const updatedGroupObject = { ...duplicateGroupObject };
        delete updatedGroupObject[name];
        setDuplicateGroupObject(updatedGroupObject);
      }
    }
  };
  

  const removeField = ({ name, duplicateField }) => {
    const currentFieldObject = duplicateFieldObject[name];
    currentFieldObject.fields = currentFieldObject.fields.filter(
      (element) => element !== duplicateField
    );
    setDuplicateFieldObject({ ...duplicateFieldObject, [name]: currentFieldObject });
  };

  const getFieldComponent = (field, duplicateFormName, isFormDisabled) => {
    const type = field.type;

    switch (type) {
      case 'group': {
        const fields = field.group.fields || [];
        const groupName = field.group.groupName;
        return (
          <>
            <div className="flex flex-col">
              <div className="flex items-center">
                <div className="text-lg font-bold text-red-500">{field.group.groupName}</div>
                {field.group.makeGroupDuplicate && (
                  <Button onClick={() => addGroup(field.group.groupName)}>Add</Button>
                )}
              </div>
              <div className="flex mt-2">
                {fields.map((element, key) => {
                  const updatedFieldName = `${groupName}${element.name}`;
                  const updatedField = { ...element, name: updatedFieldName };
                  return (
                    <span key={key}>
                      <FormField
                        className="!mr-2"
                        isFormDisabled={isFormDisabled}
                        value={formData[duplicateFormName] || ''}
                        key={duplicateFormName}
                        field={updatedField}
                        formFieldData={formData[duplicateFormName]}
                        handleChange={(event) =>
                          overrideHandleChange({
                            formData,
                            field: updatedField,
                            formKey: formKey,
                            event
                          })
                        }
                      />
                    </span>
                  );
                })}
              </div>
            </div>
            {duplicateGroupObject[groupName]?.fields?.map((duplicateGroup) => {
              return (
                <div className="flex flex-col">
                  <div className="flex items-center">
                    {field.group.makeGroupDuplicate && (
                      <Button onClick={() => removeGroup(field.group.groupName)}>Remove</Button>
                    )}
                  </div>
                  <div className="flex mt-2">
                    {fields.map((element) => {
                      const updatedFieldName = `${groupName}_${duplicateGroup}_${field.name}`;
                      const updatedField = { ...element, name: updatedFieldName };
                      return (
                        <FormField
                          className="!mr-2"
                          isFormDisabled={isFormDisabled}
                          value={formData[duplicateFormName] || ''}
                          key={duplicateFormName}
                          field={updatedField}
                          formFieldData={formData[duplicateFormName]}
                          handleChange={(event) =>
                            overrideHandleChange({
                              formData,
                              field: updatedField,
                              formKey: formKey,
                              event
                            })
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </>
        );
      }
      default: {
        return (
          <FormField
            isFormDisabled={isFormDisabled}
            value={formData[duplicateFormName] || ''}
            key={duplicateFormName}
            field={field}
            formFieldData={formData[duplicateFormName]}
            handleChange={(event) =>
              overrideHandleChange({ formData, field, formKey: formKey, event })
            }
          />
        );
      }
    }
  };

  return (
    <div className="my-4">
      <Button
        color="error"
        className="!ml-4 float-right !mr-4 !shadow-none"
        onClick={() => removeDuplicateForm(currentFormNumber)}
        variant="contained">
        {'Remove'}
      </Button>
      <Box className={formClassName}>
        <Container className="!p-0 remove-min">
          <form onSubmit={handleSubmit}>
            {formErrorMessage ? <Alert severity="error">{formErrorMessage}</Alert> : null}
            <div className="flex flex-wrap">
              {formSchema.map((field) => {
                const { name, isFormDisabled } = field;
                const duplicateFormName = `${name}_${currentFormNumber}`;
                const balanceClassName = classnames('m-2', {
                  'mt-[52px]': field.type !== 'group'
                });

                return (
                  <div>
                    {duplicateFieldObject[name] && (
                      <Button onClick={() => addField(field.name)}>Add</Button>
                    )}
                    <div className={balanceClassName}>
                      {getFieldComponent(field, duplicateFormName, isFormDisabled)}
                    </div>
                    {duplicateFieldObject[name] &&
                      duplicateFieldObject[name].fields.map((duplicateField) => {
                        const duplicateFieldObjectData = {
                          ...field,
                          name: `${field.name}_${duplicateField}`
                        };
                        return (
                          <div className="my-4">
                            <Button
                              color="error"
                              onClick={() => removeField({ name, duplicateField })}>
                              Remove
                            </Button>
                            <FormField
                              isFormDisabled={isFormDisabled}
                              value={formData[duplicateFormName] || ''}
                              key={duplicateFormName}
                              field={duplicateFieldObjectData}
                              formFieldData={formData[duplicateFormName]}
                              handleChange={(event) =>
                                overrideHandleChange({
                                  formData,
                                  field: duplicateFieldObjectData,
                                  formKey: formKey,
                                  event
                                })
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </div>
            {removeSubmitButton ? null : (
              <Button
                className="!mx-4 !mb-4 !h-[40px] !w-[80px]"
                type="submit"
                variant="contained"
                color="primary">
                {showLoader ? (
                  <div className="w-[100%] h-[100%] flex justify-center items-center">
                    <CircularProgress color="peace" size={20} />
                  </div>
                ) : (
                  'Submit'
                )}
              </Button>
            )}
          </form>
        </Container>
      </Box>
    </div>
  );
};

export default DuplicateForm;
