import React, { useState } from 'react';
import { useTable } from 'react-table';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent
} from '@mui/material';
 
const MongoDBQueryResult = ({ codeString }) => {
  const [open, setOpen] = useState(false);
  const [popupContent, setPopupContent] = useState('');
 
  const handleClickOpen = (content) => {
    setPopupContent(content);
    setOpen(true);
  };
 
  const handleClose = () => {
    setOpen(false);
    setPopupContent('');
  };
 
  const data = codeString;
  const columns = React.useMemo(
    () =>
      data && data.length > 0
        ? Object.keys(data[0]).map((key) => ({
            Header: key,
            accessor: key,
          }))
        : [],
    [data]
  );
  const tableInstance = useTable({ columns, data });
 
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
 
  return (
    <div className="mt-4 ml-4 max-w-[1200px]">
      <TableContainer component={Paper}>
        <Table {...getTableProps()} aria-label="simple table">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()} className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <TableCell {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {typeof cell.value === 'object' ? (
                        <Button variant="contained" color="primary" onClick={() => handleClickOpen(JSON.stringify(cell.value, null, 2))}>
                          View
                        </Button>
                      ) : (
                        cell.value
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
 
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Detailed View"}</DialogTitle>
        <DialogContent>
          <pre>{popupContent}</pre>
        </DialogContent>
      </Dialog>
    </div>
  );
};
 
MongoDBQueryResult.propTypes = {
  codeString: PropTypes.arrayOf(PropTypes.object).isRequired,
};
 
export default MongoDBQueryResult;