import React, { useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from '@mui/material/Button';
import { noop } from 'lodash';
import { useHistory, useNavigate } from 'react-router-dom';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import LinkIcon from '@mui/icons-material/Link';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { CircularProgress } from '@mui/material';
import MenuContainer from '../../common/menu/containers/MenuContainer';
import { Edit, ContentCopy, ContentPaste, Delete } from '@mui/icons-material';

const DurationPieChartGrid = ({
  fetchDataObjectData = noop,
  dataObjectData = [],
  fetchHLFData,
  gridData,
  title
}) => {
  const navigate = useNavigate();

  console.log(gridData, 'aaaaaaaa');

  const columnDefs = [
    { headerName: 'Feature Name', field: 'feature_name' },
    { headerName: 'Response Time (ms)', field: 'responseTime' },
    { headerName: 'Status Code', field: 'statusCode' }
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function onHide() {
    gridOptions.api.hideOverlay();
  }

  let gridApi;

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };

  const rowData = gridData?.[0]?.data?.[0]?.filteredfeatureList?.map((feature, index) => {
    return {
      feature_name: feature.feature_name,
      responseTime: feature.responseTime,
      statusCode: feature.statusCode
    };
  });

  async function onGridReady(params) {
    gridApi = params?.api;
    // params?.api?.showLoadingOverlay();
  }

  return (
    <div className="ag-theme-alpine flex-1 px-[24px] mt-[50px] p-[20px] m-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      <div className="text-[24px]">{title}</div>
      <div className="flex-1">
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          rowData={rowData}
          suppressCellFocus={true}
        />
      </div>
    </div>
  );
};

export default DurationPieChartGrid;
