import React, { useState } from 'react';
import { Bar, getElementsAtEvent } from 'react-chartjs-2';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import GenericModalContainer from '../../modals/genericModal/container';
import AccordianGraph from './traceAccordianGraph.component';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import 'chartjs-adapter-moment';
import moment from 'moment';

const HorizontalBarChart = ({ chartData: { data: chartData, parentTraceId } }) => {
  const dispatch = useDispatch();



  const requiredChartData = chartData?.[0]?.feature_trace;

  console.log("Data is .....",chartData)

  const [featureData, setFeatureData] = useState({});

  const data = {
    labels: requiredChartData?.map((element) => element.service_name) || [],
    datasets: [
      {
        label: 'Service Time',
        barThickness: 30,
        data: requiredChartData?.map((element) => [
          parseInt(element.startTime) % 100 - 1,
          parseInt(element.endTime) % 100 +1
        ]),
        borderWidth: 0.5,
        barPercentage: 1,
        backgroundColor: '#2185D0',
        borderColor: '#262626',
        barPercentage: 0.4
      }
    ]
  };

  const options = {
    indexAxis: 'y',
    scales: {
      y: {
        beginAtZero: true,
        mirror: true,
        align: 'left',
        padding: 375,
        // suggestedMin: 0,
        // min: 0.2
      },
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 45,
          minRotation: 45,
          stepValue: 0.1,
          // min: 0.2
          lables: false,
          // callback: function (value, index, ticks) {
          //   return moment(value).format('HH:mm:ss:sss');
          // }
        },
      },
      intersect: true,
    },
    onClick: async (event) => {
      //   const result = getElementsAtEvent(data, 'nearest', { intersect: true }, true);

      const chartInstance = event.chart;
      const elements = chartInstance.getElementsAtEventForMode(
        event,
        'nearest',
        { intersect: true },
        true
      );

      const clickedIndex = elements?.[0]?.index || 0;
      const selectedData = requiredChartData[clickedIndex];

      const response = await request
        .post(`${process.env.REACT_APP_GENRIC_API}/generic/feature/trace/getFeatureLogs`)
        .send({
          parent_trace_id: parentTraceId,
          span_trace_id: selectedData?.trace_id
        })
        .set('Authorization', `Bearer ${keycloak.token()}`);

      setFeatureData({
        selectedData,
        responseData: response.body.response
      });

      dispatch(
        genericModalStateUpdate({
          modalKey: ModalKeys.featureModal,
          isOpen: true
        })
      );
    }
  };

  return (
    <div className="bg-white w-[80%] h-[80%] m-auto p-2 mt-4">
      <div className="flex justify-between">
        <h2>Feature Chart</h2>
        <div
          className="w-auto float-right cursor-pointer relative"
          onClick={() =>
            dispatch(
              genericModalStateUpdate({
                modalKey: ModalKeys.horizontalChart,
                isOpen: false
              })
            )
          }>
          <CloseIcon />
        </div>
      </div>
      <Bar className="!h-[90%] p-4" data={data} options={options} />
      <GenericModalContainer modalKey={ModalKeys.featureModal}>
        <AccordianGraph data={featureData} />
      </GenericModalContainer>
    </div>
  );
};

export default HorizontalBarChart;

// this.chart = new Chart('canvas', {
//   type: 'horizontalBar',
//   data: {
//     // labels: lables,
//     datasets: [
//       {
//         label: 'Service Time',
//         barThickness: 30,
//         // data: data,
//         borderWidth: 0.5,
//         // barPercentage: 1,
//         backgroundColor: '#2185D0',
//         borderColor: '#262626',
//         barPercentage: 0.4
//       },
//     ],
//   },
//   options: {
//     // onClick: this.graphClicked,
//     layout: {
//       padding: {
//         left: 375
//       }
//     },
//     scales: {
//       yAxes: [{
//         ticks: {
//           beginAtZero: true,
//           mirror: true,
//           padding: 375,
//           // suggestedMin: 0,
//           // min: 0.2
//         },
//       }],
//       xAxes: [{
//         ticks: {
//           autoSkip: false,
//           maxRotation: 45,
//           minRotation: 45,
//           stepValue: 0.1,
//           // min: 0.2
//           lables: false,
//           callback: function (value, index, ticks) {
//             return moment(value).format('HH:mm:ss:sss');
//           }
//         },
//       }]
//     },
//     intersect: true,
//     tooltips: {
//       callbacks: {
//         label: function (tooltipItem, data) {
//           //return text to render for an individual item in the tooltip

//           let base: any = tooltipItem.value.split('"')[0].split(', ')
//           let duration = Number(base[1].split(']')[0]) - Number(base[0].split('[')[1])
//           let tooltipTimes = [Number(base[0].split('[')[1]), Number(base[1].split(']')[0])]
//           return [`StartTime: ` + moment(tooltipTimes[0]).format('YYYY-MM-DD HH:mm:ss'), `EndTime: ` + moment(tooltipTimes[1]).format('YYYY-MM-DD HH:mm:ss'), `ResponseTimeStamp: ` + duration + ' ms'];
//         }
//       }
//     }

//   }
// });

// this.chart.data.datasets[0].data = data;
// this.chart.data.labels = lables;
