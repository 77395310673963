import { useQuery } from 'react-query';
import FeatureGrid from '../component/FeatureGrid';
import { default as fetchFeatureDataCall } from '../../../services/fetchFeatureData';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFeatureDataAction } from '../slices/applicationSlice';
import { useParams } from 'react-router-dom';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { useEffect, useState } from 'react';

const FeatureGridContainer = ({ deleteFeatureClick }) => {
  const dispatch = useDispatch();
  const { app_code = '' } = useParams();
  const { refetch, isFetchedAfterMount } = useQuery('feature-data', fetchFeatureDataCall({ appCode: app_code }), {
    enabled: false,
    refetchOnWindowFocus: true
  });
  const storeFeatureData = useSelector((store) => store.store.moreApplication.featureData);

  const fetchFeatureData = async () => {
    const refetchData = await refetch();
    const { data, isSuccess } = refetchData;
    if (isSuccess) {
      dispatch(fetchFeatureDataAction({ featureData: data?.body?.featureObjects }));
    }
    return data?.body?.featureObjects;
  };

  const copyFeatureUrl = async (featureCode, app_code, path) => {
    const featureUrl = `${process.env.REACT_APP_GENRIC_API}/features/${app_code}/${
      path || featureCode
    }`;

    try {
      await navigator.clipboard.writeText(featureUrl);
    } catch (err) {
      alert('Copying url to clipboard failed.');
    }
  };

  return (
    <FeatureGrid
      copyFeatureUrl={copyFeatureUrl}
      isFetchedAfterMount={isFetchedAfterMount}
      fetchFeatureData={fetchFeatureData}
      deleteFeatureClick={deleteFeatureClick}
      featureData={storeFeatureData}
    />
  );
};

export default FeatureGridContainer;
