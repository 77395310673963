import React, { useEffect, useState } from 'react';
import DigitIdModal from '../components/DigitIdModal';
import { useSelector } from 'react-redux';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import { noop } from 'lodash';
import toast from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';

const DigitIdModalContainer = ({ onCloseModal = noop }) => {
  const [digitIdData, setDigitIdData] = useState(null);
  const modalStore = useSelector((store) => store?.store?.genericModal?.modalKeys);
  const modalData = modalStore?.digitIdModalData;
  const { auditId = '', digitId } = modalData;
  useEffect(() => {
    async function fetchData() {
      const encodedDigitId = encodeURIComponent(digitId);
      try {
        const res = await request
          .get(`${process.env.REACT_APP_CONFIG_API}/auditLog/validate`)
          .query({ auditId: auditId, digitId: encodedDigitId })
          .set('Authorization', `Bearer ${keycloak.token()}`);
        if (res?.body?.status?.code == 'SUCCESS') {
          toast.custom((t) => <Success t={t} message={res?.body?.status?.message} />, {
            duration: 4000
          });
          setDigitIdData(res?.body?.primary?.hlfTrace);
        } else {
          toast.custom((t) => <ErrorNotify t={t} message={res?.body?.status?.message} />, {
            duration: 4000
          });
          onCloseModal();
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [auditId, digitId]);

  return digitIdData ? (
    <DigitIdModal onCloseModal={onCloseModal} digitIdData={digitIdData} />
  ) : null;
};

export default DigitIdModalContainer;
