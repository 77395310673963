import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import CloseIcon from '@mui/icons-material/Close';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import OrganizationGrid from '../../HyperledgerFabricNetworks/component/OrganizationGrid';
import ChannelsGrid from '../../HyperledgerFabricNetworks/component/ChannelsGrid';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import { useDispatch, useSelector } from 'react-redux';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import keycloak from '../../../Keycloak';
import request from 'superagent';

export default function Tabs({ currentType, fetchHLFData }) {
  const [value, setValue] = React.useState('1');
  const [data, setData] = React.useState({ organizations: [], configFiles: [] });
  const { id: hlfId } = useSelector((store) => store.store?.hlf) || {};

  async function fetchOrganizationData() {
    const fetchData = await request
      .get(`${process.env.REACT_APP_CONFIG_API}/hlf/id`)
      .query({
        id: hlfId
      })
      .set('Authorization', `Bearer ${keycloak.token()}`);
    setData({
      organizations: fetchData.body?.hlfNetworkObjects[0]?.organizations,
      configFiles: fetchData.body?.hlfNetworkObjects[0]?.config_files,
      walletFiles: fetchData.body?.hlfNetworkObjects[0]?.wallet_files,
      id: fetchData.body?.hlfNetworkObjects[0]?.id || ''
    });
  }

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="w-[80%] m-auto bg-white p-4 rounded-[8px] top-[20%] relative">
      <div
        className="w-auto float-right cursor-pointer relative"
        onClick={() =>
          dispatch(
            genericModalStateUpdate({
              modalKey: ModalKeys.viewHlf,
              isOpen: false
            })
          )
        }>
        <CloseIcon />
      </div>
      <Box sx={{ typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab className="!mr-4" label="Organizations" value="1" />
              <Tab label="Channels" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <OrganizationGrid
              data={data}
              fetchHLFData={fetchHLFData}
              fetchOrganizationData={fetchOrganizationData}
              currentType={currentType}
            />
          </TabPanel>
          <TabPanel value="2">
            <ChannelsGrid />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
