import request from 'superagent';
import { submitObjectChildMapping } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';
import { toast } from 'react-hot-toast';
import Success from '../modules/notify/Success';
import Delete from '../modules/notify/Delete';

const submitNewChildObjectMapping = async ({ payload, query }) => {
  const res = await request
    .post(submitObjectChildMapping)
    .send(payload)
    .query({ flag: 'REVIEW' })
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${keycloak.token()}`);

  if (res.body.status.code == 'SUCCESS') {
    toast.custom((t) => <Success t={t} message={'Object Mapping is Submittted SuccessFully'} />, {
      duration: 4000
    });
    console.log('Form Submit successfully');
  } else {
    toast.custom((t) => <Delete t={t} message={'Object Mapping is Submission Failed'} />, {
      duration: 4000
    });
    console.log(res.body.status.message);
  }

  return res;
};

export default submitNewChildObjectMapping;
