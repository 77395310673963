import { getSourceActionOptions, getSourceObjectOptions } from '../utils/getTextFromArray';

const orchestrationFormRuleSchema = (
  orchestrationDataObject = [],
  orchestrationSourceActions = [],
  fieldData
) => {
  const sourceObjectOptions = getSourceObjectOptions(orchestrationDataObject);
  const sourceActionOptions = getSourceActionOptions(orchestrationSourceActions);
  return {
    title: 'Orchestration Rule',
    isFormDisabled: false,
    formSchema: [
      {
        name: 'Orchestration',
        type: 'Fields',
        duplicate: false,
        varient: 'full',
        data: {
          0: [
            {
              name: 'Source Object',
              label: 'Select Source Object',
              type: 'dropdown',
              options: sourceObjectOptions,
              required: false
            },
            {
              name: 'Source Action',
              label: 'Select Source Action',
              type: 'dropdown',
              options: sourceActionOptions,
              required: false
            }
          ]
        }
      }
    ]
  };
};

const orchestrationEditFormRuleSchema = (
  orchestrationDataObject = [],
  orchestrationSourceActions = [],
  fieldData,
  orchestrationData
) => {
  const sourceObjectOptions = getSourceObjectOptions(orchestrationDataObject);
  const sourceActionOptions = getSourceActionOptions(orchestrationSourceActions);
  const { sourceObjectId, sourceAction } = orchestrationData;
  return {
    title: 'Orchestration Rule',
    isFormDisabled: false,
    formSchema: [
      {
        name: 'Orchestration',
        type: 'Fields',
        duplicate: false,
        varient: 'full',
        data: {
          0: [
            {
              name: 'Source Object',
              label: 'Select Source Object',
              type: 'dropdown',
              options: sourceObjectOptions,
              defaultValue: sourceObjectId,
              required: false
            },
            {
              name: 'Source Action',
              label: 'Select Source Action',
              type: 'dropdown',
              options: sourceActionOptions,
              defaultValue: sourceAction,
              required: false
            }
          ]
        }
      }
    ]
  };
};

const orchestrationViewFormRuleSchema = (
  orchestrationDataObject = [],
  orchestrationSourceActions = [],
  fieldData,
  orchestrationData
) => {
  const sourceObjectOptions = getSourceObjectOptions(orchestrationDataObject);
  const sourceActionOptions = getSourceActionOptions(orchestrationSourceActions);
  const { sourceObjectId, sourceAction } = orchestrationData;
  return {
    title: 'Orchestration Rule',
    isFormDisabled: false,
    disableFullForm: true,
    formSchema: [
      {
        name: 'Orchestration',
        type: 'Fields',
        duplicate: false,
        varient: 'full',
        data: {
          0: [
            {
              name: 'Source Object',
              label: 'Select Source Object',
              type: 'dropdown',
              options: sourceObjectOptions,
              defaultValue: sourceObjectId,
              required: false
            },
            {
              name: 'Source Action',
              label: 'Select Source Action',
              type: 'dropdown',
              options: sourceActionOptions,
              defaultValue: sourceAction,
              required: false
            }
          ]
        }
      }
    ]
  };
};

const orchAddConditionSchema = () => {
  return {
    title: 'Orchestration Condition',
    isFormDisabled: false,
    formSchema: [
      {
        name: 'Conditions',
        type: 'Fields',
        duplicate: false,
        varient: 'full',
        data: {
          0: [
            {
              name: 'Source Condition',
              label: 'Enter Source Condition',
              type: 'text',
              required: false,
              multiline: true,
              multilineRows: 2
            },
            {
              name: 'Request Condition',
              label: 'Enter Request Condition',
              type: 'text',
              required: false,
              multiline: true,
              multilineRows: 2
            }
          ]
        }
      }
    ]
  };
};

const editOrchAddConditionSchema = (orchestrationData) => {
  const { sourceCondition, requestCondition } = orchestrationData;
  return {
    title: 'Orchestration Condition',
    isFormDisabled: false,
    formSchema: [
      {
        name: 'Conditions',
        type: 'Fields',
        duplicate: false,
        varient: 'full',
        data: {
          0: [
            {
              name: 'Source Condition',
              label: 'Enter Source Condition',
              type: 'text',
              required: false,
              defaultValue: sourceCondition,
              multiline: true,
              multilineRows: 2
            },
            {
              name: 'Request Condition',
              label: 'Enter Request Condition',
              type: 'text',
              required: false,
              defaultValue: requestCondition,
              multiline: true,
              multilineRows: 2
            }
          ]
        }
      }
    ]
  };
};

const viewOrchAddConditionSchema = (orchestrationData) => {
  const { sourceCondition, requestCondition } = orchestrationData;
  return {
    title: 'Orchestration Condition',
    isFormDisabled: false,
    disableFullForm: true,
    formSchema: [
      {
        name: 'Conditions',
        type: 'Fields',
        duplicate: false,
        varient: 'full',
        data: {
          0: [
            {
              name: 'Source Condition',
              label: 'Enter Source Condition',
              type: 'text',
              required: false,
              defaultValue: sourceCondition,
              multiline: true,
              multilineRows: 2
            },
            {
              name: 'Request Condition',
              label: 'Enter Request Condition',
              type: 'text',
              required: false,
              defaultValue: requestCondition,
              multiline: true,
              multilineRows: 2
            }
          ]
        }
      }
    ]
  };
};

const orchestrationActionsSchema = () => {
  return {
    title: 'Orchestration Actions',
    isFormDisabled: false,
    formSchema: [
      {
        name: 'Actions',
        duplicate: true,
        type: 'Fields',
        varient: 'full',
        accordianOpen: 'panel1',
        data: {
          0: [
            {
              name: 'Recievers',
              label: 'Enter Recievers',
              type: 'text',
              required: false
            },
            {
              name: 'Feature Code',
              label: 'Enter Feature Code',
              type: 'text',
              required: false
            },
            {
              name: 'Payload',
              label: 'Enter Payload',
              type: 'text',
              required: false
            },
            {
              name: 'Priority',
              label: 'Enter Priority',
              type: 'text',
              required: false
            },
            {
              name: 'Extra Headers',
              label: 'Enter Extra Headers',
              type: 'text',
              required: false
            },
            {
              name: 'Extra Body',
              label: 'Enter Extra Body',
              type: 'text',
              required: false
            }
          ]
        }
      }
    ]
  };
};

const editOrchestrationActionsSchema = (orchestrationData) => {
  const { orchestrationActions = [] } = orchestrationData;
  const actionsData = () => {
    var arr = [];
    var data = {};
    orchestrationActions.map((ele, index) => {
      const { actionEnvironment, featureName, payload, priority, extraHeaders, extraBody } = ele;
      arr.push([
        {
          name: 'Recievers',
          label: 'Enter Recievers',
          type: 'text',
          required: false,
          defaultValue: actionEnvironment
        },
        {
          name: 'Feature Code',
          label: 'Enter Feature Code',
          type: 'text',
          required: false,
          defaultValue: featureName
        },
        {
          name: 'Payload',
          label: 'Enter Payload',
          type: 'text',
          required: false,
          defaultValue: payload
        },
        {
          name: 'Priority',
          label: 'Enter Priority',
          type: 'text',
          required: false,
          defaultValue: priority
        },
        {
          name: 'Extra Headers',
          label: 'Enter Extra Headers',
          type: 'text',
          required: false,
          defaultValue: extraHeaders
        },
        {
          name: 'Extra Body',
          label: 'Enter Extra Body',
          type: 'text',
          required: false,
          defaultValue: extraBody
        }
      ]);
    });
    arr.map((ele, index) => {
      data[index] = ele;
    });
    return data;
  };

  return {
    title: 'Orchestration Actions',
    isFormDisabled: false,
    formSchema: [
      {
        name: 'Actions',
        duplicate: true,
        type: 'Fields',
        varient: 'full',
        accordianOpen: 'panel1',
        data: actionsData()
      }
    ]
  };
};

const viewOrchestrationActionsSchema = (orchestrationData) => {
  const { orchestrationActions = [] } = orchestrationData;
  const actionsData = () => {
    var arr = [];
    var data = {};
    orchestrationActions.map((ele, index) => {
      const { actionEnvironment, featureName, payload, priority, extraHeaders, extraBody } = ele;
      arr.push([
        {
          name: 'Recievers',
          label: 'Enter Recievers',
          type: 'text',
          required: false,
          defaultValue: actionEnvironment
        },
        {
          name: 'Feature Code',
          label: 'Enter Feature Code',
          type: 'text',
          required: false,
          defaultValue: featureName
        },
        {
          name: 'Payload',
          label: 'Enter Payload',
          type: 'text',
          required: false,
          defaultValue: payload
        },
        {
          name: 'Priority',
          label: 'Enter Priority',
          type: 'text',
          required: false,
          defaultValue: priority
        },
        {
          name: 'Extra Headers',
          label: 'Enter Extra Headers',
          type: 'text',
          required: false,
          defaultValue: extraHeaders
        },
        {
          name: 'Extra Body',
          label: 'Enter Extra Body',
          type: 'text',
          required: false,
          defaultValue: extraBody
        }
      ]);
    });
    arr.map((ele, index) => {
      data[index] = ele;
    });
    return data;
  };

  return {
    title: 'Orchestration Actions',
    isFormDisabled: false,
    disableFullForm: true,
    formSchema: [
      {
        name: 'Actions',
        duplicate: true,
        type: 'Fields',
        varient: 'full',
        accordianOpen: 'panel1',
        data: actionsData()
      }
    ]
  };
};

export {
  orchestrationFormRuleSchema,
  orchAddConditionSchema,
  orchestrationActionsSchema,
  orchestrationEditFormRuleSchema,
  editOrchAddConditionSchema,
  editOrchestrationActionsSchema,
  orchestrationViewFormRuleSchema,
  viewOrchAddConditionSchema,
  viewOrchestrationActionsSchema
};
