import { TextField } from '@mui/material';
import React from 'react';
import { getBezierPath, EdgeLabelRenderer, BaseEdge } from 'reactflow';

const CustomEdge = ({ id, sourceX, sourceY, targetX, targetY }) => {
  console.log('{ id, sourceX, sourceY, targetX, targetY } are ...', {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY
  });
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
             
        {/* <input  
          style={{
            height:'14px',
            width:'40px',
            fontSize:'8px',
            fontWeight:'bold',
            letterSpacing:'0.6px',
            border:'1px solid green',
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            pointerEvents: 'all'
          }}
          className="nodrag nopan"/> */}
         
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
