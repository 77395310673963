import { Handle, Position } from 'reactflow';
import React from 'react';

const NormalRightNode = ({ data }) => {
  return (
    <div
      id={data.id}
      style={{ backgroundColor: data.bg }}
      location={data.location}
      name={data.label}
      className="rounded-full font-bold px-2 overflow-hidden min-w-[200px] min-h-[60px] flex items-center justify-center mb-2 bg-[#E4E4E4]">
      <div name={data.label} id={data.id} location={data.location} className="custom-node">
        <div
          className="overflow-hidden whitespace-nowrap text-ellipsis"
          name={data.label}
          id={data.id}
          location={data.location}>
          {data.label}
        </div>
      </div>
      <Handle
        type="target"
        position={Position.Left}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={true}
      />
    </div>
  );
};

export default NormalRightNode;
