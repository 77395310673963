import request from 'superagent';
import { fetchUsersUrl } from '../modules/dashboard/constants/applicationRequest';

import keycloak from '../Keycloak';

const fetchUsersList = () => {
  return async () => {
    const res = await request.get(fetchUsersUrl).set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

export default fetchUsersList;
