import React from 'react';
import SideBar from '../../ApplicationV2/component/SideBar';
import { useNavigate, useParams } from 'react-router-dom';
import ObjectAuditLogGridContainer from './ObjectAuditLogGridContainer';
import ObjectAuditFilterContainer from './ObjectAuditFilterContainer';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import GenericModalContainer from '../../modals/genericModal/container';
import DigitIdModalContainer from './DigitIdModalContainer';
import { useDispatch } from 'react-redux';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ObjectModalContainer from './ObjectModalContainer';
import TopSubHeader from '../../../layouts/TopSubheader';
import getExtensionSubheaderSchema from '../../../utils/navigationUtils/getExtensionSubheaderSchema';

const ObjectAuditLog = () => {
  const { app_code = '' } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onMenuItemClick = ({ title }) => {
    switch (title) {
      case 'Objects': {
        navigate(`/apps/${app_code}/auditLog`);
        break;
      }
      case 'Object Mappings': {
        navigate(`/apps/${app_code}/auditLog/objectMappings`);
        break;
      }
      case 'Transformation': {
        navigate(`/apps/${app_code}/auditLog/transformation`);
        break;
      }
      case 'Audit Explorer': {
        window.open('http://20.204.137.176:8080/#/login', '_blank');
        break;
      }
    }
  };

  const onDigitIdClick = (data) => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.digitIdModal,
        isOpen: true,
        modalDataKey: 'digitIdModalData',
        modalData: data
      })
    );
  };

  const onObjectClick = (data) => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.dataObjectModal,
        isOpen: true,
        modalDataKey: 'dataObjectModalData',
        modalData: data
      })
    );
  };

  return (
    <div className="flex">
      <SideBar
        page={'auditLog'}
        onMenuItemClick={onMenuItemClick}
        currentContext={{ auditLog: 'Objects' }}
      />
      <TopSubHeader subheaderNavigationSchema={getExtensionSubheaderSchema({ extension: true })}>
        <div className="flex-1 flex flex-col !bg-[#F4F3FA]">
          <div className="w-[80%] m-auto">
            <ObjectAuditFilterContainer />
          </div>
          <ObjectAuditLogGridContainer
            onDigitIdClick={onDigitIdClick}
            onObjectClick={onObjectClick}
          />
          <GenericModalContainer modalKey={ModalKeys.digitIdModal}>
            <DigitIdModalContainer />
          </GenericModalContainer>
          <GenericModalContainer modalKey={ModalKeys.dataObjectModal}>
            <ObjectModalContainer />
          </GenericModalContainer>
        </div>
      </TopSubHeader>
    </div>
  );
};

export default ObjectAuditLog;
