import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ApplicationCardContainer from '../container/ApplicationCardContainer';
import { Button } from '@mui/material';
import modalKeys from '../../modals/genericModal/constants/GenericModalKeys';

import newApplicationSchema from '../../../schema/form_schema/newApplicationSchema';
import getApplicationCode from '../utils/getApplicationCode';
import GenericModalContainer from '../../modals/genericModal/container';
import NewApplicationFormContainer from '../container/NewApplicationFormContainer';
import { useSelector } from 'react-redux';

const Application = ({ isLoading, applicationData, openDashboardGenericModal }) => {
  const applicationState = useSelector((store) => store.store.application.applicationState);

  const loading = isLoading || applicationState == 'pending';

  if (loading) {
    return (
      <div className="w-[100%] h-[100%] flex justify-center items-center">
        <CircularProgress color="peace" />
      </div>
    );
  }

  const applicationCodes = getApplicationCode(applicationData);
  const formSchema = newApplicationSchema(applicationCodes);
  return (
    <div>
      <div className="hidden tablet:flex items-center justify-end pb-4">
        <Button variant="contained" className="border-2 border-blue-400 p-2 !mr-4 rounded">
          Import
        </Button>
        <Button
          variant="contained"
          className="border-2 border-blue-400 p-2 !mr-4 rounded"
          onClick={openDashboardGenericModal}>
          New RealApp
        </Button>
        <Button variant="contained" className="border-2 border-blue-400 p-2 !mr-4 rounded">
          File Status
        </Button>
      </div>
      <div className="flex h-[100%]">
        <div className="tablet:w-[100%] w-[100%] justify-center">
          <div className="max-w-[100%] tablet:hidden flex h-[40px] mb-4 overflow-auto">
            <button className="border-2 whitespace-nowrap border-blue-400 py-2 px-4 rounded-full mr-4">
              Import
            </button>
            <button className="border-2 whitespace-nowrap border-blue-400 py-2 px-4 rounded-full mr-4">
              New Application
            </button>
            <button className="border-2 whitespace-nowrap border-blue-400 py-2 px-4 rounded-full mr-4">
              File Status
            </button>
          </div>
          <div className="first:border-none flex flex-wrap justify-around">
            {applicationData &&
              applicationData.map((application) => {
                return <ApplicationCardContainer application={application} />;
              })}
          </div>
        </div>
      </div>
      <GenericModalContainer modalKey={modalKeys.dashboardModal}>
        <NewApplicationFormContainer schema={formSchema} />
      </GenericModalContainer>
    </div>
  );
};

export default Application;
