import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ErrorNotify from '../../notify/ErrorNotify';
import Success from '../../notify/Success';
import { CircularProgress } from '@material-ui/core';

const OcrFileDrop = ({ setOCRData, isTestCheckReducer, ocrType }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [data,setData]=useState(null)
  const onDropHandler = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onDropHandler,
    noClick: true,
    multiple: false
  });

  useEffect(() => {
    return () => {
      setSelectedFile(false);
    };
  }, [setOCRData]);

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('file', selectedFile);
        setIsLoading(true);
        const response = await request
          .post(`${process.env.REACT_APP_CONFIG_API}/type/connectors/ocr/type/${ocrType}`)
          .send(formData)
          .set('Authorization', `Bearer ${keycloak.token()}`);

        setIsLoading(false);

        if (response.body.status.code == 'SUCCESS') {
          setOCRData(response.body.response.results[0]);
          console.log(response.body.response.results[0]);

          // dispatch(

          // )
          
          isTestCheckReducer({ type: 'ocr' });
          toast.custom((t) => <Success t={t} message={'File Uploaded successfully'} />, {
            duration: 4000
          });
          dispatch(
            genericModalStateUpdate({
              modalKey: ModalKeys.ocrFileUplaod,
              isOpen: false
            })
          );
        } else {
          toast.custom((t) => <ErrorNotify t={t} message={'Failed File Upload'} />, {
            duration: 4000
          });
          console.error('File upload failed.');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        toast.custom((t) => <ErrorNotify t={t} message={'Failed File Upload. Error in API'} />, {
          duration: 4000
        });
      }
    }
  };

  return (
    <div className="w-[40%] m-auto bg-white p-4 rounded-[8px] top-[10px] relative">
      <div
        {...getRootProps()}
        className="p-6 border-2 border-dashed rounded-md h-[350px] cursor-pointer hover:border-gray-500 flex justify-center items-center">
        <input {...getInputProps()} />
        {selectedFile ? (
          <p className="text-gray-600">
            <span className="mr-2">{selectedFile.name}</span>
          </p>
        ) : (
          <div className="flex justify-center">
            <div className="bg-gray-300 rounded-full w-[60px] h-[60px] flex justify-center items-center">
              <img
                width="42"
                height="42"
                src="https://img.icons8.com/ios/50/drag-and-drop.png"
                alt="drag-and-drop"
              />
            </div>
            <div className="ml-4">
              <div className="text-[24px] text-gray-600 font-semibold">Drop anywhere to import</div>
              <div>
                Or Select{' '}
                <span className="text-blue-600 hover:underline" onClick={open}>
                  Files
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedFile && (
        <button
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 min-w-[84px]"
          onClick={handleUpload}>
          {isLoading ? <CircularProgress color="peace" size={20} /> : 'Upload'}
        </button>
      )}
      <div>
        {data}
      </div>
    </div>
  );
};

export default OcrFileDrop;
