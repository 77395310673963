import { useDispatch, useSelector } from 'react-redux';
import DefaultDashboard from '../../common/layout/container/DefaultDashboard';
import { dashboardContext } from '../../dashboard/slices/dashboardSlice';
import HLFForm from './HLFForm';
import { useState } from 'react';
import { Button } from '@mui/material';
import HLFGridContainer from './HLFGridContainer';
import { useNavigate } from 'react-router-dom';
import SideBar from '../../ApplicationV2/component/SideBar';

const HyperledgerFabricNetworks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  dispatch(dashboardContext({ contextName: 'HLF Networks', context: 'HLF' }));
  let currentContext = useSelector((store) => store.store.dashboard.currentContext);

  const onMenuItemClick = ({ contextName, title }) => {
    switch (title) {
      case 'HLF Networks': {
        navigate('/hlf');
        break;
      }
      case 'Application': {
        navigate('/dashboard');
        break;
      }
      case 'New Network': {
        navigate('/hlf/new');
        break;
      }
      case 'HLF Explorer': {
        navigate('/hlfExplorer');
        break;
      }
    }
  };

  currentContext = {
    ...currentContext,
    HLF: 'HLF Networks'
  };

  return (
    <div className="w-full min-h-full flex bg-[#F4F3FA]">
      <SideBar page="HLF" onMenuItemClick={onMenuItemClick} currentContext={currentContext} />
      <HLFGridContainer />
    </div>
  );
};

export default HyperledgerFabricNetworks;
