import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { noop } from 'lodash';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import MenuContainer from '../../common/menu/containers/MenuContainer';
import { Edit } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import 'ag-grid-community/styles/ag-theme-material.css';


const OrchestrationGrid = ({
  orchestrationData = [],
  fetchOrchestrationData = noop,
  title,
  deleteOrchestrationClick
}) => {
  const { app_code = '' } = useParams();
  const navigate = useNavigate();
  const columnDefs = [
    { headerName: 'DATA OBJECT', field: 'dataObject' },
    { headerName: 'ACTION', field: 'action' },
    { headerName: 'REQUEST CONDITION', field: 'requestCondition' },
    { headerName: 'SOURCE CONDITION', field: 'sourceCondition' },
    {
      headerName: 'TRIGGER',
      field: 'TRIGGER',
      cellRenderer: (data) => (
        <MenuContainer
          menuData={[
            {
              name: 'View',
              callback: () => cellClicked(data, 'view'),
              icon: <VisibilityIcon fontSize="small" />
            },
            {
              name: 'Edit',
              callback: () => cellClicked(data, 'edit'),
              icon: <Edit fontSize="small" />
            },
            {
              name: 'Delete',
              callback: () => cellClicked(data, 'delete'),
              icon: <DeleteIcon fontSize="small" />
            }
          ]}
        />
      )
    }
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    floatingFilter: false,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function onShowLoading() {
    gridOptions.api.showLoadingOverlay();
  }

  function onHide() {
    gridOptions.api.hideOverlay();
  }

  let gridApi;

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };
  console.log(orchestrationData, '999999');

  const rowData = orchestrationData.map((feature) => {
    return {
      dataObject: feature.sourceObjectName,
      action: feature.sourceAction,
      requestCondition: feature.requestCondition,
      sourceCondition: feature.sourceCondition || 'NA',
      id: feature.orchestrationId
    };
  });

  async function onGridReady(params) {
    gridApi = params?.api;
    params?.api?.showLoadingOverlay();
    const fetchData = await fetchOrchestrationData();
  }

  function cellClicked(data, field) {

    switch (field) {
      case 'edit': {
        navigate(`/apps/${app_code}/orchestration/edit/${data.data.id}`);
        break;
      }
      case 'delete': {
        deleteOrchestrationClick(data.data.id, data.data.dataObject);
        break;
      }
      case 'view': {
        navigate(`/apps/${app_code}/orchestration/view/${data.data.id}`);
        break;
      }
    }
  }

  const onExportClick = () => {
    gridApi = params?.api;
    gridApi?.exportDataAsCsv();
  };

  return (
    <div className="ag-theme-material h-[100%] px-[24px] p-[20px] mx-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      <div className="text-[24px]">{title}</div>
      <div className="flex-1">
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          rowData={rowData}
          onCellClicked={cellClicked}
        />
      </div>
    </div>
  );
};

export default OrchestrationGrid;
