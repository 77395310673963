import React from 'react';
import GetFormComponents from '../component/GetFormComponents';
import { Button, CircularProgress } from '@mui/material';
import GetFormComponentsV3 from '../../formV3/component/GetFormComponentsV3';
import { noop } from 'lodash';

const FormV2Container = ({
  restrictRerender = false,
  schema,
  onSubmitForm,
  disableSubmit = false,
  className = '',
  variant,
  submitButtonName,
  showTable,
  addCancelButton = false,
  onCancleBtnClick = noop,
  submitButtonClassName,
  disabledClick,
  showDraftBtn = false
}) => {
  const {
    title = '',
    formSchema,
    isFormDisabled,
    hideTitle,
    disableFullForm = false
  } = schema || {};
  const path = [title];
  console.log("schema is 30",schema)
  switch (variant) {
    case 'formV3':
      return formSchema ? (
        <form className={`flex-1 m-auto ${className}`}>
          {!hideTitle && (
            <div className='flex justify-between bg-[#00306A] py-4 px-4 text-2xl font-extrabold text-white rounded-tl-2xl rounded-tr-2xl'>
              <div>
                <h2 className="">
                  {title.toUpperCase()}
                </h2>
              </div>
              {/* <div class="p-4 h-10 bg-white rounded-full flex items-center justify-center text-black">
                <span class="text-lg font-bold">Under Review</span>
              </div> */}
            </div>
          )}
          <div className="flex shadow-lg flex-wrap">
            {formSchema.map((form, key) => {
              const FormComponent = GetFormComponentsV3({
                restrictRerender,
                form,
                path,
                isFormDisabled,
                disableFullForm,
                showTable
              });
              return FormComponent;
            })}
          </div>
          {disableSubmit ? null : (
            <div className="w-[100%] flex mt-4 !justify-center !pr-[24px]">
              <Button
                className={`!mb-[24px] !w-[200px] !h-[50px] !font-semibold ${submitButtonClassName}`}
                onClick={() => onSubmitForm({ title: title })}
                variant="contained">
                {submitButtonName || 'Submit Form'}
              </Button>
              {showDraftBtn ? (
                <Button
                  className="!mb-[24px] !ml-10 !w-[200px] !h-[50px] !font-semibold"
                  onClick={() => onSubmitForm({ title: title, state: 'DRAFT' })}
                  variant="contained">
                  Save As Draft
                </Button>
              ) : null}
              {addCancelButton ? (
                <Button
                  className="!mb-[24px] !ml-10 !w-[200px] !h-[50px] !font-semibold"
                  onClick={onCancleBtnClick}
                  variant="contained">
                  Cancel
                </Button>
              ) : null}
            </div>
          )}
        </form>
      ) : (
        <></>
      );
    default:
      return formSchema ? (
        <form className={`flex-1 m-auto ${className}`}>
          {!hideTitle && (
            <h2 className="bg-blue-700 py-4 px-4 text-lg font-bold text-white">{title}</h2>
          )}
          <div className={className}>
            {formSchema.map((form, key) => {
              const FormComponent = GetFormComponents({
                restrictRerender,
                form,
                path,
                isFormDisabled
              });
              return FormComponent;
            })}
          </div>
          {disableSubmit ? null : (
            <div className={`w-[100%] flex mt-4 !justify-end !pr-[24px] ${submitButtonClassName}`}>
              <Button
                disabled={disabledClick}
                className={`!mb-[24px] !h-[50px] !font-semibold`}
                onClick={onSubmitForm}
                variant="contained">
                {submitButtonName || 'Submit Form'}
              </Button>
            </div>
          )}
        </form>
      ) : (
        <CircularProgress />
      );
  }
};

export default FormV2Container;
