import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import keycloak from '../../../Keycloak';
import request from 'superagent';
import formKeys from '../../common/form/constants/formKeys';
import { useDispatch } from 'react-redux';
import { clearFormState, updateFormState } from '../../common/form/slices/formSlice';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import { toast } from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const FileDropZone = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [importData, setImportData] = useState('');
  const options = [
    {
      name: 'RealApp',
      value: 'RealApp'
    },
    {
      name: 'OpenAPI',
      value: 'OpenAPI'
    }
  ];

  const dispatch = useDispatch();

  const onDropHandler = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onDropHandler,
    noClick: true,
    multiple: false
  });

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('import_file', selectedFile);
        formData.append('format', importData);
        const response = await request
          .post(`${process.env.REACT_APP_CONFIG_API}/file/import`)
          .send(formData)
          .set('Authorization', `Bearer ${keycloak.token()}`);

        if (response.body.status.code == 'SUCCESS') {
          toast.custom((t) => <Success t={t} message={'Application created successfully'} />, {
            duration: 4000
          });
          dispatch(
            updateFormState({
              formKey: formKeys.newApplicationForm,
              formState: 'success'
            })
          );
          dispatch(
            genericModalStateUpdate({
              modalKey: 'dashboardModal',
              isOpen: false
            })
          );
          dispatch(
            clearFormState({
              formKey: formKeys.newApplicationForm
            })
          );
        } else {
          toast.custom(
            (t) => (
              <ErrorNotify
                t={t}
                message={response.body.status.message || 'Application creation failed.'}
              />
            ),
            {
              duration: 4000
            }
          );
          console.error('File upload failed.');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  return (
    <div>
      <div
        {...getRootProps()}
        className="p-6 border-2 border-dashed rounded-md h-[350px] cursor-pointer hover:border-gray-500 flex justify-center items-center">
        <input {...getInputProps()} />
        {selectedFile ? (
          <p className="text-gray-600">{selectedFile.name}</p>
        ) : (
          <div className="flex justify-center">
            <div className="bg-gray-300 rounded-full w-[60px] h-[60px] flex justify-center items-center">
              <img
                width="42"
                height="42"
                src="https://img.icons8.com/ios/50/drag-and-drop.png"
                alt="drag-and-drop"
              />
            </div>
            <div className="ml-4">
              <div className="text-[24px] text-gray-600 font-semibold">Drop anywhere to import</div>
              <div>
                Or Select{' '}
                <span className="text-blue-600 hover:underline" onClick={open}>
                  Files
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedFile && (
        <div className="flex">
          <FormControl className="w-[45%] !m-4">
            <InputLabel>{'Import Format'}</InputLabel>
            <Select
              name="importFormat"
              label="Import Format"
              value={importData}
              onChange={(event) => {
                setImportData(event.target.value);
              }}
              autoWidth>
              {options.map((option) => {
                const { name, value } = option || {};
                return (
                  <MenuItem key={name} value={value}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <button
            disabled={!importData}
            className={`mt-[1.5rem] px-4 py-2 !max-h-[40px] text-white rounded ${
              importData ? 'bg-blue-500' : 'bg-gray-500'
            } `}
            onClick={handleUpload}>
            Upload
          </button>
        </div>
      )}
    </div>
  );
};

export default FileDropZone;
