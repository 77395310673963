import { Label } from '@mui/icons-material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import classNames from 'classnames';
import { noop } from 'lodash';
import React from 'react';

const VersionDropdownComponent = ({ data = {}, onClick = noop }) => {
  const { prevVersions = [], currentVersion = '', status = '' } = data;

  const versionClassName = (status) =>
    classNames('ml-2', {
      'text-green-500': status == 'APPROVED',
      'text-red-500': status == 'DEPRECATED',
      'text-gray-500': status == 'DRAFT',
      'text-pink-500': status == 'REVIEW'
    });
  return (
    <div className="flex flex-col flex-1 mb-8">
      <FormControl className="w-[45%]">
        <InputLabel>Versions</InputLabel>
        <Select
          className="w-[200px]"
          onChange={onClick}
          label="Versions"
          defaultValue={currentVersion}>
          <MenuItem value={currentVersion} disabled={true}>
            <div className="flex">
              <div>{currentVersion}</div>
              <div className={versionClassName(status)}>{status}</div>
            </div>
          </MenuItem>
          {prevVersions.map((element) => {
            return (
              <MenuItem value={element}>
                <div className="flex">
                  <div>{element.version}</div>
                  <div className={versionClassName(element.status)}>{element.status}</div>
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default VersionDropdownComponent;
