import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import OrchestrationFormRuleContainer from '../../Application/container/OrchestrationFormRuleContainer';
import OrchestrationActionsContainer from '../../Application/container/OrchestrationActionsContainer';
import OrchestrationAddCond from '../../Application/container/OrchestrationAddCond';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueries } from 'react-query';
import { getOrchestrationDataObjects } from '../../../services/newOrchestrationFormData';
import { useNavigate, useParams } from 'react-router-dom';
import createNewOrchestration from '../../../services/createNewOrchestration';
import ErrorNotify from '../../notify/ErrorNotify';
import Success from '../../notify/Success';
import { toast } from 'react-hot-toast';
import { clearFormState } from '../../common/form/slices/formSlice';

const steps = [
  { label: 'Orchestration Rule', component: OrchestrationFormRuleContainer },
  { label: 'Add Condition', component: OrchestrationAddCond },
  { label: 'Actions', component: OrchestrationActionsContainer }
];

const OrchestrationForm = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { app_code = '' } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [formState, setFormState] = useState({});

  const [sourceObjectsData, setSourceObjectsData] = useState();

  const handleNext = (data) => {
    setFormState((prevState) => ({
      ...prevState,
      ...data
    }));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const results = useQueries([
    {
      queryKey: ['dataObjects'],
      queryFn: getOrchestrationDataObjects({ appCode: app_code }),
      refetchOnWindowFocus: false
    }
  ]);

  const { data: dataObjectsData = {} } = results[0];
  const apiSuccess = results.every((result) => result.isSuccess);
  useEffect(() => {
    if (apiSuccess && dataObjectsData) {
      setSourceObjectsData(dataObjectsData);
    }
  }, [apiSuccess, dataObjectsData]);

  const orchestrationDataObject = sourceObjectsData?.body?.dropDownObjects;

  const getSourceObjectName = (id) => {
    const foundData = orchestrationDataObject.find((obj) => obj.dataObjectId === id);
    return foundData ? foundData.dataObjectName : null;
  };

  const formStore = useSelector((store) => store?.store?.form);
  const formStoreData = formStore?.formData;

  const getDataBasedOnName = ({ matchingName, data }) => {
    const keyNames = Object.keys(data).filter((element) => {
      return element.startsWith(matchingName);
    });
    const keyData = keyNames.map((element) => data[element]);

    return keyData;
  };
  const createActionsPayload = (inputData) => {
    return inputData.map((item) => {
      return {
        id: null,
        actionEnvironment: item['Recievers'],
        featureCode: item['Feature Code'],
        payload: item['Payload'],
        priority: Number(item['Priority']),
        extraHeaders: item['Extra Headers'],
        extraBody: item['Extra Body']
      };
    });
  };

  const mutation = useMutation(createNewOrchestration);

  const onSubmitForm = async () => {
    const payload = {};
    const orchestrationActions = formStoreData?.['Orchestration Actions'];
    const orchestrationRuleData = formStoreData?.['Orchestration Rule']?.['Orchestration-0'];
    const orchestrationConditionData = formStoreData?.['Orchestration Condition']?.['Conditions-0'];
    payload.applicationCode = app_code;
    payload.sourceCondition = orchestrationConditionData?.['Source Condition'];
    payload.requestCondition = orchestrationConditionData?.['Request Condition'];
    const actions = getDataBasedOnName({ matchingName: 'Actions-', data: orchestrationActions });
    const actionsData = createActionsPayload(actions);

    payload.orchestrationActions = actionsData;
    payload.sourceObjectId = orchestrationRuleData?.['Source Object'];
    payload.sourceObject = getSourceObjectName(payload.sourceObjectId);
    payload.sourceAction = orchestrationRuleData?.['Source Action'];

    try {
      const response = await mutation.mutateAsync(payload);
      if (response.body.status.code == 'SUCCESS') {
        toast.custom((t) => <Success t={t} message={'Orchestration creation successful'} />, {
          duration: 4000
        });
        dispatch(
          clearFormState({
            formKey: 'Orchestration Actions'
          })
        );
        dispatch(
          clearFormState({
            formKey: 'Orchestration Rule'
          })
        );
        dispatch(
          clearFormState({
            formKey: 'Orchestration Condition'
          })
        );

        navigate(`/apps/${app_code}/orchestration`);
      }
    } catch (error) {
      toast.custom((t) => <ErrorNotify t={t} message={'Orchestration creation failed'} />, {
        duration: 4000
      });
      console.log('Error:', error);
    }
  };

  const CurrentStepComponent = steps[activeStep].component;

  return (
    <div className="flex justify-center items-center'">
      <Box sx={{ width: '85%' }}>
        <Stepper activeStep={activeStep} className="mb-[40px]">
          {steps.map((data, index) => (
            <Step key={data.label} completed={index < activeStep}>
              <StepLabel>
                <h1 className="text-2xl">{data.label}</h1>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="w-[80%] justify-center m-auto">
          <CurrentStepComponent onNext={handleNext} formState={formState} />
        </div>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            className="!text-xl mr-[5%]"
            variant="contained"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}>
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            className="!text-xl mr-[5%]"
            variant="contained"
            onClick={activeStep === steps.length - 1 ? onSubmitForm : handleNext}>
            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default OrchestrationForm;
