import React from 'react';
import SubheaderContainer from '../modules/Subheader/container/SubheaderContainer';
import { useParams } from 'react-router-dom';

const TopSubHeader = ({ children, subheaderNavigationSchema }) => {
  const { app_code } = useParams();

  return (
    <div className="flex-1 flex flex-col bg-[#F4F3FA] pt-4">
      <div className="ml-8 font-semibold mt-4 text-[36px]">{app_code}</div>
      <SubheaderContainer schema={subheaderNavigationSchema} />
      <div className="flex-1 pt-4 flex">{children}</div>
    </div>
  );
};

export default TopSubHeader;
