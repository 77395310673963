import request from 'superagent';
import { fetchTargetObjectListDataUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const fetchTargetObjectListData = async ({ app_code, type }) => {
  const res = await request
      .get(fetchTargetObjectListDataUrl)
      .query({ appCode: app_code, type: type })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
};

export default fetchTargetObjectListData;
