import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import deleteApplication from '../../../services/deleteApplication';
import updateApplication from '../../../services/updateApplication';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import ApplicationCards from '../component/ApplicationCards';
import {
  updateAddUserData,
  updateApplicationState,
  updateEditFormData
} from '../../dashboard/slices/applicationSlice';
import GenericModalContainer from '../../modals/genericModal/container';
import EditApplicationFormContainer from '../../dashboard/container/EditApplicationFormContainer';
import UserFormContainer from '../../dashboard/container/UserFormContainer';
import FileStatus from '../../common/FileStatus';
import keycloak from '../../../Keycloak';
import request from 'superagent';
import DeleteAppModalContainer from '../../dashboard/container/DeleteAppModalContainer';
import { toast } from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';
import FormV4Poc from '../../common/formV4Poc/FormV4Poc';
import { clearFormState } from '../../common/form/slices/formSlice';
import formKeys from '../../common/form/constants/formKeys';

const ApplicationCardsContainer = ({ openDashboardGenericModal, isLoading, applicationData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mutation = useMutation(deleteApplication);
  const updateAppMutation = useMutation(updateApplication);

  const [exportAppBaseData, setExportAppBaseData] = useState({});

  const onMoreInfoClick = ({ code }) => {
    navigate(`/apps/${code}/features`);
  };

  const onDeleteClick = async (applicationId, name) => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.deleteAppModal,
        isOpen: true,
        modalDataKey: 'deleteAppModalData',
        modalData: { id: applicationId, name: name }
      })
    );
  };

  const onEditClick = (name, baseApplication, code, description, id) => {
    dispatch(
      clearFormState({
        formKey: formKeys.editApplicationForm,
        formType: 'application'
      })
    );
    dispatch(
      updateEditFormData({
        formData: { code, baseApplication, id, name, description }
      })
    );
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.editModal,
        isOpen: true
      })
    );
  };

  const onAddUserClick = (code) => {
    dispatch(
      updateAddUserData({
        formData: { code }
      })
    );
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.addUserModal,
        isOpen: true
      })
    );
  };

  const onTraceClick = (code) => {
    navigate(`/trace/${code}`);
  };

  const onExportClick = async (code, name, baseApplication, description, id) => {
    setExportAppBaseData({
      code, name, baseApplication, description, id
    });
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.exportApplicationModal,
        isOpen: true
      })
    );
  };

  const onUpdateClick = async (code) => {
    dispatch(
      updateApplicationState({
        applicationState: 'pending'
      })
    );
    const updateApplicationResult = await updateAppMutation.mutateAsync(code);

    if (updateApplicationResult?.body?.status?.code == 'SUCCESS') {
      dispatch(
        updateApplicationState({
          applicationState: 'success'
        })
      );
      toast.custom((t) => <Success t={t} message={'Application Deleted SuccessFully'} />, {
        duration: 4000
      });
    } else {
      dispatch(
        updateApplicationState({
          applicationState: 'failure'
        })
      );
      toast.custom((t) => <ErrorNotify t={t} message={'Error while updating the application'} />, {
        duration: 4000
      });
    }
  };

  const onFileStatusClick = () => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.fileStatus,
        isOpen: true
      })
    );
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('import_file', selectedFile);

      try {
        const formResponse = await request
          .post(`${process.env.REACT_APP_CONFIG_API}/file/import`)
          .send(formData)
          .set('Authorization', `Bearer ${keycloak.token()}`);

        if (formResponse.body.status.code == 'SUCCESS') {
          alert(formResponse.body.status.message);
        } else {
          console.error('File upload failed');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      console.log('No file selected.');
    }
  };

  return (
    <>
      <ApplicationCards
        application={applicationData}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
        onUpdateClick={onUpdateClick}
        onAddUserClick={onAddUserClick}
        onMoreInfoClick={onMoreInfoClick}
        onFileStatusClick={onFileStatusClick}
        openDashboardGenericModal={openDashboardGenericModal}
        handleUpload={handleUpload}
        handleFileChange={handleFileChange}
        onTraceClick={onTraceClick}
        onExportClick={onExportClick}
      />
      <GenericModalContainer modalKey={ModalKeys.editModal}>
        <EditApplicationFormContainer isModal={true} modalKey={ModalKeys.editModal} />
      </GenericModalContainer>
      <GenericModalContainer modalKey={ModalKeys.addUserModal}>
        <UserFormContainer isModal={true} modalKey={ModalKeys.addUserModal} />
      </GenericModalContainer>
      <GenericModalContainer modalKey={ModalKeys.fileStatus}>
        <FileStatus isModal={true} modalKey={ModalKeys.fileStatus} />
      </GenericModalContainer>
      <GenericModalContainer modalKey={ModalKeys.deleteAppModal}>
        <DeleteAppModalContainer />
      </GenericModalContainer>
      <GenericModalContainer modalKey={ModalKeys.exportApplicationModal}>
        <FormV4Poc exportAppBaseData={exportAppBaseData} />
      </GenericModalContainer>
    </>
  );
};

export default ApplicationCardsContainer;
