import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrchestrationDataAction } from '../slices/applicationSlice';
import { useParams } from 'react-router-dom';
import fetchOrchestrationData from '../../../services/fetchOrchestrationData';
import OrchestrationGrid from '../component/OrchestrationGrid';

const OrchestrationGridContainer = ({ deleteOrchestrationClick }) => {
  const dispatch = useDispatch();
  const { app_code = '' } = useParams();
  const { refetch } = useQuery(
    'orchestration-data',
    fetchOrchestrationData({ appCode: app_code }),
    {
      enabled: false
    }
  );
  const storeOrchestrationData = useSelector(
    (store) => store.store.moreApplication.orchestrationData
  );

  const fetchOrchestrationDatas = async () => {
    const refetchData = await refetch();
    const { data, isSuccess } = refetchData;
    if (isSuccess) {
      console.log(data.body);
      dispatch(
        fetchOrchestrationDataAction({ orchestrationData: data?.body?.orchestrationObjects })
      );
    }
    return data?.body?.orchestrationObjects;
  };

  return (
    <OrchestrationGrid
      fetchOrchestrationData={fetchOrchestrationDatas}
      orchestrationData={storeOrchestrationData}
      deleteOrchestrationClick={deleteOrchestrationClick}
    />
  );
};

export default OrchestrationGridContainer;
