import * as React from 'react';
import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
import { Menu, MenuItem, Typography } from '@material-ui/core';

import Fade from '@mui/material/Fade';
import { ListItemIcon, ListItemText } from '@mui/material';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import NestedMenuItem from 'material-ui-nested-menu-item';

const MenuComponent = ({ menuData }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="!cursor-pointer">
        <ViewCompactIcon fontSize="small" />
      </div>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}>
        {menuData.map((element) => {
          if (element.nested) {
            return (
              <NestedMenuItem parentMenuOpen={true} label={element.name}>
                {element.nestedItems.map((nestedData) => {
                  return (
                    <MenuItem onClick={nestedData.callback}>
                      <ListItemIcon className='!min-w-[20px] !mr-2'>{nestedData.icon}</ListItemIcon>
                      <ListItemText>{nestedData.name}</ListItemText>
                    </MenuItem>
                  );
                })}
              </NestedMenuItem>
            );
          } else {
            return (
              <>
                <MenuItem onClick={element.callback}>
                  <ListItemIcon className='!min-w-[20px] !mr-2'>{element.icon}</ListItemIcon>
                  <ListItemText>{element.name}</ListItemText>
                </MenuItem>
              </>
            );
          }
        })}
      </Menu>
    </div>
  );
};

export default MenuComponent;
