import request from 'superagent';
import keycloak from '../Keycloak';
import { fetchNestedDataForMapURL } from '../modules/dashboard/constants/applicationRequest';

const fetcheNestedDataByCode = async ({ appcode,code }) => {
    console.log("app code on the way....",appcode)
  const res = await request
    .get(fetchNestedDataForMapURL)
    .query({ code: code,appCode:appcode })
    .set('Authorization', `Bearer ${keycloak.token()}`);

  return res;
};

export default fetcheNestedDataByCode;
