import React, { useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';

const CustomInputNode = ({ data }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div style={{ width: '150px' }}>
      <Handle
        type="source"
        position={Position.Right}
        style={{ background: '#555', borderRadius: '50%', width: '250px' }}
        onConnect={(params) => console.log('handle onConnect', params)}
      >
      <input 
        placeholder='Enter Value'
        value={inputValue} 
        onChange={handleInputChange} 
        style={{ width: '100%' }} 
      />
      </Handle>
      {data.label}
    </div>
  );
};

export default CustomInputNode;
