import { noop } from 'lodash';
import FormContainer from '../../common/form/container';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import formKeys from '../../common/form/constants/formKeys';
import {
  clearFormState,
  updateFormData,
  updateFormError,
  updateFormState
} from '../../common/form/slices/formSlice';
import editApplicationSchema from '../../../schema/form_schema/editApplicationSchema';
import editApp from '../../../services/editApp';

const EditApplicationFormContainer = ({ onCloseModal = noop, isModal = false, modalKey = '' }) => {
  const mutation = useMutation(editApp);
  const editFormData = useSelector((store) => store.store.application.editFormData);
  const { code, baseApplication, id, name, description } = editFormData;
  const schema = editApplicationSchema(code, name, description, baseApplication);

  const dispatch = useDispatch();

  const formStore = useSelector((store) => store.store.form[formKeys.editApplicationForm]) || {};

  const formData = formStore.formData || {};

  const formError = formStore.errorState || {};

  const formState = formStore.formState || 'stale';

  const handleChange = (event) => {
    if (formData.formState !== 'stale') {
      dispatch(
        updateFormState({
          formKey: formKeys.editApplicationForm,
          formState: 'stale'
        })
      );
    }

    const { name, value } = event.target;
    const fieldValue = value;
    dispatch(
      updateFormData({
        formKey: formKeys.editApplicationForm,
        formField: name,
        formData: fieldValue
      })
    );
  };

  const submitForm = async (formData) => {
    const payload = {
      code: code,
      name: formData?.Name?.value || name,
      description: formData?.Description?.value || description,
      baseCode: baseApplication
    };

    const query = { id: id };

    try {
      dispatch(
        updateFormState({
          formKey: formKeys.editApplicationForm,
          formState: 'pending'
        })
      );
      const response = await mutation.mutateAsync({ query: query, payload: payload });
      if (response.body.status.code == 'SUCCESS') {
        dispatch(
          updateFormState({
            formKey: formKeys.editApplicationForm,
            formState: 'success'
          })
        );
        onCloseModal();
        dispatch(
          clearFormState({
            formKey: formKeys.editApplicationForm
          })
        );
      } else {
        dispatch(
          updateFormState({
            formKey: formKeys.editApplicationForm,
            formState: 'failed'
          })
        );
        dispatch(
          updateFormError({
            formKey: formKeys.editApplicationForm,
            errorMessage: response.body.status.message
          })
        );
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (
    <FormContainer
      isModal={isModal}
      modalKey={modalKey}
      handleChange={handleChange}
      submitForm={submitForm}
      schema={schema}
      formData={formData}
      formError={formError}
      formKey={formKeys.editApplicationForm}
      formState={formState}
    />
  );
};

export default EditApplicationFormContainer;
