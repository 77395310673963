import React from 'react';
import ApplicationTilesContainer from '../container/ApplicationTiles';
import OrchestrationGridContainer from '../container/OrchestrationGridContainer';
import SideBar from '../../ApplicationV2/component/SideBar';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import DeleteOrchestrationModalContainer from '../container/DeleteOrchestrationModalContainer';
import { useNavigate, useParams } from 'react-router-dom';
import GenericModalContainer from '../../modals/genericModal/container';
import TopCardSubheader from '../../../layouts/TopCardSubheader';
import getApplicationTopNavigationSchema from '../../../utils/navigationUtils/getApplicationTopNavigationSchema';
import fetchApplicationCount from '../../../services/fetchApplicationCount';
import formatFeatureCountData from '../../../utils/formatFeatureCountData';
import getExtensionSubheaderSchema from '../../../utils/navigationUtils/getExtensionSubheaderSchema';

const Orchestration = ({ component, deleteOrchestrationClick }) => {
  const navigate = useNavigate();

  const { app_code = '' } = useParams();

  var btnTxt = '';
  if (component === 'table') {
    btnTxt = 'New Orchestration';
  } else {
    btnTxt = 'List';
  }
  const onMenuItemClick = ({ title }) => {
    navigate(`/apps/${app_code}/orchestration`);
    switch (title) {
      case 'New Orchestration': {
        navigate(`/apps/${app_code}/orchestration/new`);
        break;
      }
      case 'Orchestration': {
        navigate(`/apps/${app_code}/orchestration`);
        break;
      }
    }
  };
  return (
    <div className="flex">
      <SideBar
        page={'orchestration'}
        onMenuItemClick={onMenuItemClick}
        currentContext={{ orchestration: 'Orchestration' }}
      />
      <div className="flex-1 flex flex-col bg-[#F4F3FA]">
        <TopCardSubheader
          topCardNavigationSchema={getApplicationTopNavigationSchema({ orchestrations: true })}
          getCountService={fetchApplicationCount(app_code)}
          formatCountData={formatFeatureCountData}
          subheaderNavigationSchema={getExtensionSubheaderSchema({ application: true })}>
          <OrchestrationGridContainer deleteOrchestrationClick={deleteOrchestrationClick} />
        </TopCardSubheader>
      </div>
      <GenericModalContainer modalKey={ModalKeys.deleteOrchestrationModal}>
        <DeleteOrchestrationModalContainer />
      </GenericModalContainer>
    </div>
  );
};

export default Orchestration;
