import { CircularProgress } from '@mui/material';
import { noop } from 'lodash';
import React from 'react';

const DeleteFeatureModal = ({
  onCloseModal = noop,
  onDeleteClick = noop,
  showModalLoader = false,
  modalHeader,
  modalData
}) => {
  return (
    <div className="relative z-10" aria-labelledby="modal-title">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div classNameName="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg min-w-[700px]">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-[64px] w-[64px] flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg
                    className="h-10 w-10 text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-2xl font-bold leading-6 text-gray-900" id="modal-title">
                    {modalHeader} {modalData?.name || ''}
                  </h3>
                  <div className="mt-2">
                    <p className="text-2xl font-bold text-gray-500">Are you sure?</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 flex sm:flex sm:flex-row-reverse sm:px-6">
              <div className="w-full">
                <button
                  onClick={onCloseModal}
                  className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-l font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">
                  Cancel
                </button>
              </div>
              <div className="w-full">
                <button
                  onClick={onDeleteClick}
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-l font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">
                  {showModalLoader ? <CircularProgress color="peace" size={24} /> : <h1>Delete</h1>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteFeatureModal;
