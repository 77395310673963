import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setObjectMappingAuditLogGridData } from '../slices/AuditLogSlice';
import AuditLogObjectMappingGrid from '../components/AuditLogObjectMappingGrid';
import { fetchObjectAuditLogData } from '../../../services/auditLogData';
import { noop } from 'lodash';
import { useParams } from 'react-router-dom';

const ObjectMappingAuditLogGridContainer = ({
  onDigitIdClick = noop,
  onObjectMappingClick = noop
}) => {
  const dispatch = useDispatch();
  const { app_code = '' } = useParams();
  const storeObjectMappingTimeFilterData =
    useSelector((store) => store?.store?.auditLog?.objectMappingTimeFilter) || {};
  const {
    startDate = '',
    startTime = '',
    endDate = '',
    endTime
  } = storeObjectMappingTimeFilterData;
  function combineDateAndTime(dateInput = '', timeInput = '') {
    const combinedDateTime = `${dateInput}T${timeInput}:00.000Z`;
    if (dateInput && timeInput) {
      return new Date(combinedDateTime).toISOString();
    }
    return '';
  }
  const starting = combineDateAndTime(startDate, startTime);
  const ending = combineDateAndTime(endDate, endTime);
  const { refetch } = useQuery(
    'object-mapping-audit-data',
    fetchObjectAuditLogData({
      type: 'creation',
      app_code: app_code,
      startDate: starting,
      endDate: ending,
      subType: 'objectMapping'
    }),
    {
      enabled: false,
      refetchOnWindowFocus: true
    }
  );
  const storeObjectMappingAuditData = useSelector(
    (store) => store?.store?.auditLog?.objectMappingGridData
  );

  const fetchObjectMappingAuditData = async () => {
    const refetchData = await refetch();
    const { data, isSuccess } = refetchData;
    if (isSuccess) {
      dispatch(setObjectMappingAuditLogGridData({ auditData: data?.body?.data?.auditData }));
    }
    return data?.body?.featureObjects;
  };

  return (
    <AuditLogObjectMappingGrid
      fetchObjectMappingAuditData={fetchObjectMappingAuditData}
      objectMappingAuditData={storeObjectMappingAuditData}
      onDigitIdClick={onDigitIdClick}
      onObjectMappingClick={onObjectMappingClick}
    />
  );
};

export default ObjectMappingAuditLogGridContainer;
