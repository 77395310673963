import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import { noop } from 'lodash';
import toast from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';
import BlockHashModal from '../component/BlockHashModal';

const BlockHashModalContainer = ({ onCloseModal = noop }) => {
  const [blockHashData, setBlockHashData] = useState(null);
  const modalStore = useSelector((store) => store?.store?.genericModal?.modalKeys);
  const modalData = modalStore?.blockHashModalData;
  const { blockHash = '' } = modalData;
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await request
          .get(`${process.env.REACT_APP_CONFIG_API}/hlfExplorer/getBlockByBlockHash`)
          .query({ blockHash: blockHash })
          .set('Authorization', `Bearer ${keycloak.token()}`);

        console.log(res, 'oooooooooooooooooooo');
        if (res?.body?.status == 'success') {
          toast.custom((t) => <Success t={t} message={res?.body?.message} />, {
            duration: 4000
          });
          setBlockHashData(res?.body?.data);
        } else {
          toast.custom((t) => <ErrorNotify t={t} message={res?.body?.message} />, {
            duration: 4000
          });
          onCloseModal();
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [blockHash]);

  return blockHashData ? (
    <div className="bg-white">
      <BlockHashModal onCloseModal={onCloseModal} blockHashData={blockHashData} />
    </div>
  ) : null;
};

export default BlockHashModalContainer;
