import { isObject } from 'lodash';
import { toast } from 'react-hot-toast';
import ErrorNotify from '../modules/notify/ErrorNotify';

const leaveFields = {
  description: true,
  'Data Object Key Columns': true,
  'Attribute Keys': true,
  'Static Parameters': true,
  Description: true
};

function validateIfFormSubmitable({ formKeys, formData, leaveCurrent }) {
  return formKeys.every((key) => {
    const data = formData[key];
    if (isObject(data)) {
      const keys = Object.keys(data).filter((ele) => ele !== 'error');
      return validateIfFormSubmitable({ formKeys: keys, formData: data, leaveCurrent });
    } else {
      return data == '' && !(key in { ...leaveFields, ...leaveCurrent }) ? false : true;
    }
  });
}

function callValidateForm(data) {
  const isValidated = validateIfFormSubmitable(data);
  if (!isValidated) {
    toast.custom((t) => <ErrorNotify t={t} message="Please provide all form fields" />, {
      duration: 4000
    });
  }

  return isValidated;
}

export default callValidateForm;
