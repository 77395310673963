import { combineReducers } from '@reduxjs/toolkit';
import applicationReducer from '../modules/dashboard/slices/applicationSlice';
import dashboardReducer from '../modules/dashboard/slices/dashboardSlice';
import tableReducer from '../modules/tables/slices/tableSlice';
import genericModalReducer from '../modules/modals/slices/genericModalSlice';
import formReducer from '../modules/common/form/slices/formSlice';
import moreApplicationReducer from '../modules/Application/slices/applicationSlice';
import stepFormReducer from '../modules/stepForms/ladderStepForm/slice/stepFormSlice';
import hlfReducer from '../modules/HyperledgerFabricNetworks/slices/hlfSlice';
import connectionFrameworkReducer from '../modules/ConnectionFramework/slices/connectionFrameworkSlice';
import auditLogReducer from '../modules/auditLog/slices/AuditLogSlice';
import FlowReducer  from '../modules/Canvas/container/FlowSlice';
import OrgReducer from '../modules/Organisation/OragSlice';

export default combineReducers({
  application: applicationReducer,
  dashboard: dashboardReducer,
  tables: tableReducer,
  genericModal: genericModalReducer,
  form: formReducer,
  moreApplication: moreApplicationReducer,
  stepForm: stepFormReducer,
  hlf: hlfReducer,
  connectionFramework: connectionFrameworkReducer,
  auditLog: auditLogReducer,
  reactflow:FlowReducer,
  orgData:OrgReducer,
});
