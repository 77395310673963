import React from 'react';
import OrchestrationForm from '../components/OrchestrationForm';
import ApplicationTilesContainer from '../../Application/container/ApplicationTiles';
import SideBar from '../../ApplicationV2/component/SideBar';
import { useNavigate, useParams } from 'react-router-dom';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { noop } from 'lodash';
import ConformationContainer from '../../common/conformationModal/containers';
import { useDispatch, useSelector } from 'react-redux';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import TopCardSubheader from '../../../layouts/TopCardSubheader';
import getApplicationTopNavigationSchema from '../../../utils/navigationUtils/getApplicationTopNavigationSchema';
import fetchApplicationCount from '../../../services/fetchApplicationCount';
import formatFeatureCountData from '../../../utils/formatFeatureCountData';
import getExtensionSubheaderSchema from '../../../utils/navigationUtils/getExtensionSubheaderSchema';

const OrchestrationFormContainer = () => {
  const { app_code = '', action = '', orchestrationId = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formStore = useSelector((store) => store?.store?.form);

  const formStoreData = formStore.formData || {};

  const { isEdited } = formStoreData || {};

  const onMenuItemClick = ({ title }) => {
    switch (title) {
      case 'New Orchestration': {
        if (isEdited) {
          dispatch(
            genericModalStateUpdate({
              modalKey: ModalKeys.stopNewOrchestration,
              isOpen: true
            })
          );
        } else {
          navigate(`/apps/${app_code}/orchestration/new`);
        }
        break;
      }
      case 'Orchestration': {
        if (isEdited) {
          dispatch(
            genericModalStateUpdate({
              modalKey: ModalKeys.stopOrchestration,
              isOpen: true
            })
          );
        } else {
          navigate(`/apps/${app_code}/orchestration`);
        }
        break;
      }
    }
  };

  return (
    <div className="flex">
      <SideBar
        page={'orchestration'}
        onMenuItemClick={onMenuItemClick}
        currentContext={{ orchestration: 'New Orchestration' }}
      />
      <div className="flex-1 flex flex-col bg-[#F4F3FA]">
        <TopCardSubheader
          topCardNavigationSchema={getApplicationTopNavigationSchema({ orchestrations: true })}
          getCountService={fetchApplicationCount(app_code)}
          formatCountData={formatFeatureCountData}
          subheaderNavigationSchema={getExtensionSubheaderSchema({ application: true })}>
          <div className="mb-[60px]">
            {/* {isEdited ? (
            <ApplicationTilesContainer askNavigationCheck={true} />
          ) : (
            <ApplicationTilesContainer />
          )} */}
          </div>
          <div>
            <OrchestrationForm />
          </div>
        </TopCardSubheader>
      </div>
      <ConformationContainer
        modalId={ModalKeys.stopOrchestration}
        cancelObject={{ text: 'Cancel', callback: noop }}
        conformObject={{
          text: 'Discard Changes',
          callback: () => navigate(`/apps/${app_code}/orchestration`)
        }}
        modalText={{
          header: 'Discard Changes',
          desc: 'Your Data is Unsaved, do you really want to discard changes !'
        }}
      />
      <ConformationContainer
        modalId={ModalKeys.stopNewOrchestration}
        cancelObject={{ text: 'Cancel', callback: noop }}
        conformObject={{
          text: 'Discard Changes',
          callback: () => navigate(`/apps/${app_code}/orchestration/new`)
        }}
        modalText={{
          header: 'Discard Changes',
          desc: 'Your Data is Unsaved, do you really want to discard changes !'
        }}
      />
    </div>
  );
};

export default OrchestrationFormContainer;
