import React, { useState } from 'react';
import DeleteFeatureModal from '../component/DeleteFeatureModal';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import deleteDataObject from '../../../services/deleteDataObject';
import { toast } from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';

const DeleteDataObjectModalContainer = ({ onCloseModal = noop }) => {
  const { app_code = '' } = useParams();
  const mutation = useMutation(deleteDataObject);

  const [showModalLoader, setShowModalLoader] = useState();
  const modalStore = useSelector((store) => store.store.genericModal);
  const modalData = modalStore?.modalKeys?.deleteDataObjectModalData;

  const onDeleteDataObjectClick = async () => {
    const deleteResult = await mutation.mutateAsync({ id: modalData.id, appCode: app_code });
    setShowModalLoader(true);
    if (deleteResult?.body?.status?.code == 'SUCCESS') {
      setShowModalLoader(false);
      onCloseModal();
      toast.custom((t) => <Success t={t} message={deleteResult?.body?.status?.message} />, {
        duration: 4000
      });
      setTimeout(window.location.reload(), 2000);
    } else {
      toast.custom((t) => <ErrorNotify t={t} message={deleteResult?.body?.status?.message} />, {
        duration: 4000
      });
    }
  };

  return (
    <DeleteFeatureModal
      showModalLoader={showModalLoader}
      onCloseModal={onCloseModal}
      onDeleteClick={onDeleteDataObjectClick}
      modalHeader="Delete Object"
      modalData={modalData}
    />
  );
};

export default DeleteDataObjectModalContainer;
