import React from 'react';
import { Handle, Position } from 'reactflow';
import { range } from 'lodash';

const LeftNode = ({ data }) => {
  const pointStart = 50 - parseInt((data.handles * 8) / 2);
  return (
    <>
      <div
        id={data.id}
        style={{
          backgroundColor: data.bg,
          borderTopLeftRadius: '9999px',
          borderBottomLeftRadius: '9999px'
        }}
        location={data.location}
        name={data.label}
        className="font-bold px-2 overflow-hidden w-[250px] min-h-[60px] flex items-center justify-center mb-2 bg-[#E4E4E4]">
        <div name={data.label} id={data.id} location={data.location} className="custom-node">
          <div
            className="overflow-hidden whitespace-nowrap text-ellipsis"
            name={data.label}
            id={data.id}
            location={data.location}>
            {data.label}
          </div>
        </div>
        {range(data.handles).map((element) => {
          return (
            <Handle
              type="source"
              style={{ top: `${pointStart + element * 8}%` }}
              position={Position.Right}
              isConnectable={true}
              id={`${element + 1}`}
            />
          );
        })}
      </div>
    </>
  );
};

export default LeftNode;
