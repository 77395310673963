import { noop } from 'lodash';
import React from 'react';
import TextField from '../../common/formV4Poc/TextField';

const HLFViewFileForm = ({ data }) => {
  return (
    <div>
      <div>
        <div className="text-[24px] mb-4 text-orange-500">Organizations</div>
        {Object.keys(data?.organizations || {}).map((element, index) => {
          return (
            <TextField disabled={true} onChange={noop} label={`Organization`} value={element} />
          );
        })}
      </div>
      <div className="text-[24px] mb-4 text-orange-500">Peers</div>
      {Object.keys(data?.peers || {}).map((element, index) => {
        return <TextField disabled={true} onChange={noop} label={`Peers`} value={element} />;
      })}
      <div className="text-[24px] mb-4 text-orange-500">Certificate Authorities</div>
      {Object.keys(data?.certificateAuthorities || {}).map((element, index) => {
        return (
          <TextField
            disabled={true}
            onChange={noop}
            label={`Certificate Authorities`}
            value={element}
          />
        );
      })}
    </div>
  );
};

export default HLFViewFileForm;
