import request from 'superagent';
import { fetchHLFListDataUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const fetchHLFListData = () => {
  return async () => {
    const res = await request
      .get(`${process.env.REACT_APP_CONFIG_API}/hlf`)
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

export default fetchHLFListData;
