import { Button, CircularProgress } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { noop } from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import ErrorNotify from '../../notify/ErrorNotify';
import Success from '../../notify/Success';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import ConformationContainer from '../../common/conformationModal/containers';
import { useDispatch } from 'react-redux';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';

const VariableGrid = ({ fetchVariableGridData = noop, variableData = [], title }) => {
  const { app_code } = useParams();
  const [currentData, setCurrentData] = useState({});

  const dispatch = useDispatch();
  const columnDefs = [
    { headerName: 'Variable Name', field: 'variableName', editable: true },
    { headerName: 'Variable Value', field: 'variableValue', editable: true },
    { headerName: 'Data Type', field: 'type', editable: true },
    {
      cellEditorPopup: true,
      editable: true,
      headerName: 'Is Static',
      field: 'isStatic',
      cellEditor: 'agCheckboxCellEditor',
      cellRendererParams: {
        disabled: true
      }
    },
    {
      headerName: 'Actions',
      field: 'Actions',
      cellRenderer: (params) => {
        let editingCells = params.api.getEditingCells();
        // checks if the rowIndex matches in at least one of the editing cells
        let isCurrentRowEditing = editingCells.some((cell) => {
          return cell.rowIndex === params.node.rowIndex;
        });

        return (
          <div className="flex gap-2">
            {!isCurrentRowEditing ? (
              <>
                <Button variant="contained" onClick={() => cellClicked(params, 'edit')}>
                  edit
                </Button>
                <Button
                  variant="contained"
                  className="!bg-red-500"
                  onClick={() => cellClicked(params, 'delete')}>
                  Delete
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  className="!bg-green-500"
                  onClick={() => cellClicked(params, 'update')}>
                  Update
                </Button>
                <Button
                  variant="contained"
                  className="!bg-red-500"
                  onClick={() => cellClicked(params, 'cancel')}>
                  Cancel
                </Button>
              </>
            )}
          </div>
        );
      }
    }
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function onShowLoading() {
    gridOptions.api.showLoadingOverlay();
  }

  function onHide() {
    gridOptions.api.hideOverlay();
  }

  let gridApi;

  const CustomLoader = CircularProgress;

  const gridOptions = {
    suppressClickEdit: true,
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };

  const rowData = variableData.map((feature, index) => {
    return {
      variableName: feature.name,
      variableValue: feature.value,
      isStatic: feature.static,
      type: feature.type
    };
  });

  async function onGridReady(params) {
    gridApi = params?.api;
    params?.api?.showLoadingOverlay();
    const fetchData = await fetchVariableGridData();
  }

  const onExportClick = () => {
    gridApi = params?.api;
    gridApi?.exportDataAsCsv();
  };

  async function deleteHandler() {
    const deleteResult = await request
      .post(`${process.env.REACT_APP_CONFIG_API}/type/connectors/globalvariables/delete`)
      .query({ appCode: app_code })
      .send({ name: currentData.variableName })
      .set('Authorization', `Bearer ${keycloak.token()}`);
    await fetchVariableGridData();
    if (deleteResult?.body?.status.code == 'SUCCESS') {
      toast.custom(
        (t) => (
          <Success
            t={t}
            message={deleteResult?.body?.status?.message || 'Connection Deleted Successfully'}
          />
        ),
        {
          duration: 4000
        }
      );
    } else {
      toast.custom(
        (t) => (
          <ErrorNotify
            t={t}
            message={deleteResult?.body?.status?.message || 'Error While Deleting Connection'}
          />
        ),
        {
          duration: 4000
        }
      );
    }
  }

  async function cellClicked(param, field) {
    const { data } = param;
    switch (field) {
      case 'edit': {
        param.api.startEditingCell({
          rowIndex: param.rowIndex,
          // gets the first columnKey
          colKey: 'variableValue'
        });

        param.api.startEditingCell({
          rowIndex: param.rowIndex,
          // gets the first columnKey
          colKey: 'isStatic'
        });
        param.api.refreshCells({
          columns: ['Actions'],
          rowNodes: [param.node],
          force: true
        });
        break;
      }

      case 'update': {
        param.api.stopEditing(false);
        param.api.refreshCells({
          columns: ['Actions'],
          rowNodes: [param.node],
          force: true
        });

        const updateResult = await request
          .post(`${process.env.REACT_APP_CONFIG_API}/type/connectors/globalvariables/update`)
          .query({ appCode: app_code })
          .send({
            name: data.variableName,
            value: data.variableValue,
            static: data.isStatic,
            type: data.type
          })
          .set('Authorization', `Bearer ${keycloak.token()}`);

        if (updateResult?.body?.status.code == 'SUCCESS') {
          toast.custom((t) => <Success t={t} message={updateResult?.body?.status?.message} />, {
            duration: 4000
          });
        } else {
          toast.custom((t) => <ErrorNotify t={t} message={updateResult?.body?.status?.message} />, {
            duration: 4000
          });
        }

        await fetchVariableGridData();

        break;
      }
      case 'cancel': {
        param.api.stopEditing(true);
        param.api.refreshCells({
          columns: ['Actions'],
          rowNodes: [param.node],
          force: true
        });
        break;
      }
      case 'status': {
        const statusResult = await request
          .post(`${process.env.REACT_APP_CONFIG_API}/type/connectors/connections/statusUpdate`)
          .send({ connectorId: data.ConnectorId })
          .set('Authorization', `Bearer ${keycloak.token()}`);
        await fetchVariableGridData();

        if (statusResult?.body?.Status == 'Success') {
          toast.custom((t) => <Success t={t} message={statusResult?.body?.Message} />, {
            duration: 4000
          });
        } else {
          toast.custom((t) => <ErrorNotify t={t} message={statusResult?.body?.Message} />, {
            duration: 4000
          });
        }
        break;
      }
      case 'delete': {
        setCurrentData(data);
        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.deleteVariable,
            isOpen: true
          })
        );
      }
    }
  }

  return (
    <div className="ag-theme-alpine flex-1 px-[24px] p-[20px] mx-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      <div className="text-[24px]">{title}</div>
      <div className="flex-1">
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          rowData={rowData}
          suppressCellFocus={true}
        />
        <ConformationContainer
          modalId={ModalKeys.deleteVariable}
          cancelObject={{ text: 'Cancel', callback: noop }}
          conformObject={{
            text: 'Delete',
            callback: deleteHandler
          }}
          modalText={{
            header: 'Delete Variable',
            desc: `Do you really want to delete ${currentData.variableName} variable ?`
          }}
        />
      </div>
    </div>
  );
};

export default VariableGrid;
