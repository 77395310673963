import { createSlice } from '@reduxjs/toolkit';
import applicationTypes from '../../../constants/applicationTypes';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    currentContext: { Application: applicationTypes.features, Dashboard: 'Applications' },
    subHeader: { current: 'application' }
  },
  reducers: {
    dashboardContext: (state, action) => {
      state.currentContext[action.payload.contextName] = action.payload.context;
    },
    subHeaderContext: (state, action) => {
      state.subHeader.current = action.payload.current;
    }
  }
});

export const { dashboardContext, subHeaderContext } = dashboardSlice.actions;

export default dashboardSlice.reducer;
