import { noop } from 'lodash';
import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import toast from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';

const ConnectionLogProgress = ({
  featureListData = {},
  isRecord = false,
  recordsData = [],
  onRecordStepClick = noop
}) => {
  const [showDialogBox, setShowDialogBox] = useState(false);
  const modalData = useSelector((store) => store?.store?.genericModal?.modalKeys?.recordData);
  const logInfo = modalData?.[0]?.message;
  const logType = modalData?.[0]?.type;
  const steps = featureListData?.body?.result?.stages;
  const stepStatus = featureListData?.body?.result?.response;

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(logInfo, null, 2));
      toast.custom((t) => <Success t={t} message={'Json Copied'} />, {
        duration: 4000
      });
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
      toast.custom((t) => <ErrorNotify t={t} message={'Unable to copy to clipboard'} />, {
        duration: 4000
      });
    }
  };

  function findValueByKey(obj, targetKey) {
    let result;
  
    function searchObject(obj, key) {
      for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          if (prop === key) {
            result = obj[prop];
            return;
          } else if (typeof obj[prop] === 'object') {
            searchObject(obj[prop], key);
          }
        }
      }
    }
  
    searchObject(obj, targetKey);
    return result;
  }

  var steps2;
  if (recordsData) {
    steps2 = recordsData;
  }
  return !isRecord ? (
    <div className="">
      {steps &&
        steps.map((step, index) => {
          return (
            <div className="relative group flex">
              <div className="flex flex-col items-center">
                <div
                  className={`relative step flex flex-col rounded-full items-center justify-center w-8 h-8 ${
                    stepStatus?.[step]?.state == 'completed'
                      ? 'bg-green-500'
                      : stepStatus?.[step]?.state == 'partial'
                      ? 'bg-blue-500'
                      : 'bg-red-500'
                  }`}>
                  {stepStatus?.[step]?.state === 'completed' ? (
                    <span className="icon text-white">&#10003;</span>
                  ) : (
                    <span className="icon text-white">&#10005;</span>
                  )}
                </div>
                {index < steps.length - 1 && (
                  <div className="h-10 bg-gray-500 w-[2px] mx-auto my-[4px]"></div>
                )}
              </div>
              <div>
                <span className="step-label text-center text-lg mt-2 ml-2 cursor-pointer">
                  {step.toUpperCase()}
                </span>
              </div>
              {stepStatus?.[step] ? (
                <div className="tooltip absolute left-10 top-14 bg-gray-700 text-white p-2 rounded-md -mt-8 opacity-0 group-hover:opacity-100 transition-opacity z-10">
                  {Object.keys(stepStatus?.[step]?.message).map((item) => (
                    <div className="text-lg w-full">
                      {item}:{stepStatus?.[step]?.message?.[item]}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="tooltip absolute left-10 top-14 bg-gray-700 text-white p-2 rounded-md -mt-8 opacity-0 group-hover:opacity-100 transition-opacity z-10">
                  <div className="text-lg">Failed</div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  ) : (
    recordsData && (
      <div>
        <div className="mt-3">
          {steps2 &&
            steps2.map((step, index) => (
              <div className="flex" key={index}>
                <div className="step flex flex-col items-center">
                  <div
                    className={`w-8 h-8 flex items-center justify-center rounded-full m-auto bg-green-500`}>
                    <span className="icon text-white">&#10003;</span>
                  </div>
                  {index < steps2.length - 1 && (
                    <div className="h-10 bg-gray-500 w-[2px] mx-auto my-[4px]"></div>
                  )}
                </div>
                <div>
                  <span
                    className="step-label text-center mt-2 cursor-pointer ml-2 text-lg"
                    onClick={() => {
                      onRecordStepClick(step.type);
                      setShowDialogBox(true);
                    }}>
                    {step.type.toUpperCase()}
                  </span>
                </div>
              </div>
            ))}
        </div>
        {showDialogBox && logInfo && (
          <div className="fixed inset-0 flex items-center justify-center">
            <div className="bg-white p-4 rounded-lg shadow-lg min-w-[500px] h-[500px] flex flex-col">
              <div className="flex justify-between pb-4 border-b-2">
                <div className="flex items-center justify-center">
                  <h1 className='text-xl font-bold ml-10 mr-5'>{String(logType).toUpperCase()}</h1>
                  <h1 className='text-xl'>(<span className='font-bold'>Record ID</span> - {findValueByKey(logInfo,'recordId')})</h1>
                </div>
                <div>
                  <button
                    className="bg-blue-500 text-white py-2 px-4 rounded-md mr-6"
                    onClick={handleCopyClick}>
                    Copy JSON
                  </button>
                  <button onClick={() => setShowDialogBox(false)}>
                    <CloseIcon fontSize="large" />
                  </button>
                </div>
              </div>
              <div className="overflow-auto">
                <div className="p-6">
                  <ReactJson
                    enableClipboard={false}
                    src={logInfo}
                    style={{ fontSize: '18px', display: 'block' }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default ConnectionLogProgress;
