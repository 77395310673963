import { createSlice } from "@reduxjs/toolkit";

const OrgData = createSlice({
    name:'Orgdata',
    initialState:{data:[]},
    pushOrgLetails:(state,action)=>{
        let dataOrg = action.paylod;
        if(state.data.length==0){
        state.data.push(dataOrg)
        }
    }
})

export const {pushOrgLetails} = OrgData.actions;
export default OrgData.reducer;
