import React, { useState } from 'react';
import TextField from './TextField';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { useDispatch } from 'react-redux';
import keycloak from '../../../Keycloak';
import { toast } from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';
import request from 'superagent';

const FormV4Poc = ({ exportAppBaseData = {} }) => {
  const dispatch = useDispatch();

  const { code, name, description, baseApplication } = exportAppBaseData;

  const [formData, setFormData] = useState({
    'Base Application': code,
    Name: '',
    Description: description,
    Code: '',
    'Export Format': 'RealApp'
  });

  const onChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const items = [
    {
      label: 'Base Application',
      value: formData['Base Application'],
      disabled: true
    },
    {
      label: 'Name',
      value: formData['Name']
    },
    {
      label: 'Code',
      value: formData['Code']
    },
    {
      label: 'Description',
      value: formData['Description']
    },
    {
      label: 'Export Format',
      value: formData['Export Format'],
      type: 'dropdown',
      options: [
        {
          name: 'RealApp',
          value: 'RealApp'
        },
        {
          name: 'OpenAPI',
          value: 'OpenAPI'
        }
      ]
    }
  ];

  const closeModal = () => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.exportApplicationModal,
        isOpen: false
      })
    );
  };

  const submitExportApplication = async () => {
    const response = await request
      .post(`${process.env.REACT_APP_CONFIG_API}/file/export`)
      .send({
        appCode: formData['Code'],
        baseCode: formData['Base Application'],
        name: formData['Name'],
        description: formData['Description'],
        format: formData['Export Format']
      })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    if (response.body.status.code == 'SUCCESS') {
      toast.custom((t) => <Success t={t} message={'Application Exported Succesfully'} />, {
        duration: 4000
      });
      closeModal();
    } else {
      toast.custom((t) => <ErrorNotify t={t} message={'Application Export Failed'} />, {
        duration: 4000
      });
    }
  };

  return (
    <div className="bg-white w-[800px] m-auto relative top-[200px] p-[40px] rounded-[20px] shadow-lg">
      <div
        onClick={closeModal}
        className="absolute top-[10px] right-[20px] text-[24px] cursor-pointer">
        X
      </div>
      <div className="text-[24px] font-semibold">Export Application</div>
      <div className="mt-10 flex flex-wrap justify-between">
        {items?.map((element) => {
          switch (element.type) {
            case 'dropdown': {
              return (
                <FormControl className="w-[45%]">
                  <InputLabel>{element.label}</InputLabel>
                  <Select
                    name={element.label}
                    label={element.label}
                    value={element.value}
                    disabled={element.disabled}
                    onChange={onChange}
                    autoWidth>
                    {element.options.map((option) => {
                      const { name, value } = option || {};
                      return (
                        <MenuItem key={name} value={value}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              );
            }
            default: {
              return (
                <div className="w-[45%]">
                  <TextField
                    disabled={element.disabled}
                    onChange={onChange}
                    label={element.label}
                    value={element.value}
                    defaultValue={element.defaultValue || ''}
                  />
                </div>
              );
            }
          }
        })}
      </div>
      <div className="m-auto w-[80%] mt-8 flex justify-around">
        <Button
          onClick={closeModal}
          variant="outlined"
          className="!h-[56px] !rounded-[10px] !min-w-[180px]">
          Cancel
        </Button>
        <Button
          onClick={submitExportApplication}
          variant="contained"
          className="!bg-[#5570F1] !shadow-none !h-[56px] !rounded-[10px] !min-w-[180px]">
          Export Application
        </Button>
      </div>
    </div>
  );
};

export default FormV4Poc;
