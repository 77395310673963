function formatFetchStateData(data) {
  console.log(data, '1111111111');
  return {
    review: 10,
    rejected: 30,
    approved: 20
  };
}

export default formatFetchStateData;
