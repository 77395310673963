import React, { useEffect, useState } from 'react';
import AuditLogFilter from '../components/AuditLogFilter';
import keycloak from '../../../Keycloak';
import request from 'superagent';
import { useDispatch } from 'react-redux';
import {
  setObjectAuditLogGridData,
  setObjectAuditLogGridFilterData
} from '../slices/AuditLogSlice';
import { useParams } from 'react-router-dom';

const ObjectAuditFilterContainer = () => {
  const dispatch = useDispatch();

  const { app_code = '' } = useParams();

  const now = new Date();
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const currentTime = `${hours}:${minutes}`;

  const currentDate = new Date().toISOString().split('T')[0];

  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [startTime, setStartTime] = useState(currentTime);
  const [endTime, setEndTime] = useState(currentTime);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
  };

  useEffect(() => {
    dispatch(
      setObjectAuditLogGridFilterData({
        dateTimeFilter: {
          startDate: startDate,
          endDate: endDate,
          startTime: startTime,
          endTime: endTime
        }
      })
    );
  }, [
    JSON.stringify(startDate),
    JSON.stringify(endDate),
    JSON.stringify(startTime),
    JSON.stringify(endTime)
  ]);

  function combineDateAndTime(dateInput, timeInput) {
    const combinedDateTime = `${dateInput}T${timeInput}:00.000Z`;
    const isoDateTime = new Date(combinedDateTime).toISOString();

    return isoDateTime;
  }
  const clickFilter = async (startDate, startTime, endDate, endTime) => {
    const starting = combineDateAndTime(startDate, startTime);
    const ending = combineDateAndTime(endDate, endTime);
    const response = await request
      .get(`${process.env.REACT_APP_CONFIG_API}/auditLog/auditLogCreationTransaction`)
      .query({
        type: 'creation',
        app_code: app_code,
        startDate: starting,
        endDate: ending,
        subType: 'dataobject'
      })
      .set('Authorization', `Bearer ${keycloak.token()}`);
    dispatch(setObjectAuditLogGridData({ auditData: response?.body?.data?.auditData }));
  };
  return (
    <AuditLogFilter
      clickFilter={clickFilter}
      handleStartDateChange={handleStartDateChange}
      handleEndDateChange={handleEndDateChange}
      handleStartTimeChange={handleStartTimeChange}
      handleEndTimeChange={handleEndTimeChange}
      startDate={startDate}
      endDate={endDate}
      startTime={startTime}
      endTime={endTime}
    />
  );
};

export default ObjectAuditFilterContainer;
