import { useParams } from 'react-router-dom';
import {
  editOrchAddConditionSchema,
  orchAddConditionSchema,
  viewOrchAddConditionSchema
} from '../../../schema/form_schema/newOrchestrationFormSchema';
import FormV2Container from '../../common/formV2/container/FormV2Container';
import { noop } from 'lodash';

const OrchestrationAddCond = ({ orchestrationData }) => {
  const { action = '' } = useParams();

  var schema;

  if (action == 'edit') {
    schema = editOrchAddConditionSchema(orchestrationData);
  } else if (action == 'view') {
    schema = viewOrchAddConditionSchema(orchestrationData);
  } else {
    schema = orchAddConditionSchema();
  }

  return action === 'edit' || action === 'view' ? (
    <FormV2Container
      className={'bg-white'}
      restrictRerender={false}
      variant="formV3"
      schema={schema}
      onSubmitForm={noop}
      disableSubmit={true}
    />
  ) : (
    <FormV2Container
      variant="formV3"
      className={'bg-white'}
      restrictRerender={true}
      schema={schema}
      onSubmitForm={noop}
      disableSubmit={true}
    />
  );
};

export default OrchestrationAddCond;
