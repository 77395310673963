import request from 'superagent';
import keycloak from '../Keycloak';
import { connectionLogInfoUrl, connectionLogUrl } from '../modules/dashboard/constants/applicationRequest';

const getConnectionLogs = ({ appCode = '' }) => {
  return async () => {
    const res = await request
      .get(connectionLogUrl)
      .query({ code: appCode })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

const getConnectionLogsInfo = (connectorLogId) => {
  console.log('here3')
  return async () => {
    const res = await request
      .post(connectionLogInfoUrl)
      .send({ connectorLogId: connectorLogId })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

const getConnectionLogsRecordsInfo = (connectorLogId) => {
  console.log('here3')
  return async () => {
    const res = await request
      .post(connectionLogInfoUrl)
      .send({ connectorLogId: connectorLogId })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

export { getConnectionLogs, getConnectionLogsInfo, getConnectionLogsRecordsInfo };
