const getFieldData = ({ path, data }) => {
  if (path.length == 0) {
    return '';
  }

  if (path.length == 1) {
    return data[path[0]];
  }

  return getFieldData({ path: path.slice(1), data: data[path[0]] || {} });
};

export default getFieldData;
