import React, { useState } from 'react';
import DeleteFeatureModal from '../component/DeleteFeatureModal';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import deleteOrchestration from '../../../services/deleteOrchestration';
import { toast } from 'react-hot-toast';
import Success from '../../notify/Success';

const DeleteOrchestrationModalContainer = ({ onCloseModal = noop }) => {
  const { app_code = '' } = useParams();
  const mutation = useMutation(deleteOrchestration);

  const [showModalLoader, setShowModalLoader] = useState();
  const modalStore = useSelector((store) => store.store.genericModal);
  const modalData = modalStore?.modalKeys?.deleteOrchestrationModalData;

  const onDeleteOrchestrationClick = async () => {
    const deleteResult = await mutation.mutateAsync({ id: modalData.id, appCode: app_code });
    setShowModalLoader(true);
    if (deleteResult?.body?.status?.code == 'SUCCESS') {
      setShowModalLoader(false);
      onCloseModal();
      toast.custom((t) => <Success t={t} message={'Orchestration Deleted Successfully'} />, {
        duration: 4000
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      console.log('Feature Deletion failed');
    }
  };

  return (
    <DeleteFeatureModal
      showModalLoader={showModalLoader}
      onCloseModal={onCloseModal}
      onDeleteClick={onDeleteOrchestrationClick}
      modalHeader="Delete Orchestration"
      modalData={modalData}
    />
  );
};

export default DeleteOrchestrationModalContainer;
