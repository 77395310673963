import React, { useState } from 'react';
import RejectGrid from './RejectGrid';
import { useParams } from 'react-router-dom';
import request from 'superagent';
import keycloak from '../../../../Keycloak';

const ObjectRejected = ({ reviewType }) => {
  const { app_code } = useParams();
  const [rejectedData, setRejectedData] = useState([]);

  const fetchRejectedData = async function () {
    let requestUrl = '';

    switch (reviewType) {
      case 'review': {
        requestUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects/status`;
        break;
      }
      case 'reviewObjectMapping': {
        requestUrl = `${process.env.REACT_APP_CONFIG_API}/object-mapping/status`;
        break;
      }
    }

    const response = await request
      .get(requestUrl)
      .query({ appCode: app_code, flag: 'Rejected' })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    if (reviewType == 'reviewObjectMapping') {
      setRejectedData(response.body.objectMappingReviewResponseItem);
    } else {
      setRejectedData(response.body.dataObjects);
    }
  };
  return (
    <div className="h-[100%] flex flex-col">
      <RejectGrid
        reviewType={reviewType}
        rejectedData={rejectedData}
        fetchRejectedData={fetchRejectedData}
      />
    </div>
  );
};

export default ObjectRejected;
