const getNewAccordianData = (data) => {
  const sourceValue = data?.newObjectformData['Source Object List']?.value;
  const targetValue = data?.newObjectformData['Target Object List']?.value;

  const sourceList =
    data?.newObjectformData?.sourceObjectListData?.value.find(
      (ele) => ele.dataObjectId == sourceValue
    ) || {};

  const targetList =
    data?.newObjectformData?.targetObjectListData?.value.find(
      (ele) => ele.dataObjectId == targetValue
    ) || {};

  return {
    sources: sourceList,
    targets: targetList
  };
};

export default getNewAccordianData;
