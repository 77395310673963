import request from 'superagent';
import keycloak from '../Keycloak';

const fetchHLFExplorerListData = ({ networkName }) => {
  console.log("calling...",networkName)
  // let network=networkName;
  // console.log("call...",network)
  if (networkName) {
    return async () => {
      const res = await request
        .get(`${process.env.REACT_APP_CONFIG_API}/hlfExplorer/getBlocksByNetwork`)
        .query({ networkName:networkName})
        .set('Authorization', `Bearer ${keycloak.token()}`);

      return res;
    };
  } else {
    return {};
  }
};

export default fetchHLFExplorerListData;
