import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { noop } from 'lodash';
import { CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { string } from 'joi';
import ErrorNotify from '../../notify/ErrorNotify';

const ConnectionGrid = ({
  connectionLogsData = [],
  fetchConnectionsData = noop,
  onViewClick = noop
}) => {
  const gridRef = useRef();
  const { app_code = '' } = useParams();
  const navigate = useNavigate();
  const [gridUpdated, setGridUpdated] = useState(false);

  useEffect(() => {
    if (gridRef?.current?.columnApi) {
      autoSizeAll(false);
    }
  }, [gridUpdated]);

  const columnDefs = [
    { headerName: 'Connector Log ID', field: 'connectorLogId', suppressSizeToFit: false },
    { headerName: 'Connector ID', field: 'connectorId', suppressSizeToFit: false },
    { headerName: 'Polling Interval', field: 'intervalms', suppressSizeToFit: false },
    { headerName: 'Start Time', field: 'startExecution', suppressSizeToFit: false },
    { headerName: 'Next Execution Time', field: 'nextExecution', suppressSizeToFit: false },
    { headerName: 'Connector Type', field: 'type', suppressSizeToFit: false },
    {
      headerName: 'Action',
      field: 'action',
      suppressSizeToFit: false,
      cellRenderer: (params) => {
        return (
          <button
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            onClick={() => {
              cellClicked(params, 'view');
            }}>
            View
          </button>
        );
      }
    }
  ];

  const defaultColDef = {
    sortable: true,
    flex: 1,
    floatingFilter: false,
    filter: true,
    resizable: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function onShowLoading() {
    gridOptions.api.showLoadingOverlay();
  }

  function onHide() {
    gridOptions.api.hideOverlay();
  }

  let gridApi;

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };

  function formatDateString(inputDateString) {
    if (!inputDateString) {
      return null;
    }
    const inputDate = new Date(inputDateString);

    const formattedDate = `${inputDate.getUTCFullYear()}-${(inputDate.getUTCMonth() + 1)
      .toString()
      .padStart(2, '0')}-${inputDate.getUTCDate().toString().padStart(2, '0')} ${inputDate
      .getUTCHours()
      .toString()
      .padStart(2, '0')}:${inputDate.getUTCMinutes().toString().padStart(2, '0')}:${inputDate
      .getUTCSeconds()
      .toString()
      .padStart(2, '0')}`;

    return formattedDate;
  }

  const rowData = connectionLogsData.map((connections) => {
    return {
      connectorId: connections?.connectorId,
      connectorLogId: connections?.id,
      intervalms: connections?.intervalms,
      type: connections?.type,
      startExecution: formatDateString(connections?.startExecution),
      nextExecution: formatDateString(connections?.nextExecution)
    };
  });

  function autoSizeAll(skipHeader = true) {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  async function onGridReady(params) {
    gridApi = params?.api;
    params?.api?.showLoadingOverlay();
    gridApi?.sizeColumnsToFit();
    params.columnApi.autoSizeAllColumns();
    const fetchData = await fetchConnectionsData();
    setGridUpdated();
  }

  async function cellClicked(param, action) {
    const { data } = param;
    if (action == 'view') {
      console.log('here');
      const apiData = await onViewClick(data?.connectorLogId);
      if (apiData?.body?.status == 'FAILURE') {
        const errorMessage =
          typeof apiData?.body?.result == 'string'
            ? apiData?.body?.result
            : 'Unable to retrieve scheduler Logs';
        toast.custom((t) => <ErrorNotify t={t} message={errorMessage} />, {
          duration: 4000
        });
      } else {
        navigate(`/apps/${app_code}/connectionLogs/${data?.connectorLogId}`);
      }
    }
  }

  return (
    <div className="ag-theme-material px-[24px] p-[10px] mx-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white">
      <div className="flex-1">
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          onCellClicked={cellClicked}
          rowData={rowData}
          domLayout="autoHeight"
        />
      </div>
    </div>
  );
};

export default ConnectionGrid;
