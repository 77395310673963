import React from 'react';
import { Button, Checkbox, TextField } from '@mui/material';
import { noop } from 'lodash';
import { useParams } from 'react-router-dom';

const DataObjectValidationModal = ({
  validationList = [],
  errorMessage = '',
  handleCheckboxChange,
  handleTextBoxChange,
  onCloseModal = noop
}) => {
  const { action = '' } = useParams();
  switch (action) {
    case 'view': {
      return (
        <div className="items-center h-screen m-auto !w-[550px] align-middle !mt-[12%]">
          <div className="bg-white rounded-lg shadow-lg p-6 mx-auto">
            <div className="bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-t-lg py-2 px-4 mb-4">
              <h2 className="text-[24px] font-semibold">Validation Rules:</h2>
            </div>
            {validationList.map((item, index) => {
              return (
                <div key={index}>
                  <div className="flex justify-between mb-4">
                    <div>
                      <label>
                        <Checkbox
                          onChange={() => handleCheckboxChange(index)}
                          checked={item.showTextBox}
                          // defaultChecked={!!defaultValue}
                          disabled = {true}
                        />
                        {item.text}
                      </label>
                    </div>
                    <div>
                      {item.showTextBox && (
                        <div className="float-right">
                          <TextField
                            onChange={(e) => handleTextBoxChange(index, e.target.value)}
                            value={item.inputText}
                            className="!ml-5 float-right"
                            disabled = {true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {errorMessage && <h1 className="mb-4">{errorMessage}</h1>}
            <div className="flex justify-between">
              <Button variant={'contained'} onClick={onCloseModal}>
                Close
              </Button>
            </div>
          </div>
        </div>
      );
    }
    default: {
      return (
        <div className="items-center h-screen m-auto !w-[550px] align-middle !mt-[12%]">
          <div className="bg-white rounded-lg shadow-lg p-6 mx-auto">
            <div className="bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-t-lg py-2 px-4 mb-4">
              <h2 className="text-[24px] font-semibold">Validation Rules:</h2>
            </div>
            {validationList.map((item, index) => {
              return (
                <div key={index}>
                  <div className="flex justify-between mb-4">
                    <div>
                      <label>
                        <Checkbox
                          onChange={() => handleCheckboxChange(index)}
                          checked={item.showTextBox}
                        />
                        {item.text}
                      </label>
                    </div>
                    <div>
                      {item.showTextBox && (
                        <div className="float-right">
                          <TextField
                            onChange={(e) => handleTextBoxChange(index, e.target.value)}
                            value={item.inputText}
                            className="!ml-5 float-right"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {errorMessage && <h1 className="mb-4">{errorMessage}</h1>}
            <div className="flex justify-between">
              {!errorMessage && (
                <Button variant={'contained'} onClick={onCloseModal}>
                  Submit
                </Button>
              )}
              <Button variant={'contained'} onClick={onCloseModal}>
                Close
              </Button>
            </div>
          </div>
        </div>
      );
    }
  }
};

export default DataObjectValidationModal;
