const getApplicationCode = (applicationData = []) => {
  const data = [];
  applicationData.forEach((item) => {
    data.push({
      name: item.code,
      value: item.code
    });
  });
  return data;
};

export default getApplicationCode;
