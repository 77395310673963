function getEditAccordianData({ data = [], type }) {
  switch (type) {
    case 'source': {
      return data?.map((element) => {
        return {
          sourceName: element?.code,
          sourceId: element?.dataObjectKeyId,
          ...(element?.keys && element?.keys?.length
            ? {
                children: getEditAccordianData({ data: element?.keys, type: type })
              }
            : {})
        };
      });
    }
    case 'target': {
      return data?.map((element) => {
        return {
          targetName: element?.code,
          targetId: element?.dataObjectKeyId,
          ...(element?.keys && element?.keys?.length
            ? {
                children: getEditAccordianData({ data: element?.keys, type: type })
              }
            : {})
        };
      });
    }
    case 'mapping': {
      return data?.map((element) => {
        return {
          targetName: element?.targetObjectCode,
          targetId: element?.targetObjectId,
          sourceName: element?.sourceObjectCode,
          sourceId: element?.sourceObjectId,
          mappingId: element?.Id,
          ...(element?.keys && element?.keys?.length
            ? {
                children: getEditAccordianData({ data: element?.keys, type: type })
              }
            : {})
        };
      });
    }
  }
}

export default getEditAccordianData;
