import React, { useState } from 'react';
import GridCriteria from './criteriaGrid.component';
import { Button, TextField } from '@mui/material';
import request from 'superagent';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import { useDispatch } from 'react-redux';
import ConformationContainer from '../../common/conformationModal/containers';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { noop } from 'lodash';
import keycloak from '../../../Keycloak';
import toast from 'react-hot-toast';
import Success from '../../notify/Success';

const SearchCriteria = () => {
  const [criteriaName, setCriteriaName] = useState();
  const [criteriaFieldName, setCriteriaFieldName] = useState();
  const [trigger, setTrigger] = useState(true);

  const dispatch = useDispatch();

  const customComponent = (
    <div>
      <div className="mb-2">
        <div>Criteria Name :</div>
        <TextField
          value={criteriaName}
          onChange={(e) => setCriteriaName(e.target.value)}></TextField>
      </div>
      <div>
        <div>Criteria Field Name :</div>
        <TextField
          value={criteriaFieldName}
          onChange={(e) => setCriteriaFieldName(e.target.value)}></TextField>
      </div>
    </div>
  );

  const onConformModalSubmit = async () => {
    const response = await request
      .post(`${process.env.REACT_APP_GENRIC_API}/generic/feature/criteria/create`)
      .send({
        criteria: {
          dataField: criteriaFieldName,
          name: criteriaName
        }
      })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    if (response?.body?.response?.status?.acknowledged) {
      setCriteriaFieldName('');
      setCriteriaName('');
      setTrigger(!trigger);
      toast.custom((t) => <Success t={t} message={'Added Criteria SuccessFully'} />, {
        duration: 4000
      });
    }
  };

  const addCriteriaOnClick = () => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.addCriteria,
        isOpen: true
      })
    );
  };

  return (
    <div className="h-[100%] flex flex-col w-full">
      <Button
        onClick={addCriteriaOnClick}
        className="!bg-white !text-black !normal-case !w-[200px] !ml-4 !mt-4"
        variant="contained">
        Add Criteria
      </Button>
      <GridCriteria trigger={trigger} />
      <ConformationContainer
        type="submit"
        formData={{}}
        modalId={ModalKeys.addCriteria}
        cancelObject={{ text: 'Cancel', callback: noop }}
        conformObject={{
          text: 'Add Criteria',
          callback: onConformModalSubmit
        }}
        customComponent={customComponent}
        modalText={{
          header: `Create Criteria`,
          desc: `Add Criteria Here`
        }}
      />
    </div>
  );
};

export default SearchCriteria;
