import React, { useState } from 'react';
import DeleteFeatureModal from '../component/DeleteFeatureModal';
import { default as fetchFeatureDataCall } from '../../../services/fetchFeatureData';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import deleteFeature from '../../../services/deleteFeature';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFeatureDataAction } from '../slices/applicationSlice';
import { toast } from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';

const DeleteFeatureModalContainer = ({ onCloseModal = noop }) => {
  const { app_code = '' } = useParams();
  const mutation = useMutation(deleteFeature);
  const dispatch = useDispatch();

  const [showModalLoader, setShowModalLoader] = useState();
  const modalStore = useSelector((store) => store.store.genericModal);
  const modalData = modalStore?.modalKeys?.deleteFeatureModalData;

  const refetchData = useQuery('feature-data', fetchFeatureDataCall({ appCode: app_code }), {
    enabled: false,
    refetchOnWindowFocus: true
  });

  const { refetch } = refetchData;

  const fetchFeatureData = async () => {
    const refetchData = await refetch();
    const { data, isSuccess } = refetchData;
    if (isSuccess) {
      dispatch(fetchFeatureDataAction({ featureData: data?.body?.featureObjects }));
    }
    return data?.body?.featureObjects;
  };
  const onDeleteFeatureClick = async () => {
    const deleteResult = await mutation.mutateAsync({ id: modalData.id, appCode: app_code });
    setShowModalLoader(true);
    if (deleteResult?.body?.status?.code == 'SUCCESS') {
      toast.custom((t) => <Success t={t} message={'Feature Deleted Successfully'} />, {
        duration: 4000
      });
      const done = await fetchFeatureData();
      if (done) {
        setShowModalLoader(false);
        onCloseModal();
      }
    } else {
      toast.custom((t) => <ErrorNotify t={t} message={'Feature Deletion Failed'} />, {
        duration: 4000
      });
      console.log('Feature Deletion failed');
    }
  };

  return (
    <DeleteFeatureModal
      showModalLoader={showModalLoader}
      onCloseModal={onCloseModal}
      onDeleteClick={onDeleteFeatureClick}
      modalHeader="Delete Feature"
      modalData={modalData}
    />
  );
};

export default DeleteFeatureModalContainer;
