import React from 'react';
import ApplicationContainer from '../../dashboard/container/ApplicationContainer';

const Application = () => {
  return (
    <div className="w-full min-h-full flex bg-[#F4F3FA]">
      <ApplicationContainer />
    </div>
  );
};

export default Application;
