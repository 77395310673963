import { Button } from '@mui/material';
import { noop } from 'lodash';
import React, { useState } from 'react';

const AuditLogFilter = ({
  clickFilter = noop,
  handleStartDateChange,
  handleEndDateChange,
  handleStartTimeChange,
  handleEndTimeChange,
  startDate,
  endDate,
  startTime,
  endTime
}) => {
  return (
    <div>
      <div className="bg-blue-100 p-8 rounded-md shadow-md mt-4 mx-4 flex relative">
        <div className="flex justify-between m-auto">
          <div className="mb-5 mr-4">
            <div>
              <label className="block text-gray-700 text-lg font-bold mb-1" htmlFor="start-date">
                Start Date:
              </label>
              <input
                type="date"
                id="start-date"
                value={startDate}
                onChange={handleStartDateChange}
                className="w-[400px] mb-1 text-lg px-3 py-2 text-gray-700 border rounded focus:ring-2"
              />
            </div>
            <div>
              <label className="block text-gray-700 text-lg font-bold mb-1" htmlFor="start-time">
                Start Time:
              </label>
              <input
                type="time"
                id="start-time"
                value={startTime}
                onChange={handleStartTimeChange}
                className="w-[400px] text-lg px-3 py-2 text-gray-700 border rounded mb-1"
              />
            </div>
          </div>
          <div className="mb-4">
            <div>
              <label className="block text-gray-700 text-lg font-bold mb-1" htmlFor="end-date">
                End Date:
              </label>
              <input
                type="date"
                id="end-date"
                value={endDate}
                onChange={handleEndDateChange}
                className="text-lg w-[400px] px-3 py-2 text-gray-700 border rounded mb-1"
              />
            </div>
            <div>
              <label className="block text-gray-700 text-lg font-bold mb-1" htmlFor="end-time">
                End Time:
              </label>
              <input
                type="time"
                id="end-time"
                value={endTime}
                onChange={handleEndTimeChange}
                className="text-lg w-[400px] px-3 py-2 text-gray-700 border rounded mb-1"
              />
            </div>
          </div>
        </div>
        <div className='absolute bottom-2 right-4 mb-3'>
            <Button
              variant="contained"
              size='large'
              onClick={() => {
                clickFilter(startDate, startTime, endDate, endTime);
              }}>
              Show Audit Trails
            </Button>
        </div>
      </div>
    </div>
  );
};

export default AuditLogFilter;
