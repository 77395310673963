const formKeys = {
  newApplicationForm: 'newApplicationForm',
  editApplicationForm: 'editApplicationForm',
  organizationsForm: 'organizationsForm',
  organizationTypeForm: 'organizationTypeForm',
  siteTypeForm: 'siteTypeForm',
  roleForm: 'roleForm',
  featureRoleForm: 'featureRoleForm',
  userForm: 'userForm',
  editObjectMapping: 'editObjectMapping',
  newObjectMappingForm: 'newObjectMappingForm',
  featureGeneric: 'featureGeneric',
  preCheckForm: 'preCheckForm',
  featureAdvOption: 'featureAdvOption',
  generalForm: 'generalForm',
  channelForm: 'channelForm',
  ordersForm: 'ordersForm',
  sodView: 'sodView',
  orchFormRule: 'orchFormRule',
  orchActions: 'orchFormRule',
  addUserForm: 'addUserForm'
};

export default formKeys;
