import { useDispatch, useSelector } from 'react-redux';
import ApplicationTiles from '../component/ApplicationTiles';
import { dashboardContext } from '../../dashboard/slices/dashboardSlice';
import { useQuery } from 'react-query';
import fetchApplicationCount from '../../../services/fetchApplicationCount';
import { useEffect } from 'react';
import { applicationCountDataAction } from '../slices/applicationSlice';
import { useParams } from 'react-router-dom';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';

const ApplicationTilesContainer = ({ askNavigationCheck = false, context = '' }) => {
  const { app_code } = useParams();
  const dispatch = useDispatch();
  const {
    data: applicationCountData,
    isLoading,
    isSuccess,
    isFetchedAfterMount
  } = useQuery({
    queryKey: ['feature-count'],
    queryFn: fetchApplicationCount({ appCode: app_code }),
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true
  });

  const storeApplicationCountData = useSelector(
    (store) => store.store.moreApplication.applicationCountData
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        applicationCountDataAction({
          applicationCountData: applicationCountData.body.applicationCountObjects
        })
      );
    }
  }, [isLoading, isFetchedAfterMount]);

  const changeContext = (context, callback, fromModal) => {
    if (!fromModal && askNavigationCheck) {
      dispatch(
        genericModalStateUpdate({
          modalKey: context,
          isOpen: true
        })
      );
    } else {
      dispatch(dashboardContext({ contextName: 'Application', context: context }));
      callback();
    }
  };

  return (
    <ApplicationTiles
      ApplicationCountData={storeApplicationCountData}
      changeContext={changeContext}
      context={context}
    />
  );
};

export default ApplicationTilesContainer;
