const ModalKeys = {
  dashboardModal: 'dashboardModal',
  editModal: 'editModal',
  addUserModal: 'addUserModal',
  deleteFeatureModal: 'deleteFeatureModal',
  fileStatus: 'fileStatus',
  deleteOrchestrationModal: 'deleteOrchestrationModal',
  testing: 'testing',
  newMappingObjectFormSubmit: 'newMappingObjectFormSubmit',
  stopNavigation: 'stopNavigation',
  stopNavAndSendToNewObjMap: 'stopNavAndSendToNewObjMap',
  stopNavigationOnHlfClick: 'stopNavigationOnHlfClick',
  stopNavigationOnEditHlfClick: 'stopNavigationOnEditHlfClick',
  stopNavigationOnCopyHlfClick: 'stopNavigationOnCopyHlfClick',
  stopNavigationOnNewHlfClick: 'stopNavigationOnNewHlfClick',
  stopNavigationOnDashboardClick: 'stopNavigationOnDashboardClick',
  stopNavigationToHlf: 'stopNavigationToHlf',
  deleteForm: 'deleteForm',
  deleteAppModal: 'deleteAppModal',
  searchBy: 'searchBy',
  criteriaModal: 'criteriaModal',
  addCriteria: 'addCriteria',
  horizontalChart: 'horizontalChart',
  featureModal: 'featureModal',
  deleteDataObjectModal: 'deleteDataObjectModal',
  deleteMapping: 'deleteMapping',
  stopNavigationFeature: 'stopNavigationFeature',
  stopTopNavigation: 'stopTopNavigation',
  stopDataObject: 'stopDataObject',
  stopNewDataObject: 'stopNewDataObject',
  stopNewOrchestration: 'stopNewOrchestration',
  stopOrchestration: 'stopOrchestration',
  exportApplicationModal: 'exportApplicationModal',
  viewHlf: 'viewHlf',
  wallet: 'wallet',
  validationRule: 'validationRule',
  webhookId: 'webhookId',
  deleteHlfModal: 'deleteHlfModal',
  digitIdModal: 'digitIdModal',
  config: 'config',
  objectMappingModal: 'objectMappingModal',
  dataObjectModal: 'dataObjectModal',
  sourceObjectModal: 'sourceObjectModal',
  transformedObjectModal: 'transformedObjectModal',
  ocrFileUplaod: 'ocrFileUpload',
  reviewModal: 'reviewModal',
  dataObjectValidationModal: 'dataObjectValidationModal',
  commentModal: 'commentModal',
  deleteVariable: 'deleteVariable',
  connectoreLogRecordModal: 'connectoreLogRecordModal',
  reviewErrorModal: 'reviewErrorModal',
  blockHashModal: 'blockHashModal',
  hlfTxnModal: 'hlfTxnModal'
};

export default ModalKeys;
