import classNames from 'classnames';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const SideElement = ({ elementData }) => {
  const navigate = useNavigate();
  const { app_code } = useParams();

  const selectedClass = classNames(
    'cursor-pointer px-[28px] py-[14px] my-[7px] flex hover:bg-[#E4E3FF] hover:text-[#4C49ED] rounded-[6px] text-[#A1A0BD] items-center',
    {
      'bg-[#E4E3FF] !text-black !font-semibold': elementData.isSelected,
      'bg-gray-400 hover:bg-gray-400 !text-black !font-semibold': elementData.isDisabled
    }
  );
  return (
    <div
      className={selectedClass}
      onClick={() => elementData.onClick(elementData, navigate, app_code)}>
      <div className="pr-[16px]">
        <img height={'28px'} width={'28px'} src={elementData.imageUrl} alt="" srcset="" />
      </div>
      <div>{elementData.name}</div>
    </div>
  );
};

export default SideElement;
