import { createSlice } from '@reduxjs/toolkit';

export const connectionFramework = createSlice({
  name: 'connectionFramework',
  initialState: {},
  reducers: {
    setConnectionFrameworkGridData: (state, action) => {
      state.gridData = action.payload.data;
    },
    setOcrData:(state,action)=>{
      state.ocrdata=action.payload.data
    }
  }
});

export const { setConnectionFrameworkGridData } = connectionFramework.actions;

export default connectionFramework.reducer;
