import request from 'superagent';
import { objectMappingUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const fetchObjectMappingData = ({ appCode = '' }) => {
  return async () => {
    const res = await request
      .get(objectMappingUrl)
      .query({ appCode: appCode })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

export default fetchObjectMappingData;
