import { createSlice } from '@reduxjs/toolkit';

export const hlfSlice = createSlice({
  name: 'hlf',
  initialState: {},
  reducers: {
    setCurrentHLFId: (state, action) => {
      state.id = action.payload.id;
    },
    setCurrentHLFOrganization: (state, action) => {
      state.organization = action.payload.organization;
    },
    setCurrentHLFOrganizationId: (state, action) => {
      state.organizationId = action.payload.id;
    },
    setCurrentHLFNetwork: (state, action) => {
      state.network = action.payload.network;
    }
  }
});

export const {
  setCurrentHLFId,
  setCurrentHLFOrganization,
  setCurrentHLFNetwork,
  setCurrentHLFOrganizationId
} = hlfSlice.actions;

export default hlfSlice.reducer;
