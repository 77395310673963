import SideBarComponent from '../../navigation/components/Sidebar';
import sidebar_component from '../constants/sidebar';

const SideBarContainer = ({ hideSideBar, page, children }) => {
  const sidebar_element = sidebar_component[page];
  return (
    <SideBarComponent hideSideBar={hideSideBar} sidebar_component={sidebar_element} page={page}>
      {children}
    </SideBarComponent>
  );
};

export default SideBarContainer;
