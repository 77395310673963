import { Button } from '@mui/material';
import { noop } from 'lodash';
import React, { useState } from 'react';
import ConnectionLogProgress from './ConnectionLogProgress';

const ConnectionLogRecords = ({
  featureListData = {},
  onRecordClick = noop,
  recordsData = [],
  showRecordStep = false,
  onRecordStepClick = noop
}) => {
  const data = featureListData?.body?.result?.recordsList?.recordIds;
  const [currentClickedIndex, setCurrentClickedIndex] = useState(null);

  const handleButtonClick = (index) => {
    if (currentClickedIndex === index) {
      // If the same button is clicked again, close its progress
      setCurrentClickedIndex(null);
    } else {
      // Otherwise, set the new clicked index to open its progress
      setCurrentClickedIndex(index);
      onRecordClick(data[index]);
    }
  };

  return (
    <div className="">
      <div className="w-full flex justify-center bg-orange-200 p-4">
        <h1 className="text-2xl font-bold">Records</h1>
      </div>
      {data ? (
        data.map((item, index) => (
          <div className="m-auto p-8" key={index}>
            <Button
              variant={'contained'}
              className="bg-blue-500 h-12 text-xl text-white p-3 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:border-blue-300"
              onClick={() => handleButtonClick(index)}>
              Record {index + 1}
            </Button>
            {/* Render ConnectionLogProgress if currentClickedIndex matches this index */}
            {currentClickedIndex === index && recordsData && showRecordStep && (
              <ConnectionLogProgress
                key={index} // Ensure a unique key for re-rendering
                isRecord={true}
                recordsData={recordsData}
                onRecordStepClick={onRecordStepClick}
              />
            )}
          </div>
        ))
      ) : (
        <div className="flex justify-center items-center mt-10">
          <h1 className="text-3xl">No Records Found</h1>
        </div>
      )}
    </div>
  );
};

export default ConnectionLogRecords;
