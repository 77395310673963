import React from 'react';
import { Handle, Position } from 'reactflow';

const NormalLeftNode = ({ data }) => {
  return (
    <>
      <div
        id={data.id}
        style={{ backgroundColor: data.bg }}
        location={data.location}
        name={data.label}
        className="rounded-full font-bold px-2 overflow-hidden w-[250px] min-h-[60px] flex items-center justify-center mb-2 bg-[#E4E4E4]">
        <div name={data.label} id={data.id} location={data.location} className="custom-node">
          <div
            className="overflow-hidden whitespace-nowrap text-ellipsis"
            name={data.label}
            id={data.id}
            location={data.location}>
            {data.label}
          </div>
        </div>

        <Handle
          className="bg-pink-500"
          type="source"
          position={Position.Right}
          isConnectable={true}
          id="a"
        />
      </div>
    </>
  );
};

export default NormalLeftNode;
