import React from 'react';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Cancel from '@mui/icons-material/Close';

const Delete = ({ message, t }) => {
  return (
    <div className="h-[120px] w-[300px] bg-white shadow-lg flex rounded-[12px] absolute right-[20px]">
      <div className="bg-red-400 w-[60px] flex justify-center pt-[20px] rounded-l-[12px]">
        <RemoveCircleIcon />
      </div>
      <div className='ml-[12px]'>
        <div>
          <div className="text-red-600 text-[24px]">Delete!</div>
          <div className="mt-[12px]">{message}</div>
        </div>
        <div className="absolute top-[12px] right-[12px]" onClick={() => toast.dismiss(t.id)}>
          <Cancel />
        </div>
      </div>
    </div>
  );
};

export default Delete;
