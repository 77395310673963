import { Delete, Edit, ViewAgenda } from '@mui/icons-material';
import { Delete, Edit, FileUploadSharp, ViewAgenda } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { noop } from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import request from 'superagent';
import Tabs from '../../Tabs/components/Tabs';
import ConformationContainer from '../../common/conformationModal/containers';
import MenuContainer from '../../common/menu/containers/MenuContainer';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import GenericModalContainer from '../../modals/genericModal/container';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ErrorNotify from '../../notify/ErrorNotify';
import Success from '../../notify/Success';

const HlfExplorerGrid = ({
  fetchHLFExplorerData,
  HLFExplorerData = [],
  title,
  onBlockHashClick = noop,
  onHlfTxnClick = noop
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentClickedData, setCurrentClickedData] = useState({});
  const [currentType, setCurrentType] = useState('');

  const columnDefs = [
    { headerName: 'Block Number', field: 'blockNumber' },
    { headerName: 'Channel Name', field: 'channelName' },
    { headerName: 'No. of Txn', field: 'numberOfTransactions' },
    { headerName: 'Data Hash', field: 'dataHash' },
    {
      headerName: 'Block Hash',
      field: 'blockHash',
      cellRenderer: (params) => {
        return (
          <button
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            onClick={() => {
              cellClicked(params, 'blockHash');
            }}>
            {params?.data?.blockHash}
          </button>
        );
      }
    },
    { headerName: 'Previous Hash', field: 'previousHash' },
    {
      headerName: 'Transaction Id',
      field: 'transactions',
      cellRenderer: (params) => {
        return (
          <button
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            onClick={() => {
              cellClicked(params, 'txnId');
            }}>
            {params?.data?.transactions}
          </button>
        );
      }
    },
    {
      headerName: 'View',
      field: 'view',
      cellRenderer: (params) => {
        return (
          <div className="flex justify-center">
            <Button
              variant="contained"
              onClick={() => {
                cellClicked(params, 'view');
              }}>
              View
            </Button>
          </div>
        );
      }
    }
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function onShowLoading() {
    gridOptions.api.showLoadingOverlay();
  }

  function onHide() {
    gridOptions.api.hideOverlay();
  }

  let gridApi;

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };

  const rowData = HLFExplorerData.map((feature, index) => {
    return {
      blockNumber: feature?.blockNumber,
      channelName: feature?.channelName,
      numberOfTransactions: feature?.numberOfTransactions,
      blockHash: feature?.blockHash,
      previousHash: feature?.previousHash,
      dataHash: feature?.dataHash,
      transactions: feature?.transactions[0]
    };
  });

  async function onGridReady(params) {
    gridApi = params?.api;
    params?.api?.showLoadingOverlay();
    const fetchData = await fetchHLFExplorerData();
  }

  const onExportClick = () => {
    gridApi = params?.api;
    gridApi?.exportDataAsCsv();
  };

  async function cellClicked(param, field) {
    const { data } = param;
    switch (field) {
      case 'view': {
        // onDigitIdClick(data);
        console.log(data, 'view clicked');
        break;
      }
      case 'blockHash': {
        onBlockHashClick(data);
        break;
      }
      case 'txnId': {
        onHlfTxnClick(data);
        break;
      }
    }
  }

  return (
    <div className="ag-theme-alpine flex-1 px-[24px] mt-[50px] p-[20px] m-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      <div className="text-[24px] font-bold">{title}</div>
      <div className="flex-1">
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={12}
          rowData={rowData}
          suppressCellFocus={true}
          domLayout="autoHeight"
        />
      </div>
    </div>
  );
};

export default HlfExplorerGrid;
