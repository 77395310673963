import request from 'superagent';
import keycloak from '../Keycloak';
import { getFeatureByIdUrl } from '../modules/dashboard/constants/applicationRequest';

const fetchFeatureDataById = ({ featureId = '' }) => {
  return async () => {
    const res = await request
      .get(getFeatureByIdUrl)
      .query({ id: featureId })
      .set('Authorization', `Bearer ${keycloak.token()}`);
    return res;
  };
};

export default fetchFeatureDataById;
