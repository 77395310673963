import application from '../../public/icons/application.svg';
import hlfLogo from '../../public/icons/hlfLogo.svg';
import navigationSchemaConstant from '../../constants/navigationSchemaConstant';

function onSubheaderNavigationClick(componentData, navigate, app_code) {
  switch (componentData.value) {
    case 'extension': {
      navigate(`/apps/${app_code}/connections`);
      break;
    }
    case 'applications': {
      navigate(`/apps/${app_code}/features`);
      break;
    }
  }
}

function getExtensionSubheaderSchema({ application = false, extension = false }) {
  return [
    {
      name: 'Applications',
      value: 'applications',
      onClick: onSubheaderNavigationClick,
      isSelected: application,
      isDisabled: false
    },
    {
      name: 'Extension',
      value: 'extension',
      onClick: onSubheaderNavigationClick,
      isSelected: extension,
      isDisabled: false
    }
  ];
}

export default getExtensionSubheaderSchema;
