import { createSlice } from '@reduxjs/toolkit';

export const applicationSlice = createSlice({
  name: 'application',
  initialState: { applicationState: '', editFormData: { } },
  reducers: {
    successfulApplicationDataFetch: (state, action) => {
      state.data = action.payload.data;
    },
    updateApplicationState: (state, action) => {
      state.applicationState = action.payload.applicationState;
    },
    updateEditFormData: (state, action) => {
      state.editFormData = action.payload.formData;
    },
    updateAddUserData: (state, action) => {
      state.addUserData = action.payload.formData;
    }
  }
});

export const { successfulApplicationDataFetch, updateApplicationState, updateEditFormData, updateAddUserData } = applicationSlice.actions;

export default applicationSlice.reducer;
