import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import keycloak from '../../Keycloak';
import {
  Button,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Alert
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import axios from 'axios';
// import {React,useEffect} from 'react';
import { useForm, SubmitHandler, Controller, useFieldArray } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import createOrg from '../../services/createOrg';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { deleteOrgCreation, orgcreation, tenatlist } from '../dashboard/constants/applicationRequest'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
export default function OrganisationCreation() {
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');
  const rowsPerPage = 10;
  const [status, setStatus] = React.useState('');
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      Organisation: [
        {
          org_id: '',
          org_type: 'TENANT',
          org_name: '',
          parent_org_id: null,
          party_type: '',
          email: '',
          mobile: '',
          realm: '',
        }
      ],

    }
  });

  const { fields: data } = useFieldArray({
    control,
    name: 'Organisation'
  });
  const mutation = useMutation(createOrg);
  const [page, setPage] = useState(1);
  const [selectedId, setSelectedId] = useState(0)
  const token = keycloak.token();
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: {}
  }
  const [organisationList, setOrganisationList] = React.useState([]);
  const onsubmit = (data, e) => {
    console.log("createOrganiston.....", data)

    setLoading(true)
    // const query = { id: 'id' };
    // try {
    //   const response = mutation.mutateAsync({ payload: data });
    //   console.log(response,"create resp")
    //   if (response.body.status.code == 'SUCCESS') {
    //     alert(response.body.status.message)
    //     setLoading(false)
    //     console.log(response.body)
    //   }
    //   else {
    //     console.log(response.body)
    //     setLoading(false)
    //   }
    // }
    // catch (err) {
    //   console.log("error is ...", err)
    //   setLoading(false)
    // }
    let actionType = status != 'edit' ? 'post' : 'put'
    if (status === 'edit') {
      data.Organisation[0].id = selectedId;
    }

    axios[actionType](orgcreation, data.Organisation[0], Api_Config).then((res) => {
      console.log(res.data, "creation respooo")
      try {
        if (res.data.status.code === 'SUCCESS') {
          alert(res.data.status.message)
          setLoading(false)
          handleClose()
          getOrganisationData()
          if(status === 'edit'){
            setStatus('')
          }
        } else {
          console.log(response.body)
          alert(res.data.status.message)
          setLoading(false)
          handleClose()
        }
      } catch (err) {
        console.log("error is ...", err)
        setLoading(false)
        alert(res.data.status.message)
        handleClose()
      }

    })
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClickOpen = () => {
    setOpen(true);
    reset()
  };
  const handleClose = () => {
    setOpen(false);
    if(status === 'edit'){
      setStatus('')
    }else{
      
    }
    
  };
  const update = (row, type) => {
    setOpen(true)
    console.log(row, "edit")
    setValue(`Organisation.${0}.org_id`, row.org_id);
    setValue(`Organisation.${0}.org_type`, row.org_type);
    setValue(`Organisation.${0}.org_name`, row.org_name);
    setValue(`Organisation.${0}.party_type`, row.org_type);
    setValue(`Organisation.${0}.mobile`, row.mobile);
    setValue(`Organisation.${0}.email`, row.email);
    setValue(`Organisation.${0}.realm`, row.realm);
    setStatus(type)
    setSelectedId(row.id)

  };
  const deleteOrganisation = (row, type) => {
    let deletePayload = {
      id: row.id,
      status: 'DELETED'
    }
    try {
      setLoading(true)
      axios.put(deleteOrgCreation, deletePayload, Api_Config).then((res) => {
        setLoading(false)
        console.log(res, "delete resp")
        if (res.data.status.code === 'SUCCESS') {
          alert(res.data.status.message)
          getOrganisationData()
        }
      })
    } catch (err) {
      console.log("error is ...", err)
      setLoading(false)
      alert(res.data.status.message)
      handleClose()
    }
  }
  const getOrganisationData = () => {
    console.log("organisation")
    const token = keycloak.token();
    if (token) {
      axios
        .get(tenatlist, {
          params: {

          },
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          console.log(res.data.organizationObjects, "response");
          if (res.data.organizationObjects.length > 0) {
            setOrganisationList(res.data.organizationObjects);
          } else {
            setOrganisationList([]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log('Token not available');
    }
    // setLoading(true);
    // try {
    //   const response = orgList.mutateAsync();
    //   if (response.body.status.code == 'SUCCESS') {
    //     alert(response.body.status.message)
    //     setLoading(false)
    //     console.log(response.body)
    //   }
    //   else {
    //     console.log(response.body)
    //     setLoading(false)
    //   }
    // }
    // catch (err) {
    //   console.log("error is ...", err)
    //   setLoading(false)
    // }
  }
  useEffect(() => {
    getOrganisationData()

  }, []);


  return (
    <Box className="flex-1 px-[24px] mt-[50px] p-[20px] m-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.0rem', textTransform: 'uppercase', letterSpacing: '0.4' }}>
          Organisation Details
        </Typography>
        <IconButton aria-label="fingerprint" color="secondary" onClick={handleClickOpen}>
          <AddCircleOutlineOutlinedIcon />
        </IconButton>
      </Box>
      <TableContainer >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Organisation Code</TableCell>
              <TableCell align="right">Organisation Type</TableCell>
              <TableCell align="right">Organisation Name</TableCell>
              <TableCell align="right">Party Type</TableCell>
              <TableCell align="right">Mobile Number</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Realm</TableCell>

              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organisationList.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="right">{row.org_id}</TableCell>
                <TableCell align="right">{row.org_type}</TableCell>
                <TableCell align="right">{row.org_name}</TableCell>
                <TableCell align="right">{row.org_type}</TableCell>
                <TableCell align="right">{row.mobile}</TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.realm}</TableCell>

                <TableCell align="right">
                  <Button onClick={() => update(row, "edit")}>Edit</Button>
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => deleteOrganisation(row, "remove")}>Remove</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        style={{ alignSelf: 'flex-end', marginTop: '10px' }}
        count={Math.ceil(organisationList.length / rowsPerPage)}
        page={page}
        onChange={handleChangePage}
      />
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
      >
        {/* <DialogTitle
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 700,
              fontSize: '1.0rem',
              textTransform: 'uppercase',
              letterSpacing: '0.4',
              pl: 5
            }}>
            Add Organisation
          </DialogTitle> */}
        <Box sx={{ p: 2 }}>
          <form onSubmit={handleSubmit(onsubmit)}>
            <Paper elevation={0} sx={{ px: 3, mb: 3 }}>
              <Typography sx={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.0rem', textTransform: 'uppercase', letterSpacing: '0.4' }}>
              {status === 'edit' ? 'Edit':'Add'} Organisation
              </Typography>
              {data.map((field, index) => (
                <Box key={field.id} sx={{ my: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                    <TextField
                      label="Organisation Code"
                      variant="outlined"
                      key={index}
                      {...register(`Organisation.${index}.org_id`)}
                      size="small"
                      sx={{ marginRight: 2 }}
                      disabled={status != 'edit' ? false : true}
                      required
                    />
                    <TextField
                      label="Organisation Type"
                      variant="outlined"
                      {...register(`Organisation.${index}.org_type`)}
                      size="small"
                      sx={{ marginRight: 2 }}
                      disabled

                    />
                    <TextField
                      label="Organisation Name"
                      variant="outlined"
                      {...register(`Organisation.${index}.org_name`)}
                      size="small"
                      sx={{ marginRight: 2 }}
                      required


                    />
                    <TextField
                      label="Party Type"
                      variant="outlined"
                      {...register(`Organisation.${index}.party_type`)}
                      size="small"
                      sx={{ marginRight: 2 }}
                      disabled={status != 'edit' ? false : true}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', mb: 4,
                      mt: 2 }}>
                    <TextField
                      label="Mobile Number"
                      variant="outlined"
                      {...register(`Organisation.${index}.mobile`)}
                      size="small"
                      sx={{ marginRight: 2 }}
                    />
                    <TextField
                      label="Email"
                      variant="outlined"
                      {...register(`Organisation.${index}.email`)}
                      size="small"
                      sx={{ marginRight: 2 }}
                      required
                    />
                    <TextField
                      label="Realm"
                      variant="outlined"
                      {...register(`Organisation.${index}.realm`)}
                      size="small"
                      sx={{ marginRight: 2 }}
                      required
                      disabled={status != 'edit' ? false : true}
                    />
                  </Box>
                </Box>
              ))}
            </Paper>

            <LoadingButton type="submit" sx={{ mx: 4 }} loading={loading} loadingIndicator={status != 'edit' ? "Creating.." : 'Updating..'} variant="contained">
              {status != 'edit' ? 'Submit' : 'Update'}
            </LoadingButton>
            <LoadingButton type="button"  sx={{ mx: 4 }} loading={loading}  variant="contained" onClick= {handleClose}>
              Close
            </LoadingButton>
            {/* <Button type="submit" variant='contained' sx={{mt:5}}>Submit</Button> */}
          </form>
        </Box>
      </Dialog>
    </Box>

  );
}
