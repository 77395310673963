import React from 'react';
import GenericModalContainer from '../../../modals/genericModal/container';
import confirmLogo from '../../../../public/icons/confirm.svg';
import saveImage from '../../../../public/icons/save.svg';
import closeLogo from '../../../../public/icons/close.svg';
import { useDispatch } from 'react-redux';
import { emptyAll } from '../../form/slices/formSlice';

const ConformationModal = ({
  modalId,
  cancelObject,
  conformObject,
  modalText,
  onCancelModal,
  onSubmitModal,
  modalData,
  doNotEmptyState = false,
  type,
  customComponent = null
}) => {
  let infoImage;

  const dispatch = useDispatch();

  switch (type) {
    case 'submit': {
      infoImage = (
        <div>
          <img src={confirmLogo} alt="" srcset="" />
        </div>
      );
      break;
    }

    default: {
      infoImage = (
        <div>
          <img src={saveImage} alt="" srcset="" />
        </div>
      );
      break;
    }
  }

  const onModalSubmit = () => {
    onSubmitModal(modalData);
    if(!doNotEmptyState) {
      dispatch(emptyAll()); 
    }
  };

  return (
    <GenericModalContainer modalKey={modalId}>
      <div className="h-[100%] flex justify-center items-center">
        <div className="m-auto  w-[400px] rounded-[12px] p-[28px] bg-white flex flex-col justify-between border-2 border-solid border-gray-400">
          <div className="flex justify-between">
            {infoImage}
            <div onClick={onCancelModal}>
              <img src={closeLogo} alt="" srcset="" />
            </div>
          </div>
          <div className="mt-[16px] flex-1">
            <div className="text-[#101828] font-semibold mb-[8px] text-[24px]">
              {modalText.header}
            </div>
            <div className="text-[#475467]">{modalText.desc}</div>
          </div>
          <div className="py-4">{customComponent}</div>
          <div className="flex mt-[16px] pb-16px">
            <button
              onClick={onCancelModal}
              className="font-semibold w-[170px] rounded-[8px] text-black h-[44px] border-2 border-solid border-[#D0D5DD] mr-[12px]">
              {cancelObject.text}
            </button>
            <button
              onClick={onModalSubmit}
              className="font-semibold w-[170px] rounded-[8px] text-white bg-blue-500 h-[44px]">
              {conformObject.text}
            </button>
          </div>
        </div>
      </div>
    </GenericModalContainer>
  );
};

export default ConformationModal;
