import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { noop } from 'lodash';
import LinkIcon from '@mui/icons-material/Link';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import MenuContainer from '../../common/menu/containers/MenuContainer';
import { toast } from 'react-hot-toast';
import Success from '../../../modules/notify/Success';
import VisibilityIcon from '@mui/icons-material/Visibility';

const FeatureGrid = ({
  featureData = [],
  fetchFeatureData = noop,
  deleteFeatureClick = noop,
  copyFeatureUrl = noop,
  isFetchedAfterMount,
  title
}) => {
  const gridRef = useRef();
  const { app_code = '' } = useParams();
  const navigate = useNavigate();
  const [gridUpdated, setGridUpdated] = useState(false);

  useEffect(() => {
    if (gridRef?.current?.columnApi) {
      autoSizeAll(false);
    }
  }, [gridUpdated]);

  const columnDefs = [
    { headerName: 'UNIQUE CODE', field: 'uniqueCode' },
    { headerName: 'URL PATH', field: 'name' },
    {
      headerName: 'DESCRIPTION',
      field: 'description',
      maxWidth: 500,
      suppressSizeToFit: false,
      resizable: true
    },
    { headerName: 'REQUEST METHOD', field: 'requestMethod' },
    { headerName: 'DATA OBJECT', field: 'dataObject' },
    { headerName: 'REQUEST OBJECT', field: 'requestObject' },
    { headerName: 'RESPONSE OBJECT', field: 'responseObject' },
    { headerName: 'ACCESS TYPE', field: 'accessType' },
    { headerName: 'FEATURE TYPE', field: 'featureType' },
    { headerName: 'Is API', field: 'isApi' },
    { headerName: 'Cache Enabled', field: 'isCached' },
    {
      headerName: 'ACTION',
      field: 'ACTION',
      cellRenderer: (data) => (
        <MenuContainer
          menuData={[
            {
              name: 'Edit',
              callback: () => cellClicked(data, 'edit'),
              icon: <Edit fontSize="small" />
            },
            {
              name: 'Copy API Path',
              callback: () => {
                cellClicked(data, 'copyUrl');
                toast.custom((t) => <Success t={t} message={'Url copied Successfully'} />, {
                  duration: 4000
                });
              },
              icon: <LinkIcon fontSize="small" />
            },
            {
              name: 'Duplicate',
              callback: () => cellClicked(data, 'copyFeature'),
              icon: <FileCopyIcon fontSize="small" />
            },
            {
              name: 'View',
              callback: () => cellClicked(data, 'viewFeature'),
              icon: <VisibilityIcon fontSize="small" />
            },
            {
              name: 'Delete',
              callback: () => cellClicked(data, 'delete'),
              icon: <DeleteIcon fontSize="small" />
            }
          ]}
        />
      )
    }
  ];

  const defaultColDef = {
    sortable: true,
    flex: 1,
    floatingFilter: false,
    filter: true,
    resizable: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function onShowLoading() {
    gridOptions.api.showLoadingOverlay();
  }

  function onHide() {
    gridOptions.api.hideOverlay();
  }

  let gridApi;

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };

  const rowData = featureData.map((feature) => {
    return {
      uniqueCode: feature.code,
      name: feature.name,
      dataObject: feature.dataObjectName,
      description: feature.description,
      requestMethod: feature.requestMethod,
      requestObject: feature.requestObjectName,
      responseObject: feature.responseObjectName,
      queryType: feature.queryTypeCode,
      accessType: feature.accessType,
      id: feature.id,
      featureType: feature?.featureType,
      isApi: feature?.apiFlag,
      isCached: feature?.cacheFlag,
      path: feature?.path
    };
  });

  function autoSizeAll(skipHeader = true) {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  async function onGridReady(params) {
    gridApi = params?.api;
    params?.api?.showLoadingOverlay();
    gridApi?.sizeColumnsToFit();
    params.columnApi.autoSizeAllColumns();
    const fetchData = await fetchFeatureData();
    setGridUpdated();
  }

  const onExportClick = () => {
    gridApi = params?.api;
    gridApi?.exportDataAsCsv();
  };

  function cellClicked(param, field) {
    const { data } = param;

    switch (field) {
      case 'copyFeature': {
        navigate(`/apps/${app_code}/features/copy/${data.id}`);
        break;
      }
      case 'edit': {
        navigate(`/apps/${app_code}/features/edit/${data.id}`);
        break;
      }
      case 'delete': {
        deleteFeatureClick({ id: data.id, name: data.name });
        break;
      }
      case 'copyUrl': {
        copyFeatureUrl(data.uniqueCode, app_code, data.path);
        break;
      }
      case 'viewFeature': {
        navigate(`/apps/${app_code}/features/view/${data.id}`);
        break;
      }
    }
  }

  return (
    <div className="ag-theme-material h-[100%] px-[24px] p-[10px] mx-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      <div className="text-[24px]">{title}</div>
      <div className="flex-1">
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          onCellClicked={cellClicked}
          rowData={rowData}
        />
      </div>
    </div>
  );
};

export default FeatureGrid;
