import React, { useEffect, useState } from 'react';
import FormV2Container from '../../common/formV2/container/FormV2Container';
import getTraceSchema from '../utils/traceSchema';
import { noop } from 'lodash';
import keycloak from '../../../Keycloak';
import request from 'superagent';
import { useDispatch, useSelector } from 'react-redux';
import ConformationContainer from '../../common/conformationModal/containers';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import { TextField } from '@mui/material';

const TraceForm = ({ onFormSubmit = noop, fetchTraceData = noop }) => {
  const dispatch = useDispatch();
  const formStore = useSelector((store) => store?.store?.form?.formData) || {};
  const [searchByOptions, setSearchByOptions] = useState([]);
  const [searchText, setSearchText] = useState();

  const traceStore = formStore['Trace']?.['Trace-0'] || {};

  const searchBy = traceStore['Search By'];

  const selectSearchBy = traceStore['Select'];

  useEffect(() => {
    (async function () {
      if (searchBy == 'criteria') {
        const response = await request
          .post(
            `${process.env.REACT_APP_GENRIC_API}/generic/feature/criteria/fetchAll`
          )
          .send({})
          .set('Authorization', `Bearer ${keycloak.token()}`);

        if (response.body.status == 'success') {
          const result = response.body.response;
          setSearchByOptions(result);
        }
      } else {
        setSearchByOptions([{ name: 'No Value', value: '' }]);
      }
    })();
  }, [searchBy]);

  useEffect(() => {
    if (selectSearchBy && searchBy == 'criteria') {
      dispatch(
        genericModalStateUpdate({
          modalKey: ModalKeys.searchBy,
          isOpen: true
        })
      );
    }

    return () => {
      dispatch(
        genericModalStateUpdate({
          modalKey: ModalKeys.searchBy,
          isOpen: false
        })
      );
    };
  }, [selectSearchBy]);

  const traceSchema = getTraceSchema({ searchByOptions });

  const customComponent = (
    <div>
      <TextField value={searchText} onChange={(e) => setSearchText(e.target.value)} />
    </div>
  );

  const onModalSearchClick = () => {
    fetchTraceData({ data_field: selectSearchBy, data_value: searchText });
  };

  return (
    <div className="w-[100%]">
      <FormV2Container
        submitButtonName={'Find Trace'}
        schema={traceSchema}
        onSubmitForm={onFormSubmit}
      />
      <ConformationContainer
        type="submit"
        formData={{}}
        modalId={ModalKeys.searchBy}
        cancelObject={{ text: 'Cancel', callback: noop }}
        conformObject={{
          text: 'Search',
          callback: onModalSearchClick
        }}
        customComponent={customComponent}
        modalText={{
          header: `Enter ${selectSearchBy} Value`,
          desc: `Search the  ${selectSearchBy} you want to find`
        }}
      />
    </div>
  );
};

export default TraceForm;
