const getUpdatedFieldData = ({ path, data, value }) => {
  if (path.length == 0) {
    return {};
  }
  if (path.length == 1) {
    return { ...data, [path[0]]: value };
  }

  return {
    ...data,
    [path[0]]: getUpdatedFieldData({ path: path.slice(1), data: data[path[0]] || {}, value })
  };
};

export default getUpdatedFieldData;
