import React, { useRef, useState } from 'react';
import poweredByRealWare from '../../../public/icons/Poweredby.svg';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Joi from 'joi';
import request from 'superagent';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import ErrorNotify from '../../../modules/notify/ErrorNotify';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [errorObj, setErrorObj] = useState({ email: '', password: '' });
  const [credentails, setCredentails] = useState({ email: '', password: '' });

  function onFieldValueChange(event) {
    const type = event.target.type;
    const value = event.target.value;

    const joiSchema = Joi.object({
      email: Joi.string().min(5).max(50).required()
    });

    switch (type) {
      case 'email': {
        const emailValidation = joiSchema.validate({ email: value });
        setErrorObj({
          ...errorObj,
          email: emailValidation.error ? emailValidation.error.message : ''
        });
        break;
      }
    }

    setCredentails({
      ...credentails,
      [type]: value
    });
  }

  async function onFormSubmit() {
    const joiSchema = Joi.object({
      email: Joi.string().min(5).max(50).required(),
      password: Joi.string().min(1).max(50).required()
    });

    const validateForm = joiSchema.validate(credentails);

    if (false) {
      const type = validateForm?.error?.details[0]?.context?.key;
      setErrorObj({
        ...errorObj,
        [type]: validateForm?.error.message
      });
    } else {
      setErrorObj({
        email: '',
        password: ''
      });
      const email = credentails.email;
      const password = credentails.password;
      try {
        const token = await request
          .post(
            `${process.env.REACT_APP_KEYCLOAK_DOMAIN}/realms/${process.env.REACT_APP_RELM}/protocol/openid-connect/token`
          )
          .set('content-type', 'application/x-www-form-urlencoded')
          .send({
            username: email,
            password: password,
            grant_type: process.env.REACT_APP_GRANT_TYPE,
            client_id: process.env.REACT_APP_CLIENT_ID,
            redirect_url: process.env.REACT_APP_REDIRECT_URL
          });

          if (token.status == 200) {
            const expiresIn = Date.now() + token.body.expires_in * 1000; // Convert seconds to milliseconds
            sessionStorage.setItem("expiretokenTime", expiresIn);
            console.log("token data is ....", token.body.expires_in);
          
            const userData = await request
              .get(
                `${process.env.REACT_APP_KEYCLOAK_DOMAIN}/realms/${process.env.REACT_APP_RELM}/protocol/openid-connect/userinfo`
              )
              .set('content-type', 'application/x-www-form-urlencoded')
              .set('Authorization', `Bearer ${token?.body?.access_token}`);
          
            if (userData.body && userData.status) {
              console.log("Data is .....", userData.body);
              localStorage.setItem(
                'RW_AUTH_DATA',
                JSON.stringify({
                  access_token: token.body.access_token,
                  ...userData.body
                })
              );
              navigate('/dashboard');
            }
          }
          
      } catch (error) {
        switch (error.message) {
          case 'Unauthorized': {
            toast.custom(
              (t) => <ErrorNotify t={t} message={'Please supply correct credentials'} />,
              {
                duration: 4000
              }
            );
          }
        }
      }
    }
  }

  return (
    <div className="bg-[url('https://config.demo.realdemand.realware.app/assets/realware-cover-page.png')] h-[100vh] bg-cover flex justify-center items-center flex-col">
      <div className="w-[1200px] h-[500px] flex p-[40px]">
        <div className="bg-white w-[50%] flex justify-center items-center rounded-l-[20px] pl-[40px]">
          <img
            src={'https://config.demo.realdemand.realware.app/assets/Realware-new.png'}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white w-[50%] rounded-r-[20px] pl-[64px] pt-[40px]">
          <div className="text-[#355B3E] text-[16px] mt-[16px]">Please login to your account</div>
          <div className="flex flex-col mt-[36px]">
            <FormControl sx={{ m: 1, width: '422px' }} variant="outlined">
              <div className="mb-2">Email</div>
              <OutlinedInput
                value={credentails.email}
                error={!!errorObj.email}
                onChange={onFieldValueChange}
                type="email"
                sx={{ borderRadius: '8px' }}
                id="Email"
                placeholder="Email"
              />
              <span className="mt-2 text-red-500">{errorObj.email}</span>
            </FormControl>
            <FormControl sx={{ m: 1, width: '422px' }} variant="outlined">
              <div className="mb-2">Password</div>
              <OutlinedInput
                value={credentails.password}
                onChange={onFieldValueChange}
                sx={{ borderRadius: '8px' }}
                placeholder="Password"
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <span className="mt-2 text-red-500">{errorObj.password}</span>
            </FormControl>
            <Button
              onClick={onFormSubmit}
              className="!text-[16px] w-[200px] h-[48px] !rounded-[8px] !m-[8px] !font-semibold !normal-case"
              variant="contained">
              Login
            </Button>
          </div>
        </div>
      </div>
      <a href="https://www.realvariable.com/">
        <div className="mt-[40px] flex justify-center items-center">
          <div>
            <img className="w-[300px]" src={poweredByRealWare} alt="" srcset="" />
          </div>
        </div>
      </a>
    </div>
  );
};

export default Login;
