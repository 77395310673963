import request from 'superagent';
import {
  getSourceActionsUrl,
  orchestrationDataObjectsUrl
} from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const getOrchestrationDataObjects = ({ appCode = '' }) => {
  return async () => {
    const res = await request
      .get(orchestrationDataObjectsUrl)
      .query({ type: 'DATA_OBJECT', appCode: appCode })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

const getSourceActions = () => {
  return async () => {
    const res = await request
      .get(getSourceActionsUrl)
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

export { getOrchestrationDataObjects, getSourceActions };
