import CloseIcon from '@mui/icons-material/Close';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import HLFViewFileForm from '../../HyperledgerFabricNetworks/component/HLFViewFileForm';
import ConfigFileDrop from '../../common/fileDrop/ConfigFileDrop';
import WalletFileDropZone from '../../common/fileDrop/WalletFileDrop';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';

export default function WalletTabs({ name, fetchOrganizationData, fetchHLFData }) {
  const [value, setValue] = React.useState('1');
  const dispatch = useDispatch();
  const [fileData, setFileData] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  var currentComponent;

  switch (name) {
    case 'config': {
      currentComponent = (
        <TabPanel value="1">
          <ConfigFileDrop fetchHLFData={fetchHLFData} fetchOrganizationData={fetchOrganizationData} setFileData={setFileData} />
          <div className="mt-4 max-h-[200px] overflow-auto overflow-x-hidden pr-4">
            {/* {fileData ? <div className="mt-2 overflow-scroll">{JSON.stringify(beautify(fileData))}</div> : null} */}
            {fileData ? <HLFViewFileForm data={fileData} /> : null}
          </div>
        </TabPanel>
      );
      break;
    }
    case 'wallet': {
      currentComponent = (
        <TabPanel value="1">
          <WalletFileDropZone fetchHLFData={fetchHLFData} fetchOrganizationData={fetchOrganizationData} />
        </TabPanel>
      );
      break;
    }
  }

  return (
    <div className="w-[40%] m-auto bg-white p-4 rounded-[8px] top-[10px] relative">
      <div
        className="w-auto float-right cursor-pointer relative"
        onClick={() =>
          dispatch(
            genericModalStateUpdate({
              modalKey: name,
              isOpen: false
            })
          )
        }>
        <CloseIcon />
      </div>
      <Box sx={{ width: '100%', typography: 'body1' }}>{currentComponent}</Box>
    </div>
  );
}
