import React from 'react';
import ViewCards from './ViewCards';

const ViewObjectMapping = ({ objectMappingData = [], refetchOnDelete }) => {
  const viewData = {};

  objectMappingData.map((data) => {
    const prevData = viewData[data?.sourceObjectId] || {};
    const prevMapping = prevData?.mapping || [];

    viewData[data?.sourceObjectId] = {
      sourceId: data?.sourceObjectId,
      sourceName: data?.sourceObjectCode,
      mapping: [
        ...prevMapping,
        {
          targetName: data?.targetObjectCode,
          targetId: data?.targetObjectId,
          mappingId: data?.Id,
          status: data?.status,
          version: data?.version
        }
      ]
    };
  });

  return (
    <div className="flex justify-start flex-wrap flex-1 py-[20px] rounded-[20px] mt-[20px] px-8 ">
      {Object.keys(viewData).map((data) => {
        return (
          <div className="w-[30%] h-[450px] p-[32px] rounded-[8px] bg-white flex flex-col justify-between mb-[20px] mr-4">
            <div className="text-[32px] text-[#333333] font-extrabold truncate">
              {viewData[data].sourceName}
            </div>
            <ViewCards
              refetchOnDelete={refetchOnDelete}
              sourceData={viewData[data]}
              childrens={viewData[data].mapping}
            />
          </div>
        );
      })}
    </div>
  );
};

// {Object.keys(viewData).map((data) => {
//   return (
//     <div className="h-[200px] mr-[20px] mt-[20px] justify-between items-center flex py-[20px] px-[40px] shadow-2xl bg-white rounded-2xl">
//       <div className="p-[10px] mr-[28px] bg-slate-200 rounded-lg w-[200px] truncate">
//         {viewData[data].sourceName}
//       </div>
//       <div className="mt-[20px] h-[100%] overflow-auto flex flex-col w-[260px] overflow-x-clip">
//         {viewData[data].mapping.map((map) => {
//           return (
//             <div className="p-[10px] diagHover my-[8px] bg-slate-200 rounded-lg w-[100%] whitespace-nowrap">
//               {map?.targetName}
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// })}

export default ViewObjectMapping;
