import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import errorMessageFormatter from '../utils/errorMessageFormatter';
import { FormControl, FormControlLabel, InputLabel } from '@mui/material';
import MultiselectContainer from '../../multiselectDropdown/container';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Switch from '@mui/material/Switch';

const FormField = ({
  field,
  handleChange,
  value,
  formFieldData = {},
  isFormDisabled = false,
  className
}) => {
  const {
    name,
    label,
    type,
    options = [],
    required,
    defaultValue = '',
    switchChecked = false,
    disabled
  } = field;
  const { errorState: { errorMessage = '' } = {} } = formFieldData || {};
  const { value: fieldValue } = value;
  const isError = errorMessage ? true : false;
  const helperText = isError ? errorMessageFormatter(errorMessage, name) : '';

  const alignment = fieldValue ? fieldValue : '';

  if (!options.length) {
    options.push('');
  }

  switch (type) {
    case 'text':
      return (
        <TextField
          className={className}
          error={isError}
          helperText={helperText}
          defaultValue={defaultValue}
          disabled={isFormDisabled || disabled}
          value={fieldValue}
          key={name}
          name={name}
          label={label}
          type="text"
          onChange={handleChange}
          fullWidth
          required={required}
        />
      );
    case 'checkbox':
      return (
        <FormControlLabel
          key={name}
          name={name}
          label={label}
          onChange={(e) => {
            handleChange({ target: { name: name, value: e.target.checked } });
          }}
          disabled={isFormDisabled || disabled}
          control={<Checkbox />}
        />
      );
    case 'multiselect-dropdown':
      return (
        <MultiselectContainer
          name={name}
          handleChange={handleChange}
          disabled={isFormDisabled || disabled}
        />
      );
    case 'dropdown':
      return (
        <FormControl className="!min-w-[150px]">
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            disabled={isFormDisabled || disabled}
            defaultValue={defaultValue}
            key={name}
            onChange={handleChange}
            name={name}
            autoWidth
            required={required}>
            {options.map((option) => {
              const { name, value } = option || {};
              return (
                <MenuItem key={name} value={value}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    case 'toggle':
      return (
        <div class="flex">
          <div class="mr-4 flex items-center">{name}</div>
          <ToggleButtonGroup
            style={{ height: '56px' }}
            disabled={isFormDisabled || disabled}
            defaultValue={defaultValue}
            color="primary"
            exclusive
            onChange={handleChange}
            key={name}
            name={name}
            value={alignment}
            aria-label="Platform">
            {options.map((option, index) => (
              <ToggleButton name={name} value={option}>
                {option}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      );
    case 'switch':
      return <Switch checked={switchChecked} onChange={handleChange} name={name} />;
    default:
      return null;
  }
};

export default FormField;
