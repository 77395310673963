const traceSchema = ({ searchByOptions }) => {
  const searchByOptionsUpdated = searchByOptions.map((element) => {
    return {
      name: element.criteria_name,
      value: element.criteria_fieldname
    };
  });

  return {
    title: 'Trace',
    hideTitle: true,
    formSchema: [
      {
        name: 'Trace',
        type: 'Fields',
        duplicate: false,
        varient: 'full',
        data: {
          0: [
            {
              name: 'From Date',
              label: 'From Date :',
              type: 'date',
              required: true,
              defaultValue: ''
            },
            {
              name: 'To Date',
              label: 'To Date :',
              type: 'date',
              required: true,
              defaultValue: ''
            },
            {
              name: 'Search By',
              label: 'Search By :',
              type: 'radio',
              required: true,
              options: [
                {
                  name: 'Feature',
                  value: 'feature'
                },
                {
                  name: 'Criteria',
                  value: 'criteria'
                }
              ],
              defaultValue: 'feature'
            },
            {
              name: 'Select',
              label: '',
              type: 'dropdown',
              required: true,
              options: searchByOptionsUpdated,
              defaultValue: ''
            }
          ]
        }
      }
    ]
  };
};

export default traceSchema;
