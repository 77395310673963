import request from 'superagent';
import { editApplicationUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const editApp = async ({ payload, query }) => {
  const res = await request
    .put(editApplicationUrl)
    .query(query)
    .send(payload)
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${keycloak.token()}`);

  if (res.body.status.code == 'SUCCESS') {
    console.log('Form data edited successfully');
  } else {
    console.log(res.body.status.message);
  }

  return res;
};

export default editApp;
