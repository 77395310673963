import React, { useCallback, useMemo } from 'react';
import { getConnectedEdges, Handle, useNodeId, useStore, Position } from 'reactflow';
import db from '../../../public/flowIcons/db.png';
import http from '../../../public/flowIcons/http.png';
import leaf from '../../../public/flowIcons/leaf.png';
import split from '../../../public/flowIcons/split.png';
import script from '../../../public/flowIcons/script.png';
import start from '../../../public/flowIcons/start.png';
import subflow from '../../../public/flowIcons/subflow.png';
import sync from '../../../public/flowIcons/sync.png';
import value from '../../../public/flowIcons/value.png';
import wait from '../../../public/flowIcons/wait.png';
import condition from '../../../public/flowIcons/condition.png';
import iterative from '../../../public/flowIcons/iterative.png';

const selector = (state) => ({
  nodeInternals: state.nodeInternals,
  edges: state.edges
});

const icons = {
  Start: start,
  Split: split,
  Script: script,
  End: leaf,
  Join: sync,
  Wait: wait, 
  Http: http,
  SubFlow: subflow,
  Db: db,
  Dt: value,
  Condition: condition,
  IterativeFlow: iterative
};

const CustomHandle = (props) => {
  const { nodeInternals, edges } = useStore(selector);
  const nodeId = useNodeId();

  const isHandleConnectable = useMemo(() => {
    const node = nodeInternals.get(nodeId);
    const connectedEdges=getConnectedEdges([node], edges);

    if (typeof props.isConnectable === 'function') {
      return props.isConnectable({ node, connectedEdges });
    }

    if (typeof props.isConnectable === 'number') {
      return connectedEdges.length < props.isConnectable;
    }

    return props.isConnectable;
  }, [nodeInternals, edges, nodeId, props.isConnectable]);

  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  let  nodeLabel = capitalizeFirstLetter(props.data.label);
  if(nodeLabel == "Data Transformation") {
    nodeLabel="Dt"
  }
  

  return (
    <div>
      {nodeLabel !== 'Start' && (
        <Handle
          type="target"
          position={Position.Left}
          isConnectable={isHandleConnectable}
          className="w-16 !bg-teal-500"
        />
      )}
      <div>
        {icons[nodeLabel] && (
          <img
            style={{
              border: '1px solid black',
              borderRadius: '5px',
              padding: '2px',
              alignItems: 'center'
            }}
            src={icons[nodeLabel]}
            alt={nodeLabel}
            width={30}
            height={30}
          />
        )}
        <input
          placeholder="Node Type"
          style={{
            height: '12px',
            width: '30px',
            fontSize: '6px',
            fontWeight: 'bold',
            border: '1px solid black',
            position: 'absolute',
            marginTop: '2px',
            pointerEvents: 'all'
          }}
          className="nodrag nopan"
        />
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        isConnectable={isHandleConnectable}
        className="w-16 !bg-teal-500"
      />
    </div>
);
};

export default CustomHandle;
