import {
  AppBar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
  selectClasses
} from '@mui/material';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility as VisibilityIcon
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller, useFieldArray } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { SetSplitCounter, pushEditFlowData, pushFlowData } from './FlowSlice';
import { useDispatch, useSelector } from 'react-redux';
import FormStyles from './FormStyles';
import FormCreate from './FormCreate';
import { result } from 'lodash';
import axios from 'axios';
import { Form, Field, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

// ICON
import Dbicon from '../../../public/icons/bdIcon.svg';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

const FormSection = ({ selectedNodes }) => {
  // console.log('jbj', selectedNodes);
  const matter = sessionStorage.getItem('metaData');

  const formData = useSelector((state) => state);
  const metaData = useSelector((state) => state.store.reactflow.metaData);
  const workFlowList = useSelector((state) => state.store.reactflow.workFlowList[0]);
  // console.log('workFlowList 1098 :-', workFlowList);
  const metaid = metaData[0].metaid;
  const metaname = metaData[0].metaname;
  const metadesc = metaData[0].metadesc;
  const variablesMeta = metaData[0].variables;

  const [selections, setSelections] = useState({});

  const handleSelectionChange = (index, event) => {
    const { name, value } = event.target;
    setSelections((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        [name]: value
      }
    }));
  };

  const handleDropdownChange = (event) => {
    console.log('In handleDropdownChange');
    console.log('eventtt', event.target.value);
    const selectedValue = event.target.value;
    console.log('workFlowListtt', workFlowList);
    const selectedItem = workFlowList.find((item) => item.id === selectedValue);
    console.log('selectedItem :- ', selectedItem);
    if (selectedItem) {
      fieldss(selectedItem.id, selectedItem.version);
    }
    // createSubflow();
  };

  const [varKeys, setVarKeys] = useState([]);
  const fieldss = (id, version) => {
    console.log('IDDDDDDDD', id);
    axios
      .get(`${process.env}/workerFlow/subFlowId/${id}`)
      .then((res) => setVarKeys(res.data.primary))
      .catch((err) => console.log(err));
  };
  // console.log('varKeys 1098:- ', varKeys);
  let apiData = [];
  const dispatch = useDispatch();
  const [selectedNodeData, setSelectedNodeData] = useState(null);
  // const [showSubmit, setShowSubmit] = useState(selectedNodes?.data.data.type);

  // const [sequenceData, setSequenceData] = useState([]);

  useEffect(() => {
    // let target=[]
    // selectedEdges.data.nodeData.map((data)=>target.push(data.edgeData.target))
    // console.log(target)
  }, []);

  // console.log('Type of node is ', selectedNodes?.data.type);
  // console.log('Type of node is ', selectedNodes);

  const [splitCount, setSplitCount] = useState(1);
  const [addsplit, setSplitAdd] = useState(1);

  const [syncCount, setSyncCount] = useState(1);
  const [addsync, setSyncAdd] = useState(1);

  const getdata = sessionStorage.getItem('metaData');
  const createHttpConfigData = (data) => {
    console.log(data);
    let keys = Object.keys(data[0]);
    console.log('keys', keys);
    // let values = Object.values(data);
    let Obj = [];
    for (let j = 0; j < keys.length; j++) {
      Obj.push({
        key: keys[j],
        value: data[0][keys[j]]
      });
    }
    console.log(Obj);
    return Obj;
  };
  const createConfigdata = (data) => {
    console.log(data);
    const obj = [];

    const gettype = data[0].splittype;
    data.map((config) => {
      if (gettype == 'withcondition') {
        obj.push({
          key: 'expression',
          val1: config.val1,
          val2: config.val2,
          op: config.op,
          value: config.value
        });
      } else {
        obj.push({
          key: 'split',
          value: config.nodeid,
          name: config.branch
        });
      }
    });

    obj.push({
      key: 'Splittype',
      value: gettype
    });

    console.log('obj', obj);
    return obj;
  };
  const createwaitConfig = (data) => {
    console.log(data);
    let keys = Object.keys(data[0]);
    console.log('keys', keys);
    // let values = Object.values(data);
    let Obj = [];
    for (let j = 0; j < data.length; j++) {
      for (let i = 0; i < keys.length; i++) {
        Obj.push({
          key: keys[i],
          value: data[j][keys[i]]
        });
      }
    }
    console.log(Obj);
    let datasss = [Obj];
    console.log(datasss);
    const filteredValues = data.map((config) => {
      if (config.trigger === 'external') {
        return [
          {
            key: 'trigger',
            value: 'external'
          },
          {
            key: 'type',
            value: config.type
          }
        ];
      } else {
        return [
          {
            key: 'trigger',
            value: 'delay'
          },
          {
            key: 'type',
            value: config.type
          },
          {
            key: 'ms',
            value: config.ms
          }
        ];
      }
    });

    // Flatten the array of arrays into a single array
    const flattenedValues = filteredValues.flat();

    // Log or submit the flattened values
    console.log('Filtered and Flattened Form Values:', flattenedValues);
    return flattenedValues;
  };
  const createSyncConfigdata = (data) => {
    console.log(data);
    let keys = Object.keys(data[0]);
    console.log('keys', keys);
    // let values = Object.values(data);
    let Obj = [];

    for (let j = 0; j < data.length; j++) {
      console.log(data[j]['sync']);
      Obj.push({
        key: 'sync',
        value: data[j]['sync']
      });
    }
    Obj.push({
      key: 'syncType',
      value: data[0]['checkbox'] == true ? 'all' : 'any'
    });
    console.log(Obj);
    return Obj;
  };
  const createDtData = (data) => {
    console.lo;
    let payload = [
      {
        key: 'type',
        type: data[0].type,
        value: ''
      },
      {
        key: 'write',
        type: 'data',
        value: ''
      },
      {
        key: 'read',
        type: 'data',
        value: data[0].value
      }
    ];
    return payload;
  };
  const createScriptConfigData = (data) => {
    let ans = [
      {
        key: 'type',
        type: 'data',
        value: data[0].functionDef
      },
      {
        key: 'function',
        value: data[0].functionCall
      }
    ];
    return ans;
  };
  const createIterativeData = (data) => {
    let payload = [
      {
        key: 'workerId',
        value: data[0].workerid,
        count: data[0].count
      }
    ];
    return payload;
  };
  const createSubflow = (data) => {
    let obj = [
      {
        key: 'workerId',
        value: data[0].workerid
      }
    ];
    console.log('createSubflow data', obj);
    return obj;
  };
  const createsubflowVar = (data) => {
    console.log('339 mit data', data);
    console.log(varKeys);
    let result = {};
    varKeys.forEach((key) => {
      if (data[0].hasOwnProperty(key)) {
        result[key] = data[0][key];
      }
    });
    console.log('351111111111111111', result);
    console.log(createHttpConfigData([result])[0]);
  };
  const getConfig = (name, data) => {
    console.log(name, data);
    if(data==undefined){
      data=[]
      return data
    }
    switch (name) {
      case 'start' :
        return data;
      case 'leaf':
        return data;
      case 'split':
        return createConfigdata(data);
      case 'http':
        return createHttpConfigData(data);
      case 'script':
        return createScriptConfigData(data);
      case 'sync':
        return createSyncConfigdata(data);
      case 'subFlow':
        return createSubflow(data);
      case 'db':
        return createHttpConfigData(data);
      case 'dt':
        return createDtData(data);
      case 'iterativeFlow':
        return createIterativeData(data);
      case 'wait':
        return createwaitConfig(data);
      default:
        throw new Error('Invalid name');
    }
  };

  const onSubmit = (id, data) => {
    let formdata = {};
    Object.keys(data).forEach((key) => {
      if (key === id) {
        formdata['sequence'] = data[key];
      } else if (key.startsWith(`${id}+`)) {
        const newKey = key.split('+')[1];
        formdata[newKey] = data[key];
      }
    });
    console.log('214', formdata);
    let dataObj = {};
    console.log('start Configs', selectedNodes);
    dataObj = {
      id: selectedNodes.id,
      name: selectedNodes.data.data.name,
      type: selectedNodes.data.data.type,
      assign: formdata.assign || [],
      variables:
        selectedNodes.data.data.type == 'subFlow'
          ? createsubflowVar(formdata.variables)
          : formdata.variables ||[],
      configurations: getConfig(selectedNodes.data.data.type, formdata.configurations),
      sequence: formdata.sequence || [],
      position: selectedNodes.position
    };

    dispatch(pushFlowData(dataObj));

    console.log('start data', dataObj);

    alert('Data Has Been Saved!');
  };

  const submit = () => {
    let payload = formData.store.reactflow.flowData;

    let id = selectedNodes.id;

    // let data = {
    //   id: metaid,
    //   name: metaname,
    //   description: metadesc,
    //   variables: variablesMeta,
    //   activities: payload,
    //   status:'draft',
    //   version:"v1.1"
    // };
    // console.log('payload data is', data, metaData);
    axios
      .post(
        `${process.env.REACT_APP_WORKFLOW}/workerFlow/submitworkflow`,

        {
          id: metaid,
          name: metaname,
          description: metadesc,
          variables: variablesMeta,
          activities: payload,
          status: 'Draft',
          version: 'v1.1'
        },
        {}
      )
      .then((res) => {
        console.log();
        alert('WorkFlow Has Been saved..👍');
      })
      .catch((err) => console.log(err));
  };

  const [workerid, setWorkerId] = useState('');

  const [status, setStaus] = useState('success');
  const onStatusChange = (e) => {
    setStaus(e.target.value);
  };

  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleAddCondition = () => {
    appendCondition({ key: 'expression', val1: '', val2: '', op: '', value: '' });
  };

  const onubmit = async (values) => {
    await sleep(300);
    window.alert(JSON.stringify(values, 0, 2));
  };

  const [configSelections, setConfigSelections] = useState({});

  const handleConfigSelectionChange = (index, event) => {
    const { name, value } = event.target;
    setConfigSelections((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        [name]: value
      }
    }));
  };

  function form() {
    switch (selectedNodes.data.data.type) {
      case 'leaf':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        End Node : {selectedNodes.id}
                      </Typography>

                      <Button variant="contained" color="primary" type="submit" onClick={submit}>
                        Submit
                      </Button>
                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                    </Paper>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key{index + 1}
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value{index + 1}
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'start':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        Start Node : {selectedNodes.id}
                      </Typography>

                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                    </Paper>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          {/* {fields == undefined ||fields.value ==undefined && */}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                          {/* } */}
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key{index + 1}
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value{index + 1}
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            // disabled={!fields || fields.length === 0}
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ value: '', key: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );

      case 'db':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        DataBase Node : {selectedNodes.id}
                      </Typography>
                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                    </Paper>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Database Type
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.type`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Host
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.host`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Connection
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.connection`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    UserName
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.username`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Password
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.password`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    port
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.port`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Database
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.database`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Table
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.table`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Method
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.method`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    query
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.query`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({})}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'split':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        Split Node : {selectedNodes.id}
                      </Typography>

                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                {index === 0 && (
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label>
                                      <Field
                                        name={`${name}.splittype`}
                                        component="input"
                                        type="radio"
                                        value="withcondition"
                                      />
                                      With Condition
                                    </label>
                                    <label>
                                      <Field
                                        name={`${name}.splittype`}
                                        component="input"
                                        type="radio"
                                        value="withoutcondition"
                                      />
                                      Without Condition
                                    </label>
                                  </div>
                                )}
                                {fields.value[0].splittype == 'withoutcondition' ? (
                                  <>
                                    <div>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label
                                          style={{
                                            fontSize: '16px',
                                            color: 'black',
                                            fontWeight: 'bold'
                                          }}>
                                          Split
                                        </label>
                                        <Field
                                          style={{ padding: 3, border: '2px solid blue' }}
                                          name={`${name}.split`}
                                          defaultValue="split"
                                          component="input"
                                          disabled
                                        />
                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label
                                          style={{
                                            fontSize: '16px',
                                            color: 'black',
                                            fontWeight: 'bold'
                                          }}>
                                          NodeID
                                        </label>
                                        <Field
                                          style={{ padding: 3, border: '2px solid blue' }}
                                          name={`${name}.nodeid`}
                                          component="input"
                                          placeholder="Value"
                                        />
                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label
                                          style={{
                                            fontSize: '16px',
                                            color: 'black',
                                            fontWeight: 'bold'
                                          }}>
                                          BranchName
                                        </label>
                                        <Field
                                          style={{ padding: 3, border: '2px solid blue' }}
                                          name={`${name}.branch`}
                                          component="input"
                                          placeholder="Value"
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <label
                                        style={{
                                          fontSize: '16px',
                                          color: 'black',
                                          fontWeight: 'bold'
                                        }}>
                                        Key
                                      </label>
                                      <Field
                                        style={{ padding: 3, border: '2px solid blue' }}
                                        name={`${name}.key`}
                                        defaultValue="expression"
                                        component="input"
                                        disabled
                                      />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <label
                                        style={{
                                          fontSize: '16px',
                                          color: 'black',
                                          fontWeight: 'bold'
                                        }}>
                                        Value 1
                                      </label>
                                      <Field
                                        style={{ padding: 3, border: '2px solid blue' }}
                                        name={`${name}.val1`}
                                        component="input"
                                        placeholder="Value"
                                      />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <label
                                        style={{
                                          fontSize: '16px',
                                          color: 'black',
                                          fontWeight: 'bold'
                                        }}>
                                        Value 2
                                      </label>
                                      <Field
                                        style={{ padding: 3, border: '2px solid blue' }}
                                        name={`${name}.val2`}
                                        component="input"
                                        placeholder="Value 2"
                                      />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <label
                                        style={{
                                          fontSize: '16px',
                                          color: 'black',
                                          fontWeight: 'bold'
                                        }}>
                                        Conditional Operator
                                      </label>
                                      <Field
                                        style={{ padding: 3, border: '2px solid blue' }}
                                        name={`${name}.op`}
                                        component="input"
                                        placeholder="Value"
                                      />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <label
                                        style={{
                                          fontSize: '16px',
                                          color: 'black',
                                          fontWeight: 'bold'
                                        }}>
                                        Node ID
                                      </label>
                                      <Field
                                        style={{ padding: 3, border: '2px solid blue' }}
                                        name={`${name}.value`}
                                        component="input"
                                        placeholder="Value"
                                      />
                                    </div>
                                  </>
                                )}

                                {/* <Field name={`${name}.splittype`} subscription={{ value: true }}>
                                  {({ input: { value: splittype } }) => (
                                    <>
                                      {splittype === 'withoutcondition' ? (
                                        <div>
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label
                                              style={{
                                                fontSize: '16px',
                                                color: 'black',
                                                fontWeight: 'bold'
                                              }}>
                                              Split
                                            </label>
                                            <Field
                                              style={{ padding: 3, border: '2px solid blue' }}
                                              name={`${name}.split`}
                                              defaultValue="split"
                                              component="input"
                                              disabled
                                            />
                                          </div>
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label
                                              style={{
                                                fontSize: '16px',
                                                color: 'black',
                                                fontWeight: 'bold'
                                              }}>
                                              NodeID
                                            </label>
                                            <Field
                                              style={{ padding: 3, border: '2px solid blue' }}
                                              name={`${name}.nodeid`}
                                              component="input"
                                              placeholder="Value"
                                            />
                                          </div>
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label
                                              style={{
                                                fontSize: '16px',
                                                color: 'black',
                                                fontWeight: 'bold'
                                              }}>
                                              BranchName
                                            </label>
                                            <Field
                                              style={{ padding: 3, border: '2px solid blue' }}
                                              name={`${name}.branch`}
                                              component="input"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label
                                              style={{
                                                fontSize: '16px',
                                                color: 'black',
                                                fontWeight: 'bold'
                                              }}>
                                              Key
                                            </label>
                                            <Field
                                              style={{ padding: 3, border: '2px solid blue' }}
                                              name={`${name}.key`}
                                              defaultValue="expression"
                                              component="input"
                                              disabled
                                            />
                                          </div>
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label
                                              style={{
                                                fontSize: '16px',
                                                color: 'black',
                                                fontWeight: 'bold'
                                              }}>
                                              Value 1
                                            </label>
                                            <Field
                                              style={{ padding: 3, border: '2px solid blue' }}
                                              name={`${name}.val1`}
                                              component="input"
                                              placeholder="Value"
                                            />
                                          </div>
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label
                                              style={{
                                                fontSize: '16px',
                                                color: 'black',
                                                fontWeight: 'bold'
                                              }}>
                                              Value 2
                                            </label>
                                            <Field
                                              style={{ padding: 3, border: '2px solid blue' }}
                                              name={`${name}.val2`}
                                              component="input"
                                              placeholder="Value 2"
                                            />
                                          </div>
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label
                                              style={{
                                                fontSize: '16px',
                                                color: 'black',
                                                fontWeight: 'bold'
                                              }}>
                                              Conditional Operator
                                            </label>
                                            <Field
                                              style={{ padding: 3, border: '2px solid blue' }}
                                              name={`${name}.op`}
                                              component="input"
                                              placeholder="Value"
                                            />
                                          </div>
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label
                                              style={{
                                                fontSize: '16px',
                                                color: 'black',
                                                fontWeight: 'bold'
                                              }}>
                                              Node ID
                                            </label>
                                            <Field
                                              style={{ padding: 3, border: '2px solid blue' }}
                                              name={`${name}.value`}
                                              component="input"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </Field> */}
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ splittype: 'withoutcondition' })} // Initialize with default value
                          >
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'sync':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        Sync Node : {selectedNodes.id}
                      </Typography>

                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    {/* <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <div>
                                <label>Synchronized Node ID</label>
                                <Field
                                  name={`${name}.sync`}
                                  component="input"
                                  placeholder="Value"
                                />
                                <Field
                                  name={`${name}.checkbox`}
                                  type="checkbox"
                                  component="input"
                                />
                                <button type="button" onClick={() => fields.remove(index)}>
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                          <button
                            type="button"
                            onClick={() => {
                              // Check if there's already a non-empty `sync` field
                              // const alreadyExists = fields.some((field) => !!field.sync);

                              // if (!alreadyExists) {
                                fields.push({ sync: '', checkbox: false });
                              // }
                            }}>
                            Add Sync Node
                          </button>
                        </div>
                      )}
                    </FieldArray> */}
                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {index === 0 && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      marginRight: 5
                                    }}>
                                    <label
                                      style={{
                                        fontSize: '16px',
                                        color: 'black',
                                        fontWeight: 'bold'
                                      }}>
                                      Select All
                                    </label>
                                    <Field
                                      style={{ padding: 3, border: '2px solid blue' }}
                                      name={`${name}.checkbox`}
                                      type="checkbox"
                                      component="input"
                                    />
                                  </div>
                                )}
                                <label
                                  style={{
                                    fontSize: '16px',
                                    color: 'black',
                                    fontWeight: 'bold'
                                  }}>
                                  Synchronized Node ID
                                </label>
                                <Field
                                  style={{ padding: 3, border: '2px solid blue' }}
                                  name={`${name}.sync`}
                                  component="input"
                                  placeholder="Value"
                                />

                                <button type="button" onClick={() => fields.remove(index)}>
                                  ❌
                                </button>
                              </div>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                              fields.push({ sync: '', checkbox: fields.length === 0 });
                            }}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'script':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        Script Node : {selectedNodes.id}
                      </Typography>

                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Function Defination
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.functionDef`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Function Call
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.functionCall`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>

                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ functionDef: '', functionCall: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'wait':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        Wait Node : {selectedNodes.id}
                      </Typography>

                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div
                              key={name}
                              style={{
                                border: '1px solid #ddd',
                                padding: '15px',
                                marginBottom: '10px'
                              }}>
                              <div style={{ marginBottom: '10px' }}>
                                <label htmlFor={`config-first-dropdown-${index}`}>Type</label>
                                <Field
                                  name={`${name}.trigger`}
                                  component="select"
                                  id={`config-first-dropdown-${index}`}
                                  // onChange={(event) => handleConfigSelectionChange(index, event)}
                                  // value={configSelections[index]?.type || ''}
                                >
                                  <option value="">Select type</option>
                                  <option value="external">External</option>
                                  <option value="delay">Delay</option>
                                </Field>
                              </div>

                              {/* Conditionally render fields based on the trigger value */}
                              {fields.value[index]?.trigger === 'external' && (
                                <Field name={`${name}.type`} component="select">
                                  <option value="">Select option</option>
                                  <option value="http">HTTP</option>
                                  <option value="kafka">Kafka</option>
                                </Field>
                              )}

                              {fields.value[index]?.trigger === 'delay' && (
                                <>
                                  <Field name={`${name}.type`} component="select">
                                    <option value="">Select option</option>
                                    <option value="time">Time</option>
                                    <option value="timeout">Timeout</option>
                                  </Field>
                                  <div style={{ marginBottom: '10px' }}>
                                    <label htmlFor={`milliseconds-${index}`}>Milliseconds</label>
                                    <Field
                                      name={`${name}.ms`}
                                      component="input"
                                      type="number"
                                      placeholder="Milliseconds"
                                      style={{ padding: '5px', width: '100%' }}
                                      id={`milliseconds-${index}`}
                                    />
                                  </div>
                                </>
                              )}

                              <button type="button" onClick={() => fields.remove(index)}>
                                ❌ Remove
                              </button>
                            </div>
                          ))}
                          <button
                            // disabled={fields.value.length}
                            type="button"
                            onClick={() => {
                              // const newIndex = fields.length; // Determine the new index
                              fields.push({ trigger: '', type: '', ms: '' });
                            }}>
                            ➕ Add Configuration
                          </button>
                        </div>
                      )}
                    </FieldArray>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'http':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        HTTP Node : {selectedNodes.id}
                      </Typography>

                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Method
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.method`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Header
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.header`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Endpoint
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.url`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Body
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.body`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() =>
                              fields.push({ method: '', header: '', endpoint: '', body: '' })
                            }>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'subFlow':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }}>
                        SubFlow : {selectedNodes.id}
                      </Typography>

                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    WorkerID
                                  </label>
                                  <Field name={`${name}.workerid`}>
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        style={{
                                          padding: '5px',
                                          border: '2px solid blue',
                                          borderRadius: '5px',
                                          marginLeft: '10px'
                                        }}
                                        onChange={(event) => {
                                          input.onChange(event); // Call react-final-form's onChange
                                          handleDropdownChange(event);
                                        }}>
                                        <option value="" disabled>
                                          Select Workflow
                                        </option>
                                        {workFlowList.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.show}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ workerid: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                {varKeys?.map((data, index) => (
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label
                                      style={{
                                        fontSize: '16px',
                                        color: 'black',
                                        fontWeight: 'bold'
                                      }}>
                                      {data}
                                    </label>
                                    <Field
                                      style={{ padding: 3, border: '2px solid blue' }}
                                      name={`${name}.${data}`}
                                      component="input"
                                      placeholder="Key"
                                    />
                                  </div>
                                ))}

                                {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div> */}
                                <Button
                                  onClick={() => {
                                    fields.remove(index);
                                  }}>
                                  ❌
                                </Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            // disabled={workerid.length==0}
                            onClick={() => {
                              fields.push({});
                              // fieldss(workerid);
                            }}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'dt':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        Data Transform : {selectedNodes.id}
                      </Typography>

                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <h4
                                  style={{
                                    fontSize: '16px',
                                    color: 'black',
                                    fontWeight: 'bold'
                                  }}>
                                  Configuration{' '}
                                </h4>

                                {/* Radio Buttons for xmltojson and jsontoxml */}
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label>
                                    <Field
                                      name={`${name}.type`}
                                      component="input"
                                      type="radio"
                                      value="xmltojson"
                                    />
                                    XML to JSON
                                  </label>
                                  <label>
                                    <Field
                                      name={`${name}.type`}
                                      component="input"
                                      type="radio"
                                      value="jsontoxml"
                                    />
                                    JSON to XML
                                  </label>
                                </div>
                                {/* General Text Field */}
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    name={`${name}.value`}
                                    component="input"
                                    type="text"
                                    placeholder="Enter a value"
                                    style={{ padding: 3, border: '2px solid blue' }}
                                  />
                                </div>

                                {/* Remove Button */}
                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={() => fields.remove(index)}
                                  style={{ marginTop: '10px' }}>
                                  ❌ Remove
                                </Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() =>
                              fields.push({
                                conversionType: '',
                                write: 'data',
                                type: 'nag',
                                value: ''
                              })
                            }
                            style={{ marginTop: '10px', padding: '5px 10px' }}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'iterativeFlow':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }}>
                        Iteration : {selectedNodes.id}
                      </Typography>

                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() =>
                              fields.push({ result: '', node: '', edgeDescription: '' })
                            }>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    WorkerID
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.workerid`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Iteration Count
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.count`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ workerid: '', count: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      default:
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }}>
                        Conditional Operator : {selectedNodes.id}
                      </Typography>
                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    value="expression"
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value 1
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.val1`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value 2
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.val2`}
                                    component="input"
                                    placeholder="Value 2"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Conditional Operator
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.op`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node ID
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() =>
                              fields.push({ key: '', op: '', value: '', val1: '', val2: '' })
                            }>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                <Button onClick={() => fields.remove(index)}>❌</Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
    }
  }
  return (
    <>
      <FormCreate>{form()}</FormCreate>
    </>
  );
};

export default FormSection;
