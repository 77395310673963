import React from 'react';
import FileDropZone from './FileDropZone';

const DropZone = () => {
  return (
    <div className="container mx-auto mt-5 p-4">
      {/* <h1 className="text-3xl font-semibold mb-6">File Upload</h1> */}
      <FileDropZone />
    </div>
  );
};

export default DropZone;
