export default {
  hlfNetworks: 'hlfNetworks',
  newFeature: 'newFeature',
  features: 'feature',
  orchestrations: 'orchestrations',
  objects: 'objects',
  objectMappings: 'ObjectMappings',
  users: 'users',
  review: 'review',
  rejected: 'rejected',
  approved: 'approved',
  connections: 'connections',
  reviews: 'reviews',
  traces: 'traces',
  auditLogs: 'auditLogs',
  canvas: 'showCanvas',
  organisation:'organisation'
};
