import React from 'react';
import success from '../../public/icons/success.png';

const Success = ({ message, t }) => {
  return (
    <div className="w-[300px] bg-white shadow-lg flex absolute right-[20px] rounded-sm justify-start items-center p-2">
      <div className="w-[60px] flex justify-center">
        <img src={success} alt="" srcset="" />
      </div>
      <div className="ml-[12px]">
        <div className="p-[12px]">
          <div className="font-semibold">{message}</div>
        </div>
        {/* <div className="absolute top-[0px] right-[-80px]" onClick={() => toast.dismiss(t.id)}>
          <Cancel />
        </div> */}
      </div>
    </div>
  );
};

export default Success;
