import request from 'superagent';
import {
  fetchMatchDataUrl,
  fetchObjectDataUrl,
  fetchTargetObjectDataUrl
} from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';
import fetchNestedDataForMap from './fetchNestedDataForMap';
import fetcheNestedDataByCode from './fetchNestedDataByCode';

const fetchMatchListData = ({ app_code, id, sourceId, targetId,sourceCode,targetcode }) => {
  // console.log( "edit form",app_code, id, sourceId, targetId,sourceCode,targetcode)

  return async () => {
    const fetchMatchDataRes = await request
      .get(fetchMatchDataUrl)
      .query({ appCode: app_code, id: id })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    const matchData = fetchMatchDataRes.body.objectMappingResponseItem;
    const { currentStatus, currentVersion, prevVersions } = fetchMatchDataRes.body || {};
    var sourceData = [];
    var targetData = [];


    console.log("hiiiiii")
 
    if (matchData.length) {
      sourceData = await fetcheNestedDataByCode({appcode:app_code,code:sourceCode  });
      targetData = await fetcheNestedDataByCode({ appcode:app_code,code:targetcode  });
    }

    // if (matchData.length) {
    //   const {
    //     sourceObjectTypeCode,
    //     targetObjectTypeCode,
    //     targetObjectCode,
    //     targetObjectId,
    //     sourceObjectId,
    //     keys: matchKeys
    //   } = matchData[0];

    //   const majorSourceObjectList = await request
    //     .get(fetchObjectDataUrl)
    //     .query({ appCode: app_code, type: sourceObjectTypeCode })
    //     .set('Authorization', `Bearer ${keycloak.token()}`);

    //   const majorTargetObjectList = await request
    //     .get(fetchObjectDataUrl)
    //     .query({ appCode: app_code, type: targetObjectTypeCode })
    //     .set('Authorization', `Bearer ${keycloak.token()}`);

    //   const targetObjectType = await request
    //     .get(fetchTargetObjectDataUrl)
    //     .set('Authorization', `Bearer ${keycloak.token()}`);

    //   return {
    //     sourceObjectList: majorSourceObjectList.body.dataObjects,
    //     targetObjectList: majorTargetObjectList.body.dataObjects,
    //     targetObjectType: targetObjectType.body.primary,
    //     sourceObjectTypeCode,
    //     targetObjectTypeCode,
    //     targetObjectCode,
    //     targetObjectId,
    //     sourceObjectId,
    //     matchKeys
    //   };
    // } else {
    //   return {};
    // }
    return {
      matchKeys: matchData,
      sourceData,
      targetData,
      currentStatus,
      currentVersion,
      prevVersions
    };
  };
};

export default fetchMatchListData;
