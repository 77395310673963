import { isEmpty, noop } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactFlow, addEdge, useEdgesState, useNodesState } from 'reactflow';
import getFlowNodes from '../utils/getFlowNodes';
import { Button } from '@mui/material';
import deleteObjectMappingProperty from '../../../services/deleteObjectMappingProperty';
import { useMutation } from 'react-query';
import fetchChildDataObjects from '../../../services/fetchChildDataObjects';
import fetchSecondaryMappedData from '../../../services/fetchSecondaryMappedData';
import submitNewObjectMapping from '../../../services/submitNewObjectMapping';
import { useParams } from 'react-router-dom';
import submitNewChildObjectMapping from '../../../services/submitNewChildObjectMapping';

const NewFormMapping = (props) => {
  const [selectedData, setSelectedData] = useState({});
  const [alreadyOpened, setAlreadyOpened] = useState({});
  const [connections, setConnections] = useState([]);
  const { app_code } = useParams();

  const {
    newObjectformData: {
      targetObjectListData: { value: targetObjectListData = [] } = {},
      sourceObjectListData: { value: sourceObjectListData = [] } = {},
      'Target Object List': { value: targetObjectId } = {},
      'Source Object List': { value: sourceObjectId } = {},
      matchKeys: { value: matchKeys } = {},
      editId = ''
    }
  } = props;

  let targetObjectListTemp = targetObjectListData?.find(
    (element) => element.dataObjectId == targetObjectId
  );

  const updatedtarget = targetObjectListTemp?.keys?.map((element) => {
    return { ...element, uniqueKey: `${element.dataObjectId}-${element.code}` };
  });
  targetObjectListTemp = { ...targetObjectListTemp, keys: updatedtarget };

  let sourceObjectListTemp = sourceObjectListData?.find(
    (element) => element.dataObjectId == sourceObjectId
  );

  const updatedSource = sourceObjectListTemp?.keys?.map((element) => {
    return { ...element, uniqueKey: `${element.dataObjectId}-${element.code}` };
  });

  sourceObjectListTemp = { ...sourceObjectListTemp, keys: updatedSource };

  const [sourceObjectList, setSourceObjectList] = useState(sourceObjectListTemp);
  const [targetObjectList, setTargetObjectList] = useState(targetObjectListTemp);

  let sourceSize = 0;
  let targetSize = 0;

  const centerScreen = parseInt(window.innerWidth / 2);

  const sourceNodes = (sourceObjectList?.keys || []).map((element) => {
    sourceSize++;
    return {
      id: `${element.uniqueKey}-source`,
      data: {
        label: element.name,
        id: element.dataObjectKeyId,
        location: 'source',
        bg: element.bg
      },
      type: 'leftCustom',
      position: { x: centerScreen - 350, y: 65 * sourceSize }
    };
  });

  sourceSize = 0;
  targetSize = 0;

  const targetNodes =
    (targetObjectList?.keys || [])?.map((element) => {
      targetSize++;
      return {
        id: `${element.uniqueKey}-target`,
        data: {
          label: element.name,
          id: element.dataObjectKeyId,
          location: 'target',
          bg: element.bg
        },
        type: 'rightCustom',
        position: { x: centerScreen + 50, y: 65 * targetSize }
      };
    }) || [];

  const matchEdges = (matchKeys || [])?.map((element) => {
    return {
      id: element.id,
      source: `${element.sourceAttributeCode}-source`,
      target: `${element.sourceAttributeCode}-target`,
      animated: true,
      sourceHandle: 'a'
    };
  });

  const initialNode = [...sourceNodes, ...targetNodes];

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNode);
  const [edges, setEdges, onEdgesChange] = useEdgesState(matchEdges);
  const [initialEdges, setInitialEdges] = useState(matchEdges);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [screenHeight, setScreenHeight] = useState(initialNode.length * 55);
  const onConnect = (params) => {
    const { source, target } = params;

    const sourceCode = source.split('-')[1];
    const targetCode = target.split('-')[1];
    const souceObjectId = source.split('-')[0];
    const targetObjectId = target.split('-')[0];

    const foundSource = sourceObjectList.keys.find((element) => {
      return element.code == sourceCode && element.dataObjectId == souceObjectId;
    });

    const foundTarget = targetObjectList.keys.find((element) => {
      return element.code == targetCode && element.dataObjectId == targetObjectId;
    });

    const currentContext = { trying: undefined };

    if (foundSource.dataObjectTypeId == foundTarget.dataObjectTypeId) {
      const connectionResult = connectAllParents.call(
        currentContext,
        foundSource,
        foundTarget,
        sourceObjectList.keys,
        targetObjectList.keys,
        {
          ...params,
          source: `${foundSource.dataObjectId}-${foundSource.objectCode}-source`,
          target: `${foundTarget.dataObjectId}-${foundTarget.objectCode}-target`
        }
      );

      if (connectionResult) {
        setConnections([...connections, currentContext.trying]);

        return setEdges((eds) => addEdge(params, eds));
      } else {
        return false;
      }
    }

    return null;
  };
  const onEdgeClick = (event, edge) => {
    setSelectedEdge(edge);
    console.log('Clicked on edge:', edge);
  };

  function connectAllParents(child1, child2, parentList1, parentList2, params) {
    const parent1 = parentList1.find((element) => element.dataObjectTypeId == child1.dataObjectId);
    const parent2 = parentList2.find((element) => element.dataObjectTypeId == child2.dataObjectId);
    this.trying = {
      sourceAttributeCode: child1.code,
      targetAttributeCode: child2.code,
      id: null,
      ...(this.trying ? { attributeCodes: [this.trying] } : {})
    };
    if (parent1 && parent2) {
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            source: `${parent1.uniqueKey}-source`,
            target: `${parent2.uniqueKey}-target`
          },
          eds
        )
      );
      return connectAllParents.call(this, parent1, parent2, parentList1, parentList2, params);
    }
    if (parent1 || parent2) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    setNodes([...sourceNodes, ...targetNodes]);
    setScreenHeight([...sourceNodes, ...targetNodes].length * 55);
  }, [JSON.stringify(sourceNodes), JSON.stringify(targetNodes)]);

  useEffect(() => {
    setSourceObjectList(sourceObjectListTemp);
    setTargetObjectList(targetObjectListTemp);
    setAlreadyOpened({});
  }, [targetObjectId, sourceObjectId]);

  const onDeleteCtaClick = () => {
    const updatedEdges = edges.filter((edge) => {
      return !(edge.source == selectedEdge.source && edge.target == selectedEdge.target);
    });

    setEdges(updatedEdges);
    setSelectedEdge(null);
  };

  const onScroll = (event) => {
    event.stopPropagation();
  };

  const submitNewObjectMappingForm = useMutation(submitNewObjectMapping);
  const submitNewChildObjectMappingForm = useMutation(submitNewChildObjectMapping);

  const submitNewForData = async () => {
    const selectedEdges = initialEdges.map((edge) => {
      return {
        sourceAttributeCode: edge.source.replace('-source', '').split('-')[1],
        targetAttributeCode: edge.target.replace('-target', '').split('-')[1],
        id: edge.eid || null
      };
    });

    const sourceObjectCode = sourceObjectListData.find(
      (element) => element.dataObjectId == sourceObjectId
    )?.code;

    const targetObjectCode = targetObjectListData.find(
      (element) => element.dataObjectId == targetObjectId
    )?.code;

    function mergeObjects(arr) {
      const merged = [];
      const map = new Map();

      function merge(obj) {
        const key = `${obj.sourceAttributeCode}-${obj.targetAttributeCode}`;
        if (map.has(key)) {
          const existing = map.get(key);
          if (obj.attributeCodes) {
            existing.attributeCodes = mergeObjects([
              ...(existing.attributeCodes || []),
              ...obj.attributeCodes
            ]);
          }
        } else {
          map.set(key, obj);
          merged.push(obj);
        }
      }

      for (const obj of arr) {
        merge(obj);
      }

      return merged;
    }

    const transformed = mergeObjects(connections);

    const isChildMapped = transformed.some((element) => element.attributeCodes);

    if (isChildMapped) {
      const result = await submitNewChildObjectMappingForm.mutateAsync({
        payload: {
          applicationCode: app_code,
          sourceObjectId: sourceObjectId,
          targetObjectId: targetObjectId,
          sourceObjectCode: sourceObjectCode,
          targetObjectCode: targetObjectCode,
          attributeCodes: [...transformed]
        }
      });
    } else {
      const result = await submitNewObjectMappingForm.mutateAsync({
        payload: {
          applicationCode: app_code,
          sourceObjectId: sourceObjectId,
          targetObjectId: targetObjectId,
          sourceObjectCode: sourceObjectCode,
          targetObjectCode: targetObjectCode,
          attributeCodes: [...transformed]
        }
      });
    }
  };

  const onNodeClick = async (e) => {
    const { id } = e.target;
    const location = e.target.getAttribute('location');
    // if (alreadyOpened[id]) {
    //   return false;
    // }

    // setAlreadyOpened({ ...alreadyOpened, [id]: true });

    switch (location) {
      case 'source': {
        const sourceElement = sourceObjectList.keys.find(
          (element) => element.dataObjectKeyId == id
        );

        setSelectedData({ ...selectedData, sourceData: sourceElement });

        const {
          body: { dataObjects = [] }
        } = await fetchChildDataObjects(sourceElement.dataObjectTypeId);

        const { applicationCode = [] } = dataObjects[0] || {};

        const updatedApplicationCode = applicationCode.map((element) => {
          return {
            ...element,
            uniqueKey: `${element.dataObjectId}-${element.code}`,
            bg: '#8C9EFF'
          };
        });

        setSourceObjectList({
          ...sourceObjectList,
          keys: [...sourceObjectList.keys, ...updatedApplicationCode]
        });

        const newOptions = applicationCode.map((element) => {
          return {
            id: `${element.dataObjectId}-${element.name}-source`,
            data: {
              bg: '#8C9EFF',
              label: element.name,
              id: element.dataObjectKeyId,
              location: 'source'
            },
            type: 'leftCustom'
          };
        });

        const parentObjectIndex = nodes.findIndex((element) => element?.data?.id == id);
        const updatedSourceObjects = [
          ...nodes.slice(0, parentObjectIndex + 1),
          ...newOptions,
          ...nodes.slice(parentObjectIndex + 1)
        ];

        sourceSize = 0;
        targetSize = 0;

        const updatedSourceNodes = (updatedSourceObjects || []).map((element) => {
          switch (element.type) {
            case 'leftCustom': {
              sourceSize++;
              return {
                id: element.id,
                data: {
                  label: element.data.label,
                  id: element.data.id,
                  location: 'source',
                  bg: element.data.bg
                },
                type: 'leftCustom',
                position: { x: centerScreen - 350, y: 65 * sourceSize }
              };
            }

            case 'rightCustom': {
              targetSize++;
              return {
                id: element.id,
                data: {
                  label: element.data.label,
                  id: element.data.id,
                  location: 'target',
                  bg: element.data.bg
                },
                type: 'rightCustom',
                position: { x: centerScreen + 50, y: 65 * targetSize }
              };
            }
          }
        });
        setNodes(updatedSourceNodes, 'here');
        break;
      }
      case 'target': {
        const targetElement = targetObjectList.keys.find(
          (element) => element.dataObjectKeyId == id
        );

        setSelectedData({ ...selectedData, targetData: targetElement });

        const {
          body: { dataObjects = [] }
        } = await fetchChildDataObjects(targetElement.dataObjectTypeId);

        const { applicationCode = [] } = dataObjects[0] || {};

        const updatedApplicationCode = applicationCode.map((element) => {
          return {
            ...element,
            uniqueKey: `${element.dataObjectId}-${element.code}`,
            bg: '#8C9EFF'
          };
        });

        setTargetObjectList({
          ...targetObjectList,
          keys: [...targetObjectList.keys, ...updatedApplicationCode]
        });

        const newOptions = applicationCode.map((element) => {
          return {
            id: `${element.dataObjectId}-${element.name}-target`,
            data: {
              bg: '#8C9EFF',
              label: element.name,
              id: element.dataObjectKeyId,
              location: 'target'
            },
            type: 'rightCustom'
          };
        });

        const parentObjectIndex = nodes.findIndex((element) => element?.data?.id == id);
        const updatedTargetObjects = [
          ...nodes.slice(0, parentObjectIndex + 1),
          ...newOptions,
          ...nodes.slice(parentObjectIndex + 1)
        ];

        sourceSize = 0;
        targetSize = 0;

        const updatedTargetNodes = (updatedTargetObjects || []).map((element) => {
          switch (element.type) {
            case 'leftCustom': {
              sourceSize++;
              return {
                id: element.id,
                data: {
                  label: element.data.label,
                  id: element.data.id,
                  location: 'source',
                  bg: element.data.bg
                },
                type: 'leftCustom',
                position: { x: centerScreen - 350, y: 65 * sourceSize }
              };
            }

            case 'rightCustom': {
              targetSize++;
              return {
                id: element.id,
                data: {
                  label: element.data.label,
                  id: element.data.id,
                  location: 'target',
                  bg: element.data.bg
                },
                type: 'rightCustom',
                position: { x: centerScreen + 50, y: 65 * targetSize }
              };
            }
          }
        });
        setNodes(updatedTargetNodes);

        if (selectedData.sourceData && !isEmpty(targetElement)) {
          const payload = {
            sourceObjectId: selectedData.sourceData.dataObjectKeyId,
            targetObjectId: targetElement.dataObjectKeyId,
            appCode: app_code
          };
          const secondaryMappedData = await fetchSecondaryMappedData(payload);
        }

        break;
      }
    }
  };

  return (
    <>
      <div className="flex justify-around">
        <Button onClick={onDeleteCtaClick} variant={'contained'} disabled={!selectedEdge}>
          Delete Edge
        </Button>
        <Button onClick={submitNewForData} variant={'contained'}>
          Submit New Form
        </Button>
      </div>
      <div
        onWheel={onScroll}
        style={{
          width: '100%',
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1
        }}>
        <ReactFlow
          panOnScrollMode="vertical"
          panOnScroll={true}
          zoomOnScroll={false}
          onConnect={onConnect}
          nodes={nodes}
          edges={edges}
          onNodeClick={onNodeClick}
          nodeTypes={{
            leftCustom: getFlowNodes.normalLeftNode,
            rightCustom: getFlowNodes.normalRightNode
          }}
          onEdgeClick={onEdgeClick}
          style={{ overflow: 'none' }}
        />
      </div>
    </>
  );
};

export default NewFormMapping;
