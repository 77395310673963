import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography
} from '@mui/material';
import { useForm, useFieldArray } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import CreateSubOrg from '../../services/createSuborg';
import getTenets from '../../services/getTenets';
import keycloak from '../../Keycloak';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Pagination from '@mui/material/Pagination';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { getparties, suborgDelete, suborgcreation } from '../dashboard/constants/applicationRequest';

export default function CreateSubOrganisation() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      Organization: [
        {
          org_name: '',
          org_type: 'SUBORG',
          org_id: '',
          mobile: '',
          email: '',
          parent_org_id: '',
          party_type: ''
        }
      ]
    }
  });

  const { fields: data } = useFieldArray({
    control,
    name: 'Organization'
  });

  //get organisation Code
  const {
    data: tenets,
    isLoading: tenetsLoading,
    error: tenetsError
  } = useQuery('tenets', getTenets);
  const [age, setAge] = React.useState('');
  const [partyList, setPartyList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [organisationList, setOrganisationList] = React.useState([]);
  const [status, setStatus] = React.useState('');
  const [loading, setLoading] = React.useState(false)
  const rowsPerPage = 10;
  const [page, setPage] = React.useState(1);
  const [selectedId, setSelectedId] = React.useState(0)
  const token = keycloak.token();
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: {}
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClickOpen = () => {
    setValue(`Organization.${0}.parent_org_id`, age);
    setOpen(true);
    getCurrentTypeData(age)
  };

  const handleClose = () => {
    setOpen(false);
    if(status === 'edit'){
      setStatus('')
    }else{
      
    }
  };

  const handleChange = (event) => {
    // const token = keycloak.token();
    // if (token) {
    //   axios
    //     .get(suborgcreation, {
    //       params: {
    //         tenantId: event.target.value ? event.target.value : tenets[0].org_id
    //       },
    //       headers: { Authorization: `Bearer ${token}` }
    //     })
    //     .then((res) => {
    //       console.log(res.data.list);
    //       if (res.data.list.length > 0) {
    //         setPartyList(res.data.list[0].party);
    //       } else {
    //         setPartyList([]);
    //       }
    //     })
    //     .catch((err) => console.log(err));
    // } else {
    //   console.log('Token not available');
    // }

    setAge(event.target.value);
    getPartnersData(event.target.value)
  };
  const handleChangePartnerOrganisation = (event) => {
    console.log(event, "selected event")
    getCurrentTypeData(event.target.value)
  }
  const getCurrentTypeData = (event) => {
    console.log(event, "current type")
    if (token) {
      axios
        .get(getparties, {
          params: {
            tenantId: event ? event : tenets[0].org_id
          },
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          console.log(res.data.list), "current type data";
          if (res.data.list.length > 0) {
            setPartyList(res.data.list[0].party);
          } else {
            setPartyList([]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log('Token not available');
    }
  }
  let mutation = useMutation(CreateSubOrg);
  const onSubmit = (data, e) => {
    console.log('createOrganiston', data);
    e.preventDefault();
    console.log(data);
    if (status === 'edit') {
      data.Organization[0].id = selectedId;
      axios.put(suborgcreation, data.Organization[0], Api_Config).then((res) => {
        console.log(res.data, "creation respooo")
        try {
          if (res.data.status.code === 'SUCCESS') {
            alert(res.data.status.message)
            setLoading(false)
            handleClose()
            getPartnersData(age)
          } else {
            console.log(res.data)
            alert(res.data.status.message)
            setLoading(false)
            handleClose()
          }
        } catch (err) {
          console.log("error is ...", err)
          setLoading(false)
          alert(res.data.status.message)
          handleClose()
        }

      })
    } else {
      let payload = data.Organization[0]
      try {
        mutation
          .mutateAsync({ payload })
          .then((response) => {
            if (response.body.status.code === 'SUCCESS') {
              alert(response.body.status.message);
              handleClose()
              console.log(response);
              getPartnersData(age)
            } else {
              console.log(response.body);
            }
          })
          .catch((err) => {
            console.log('Error:', err);
          });
      } catch (err) {
        console.log('Error:', err);
      }
    }

  };
  const getPartnersData = (event) => {
    console.log("organisation",event)
    const token = keycloak.token();
    if (token) {
      axios
        .get(suborgcreation, {
          params: {
            tenantId: event ? event : tenets[0].org_id
          },
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          console.log(res.data.organizationObjects, "response");
          if (res.data.organizationObjects.length > 0) {
            setOrganisationList(res.data.organizationObjects);
          } else {
            setOrganisationList([]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log('Token not available');
    }
    // setLoading(true);
    // try {
    //   const response = orgList.mutateAsync();
    //   if (response.body.status.code == 'SUCCESS') {
    //     alert(response.body.status.message)
    //     setLoading(false)
    //     console.log(response.body)
    //   }
    //   else {
    //     console.log(response.body)
    //     setLoading(false)
    //   }
    // }
    // catch (err) {
    //   console.log("error is ...", err)
    //   setLoading(false)
    // }
  }
  const update = (row, type) => {
    setOpen(true)
    console.log(row, "selected row")
    setValue(`Organization.${0}.org_name`, row.org_name);
    setValue(`Organization.${0}.org_type`, row.org_type);
    setValue(`Organization.${0}.org_id`, row.org_id);
    setValue(`Organization.${0}.mobile`, row.mobile);
    setValue(`Organization.${0}.email`, row.email);
    console.log(tenets, "tenets")
    // const indexToUpdate = data.findIndex(org => org.org_id === row.org_id);

    setValue(`Organization.${0}.parent_org_id`, row.parent_org_id);
    // setValue(`Organization.${0}.party_type`, row.party_type);
    getCurrentTypeData(row.org_name)
    setSelectedId(row.id)
    setStatus(type)
    // const payload = {
    //   tenantId: age,
    //   operation: type,
    //   party: {
    //     name: row.name,
    //     description:row.description
    //   }
    // };
    // console.log(payload)
    // try {
    //   mutation
    //     .mutateAsync({ payload })
    //     .then((response) => {
    //       if (response.body.status.code === 'SUCCESS') {
    //         alert(response.staus);
    //         console.log(response);
    //       } else {
    //         console.log(response.body);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log('Error:', err);
    //     });
    // } catch (err) {
    //   console.log('Error:', err);
    // }
  };
  const deleteSubOrganisation = (row,type) => {
    let deletePayload = {
      id: row.id,
      status: 'DELETED'
    }
    console.log(deletePayload,"delete payload")
    try {
      setLoading(true)
      axios.put(suborgDelete, deletePayload, Api_Config).then((res) => {
        setLoading(false)
        console.log(res, "delete resp")
        if (res.data.status.code === 'SUCCESS') {
          alert(res.data.status.message)
          getPartnersData(age)
        }
      })
    } catch (err) {
      console.log("error is ...", err)
      setLoading(false)
      alert(res.data.status.message)
      handleClose()
    }
  }
  if (tenetsLoading) {
    return <div>Loading...</div>;
  }

  if (tenetsError) {
    return <div>Error: {tenetsError.message}</div>;
  }

  return (
    <Box className="flex-1 px-[24px] mt-[50px] p-[20px] m-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.0rem', textTransform: 'uppercase', letterSpacing: '0.4' }}>
          Partner Details
        </Typography>
        <Box>
          <FormControl>
            <InputLabel id="party-select-label">Organisation Code</InputLabel>
            <Select
              labelId="party-select-label"
              id="party-select"
              value={age}
              label="Organisation Code"
              sx={{ width: 223, mr: 2 }}
              size={'small'}
              onChange={handleChange}>
              {tenets.map((data) => (
                <MenuItem key={data.org_id} value={data.org_id}>
                  {data.org_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton aria-label="fingerprint" color="secondary" onClick={handleClickOpen}>
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
        </Box>

      </Box>
      <TableContainer >
        <Table sx={{ minWidth: 800 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Organisation Code</TableCell>
              <TableCell align="right">Organisation Type</TableCell>
              <TableCell align="right">Organisation Id</TableCell>
              <TableCell align="right">Mobile Number</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Parent Organisation</TableCell>
              <TableCell align="right">Current Type</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organisationList.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="right">{row.org_id}</TableCell>
                <TableCell align="right">{row.org_type}</TableCell>
                <TableCell align="right">{row.org_id}</TableCell>
                <TableCell align="right">{row.mobile}</TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.parent_org_id}</TableCell>
                <TableCell align="right">{row.party_type}</TableCell>

                <TableCell align="right">
                  <Button onClick={() => update(row, "edit")}>Edit</Button>
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => deleteSubOrganisation(row, "remove")}>Remove</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        style={{ alignSelf: 'flex-end', marginTop: '10px' }}
        count={Math.ceil(organisationList.length / rowsPerPage)}
        page={page}
        onChange={handleChangePage}
      />
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: '1.0rem',
            textTransform: 'uppercase',
            letterSpacing: '0.4',
            pl: 5
          }}>
          Sub Organisation Details
        </DialogTitle>
        <Box sx={{ p: 5 }}>
          <form onSubmit={handleSubmit(onSubmit)}>

            {data.map((field, index) => (
              <Box key={field.id} sx={{ margin: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 3 }}>
                  <TextField
                    label="Organisation Code"
                    variant="outlined"
                    key={index}
                    {...register(`Organization.${index}.org_name`)}
                    size="small"
                    sx={{ marginRight: 2 }}
                  />

                  <TextField
                    label="Organisation Type"
                    variant="outlined"
                    {...register(`Organization.${index}.org_type`)}
                    size="small"
                    sx={{ marginRight: 2 }}
                    disabled
                  />
                  <TextField
                    label="Organisation ID"
                    variant="outlined"
                    {...register(`Organization.${index}.org_id`)}
                    size="small"
                    sx={{ marginRight: 2 }}
                  />

                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 3 }}>
                  <TextField
                    label="Mobile Number"
                    variant="outlined"
                    {...register(`Organization.${index}.mobile`)}
                    size="small"
                    sx={{ marginRight: 2 }}
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    {...register(`Organization.${index}.email`)}
                    size="small"
                    required
                    sx={{ marginRight: 2 }}
                  />
                  {/* <FormControl>
                    <InputLabel id="demo-simple-select-label">Parent Organization</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      {...register(`Organization.${index}.parent_org_id`)}
                      label="Parent Organisation"
                      size="small"
                      sx={{ width: 223, marginRight: 2 }}
                      onChange={handleChangePartnerOrganisation}>
                      {tenets.map((data) => (
                        <MenuItem value={data.org_id}>
                          {data.org_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <TextField
                    label="Parent Organisation"
                    variant="outlined"
                    {...register(`Organization.${index}.parent_org_id`)}
                    size="small"
                    required
                    sx={{ marginRight: 2 }}
                    disabled
                  />
                </Box>

                <FormControl>
                  <InputLabel id="demo-simple-select-label">Current Type*</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register(`Organization.${index}.party_type`)}
                    label="Parent Organisation"
                    size="small"
                    sx={{ width: 223 }}
                  // onChange={handleChange}
                  >
                    {partyList.map((row, index) => (
                      <MenuItem value={row.name}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            ))}

            <Button type="submit" variant="contained" >
              {status != 'edit' ? 'Submit' : 'Update'}
            </Button>
            <Button type="button" variant="contained" sx={{ mx: 4 }} onClick={handleClose}>
              Close
            </Button>
          </form>
        </Box>
      </Dialog>
    </Box>
  );
}
