import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

function FadeMenu({
  onDeleteClick,
  onEditClick,
  onUpdateClick,
  onAddUserClick,
  children,
  onTraceClick,
  onExportClick,
  applicationData
}) {
  const { name, baseApplication, code, description, id } = applicationData;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const handleEditClick = (event, name, baseApplication, code, description, id) => {
    event.stopPropagation();
    setAnchorEl(null);
    onEditClick(name, baseApplication, code, description, id);
  };
  const handleDeleteClick = (event, id, name) => {
    event.stopPropagation();
    setAnchorEl(null);
    onDeleteClick(id, name);
  };
  const handleUpdateClick = (event, code) => {
    event.stopPropagation();
    setAnchorEl(null);
    onUpdateClick(code);
  };
  const handleAddUserClick = (event, code) => {
    event.stopPropagation();
    setAnchorEl(null);
    onAddUserClick(code);
  };

  const handleTraceClick = (event, code) => {
    event.stopPropagation();
    setAnchorEl(null);
    onTraceClick(code);
  };

  const handleExportApplication = ({ event, code, name, baseApplication, description, id }) => {
    event.stopPropagation();
    setAnchorEl(null);
    onExportClick(code, name, baseApplication, description, id);
  };

  return (
    <div>
      <div
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="!cursor-pointer">
        {children}
      </div>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}>
        <MenuItem onClick={(e) => handleEditClick(e, name, baseApplication, code, description, id)}>
          Edit
        </MenuItem>
        <MenuItem onClick={(e) => handleDeleteClick(e, id, name)}>Delete</MenuItem>
        <MenuItem onClick={(e) => handleUpdateClick(e, code)}>Update Application</MenuItem>
        <MenuItem onClick={(e) => handleAddUserClick(e, code)}>Application Users</MenuItem>
        {/* <MenuItem onClick={(e) => handleTraceClick(e, code)}>Application Trace</MenuItem> */}
        <MenuItem
          onClick={(e) =>
            handleExportApplication({ event: e, code, name, baseApplication, description, id })
          }>
          Export Application
        </MenuItem>
      </Menu>
    </div>
  );
}

export default FadeMenu;
