import React from 'react';
import Card from './Card';

const TopCards = ({ TopCardsSchema, countData = {} }) => {
  const cardsCount = TopCardsSchema.length;
  return (
    <div className="flex items-center flex-row border-2 border-[#E6EDFF] h-[142px] rounded-[12px] ml-[28px] bg-[#E4E4E5] mr-[12px] mt-8 shadow-lg mb-8">
      {TopCardsSchema.map((tile) => {
        const count = countData[tile.value];
        return <Card cardsCount={cardsCount} count={count} cardData={tile} />;
      })}
    </div>
  );
};

export default TopCards;
