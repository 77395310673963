const connectionFrameworkTypeInfoSchema = () => {
  return {
    title: 'Connection Framework-Mail',
    hideTitle: true,
    formSchema: [
      {
        name: 'email',
        label: 'Email',
        type: 'text',
        required: true
      },
      {
        name: 'appPassCode',
        label: 'App Pass Code',
        type: 'text',
        required: true
      },
      {
        name: 'imapServer',
        label: 'Imap Server',
        type: 'text',
        required: true
      }
    ]
  };
};


export default connectionFrameworkTypeInfoSchema;