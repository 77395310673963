import { noop } from 'lodash';
import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ReactJson from 'react-json-view';

const SourceObjectModal = ({ sourceObjectData = [], onCloseModal = noop, context = '' }) => {
  if (!sourceObjectData) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
    <div className="relative bg-white w-[800px] h-[80vh] flex flex-col rounded-lg border-4">
      {/* <!-- Fixed Header --> */}
      <div className="flex justify-between items-center border-b-4 p-4 bg-white">
        <h1 className='text-3xl font-bold'>{context === 'transformedObject' ? 'Transformed Object' : 'Source Object'}</h1>
        <button onClick={onCloseModal} className="text-blue-500 hover:text-blue-700">
          <HighlightOffIcon className="!text-3xl !w-8 !h-8" />
        </button>
      </div>
      {/* <!-- Scrollable Content Area --> */}
      <div className="flex-1 p-6 overflow-y-auto">
        <ReactJson src={sourceObjectData} style={{ fontSize: '18px' }} />
      </div>
    </div>
  </div>
  
  

  
  
  
  );
};

export default SourceObjectModal;
