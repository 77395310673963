import request from 'superagent';
import { updateApplicationUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const updateApplication = async (queryData) => {
  const updatedUrl = updateApplicationUrl + `/${queryData}/generate`;
  const res = await request
    .put(updatedUrl)
    .send({})
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${keycloak.token()}`);

  if (res.body.status.code == 'SUCCESS') {
    console.log('Application updated successfully');
  } else {
    console.log(res.body.status.message);
  }

  return res;
};

export default updateApplication;
