import { ConfirmationNumberSharp, Edit, WalletSharp } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { noop } from 'lodash';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import WalletTabs from '../../Tabs/components/WalletTabs';
import MenuContainer from '../../common/menu/containers/MenuContainer';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import GenericModalContainer from '../../modals/genericModal/container';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import {
  setCurrentHLFId,
  setCurrentHLFOrganization,
  setCurrentHLFOrganizationId
} from '../slices/hlfSlice';
import Success from '../../notify/Success';
import toast from 'react-hot-toast';

const OrganizationGrid = ({
  data: featureData,
  fetchOrganizationData,
  deleteFeatureClick = noop,
  copyFeatureUrl = noop,
  fetchHLFData = noop,
  currentType = ''
}) => {
  const gridRef = useRef();
  const { app_code = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: hlfId, network } = useSelector((store) => store.store?.hlf) || {};

  const columnDefs = [
    { headerName: 'Organizations', field: 'organizations' },
    { headerName: 'Peer Names', field: 'peerNames' },
    { headerName: 'Config Upload Status', field: 'configUploadStatus' },
    { headerName: 'Wallet Upload Status', field: 'walletUploadStatus' }
  ];

  if (currentType == 'manage') {
    columnDefs.push({
      headerName: 'FILE ACTION',
      field: 'FILE ACTION',
      cellRenderer: (data) => (
        <MenuContainer
          menuData={[
            {
              name: 'Upload',
              icon: <Edit fontSize="small" />,
              nested: true,
              nestedItems: [
                {
                  name: 'Config',
                  icon: <ConfirmationNumberSharp fontSize="small" />,
                  callback: () => cellClicked(data, 'config')
                },
                {
                  name: 'Wallet',
                  icon: <WalletSharp fontSize="small" />,
                  callback: () => cellClicked(data, 'wallet')
                }
              ]
            },
            {
              name: 'Fetch',
              icon: <Edit fontSize="small" />,
              nested: true,
              nestedItems: [
                {
                  name: 'Config',
                  icon: <ConfirmationNumberSharp fontSize="small" />,
                  callback: () => cellClicked(data, 'configFetch')
                },
                {
                  name: 'Wallet',
                  icon: <WalletSharp fontSize="small" />,
                  callback: () => cellClicked(data, 'walletFetch')
                }
              ]
            }
          ]}
        />
      )
    });
  }

  const defaultColDef = {
    sortable: true,
    flex: 1,
    floatingFilter: true,
    filter: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function onShowLoading() {
    gridOptions.api.showLoadingOverlay();
  }

  function onHide() {
    gridOptions.api.hideOverlay();
  }

  let gridApi;

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };

  const getFileStatus = (files, name) => {
    return (files.find((element) => element.orgName == name) || {})?.uploadStatus;
  };

  const rowData = featureData?.organizations?.map((feature) => {
    return {
      organizations: feature.name,
      peerNames: feature.peerNames,
      configUploadStatus: getFileStatus(featureData.configFiles, feature.name),
      walletUploadStatus: getFileStatus(featureData.walletFiles, feature.name),
      id: featureData.id
    };
  });

  // const autoSizeAll = useCallback((skipHeader) => {
  //   const allColumnIds = [];
  //   gridRef.current.columnApi.getColumns().forEach((column) => {
  //     allColumnIds.push(column.getId());
  //   });
  //   gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  // }, []);

  async function onGridReady(params) {
    gridApi = params?.api;
    params?.api?.showLoadingOverlay();
    gridApi?.sizeColumnsToFit();
    // const fetchData = await request
    //   .get(`${process.env.REACT_APP_CONFIG_API}/hlf/id`)
    //   .query({
    //     id: hlfId
    //   })
    //   .set('Authorization', `Bearer ${keycloak.token()}`);
    const res = await fetchOrganizationData();
    // setFeatureData({
    //   organizations: fetchData.body?.hlfNetworkObjects[0]?.organizations,
    //   configFiles: fetchData.body?.hlfNetworkObjects[0]?.config_files,
    //   walletFiles: fetchData.body?.hlfNetworkObjects[0]?.wallet_files,
    //   id: fetchData.body?.hlfNetworkObjects[0]?.id || ''
    // });
    // console.log(fetchData.body?.hlfNetworkObjects[0]?.organizations);
    // autoSizeAll(false);
  }

  const onExportClick = () => {
    gridApi = params?.api;
    gridApi?.exportDataAsCsv();
  };

  async function cellClicked(param, field) {
    const { data } = param;

    switch (field) {
      case 'copyFeature': {
        navigate(`/apps/${app_code}/features/copy/${data.id}`);
        break;
      }
      case 'edit': {
        navigate(`/apps/${app_code}/features/edit/${data.id}`);
        break;
      }
      case 'delete': {
        deleteFeatureClick({ id: data.id, name: data.name });
        break;
      }
      case 'copyUrl': {
        copyFeatureUrl(data.uniqueCode, app_code);
        break;
      }
      case 'viewFeature': {
        navigate(`/apps/${app_code}/features/view/${data.id}`);
        break;
      }
      case 'wallet': {
        dispatch(
          setCurrentHLFOrganizationId({
            id: data.id
          })
        );
        dispatch(
          setCurrentHLFOrganization({
            organization: data.organizations
          })
        );
        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.wallet,
            isOpen: true
          })
        );
        break;
      }
      case 'config': {
        dispatch(
          setCurrentHLFOrganizationId({
            id: data.id
          })
        );
        dispatch(
          setCurrentHLFOrganization({
            organization: data.organizations
          })
        );
        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.config,
            isOpen: true
          })
        );
        break;
      }
      case 'walletFetch': {
        const payload = {
          id: data.id,
          networkName: network,
          orgName: data.organizations,
          flag: 'fetch-user'
        };

        const res = await request
          .post(`${process.env.REACT_APP_CONFIG_API}/hlf/fetchFile`)
          .send(payload)
          .set('Authorization', `Bearer ${keycloak.token()}`);

        toast.custom((t) => <Success t={t} message={res.body.message} />, {
          duration: 4000
        });
        break;
      }
      case 'configFetch': {
        const payload = {
          id: data.id,
          networkName: network,
          orgName: data.organizations,
          flag: 'fetch-connection'
        };

        const res = await request
          .post(`${process.env.REACT_APP_CONFIG_API}/hlf/fetchFile`)
          .send(payload)
          .set('Authorization', `Bearer ${keycloak.token()}`);

        toast.custom((t) => <Success t={t} message={res.body.message} />, {
          duration: 4000
        });
        fetchHLFData();
        fetchOrganizationData();
        break;
      }
    }
  }

  return (
    <div className="ag-theme-alpine h-[400px] px-[24px] mt-[50px] p-[20px] m-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      <div className="text-[24px]">{'Organizations'}</div>
      <div className="flex-1">
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          onCellClicked={cellClicked}
          rowData={rowData}
        />
      </div>
      <GenericModalContainer modalKey={ModalKeys.wallet}>
        <WalletTabs
          fetchHLFData={fetchHLFData}
          fetchOrganizationData={fetchOrganizationData}
          name="wallet"
        />
      </GenericModalContainer>
      <GenericModalContainer modalKey={ModalKeys.config}>
        <WalletTabs
          fetchHLFData={fetchHLFData}
          fetchOrganizationData={fetchOrganizationData}
          name="config"
        />
      </GenericModalContainer>
    </div>
  );
};

export default OrganizationGrid;
