import React, { useEffect, useState } from 'react';
import {
  duplicateObjectSchema,
  editObjectSchema,
  newObjectSchema,
  viewObjectSchema
} from '../../../schema/form_schema/newDataObjectFormSchema';
import {
  editObject,
  fetchGroupsApi,
  getDataObjectTypes,
  getDataTypes
} from '../../../services/newDataObjectData';
import { useMutation, useQuery } from 'react-query';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import FormV2Container from '../../common/formV2/container/FormV2Container';
import ApplicationTilesContainer from './ApplicationTiles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import createNewDataObject from '../../../services/createNewDataObject';
import SideBar from '../../ApplicationV2/component/SideBar';
import { toast } from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ConformationContainer from '../../common/conformationModal/containers';
import { isEmpty, noop } from 'lodash';
import { clearFormState, emptyAll, setIsEditForm } from '../../common/form/slices/formSlice';
import GenericModalContainer from '../../modals/genericModal/container';
import ValidationRuleModal from '../component/ValidationRuleModal';
import DataObjectValidationModalContainer from './DataObjectValidationModalContainer';
import VersionDropdownComponent from '../../common/VersionDropdown/VersionDropdownComponent';
import getApplicationTopNavigationSchema from '../../../utils/navigationUtils/getApplicationTopNavigationSchema';
import fetchApplicationCount from '../../../services/fetchApplicationCount';
import formatFeatureCountData from '../../../utils/formatFeatureCountData';
import getExtensionSubheaderSchema from '../../../utils/navigationUtils/getExtensionSubheaderSchema';
import TopCardSubheader from '../../../layouts/TopCardSubheader';

const NewObjectFormContainer = ({ viewAction, subAction, dataObjectId: dataObjectIdReview }) => {
  const navigate = useNavigate();

  const { app_code, action = viewAction, dataObjectId = dataObjectIdReview } = useParams();
  const dispatch = useDispatch();

  const isSubActionReview = subAction == 'review';
  useEffect(() => {
    dispatch(emptyAll());
  }, []);

  const mutation = useMutation(createNewDataObject);
  const [dataObjectValue2, setDataObjectValue2] = useState('');

  const [dataType, setDataType] = useState([]);
  const [dataObjectValue, setDataObjectValue] = useState('');
  const [aclSelected, setAclSelected] = useState(false);
  const [showValidationRequired, setShowValidationRequired] = useState(false);
  const [objectSpecificSelected, setObjectSpecificSelected] = useState(false);
  const [aclGroups, setAclGroups] = useState();
  const [showValidateBtn, setShowValidateBtn] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showSequenceFields, setShowSequenceFields] = useState(false);

  const { data: dataObjectTypeData } = useQuery({
    queryKey: ['dataObjectTypes'],
    queryFn: getDataObjectTypes(),
    refetchOnWindowFocus: false
  });
  const { refetch } = useQuery({
    queryKey: ['dataTypes'],
    queryFn: getDataTypes({ app_code: app_code, type: dataObjectValue }),
    refetchOnWindowFocus: false
  });
  const { data: editObjectData, isSuccess: editDataObjectSuccess } = useQuery({
    queryKey: ['editObject'],
    queryFn: editObject({ dataObjectId: dataObjectId }),
    refetchOnWindowFocus: false,
    refetchOnMount: true
  });

  const onValidateBtnClick = (event) => {
    const { value = '' } = event.target;
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.dataObjectValidationModal,
        isOpen: true,
        modalDataKey: 'propertyIndex',
        modalData: value
      })
    );
  };

  const editDataObjectsData = editObjectData?.body?.dataObjects?.[0];
  useEffect(() => {
    if (dataObjectValue) {
      refetch().then((formData) => setDataType(formData?.data?.body?.dataObjects));
    }
  }, [JSON.stringify(dataObjectValue)]);
  const [showAcl, setshowAcl] = useState(true);
  const onDataObjectSelect = async (event) => {
    const { value } = event.target;
    if (value == 'DATA_OBJECT' || value == 'SCHEMA_OBJECT') {
      setshowAcl(true);
    } else {
      setshowAcl(false);
    }
    if (value == 'REQUEST_OBJECT' || value == 'SCHEMA_OBJECT') {
      setShowValidateBtn(true);

      if (value == 'REQUEST_OBJECT') {
        setShowValidationRequired(true);
      }
    } else {
      setShowValidateBtn(false);
      setShowValidationRequired(false);
    }
    setDataObjectValue(value);
  };

  const onAclRequiredSelect = (event) => {
    const { value } = event.target;
    if (value === 'YES') {
      setAclSelected(true);
    } else {
      setAclSelected(false);
    }
  };

  const { data: aclGroupsData, isSuccess: aclGroupsSuccess } = useQuery({
    queryKey: ['aclGroups'],
    queryFn: fetchGroupsApi(),
    refetchOnWindowFocus: false
  });

  const onObjectSpecificSelect = async (event) => {
    const { value } = event.target;
    if (value === 'YES' && aclGroupsSuccess) {
      setObjectSpecificSelected(true);
      setAclGroups(aclGroupsData?.body?.roleObjects);
    } else {
      setObjectSpecificSelected(false);
    }
  };

  const dataObjectTypes = dataObjectTypeData?.body?.primary;

  var schema;

  const apiCall = async (objectType) => {
    const res = request
      .get(`${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`)
      .query({ appCode: app_code, type: objectType })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };

  const onDataTypeSelect = (event) => {
    console.log('selecting.....', event.target);
    const { value } = event.target;
    if (value == 'DOI000005') {
      setShowSequenceFields((prev) => !prev);
    } else {
      setShowSequenceFields(false);
    }
  };

  useEffect(() => {
    async function callme() {
      if (action == 'edit' || action == 'duplicate' || action == 'view') {
        const { objectType = '' } = editDataObjectsData || {};
        if (objectType) {
          setDataObjectValue2(objectType);
          if (objectType == 'REQUEST_OBJECT' || objectType == 'SCHEMA_OBJECT') {
            setShowValidateBtn(true);
          }
          if (objectType == 'REQUEST_OBJECT') {
            setShowValidationRequired(true);
          }
          const res = await apiCall(objectType);
          setDataType(res?.body?.dataObjects);
        }
        setAclGroups(aclGroupsData?.body?.roleObjects);
        const { aclFlag = 0, fieldSpecific = 1 } = editDataObjectsData?.aclData || {};
        if (aclFlag == 1) {
          setAclSelected(true);
        }
        if (fieldSpecific == 0) {
          setObjectSpecificSelected(true);
        }
        setShowForm(true);
      }
    }
    callme();
  }, [JSON.stringify(editDataObjectsData), aclGroupsSuccess]);

  switch (action) {
    case 'edit': {
      schema = editObjectSchema({
        dataObjectTypes,
        onDataObjectSelect,
        dataType,
        showAcl,
        editDataObjectsData,
        onAclRequiredSelect,
        aclSelected,
        onObjectSpecificSelect,
        objectSpecificSelected,
        aclGroups,
        showValidateBtn,
        onValidateBtnClick,
        showValidationRequired
      });
      break;
    }
    case 'view': {
      schema = viewObjectSchema({
        dataObjectTypes,
        onDataObjectSelect,
        dataType,
        showAcl,
        editDataObjectsData,
        onAclRequiredSelect,
        aclSelected,
        onObjectSpecificSelect,
        objectSpecificSelected,
        aclGroups,
        isSubActionReview,
        showValidateBtn,
        onValidateBtnClick,
        showValidationRequired
      });
      break;
    }
    case 'duplicate': {
      schema = duplicateObjectSchema({
        dataObjectTypes,
        onDataObjectSelect,
        dataType,
        showAcl,
        editDataObjectsData,
        onAclRequiredSelect,
        aclSelected,
        onObjectSpecificSelect,
        objectSpecificSelected,
        aclGroups,
        showValidateBtn,
        onValidateBtnClick,
        showValidationRequired
      });
      break;
    }
    default: {
      schema = newObjectSchema({
        dataObjectTypes,
        onDataObjectSelect,
        showAcl,
        dataType,
        onAclRequiredSelect,
        aclSelected,
        onObjectSpecificSelect,
        objectSpecificSelected,
        aclGroups,
        onValidateBtnClick,
        showValidateBtn,
        showValidationRequired,
        onDataTypeSelect,
        showSequenceFields
      });
      break;
    }
  }

  const formStore = useSelector((store) => store?.store?.form);

  const formStoreData = formStore.formData || {};

  const { isEdited } = formStoreData || {};

  const getDataBasedOnName = ({ matchingName, data }) => {
    const keyNames = Object.keys(data).filter((element) => {
      return element.startsWith(matchingName);
    });
    const keyData = keyNames.map((element) => data[element]);
    return keyData;
  };
  function getNameByDataObjectId(dataObjectId) {
    const foundData = dataType.find((item) => item.dataObjectId === dataObjectId);
    return foundData ? foundData.code : null;
  }
  const filterValidationRule = (dataTypeId, validations) => {
    const result = {};
    if (dataTypeId == 'DOI000001') {
      if (validations?.multipleOf?.checked) {
        result.multipleOf = validations?.multipleOf?.value;
      }
      if (validations?.multipleOf?.checked) {
        result.multipleOf = validations?.multipleOf?.value;
      }
      if (validations?.maximum?.checked) {
        result.maximum = validations?.maximum?.value;
      }
      if (validations?.exclusiveMaximum?.checked) {
        result.exclusiveMaximum = validations?.exclusiveMaximum?.value;
      }
      if (validations?.minimum?.checked) {
        result.minimum = validations?.minimum?.value;
      }
      if (validations?.exclusiveMinimum?.checked) {
        result.exclusiveMinimum = validations?.exclusiveMinimum?.value;
      }
      if (validations?.minItems?.checked) {
        result.minItems = validations?.minItems?.value;
      }
      if (validations?.maxItems?.checked) {
        result.maxItems = validations?.maxItems?.value;
      }
      return result;
    } else if (dataTypeId == 'DOI000002') {
      if (validations?.maxLength?.checked) {
        result.maxLength = validations?.maxLength?.value;
      }
      if (validations?.minLength?.checked) {
        result.minLength = validations?.minLength?.value;
      }
      if (validations?.pattern?.checked) {
        result.pattern = validations?.pattern?.value;
      }
      if (validations?.minItems?.checked) {
        result.minItems = validations?.minItems?.value;
      }
      if (validations?.maxItems?.checked) {
        result.maxItems = validations?.maxItems?.value;
      }
      return result;
    }
  };
  const createPropertiesPayload = (inputData, propertiesValidations) => {
    return inputData.map((item, index) => {
      return {
        code: item?.Code,
        name: item?.Name,
        isArray: item?.isArray == 'YES' ? true : false,
        dataObjectTypeId: item?.['Data Type'],
        dataType: getNameByDataObjectId(item?.['Data Type']),
        description: item?.Description,
        isOptional: item?.Optional || false,
        validationsRule: propertiesValidations[index]
          ? filterValidationRule(item?.['Data Type'], propertiesValidations[index])
          : {}
      };
    });
  };
  const createGroupsPayload = (inputData) => {
    return inputData.map((item) => {
      return {
        groups: item?.Groups,
        allow: item?.Allow === 'ALLOW' ? 1 : 0,
        actions: item?.Actions
      };
    });
  };

  var onSubmitForm;

  switch (action) {
    case 'view': {
      onSubmitForm = () => {
        navigate(`/apps/${app_code}/dataObject`);
      };
      break;
    }
    default: {
      onSubmitForm = async ({ title, state = 'REVIEW' }) => {
        const store = formStore?.formData;
        const formData = formStore?.formData?.[title];
        const payload = {};
        const objectData = formData?.['Definition-0'];
        const propertiesFormData = formData;
        const properties = getDataBasedOnName({
          matchingName: 'Properties-',
          data: propertiesFormData
        });

        const propertiesValidations = getDataBasedOnName({
          matchingName: 'validationRule-Properties-',
          data: store
        });

        if (properties[0]?.Code) {
          const propertiesData = createPropertiesPayload(properties, propertiesValidations);
          payload.keys = propertiesData;
        }
        payload.applicationCode = app_code;
        payload.code = objectData?.Code;
        payload.name = objectData?.Name;
        payload.objectType = objectData?.Type;
        payload.description = objectData?.Description;
        payload.validationFlag = objectData?.['Validation Required'] === 'YES' ? true : false;
        if (objectData?.['ACL Required']) {
          payload.aclFlag = objectData?.['ACL Required'] === 'YES' ? 1 : 0;
        }
        if (objectData?.['Object Specific']) {
          payload.fieldSpecific = objectData?.['Object Specific'] === 'YES' ? 0 : 1;
        }

        if (objectData?.['Object Specific'] === 'YES') {
          const groups = getDataBasedOnName({
            matchingName: 'Access Control Policy-',
            data: propertiesFormData
          });

          const groupsData = createGroupsPayload(groups);
          payload.fields = groupsData;
        }

        switch (action) {
          case 'edit': {
            const res = await request
              .put(`${process.env.REACT_APP_CONFIG_API}/data-objects/${dataObjectId}`)
              .query({ flag: state })
              .send(payload)
              .set('Content-Type', 'application/json')
              .set('Authorization', `Bearer ${keycloak.token()}`);

            if (res.body.status.code == 'SUCCESS') {
              toast.custom((t) => <Success t={t} message={'Object Submitted For Review'} />, {
                duration: 4000
              });
              dispatch(
                clearFormState({
                  formKey: title
                })
              );
              dispatch(emptyAll());
              navigate(`/apps/${app_code}/dataObject`);
            } else {
              toast.custom((t) => <ErrorNotify t={t} message={res.body.status.message} />, {
                duration: 4000
              });
            }
            break;
          }
          default: {
            try {
              const response = await mutation.mutateAsync({ payload: payload, state: state });
              if (response.body.status.code == 'SUCCESS') {
                toast.custom((t) => <Success t={t} message={response?.body?.status?.message} />, {
                  duration: 4000
                });
                dispatch(
                  clearFormState({
                    formKey: title
                  })
                );
                dispatch(emptyAll());
                navigate(`/apps/${app_code}/dataObject`);
              } else {
                toast.custom((t) => <ErrorNotify t={t} message={response.body.status.message} />, {
                  duration: 4000
                });
              }
            } catch (error) {
              console.log('Error:', error);
            }
            break;
          }
        }
      };
    }
  }

  const onCancleBtnClick = () => {
    if (isEdited) {
      dispatch(
        genericModalStateUpdate({
          modalKey: ModalKeys.stopDataObject,
          isOpen: true
        })
      );
    } else {
      navigate(`/apps/${app_code}/dataObject`);
    }
  };

  const onMenuItemClick = ({ title }) => {
    switch (title) {
      case 'Objects': {
        if (isEdited) {
          dispatch(
            genericModalStateUpdate({
              modalKey: ModalKeys.stopDataObject,
              isOpen: true
            })
          );
        } else {
          navigate(`/apps/${app_code}/dataObject`);
        }
        break;
      }
      case 'New Object': {
        if (isEdited) {
          dispatch(
            genericModalStateUpdate({
              modalKey: ModalKeys.stopNewDataObject,
              isOpen: true
            })
          );
        } else {
          navigate(`/apps/${app_code}/dataObject/new`);
        }
        break;
      }
    }
  };

  // console.log(schema, 'vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv')

  switch (action) {
    case 'edit':
      return (
        <div className="flex">
          <SideBar
            page={'object'}
            onMenuItemClick={onMenuItemClick}
            currentContext={{ object: 'New Object' }}
          />
          <div className="flex-1 flex flex-col bg-[#F4F3FA]">
            <div>
              {/* {isEdited ? (
                <ApplicationTilesContainer askNavigationCheck={true} />
              ) : (
                <ApplicationTilesContainer />
              )} */}
            </div>
            <TopCardSubheader
              topCardNavigationSchema={getApplicationTopNavigationSchema({ objects: true })}
              getCountService={fetchApplicationCount(app_code)}
              formatCountData={formatFeatureCountData}
              subheaderNavigationSchema={getExtensionSubheaderSchema({ application: true })}>
              <div className="w-11/12 m-auto mt-8">
                {showForm ? (
                  <FormV2Container
                    variant="formV3"
                    schema={schema}
                    className={'bg-white'}
                    onSubmitForm={onSubmitForm}
                    submitButtonName={'Submit'}
                    addCancelButton={true}
                    onCancleBtnClick={onCancleBtnClick}
                    showDraftBtn={true}
                  />
                ) : (
                  <div className="m-auto">
                    <CircularProgress />
                  </div>
                )}
              </div>
            </TopCardSubheader>
          </div>
          <ConformationContainer
            modalId={ModalKeys.stopDataObject}
            cancelObject={{ text: 'Cancel', callback: noop }}
            conformObject={{
              text: 'Discard Changes',
              callback: () => navigate(`/apps/${app_code}/dataObject`)
            }}
            modalText={{
              header: 'Discard Changes',
              desc: 'Your Data is Unsaved, do you really want to discard changes !'
            }}
          />
          <ConformationContainer
            modalId={ModalKeys.stopNewDataObject}
            cancelObject={{ text: 'Cancel', callback: noop }}
            conformObject={{
              text: 'Discard Changes',
              callback: () => navigate(`/apps/${app_code}/dataObject/new`)
            }}
            modalText={{
              header: 'Discard Changes',
              desc: 'Your Data is Unsaved, do you really want to discard changes !'
            }}
          />
          <GenericModalContainer modalKey={ModalKeys.dataObjectValidationModal}>
            <DataObjectValidationModalContainer />
          </GenericModalContainer>
        </div>
      );
    case 'view':
      return (
        <div className="flex">
          {!isSubActionReview ? (
            <SideBar
              page={'object'}
              onMenuItemClick={onMenuItemClick}
              currentContext={{ object: 'New Object' }}
            />
          ) : null}
          <div className={`flex-1 flex flex-col ${!isSubActionReview ? 'bg-[#F4F3FA]' : 'mt-4'}`}>
            {!isSubActionReview
              ? null
              : // <div>
                //   {isEdited ? (
                //     <ApplicationTilesContainer askNavigationCheck={true} />
                //   ) : (
                //     <ApplicationTilesContainer />
                //   )}
                // </div>
                null}
            <TopCardSubheader
              topCardNavigationSchema={getApplicationTopNavigationSchema({ objects: true })}
              getCountService={fetchApplicationCount(app_code)}
              formatCountData={formatFeatureCountData}
              subheaderNavigationSchema={getExtensionSubheaderSchema({ application: true })}
              hideMe={isSubActionReview}>
              <div className="w-11/12 m-auto mt-8">
                {!isEmpty(editDataObjectsData?.prevVersions) && (
                  <VersionDropdownComponent
                    data={editDataObjectsData}
                    onClick={({ target: { value = {} } = {} } = {}) => {
                      navigate(`/apps/${app_code}/dataObject/view/${value.id}`);
                      navigate(0);
                    }}
                  />
                )}
                {showForm ? (
                  <FormV2Container
                    variant="formV3"
                    schema={schema}
                    className={'bg-white'}
                    onSubmitForm={onSubmitForm}
                    submitButtonName={'CLose'}
                    disableSubmit={isSubActionReview}
                  />
                ) : (
                  <div className="m-auto">
                    <CircularProgress />
                  </div>
                )}
              </div>
            </TopCardSubheader>
          </div>
          <ConformationContainer
            modalId={ModalKeys.stopDataObject}
            cancelObject={{ text: 'Cancel', callback: noop }}
            conformObject={{
              text: 'Discard Changes',
              callback: () => navigate(`/apps/${app_code}/dataObject`)
            }}
            modalText={{
              header: 'Discard Changes',
              desc: 'Your Data is Unsaved, do you really want to discard changes !'
            }}
          />
          <ConformationContainer
            modalId={ModalKeys.stopNewDataObject}
            cancelObject={{ text: 'Cancel', callback: noop }}
            conformObject={{
              text: 'Discard Changes',
              callback: () => navigate(`/apps/${app_code}/dataObject/new`)
            }}
            modalText={{
              header: 'Discard Changes',
              desc: 'Your Data is Unsaved, do you really want to discard changes !'
            }}
          />
          <GenericModalContainer modalKey={ModalKeys.dataObjectValidationModal}>
            <DataObjectValidationModalContainer editDataObjectsData={editDataObjectsData} />
          </GenericModalContainer>
        </div>
      );
    case 'duplicate':
      return (
        <div className="flex">
          <SideBar
            page={'object'}
            onMenuItemClick={onMenuItemClick}
            currentContext={{ object: 'New Object' }}
          />
          <div className="flex-1 flex flex-col bg-[#F4F3FA]">
            {/* <div>
              <ApplicationTilesContainer />
            </div> */}
            <TopCardSubheader
              topCardNavigationSchema={getApplicationTopNavigationSchema({ objects: true })}
              getCountService={fetchApplicationCount(app_code)}
              formatCountData={formatFeatureCountData}
              subheaderNavigationSchema={getExtensionSubheaderSchema({ application: true })}
              hideMe={isSubActionReview}>
              <div className="w-11/12 m-auto mt-8">
                {showForm && (
                  <FormV2Container
                    variant="formV3"
                    schema={schema}
                    className={'bg-white'}
                    onSubmitForm={onSubmitForm}
                    submitButtonName={'Submit'}
                  />
                )}
              </div>
            </TopCardSubheader>
          </div>
          <ConformationContainer
            modalId={ModalKeys.stopDataObject}
            cancelObject={{ text: 'Cancel', callback: noop }}
            conformObject={{
              text: 'Discard Changes',
              callback: () => navigate(`/apps/${app_code}/dataObject`)
            }}
            modalText={{
              header: 'Discard Changes',
              desc: 'Your Data is Unsaved, do you really want to discard changes !'
            }}
          />
          <ConformationContainer
            modalId={ModalKeys.stopNewDataObject}
            cancelObject={{ text: 'Cancel', callback: noop }}
            conformObject={{
              text: 'Discard Changes',
              callback: () => navigate(`/apps/${app_code}/dataObject/new`)
            }}
            modalText={{
              header: 'Discard Changes',
              desc: 'Your Data is Unsaved, do you really want to discard changes !'
            }}
          />
          <GenericModalContainer modalKey={ModalKeys.dataObjectValidationModal}>
            <DataObjectValidationModalContainer editDataObjectsData={editDataObjectsData} />
          </GenericModalContainer>
        </div>
      );
    default:
      return (
        <div className="flex">
          <SideBar
            page={'object'}
            onMenuItemClick={onMenuItemClick}
            currentContext={{ object: 'New Object' }}
          />
          <div className="flex-1 flex flex-col bg-[#F4F3FA]">
            {/* <div>
              <ApplicationTilesContainer />
            </div> */}
            <TopCardSubheader
              topCardNavigationSchema={getApplicationTopNavigationSchema({ objects: true })}
              getCountService={fetchApplicationCount(app_code)}
              formatCountData={formatFeatureCountData}
              subheaderNavigationSchema={getExtensionSubheaderSchema({ application: true })}
              hideMe={isSubActionReview}>
              <div className="w-[98%] m-auto mt-8">
                {aclGroupsSuccess && (
                  <FormV2Container
                    schema={schema}
                    className={'bg-white'}
                    onSubmitForm={onSubmitForm}
                    variant="formV3"
                    submitButtonName={'Submit'}
                    showDraftBtn={true}
                  />
                )}
              </div>
            </TopCardSubheader>
          </div>
          <ConformationContainer
            modalId={ModalKeys.stopDataObject}
            cancelObject={{ text: 'Cancel', callback: noop }}
            conformObject={{
              text: 'Discard Changes',
              callback: () => navigate(`/apps/${app_code}/dataObject`)
            }}
            modalText={{
              header: 'Discard Changes',
              desc: 'Your Data is Unsaved, do you really want to discard changes !'
            }}
          />
          <ConformationContainer
            modalId={ModalKeys.stopNewDataObject}
            cancelObject={{ text: 'Cancel', callback: noop }}
            conformObject={{
              text: 'Discard Changes',
              callback: () => navigate(`/apps/${app_code}/dataObject/new`)
            }}
            modalText={{
              header: 'Discard Changes',
              desc: 'Your Data is Unsaved, do you really want to discard changes !'
            }}
          />
          <GenericModalContainer modalKey={ModalKeys.dataObjectValidationModal}>
            <DataObjectValidationModalContainer />
          </GenericModalContainer>
          {/* <GenericModalContainer modalKey={ModalKeys.commentModal}>
            <CommentModalContainer onSubmitForm={onSubmitForm} />
          </GenericModalContainer> */}
        </div>
      );
  }
};

export default NewObjectFormContainer;
