import { createSlice } from '@reduxjs/toolkit';

export const stepFormSlice = createSlice({
  name: 'stepForm',
  initialState: {},
  reducers: {
    updateCurrent: (state, action) => {
      const { id, current } = action.payload;
      state[id] = {
        ...state?.[id],
        current: current
      };
    },
    pushInSuccess: (state, action) => {
      const { id, current } = action.payload;
      state[id] = {
        ...state?.[id],
        successList: [...(state?.[id]?.successList || []), current]
      };
    },
    clearSuccess: (state, action) => {
      const { id, current } = action.payload;
      if(state[id]){
        state[id].successList = [];
      }
    }
  }
});

export const { updateCurrent, pushInSuccess, clearSuccess } = stepFormSlice.actions;

export default stepFormSlice.reducer;
