import { createSlice } from '@reduxjs/toolkit';

export const auditLog = createSlice({
  name: 'auditLog',
  initialState: {},
  reducers: {
    setObjectAuditLogGridData: (state, action) => {
      state.objectGridData = action.payload.auditData;
    },
    setObjectMappingAuditLogGridData: (state, action) => {
      state.objectMappingGridData = action.payload.auditData;
    },
    setTransformationAuditLogGridData: (state, action) => {
      state.transformationGridData = action.payload.auditData;
    },
    setObjectAuditLogGridFilterData: (state, action) => {
      state.objectTimeFilter = action.payload.dateTimeFilter;
    },
    setObjectMappingAuditLogGridFilterData: (state, action) => {
      state.objectMappingTimeFilter = action.payload.dateTimeFilter;
    },
    setTransformationAuditLogGridFilterData: (state, action) => {
      state.transformationTimeFilter = action.payload.dateTimeFilter;
    },
  }
});

export const {
  setObjectAuditLogGridData,
  setObjectMappingAuditLogGridData,
  setTransformationAuditLogGridData,
  setObjectAuditLogGridFilterData,
  setObjectMappingAuditLogGridFilterData,
  setTransformationAuditLogGridFilterData
} = auditLog.actions;

export default auditLog.reducer;
