const newApplicationSchema = (selectedKeys) => {
  return {
    title: 'New RealApp',
    formSchema: [
      {
        name: 'Code',
        label: 'Enter Code',
        type: 'text',
        required: true,
        validation: {
          type: 'string',
          min: 3
        }
      },
      { name: 'Name', label: 'Enter Name', type: 'text', required: true },
      { name: 'Description', label: 'Enter Description', type: 'text', required: true },
      {
        name: 'Base Application',
        label: 'Select Base Application',
        type: 'dropdown',
        options: selectedKeys,
        required: false
      }
    ]
  };
};

export default newApplicationSchema;