import request from 'superagent';
import keycloak from '../Keycloak';

const fetchChildDataObjects = async  (id) => {
    const res = await request
      .get(`${process.env.REACT_APP_GENRIC_API}/data-objects/id`)
      .query({ id: id })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };

export default fetchChildDataObjects;