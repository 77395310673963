import { useDispatch, useSelector } from 'react-redux';
import DefaultDashboard from '../../common/layout/container/DefaultDashboard';
import HLFForm from './HLFForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import fetchNetworkData from '../../../services/fetchNetworkData';
import { useEffect } from 'react';
import { useState } from 'react';
import SideBar from '../../ApplicationV2/component/SideBar';
import { dashboardContext } from '../../dashboard/slices/dashboardSlice';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import ConformationContainer from '../../common/conformationModal/containers';
import { noop } from 'lodash';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import LadderStepForm from '../../stepForms/ladderStepForm/components/LadderStepForm';
import LadderStepFormElement from '../../stepForms/ladderStepForm/components/LadderStepFormElement';
import FormV2Container from '../../common/formV2/container/FormV2Container';
import getGenericInfoSchema from '../schemas/getGenericInfoSchema';
import getOrganizationInfoSchema from '../schemas/getOrganizationInfoSchema';
import testIsFormReadyForNext from '../utils/testIsFormReadyForNext';
import getChannelsSchema from '../schemas/getChannelsSchema';
import testIsFormReadyForOrganizationNext from '../utils/testIsFormReadyForOrganizationNext';
import getAllOrganizations from '../utils/getAllOrganizations';
import testIsFormReadyForSubmit from '../utils/testIsFormReadyForSubmit';
import { toast } from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';
import { clearSuccess, updateCurrent } from '../../stepForms/ladderStepForm/slice/stepFormSlice';
import { emptyAll } from '../../common/form/slices/formSlice';
import getEditOrganizationInfoSchema from '../schemas/getEditOrganizationInfoSchema';
import getEditChannelSchema from '../schemas/getEditChannelSchema';
import GetEditChannelSchemaHook from '../schemas/GetEditChannelSchemaHook';
import callValidateForm from '../../../utils/validateIfFormSubmitable';

const HLFFormContainer = () => {
  const { action, networkName } = useParams();
  const [formData, setFormData] = useState({});
  const [currentChannelState, setCurrentChannelState] = useState(true);
  const isFetchData = !!networkName;
  const dispatch = useDispatch();

  const getFormContext = {
    new: 'New Network',
    edit: 'Edit HLF'
  };

  dispatch(
    dashboardContext({ contextName: getFormContext[action], context: getFormContext[action] })
  );

  const navigate = useNavigate();

  const formStore = useSelector((store) => store?.store?.form?.formData);
  const currentContext = useSelector((store) => store.store.dashboard.currentContext);

  const formStoreDataForEdit = formStore || {};

  const { isEdited } = formStoreDataForEdit || {};

  const [channelSchema, setChannelSchema, setUpdatedData, setUpdatedOrgs] =
    GetEditChannelSchemaHook([], [], setCurrentChannelState);

  const formStoreData = formStore?.['HLF NETWORK'];

  const { refetch } = useQuery('fetch-network', fetchNetworkData({ networkName }), {
    enabled: false
  });

  useEffect(() => {
    if (isFetchData) {
      refetch().then((formData) => {
        setFormData(formData?.data?.body?.hlfNetworkObjects[0] || {});
      });
    }
    return function () {
      dispatch(clearSuccess({ id: 'hlf' }));
      dispatch(updateCurrent({ id: 'hlf', current: 1 }));
      dispatch(emptyAll());
    };
  }, []);

  const getDataBasedOnName = ({ matchingName, data }) => {
    const keyNames = Object.keys(data).filter((element) => {
      return element.startsWith(matchingName);
    });
    const keyData = keyNames.map((element) => data[element]);

    return keyData;
  };

  const clientConfigMap = {
    'organization name': 'organizationName',
    'peer msp path': 'peerMspPath',
    'peer name': 'peerName',
    'peer ssl target name override': 'peerSslTargetNameOverride',
    'peer url': 'peerUrl',
    'user name': 'userName'
  };

  const peerConfigMap = {
    'Chain Code Query': 'chaincodeQuery',
    'Endorsing Peer': 'endorsingPeer',
    'Event Source': 'eventSource',
    'Ledger Query': 'ledgerQuery',
    Name: '_nameKey'
  };

  const onSubmitForm = async () => {
    const payload = {};
    const commonData = formStoreData['Common Details-0'];
    payload.name = commonData.Name;
    payload.description = commonData.Description;
    payload.version = commonData.Version;
    const channels = getDataBasedOnName({ matchingName: 'Channels-', data: formStoreData });

    const channelsData = channels.map((element) => {
      const chaincodes = getDataBasedOnName({ matchingName: 'Chain Code-', data: element });
      const clientConfig = getDataBasedOnName({ matchingName: 'Client Config-', data: element });
      const orderers = getDataBasedOnName({ matchingName: 'Orderers-', data: element });
      const peers = getDataBasedOnName({ matchingName: 'Peers-', data: element });

      const chaincodesData = chaincodes.map((ele) => ele.Name);

      const clientConfigData = clientConfig?.[0] || {};

      const clientConfigDataFromMap = Object.keys(clientConfigData).reduce(function (acc, element) {
        acc[clientConfigMap[element]] = clientConfigData[element];
        return acc;
      }, {});

      const orderersData = orderers.map((ele) => ele.Name);

      const peersData = peers.map((peer) => {
        return Object.keys(peer).reduce(function (acc, element) {
          acc[peerConfigMap[element]] = peer[element];
          return acc;
        }, {});
      });

      return {
        chaincodes: chaincodesData,
        clientConfig: clientConfigDataFromMap,
        orderers: orderersData,
        peers: peersData
      };
    });

    const organization = getDataBasedOnName({
      matchingName: 'Organizations-',
      data: formStoreData
    });

    const organizationData = organization.map((org) => {
      const certificateAuthority = getDataBasedOnName({
        matchingName: 'Certificate Authority-',
        data: org
      });
      const certificateAuthorityData = certificateAuthority.map((certificate) => certificate.Name);

      const peers = getDataBasedOnName({
        matchingName: 'Peers-',
        data: org
      });
      const peersData = peers.map((certificate) => certificate.Name);

      return {
        mspid: org.MSPID,
        _nameKey: org.name,
        adminPrivateKey: { path: org['admin private key path'] },
        certificateAuthorities: certificateAuthorityData,
        peers: peersData,
        signedCert: { path: org['signed cart path'] }
      };
    });

    const orderers = getDataBasedOnName({
      matchingName: 'Orderers-0',
      data: formStoreData
    });

    const orderersData = orderers.map((orderers) => {
      const grpcOptions = getDataBasedOnName({
        matchingName: 'Grpc Options-',
        data: orderers
      });

      return {
        eventUrl: orderers['Event Url'],
        url: orderers.url,
        _nameKey: orderers.name,
        tlsCACerts: { path: orderers['TLSCA Cert Path'] },
        grpcOptions: grpcOptions[0]
      };
    });

    const certificateAuthorities = getDataBasedOnName({
      matchingName: 'Certificate Authorities-',
      data: formStoreData
    });

    const certificateAuthoritiesData = certificateAuthorities.map((certificate) => {
      const caName = certificate['Ca Name'];
      const httpOptionVerify = !!certificate['Http Options-0']?.verify;
      const name = certificate.Name;
      const tlscaCertPath = certificate['TLSCA Cert Path'];
      const url = certificate['URL'];

      return {
        caName,
        httpOptionVerify: { verify: httpOptionVerify },
        tlsCACerts: { path: tlscaCertPath },
        _nameKey: name,
        url
      };
    });

    const peers = getDataBasedOnName({
      matchingName: 'Peers-',
      data: formStoreData
    });

    const peersData = peers.map((peer) => {
      const eventUrl = peer['Event URL'];
      const HSCACertPath = peer['HSCA Cert Path'];
      const name = peer.Name;
      const url = peer.URL;
      const grpcOptions = peer['Grpc Options-0'];

      return {
        eventUrl,
        grpcOptions,
        tlsCACerts: { path: HSCACertPath },
        url: url,
        _nameKey: name
      };
    });

    payload.channels = channelsData;
    payload.organization = organizationData;
    payload.orderers = orderersData;
    payload.certificateAuthorities = certificateAuthoritiesData;
    payload.peers = peersData;

    switch (action) {
      case 'new': {
        const formResponse = await request
          .post(`${process.env.REACT_APP_GENRIC_API}/hlf/networks`)
          .send(payload)
          .set('Authorization', `Bearer ${keycloak.token()}`);
        if (formResponse.status == '200') {
          navigate('/hlf');
        }
        break;
      }
      case 'edit': {
        const formResponse = await request
          .put(`${process.env.REACT_APP_GENRIC_API}/hlf/networks/${commonData.Name}`)
          .send(payload)
          .set('Authorization', `Bearer ${keycloak.token()}`);
        if (formResponse.status == '200') {
          navigate('/hlf');
        }
        break;
      }
      case 'copy': {
        const formResponse = await request
          .post(`${process.env.REACT_APP_GENRIC_API}/hlf/networks`)
          .send(payload)
          .set('Authorization', `Bearer ${keycloak.token()}`);
        if (formResponse.status == '200') {
          navigate('/hlf');
        }
        break;
      }
    }
  };
  const onMenuItemClick = ({ contextName, title }) => {
    switch (title) {
      case 'HLF Networks':
      case 'Hyperledger':
      case 'HLF': {
        dispatch(clearSuccess({ id: 'hlf' }));
        dispatch(updateCurrent({ id: 'hlf', current: 1 }));
        if (isEdited) {
          dispatch(
            genericModalStateUpdate({
              modalKey: ModalKeys.stopNavigationOnHlfClick,
              isOpen: true
            })
          );
        } else {
          navigate('/hlf');
        }
        break;
      }
      case 'Application': {
        if (isEdited) {
          dispatch(
            genericModalStateUpdate({
              modalKey: ModalKeys.stopNavigationOnDashboardClick,
              isOpen: true
            })
          );
        } else {
          navigate('/dashboard');
        }
        break;
      }
      case 'New Network': {
        dispatch(clearSuccess({ id: 'hlf' }));
        dispatch(updateCurrent({ id: 'hlf', current: 1 }));
        dispatch(emptyAll());
        if (isEdited) {
          dispatch(
            genericModalStateUpdate({
              modalKey: ModalKeys.stopNavigationOnNewHlfClick,
              isOpen: true
            })
          );
        } else {
          dispatch(clearSuccess({ id: 'hlf' }));
          dispatch(updateCurrent({ id: 'hlf', current: 1 }));
          dispatch(emptyAll());
          navigate('/hlf/new');
        }
        break;
      }
    }
  };

  const genericFormData = useSelector((store) => store?.store?.form?.formData?.genericForm) || {};
  const channelFormData = useSelector((store) => store?.store?.form?.formData?.channels) || {};
  const organizationFormData =
    useSelector((store) => store?.store?.form?.formData?.organization) || {};

  const isGenericNextReady = !testIsFormReadyForNext(genericFormData, { description: true });

  const isOrganizationNextReady = !testIsFormReadyForOrganizationNext(organizationFormData);

  const isChannelNextReady = !testIsFormReadyForSubmit(channelFormData);

  var allOrganizations = getAllOrganizations(organizationFormData);

  useEffect(() => {
    setUpdatedData(formData);
    setUpdatedOrgs(allOrganizations);
  }, [JSON.stringify(formData), JSON.stringify(allOrganizations)]);

  const submitLadderForm = async () => {
    const genericData = {
      customer: genericFormData.customerName,
      network: genericFormData.networkName,
      description: genericFormData.description
    };

    const result = Object.entries(organizationFormData).reduce((acc, [key, value]) => {
      if (key.startsWith('organizations-')) {
        return {
          ...acc,
          [key]: {
            organizationName: organizationFormData[key].organizationName,
            ...Object.keys(organizationFormData[key]).reduce((acc, value) => {
              if (value.startsWith('peers-')) {
                return {
                  ...acc,
                  [value]: Object.values(organizationFormData[key][value])[0]
                };
              } else {
                return acc;
              }
            }, {})
          }
        };
      } else {
        return acc;
      }
    }, {});

    const organizationPayload = Object.keys(result).map((key) => {
      return Object.keys(result[key]).reduce((acc, secondKey) => {
        if (secondKey.startsWith('organizationName')) {
          return { ...acc, name: result[key][secondKey] };
        }
        if (secondKey.startsWith('peers-')) {
          return {
            ...acc,
            peerNames: [...(acc?.peerNames || []), result[key][secondKey]]
          };
        }
      }, {});
    });

    const channelPayload = Object.keys(channelFormData).reduce((acc, element) => {
      if (element.startsWith('channels-')) {
        return [
          ...acc,
          {
            name: channelFormData[element].channelName,
            orgNames: channelFormData[element].organizations
          }
        ];
      }
      return acc;
    }, []);

    const payload = {
      ...genericData,
      organizations: [...organizationPayload],
      channels: [...channelPayload]
    };

    let response;

    // const canBeFormSubmitable = callValidateForm({
    //   formData: formStore,
    //   formKeys: ['genericForm', 'channels', 'organizationForm']
    // });

    if (action == 'edit') {
      if (currentChannelState) {
        payload.channels = payload.channels.concat(formData.channels);
      }

      response = await request
        .put(`${process.env.REACT_APP_CONFIG_API}/hlf/edit/${networkName}`)
        .send(payload)
        .set('Authorization', `Bearer ${keycloak.token()}`);
    } else {
      response = await request
        .post(`${process.env.REACT_APP_CONFIG_API}/hlf`)
        .send(payload)
        .set('Authorization', `Bearer ${keycloak.token()}`);
    }

    if (response.body.status.code == 'SUCCESS') {
      toast.custom((t) => <Success t={t} message={'HLF formed Successfully'} />, {
        duration: 4000
      });

      dispatch(updateCurrent({ id: 'hlf', current: 1 }));
      dispatch(clearSuccess({ id: 'hlf' }));
      dispatch(emptyAll());
      navigate('/hlf');
    } else {
      toast.custom((t) => <ErrorNotify t={t} message={'Failed creating HLF'} />, {
        duration: 4000
      });
    }
  };

  const basicNextText = action == 'edit' ? 'Edit Organization' : 'Add Organization';
  const organizationNextText = action == 'edit' ? 'Edit Channels' : 'Add Channels';

  return (
    <div className="flex bg-[#E4E3FF]">
      <SideBar
        page="HLF"
        onMenuItemClick={onMenuItemClick}
        currentContext={{ HLF: getFormContext[action] }}
      />
      <div className="bg-[#E4E3FF] flex-1">
        <div className="mx-[100px] mt-[52px]">
          {action == 'edit' ? (
            <>
              <div className="text-[32px] font-semibold mb-4">Edit Connection Profile</div>
              <LadderStepForm
                id="hlf"
                defaultOpen="2"
                elements={[
                  {
                    title: 'Basic Information',
                    child: (
                      <FormV2Container
                        schema={getGenericInfoSchema(formData, true)}
                        className="flex w-[100%]"
                        disableSubmit={true}
                      />
                    ),
                    isNextDisabled: isGenericNextReady,
                    nextText: basicNextText,
                    noNext: true
                  },
                  {
                    title: 'Organization',
                    child: (
                      <FormV2Container
                        schema={getEditOrganizationInfoSchema(formData)}
                        className="flex w-[100%]"
                        disableSubmit={true}
                      />
                    ),
                    isNextDisabled: isOrganizationNextReady,
                    nextText: organizationNextText
                  },
                  {
                    title: 'Channels',
                    child: (
                      <FormV2Container
                        schema={channelSchema}
                        className="flex w-[100%]"
                        disableSubmit={true}
                      />
                    ),
                    isNextDisabled: isChannelNextReady,
                    nextText: 'Submit Networks',
                    submitForm: submitLadderForm
                  }
                ]}
              />
            </>
          ) : (
            <>
              <div className="text-[32px] font-semibold mb-4">New Connection Profile</div>
              <LadderStepForm
                id="hlf"
                newForm={true}
                elements={[
                  {
                    title: 'Basic Information',
                    child: (
                      <FormV2Container
                        schema={getGenericInfoSchema()}
                        className="flex w-[100%]"
                        disableSubmit={true}
                      />
                    ),
                    isNextDisabled: isGenericNextReady,
                    nextText: basicNextText
                  },
                  {
                    title: 'Organizations',
                    child: (
                      <FormV2Container
                        schema={getOrganizationInfoSchema()}
                        className="flex w-[100%]"
                        disableSubmit={true}
                      />
                    ),
                    isNextDisabled: isOrganizationNextReady,
                    nextText: organizationNextText
                  },
                  {
                    title: 'Channels',
                    child: (
                      <FormV2Container
                        schema={getChannelsSchema(allOrganizations)}
                        className="flex w-[100%]"
                        disableSubmit={true}
                      />
                    ),
                    isNextDisabled: isChannelNextReady,
                    nextText: 'Submit Connection',
                    submitForm: submitLadderForm
                  }
                ]}
              />
            </>
          )}
        </div>
        <ConformationContainer
          modalId={ModalKeys.stopNavigationOnDashboardClick}
          cancelObject={{ text: 'Cancel', callback: noop }}
          conformObject={{
            text: 'Discard Changes',
            callback: () => navigate('/dashboard')
          }}
          modalText={{
            header: 'Discard Changes',
            desc: 'Your Data is Unsaved, do you really want to discard changes !'
          }}
        />
        <ConformationContainer
          modalId={ModalKeys.stopNavigationOnHlfClick}
          cancelObject={{ text: 'Cancel', callback: noop }}
          conformObject={{
            text: 'Discard Changes',
            callback: () => navigate('/hlf')
          }}
          modalText={{
            header: 'Discard Changes',
            desc: 'Your Data is Unsaved, do you really want to discard changes !'
          }}
        />
        <ConformationContainer
          modalId={ModalKeys.stopNavigationOnNewHlfClick}
          cancelObject={{ text: 'Cancel', callback: noop }}
          conformObject={{
            text: 'Discard Changes',
            callback: () => navigate('/hlf/new')
          }}
          modalText={{
            header: 'Discard Changes',
            desc: 'Your Data is Unsaved, do you really want to discard changes !'
          }}
        />
      </div>
    </div>
  );
};

export default HLFFormContainer;
