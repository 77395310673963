import React from 'react';
import SideBar from '../../ApplicationV2/component/SideBar';
import { useNavigate, useParams } from 'react-router-dom';
import ApplicationTiles from '../../Application/component/ApplicationTiles';
import ApplicationTilesContainer from '../../Application/container/ApplicationTiles';
import ConnectionFrameworkGridContainer from '../containers/ConnectionFrameworkGridContainer';
import TopCardSubheader from '../../../layouts/TopCardSubheader';
import getApplicationTopNavigationSchema from '../../../utils/navigationUtils/getApplicationTopNavigationSchema';
import getConnectionTopNavigationSchema from '../../../utils/navigationUtils/getConnectionTopNavigationSchema';
import { noop } from 'lodash';
import getExtensionSubheaderSchema from '../../../utils/navigationUtils/getExtensionSubheaderSchema';
import fetchConnectionCount from '../../../services/fetchConnectionCount';

const ConnectionFramework = () => {
  const { app_code = '' } = useParams();
  const navigate = useNavigate();

  const onMenuItemClick = ({ title }) => {
    switch (title) {
      case 'New Connection': {
        navigate(`/apps/${app_code}/connections/new`);
        break;
      }
      case 'Variable Management': {
        navigate(`/apps/variables/${app_code}`);
        break;
      }
      case 'Connection Logs': {
        navigate(`/apps/${app_code}/connectionLogs`);
        break;
      }
    }
  };

  const formatConnectionCount = (data) => {
    return {
      connections: data.body.count
    };
  };

  return (
    <div className="flex">
      <SideBar
        page={'connectionFramework'}
        onMenuItemClick={onMenuItemClick}
        currentContext={{ connectionFramework: 'Connections' }}
      />
      <div className="flex-1 flex flex-col bg-[#F4F3FA]">
        {/* <ApplicationTilesContainer context="connection" /> */}
        <TopCardSubheader
          topCardNavigationSchema={getConnectionTopNavigationSchema({ connections: true })}
          getCountService={fetchConnectionCount(app_code)}
          formatCountData={formatConnectionCount}
          subheaderNavigationSchema={getExtensionSubheaderSchema({ extension: true })}>
          <ConnectionFrameworkGridContainer />
        </TopCardSubheader>
        {/* <GenericModalContainer modalKey={ModalKeys.deleteFeatureModal}>
          <DeleteFeatureModalContainer />
        </GenericModalContainer>
        <FeatureGridContainer deleteFeatureClick={deleteFeatureClick} /> */}
      </div>
    </div>
  );
};

export default ConnectionFramework;
