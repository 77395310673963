import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import request from 'superagent';
import keycloak from '../../../Keycloak';

const NetworkListDropdown = ({ networkName, handleChange }) => {
  const [networkListData, setNetworkListData] = useState([]);

  const formatNetworkListOptions = (data = []) => {
    return data.map((item) => {
      return {
        name: item.customer,
        value: item.network
      };
    });
  };
  const fetchNetworkList = async () => {
    const res = await request
      .get(`${process.env.REACT_APP_CONFIG_API}/hlfExplorer/listOfNetworks`)
      .set('Authorization', `Bearer ${keycloak.token()}`);

    console.log(res, 'lllllllllllllllllllll');
    setNetworkListData(formatNetworkListOptions(res?.body?.data));
  };

  useEffect(() => {
    fetchNetworkList();
  }, []);
  return (
    <Box sx={{ minWidth: '200px', marginRight: '40px', marginTop: '40px' }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select Network</InputLabel>
        <Select id="network-list-select" value={networkName} label="Age" onChange={handleChange} >
          {networkListData.map((option) => (
            <MenuItem value={option?.value}>{option?.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default NetworkListDropdown;
