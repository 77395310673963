import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchHLFDataAction,
  fetchHLFExplorerDataAction
} from '../../Application/slices/applicationSlice';
import HlfExplorerGrid from '../component/HlfExplorerGrid';
import fetchHLFExplorerListData from '../../../services/fetchHlfExplorerListData';
import NetworkListDropdown from '../component/NetworkListDropdown';
import { useEffect, useState } from 'react';
import { noop } from 'lodash';

const HlfExplorerGridContainer = ({ onBlockHashClick = noop, onHlfTxnClick = noop }) => {


  const dispatch = useDispatch();

  const [networkName, setNetworkName] = useState('audit_engine');

  const { refetch } = useQuery(
    'HLF-Explorer-Data',
    fetchHLFExplorerListData({ networkName: networkName }),
    {
      enabled: false
    }
  );

  
  const HLFExplorerStoreData =
    useSelector((store) => store.store.moreApplication.HLFExplorerData) || [];

  const fetchHLFExplorerData = async () => {
    const refetchData =  await refetch();
    const { data, isSuccess } = refetchData;

    if (isSuccess) {
      dispatch(fetchHLFExplorerDataAction({ HLFExplorerData: data?.body?.data }));
    }
    return data?.body;
  };

  const handleChange = (event) => {
    setNetworkName(event.target.value);
    console.log("selected event...",event.target.value)

    fetchHLFExplorerData();
  };

  return (
    <div className="w-[100%] h-screen">
      <div className="flex justify-end">
        <NetworkListDropdown handleChange={handleChange} networkName={networkName} />
      </div>
      <div className="w-[100%]">
        <HlfExplorerGrid
          title="HLF Explorer Networks"
          fetchHLFExplorerData={fetchHLFExplorerData}
          HLFExplorerData={HLFExplorerStoreData}
          onBlockHashClick={onBlockHashClick}
          onHlfTxnClick={onHlfTxnClick}
        />
      </div>
    </div>
  );
};

export default HlfExplorerGridContainer;
