function getEditOrganizationInfoSchema(formData) {
  const { organizations = [] } = formData;

  return {
    title: 'organization',
    disableSubmit: true,
    hideTitle: true,
    formSchema: [
      {
        name: 'organizations',
        label: 'Organizations',
        disableSubmit: true,
        require: false,
        type: 'Fields',
        addIconName: 'Add Organization',
        removeIconName: 'Remove Organization',
        duplicate: true,
        disabled: true,
        hideTitle: true,
        defaultSchema: [
          {
            name: 'organizationName',
            label: 'Name',
            type: 'text',
            require: false,
            defaultValue: ''
          },
          {
            name: 'peers',
            label: 'peers',
            disableSubmit: true,
            require: false,
            type: 'Fields',
            duplicate: true,
            hideTitle: true,
            addIconName: 'Add Organization',
            removeIconName: 'Remove Organization',
            varient: 'full',
            data: [
              [
                {
                  name: 'peer-0',
                  label: 'peers',
                  type: 'text',
                  require: false,
                  defaultValue: '',
                  isRemoveOff: true
                }
              ]
            ]
          }
        ],
        data: {
          ...organizations.map((element) => {
            const child = element.peerNames.map((item, index) => {
              return {
                name: `peer-${index}`,
                disabled: true,
                label: 'peers',
                type: 'text',
                require: false,
                defaultValue: item,
                isRemoveOff: true
              };
            });
            child.isRemoveOff = true;

            const updatedChildData = child.map((element) => {
              const data = [element];
              data.isRemoveOff = true;
              return data;
            });

            const parent = [
              {
                name: 'organizationName',
                label: 'Name',
                type: 'text',
                require: false,
                defaultValue: element.name,
                disabled: true,
                isRemoveOff: true,
                hideMe: true
              },
              {
                name: 'peers',
                label: 'peers111',
                disableSubmit: true,
                require: false,
                type: 'Fields',
                addIconName: 'Add Peers',
                removeIconName: 'Remove Peers',
                duplicate: true,
                hideTitle: true,
                disabled: true,
                varient: 'full',
                data: updatedChildData,
                accordianName: `Organization: ${element.name}`
              }
            ];

            parent.isRemoveOff = true;
            return parent;
          })
        }
      }
    ]
  };
}

export default getEditOrganizationInfoSchema;
