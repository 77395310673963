import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ErrorNotify from '../../notify/ErrorNotify';
import Success from '../../notify/Success';

const ConfigFileDrop = ({ setFileData, fetchOrganizationData, fetchHLFData }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [validFile, setValidFile] = useState(false);
  const dispatch = useDispatch();
  const hlfData = useSelector((state) => {
    return state?.store?.hlf;
  });

  const { network, organization, organizationId } = hlfData;

  const onDropHandler = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
    }
  }, []);

  const readFileData = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const jsonData = JSON.parse(e.target.result);
        setFileData(jsonData);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        // Handle the error as needed
      }
    };
    reader.readAsText(file);
  };

  useEffect(() => {
    if (selectedFile && selectedFile?.type != 'application/json') {
      toast.custom((t) => <ErrorNotify t={t} message={'Required File Extension is .json'} />, {
        duration: 4000
      });
    } else if (selectedFile) {
      setValidFile(true);
      readFileData(selectedFile);
    }
  }, [JSON.stringify(selectedFile)]);

  useEffect(() => {
    return () => {
      setValidFile(false);
      setFileData(null);
      setSelectedFile(null);
    };
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onDropHandler,
    noClick: true,
    multiple: false
  });

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('orgName', organization);
        formData.append('networkName', network);
        formData.append('id', organizationId);
        const response = await request
          .post(`${process.env.REACT_APP_CONFIG_API}/hlf/upload`)
          .send(formData)
          .set('Authorization', `Bearer ${keycloak.token()}`);

        if (response.status == '200') {
          toast.custom((t) => <Success t={t} message={'Config Uploaded successfully'} />, {
            duration: 4000
          });
          await fetchOrganizationData();
          await fetchHLFData();
          dispatch(
            genericModalStateUpdate({
              modalKey: ModalKeys.config,
              isOpen: false
            })
          );
        } else {
          toast.custom((t) => <ErrorNotify t={t} message={'failed Config Upload'} />, {
            duration: 4000
          });
          console.error('File upload failed.');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  return (
    <div>
      <div
        {...getRootProps()}
        className="p-6 border-2 border-dashed rounded-md h-[350px] cursor-pointer hover:border-gray-500 flex justify-center items-center">
        <input {...getInputProps()} />
        {validFile && selectedFile ? (
          <p className="text-gray-600">{selectedFile.name}</p>
        ) : (
          <div className="flex justify-center">
            <div className="bg-gray-300 rounded-full w-[60px] h-[60px] flex justify-center items-center">
              <img
                width="42"
                height="42"
                src="https://img.icons8.com/ios/50/drag-and-drop.png"
                alt="drag-and-drop"
              />
            </div>
            <div className="ml-4">
              <div className="text-[24px] text-gray-600 font-semibold">Drop anywhere to import</div>
              <div>
                Or Select{' '}
                <span className="text-blue-600 hover:underline" onClick={open}>
                  Files
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      {validFile && selectedFile && (
        <button
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={handleUpload}>
          Upload
        </button>
      )}
    </div>
  );
};

export default ConfigFileDrop;
