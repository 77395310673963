import React from 'react';
import TopCardsContainer from '../modules/common/TopCards/container/TopCardsContainer';
const TopCardsLayout = ({
  topCardNavigationSchema,
  children,
  getCountService,
  formatCountData,
}) => {
  return (
    <div className="w-[100%] flex h-[100%] flex-col">
      <TopCardsContainer
        getCountService={getCountService}
        formatCountData={formatCountData}
        schema={topCardNavigationSchema}
      />
      <div className="flex-1">{children}</div>
    </div>
  );
};

export default TopCardsLayout;
