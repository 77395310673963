import { createSlice } from '@reduxjs/toolkit';

export const flowSlice = createSlice({
  name: 'flow',
  initialState: { flowData: [], metaData: [],versioning:[], workFlowList:[],splitCounter: 1, syncCounter: 1, editFlow: [] },
  reducers: {
    pushFlowData: (state, action) => {
      console.log('action.payload....................', action.payload);
      let index = state.flowData.findIndex((data, index) => data.id == action.payload.id);
      if (state.flowData.length == 0) {
        state.flowData.push(action.payload);
      } else if (index == -1) {
        state.flowData.push(action.payload);
      } else {
        state.flowData[index] = action.payload;
      }
    },
    pushEditFlowData: (state, action) => {
      console.log('action.payload....................', action.payload);
      let index = state.editFlow.findIndex((data, index) => data.id == action.payload.id);
      console.log(index)
      if (state.editFlow.length == 0) {
        state.editFlow.push(action.payload);
      } else if (index == -1) {
        state.editFlow.push(action.payload);
      } else {
        state.editFlow[index] = action.payload;
      }
    },
    pushMetaData: (state, action) => {
      if (state.metaData.length == 0) {
        state.metaData.push(action.payload);
      } else {
        state.metaData[0] = action.payload;
      }
    },
    removeDeletedNode : (state,action)=>{
      state.flowData=state.flowData.filter((data,index)=>data.id!=action.payload[0].id);
      console.log("data is....",state.flowData);
    },
    removeEditDeletedNode:(state,action)=>{
      state.editFlow=state.editFlow.filter((data,index)=>data.id!=action.payload[0].id);
      console.log("data is....",state.editFlow);
    },
    pushVersioning:(state,action) => {
      if(state.versioning.length == 0)
      {
        state.versioning.push(action.payload)
      }
    },
    pushWorkFlowList:(state,action) => {
      if(state.workFlowList.length == 0)
      {
        state.workFlowList.push(action.payload)
      }
    },
    SpreadArray:(state, action)=>{
      if(state.editFlow.length != 0)
      {
        state.editFlow=[]
        action.payload.map((data,index)=>{
          state.editFlow.push(data)
        })
      }
    },
    SetSplitCounter: (state, action) => {
      state.splitCounter = action.payload;
    },
    SetSyncCounter: (state, action) => {
      state.splitCounter = action.payload;
    },
  },
});

export const { pushFlowData, 
  pushMetaData, 
  pushVersioning,
  pushWorkFlowList,
  SetSplitCounter,
  SpreadArray, 
  pushEditFlowData, 
  SetSyncCounter ,
  removeDeletedNode,
  removeEditDeletedNode
} =
  flowSlice.actions;
export default flowSlice.reducer;
