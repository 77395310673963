import React from 'react';
import Users from '../component/Users';
import { useParams } from 'react-router-dom';
import NewUserContainer from './NewUserContainer';
import UserListingContainer from './UserListingContainer';

const UsersContainer = ({ pageContext }) => {

  switch (pageContext) {
    case 'new': {
      return <NewUserContainer />;
    }
    default: {
      return <UserListingContainer />;
    }
  }
};

export default UsersContainer;
