import React from 'react';
import TopCardsContainer from '../modules/common/TopCards/container/TopCardsContainer';
import SubheaderContainer from '../modules/Subheader/container/SubheaderContainer';
import { useParams } from 'react-router-dom';
const TopCardSubheader = ({
  topCardNavigationSchema,
  children,
  getCountService,
  formatCountData,
  subheaderNavigationSchema,
  hideMe,
  triggerFetch,
  hidesecondNavigation = false
}) => {
  const { app_code } = useParams();
  return (
    <div className="w-[100%] flex h-[100%] flex-col">
      {!hideMe && (
        <>
          <div className="ml-8 font-semibold mt-4 text-start text-[36px]">{app_code}</div>
          <SubheaderContainer schema={subheaderNavigationSchema} />
          {!hidesecondNavigation && (
            <TopCardsContainer
              triggerFetch={triggerFetch}
              getCountService={getCountService}
              formatCountData={formatCountData}
              schema={topCardNavigationSchema}
            />
          )}
        </>
      )}

      <div className="flex-1">{children}</div>
    </div>
  );
};

export default TopCardSubheader;
