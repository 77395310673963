import FormContainer from '../../common/form/container';
import formKeys from '../../common/form/constants/formKeys';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData, updateFormState } from '../../common/form/slices/formSlice';
import { useQueries, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  orchAddConditionSchema,
  orchestrationEditFormRuleSchema,
  orchestrationFormRuleSchema,
  orchestrationViewFormRuleSchema
} from '../../../schema/form_schema/newOrchestrationFormSchema';
import {
  getOrchestrationDataObjects,
  getSourceActions
} from '../../../services/newOrchestrationFormData';
import { useEffect, useState } from 'react';
import FormV2Container from '../../common/formV2/container/FormV2Container';
import { noop } from 'lodash';

const OrchestrationFormRuleContainer = ({ orchestrationData = {} }) => {
  const { app_code = '', action = '' } = useParams();

  const [sourceObjectsData, setSourceObjectsData] = useState();
  const [sourceActions, setSourceActions] = useState();

  const dispatch = useDispatch();

  const formStore = useSelector((store) => store.store.form) || {};
  const formData = formStore?.formData || {};
  const fieldData = formData?.['Orchestration Rule']?.['Orchestration-0'];
  const formError = formStore.errorState || {};
  // const formState = formStore.formState || 'stale';
  const results = useQueries([
    {
      queryKey: ['dataObjects'],
      queryFn: getOrchestrationDataObjects({ appCode: app_code }),
      refetchOnWindowFocus: false
    },
    {
      queryKey: ['sourceActions'],
      queryFn: getSourceActions(),
      refetchOnWindowFocus: false
    }
  ]);

  const { data: dataObjectsData = {} } = results[0];
  const { data: sourceActionsData = {} } = results[1];

  const apiSuccess = results.every((result) => result.isSuccess);

  useEffect(() => {
    if (apiSuccess && dataObjectsData && sourceActionsData) {
      setSourceObjectsData(dataObjectsData);
      setSourceActions(sourceActionsData);
    }
  }, [apiSuccess, dataObjectsData, JSON.stringify(sourceActionsData)]);

  const orchestrationDataObject = sourceObjectsData?.body?.dropDownObjects;
  const orchestrationSourceActions = sourceActions?.body?.primary;

  var schema;
  if (action == 'edit') {
    schema = orchestrationEditFormRuleSchema(
      orchestrationDataObject,
      orchestrationSourceActions,
      fieldData,
      orchestrationData
    );
  } else if (action == 'view') {
    schema = orchestrationViewFormRuleSchema(
      orchestrationDataObject,
      orchestrationSourceActions,
      fieldData,
      orchestrationData
    );
  } else {
    schema = orchestrationFormRuleSchema(
      orchestrationDataObject,
      orchestrationSourceActions,
      fieldData
    );
  }

  return action === 'edit' || (action === 'view' && orchestrationData && apiSuccess) ? (
    <FormV2Container
      variant="formV3"
      className={'bg-white'}
      restrictRerender={false}
      formData={formData}
      schema={schema}
      onSubmitForm={noop}
      disableSubmit={true}
    />
  ) : (
    <FormV2Container
      variant="formV3"
      className={'bg-white'}
      restrictRerender={true}
      formData={formData}
      schema={schema}
      onSubmitForm={noop}
      disableSubmit={true}
    />
  );
};

export default OrchestrationFormRuleContainer;
