import React from 'react';
import SideBar from '../../ApplicationV2/component/SideBar';
import { useNavigate, useParams } from 'react-router-dom';
import ConnectionLogProgress from '../components/ConnectionLogProgress';
import { useQuery } from 'react-query';
import { getConnectionLogs } from '../../../services/connectionLogData';
import ConnectionLogGridContainer from './ConnectionLogGridContainer';
import TopCardSubheader from '../../../layouts/TopCardSubheader';
import fetchConnectionCount from '../../../services/fetchConnectionCount';
import getExtensionSubheaderSchema from '../../../utils/navigationUtils/getExtensionSubheaderSchema';

const ConnectionLogContainer = () => {
  const { app_code = '' } = useParams();
  const navigate = useNavigate();

  const onMenuItemClick = ({ title }) => {
    switch (title) {
      case 'New Connection': {
        navigate(`/apps/${app_code}/connections/new`);
        break;
      }
      case 'Variable Management': {
        navigate(`/apps/variables/${app_code}`);
        break;
      }
      case 'Connections': {
        navigate(`/apps/${app_code}/connections`);
        break;
      }
    }
  };
  return (
    <div className="flex">
      <SideBar
        page={'connectionFramework'}
        onMenuItemClick={onMenuItemClick}
        currentContext={{ connectionFramework: 'Connection Logs' }}
      />
      <div className="flex-1 flex flex-col bg-[#F4F3FA]">
        <TopCardSubheader
          subheaderNavigationSchema={getExtensionSubheaderSchema({ extension: true })}
          hidesecondNavigation={true}>
          {/* <ConnectionLogProgress /> */}
          <div className="mt-12">
            <ConnectionLogGridContainer />
          </div>
        </TopCardSubheader>
      </div>
    </div>
  );
};

export default ConnectionLogContainer;
