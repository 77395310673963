import React, { useRef } from 'react';
import realwareBanner from '../../../public/icons/realwareBanner.svg';
import featureBanner from '../../../public/icons/featureBanner.svg';
import optionsLogo from '../../../public/icons/optionsLogo.svg';
import sideclipLogo from '../../../public/icons/sideclip.png';
import arrowRight from '../../../public/icons/arrow_light.png';
import FadeMenu from './Menu';
import NumberCounter from 'number-counter';
import { Button, CircularProgress } from '@mui/material';
import setting from '../../../public/icons/setting.svg';
import applicationIcon from '../../../public/icons/application.svg';
import fileIcon from '../../../public/icons/file.svg';
import importIcon from '../../../public/icons/import.svg';
import GenericModalContainer from '../../modals/genericModal/container';
import NewApplicationFormContainer from '../../dashboard/container/NewApplicationFormContainer';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import getApplicationCode from '../../dashboard/utils/getApplicationCode';
import newApplicationSchema from '../../../schema/form_schema/newApplicationSchema';
const ApplicationCards = ({
  application,
  onMoreInfoClick,
  onDeleteClick,
  onEditClick,
  onUpdateClick,
  onAddUserClick,
  onFileStatusClick,
  openDashboardGenericModal,
  handleUpload,
  handleFileChange,
  onTraceClick,
  onExportClick
}) => {
  const applicationCodes = getApplicationCode(application);
  const formSchema = newApplicationSchema(applicationCodes);
  return (
    <>
      <div className="flex-1 mx-[35px] my-[56px] flex flex-col">
        {/* <div className="flex">
        <div className="mr-[30px]">
          <img src={realwareBanner} alt="" srcset="" />
        </div>
        <div className="mr-[30px]">
          <img src={featureBanner} alt="" srcset="" />
        </div>
        <div className="w-[360px] rounded-[20px] relative bg-white">
          <div className="absolute top-[36px]">
            <img src={sideclipLogo} alt="" srcset="" />
          </div>
          <div className="flex flex-col ml-[28px]">
            <div className="text-[28px] mt-[14%] font-bold">Total Applications</div>
            <div className="flex">
              <img src="" alt="" srcset="" />
              <div>
                {application && (
                  <NumberCounter
                    end={application?.length || 100}
                    delay={3}
                    className="text-[48px] font-semibold text-green-600"
                  />
                )}
              </div>
            </div>
            <div className="flex mt-[8%] items-center">
              <img src={arrowRight} alt="" srcset="" />
              <div className="ml-[20px]">
                <NumberCounter
                  end={100}
                  delay={5}
                  className="text-[24px] font-semibold text-blue-500"
                  postFix="%"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
        <div className="flex justify-end">
          <div
            className="cursor-pointer px-[28px] py-[14px] my-[7px] flex justify-center items-center bg-[#E4E3FF] hover:text-[#4C49ED] rounded-[6px] mr-[20px]"
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_GENRIC_API}/grafana/dashboards`,

                '_blank'
              );
            }}>
            <div className="pr-[16px]">
              <img className='w-[24px] h-[24px]' src={'https://img.icons8.com/ios/100/system-task--v1.png'} alt="" srcset="" />
            </div>
            <div className="text-black">Monitoring</div>
          </div>
          <div
            className="cursor-pointer px-[28px] py-[14px] my-[7px] flex justify-center items-center bg-[#E4E3FF] hover:text-[#4C49ED] rounded-[6px] mr-[20px]"
            onClick={openDashboardGenericModal}>
            <div className="pr-[16px]">
              <img src={applicationIcon} alt="" srcset="" />
            </div>
            <div className="text-black">New RealApp</div>
          </div>
          <div
            onClick={onFileStatusClick}
            className="cursor-pointer px-[28px] py-[14px] my-[7px] flex justify-center items-center bg-[#E4E3FF] hover:text-[#4C49ED] rounded-[6px] mr-[20px]">
            <div className="pr-[16px]">
              <img height={'24px'} width={'24px'} src={fileIcon} alt="" srcset="" />
            </div>
            <div className="text-black">Export/Import</div>
          </div>
        </div>
        {application ? (
          <div className="flex flex-wrap mt-4 ">
            {application?.map((applicationData) => {
              const { name, baseApplication, code, description, id } = applicationData;

              return (
                <div
                  onClick={() => {
                    onMoreInfoClick({ code: code });
                  }}
                  className="cursor-pointer mt-8 mr-[32px] justify-between flex w-[350px] h-[116px] bg-white rounded-[16px] pt-[16px] pr-[16px] pb-[20px] pl-[24px] overflow-hidden">
                  <div className="flex flex-col w-[80%]">
                    <div className="text-[12px] text-orange-800">{code}</div>
                    <div className="text-[24px] overflow-hidden text-ellipsis whitespace-nowrap">
                      {name}
                    </div>
                    <div className="flex">
                      <div className="pt-[6px] text-[#7A7A9D] text-[12px] overflow-hidden text-ellipsis whitespace-nowrap">
                        {description}
                      </div>
                    </div>
                  </div>
                  <FadeMenu
                    onDeleteClick={onDeleteClick}
                    onEditClick={onEditClick}
                    onUpdateClick={onUpdateClick}
                    onAddUserClick={onAddUserClick}
                    onTraceClick={onTraceClick}
                    onExportClick={onExportClick}
                    applicationData={applicationData}>
                    <div className="cursor-pointer">
                      <img src={optionsLogo} alt="" srcset="" />
                    </div>
                  </FadeMenu>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex justify-center items-center h-[100%]">
            <CircularProgress />
          </div>
        )}
      </div>
      <GenericModalContainer modalKey={ModalKeys.dashboardModal}>
        <NewApplicationFormContainer
          isModal={true}
          modalKey={ModalKeys.dashboardModal}
          schema={formSchema}
          handleFileChange={handleFileChange}
          handleUpload={handleUpload}
        />
      </GenericModalContainer>
    </>
  );
};

export default ApplicationCards;
