import {
  FormControlLabel,
  TextField,
  Checkbox,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  RadioGroup,
  Radio,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Tooltip
} from '@mui/material';
import DateTimePicker from 'react-datetime-picker';
import React, { useState } from 'react';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import copyButton from '../../../../public/icons/copy.png';
import { noop } from 'lodash';
import Editor from '@monaco-editor/react';
import FetchDropdown from './FetchDropdown';
import { InfoTwoTone } from '@mui/icons-material';

const FieldComponent = ({
  label,
  type,
  name,
  showSwitchName,
  handleChange,
  disabled,
  defaultValue,
  value,
  isFormDisabled,
  disableForm,
  options,
  errorData,
  multiline,
  dateValue,
  onButtonClick,
  min,
  max,
  classname,
  isCopyButton = false,
  copyButtonCallback = noop,
  hideTitle = false,
  editor = false,
  defaultLanguage = 'json',
  validateFunc = noop,
  specialValidate = true,
  getData,
  formatData,
  triggerMe,
  multiple,
  startAdornment,
  info
}) => {
  const alignment = value ? value : '';
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  switch (type) {
    case 'checkbox':
      return (
        <div className={`flex max-w-[300px] mx-[20px] items-center ${classname}`}>
          <div>{label}</div>
          <Checkbox
            key={name}
            name={name}
            onChange={(e) => {
              handleChange({ target: { name: name, value: e.target.checked } });
            }}
            checked={!!value}
            defaultChecked={!!defaultValue}
            disabled={disabled || isFormDisabled}
          />
        </div>
      );

    case 'date':
      return (
        <div className="flex max-w-[300px] mx-[20px] flex-col">
          <div className="pt-[8px] mb-4">{label}</div>
          <div>
            <DateTimePicker
              format={'y-MM-dd h:mm:ss'}
              key={name}
              name={name}
              value={dateValue}
              onChange={(date, time) => {
                handleChange(date, false, 'date');
              }}
              disabled={disabled || isFormDisabled}
            />
          </div>
        </div>
      );

    case 'text':
    case 'number':
      if (multiline) {
        return (
          <div className="flex flex-col w-[500px] mx-[20px] my-2">
            {!hideTitle ? <div className="overflow-hidden whitespace-nowrap">{label}</div> : null}
            <TextField
              error={!!errorData}
              label={!!errorData ? errorData : ''}
              disabled={disabled || isFormDisabled}
              onChange={handleChange}
              defaultValue={defaultValue}
              value={value}
              key={name}
              multiline
              rows={5}
            />
          </div>
        );
      } else {
        return (
          <div
            className={`flex flex-col relative ${
              isCopyButton ? 'max-w-[250px]' : 'max-w-[200px]'
            } mx-[20px] my-2 ${classname}`}>
            {!hideTitle ? (
              <div className="overflow-hidden whitespace-nowrap">
                {label}
                {info ? (
                  <Tooltip
                    className="ml-4"
                    title={
                      'Validations : - Cannot start with Number , No Special Characters are allowed except _(underscore) and No spaces are allowed'
                    }>
                    <InfoTwoTone />
                  </Tooltip>
                ) : null}
              </div>
            ) : null}
            <div className={`${isCopyButton ? 'flex justify-center items-center' : classname}`}>
              <TextField
                className="!w-[100%] !min-w-[186px]"
                disabled={disabled || isFormDisabled}
                onChange={handleChange}
                defaultValue={defaultValue}
                value={value}
                key={name}
                InputProps={{
                  inputProps: { min: min || 0, max: max || 100000 },
                  startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment>
                }}
                type={type}
                error={specialValidate && value ? validateFunc(value) : !!errorData}
              />
              {isCopyButton ? (
                <div
                  onClick={copyButtonCallback}
                  className="ml-2 rounded-md cursor-pointer h-[30px]  w-[30px]">
                  <img src={copyButton} alt="" srcset="" />
                </div>
              ) : null}
            </div>
          </div>
        );
      }
    case 'switch':
      return (
        <div className={`flex mt-7 mx-[20px] ${classname}`}>
          {showSwitchName ? <div class="mr-4 flex items-center">{name}</div> : null}
          {label ? <div class="mr-4 flex items-center">{label}</div> : null}
          <Switch
            key={name}
            name={name}
            onChange={(e) => {
              handleChange({ target: { name: name, value: e.target.checked } }, disableForm);
            }}
            checked={!!value}
            defaultChecked={!!defaultValue}
            disabled={disabled}
          />
        </div>
      );
    case 'dropdown':
      return (
        <div className={`flex flex-col max-w-[200px] mx-[20px] align-middle mt-2 ${classname}`}>
          <FormControl className="!min-w-[200px]">
            <div>{label}</div>
            <Select
              disabled={isFormDisabled || disabled}
              defaultValue={defaultValue}
              value={value}
              key={name}
              onChange={handleChange}
              name={name}
              autoWidth>
              {options.map((option) => {
                const { name, value } = option;
                return (
                  <MenuItem key={name} value={value}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      );

    case 'fetchDropdown':
      return (
        <FetchDropdown
          classname={classname}
          label={label}
          disabled={isFormDisabled || disabled}
          defaultValue={defaultValue}
          value={value}
          key={name}
          onChange={handleChange}
          name={name}
          autoWidth
          getData={getData}
          formatData={formatData}
          triggerMe={triggerMe}
          multiple={multiple}
        />
      );
    case 'radio':
      return (
        <div className="flex flex-col max-w-[200px] mx-[20px] align-middle">
          <RadioGroup onChange={handleChange} defaultValue={'feature'}>
            {options.map((element) => {
              return (
                <FormControlLabel value={element.value} control={<Radio />} label={element.name} />
              );
            })}
          </RadioGroup>
        </div>
      );
    case 'toggle':
      return (
        <div class="flex flex-col mx-4">
          <div class="flex items-center">{name}</div>
          <ToggleButtonGroup
            style={{ height: '56px' }}
            disabled={isFormDisabled}
            defaultValue={defaultValue}
            color="primary"
            exclusive
            onChange={handleChange}
            key={name}
            name={name}
            value={alignment}
            aria-label="Platform">
            {options.map((option, index) => (
              <ToggleButton name={name} value={option}>
                {option}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      );

    case 'textV2':
      if (multiline) {
        return (
          <div className="flex flex-col w-[500px] mx-[20px] my-2">
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{
                className: 'py-2 px-3 focus:outline-none focus:ring focus:border-blue-300'
              }}
              error={!!errorData}
              label={!!errorData ? errorData : label}
              disabled={disabled || isFormDisabled}
              onChange={handleChange}
              defaultValue={defaultValue}
              value={value}
              key={name}
              multiline
              rows={5}
            />
          </div>
        );
      } else if (editor) {
        return (
          <div className="flex flex-col w-[500px] mx-[20px] my-2">
            <div className="overflow-hidden whitespace-nowrap">{label}</div>
            <Editor
              className="border-2 !border-gray-200 border-solid rounded-md"
              height={'200px'}
              error={!!errorData}
              label={!!errorData ? errorData : ''}
              disabled={disabled || isFormDisabled}
              onChange={(data) => {
                handleChange({ target: { name: name, value: data } });
              }}
              defaultValue={defaultValue}
              value={value}
              key={name}
              multiline
              rows={5}
              defaultLanguage={defaultLanguage}
            />
          </div>
        );
      } else {
        return (
          <div className={`flex flex-col max-w-[200px] mx-[20px] my-2 ${classname}`}>
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{
                className: 'py-2 px-3 focus:outline-none focus:ring focus:border-blue-300'
              }}
              error={!!errorData}
              label={!!errorData ? errorData : label}
              disabled={disabled || isFormDisabled}
              onChange={handleChange}
              defaultValue={defaultValue}
              value={value}
              key={name}
            />
          </div>
        );
      }
    case 'multiSelect': {
      return (
        <div className={`flex flex-col max-w-[200px] mx-[20px] align-middle mt-[8px] ${classname}`}>
          <FormControl className="!min-w-[200px]">
            <div className="overflow-hidden whitespace-nowrap">{label}</div>
            <Select
              multiple
              disabled={isFormDisabled}
              value={value || []}
              key={name}
              onChange={(e) => handleChange(e, '', 'multiSelect')}
              name={name}
              autoWidth>
              {options.map((option) => {
                const { name, value } = option;
                return (
                  <MenuItem
                    disabled={(defaultValue || []).find((ele) => name == ele)}
                    key={name}
                    value={value}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      );
    }
    case 'button': {
      return (
        <Button
          disabled={disabled}
          className={`!normal-case !mx-4 ${classname}`}
          variant="contained"
          onClick={async () => {
            setIsButtonLoading(true);
            await onButtonClick();
            setIsButtonLoading(false);
          }}>
          {isButtonLoading ? <CircularProgress color="peace" size={20} /> : label}
        </Button>
      );
    }
    case 'breakLine': {
      return <div className="h-[10px]"></div>;
    }
  }
};

export default FieldComponent;
