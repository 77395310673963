import React from 'react';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import FormV2Container from '../../common/formV2/container/FormV2Container';
import { useSelector } from 'react-redux';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const NewUserContainer = () => {
  const navigate = useNavigate();

  const fetchAppCodeDropdownData = async () => {
    const res = await request
      .get(`${process.env.REACT_APP_CONFIG_API}/application`)
      .set('Authorization', `Bearer ${keycloak.token()}`);

    if (res?.body?.status?.code == 'SUCCESS') {
      return res?.body?.applicationObjects;
    } else {
      return [];
    }
  };

  const formatAppCodeDropDownData = (data) => {
    return data.map((element) => {
      return {
        name: element.name,
        value: element.code
      };
    });
  };

  const fetchOrganizationDropDownData = async () => {
    const res = await request
      .get(`${process.env.REACT_APP_CONFIG_API}/organization`)
      .set('Authorization', `Bearer ${keycloak.token()}`);

    if (res?.body?.status?.code == 'SUCCESS') {
      return res?.body?.organizationObjects;
    } else {
      return [];
    }
  };

  const formatOrganizationDropDownData = (data) => {
    return data.map((element) => {
      return {
        name: element.org_name,
        value: element.org_id
      };
    });
  };

  const formStore = useSelector((store) => store.store.form) || {};

  const formData = formStore?.formData?.['Trace'] || {};

  const isSubmitDisabled = !formData?.user_id;

  const onFormSubmit = async () => {
    const payload = {
      user_id: formData.user_id,
      username: formData.username,
      org_code: formData.org_code,
      email: formData.email,
      mobile_number: formData.mobile_number,
      app_code: formData.app_code,
      roles: formData.user_roles
    };

    const res = await request
      .post(`${process.env.REACT_APP_CONFIG_API}/dashboard/add_new_customer`)
      .send(payload)
      .set('Authorization', `Bearer ${keycloak.token()}`);

    if (res.body.status.code == 'Success') {
      toast.custom((t) => <Success t={t} message={res.body.status.message} />, {
        duration: 4000
      });
      navigate('/users');
    } else {
      toast.custom(
        (t) => <ErrorNotify t={t} message={res.body.status.message || 'User Creation Failed'} />,
        {
          duration: 4000
        }
      );
    }
  };

  return (
    <div className="bg-white m-8 rounded-md">
      <div className="pl-[48px] py-4 text-3xl rounded-t-md bg-[#00306A] font-semibold text-white">
        New User Information
      </div>
      <FormV2Container
        submitButtonName="Add User"
        onSubmitForm={onFormSubmit}
        disabledClick={isSubmitDisabled}
        className="!p-4"
        submitButtonClassName="!inline-block !ml-8 !w-auto"
        schema={{
          title: 'Trace',
          hideTitle: true,
          formSchema: [
            {
              name: 'user_id',
              label: 'User Id',
              type: 'text',
              defaultValue: '',
              classname: '!inline-block'
            },
            {
              name: 'username',
              label: 'Name',
              type: 'text',
              defaultValue: '',
              classname: '!inline-block'
            },
            {
              name: 'org_code',
              label: 'Organization Code',
              type: 'fetchDropdown',
              classname: '!inline-block',
              getData: fetchOrganizationDropDownData,
              formatData: formatOrganizationDropDownData,
              triggerMe: ''
            },
            {
              name: 'email',
              label: 'Email',
              type: 'text',
              defaultValue: '',
              classname: '!inline-block'
            },
            {
              name: 'breakline1',
              label: 'breakline1',
              type: 'breakLine'
            },
            {
              name: 'mobile_number',
              label: 'Mobile Number',
              type: 'number',
              defaultValue: '',
              classname: '!inline-block'
            },
            {
              name: 'app_code',
              label: 'Application Codes',
              type: 'fetchDropdown',
              defaultValue: '',
              classname: '!inline-block',
              getData: fetchAppCodeDropdownData,
              formatData: formatAppCodeDropDownData,
              triggerMe: ''
            },
            {
              name: 'user_roles',
              label: 'User Roles',
              type: 'multiSelect',
              options: [
                { name: 'APP_ADMIN', value: 'APP_ADMIN' },
                { name: 'APP_ENGINEER', value: 'APP_ENGINEER' }
              ],
              defaultValue: '',
              classname: '!inline-block'
            }
          ]
        }}
      />
    </div>
  );
};

export default NewUserContainer;
