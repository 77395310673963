import request from 'superagent';
import { fetchNestedDataForMapUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const fetchNestedDataForMap = async ({ id }) => {
console.log("12345",id)
  const res = await request
    .get(fetchNestedDataForMapUrl)
    .query({ id: id })
    .set('Authorization', `Bearer ${keycloak.token()}`);
 
  return res;
};

export default fetchNestedDataForMap;
