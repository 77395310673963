import { useDispatch, useSelector } from 'react-redux';
import ConformationModal from '../components/ConformationModal';
import React, { useEffect, useState } from 'react';
import { genericModalStateUpdate } from '../../../modals/slices/genericModalSlice';

function ConformationContainer({
  formData,
  modalId,
  cancelObject,
  conformObject,
  modalText,
  customComponent,
  type,
  doNotEmptyState
}) {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState(formData);

  useEffect(() => {
    setModalData(formData);
  }, [JSON.stringify(formData)]);

  const onCancelModal = () => {
    cancelObject.callback();
    dispatch(
      genericModalStateUpdate({
        modalKey: modalId,
        isOpen: false
      })
    );
  };

  const onSubmitModal = (data) => {
    conformObject.callback(data);
    dispatch(
      genericModalStateUpdate({
        modalKey: modalId,
        isOpen: false
      })
    );
  };

  return (
    <ConformationModal
      type={type}
      doNotEmptyState={doNotEmptyState}
      modalData={modalData}
      modalId={modalId}
      cancelObject={cancelObject}
      conformObject={conformObject}
      modalText={modalText}
      onCancelModal={onCancelModal}
      onSubmitModal={onSubmitModal}
      customComponent={customComponent}
    />
  );
}

export default ConformationContainer;
