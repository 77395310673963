import fetchMatchListData from '../../../services/fetchMatchListData';

import { isEmpty, noop } from 'lodash';
import getSubmitPayload from '../utils/getSubmitPayload';

import formKeys from '../../common/form/constants/formKeys';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress } from '@material-ui/core';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import fetchTargetObjectListData from '../../../services/fetchSourceObjectListData';
import submitChildObjectMapping from '../../../services/submitChildObjectMapping';
import SideBar from '../../ApplicationV2/component/SideBar';
import ConformationContainer from '../../common/conformationModal/containers';
import {
  initializeFormData,
  updateFormData,
  updateFormState
} from '../../common/form/slices/formSlice';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import NewFormAccordian from '../../objectMappingV2/components/NewFormAccordian';
import ApplicationTilesContainer from '../container/ApplicationTiles';
import getEditAccordianData from '../utils/getEditAccordianData';
import getApplicationTopNavigationSchema from '../../../utils/navigationUtils/getApplicationTopNavigationSchema';
import fetchApplicationCount from '../../../services/fetchApplicationCount';
import formatFeatureCountData from '../../../utils/formatFeatureCountData';
import getExtensionSubheaderSchema from '../../../utils/navigationUtils/getExtensionSubheaderSchema';
import TopCardSubheader from '../../../layouts/TopCardSubheader';

const EditObjectMappingForm = ({ inReview = false, reviewObject = {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { action } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const reviewFromUrl = action == 'view';

  const { app_code = reviewObject.appCode, edit_id = reviewObject.id } = useParams();
  const submitEditChildForm = useMutation(submitChildObjectMapping);

  const editId = edit_id;



  const parsedUrl = extractQueryStringFromHashedUrl(window.location.href);

  const [versionData, setVersionData] = useState({});

  const {
    sourceId = reviewObject.sourceId,
    sourceName = reviewObject.sourceCode,
    targetId = reviewObject.targetId,
    targetName = reviewObject.targetCode
  } = parsedUrl;

  const sourecode=sourceName;
  const targetcode=targetName;

  

  function extractQueryStringFromHashedUrl(url) {
    const indexOfHash = url.indexOf('?');

    if (indexOfHash !== -1) {
      const queryString = url.slice(indexOfHash + 1);
      const queryParams = {};

      const keyValuePairs = queryString.split('&');

      keyValuePairs.forEach((keyValue) => {
        const [key, value] = keyValue.split('=');
        queryParams[key] = decodeURIComponent(value);
      });

      return queryParams; 
    } else {
      return {};
    }
  }

  const { refetch, isFetchedAfterMount } = useQuery({
    queryKey: ['object-mapping-form-data'],
    queryFn: fetchMatchListData({ app_code: app_code, id: editId, sourceCode:sourecode,targetcode:targetcode,sourceId, targetId }),
    refetchOnWindowFocus: false,
    retryOnMount: true,
    enabled: false
  });

  useEffect(() => {
    setIsLoading(true);
    refetch().then((objectMappingData) => {
      const { sourceData, targetData, matchKeys, currentStatus, currentVersion, prevVersions } =
        objectMappingData.data || {};

      setVersionData({ status: currentStatus, currentVersion, prevVersions });

      dispatch(
        initializeFormData({
          formKey: formKeys.editObjectMapping,
          formData: {
            sourceData: {
              value: sourceData?.body?.dataObjects
            },
            targetData: {
              value: targetData?.body?.dataObjects
            },
            matchKeys: {
              value: matchKeys
            }
          }
        })
      );
      setIsLoading(false);
    });
  }, [editId, isFetchedAfterMount]);

  const formStore = useSelector((store) => store.store.form[formKeys.editObjectMapping]) || {};

  const editObjectformData = formStore.formData || {};

  const formError = formStore.errorState || {};

  const formState = formStore.formState || 'stale';

  const formStoreData = useSelector((store) => store.store.form?.formData);

  const { isEdited } = formStoreData || {};

  const handleChange = async (event) => {
    if (formStore.formState !== 'stale') {
      dispatch(
        updateFormState({
          formKey: formKeys.editObjectMapping,
          formState: 'stale'
        })
      );
    }

    const { name, value } = event.target;

    switch (name) {
      case 'Target Object Type': {
        const fetchedData = await fetchTargetObjectListData({ app_code, type: value });
        dispatch(
          updateFormData({
            formKey: formKeys.editObjectMapping,
            formField: 'targetObjectListData',
            formData: fetchedData.body.dataObjects
          })
        );
        break;
      }

      case 'Source Object List': {
        dispatch(
          updateFormData({
            formKey: formKeys.editObjectMapping,
            formField: name,
            formData: value
          })
        );
        break;
      }

      case 'Target Object List': {
        dispatch(
          updateFormData({
            formKey: formKeys.editObjectMapping,
            formField: name,
            formData: value
          })
        );
        break;
      }

      default: {
        dispatch(
          updateFormData({
            formKey: formKeys.editObjectMapping,
            formField: name,
            formData: value
          })
        );
      }
    }
  };

  const sourceData = getEditAccordianData({
    data: editObjectformData?.sourceData?.value,
    type: 'source'
  });

  const targetData = getEditAccordianData({
    data: editObjectformData?.targetData?.value,
    type: 'target'
  });

  let mappingData = getEditAccordianData({
    data: editObjectformData?.matchKeys?.value,
    type: 'mapping'
  });

  let current = 0;

  const currentMappingObject = {};

  mappingData = mappingData.map((element) => {
    ++current;
    currentMappingObject[current] = element;
    return {
      ...element,
      current
    };
  });

  const editData = {
    sourceData,
    targetData,
    mappingData,
    currentMappingObject
  };
  const onMenuItemClick = ({ contextName, title }) => {
    switch (title) {
      case 'New ': {
        if (isEdited) {
          dispatch(
            genericModalStateUpdate({
              modalKey: ModalKeys.stopNavAndSendToNewObjMap,
              isOpen: true
            })
          );
        } else {
          navigate(`/${app_code}/object-mapping/new`);
        }
        break;
      }
      case 'Object Mapping': {
        if (isEdited) {
          dispatch(
            genericModalStateUpdate({
              modalKey: ModalKeys.stopNavigation,
              isOpen: true
            })
          );
        } else {
          navigateToHlfObj();
        }
      }
    }
  };

  const onFormSubmit = async ({ mappingData, state }) => {
    const mappingDataSubmit = getSubmitPayload(mappingData, '', true);
    // mappingData = mappingData.map((element) => {
    //   return {
    //     sourceObjectId: element.sourceId,
    //     targetObjectId: element.targetId,
    //     targetObjectCode: element.targetName,
    //     sourceObjectCode: element.sourceName,
    //     id: element.mappingId || null,
    //     attributeCodes: [] // currently empty but there will be children later here
    //   };
    // });
    const result = await submitEditChildForm.mutateAsync({
      query: { id: editId, flag: state },
      payload: {
        applicationCode: app_code,
        sourceObjectId: sourceId,
        targetObjectId: targetId,
        targetObjectCode: targetName,
        sourceObjectCode: sourceName,
        attributeCodes: mappingDataSubmit
      }
    });
  };
  const confirmModal = (data) => {
    setFormData(data);
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.newMappingObjectFormSubmit,
        isOpen: true
      })
    );
  };

  const navigateToHlfObj = () => {
    navigate(`/apps/${app_code}/objectMapping`);
  };

  return (
    <div className={`flex-1 flex flex-col ${inReview ? 'white' : 'bg-[#F4F3FA]'}`}>
      {isEmpty(editObjectformData) || isLoading ? (
        <div className="w-[100%] mt-[200px] h-[100%] flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <div className="flex-1 flex">
          {/* <FormContainer
            variant="original"
            className="mt-4 shadow-none"
            handleChange={handleChange}
            submitForm={noop}
            schema={editObjectMappingSchema(editObjectformData)}
            formData={editObjectformData}
            formError={formError}
            formKey={formKeys.editObjectMapping}
            formState={formState}
          /> */}
          {!inReview ? (
            <SideBar
              page="objectMapping"
              onMenuItemClick={onMenuItemClick}
              currentContext={{ objectMapping: '' }}
            />
          ) : null}
          <div className="flex-1 min-h-[100vh]">
            {/* {!inReview ? (
              isEdited ? (
                <ApplicationTilesContainer askNavigationCheck={true} />
              ) : (
                <ApplicationTilesContainer />
              )
            ) : (
              <></>
            )} */}
            <TopCardSubheader
              topCardNavigationSchema={getApplicationTopNavigationSchema({ objectMappings: true })}
              getCountService={fetchApplicationCount(app_code)}
              formatCountData={formatFeatureCountData}
              subheaderNavigationSchema={getExtensionSubheaderSchema({ application: true })}
              hideMe={inReview}>
              <NewFormAccordian
                newSourceName={sourceName}
                newTargetName={targetName}
                onFormSubmit={confirmModal}
                data={editData}
                type="edit"
                inReview={inReview || reviewFromUrl}
                versionData={versionData}
              />
            </TopCardSubheader>
          </div>
          <ConformationContainer
            type="submit"
            formData={formData}
            modalId={ModalKeys.newMappingObjectFormSubmit}
            cancelObject={{ text: 'Cancel', callback: noop }}
            conformObject={{
              text: 'Save Mapping',
              callback: onFormSubmit
            }}
            modalText={{
              header: 'Save Object Mapping',
              desc: 'Save object mapping will add a new object mapping the application'
            }}
          />
          <ConformationContainer
            modalId={ModalKeys.stopNavigation}
            cancelObject={{ text: 'Cancel', callback: noop }}
            conformObject={{
              text: 'Discard Changes',
              callback: navigateToHlfObj
            }}
            modalText={{
              header: 'Discard Changes',
              desc: 'Your Data is Unsaved, do you really want to discard changes !'
            }}
          />
          <ConformationContainer
            modalId={ModalKeys.stopNavAndSendToNewObjMap}
            cancelObject={{ text: 'Cancel', callback: noop }}
            conformObject={{
              text: 'Discard Changes',
              callback: () => navigate(`/${app_code}/object-mapping/new`)
            }}
            modalText={{
              header: 'Discard Changes',
              desc: 'Your Data is Unsaved, do you really want to discard changes !'
            }}
          />
        </div>
      )}
    </div>
  );
};

export default EditObjectMappingForm;
