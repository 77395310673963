import request from 'superagent';
import {  tenatlist } from '../modules/dashboard/constants/applicationRequest';

import keycloak from '../Keycloak';

const getTenets = async () => {
  try {
    const res = await request.get(tenatlist).set('Authorization', `Bearer ${keycloak.token()}`);
    if (res.body && res.body.organizationObjects) {
      console.log("Response:", res.body.organizationObjects);
      return res.body.organizationObjects;
    } else {
      console.error("Response does not contain expected data structure:", res.body);
      throw new Error("Invalid response structure");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Propagate the error further
  }
};

export default getTenets;