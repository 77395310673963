function getChannelsSchema(allOrganizations) {

  return {
    title: 'channels',
    disableSubmit: true,
    hideTitle: true,
    formSchema: [
      {
        name: 'channels',
        label: 'channels',
        disableSubmit: true,
        require: false,
        type: 'Fields',
        duplicate: true,
        addIconName: 'Add Channel',
        removeIconName: 'Remove Channel',
        data: {
          0: [
            {
              name: 'channelName',
              label: 'Channel Name',
              type: 'text',
              require: false
            },
            {
              name: 'organizations',
              label: 'Organizations',
              type: 'multiSelect',
              require: false,
              options: allOrganizations.map((element) => {
                return { name: element, value: element };
              })
            }
          ]
        }
      }
    ]
  };
}

export default getChannelsSchema;
