const newObjectMappingSchema = (defaultFormData) => {
  const sourceObjectTypeCode = defaultFormData['Source Object Type']?.value;
  const sourceObjectType = defaultFormData['sourceObjectType']?.value?.map((element) => {
    return { name: element.item_id, value: element.item_id };
  });
  const sourceObjectName = defaultFormData['Source Object List']?.value;
  const currentDataObject = defaultFormData['sourceObjectListData']?.value
    ?.filter((element) => element.objectType == sourceObjectTypeCode)
    .map((element) => {
      return { name: element.name, value: element.dataObjectId };
    });

  const targetObjectTypeCode = defaultFormData['Target Object Type']?.value;
  const targetObjectValues = defaultFormData['targetObjectType']?.value?.map(
    (element) => { return { name: element.item_id, value: element.item_id }; }
  );
  const targetObjectName = defaultFormData['Target Object List']?.value;
  const targetListData = defaultFormData['targetObjectListData']?.value?.map((element) => {
    return { name: element.name, value: element.dataObjectId };
  });

  return {
    title: 'New Object Mapping',
    removeSubmitButton: true,
    formSchema: [
      {
        name: 'Source Object Type',
        label: 'Enter Source Object',
        type: 'dropdown',
        options: sourceObjectType,
        required: false
      },
      {
        name: 'Source Object List',
        label: 'Select Source Object',
        type: 'dropdown',
        options: currentDataObject,
        required: false
      },
      {
        name: 'Target Object Type',
        label: 'Select Target Object',
        type: 'dropdown',
        options: targetObjectValues,
        required: false
      },
      {
        name: 'Target Object List',
        label: 'Select Target Object',
        type: 'dropdown',
        options: targetListData,
        required: false
      }
    ]
  };
};

export default newObjectMappingSchema;