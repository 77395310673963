import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { noop } from 'lodash';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import { CircularProgress } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import MenuContainer from '../../common/menu/containers/MenuContainer';
import { Edit } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import 'ag-grid-community/styles/ag-theme-material.css';

const DataObjectGrid = ({
  fetchDataObjectData = noop,
  dataObjectData = [],
  title,
  deleteDataObjectClick
}) => {
  const navigate = useNavigate();

  const hideMenu = {
    NUMBER: 'NUMBER',
    DATE_TIME: 'DATE_TIME',
    AUTO_GENERATE: 'AUTO_GENERATE',
    BASE_RESPONSE: 'BASE_RESPONSE',
    DATE: 'DATE',
    STRING: 'STRING'
  };

  const { app_code = '' } = useParams();
  const columnDefs = [
    { headerName: 'CODE', field: 'code' },
    { headerName: 'NAME', field: 'name' },
    { headerName: 'DESCRIPTION', field: 'description' },
    { headerName: 'USER DEFINED', field: 'userDefined' },
    { headerName: 'OBJECT TYPE', field: 'objectType' },
    { headerName: 'VERSION', field: 'version' },
    { headerName: 'STATUS', field: 'status' },
    {
      headerName: 'Actions',
      field: 'Actions',
      cellRenderer: (data) => {
        if (data?.data?.code in hideMenu) {
          return null;
        }
        if (data?.data?.status == 'REVIEW') {
          return (
            <MenuContainer
              menuData={[
                {
                  name: 'View',
                  callback: () => cellClicked(data, 'view'),
                  icon: <VisibilityIcon fontSize="small" />
                }
              ]}
            />
          );
        }
        return (
          <MenuContainer
            menuData={[
              {
                name: 'View',
                callback: () => cellClicked(data, 'view'),
                icon: <VisibilityIcon fontSize="small" />
              },
              {
                name: 'Edit',
                callback: () => cellClicked(data, 'edit'),
                icon: <Edit fontSize="small" />
              },
              {
                name: 'Duplicate',
                callback: () => cellClicked(data, 'duplicate'),
                icon: <LinkIcon fontSize="small" />
              },
              {
                name: 'Delete',
                callback: () => cellClicked(data, 'delete'),
                icon: <DeleteIcon fontSize="small" />
              }
            ]}
          />
        );
      }
    }
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    floatingFilter: false,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function onShowLoading() {
    gridOptions.api.showLoadingOverlay();
  }

  function onHide() {
    gridOptions.api.hideOverlay();
  }

  let gridApi;

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };

  const rowData = dataObjectData.map((feature) => {
    return {
      code: feature.code,
      name: feature.name,
      description: feature.description,
      userDefined: feature.userDefined || 'NA',
      objectType: feature.objectType,
      id: feature?.dataObjectId,
      status: feature?.status,
      version: feature?.version
    };
  });

  async function onGridReady(params) {
    gridApi = params?.api;
    params?.api?.showLoadingOverlay();
    const fetchData = await fetchDataObjectData();
  }

  function cellClicked(data, field) {
    switch (field) {
      case 'edit': {
        navigate(`/apps/${app_code}/dataObject/edit/${data.data.id}`);
        break;
      }
      case 'delete': {
        deleteDataObjectClick(data.data.id, data.data.name);
        break;
      }
      case 'duplicate': {
        navigate(`/apps/${app_code}/dataObject/duplicate/${data.data.id}`);
        break;
      }
      case 'view': {
        navigate(`/apps/${app_code}/dataObject/view/${data.data.id}`);
        break;
      }
    }
  }

  const onExportClick = () => {
    gridApi = params?.api;
    gridApi?.exportDataAsCsv();
  };

  return (
    <div className="flex-1 ag-theme-material !h-[100%] px-[24px] p-[20px] mx-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      <div className="text-[24px]">{title}</div>
      <div className="flex-1">
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          rowData={rowData}
          onCellClicked={cellClicked}
        />
      </div>
    </div>
  );
};

export default DataObjectGrid;
