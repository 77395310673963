import { CircularProgress } from '@mui/material';
import HLFFormContainer from '../../common/formV2/container/FormV2Container';
import editCopyHLFFormSchema from '../../../schema/form_schema/editCopyHLFFormSchema';
import HLFFormSchema from '../../../schema/form_schema/HLFFormSchema';

const HLFForm =  ({ formData, isFetchData, action, onSubmitForm }) => {
  const showForm = !isFetchData || !!formData;

  let schema;

  switch (action) {
    case 'copy': {
      if (formData) {
        formData.name = '';
        formData.description = '';
      }
      schema = editCopyHLFFormSchema({ formData });
      break;
    }
    case 'edit': {
      schema = editCopyHLFFormSchema({ formData });
      break;
    }
    default: {
      schema = HLFFormSchema();
      break;
    }
  }

  return showForm ? (
    <HLFFormContainer onSubmitForm={onSubmitForm} schema={schema} formData={formData || {}} />
  ) : (
    <CircularProgress />
  );
};
export default HLFForm;