import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getFieldData from '../../../HyperledgerFabricNetworks/container/getFieldData';
import { setIsEditForm, updateField } from '../../form/slices/formSlice';
import FieldComponent from '../component/FieldComponent';
import Joi from 'joi';
import { noop } from 'lodash';

const fieldValidation = () => {
  var validationInstance = Joi;

  validationInstance = validationInstance.string().required();
  return validationInstance;
};

const FieldContainer = ({
  fieldData: newName,
  restrictRerender = false,
  form,
  path,
  isFormDisabled: disableForm
}) => {
  const {
    defaultValue = '',
    label = '',
    disabled = false,
    name,
    showSwitchName = false,
    type,
    overloadOnchange,
    options = [],
    validation,
    multiline = false,
    onButtonClick = noop,
    notValidate,
    min,
    max,
    classname,
    isCopyButton,
    copyButtonCallback,
    hideTitle = false,
    hideMe = false,
    editor = false,
    defaultLanguage = 'json',
    specialValidate = false,
    validateFunc = noop,
    getData = noop,
    formatData = noop,
    triggerMe = '',
    multiple,
    startAdornment,
    info
  } = form;

  let formStore = useSelector((store) => store.store.form?.formData) || {};
  let dispatch = useDispatch();
  const errorPath = [...path.slice(0, path.length - 1), 'error', ...path.slice(-1)];
  const [dateValue, onDateValueChange] = useState(new Date());

  const disablePath = [...path];
  disablePath.pop();
  disablePath.push('isFormDisabled');

  const fieldData = getFieldData({ path, data: formStore });
  const errorData = getFieldData({ path: errorPath, data: formStore });
  const isFormDisabled = getFieldData({ path: disablePath, data: formStore });

  const onFieldDataChange = (event, disableForm, type) => {
    if (value !== '') {
      dispatch(setIsEditForm(true));
    }
    var value;
    if (type == 'date') {
      value = event;
      onDateValueChange(event);
    } else {
      value = event.target.value;
    }
    if (overloadOnchange) {
      overloadOnchange(event);
    }

    if (type == 'multiSelect') {
      dispatch(updateField({ path, value: value }));
    } else {
      dispatch(updateField({ path, value: value }));
    }
    if (disableForm) {
      dispatch(updateField({ path: disablePath, value: !value }));
    }
    const fieldValidationInstance = fieldValidation(validation);
    const validated = fieldValidationInstance.validate(value);
    const errorMessage = validated.error && !notValidate ? validated.error.message : '';
    dispatch(updateField({ path: errorPath, value: errorMessage }));
  };

  const { restrictRerender: restrict } = restrictRerender;
  if (!restrict) {
    useEffect(() => {
      dispatch(updateField({ path, value: defaultValue }));
      return () => {
        dispatch(setIsEditForm(false));
      };
    }, [JSON.stringify(form)]);
  }

  if (hideMe) {
    return null;
  }

  return (
    <FieldComponent
      label={label}
      name={name}
      showSwitchName={showSwitchName}
      handleChange={onFieldDataChange}
      disabled={disabled}
      defaultValue={defaultValue}
      value={fieldData}
      type={type}
      errorData={errorData}
      options={options}
      overloadOnchange={overloadOnchange}
      isFormDisabled={isFormDisabled}
      disableForm={disableForm}
      multiline={multiline}
      editor={editor}
      dateValue={dateValue}
      FieldComponent={FieldComponent}
      onButtonClick={onButtonClick}
      min={min}
      max={max}
      classname={classname}
      isCopyButton={isCopyButton}
      copyButtonCallback={copyButtonCallback}
      hideTitle={hideTitle}
      defaultLanguage={defaultLanguage}
      specialValidate={specialValidate}
      validateFunc={validateFunc}
      getData={getData}
      formatData={formatData}
      triggerMe={triggerMe}
      multiple={multiple}
      startAdornment={startAdornment}
      info={info}
    />
  );
};

export default FieldContainer;
