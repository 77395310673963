import React, { useState } from 'react';
import VariableComponent from '../components/VariableComponent';
import VariablesForm from '../components/VariablesForm';
import VariableGrid from '../components/VariableGrid';
import { useParams } from 'react-router-dom';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import SideBar from '../../ApplicationV2/component/SideBar';
import { noop } from 'lodash';
import getExtensionSubheaderSchema from '../../../utils/navigationUtils/getExtensionSubheaderSchema';
import TopSubHeader from '../../../layouts/TopSubheader';

const VariableContainer = () => {
  const [variableData, setVariableData] = useState([]);

  const { app_code } = useParams();

  const fetchVariableGridData = async () => {
    // /globalvariables/appCode?appCode=
    const response = await request
      .get(`${process.env.REACT_APP_CONFIG_API}/type/connectors/globalvariables/appCode`)
      .query({ appCode: app_code })
      .set('Authorization', `Bearer ${keycloak.token()}`);
    setVariableData(response?.body?.variables || []);
  };

  return (
    <div className="flex">
      <SideBar
        page={'variables'}
        onMenuItemClick={noop}
        currentContext={{ variables: 'Variables' }}
      />
      <TopSubHeader subheaderNavigationSchema={getExtensionSubheaderSchema({ application: true })}>
        <div className="flex-1 flex flex-col bg-[#F4F3FA]">
          <VariablesForm fetchVariableGridData={fetchVariableGridData} />
          <VariableGrid fetchVariableGridData={fetchVariableGridData} variableData={variableData} />
        </div>
      </TopSubHeader>
    </div>
  );
};

export default VariableContainer;
