import request from 'superagent';
import { applicationCountUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';
import { useParams } from 'react-router-dom';

const fetchReviewCount = (url, app_code) => {
  console.log(url, 'mmmmmm');
  return async () => {
    const res = await request
      .get(url)
      .query({ appCode: app_code })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

export default fetchReviewCount;
