import application from '../../public/icons/application.svg';
import organisation from '../../public/icons/organisation.svg';
import hlfLogo from '../../public/icons/hlfLogo.svg';
import navigationSchemaConstant from '../../constants/navigationSchemaConstant';

function onSideNavigationClick(componentData, navigate) {
  switch (componentData.value) {
    case navigationSchemaConstant.hlfNetworks: {
      navigate('/hlf');
      break;
    }
    case navigationSchemaConstant.users: {
      navigate('/users');
      break;
    }
    case navigationSchemaConstant.organisation: {
      navigate('/organisation');
      break;
    }
    case navigationSchemaConstant.canvas: {
      navigate('/showCanvas');
      break;
    }
  }
}

function getDashboardNavigationSchema() {
  return [
    {
      name: 'Applications',
      value: 'application',
      onClick: onSideNavigationClick,
      isSelected: true,
      isDisabled: false,
      imageUrl: application
    },
    {
      name: 'Organisation',
      value: 'organisation',
      onClick: onSideNavigationClick,
      isSelected: false,
      isDisabled: false,
      imageUrl: organisation
    },
    {
      name: 'HLF Networks',
      value: 'hlfNetworks',
      onClick: onSideNavigationClick,
      isSelected: false,
      isDisabled: false,
      imageUrl: hlfLogo
    },
    {
      name: 'User Management',
      value: 'users',
      onClick: onSideNavigationClick,
      isSelected: false,
      isDisabled: false,
      imageUrl: 'https://img.icons8.com/ios-glyphs/100/group.png'
    },
    {
      name: 'Business Process Orchestration',
      value: 'showCanvas',
      onClick: onSideNavigationClick,
      isSelected: false,
      isDisabled: false,
      imageUrl: 'https://img.icons8.com/ios-glyphs/100/group.png'
    }
  ];
}

export default getDashboardNavigationSchema;
