import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {
  getConnectionLogsInfo,
  getConnectionLogsRecordsInfo
} from '../../../services/connectionLogData';
import { useNavigate, useParams } from 'react-router-dom';
import SideBar from '../../ApplicationV2/component/SideBar';
import ConnectionLogProgress from '../components/ConnectionLogProgress';
import ConnectionLogRecords from '../components/ConnectionLogRecords';
import { useDispatch } from 'react-redux';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import GenericModalContainer from '../../modals/genericModal/container';
import { connectionLogRecordInfoUrl } from '../../dashboard/constants/applicationRequest';
import keycloak from '../../../Keycloak';
import request from 'superagent';
import RecordInfoModal from '../components/RecordInfoModal';

const ConnectionLogInfoContainer = () => {
  const { connectionLogId = '', app_code = '' } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [recordsData, setRecordData] = useState([]);
  const [showRecordStep, setShowRecordStep] = useState(false);
  const { isSuccess: featureListSuccess, data: featureListData } = useQuery({
    queryKey: ['featureList'],
    queryFn: getConnectionLogsInfo(connectionLogId),
    refetchOnWindowFocus: false
  });

  const filterByType = (data, targetType) => {
    return data.filter((item) => item.type === targetType);
  };

  const onRecordClick = async (recordId) => {
    const res = await request
      .post(connectionLogRecordInfoUrl)
      .send({ connectorLogId: connectionLogId, recordId: recordId })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    setShowRecordStep(!showRecordStep);
    const recordData = res?.body?.result;
    if (res) {
      setRecordData(recordData);
    }
  };

  const onRecordStepClick = (type) => {
    const jsonData = filterByType(recordsData, type);
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.connectoreLogRecordModal,
        isOpen: true,
        modalDataKey: 'recordData',
        modalData: jsonData
      })
    );
  };

  const onRecordsModalClose = () => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.connectoreLogRecordModal,
        isOpen: false
      })
    );
  };

  const onMenuItemClick = ({ title }) => {
    switch (title) {
      case 'New Connection': {
        navigate(`/apps/${app_code}/connections/new`);
        break;
      }
      case 'Variable Management': {
        navigate(`/apps/variables/${app_code}`);
        break;
      }
      case 'Connections': {
        navigate(`/apps/${app_code}/connections`);
        break;
      }
      case 'Connection Logs': {
        navigate(`/apps/${app_code}/connectionLogs`);
        break;
      }
    }
  };
  return (
    <div className="flex">
      <SideBar
        page={'connectionFramework'}
        onMenuItemClick={onMenuItemClick}
        currentContext={{ connectionFramework: 'Connection Logs' }}
      />
      <div className="w-full h-screen bg-[#F4F3FA]">
        <div className="bg-[#DBEAFE] p-4 justify-center flex">
          <h1 className="text-3xl font-bold">Connection Log Id : {connectionLogId}</h1>
        </div>
        <div className="flex justify-between">
          <div className="w-[35%] h-[93vh] border-r-4">
            <div className="bg-orange-200 flex justify-center mb-8 p-4">
              <h1 className="text-2xl font-bold">Connector Status</h1>
            </div>
            <div className='flex justify-center'>
              <ConnectionLogProgress featureListData={featureListData} isRecord={false} />
            </div>
          </div>
          <div className="w-[65%] items-center">
            <ConnectionLogRecords
              featureListData={featureListData}
              onRecordClick={onRecordClick}
              recordsData={recordsData}
              showRecordStep={showRecordStep}
              onRecordStepClick={onRecordStepClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionLogInfoContainer;
