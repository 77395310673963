import navigationSchemaConstant from '../../constants/navigationSchemaConstant';

function onTopNavigationClick(componentData, navigate, app_code) {
  switch (componentData.value) {
    case navigationSchemaConstant.connections: {
      navigate(`/apps/${app_code}/connections`);
      break;
    }
    case navigationSchemaConstant.reviews: {
      navigate(`/apps/${app_code}/review`);
      break;
    }
    case navigationSchemaConstant.traces: {
      navigate(`/apps/${app_code}/trace`);
      break;
    }
    case navigationSchemaConstant.auditLogs: {
      navigate(`/apps/${app_code}/auditLog`);
      break;
    }
  }
}

function getConnectionTopNavigationSchema({ connections, reviews, traces, auditLogs }) {
  return [
    {
      name: 'Connections',
      value: navigationSchemaConstant.connections,
      onClick: onTopNavigationClick,
      isSelected: connections,
      isDisabled: false,
      imageUrl: 'https://img.icons8.com/ios/100/safety-collection-place.png',
    },
    {
      name: 'Reviews',
      value: navigationSchemaConstant.reviews,
      onClick: onTopNavigationClick,
      isSelected: reviews,
      isDisabled: false,
      imageUrl: 'https://img.icons8.com/ios/100/favorite-window.png',
      hideCount: true
    },
    {
      name: 'Traces',
      value: navigationSchemaConstant.traces,
      onClick: onTopNavigationClick,
      isSelected: traces,
      isDisabled: false,
      count: 30,
      imageUrl: 'https://img.icons8.com/ios/100/cable-release.png',
      hideCount: true
    },
    {
      name: 'Audit Trails',
      value: navigationSchemaConstant.auditLogs,
      onClick: onTopNavigationClick,
      isSelected: auditLogs,
      isDisabled: false,
      imageUrl: 'https://img.icons8.com/ios/100/assignment-turned-in.png',
      hideCount: true
    }
  ];
}

export default getConnectionTopNavigationSchema;
