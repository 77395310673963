import { createSlice, current } from '@reduxjs/toolkit';
import getUpdatedFieldData from '../../../HyperledgerFabricNetworks/container/getUpdatedFieldData';

function deleteDeep(formKey, keyName, object) {
  if (formKey.length == 0) {
    delete object?.[keyName];
    return object;
  }

  return {
    ...object,
    [formKey[0]]: deleteDeep(formKey.slice(1), keyName, object[formKey[0]])
  };
}

export const formSlice = createSlice({
  name: 'form',
  initialState: {},
  reducers: {
    updateFormData: (state, action) => {
      const previousFormData = (state[action.payload.formKey] || {}).formData || {};
      const previousFormFieldData = previousFormData[action.payload.formField] || {};
      state[action.payload.formKey] = {
        ...(state[action.payload.formKey] || {}),
        formData: {
          ...previousFormData,
          [action.payload.formField]: { ...previousFormFieldData, value: action.payload.formData }
        }
      };
    },
    updateField: (state, action) => {
      state.formData = getUpdatedFieldData({
        data: state.formData || {},
        path: action.payload.path,
        value: action.payload.value
      });
    },
    initializeFormData: (state, action) => {
      state[action.payload.formKey] = { formData: action.payload.formData };
    },
    updateFormDataByObject: (state, action) => {
      state[action.payload.formKey] = action.payload.formData;
    },
    updateFormState: (state, action) => {
      state[action.payload.formKey] = {
        ...(state[action.payload.formKey] || {}),
        formState: action.payload.formState
      };
    },
    updateFormFieldError: (state, action) => {
      const previousFormData = (state[action.payload.formKey] || {}).formData || {};
      const newErrorState = {
        ...(state[action.payload.formKey] || {}),
        formData: {
          ...previousFormData,
          [action.payload.fieldName]: { errorState: { errorMessage: action.payload.errorMessage } }
        }
      };
      state[action.payload.formKey] = newErrorState;
    },
    clearFormState: (state, action) => {
      if (action.payload?.formType === 'application') {
        state[action.payload.formKey] = state[action.payload.formKey] || {};
        state[action.payload.formKey].formData = {};
      }
      else if (state[action.payload.formKey]) {
        state.formData[action.payload.formKey] = {};
      }    
    },
    updateFormError: (state, action) => {
      state[action.payload.formKey] = {
        ...(state[action.payload.formKey] || {}),
        errorState: {
          errorMessage: action.payload.errorMessage
        }
      };
    },
    deleteForm: (state, action) => {
      const formKey = action.payload.formKey;
      delete state[formKey];
    },
    deleteFormObjectByKey: (state, action) => {
      const { formKey, keyName } = action.payload;
      if (state.formData) {
        let prevData = { ...JSON.parse(JSON.stringify(current(state))).formData };
        state.formData = deleteDeep(formKey, keyName, prevData);
      }
    },
    emptyAll: (state, action) => {
      state.formData = {};
    },
    setIsEditForm: (state, action) => {
      state.formData = {
        ...(state.formData || {}),
        isEdited: action.payload
      };
    },
    clearKeys(state, action) {
      const keysToRemove = action.payload;
      for (const key of keysToRemove) {
        delete state.formData[key];
      }
    }
  }
});

export const {
  updateFormData,
  updateFormFieldError,
  updateFormState,
  initializeFormData,
  clearFormState,
  updateFormError,
  deleteForm,
  updateFormDataByObject,
  updateField,
  deleteFormObjectByKey,
  emptyAll,
  setIsEditForm,
  clearKeys
} = formSlice.actions;

export default formSlice.reducer;
