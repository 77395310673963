import request from 'superagent';
import {  orgcreation } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const createOrg = async (payload) => {
  
  console.log(payload)
  const res = await request
    .post(orgcreation)
    .send(payload.Organisation[0])
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${keycloak.token()}`);

  if (res.body.status.code == 'SUCCESS') {
    console.log('Form data edited successfully');
  } else {
    console.log(res.body.status.message);
  }

  return res;
};

const listOrg = async () => {
  console.log(payload)
  const res = await request
    .get(orgcreation)
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${keycloak.token()}`);

  if (res.body.status.code == 'SUCCESS') {
    console.log('Form data edited successfully');
  } else {
    console.log(res.body.status.message);
  }

  return res;
};
export default {createOrg, listOrg};