function testIsFormReadyForSubmit(data) {
  const channelCount = Object.keys(data).reduce((acc, element) => {
    if (element.startsWith('channels-')) {
      return acc + 1;
    } else {
      return acc;
    }
  }, 0);

  return channelCount && Object.keys(data).every((element) => {
    if (element.startsWith('channels-')) {
      return data[element].channelName && data[element].organizations.length;
    } else {
      return true;
    }
  });
}

export default testIsFormReadyForSubmit;
