import React, { useEffect, useState } from 'react';
import GenerateAccordians from './GenreateAccordians';
import AccordianDiv from './AccordianDiv';
import { useDrop } from 'react-dnd';
import {
  findParentWithGivenId,
  getMappingStyledOutput,
  makeDataFavourable,
  mergeObjectsWithMatchingProperties
} from '../utils/getParentData';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import deleteObjectMappingProperty from '../../../services/deleteObjectMappingProperty';
import { setIsEditForm } from '../../common/form/slices/formSlice';
import { useDispatch, useSelector } from 'react-redux';
import VersionDropdownComponent from '../../common/VersionDropdown/VersionDropdownComponent';
import { noop } from 'lodash';

const NewFormAccordian = ({
  onFormSubmit,
  data,
  type = 'new',
  newSourceName,
  newTargetName,
  inReview,
  versionData
}) => {
  const dispatch = useDispatch();
  const [mapSchema, setMapSchema] = useState(data?.mappingData || []);
  const deleteMutation = useMutation(deleteObjectMappingProperty);

  const { app_code = '' } = useParams();
  const navigate = useNavigate();
  const [parents, setParents] = useState({});
  const [currentObject, setCurrentObject] = useState(0);
  const [currentObjectMapping, setCurrentObjectMapping] = useState(
    data?.currentMappingObject || {}
  );

  const sourceData = data?.sources?.value || [];
  const targetData = data?.targets?.value || [];

  useEffect(() => {
    if (type == 'new') {
      setMapSchema([]);
    }
  }, [JSON.stringify(sourceData), JSON.stringify(targetData)]);

  let tempSourceData = [];

  let tempTargetData = [];

  if (type == 'new') {
    tempSourceData = makeDataFavourable({ data: sourceData, type: 'source' });
    tempTargetData = makeDataFavourable({ data: targetData, type: 'target' });
  }
  console.log("57 tempSourceData,tempTargetData :- ",tempSourceData,tempTargetData)
  if (type == 'edit') {
    const { sourceData, targetData, mappingData } = data;
    tempSourceData = sourceData;
    tempTargetData = targetData;
  }

  const [{ isOver: isMatchOver }, matchDrop] = useDrop({
    accept: 'delete',
    drop: async (item, monitor) => {
      dispatch(setIsEditForm(true));
      const updatedMatchList = mapSchema.reduce((acc, element) => {
        if (element.current == item.data.current) {
          return acc;
        }
        return [...acc, element];
      }, []);
      setMapSchema(updatedMatchList);
      if (type == 'edit') {
        console.log(item.data);
        const deleteElement = await deleteMutation.mutateAsync({
          id: item.data.mappingId,
          appCode: app_code
        });
      }
    }
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'data',
    drop: (item, monitor) => {
      console.log("88 item :- ",item)
      dispatch(setIsEditForm(true));
      switch (item.location) {
        case 'source': {
          const newElement = findParentWithGivenId({
            data: tempSourceData,
            // id: item.data.sourceId,
            id: item.data.id,
            type: 'source'
          });
          console.log("97 newElement :- ",newElement)
          // this will get the parent of the child and get that back
          newElement.current = currentObject;
          // this is to set to find the node in the place

          // this will set the map schema
          setMapSchema([...mapSchema, newElement]);
          // this will give save the data parallel to the currentObject
          setCurrentObjectMapping({ ...currentObjectMapping, [currentObject]: newElement });
          setCurrentObject(currentObject + 1);
          break;
        }
        case 'target': {
          const newElement = findParentWithGivenId({
            data: tempTargetData,
            // id: item.data.targetId,
            id: item.data.id,
            type: 'target'
          });
          console.log("115 newElement :- ",newElement)
          // this will get the parent of the child and get that back
          newElement.current = currentObject;
          // this is to set to find the node in the place

          // this will set the map schema
          setMapSchema([...mapSchema, newElement]);
          // this will give save the data parallel to the currentObject
          setCurrentObjectMapping({ ...currentObjectMapping, [currentObject]: newElement });
          setCurrentObject(currentObject + 1);
          break;
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  return (
    <div className="flex flex-col">
      {!inReview ? (
        <div className="flex justify-between mt-4">
          <div>
            <button
              className="bg-green-500 w-[200px] h-[50px] px-4 rounded-md mt-[12px] ml-[28px] text-white font-semibold"
              onClick={() => {
                onFormSubmit({ mappingData: mapSchema, state: 'REVIEW' });
              }}>
              Submit Mapping
            </button>
            <button
              className="bg-blue-500 w-[200px] h-[50px] px-4 rounded-md mt-[12px] ml-[28px] text-white font-semibold"
              onClick={() => {
                onFormSubmit({ mappingData: mapSchema, state: 'DRAFT' });
              }}>
              Save As Draft
            </button>
          </div>

          <div
            ref={matchDrop}
            className={
              'w-[300px] h-[100px] mr-4 pt-[24px] text-center !text-black text-[28px] drop-zone'
            }>
            Delete Zone
          </div>
        </div>
      ) : (
        <div className="flex mt-4">
          <button
            className="float-right bg-red-500 w-[200px] h-[50px] px-4 rounded-md mt-[12px] ml-[28px] text-white font-semibold"
            onClick={() => {
              navigate(-1);
            }}>
            Close
          </button>
          <div className="mt-2 ml-4">
            <VersionDropdownComponent
              data={versionData}
              onClick={({ target: { value } }) => {
                navigate(
                  `/${app_code}/object-mapping/view/${value.id}?sourceId=${value.sourceId}&sourceName=${value.sourceCode}&targetId=${value.targetId}&targetName=${value.targetCode}`
                );
                navigate(0);
              }}
            />
          </div>
        </div>
      )}
      <div className="flex justify-between flex-1 pt-[20px] rounded-[20px]">
        <div className="w-[30%] h-[100%] p-[12px] flex-1 mx-4">
          <AccordianDiv
            className="sticky top-[12px] z-50 overflow-hidden whitespace-nowrap text-ellipsis"
            type="header">
            {newSourceName || 'Source Object'}
          </AccordianDiv>
          {tempSourceData.map((schema) => {
            const isPresentInMapping = !!mapSchema.find((element) => {
              return element.sourceId == schema.sourceId;
            });

            return (
              <GenerateAccordians
                inReview={inReview}
                currentObjectMapping={currentObjectMapping}
                isPresentInMapping={isPresentInMapping}
                setMapSchema={setMapSchema}
                setCurrentObjectMapping={setCurrentObjectMapping}
                mapSchema={mapSchema}
                sourceData={tempSourceData}
                targetData={tempTargetData}
                findParentWithGivenId={findParentWithGivenId}
                accordianType="source-target"
                accordianSchema={schema}
                location="source"
                accept="data"
                getMappingStyledOutput={getMappingStyledOutput}
                parents={parents}
                currentObject={currentObject}
                setCurrentObject={setCurrentObject}
              />
            );
          })}
        </div>
        <div
          className={`w-[30%] p-[12px] flex flex-col flex-1 sticky top-0 h-[500px] overflow-auto overflow-x-hidden`}>
          <div className="sticky top-0 z-50">
            <AccordianDiv type="header">Data Mapping</AccordianDiv>
          </div>
          {mapSchema?.map((schema) => {
            return (
              <GenerateAccordians
                inReview={inReview}
                currentObjectMapping={currentObjectMapping}
                setCurrentObjectMapping={setCurrentObjectMapping}
                setMapSchema={setMapSchema}
                current={schema.current}
                mapSchema={mapSchema}
                sourceData={tempSourceData}
                targetData={tempTargetData}
                findParentWithGivenId={findParentWithGivenId}
                accordianType="mapping"
                accordianSchema={schema}
                location="mapping"
                accept="mapped"
                getMappingStyledOutput={getMappingStyledOutput}
                parents={parents}
                currentObject={currentObject}
                setCurrentObject={setCurrentObject}
              />
            );
          })}
          <div className={`${isOver ? 'diag' : ''} !h-[100%] !min-h-[80px]`} ref={drop}></div>
        </div>
        <div className="w-[30%] h-[100%] p-[12px] flex-1">
          <AccordianDiv className="sticky top-[12px] z-50" type="header">
            {newTargetName || 'Target Object'}
          </AccordianDiv>
          {tempTargetData.map((schema) => {
            const isPresentInMapping = !!mapSchema.find((element) => {
              return element.targetId == schema.targetId;
            });

            return (
              <GenerateAccordians
                inReview={inReview}
                isPresentInMapping={isPresentInMapping}
                currentObjectMapping={currentObjectMapping}
                setCurrentObjectMapping={setCurrentObjectMapping}
                setMapSchema={setMapSchema}
                mapSchema={mapSchema}
                sourceData={tempSourceData}
                targetData={tempTargetData}
                findParentWithGivenId={findParentWithGivenId}
                accordianType="source-target"
                accordianSchema={schema}
                location="target"
                accept="data"
                getMappingStyledOutput={getMappingStyledOutput}
                parents={parents}
                currentObject={currentObject}
                setCurrentObject={setCurrentObject}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NewFormAccordian;
