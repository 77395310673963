import request from 'superagent';
import { connectionGridDataUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';
import { useParams } from 'react-router-dom';

const fetchConnectionGridData = () => {
  const { app_code = '' } = useParams();

  return async () => {
    const res = await request
      .post(connectionGridDataUrl)
      .set('Authorization', `Bearer ${keycloak.token()}`)
      .send({ appCode: app_code });

    return res;
  };
};

export default fetchConnectionGridData;
