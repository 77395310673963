import navigationSchemaConstant from '../../constants/navigationSchemaConstant';

function onTopNavigationClick(componentData, navigate, app_code) {
  switch (componentData.value) {
    case navigationSchemaConstant.features: {
      navigate(`/apps/${app_code}/features`);
      break;
    }
    case navigationSchemaConstant.orchestrations: {
      navigate(`/apps/${app_code}/orchestration`);
      break;
    }
    case navigationSchemaConstant.objects: {
      navigate(`/apps/${app_code}/dataObject`);
      break;
    }
    case navigationSchemaConstant.objectMappings: {
      navigate(`/apps/${app_code}/objectMapping`);
      break;
    }
  }
}

function getApplicationTopNavigationSchema({
  features = false,
  objects = false,
  orchestrations = false,
  objectMappings = false
}) {
  return [
    {
      name: 'Features',
      value: navigationSchemaConstant.features,
      onClick: onTopNavigationClick,
      isSelected: features,
      isDisabled: false,
      count: 10,
      imageUrl: 'https://img.icons8.com/dotty/100/extra-features.png'
    },
    {
      name: 'Orchestrations',
      value: navigationSchemaConstant.orchestrations,
      onClick: onTopNavigationClick,
      isSelected: orchestrations,
      isDisabled: false,
      count: 20,
      imageUrl: 'https://i.ibb.co/kKyrC1z/orchestrate-1.png'
    },
    {
      name: 'Objects',
      value: navigationSchemaConstant.objects,
      onClick: onTopNavigationClick,
      isSelected: objects,
      isDisabled: false,
      count: 30,
      imageUrl: 'https://img.icons8.com/ios/100/group-objects.png'
    },
    {
      name: 'Object Mappings',
      value: navigationSchemaConstant.objectMappings,
      onClick: onTopNavigationClick,
      isSelected: objectMappings,
      isDisabled: false,
      count: 40,
      imageUrl: 'https://img.icons8.com/ios/100/broadcasting.png'
    }
  ];
}

export default getApplicationTopNavigationSchema;
