import ObjectApproved from '../../component/object/ObjectApproved';
import ObjectRejected from '../../component/object/ObjectRejected';
import ObjectReview from '../../component/object/ObjectReview';

function ObjectContainer({ currentReviewContext, setCurrentReviewState, reviewType }) {
  switch (currentReviewContext) {
    case 'review':
      return <ObjectReview setCurrentReviewState={setCurrentReviewState} reviewType={reviewType} />;
    case 'rejected':
      return <ObjectRejected reviewType={reviewType} />;
    case 'approved':
      return <ObjectApproved reviewType={reviewType} />;
  }
}

export default ObjectContainer;
