import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const DataObjectModalGrid = ({ data = [] }) => {
  const columnDefs = [
    { headerName: 'NAME', field: 'name' },
    { headerName: 'CODE', field: 'code' },
    { headerName: 'TYPE', field: 'type' },
    { headerName: 'ISARRAY', field: 'isArray' }
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  const gridOptions = {
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    domLayout: 'autoHeight'
  };
  let gridApi;

  async function onGridReady(params) {
    gridApi = params?.api;
  }

  const rowData = data?.map((keys) => {
    return {
      name: keys?.name,
      code: keys?.code,
      type: keys?.type,
      isArray: keys?.is_array == 0 ? 'NO' : 'YES'
    };
  });

  if (!data) {
    return null;
  }

  return (
    <div className="ag-theme-alpine w-[800px] m-auto flex justify-center items-center flex-col">
      <div className="w-[100%] bg-white mt-[20px] p-[10px] border-2 border-black rounded-lg">
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={5}
          rowData={rowData}
        />
      </div>
    </div>
  );
};

export default DataObjectModalGrid;
