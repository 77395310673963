import { useDispatch, useSelector } from 'react-redux';
import {
  clearFormState,
  clearKeys,
  emptyAll,
  updateFormError,
  updateFormState
} from '../../common/form/slices/formSlice';
import { useMutation, useQuery } from 'react-query';
import {
  getCommonObjects,
  getDataObjects,
  getFeatureList,
  getNetworks,
  getQueryTypes,
  getRequestMethods,
  getRequestObjects,
  getResponseObjects
} from '../../../services/newFeatureFormData';
import { useNavigate, useParams } from 'react-router-dom';
import FormV2Container from '../../common/formV2/container/FormV2Container';
import newFeatureFormSchema from '../../../schema/form_schema/newFeatureFormSchema';
import { useEffect, useState } from 'react';
import createNewFeature from '../../../services/createNewFeature';
import ApplicationTilesContainer from './ApplicationTiles';
import fetchFeatureDataById from '../../../services/fetchFeatureDataById';
import SideBar from '../../ApplicationV2/component/SideBar';
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';
import ConformationContainer from '../../common/conformationModal/containers';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import { isEmpty, isObject, noop } from 'lodash';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import GenericModalContainer from '../../modals/genericModal/container';
import ValidationRuleModal from '../component/ValidationRuleModal';
import callValidateForm from '../../../utils/validateIfFormSubmitable';
import TopCardSubheader from '../../../layouts/TopCardSubheader';
import getApplicationTopNavigationSchema from '../../../utils/navigationUtils/getApplicationTopNavigationSchema';
import fetchApplicationCount from '../../../services/fetchApplicationCount';
import formatFeatureCountData from '../../../utils/formatFeatureCountData';
import getExtensionSubheaderSchema from '../../../utils/navigationUtils/getExtensionSubheaderSchema';

const FeatureFormPreCheckContainer = (props) => {
  const { app_code = '', action, featureId } = useParams();
  const [toggleSuccess, setToggleSuccess] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFormDisable, setIsFormDisabled] = useState();
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showCancelBtn, setShowCancelBtn] = useState(false);

  const formStore = useSelector((store) => store?.store?.form);
  const formValidData = formStore?.formData;

  const [dataObjectKeyColumnsData, setDataObjectKeyColumnsData] = useState([]);
  const [hlfChannelsOptions, setHlfChannelsOptions] = useState([]);
  const [hlfChainCodeOptions, setHlfChainCodeOptions] = useState([]);
  const [advOptionFeature, setAdvOptionFeature] = useState('NORMAL');
  const [formData, setFormData] = useState({});

  const formStoreData = formStore.formData || {};

  const { isEdited } = formStoreData || {};

  const isFetchData = !!featureId;
  const keys = {
    newFeature: 'New Feature'
  };

  const { refetch, isSuccess: featureDataSuccss } = useQuery(
    'fetch-feature-data',
    fetchFeatureDataById({ featureId }),
    {
      enabled: false
    }
  );

  const mutation = useMutation(createNewFeature);

  const { isSuccess: featureListSuccess, data: featureListData } = useQuery({
    queryKey: ['featureList'],
    queryFn: getFeatureList({ appCode: app_code }),
    refetchOnWindowFocus: false
  });

  const { isSuccess: queryTypeSuccess, data: queryTypesData } = useQuery({
    queryKey: ['queryTypes'],
    queryFn: getQueryTypes,
    refetchOnWindowFocus: false
  });

  const { data: requestMethodsData } = useQuery({
    queryKey: ['requestMethods'],
    queryFn: getRequestMethods,
    refetchOnWindowFocus: false
  });

  const { data: requestObjectsData, isSuccess: requestObjectSuccess } = useQuery({
    queryKey: ['requestObjects'],
    queryFn: getRequestObjects({ appCode: app_code }),
    refetchOnWindowFocus: false
  });
  const { data: responseObjectsData } = useQuery({
    queryKey: ['responseObjects'],
    queryFn: getResponseObjects({ appCode: app_code }),
    refetchOnWindowFocus: false
  });

  const { isSuccess: dataObjectSuccess, data: dataObjectsData } = useQuery({
    queryKey: ['dataObjects'],
    queryFn: getDataObjects({ appCode: app_code }),
    refetchOnWindowFocus: false
  });
  const { data: networksListData, isSuccess: networkDataSuccess } = useQuery({
    queryKey: ['networkList'],
    queryFn: getNetworks,
    refetchOnWindowFocus: false
  });
  const { data: commonObjectsData, isSuccess: commonObjectSuccess } = useQuery({
    queryKey: ['commonObjects'],
    queryFn: getCommonObjects,
    refetchOnWindowFocus: false
  });

  const featureList = featureListData?.body?.dropDownObjects;
  const queryTypes = queryTypesData?.body?.primary;
  const dataObjects = dataObjectsData?.body?.dataObjects;
  const requestObjects = requestObjectsData?.body?.dropDownObjects;
  const responseObjects = responseObjectsData?.body?.dataObjects;
  const requestMethods = requestMethodsData?.body?.primary;
  const networkList = networksListData?.body?.hlfNetworkObjects;
  const commonObjectsList = commonObjectsData?.body?.dropDownObjects;

  const onSwitchChange = (event) => {
    const { value } = event.target;
    setIsFormDisabled(!value);
  };

  const onDataObjectSelect = (event) => {
    const { value } = event.target;
    const filteredData = dataObjects.find((obj) => obj.dataObjectId === value);
    const dataObjectKeysData = filteredData ? filteredData.keys : [];
    setDataObjectKeyColumnsData(dataObjectKeysData);
  };

  const getChannelsByName = (dataArray, nameToFind) => {
    if (dataArray) {
      const channelsArray = dataArray.reduce((acc, curr) => {
        if (curr.network === nameToFind && Array.isArray(curr.channels)) {
          return [...acc, ...curr.channels];
        }
        return acc;
      }, []);

      return channelsArray;
    }
    return [];
  };

  // function getChaincodesByNameKey(dataArray, nameKeyValueToFind) {
  //   const matchingData = dataArray.find((item) => item._nameKey === nameKeyValueToFind);
  //   return matchingData ? matchingData.chaincodes : [];
  // }

  const onHlfNetworkSelect = (event) => {
    const selectedValue = event.target.value;
    const hlfChannelData = getChannelsByName(networkList, selectedValue);
    setHlfChannelsOptions(hlfChannelData);
  };

  // const onHlfChannelsSelect = (event) => {
  //   const selectedValue = event.target.value;
  //   const hlfChainCodeData = getChaincodesByNameKey(hlfChannelsOptions, selectedValue);
  //   setHlfChainCodeOptions(hlfChainCodeData);
  // };

  const validationRuleClick = (index) => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.validationRule,
        isOpen: true,
        modalDataKey: 'paramIndex',
        modalData: index
      })
    );
  };
  function getKeysWithCommonPrefixes(obj, prefixes) {
    const keysWithPrefixes = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        for (const prefix of prefixes) {
          if (key.startsWith(prefix)) {
            keysWithPrefixes.push(key);
            break;
          }
        }
      }
    }

    return keysWithPrefixes;
  }

  const onPathValidate = async (value, store) => {
    const reqArray = getKeysWithCommonPrefixes(store, [
      'name-',
      'param_type-',
      'description-',
      'validationRule-',
      'type_id-',
      
    ]);
    dispatch(clearKeys(reqArray));
    const res = await request
      .post(`${process.env.REACT_APP_CONFIG_API}/features/path`)
      .send({ path: value })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    if (res.body.status.code == 'SUCCESS') {
      setTableData(res.body.primary);
      setShowTable(true);
    } else {
      const errorMessage = res.body.primary.error || 'No Params Found';
      toast.custom((t) => <ErrorNotify t={t} message={errorMessage} />, {
        duration: 4000
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (isFetchData) {
        const formData = await refetch({ enabled: true });
        setFormData(formData?.data?.body);
        setAdvOptionFeature(formData?.data?.body?.featureObjects?.[0]?.featureType);
        const paramsData = formData?.data?.body?.featureObjects?.[0]?.params;
        if (paramsData.length) {
          setShowTable(true);
          setTableData(paramsData);
        }
        // const hlfChainCodeData = getChaincodesByNameKey(
        //   hlfChannelsOptions,
        //   formData?.data?.body?.featureObjects?.[0]?.hlfNetworkName
        // );
        // setHlfChainCodeOptions(hlfChainCodeData);
        const hlfChannelData = getChannelsByName(
          networkList,
          formData?.data?.body?.featureObjects?.[0]?.hlfNetworkName
        );
        setHlfChannelsOptions(hlfChannelData);
      }
    })();
    if (action == 'edit') {
      setShowCancelBtn(true);
    }
  }, [window.location.href, dataObjectSuccess, featureDataSuccss, networkDataSuccess]);

  const advOptionsFormSelect = (event) => {
    const selectedValue = event.target.value;
    setAdvOptionFeature(selectedValue);
    const featureSchema = newFeatureFormSchema({
      featureList,
      queryTypes,
      dataObjects: dataObjectsData?.body?.dataObjects,
      requestObjects,
      responseObjects,
      requestMethods,
      networkList,
      hlfChannelsOptions,
      hlfChainCodeOptions,
      dataObjectKeyColumnsData,
      onSwitchChange,
      onDataObjectSelect,
      advOptionsFormSelect,
      onHlfNetworkSelect,
      // onHlfChannelsSelect,
      advOptionFeature,
      formData,
      action,
      onPathValidate,
      tableData,
      commonObjectsList,
      validationRuleClick
    });
    setCurrentSchema(featureSchema);
  };

  const computeSchema = () => {
    if (requestObjectSuccess) {
      return newFeatureFormSchema({
        featureList,
        queryTypes,
        dataObjects,
        requestObjects,
        responseObjects,
        requestMethods,
        networkList,
        hlfChannelsOptions,
        hlfChainCodeOptions,
        dataObjectKeyColumnsData,
        onSwitchChange,
        onDataObjectSelect,
        advOptionsFormSelect,
        onHlfNetworkSelect,
        // onHlfChannelsSelect,
        advOptionFeature,
        formData,
        action,
        onPathValidate,
        tableData,
        commonObjectsList,
        validationRuleClick
      });
    }
  };

  useEffect(() => {
    var schema;
    if (requestObjectSuccess && commonObjectSuccess) {
      schema = computeSchema();
      console.log(schema)
    }
    setCurrentSchema(schema);
  }, [
    dataObjectSuccess,
    queryTypeSuccess,
    dataObjects,
    requestObjectSuccess,
    dataObjectKeyColumnsData,
    featureListSuccess,
    advOptionFeature,
    hlfChannelsOptions,
    hlfChainCodeOptions,
    featureDataSuccss,
    commonObjectSuccess,
    JSON.stringify(tableData),
    JSON.stringify(formData)
  ]);

  const [currentSchema, setCurrentSchema] = useState(computeSchema());

  const addUnderscoreForSpaces = (inputString) => {
    return inputString?.replace(/\s+/g, '_');
  };

  const getDataBasedOnName = ({ matchingName, data = {} }) => {
    const keyNames = Object.keys(data).filter((element) => {
      return element.startsWith(matchingName);
    });
    const keyData = keyNames.map((element) => data[element]);

    return keyData;
  };

  const createPreCheckPayload = (inputData) => {
    if (inputData.length == 1) {
      if (inputData[0]?.['Feature List']) {
        return [
          {
            viewData: {
              featureCode: inputData[0]['Feature List'],
              expected: inputData[0]['expected expression'],
              errorMessage: inputData[0]['error message']
            },
            priority: Number(inputData[0]['priority']),
            status_enabled: inputData[0]['precheck switch'] || false
          }
        ];
      } else {
        return [];
      }
    }
    return inputData.map((item) => {
      return {
        viewData: {
          featureCode: item['Feature List'],
          expected: item['expected expression'],
          errorMessage: item['error message']
        },
        priority: Number(item['priority']),
        status_enabled: item['precheck switch'] || false
      };
    });
  };

  const getParamsData = (
    paramTypeData,
    paramCodeData,
    paramDescData,
    paramDataTypeData,
    filteredValidationRuleData
  ) => {
    var res = [];
    paramTypeData.map((ele, index) => {
      res.push({
        param_type: ele,
        name: paramCodeData[index],
        description: paramDescData[index],
        type_id: paramDataTypeData[index],
        validationRule: filteredValidationRuleData[index]
      });
    });
    return res;
  };

  function isObject(value) {
    return typeof value === 'object' && value !== null;
  }
  const filterValidationRule = (validationRuleData = []) => {
    return validationRuleData.map((ele) => {
      if (isObject(ele)) {
        const newObj = {};
        for (const data in ele) {
          if (isObject(ele?.[data])) {
            newObj[data] = ele?.[data]?.data;
          } else {
            newObj[data] = ele?.[data];
          }
        }
        return newObj;
      } else {
        return ele;
      }
    });
  };
  const reqValidationRuleDataFn = (paramDataTypeData, filteredValidationRuleData) => {
    var res = [];
    filteredValidationRuleData.map((ele, index) => {
      if (paramDataTypeData[index] == 'DOI000001') {
        res.push({
          multipleOf: ele?.multipleOf,
          maximum: ele?.maximum,
          exclusiveMaximum: ele?.exclusiveMaximum,
          minimum: ele?.minimum,
          exclusiveMinimum: ele?.exclusiveMinimum
        });
      } else if (paramDataTypeData[index] == 'DOI000002') {
        res.push({
          maxLength: ele?.maxLength,
          minLength: ele?.minLength,
          pattern: ele?.pattern
        });
      } else {
        res.push(ele);
      }
    });
    return res;
  };

  var onSubmitForm;

  switch (action) {
    case 'view': {
      onSubmitForm = () => {
        navigate(`/apps/${app_code}/features`);
      };
      break;
    }
    case 'edit': {
      onSubmitForm = async ({ title }) => {
        const payload = {};
        const formStoreData = formStore?.formData?.[title];
        const featureData = formStoreData?.['Definitions-0']?.['-0'];
        const advOptionsData = formStoreData?.['Advanced Options-0']?.['-0'];
        const sodViewData = formStoreData?.['Sod View-0'];
        payload.code = featureData?.['Unique Code'];
        payload.name = featureData?.Name;
        payload.accessType = featureData?.Access;
        payload.description = featureData?.Description;
        payload.dataObjectId = featureData?.['Data Object'];
        payload.responseObjectId = featureData?.['Response Object'];
        payload.requestObjectId = featureData?.['Request Object'];
        payload.queryTypeCode = featureData?.['Query Type'];
        payload.requestMethod = featureData?.['Request Method'];
        // payload.partOfQuery = sodViewData?.['part of query'];
        payload.customResponseObject = featureData?.['Custom Response Object'];
        payload.attributionKeys = featureData?.['Attribute Keys'];
        payload.staticParameters = featureData?.['Static Parameters'];
        payload.keyColumns = featureData?.['Data Object Key Columns'];
        payload.featureType = addUnderscoreForSpaces(advOptionsData?.['Feature Type']);
        payload.featureCondition = advOptionsData?.['Feature Condition'];
        payload.transformationFromRequestToDataObjectRequired =
          advOptionsData?.['Transformation: Request->Data Object Required'] == 'REQUIRED'
            ? true
            : false;
        payload.dbOperationRequired =
          advOptionsData?.['DataBase Operation Required'] == 'REQUIRED' ? true : false;
        payload.iterativeCountExpression = advOptionsData?.['Count Expression'];
        payload.iterativeFeatureCode = advOptionsData?.['Iterative Feature'];
        payload.externalMethod = advOptionsData?.['Method'];
        payload.externalHost = advOptionsData?.['Host'];
        payload.externalPort = advOptionsData?.['Port'];
        payload.externalPath = advOptionsData?.['Path'];
        payload.externalQuery = advOptionsData?.['Query'];
        payload.externalHeaders = advOptionsData?.['Header'];
        payload.externalPayload = advOptionsData?.['Payload'];
        payload.externalAuthCode = advOptionsData?.['Authorization Code'];
        payload.externalAuthType = advOptionsData?.['Authorization Type'];
        payload.externalProtocol = advOptionsData?.['Protocol'];
        payload.applicationCode = app_code;
        payload.hlfNetworkName = advOptionsData?.['Network'];
        payload.hlfChannelName = advOptionsData?.['Channel'];
        payload.hlfChaincodeName = advOptionsData?.['Chain Code'];
        payload.hlfChaincodeFunctionName = advOptionsData?.['Chain Code Function Name'];
        payload.hlfTransientArgs = advOptionsData?.['Transient Orgs'];
        payload.hlfNonTransientArgs = advOptionsData?.['Non Transient Orgs'];
        payload.trueFeatureCode = advOptionsData?.['Feature Condition True'];
        payload.falseFeatureCode = advOptionsData?.['Feature Condition False'];
        payload.path = featureData?.['Relative URL Path'];

        const preChecks = getDataBasedOnName({ matchingName: 'Pre Check-', data: formStoreData });
        const preChecksData = createPreCheckPayload(preChecks);
        const paramTypeData = getDataBasedOnName({
          matchingName: 'param_type-',
          data: formStore?.formData
        });
        const paramCodeData = getDataBasedOnName({
          matchingName: 'name-',
          data: formStore?.formData
        });
        const paramDescData = getDataBasedOnName({
          matchingName: 'description-',
          data: formStore?.formData
        });
        const paramDataTypeData = getDataBasedOnName({
          matchingName: 'type_id-',
          data: formStore?.formData
        });
        const validationRuleData = getDataBasedOnName({
          matchingName: 'validationRule-',
          data: formStore?.formData
        });
        const filteredValidationRuleData = filterValidationRule(validationRuleData);
        const reqValidationRuleData = reqValidationRuleDataFn(
          paramDataTypeData,
          filteredValidationRuleData
        );
        const paramsData = getParamsData(
          paramTypeData,
          paramCodeData,
          paramDescData,
          paramDataTypeData,
          reqValidationRuleData
        );

        payload.preChecks = preChecksData;
        payload.params = paramsData;

        const newFeatureFormKeys = Object.keys(formStoreData);

        try {
          const res = await request
            .put(`${process.env.REACT_APP_CONFIG_API}/features/${featureId}`)
            .send(payload)
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${keycloak.token()}`);

          if (res.body.status.code == 'SUCCESS') {
            dispatch(
              clearFormState({
                formKey: title
              })
            );
            toast.custom((t) => <Success t={t} message={res.body.status.message} />, {
              duration: 4000
            });
            dispatch(emptyAll());
            setToggleSuccess(!toggleSuccess);
            navigate(`/apps/${app_code}/features`);
          } else {
            newFeatureFormKeys.map((ele) => {
              dispatch(
                updateFormError({
                  formKey: keys.newFeature,
                  subFormKey: ele,
                  errorMessage: res.body.status.message
                })
              );
            });
            toast.custom((t) => <ErrorNotify t={t} message={res.body.status.message} />, {
              duration: 4000
            });
          }
        } catch (error) {
          console.log('Error:', error);
        }
      };
      break;
    }
    default: {
      onSubmitForm = async ({ title }) => {
        const payload = {};
        const formStoreData = formStore?.formData?.[title];
        const featureData = formStoreData?.['Definitions-0']?.['-0'];
        const advOptionsData = formStoreData?.['Advanced Options-0']?.['-0'];
        const sodViewData = formStoreData?.['Sod View-0'];
        payload.code = featureData?.['Unique Code'];
        payload.name = featureData?.Name;
        payload.accessType = featureData?.Access;
        payload.description = featureData?.Description;
        payload.dataObjectId = featureData?.['Data Object'];
        payload.responseObjectId = featureData?.['Response Object'];
        payload.requestObjectId = featureData?.['Request Object'];
        payload.queryTypeCode = featureData?.['Query Type'];
        payload.requestMethod = featureData?.['Request Method'];
        // payload.partOfQuery = sodViewData?.['part of query'];
        payload.customResponseObject = featureData?.['Custom Response Object'];
        payload.attributionKeys = featureData?.['Attribute Keys'];
        payload.staticParameters = featureData?.['Static Parameters'];
        payload.keyColumns = featureData?.['Data Object Key Columns'];
        payload.featureType = addUnderscoreForSpaces(advOptionsData?.['Feature Type']);
        payload.featureCondition = advOptionsData?.['Feature Condition'];
        payload.transformationFromRequestToDataObjectRequired =
          advOptionsData?.['Transformation: Request->Data Object Required'] == 'REQUIRED'
            ? true
            : false;
        payload.dbOperationRequired =
          advOptionsData?.['DataBase Operation Required'] == 'REQUIRED' ? true : false;
        payload.iterativeCountExpression = advOptionsData?.['Count Expression'];
        payload.iterativeFeatureCode = advOptionsData?.['Iterative Feature'];
        payload.externalMethod = advOptionsData?.['Method'];
        payload.externalHost = advOptionsData?.['Host'];
        payload.externalPort = advOptionsData?.['Port'];
        payload.externalPath = advOptionsData?.['Path'];
        payload.externalQuery = advOptionsData?.['Query'];
        payload.externalHeaders = advOptionsData?.['Header'];
        payload.externalPayload = advOptionsData?.['Payload'];
        payload.externalAuthCode = advOptionsData?.['Authorization Code'];
        payload.externalAuthType = advOptionsData?.['Authorization Type'];
        payload.externalProtocol = advOptionsData?.['Protocol'];
        payload.applicationCode = app_code;
        payload.hlfNetworkName = advOptionsData?.['Network'];
        payload.hlfChannelName = advOptionsData?.['Channel'];
        payload.hlfChaincodeName = advOptionsData?.['Chain Code'];
        payload.hlfChaincodeFunctionName = advOptionsData?.['Chain Code Function Name'];
        payload.hlfTransientArgs = advOptionsData?.['Transient Orgs'];
        payload.hlfNonTransientArgs = advOptionsData?.['Non Transient Orgs'];
        payload.trueFeatureCode = advOptionsData?.['Feature Condition True'];
        payload.falseFeatureCode = advOptionsData?.['Feature Condition False'];
        payload.path = featureData?.['Relative URL Path'];

        const preChecks = getDataBasedOnName({ matchingName: 'Pre Check-', data: formStoreData });
        const preChecksData = createPreCheckPayload(preChecks);
        const paramTypeData = getDataBasedOnName({
          matchingName: 'param_type-',
          data: formStore?.formData
        });
        const paramCodeData = getDataBasedOnName({
          matchingName: 'name-',
          data: formStore?.formData
        });
        const paramDescData = getDataBasedOnName({
          matchingName: 'description-',
          data: formStore?.formData
        });
        const paramDataTypeData = getDataBasedOnName({
          matchingName: 'type_id-',
          data: formStore?.formData
        });
        const validationRuleData = getDataBasedOnName({
          matchingName: 'validationRule-',
          data: formStore?.formData
        });
        const filteredValidationRuleData = filterValidationRule(validationRuleData);
        const reqValidationRuleData = reqValidationRuleDataFn(
          paramDataTypeData,
          filteredValidationRuleData
        );
        const paramsData = getParamsData(
          paramTypeData,
          paramCodeData,
          paramDescData,
          paramDataTypeData,
          reqValidationRuleData
        );

        payload.preChecks = preChecksData;
        payload.params = paramsData;

        const newFeatureFormKeys = Object.keys(formStoreData);

        const preCheckLeaveKey = isEmpty(preChecksData);

        // const canBeFormSubmitable = callValidateForm({
        //   formData: formValidData,
        //   formKeys: ['New Feature'],
        //   leaveCurrent: {
        //     ...(preCheckLeaveKey
        //       ? {
        //           'expected expression': true,
        //           'error message': true,
        //           priority: true,
        //           'Feature List': true,
        //           'precheck switch': true
        //         }
        //       : {}),
        //     Path: true,
        //     'params table': true
        //   }
        // });

        if (true) {
          try {
            const response = await mutation.mutateAsync(payload);
            if (response.body.status.code == 'SUCCESS') {
              dispatch(
                clearFormState({
                  formKey: title
                })
              );
              toast.custom((t) => <Success t={t} message={response.body.status.message} />, {
                duration: 4000
              });
              dispatch(emptyAll());
              setToggleSuccess(!toggleSuccess);
              navigate(`/apps/${app_code}/features`);
            } else {
              newFeatureFormKeys.map((ele) => {
                dispatch(
                  updateFormError({
                    formKey: keys.newFeature,
                    subFormKey: ele,
                    errorMessage: response.body.status.message
                  })
                );
              });
              toast.custom(
                (t) => (
                  <ErrorNotify
                    t={t}
                    message={response.body.status.message || 'Feature Creation Failed'}
                  />
                ),
                {
                  duration: 4000
                }
              );
            }
          } catch (error) {
            console.log('Error:', error);
          }
        }
      };
    }
  }

  const onMenuItemClick = () => {
    if (isEdited) {
      dispatch(
        genericModalStateUpdate({
          modalKey: ModalKeys.stopNavigationFeature,
          isOpen: true
        })
      );
    } else {
      navigate(`/apps/${app_code}/features`);
    }
  };

  const onCancleBtnClick = () => {
    if (isEdited) {
      dispatch(
        genericModalStateUpdate({
          modalKey: ModalKeys.stopNavigationFeature,
          isOpen: true
        })
      );
    } else {
      navigate(`/apps/${app_code}/features`);
    }
  };

  const onValidationRuleModalClose = () => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.validationRule,
        isOpen: false
      })
    );
  };

  switch (action) {
    case 'view': {
      const onMenuItemClick = () => {
        navigate(`/apps/${app_code}/features`);
      };

      return (
        <div className="flex">
          <SideBar
            page={'features'}
            onMenuItemClick={onMenuItemClick}
            currentContext={{ features: '' }}
          />
          <div className="flex-1 flex flex-col">
            <TopCardSubheader
              topCardNavigationSchema={getApplicationTopNavigationSchema({ features: true })}
              getCountService={fetchApplicationCount(app_code)}
              formatCountData={formatFeatureCountData}
              subheaderNavigationSchema={getExtensionSubheaderSchema({ application: true })}>
              <div className="!bg-[#F4F3FA] pb-[40px]">
                {/* {isEdited ? (
                  <ApplicationTilesContainer askNavigationCheck={true} />
                ) : (
                  <ApplicationTilesContainer />
                )} */}
              </div>
              {currentSchema ? (
                <div className="m-auto mx-2">
                  <FormV2Container
                    variant="formV3"
                    formData={formData}
                    schema={currentSchema}
                    onSubmitForm={onSubmitForm}
                    className={'bg-white'}
                    submitButtonName={'Close'}
                    showTable={showTable}></FormV2Container>
                </div>
              ) : (
                <div className="m-auto">
                  <CircularProgress />
                </div>
              )}
            </TopCardSubheader>
          </div>
          <GenericModalContainer modalKey={ModalKeys.validationRule}>
            <ValidationRuleModal onValidationRuleModalClose={onValidationRuleModalClose} />
          </GenericModalContainer>
        </div>
      );
    }
    default: {
      return (
        <div className="flex">
          <SideBar
            page={'features'}
            onMenuItemClick={onMenuItemClick}
            currentContext={{ features: 'New Feature' }}
          />
          <div className="flex-1 flex flex-col">
            <TopCardSubheader
              topCardNavigationSchema={getApplicationTopNavigationSchema({ features: true })}
              getCountService={fetchApplicationCount(app_code)}
              formatCountData={formatFeatureCountData}
              subheaderNavigationSchema={getExtensionSubheaderSchema({ application: true })}>
              <div className="!bg-[#F4F3FA] pb-[40px]">
                {/* {isEdited ? (
                  <ApplicationTilesContainer askNavigationCheck={true} />
                ) : (
                  <ApplicationTilesContainer />
                )} */}
              </div>
              {currentSchema ? (
                <div className="m-auto mx-2">
                  <FormV2Container
                    variant="formV3"
                    formData={formData}
                    schema={currentSchema}
                    onSubmitForm={onSubmitForm}
                    className={'bg-white'}
                    submitButtonName={'Submit'}
                    showTable={showTable}
                    onCancleBtnClick={onCancleBtnClick}
                    addCancelButton={showCancelBtn}></FormV2Container>
                </div>
              ) : (
                <div className="m-auto">
                  <CircularProgress />
                </div>
              )}
            </TopCardSubheader>
          </div>
          <ConformationContainer
            type="discard"
            modalId={ModalKeys.stopNavigationFeature}
            cancelObject={{ text: 'Cancel', callback: noop }}
            conformObject={{
              text: 'Discard Changes',
              callback: () => navigate(`/apps/${app_code}/features`)
            }}
            modalText={{
              header: 'Discard Changes',
              desc: 'Your Data is Unsaved, do you really want to discard changes !'
            }}
          />
          <GenericModalContainer modalKey={ModalKeys.validationRule}>
            <ValidationRuleModal onValidationRuleModalClose={onValidationRuleModalClose} />
          </GenericModalContainer>
        </div>
      );
    }
  }
};

export default FeatureFormPreCheckContainer;
