import request from 'superagent';
import {
  fetchTargetObjectDataUrl
} from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const fetchNewFormData = () => {
  return async () => {
    const targetObjectType = await request
      .get(fetchTargetObjectDataUrl)
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return {
      targetObjectType: targetObjectType.body.primary
    };
  };
};

export default fetchNewFormData;
