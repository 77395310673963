import React, { useState } from 'react';
import ApprovedGrid from './ApprovedGrid';
import keycloak from '../../../../Keycloak';
import { useParams } from 'react-router-dom';
import request from 'superagent';

const ObjectApproved = ({ reviewType }) => {
  const { app_code } = useParams();
  const [approvedData, setApprovedData] = useState([]);

  const fetchApprovedData = async function () {
    let requestUrl = '';

    switch (reviewType) {
      case 'review': {
        requestUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects/status`;
        break;
      }
      case 'reviewObjectMapping': {
        requestUrl = `${process.env.REACT_APP_CONFIG_API}/object-mapping/status`;
        break;
      }
    }

    const response = await request
      .get(requestUrl)
      .query({ appCode: app_code, flag: 'Approved' })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    if (reviewType == 'reviewObjectMapping') {
      setApprovedData(response.body.objectMappingReviewResponseItem);
    } else {
      setApprovedData(response.body.dataObjects);
    }
  };
  return (
    <div className="h-[100%] flex flex-col">
      <ApprovedGrid reviewType={reviewType} approvedData={approvedData} fetchApprovedData={fetchApprovedData} />
    </div>
  );
};

export default ObjectApproved;
