import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import AuditLogTransformationGrid from '../components/AuditLogTransformationGrid';
import { fetchTransformationAuditLogData } from '../../../services/auditLogData';
import { setTransformationAuditLogGridData } from '../slices/AuditLogSlice';
import { noop } from 'lodash';
import { useParams } from 'react-router-dom';

const TransformationAuditLogGridContainer = ({
  onDigitIdClick = noop,
  onSourceObjectClick = noop,
  onTransformedObjectClick = noop
}) => {
  const dispatch = useDispatch();
  const { app_code = '' } = useParams();
  const storeTransformationTimeFilterData =
    useSelector((store) => store?.store?.auditLog?.transformationTimeFilter) || {};
  const {
    startDate = '',
    startTime = '',
    endDate = '',
    endTime
  } = storeTransformationTimeFilterData;
  function combineDateAndTime(dateInput = '', timeInput = '') {
    const combinedDateTime = `${dateInput}T${timeInput}:00.000Z`;
    if (dateInput && timeInput) {
      return new Date(combinedDateTime).toISOString();
    }
    return '';
  }
  const starting = combineDateAndTime(startDate, startTime);
  const ending = combineDateAndTime(endDate, endTime);
  const { refetch } = useQuery(
    'object-mapping-audit-data',
    fetchTransformationAuditLogData({
      type: 'transaction',
      app_code: app_code,
      startDate: starting,
      endDate: ending
    }),
    {
      enabled: false,
      refetchOnWindowFocus: true
    }
  );
  const storeTransformationAuditData = useSelector(
    (store) => store?.store?.auditLog?.transformationGridData
  );

  const fetchTransformationAuditData = async () => {
    const refetchData = await refetch();
    const { data, isSuccess } = refetchData;
    if (isSuccess) {
      dispatch(setTransformationAuditLogGridData({ auditData: data?.body?.data?.auditData }));
    }
    return data?.body?.featureObjects;
  };

  return (
    <AuditLogTransformationGrid
      fetchTransformationAuditData={fetchTransformationAuditData}
      transformationAuditData={storeTransformationAuditData}
      onDigitIdClick={onDigitIdClick}
      onSourceObjectClick={onSourceObjectClick}
      onTransformedObjectClick={onTransformedObjectClick}
    />
  );
};

export default TransformationAuditLogGridContainer;
