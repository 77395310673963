import request from 'superagent';
import {
  getQueryTypesUrl,
  getRequestMethodsUrl,
  getDataObjectsUrl,
  getRequestObjectsUrl,
  getResponseObjectsUrl,
  getFeatureListUrl,
  getNetworksUrl,
  commonObjectsUrl
} from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const getQueryTypes = async () => {
  const res = await request
    .get(getQueryTypesUrl)
    .set('Authorization', `Bearer ${keycloak.token()}`);

  return res;
};

const getRequestMethods = async () => {
  const res = await request
    .get(getRequestMethodsUrl)
    .set('Authorization', `Bearer ${keycloak.token()}`);

  return res;
};

const getDataObjects = ({ appCode = '' }) => {
  return async () => {
    const res = await request
      .get(getDataObjectsUrl)
      .query({ type: 'DATA_OBJECT', appCode: appCode })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

const getRequestObjects = ({ appCode = '' }) => {
  return async () => {
    const res = await request
      .get(getRequestObjectsUrl)
      .query({ type: 'REQUEST_OBJECT', appCode: appCode })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

const getResponseObjects = ({ appCode = '' }) => {
  return async () => {
    const res = await request
      .get(getResponseObjectsUrl)
      .query({ type: 'RESPONSE_OBJECT', appCode: appCode })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

const getFeatureList = ({ appCode = '' }) => {
  return async () => {
    const res = await request
      .get(getFeatureListUrl)
      .query({ appCode: appCode })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

const getNetworks = async () => {
  const res = await request.get(getNetworksUrl).set('Authorization', `Bearer ${keycloak.token()}`);

  return res;
};

const getCommonObjects = async () => {
  const res = await request
    .get(commonObjectsUrl)
    .set('Authorization', `Bearer ${keycloak.token()}`);

  return res;
};

export {
  getQueryTypes,
  getRequestMethods,
  getDataObjects,
  getRequestObjects,
  getResponseObjects,
  getFeatureList,
  getNetworks,
  getCommonObjects
};
