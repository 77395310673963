import { Button, CircularProgress, TextField } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import request from 'superagent';
import keycloak from '../../../../Keycloak';
import EditObjectMappingForm from '../../../Application/component/EditObjectMappingForm';
import NewObjectFormContainer from '../../../Application/container/NewObjectFormContainer';
import {
  setCurrentHLFId,
  setCurrentHLFNetwork
} from '../../../HyperledgerFabricNetworks/slices/hlfSlice';
import Tabs from '../../../Tabs/components/Tabs';
import ConformationContainer from '../../../common/conformationModal/containers';
import ModalKeys from '../../../modals/genericModal/constants/GenericModalKeys';
import GenericModalContainer from '../../../modals/genericModal/container';
import { genericModalStateUpdate } from '../../../modals/slices/genericModalSlice';
import ErrorNotify from '../../../notify/ErrorNotify';
import Success from '../../../notify/Success';
import ReviewErrorModal from './ReviewErrorModal';

const ReviewGrid = ({
  fetchReviewData,
  title = '',
  setCurrentReviewState,
  reviewType,
  reviewData
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentClickedData, setCurrentClickedData] = useState({});
  const [searchText, setSearchText] = useState();

  const [objectData, setObjectData] = useState({});
  const { app_code } = useParams();

  useEffect(() => {
    fetchReviewData();
  }, [reviewType]);

  let columnDefs;

  if (reviewType == 'reviewObjectMapping') {
    columnDefs = [
      { headerName: 'source code', field: 'sourceCode' },
      { headerName: 'target code', field: 'targetCode' },
      { headerName: 'Created By', field: 'createdBy' },
      { headerName: 'Created On', field: 'createdOn' },
      { headerName: 'Version', field: 'version' },
      {
        headerName: 'Review',
        field: 'review',
        cellRenderer: (data) => {
          return (
            <div
              className="text-blue-400"
              onClick={() => {
                setObjectData(data.data);
                dispatch(
                  genericModalStateUpdate({
                    modalKey: ModalKeys.reviewModal,
                    isOpen: true
                  })
                );
              }}>
              Review
            </div>
          );
        }
      }
    ];
  } else {
    columnDefs = [
      { headerName: 'Name', field: 'name' },
      { headerName: 'Type', field: 'type' },
      { headerName: 'Created By', field: 'createdBy' },
      { headerName: 'Created On', field: 'createdOn' },
      { headerName: 'Version', field: 'version' },
      {
        headerName: 'Review',
        field: 'review',
        cellRenderer: (data) => {
          return (
            <div
              className="text-blue-400"
              onClick={() => {
                setObjectData(data.data);
                dispatch(
                  genericModalStateUpdate({
                    modalKey: ModalKeys.reviewModal,
                    isOpen: true
                  })
                );
              }}>
              Review
            </div>
          );
        }
      }
    ];
  }

  // {
  //   headerName: 'Actions',
  //   field: 'Actions',
  //   cellRenderer: (data) => (
  //     <MenuContainer
  //       menuData={[
  //         {
  //           name: 'Approve',
  //           callback: () => cellClicked(data, 'approve'),
  //           icon: <ViewAgenda fontSize="small" />
  //         },
  //         {
  //           name: 'Reject',
  //           callback: () => cellClicked(data, 'reject'),
  //           icon: <Delete fontSize="small" />
  //         }
  //       ]}
  //     />
  //   )
  // }

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function onShowLoading() {
    gridOptions.api.showLoadingOverlay();
  }

  function onHide() {
    gridOptions.api.hideOverlay();
  }

  let gridApi;

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };

  let rowData;

  if (reviewType == 'reviewObjectMapping') {
    rowData = reviewData?.map((element) => {
      return {
        sourceCode: element.sourceCode,
        targetCode: element.targetCode,
        sourceId: element.sourceId,
        targetId: element.targetId,
        appCode: element.appCode,
        createdOn: element.createdOn,
        createdBy: element.createdBy || '',
        version: element.version,
        id: element.id
      };
    });
  } else {
    rowData = reviewData?.map((element) => {
      return {
        name: element.code,
        type: element.type,
        createdOn: element.createdAt,
        createdBy: element.createdBy || '',
        version: element.version,
        dataObjectId: element.dataObjectId
      };
    });
  }

  async function onGridReady(params) {
    gridApi = params?.api;
    params?.api?.showLoadingOverlay();
    const fetchData = await fetchReviewData();
  }

  const onExportClick = () => {
    gridApi = params?.api;
    gridApi?.exportDataAsCsv();
  };

  const onDeleteClick = async (data) => {
    const deleteResult = await request
      .put(`${process.env.REACT_APP_CONFIG_API}/hlf/delete`)
      .query({
        id: data.id
      })
      .send({});
    await fetchHLFData();
    if (deleteResult?.body?.status?.code == 'SUCCESS') {
      toast.custom((t) => <Success t={t} message={deleteResult?.body?.status?.message} />, {
        duration: 4000
      });
    } else {
      toast.custom((t) => <ErrorNotify t={t} message={deleteResult?.body?.status?.message} />, {
        duration: 4000
      });
      console.log('Data Object Deletion failed');
    }
  };

  async function cellClicked(param, field) {
    const { data } = param;

    switch (field) {
      case 'reject': {
        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.searchBy,
            isOpen: true
          })
        );
        break;
      }

      case 'approve': {
        setCurrentReviewState('approved');
        break;
      }

      case 'copy': {
        navigate(`/hlf/copy/${data.networkName}`);
        break;
      }
      case 'edit': {
        navigate(`/hlf/edit/${data.id}`);
        break;
      }
      case 'delete': {
        setCurrentClickedData(data);
        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.deleteHlfModal,
            isOpen: true
          })
        );
        break;
      }
      case 'view': {
        dispatch(
          setCurrentHLFId({
            id: data.id
          })
        );
        dispatch(
          setCurrentHLFNetwork({
            network: data.network
          })
        );
        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.viewHlf,
            isOpen: true
          })
        );
      }
    }
  }

  const onClickApprove = async () => {
    if (reviewType == 'reviewObjectMapping') {
      const response = await request
        .put(`${process.env.REACT_APP_CONFIG_API}/object-mapping/review`)
        .query({
          appCode: app_code,
          id: objectData.id,
          flag: 'Approve',
          sourceCode: objectData.sourceCode,
          targetCode: objectData.targetCode
        })
        .set('Authorization', `Bearer ${keycloak.token()}`);

      setCurrentReviewState('approved');
      dispatch(
        genericModalStateUpdate({
          modalKey: ModalKeys.reviewModal,
          isOpen: false
        })
      );
    } else {
      const response = await request
        .post(`${process.env.REACT_APP_CONFIG_API}/data-objects/review`)
        .query({
          appCode: app_code,
          code: objectData.name,
          id: objectData.dataObjectId,
          flag: 'Approved'
        })
        .set('Authorization', `Bearer ${keycloak.token()}`);

      if (response?.body?.status == 201) {
        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.reviewErrorModal,
            isOpen: true,
            modalDataKey: 'reviewErrorData',
            modalData: response?.body
          })
        );
      } else {
        setCurrentReviewState('approved');
        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.reviewModal,
            isOpen: false
          })
        );
      }
    }
  };

  const onCloseErrorModalClick = () => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.reviewErrorModal,
        isOpen: false
      })
    );
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.reviewModal,
        isOpen: false
      })
    );
  };

  async function rejected() {
    if (reviewType == 'reviewObjectMapping') {
      const response = await request
        .put(`${process.env.REACT_APP_CONFIG_API}/object-mapping/review`)
        .query({
          appCode: app_code,
          id: objectData.id,
          flag: 'Reject',
          sourceCode: objectData.sourceCode,
          targetCode: objectData.targetCode,
          reason: searchText
        })
        .set('Authorization', `Bearer ${keycloak.token()}`);
    } else {
      const response = await request
        .post(`${process.env.REACT_APP_CONFIG_API}/data-objects/review`)
        .query({
          appCode: app_code,
          code: objectData.name,
          id: objectData.dataObjectId,
          flag: 'Reject',
          reason: searchText
        })
        .set('Authorization', `Bearer ${keycloak.token()}`);
    }
  }

  const onCLickReject = async () => {
    if (reviewType == 'reviewObjectMapping') {
      dispatch(
        genericModalStateUpdate({
          modalKey: ModalKeys.searchBy,
          isOpen: true
        })
      );
    } else {
      dispatch(
        genericModalStateUpdate({
          modalKey: ModalKeys.searchBy,
          isOpen: true
        })
      );
    }
  };

  const customComponent = (
    <div>
      <TextField value={searchText} onChange={(e) => setSearchText(e.target.value)} />
    </div>
  );

  return (
    <div className="ag-theme-alpine flex-1 px-[24px] my-[50px] p-[20px] mr-[20px]  border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      <div className="text-[24px]">{title}</div>
      <div className="flex-1">
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={20}
          rowData={rowData}
          suppressCellFocus={true}
        />
      </div>
      <GenericModalContainer modalKey={ModalKeys.viewHlf}>
        <Tabs />
      </GenericModalContainer>
      <ConformationContainer
        formData={currentClickedData}
        doNotEmptyState={true}
        modalId={ModalKeys.deleteHlfModal}
        conformObject={{
          text: 'Delete Connection',
          callback: onDeleteClick
        }}
        cancelObject={{ text: 'Cancel', callback: noop }}
        modalText={{
          header: `Delete Connection`,
          desc: 'Do you really want to Remove!'
        }}
      />
      <ConformationContainer
        type="submit"
        formData={{}}
        modalId={ModalKeys.searchBy}
        cancelObject={{ text: 'Cancel', callback: noop }}
        conformObject={{
          text: 'Reject',
          callback: async () => {
            dispatch(
              genericModalStateUpdate({
                modalKey: ModalKeys.reviewModal,
                isOpen: false
              })
            );
            dispatch(
              genericModalStateUpdate({
                modalKey: ModalKeys.reviewModal,
                isOpen: false
              })
            );
            await rejected();
            setCurrentReviewState('rejected');
          }
        }}
        customComponent={customComponent}
        modalText={{
          header: `Reason`,
          desc: `Please share the reason for rejecting this request`
        }}
      />
      <GenericModalContainer modalKey={ModalKeys.reviewModal}>
        <div className="h-[80vh] bg-white w-[90vw] m-auto mt-[100px] border-none overflow-auto outline-none flex flex-col justify-between">
          <div>
            <div className="h-[50px] bg-blue-600 sticky top-0 z-[1000] flex justify-between">
              <div className="text-white text-[24px] ml-2 pt-2">
                Review Objects {reviewType == 'reviewObjectMapping' ? 'Mapping' : ''} - Testing
              </div>
            </div>
            {reviewType == 'reviewObjectMapping' ? (
              <EditObjectMappingForm inReview={true} reviewObject={objectData} />
            ) : (
              <NewObjectFormContainer
                viewAction="view"
                subAction="review"
                dataObjectId={objectData.dataObjectId}
              />
            )}
          </div>
          <div className="w-[100%] bg-white sticky bottom-0 justify-end flex gap-4 items-center pr-4 py-4">
            <div className="p-2 rounded-md cursor-pointer">
              <Button variant="contained" onClick={onClickApprove} color="success">
                Approve
              </Button>
            </div>
            <div className="p-2 rounded-md cursor-pointer">
              <Button variant="contained" onClick={onCLickReject} color="error">
                Reject
              </Button>
            </div>
          </div>
        </div>
      </GenericModalContainer>
      <GenericModalContainer modalKey={ModalKeys.reviewErrorModal}>
        <ReviewErrorModal modalKey={ModalKeys.reviewErrorModal} onCloseErrorModalClick = {onCloseErrorModalClick} />
      </GenericModalContainer>
    </div>
  );
};

export default ReviewGrid;
