import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const ApplicationCard = ({
  application,
  onMoreInfoClick,
  onDeleteClick,
  onEditClick,
  onUpdateClick,
  onAddUserClick
}) => {
  const { name, baseApplication, code, description, id } = application;
  return (
    <div className=" w-[100%] tablet:w-[35%] tablet:min-w-[470px] my-4 border-2 border-grey-400 tablet:mr-2 flex h-[168px] tablet:mb-4 rounded-2xl shadow-lg tablet:ml-8">
      <div className="flex w-[100%]">
        <div className="bg-white rounded-l-2xl w-[25%] border-white flex justify-center items-center p-4">
          <img
            src="https://media.tenor.com/372D-222UN8AAAAC/art-line-art.gif"
            className="h-auto w-auto"
          />
        </div>
        <div className="flex bg-[rgb(221,237,248)] grow rounded-r-2xl justify-between">
          <div className="flex flex-col grow">
            <div className="flex grow">
              <div className="w-[100%] tablet:w-[45%] flex flex-col justify-around ml-4">
                <div className="font-bold text-blue-400 whitespace-nowrap">{name}</div>
                <div className="font-bold">{description}</div>
                <div className="underline">
                  <Button onClick={onMoreInfoClick} variant="text">
                    More Info
                    <KeyboardDoubleArrowRightIcon />
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <div className="bg-blue-500 rounded-tl-2xl pl-4 pr-2">
                <Tooltip title="Edit Application">
                  <IconButton>
                    <EditIcon
                      className="text-white"
                      onClick={() => onEditClick(code, baseApplication, name, description, id)}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Application">
                  <IconButton>
                    <DeleteIcon onClick={() => onDeleteClick(id)} className="text-white" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Application Analytics">
                  <IconButton>
                    <AnalyticsIcon className="text-white" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cache Application">
                  <IconButton>
                    <CachedIcon onClick={() => onUpdateClick(code)} className="text-white" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Application Users">
                  <IconButton>
                    <PersonIcon onClick={() => onAddUserClick()} className="text-white" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Export Application">
                  <IconButton>
                    <FileUploadIcon className="text-white" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy Application">
                  <IconButton>
                    <CopyAllIcon className="text-white" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationCard;
