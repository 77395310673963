import React, { useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { noop } from 'lodash';
import LinkIcon from '@mui/icons-material/Link';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import MenuContainer from '../../common/menu/containers/MenuContainer';
import { toast } from 'react-hot-toast';
import Success from '../../notify/Success';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from 'react';
import keycloak from '../../../Keycloak';
import request from 'superagent';
import { useSelector } from 'react-redux';

const ChannelsGrid = ({
  fetchFeatureData = noop,
  deleteFeatureClick = noop,
  copyFeatureUrl = noop
}) => {
  const hlfId = useSelector((store) => store.store?.hlf?.id) || '';
  const [featureData, setFeatureData] = useState([]);
  const gridRef = useRef();
  const { app_code = '' } = useParams();
  const navigate = useNavigate();
  const columnDefs = [
    { headerName: 'Channels Name', field: 'channelsName' },
    { headerName: 'Organizations Name', field: 'organizationsName' }
  ];

  const defaultColDef = {
    sortable: true,
    flex: 1,
    floatingFilter: true,
    filter: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function onShowLoading() {
    gridOptions.api.showLoadingOverlay();
  }

  function onHide() {
    gridOptions.api.hideOverlay();
  }

  let gridApi;

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };

  const rowData = featureData.map((feature) => {
    return {
      channelsName: feature.name,
      organizationsName: feature.orgNames
    };
  });

  // const autoSizeAll = useCallback((skipHeader) => {
  //   const allColumnIds = [];
  //   gridRef.current.columnApi.getColumns().forEach((column) => {
  //     allColumnIds.push(column.getId());
  //   });
  //   gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  // }, []);

  async function onGridReady(params) {
    gridApi = params?.api;
    // params?.api?.showLoadingOverlay();
    gridApi?.sizeColumnsToFit();
    const fetchData = await request
      .get(`${process.env.REACT_APP_CONFIG_API}/hlf/id`)
      .query({
        id: hlfId
      })
      .set('Authorization', `Bearer ${keycloak.token()}`);
    setFeatureData(fetchData.body?.hlfNetworkObjects[0]?.channels);
    // autoSizeAll(false);
  }

  const onExportClick = () => {
    gridApi = params?.api;
    gridApi?.exportDataAsCsv();
  };

  function cellClicked(param, field) {
    const { data } = param;

    switch (field) {
      case 'copyFeature': {
        navigate(`/apps/${app_code}/features/copy/${data.id}`);
        break;
      }
      case 'edit': {
        navigate(`/apps/${app_code}/features/edit/${data.id}`);
        break;
      }
      case 'delete': {
        deleteFeatureClick({ id: data.id, name: data.name });
        break;
      }
      case 'copyUrl': {
        copyFeatureUrl(data.uniqueCode, app_code);
        break;
      }
      case 'viewFeature': {
        navigate(`/apps/${app_code}/features/view/${data.id}`);
        break;
      }
    }
  }

  return (
    <div className="ag-theme-alpine px-[24px] mt-[50px] p-[20px] m-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col h-[400px]">
      <div className="text-[24px]">{'Channels'}</div>
      <div className="flex-1">
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          onCellClicked={cellClicked}
          rowData={rowData}
        />
      </div>
    </div>
  );
};

export default ChannelsGrid;
