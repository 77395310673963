import { Handle, Position } from 'reactflow';
import React from 'react';
import { range } from 'lodash';

const RightNode = ({ data }) => {
  const pointStart = 50 - parseInt((data.handles * 10) / 2);
  return (
    <div
      id={data.id}
      style={{
        backgroundColor: data.bg,
        borderTopRightRadius: '9999px',
        borderBottomRightRadius: '9999px'
      }}
      location={data.location}
      name={data.label}
      className="font-bold px-2 overflow-hidden min-w-[200px] min-h-[60px] flex items-center justify-center mb-2 bg-[#E4E4E4]">
      <div name={data.label} id={data.id} location={data.location} className="custom-node">
        <div
          className="overflow-hidden whitespace-nowrap text-ellipsis"
          name={data.label}
          id={data.id}
          location={data.location}>
          {data.label}
        </div>
      </div>
      {range(data.handles).map((element) => {
        return (
          <Handle
            type="target"
            position={Position.Left}
            onConnect={(params) => console.log('handle onConnect', params)}
            isConnectable={true}
            id={`${element + 1}`}
            style={{ top: `${pointStart + element * 10}%` }}
          />
        );
      })}
    </div>
  );
};

export default RightNode;
