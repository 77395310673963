import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from '@mui/material/Button';
import { noop } from 'lodash';
import { useHistory, useNavigate } from 'react-router-dom';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import LinkIcon from '@mui/icons-material/Link';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { CircularProgress } from '@mui/material';
import MenuContainer from '../../common/menu/containers/MenuContainer';
import { Edit, ContentCopy, ContentPaste, Delete } from '@mui/icons-material';
import request from 'superagent';

const TraceGrid = ({
  gridTrigger = false,
  onParentTraceGridClick = noop,
  fetchTraceData = noop,
  traceData = [],
  title = 'Trace Grid'
}) => {
  const navigate = useNavigate();
  const CustomColorRenderer = ({ value }) => <span style={{ color: 'blue' }}>{value}</span>;

  const columnDefs = [
    {
      headerName: 'Parent Trace Id',
      field: 'parent_trace_id',
      cellRenderer: CustomColorRenderer
    },
    { headerName: 'Feature Trace Name', field: 'feature_trace_name' },
    { headerName: 'Request IP', field: 'request_ip' },
    { headerName: 'Feature StartTime', field: 'createdAt' }
  ];

  const handleCellClick = async (data) => {
    const field = data.colDef?.field;

    if (field == 'parent_trace_id') {
      const { parent_trace_id } = data?.data;
      const response = onParentTraceGridClick(parent_trace_id);
    }
  };

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function onShowLoading() {
    gridOptions.api.showLoadingOverlay();
  }

  function onHide() {
    gridOptions.api.hideOverlay();
  }

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };

  const rowData = traceData.map((feature, index) => {
    return {
      parentTraceId: feature.parent_trace_id,
      featureTraceName: feature.feature_trace_name,
      requestIp: feature.request_ip,
      featureStartTime: feature.createdAt
    };
  });

  // async function onGridReady(params) {
  //   gridApi = params?.api;
  //   params?.api?.showLoadingOverlay();
  //   const fetchData = await fetchTraceData();
  // }
  const [gridApi, setGridApi] = useState(null);
  const perPage = 10;

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: async (params) => {
          // Use startRow and endRow for sending pagination to Backend
          // params.startRow : Start Page
          // params.endRow : End Page

          const page = params.endRow / perPage;
          const response = await fetchTraceData({
            pageNo: page
          });
          params.successCallback(response?.body?.response, response?.body?.totalRecords);
        }
      };

      gridApi?.sizeColumnsToFit();
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, gridTrigger]);

  return (
    <div className="ag-theme-alpine flex-1 px-[24px] mt-[50px] p-[20px] m-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      <div className="text-[24px]">{title}</div>
      <div className="flex-1">
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          rowData={rowData}
          suppressCellFocus={true}
          onCellClicked={handleCellClick}
          domLayout="autoHeight"
          rowModelType={'infinite'}
          cacheBlockSize={10}
        />
      </div>
    </div>
  );
};

export default TraceGrid;
