import React, { useState } from 'react';
import FieldsComponentsV3 from './FieldsComponentsV3';
import FieldContainerV3 from '../container/FieldContainerV3';

const GetFormComponentsV3 = ({
  fieldData,
  restrictRerender,
  form,
  path,
  isFormDisabled = '',
  disableFullForm = {},
  showTable
}) => {
  const { type, name, showThisForm = true } = form;
  switch (type) {
    case 'Fields': {
      return showThisForm ? (
        <FieldsComponentsV3
          restrictRerender={restrictRerender}
          // isFormDisabled={isFormDisabled}
          form={form}
          path={path}
          disableFullForm={disableFullForm}
          showTable={showTable}
        />
      ) : null;
    }
    default: {
      return (
        <FieldContainerV3
          fieldData={fieldData}
          restrictRerender={restrictRerender}
          isFormDisabled={isFormDisabled}
          form={form}
          path={[...path, name]}
          disableFullForm={disableFullForm}
          showTable={showTable}
        />
      );
    }
  }
};

export default GetFormComponentsV3;
