import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import DeleteFeatureModal from '../../Application/component/DeleteFeatureModal';
import deleteApplication from '../../../services/deleteApplication';
import { updateApplicationState } from '../slices/applicationSlice';
import { toast } from 'react-hot-toast';
import Success from '../../notify/Success';

const DeleteAppModalContainer = ({ onCloseModal = noop }) => {
  const mutation = useMutation(deleteApplication);

  const dispatch = useDispatch();

  const [showModalLoader, setShowModalLoader] = useState();
  const modalStore = useSelector((store) => store.store.genericModal);
  const modalData = modalStore?.modalKeys?.deleteAppModalData;

  const onDeleteAppClick = async () => {
    dispatch(
      updateApplicationState({
        applicationState: 'pending'
      })
    );
    const deleteResult = await mutation.mutateAsync({ id: modalData.id });
    setShowModalLoader(true);

    if (deleteResult?.body?.status?.code == 'SUCCESS') {
      setShowModalLoader(false);
      onCloseModal();
      dispatch(
        updateApplicationState({
          applicationState: 'success'
        })
      );
      toast.custom((t) => <Success t={t} message={'Application Deleted SuccessFully'} />, {
        duration: 4000
      });
    } else {
      dispatch(
        updateApplicationState({
          applicationState: 'failure'
        })
      );
    }
  };

  return (
    <DeleteFeatureModal
      showModalLoader={showModalLoader}
      onCloseModal={onCloseModal}
      onDeleteClick={onDeleteAppClick}
      modalHeader="Delete Application"
      modalData={modalData}
    />
  );
};

export default DeleteAppModalContainer;
