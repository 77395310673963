import { isEmpty } from 'lodash';

function testIsFormReadyForOrganizationNext(data) {
  const result = Object.entries(data).reduce((acc, [key, value]) => {
    if (key.startsWith('organizations-')) {
      let peer = Object.keys(data[key]).reduce((acc, value) => {
        if (value.startsWith('peers-')) {
          return {
            ...acc,
            [value]: Object.values(data[key][value])[0]
          };
        } else {
          return acc;
        }
      }, {});

      if (isEmpty(peer)) {
        peer = {
          peer: ''
        };
      }

      return {
        ...acc,
        [key]: {
          organizationName: data[key].organizationName,
          ...peer
        }
      };
    } else {
      return acc;
    }
  }, {});

  return Object.keys(result).every((key) => {
    return Object.keys(result[key]).every((secondKey) => {
      return result[key][secondKey];
    });
  });
}

export default testIsFormReadyForOrganizationNext;
