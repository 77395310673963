import React from 'react';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import TraceAccordian from './traceAccordian.component';

const AccordianGraph = (data) => {
  const dispatch = useDispatch();

  const {
    data: { responseData = [], selectedData: featureData = {} }
  } = data;
  return (
    <div className="m-auto bg-white h-[90%] mt-4 p-4">
      <div className="flex justify-between">
        <div className="text-[24px]">Feature Name - {featureData?.service_name}</div>
        <div
          className="w-auto float-right cursor-pointer relative"
          onClick={() =>
            dispatch(
              genericModalStateUpdate({
                modalKey: ModalKeys.featureModal,
                isOpen: false
              })
            )
          }>
          <CloseIcon />
        </div>
      </div>
      <div>
        <TraceAccordian responseData={responseData} />
      </div>
    </div>
  );
};

export default AccordianGraph;
