import React, { useEffect, useState } from 'react';
import ApplicationCard from '../component/ApplicationCard';
import { useNavigate } from 'react-router-dom';
import deleteApplication from '../../../services/deleteApplication';
import updateApplication from '../../../services/updateApplication';
import { useDispatch } from 'react-redux';
import { updateApplicationState, updateEditFormData } from '../slices/applicationSlice';
import { useMutation } from 'react-query';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import GenericModalContainer from '../../modals/genericModal/container';
import EditApplicationFormContainer from './EditApplicationFormContainer';
import UserFormContainer from './UserFormContainer';
import DeleteOrchestrationModalContainer from './DeleteAppModalContainer';
import DeleteAppModalContainer from './DeleteAppModalContainer';

const ApplicationCardContainer = ({ application }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mutation = useMutation(deleteApplication);
  const updateAppMutation = useMutation(updateApplication);

  const onMoreInfoClick = () => {
    const { code = '' } = application;
    navigate(`/apps/${code}`);
  };

  const onDeleteClick = async (applicationId) => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.deleteAppModal,
        isOpen: true,
        modalDataKey: 'deleteAppModalData',
        modalData: applicationId
      })
    );
  };

  const onEditClick = (code, baseApplication, name, description, id) => {
    dispatch(
      updateEditFormData({
        formData: { code, baseApplication, id, name, description }
      })
    );
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.editModal,
        isOpen: true
      })
    );
  };

  const onAddUserClick = () => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.addUserModal,
        isOpen: true
      })
    );
  };

  const onUpdateClick = async (code) => {
    dispatch(
      updateApplicationState({
        applicationState: 'pending'
      })
    );
    const updateApplicationResult = await updateAppMutation.mutateAsync(code);

    if (updateApplicationResult?.body?.status?.code == 'SUCCESS') {
      dispatch(
        updateApplicationState({
          applicationState: 'success'
        })
      );
    } else {
      dispatch(
        updateApplicationState({
          applicationState: 'failure'
        })
      );
    }
  };

  return (
    <>
      <ApplicationCard
        application={application}
        onMoreInfoClick={onMoreInfoClick}
        onDeleteClick={onDeleteClick}
        onUpdateClick={onUpdateClick}
        onEditClick={onEditClick}
        onAddUserClick={onAddUserClick}
      />
      <GenericModalContainer modalKey={ModalKeys.editModal}>
        <EditApplicationFormContainer modalKey={ModalKeys.editModal} isModal={true} />
      </GenericModalContainer>
      <GenericModalContainer modalKey={ModalKeys.addUserModal}>
        <UserFormContainer modalKey={ModalKeys.addUserModal} isModal={true} />
      </GenericModalContainer>
      <GenericModalContainer modalKey={ModalKeys.deleteAppModal}>
        <DeleteAppModalContainer />
      </GenericModalContainer>
    </>
  );
};

export default ApplicationCardContainer;
