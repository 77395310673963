import { useState } from 'react';
import { noop } from 'lodash';
import React, { useEffect } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const BlockHashModal = ({ onCloseModal = noop, blockHashData = [] }) => {
  console.log(onCloseModal, '676767676767676');

  const [copiedIcons, setCopiedIcons] = useState({
    dataHash: false,
    blockHash: false,
    previousHash: false
  });

  const {
    channelName = '',
    blockNumber = 0,
    numberOfTransactions = 0,
    dataHash = '',
    blockHash = '',
    previousHash = ''
  } = blockHashData[0];

  const copyToClipboard = (text, field) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopiedIcons({ ...copiedIcons, [field]: true });
          setTimeout(() => {
            setCopiedIcons({ ...copiedIcons, [field]: false });
          }, 2000);
        })
        .catch((err) => {
          console.error('Error copying to clipboard: ', err);
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);

      setCopiedIcons({ ...copiedIcons, [field]: true });
      setTimeout(() => {
        setCopiedIcons({ ...copiedIcons, [field]: false });
      }, 2000);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout();
    };
  }, []);

  return (
    <div class="fixed inset-0 flex items-center justify-center z-50 overflow-x-hidden overflow-y-auto">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute opacity-50"></div>
      </div>
      <div class="relative z-50 bg-white rounded-lg overflow-hidden shadow-xl">
        <div class="flex justify-between py-4 pl-8 pr-4 bg-blue-100">
          <h1 className="text-xl font-bold text-blue-600">Block Hash</h1>
          <button
            onClick={onCloseModal}
            class="text-gray-600 hover:text-gray-800 focus:outline-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="p-8">
          <div className="flex mb-4 w-full">
            <div className="w-[25%]">
              <h1 className="text-lg font-bold text-blue-600">Channel Name</h1>
            </div>
            <div className="">
              <h1 className="text-lg break-all">{channelName}</h1>
            </div>
          </div>
          <div className="flex w-full mb-4">
            <div className="w-[25%]">
              <h1 className="text-lg font-bold text-blue-600">Block Number</h1>
            </div>
            <div className="">
              <h1 className="text-lg break-all">{blockNumber}</h1>
            </div>
          </div>
          <div className="flex w-full mb-4">
            <div className="w-[25%]">
              <h1 className="text-lg font-bold text-blue-600">No. of Txn</h1>
            </div>
            <div className="">
              <h1 className="text-lg break-all">{numberOfTransactions}</h1>
            </div>
          </div>
          <div className="flex w-full mb-4">
            <div className="w-[25%]">
              <h1 className="text-lg font-bold text-blue-600">Block Hash</h1>
            </div>
            <div className="">
              <h1 className="text-lg break-all">
                {blockHash}
                <div className="float-right ml-4">
                  <button
                    onClick={() => copyToClipboard(blockHash, 'blockHash')}
                    disabled={copiedIcons.blockHash}>
                    {copiedIcons.blockHash ? 'Copied' : <ContentCopyIcon />}
                  </button>
                </div>
              </h1>
            </div>
          </div>
          <div className="flex w-full mb-4">
            <div className="w-[25%]">
              <h1 className="text-lg font-bold text-blue-600">Data Hash</h1>
            </div>
            <div className="">
              <h1 className="text-lg break-all">
                {dataHash}
                <div className="float-right ml-4">
                  <button
                    onClick={() => copyToClipboard(dataHash, 'dataHash')}
                    disabled={copiedIcons.dataHash}>
                    {copiedIcons.dataHash ? 'Copied' : <ContentCopyIcon />}
                  </button>
                </div>
              </h1>
            </div>
          </div>
          <div className="flex w-full mb-4">
            <div className="w-[25%] items-center">
              <h1 className="text-lg font-bold text-blue-600">Previous Hash</h1>
            </div>
            <div className="items-center">
              <h1 className="text-lg break-all">
                {previousHash}{' '}
                <div className="float-right ml-4">
                  <button
                    onClick={() => copyToClipboard(previousHash, 'previousHash')}
                    disabled={copiedIcons.previousHash}>
                    {copiedIcons.previousHash ? 'Copied' : <ContentCopyIcon />}
                  </button>
                </div>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockHashModal;
