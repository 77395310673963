import request from 'superagent';
import { featureDataUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const fetchFeatureData = ({ appCode = '' }) => {
  return async () => {
    const res = await request
      .get(featureDataUrl)
      .query({ appCode: appCode })
      .set('Authorization', `Bearer ${keycloak.token()}`);
    return res;
  };
};

export default fetchFeatureData;
