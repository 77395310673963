import request from 'superagent';
import {
  editObjectUrl,
  fetchGroupsUrl,
  getDataObjectTypesUrl,
  getDataTypesUrl
} from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const getDataObjectTypes = () => {
  return async () => {
    const res = await request
      .get(getDataObjectTypesUrl)
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

const getDataTypes = ({ app_code = '', type = '' }) => {
  return async () => {
    const res = await request
      .get(getDataTypesUrl)
      .query({ appCode: app_code, type: type })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

const editObject = ({ dataObjectId = '' }) => {
  return async () => {
    const res = await request
      .get(editObjectUrl)
      .query({ id: dataObjectId })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

const fetchGroupsApi = () => {
  return async () => {
    const res = await request
      .get(fetchGroupsUrl)
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

//
export { getDataObjectTypes, getDataTypes, editObject, fetchGroupsApi };
