import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import { noop } from 'lodash';
import ObjectModal from '../components/ObjectModal';
import toast from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';

const ObjectModalContainer = ({ onCloseModal = noop }) => {
  const [objectMappingData, setObjectMappingData] = useState(null);
  const modalStore = useSelector((store) => store?.store?.genericModal?.modalKeys);
  const modalData = modalStore?.dataObjectModalData;
  const { auditId = '' } = modalData;
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await request
          .get(`${process.env.REACT_APP_CONFIG_API}/auditLog/transDataObjRecord`)
          .query({ auditId: auditId, type: 'creation' })
          .set('Authorization', `Bearer ${keycloak.token()}`);
        if (res?.body?.status?.code == 'SUCCESS') {
          toast.custom((t) => <Success t={t} message={res?.body?.status?.message} />, {
            duration: 4000
          });
          setObjectMappingData(res?.body?.record);
        } else {
          toast.custom((t) => <ErrorNotify t={t} message={res?.body?.status?.message} />, {
            duration: 4000
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [auditId]);

  return <ObjectModal onCloseModal={onCloseModal} objectMappingData={objectMappingData} />;
};

export default ObjectModalContainer;
