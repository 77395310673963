import navigationSchemaConstant from '../../constants/navigationSchemaConstant';

function getReviewTopNavigationSchema(
  { review = false, approved = false, rejected = false } = {},
  setCurrentReviewState
) {
  function onTopNavigationClick(componentData) {
    switch (componentData.value) {
      case navigationSchemaConstant.review: {
        setCurrentReviewState(componentData.value);
        break;
      }
      case navigationSchemaConstant.rejected: {
        setCurrentReviewState(componentData.value);
        break;
      }
      case navigationSchemaConstant.approved: {
        setCurrentReviewState(componentData.value);
        break;
      }
    }
  }

  return [
    {
      name: 'Review',
      value: navigationSchemaConstant.review,
      onClick: onTopNavigationClick,
      isSelected: review,
      isDisabled: false,
      count: 10,
      imageUrl: 'https://img.icons8.com/ios/100/mark-as-favorite.png'
    },
    {
      name: 'Rejected',
      value: navigationSchemaConstant.rejected,
      onClick: onTopNavigationClick,
      isSelected: rejected,
      isDisabled: false,
      count: 20,
      imageUrl: 'https://img.icons8.com/external-bearicons-detailed-outline-bearicons/64/external-rejected-approved-and-rejected-bearicons-detailed-outline-bearicons-11.png'
    },
    {
      name: 'Approved',
      value: navigationSchemaConstant.approved,
      onClick: onTopNavigationClick,
      isSelected: approved,
      isDisabled: false,
      count: 30,
      imageUrl: 'https://img.icons8.com/ios/100/good-quality--v1.png'
    }
  ];
}

export default getReviewTopNavigationSchema;
