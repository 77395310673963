import React, { useEffect, useState } from 'react';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import { AgGridReact } from 'ag-grid-react';
import { FileDownload } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useDispatch } from 'react-redux';

const GridCriteria = ({ trigger }) => {
  const [criteraData, setCriteraData] = useState(null);
  const dispatch = useDispatch();
  const columnDefs = [
    { headerName: 'Criteria Name', field: 'criteriaName' },
    { headerName: 'Criteria Field Name', field: 'criteriaFieldName' }
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };
  let gridApi;

  useEffect(() => {
    const fetchData = request
      .post(`${process.env.REACT_APP_GENRIC_API}/generic/feature/criteria/fetchAll`)
      .send({})
      .set('Authorization', `Bearer ${keycloak.token()}`)
      .then((data) => {
        setCriteraData(data.body.response);
      });
  }, [trigger]);

  async function onGridReady(params) {
    gridApi = params?.api;
    params?.api?.showLoadingOverlay();
    gridApi?.sizeColumnsToFit();
    const fetchData = request
      .post(`${process.env.REACT_APP_GENRIC_API}/generic/feature/criteria/fetchAll`)
      .send({})
      .set('Authorization', `Bearer ${keycloak.token()}`)
      .then((data) => {
        setCriteraData(data.body.response);
      });
  }

  const rowData = criteraData?.map((feature) => {
    return {
      criteriaName: feature.criteria_name,
      criteriaFieldName: feature.criteria_fieldname
    };
  });

  return (
    <div className="ag-theme-alpine flex-1 px-[24px] mt-[50px] p-[20px] m-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      <div className="text-[24px]">{'Criteria Grid'}</div>
      <div className="flex-1">
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          rowData={rowData}
        />
      </div>
    </div>
  );
};

export default GridCriteria;
