import { Delete, Edit } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import activeIcon from '../../../public/icons/active.png';
import inActiveIcon from '../../../public/icons/inActive.png';
import MenuContainer from '../../common/menu/containers/MenuContainer';
import ErrorNotify from '../../notify/ErrorNotify';
import Success from '../../notify/Success';
import { ViewAgenda } from '@material-ui/icons';

const ConnectionFrameworkGrid = ({
  fetchConnectionFrameworkData,
  connectionFrameworkData,
  title
}) => {
  const navigate = useNavigate();
  const { app_code = '' } = useParams();

  const columnDefs = [
    { headerName: 'Connector ID', field: 'ConnectorId' },
    { headerName: 'Name', field: 'Name' },
    { headerName: 'Description', field: 'Description' },
    { headerName: 'Type', field: 'Type' },
    { headerName: 'Party ID', field: 'PartyId' },
    {
      headerName: 'Status',
      field: 'Status',
      cellRenderer: (data) => {
        return (
          <div className="flex" onClick={() => cellClicked(data, 'status')}>
            {data?.data?.Status == 'ACTIVE' ? (
              <img src={activeIcon} alt="" srcset="" />
            ) : (
              <img src={inActiveIcon} alt="" srcset="" />
            )}
            <div className="ml-2 font-semibold">{data?.data?.Status}</div>
          </div>
        );
      }
    },
    {
      headerName: 'Actions',
      field: 'Actions',
      cellRenderer: (data) => (
        <MenuContainer
          menuData={[
            {
              name: 'Delete',
              callback: () => cellClicked(data, 'delete'),
              icon: <Delete fontSize="small" />
            },
            {
              name: 'View',
              callback: () => cellClicked(data, 'edit'),
              icon: <ViewAgenda fontSize="small" />
            }
          ]}
        />
      )
    }
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function onShowLoading() {
    gridOptions.api.showLoadingOverlay();
  }

  function onHide() {
    gridOptions.api.hideOverlay();
  }

  let gridApi;

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };

  const rowData = connectionFrameworkData.map((feature, index) => {
    return {
      ConnectorId: feature.ConnectorId,
      Description: feature.Description,
      Name: feature.Name,
      PartyId: feature.PartyId,
      Status: feature.Status,
      Type: feature.Type
    };
  });

  async function onGridReady(params) {
    gridApi = params?.api;
    params?.api?.showLoadingOverlay();
    const fetchData = await fetchConnectionFrameworkData();
  }

  const onExportClick = () => {
    gridApi = params?.api;
    gridApi?.exportDataAsCsv();
  };

  async function cellClicked(param, field) {
    const { data } = param;
    console.log(data);
    switch (field) {
      case 'edit': {
        navigate(`/apps/${app_code}/connections/edit/${data.ConnectorId}`);
        break;
      }

      case 'status': {
        const statusResult = await request
          .post(`${process.env.REACT_APP_CONFIG_API}/type/connectors/connections/statusUpdate`)
          .send({ connectorId: data.ConnectorId })
          .set('Authorization', `Bearer ${keycloak.token()}`);
        await fetchConnectionFrameworkData();

        if (statusResult?.body?.Status == 'Success') {
          toast.custom((t) => <Success t={t} message={statusResult?.body?.Message} />, {
            duration: 4000
          });
        } else {
          toast.custom((t) => <ErrorNotify t={t} message={statusResult?.body?.Message} />, {
            duration: 4000
          });
        }
        break;
      }
      case 'delete': {
        const deleteResult = await request
          .post(`${process.env.REACT_APP_CONFIG_API}/type/connectors/connections/delete`)
          .send({ connectorId: data.ConnectorId })
          .set('Authorization', `Bearer ${keycloak.token()}`);
        await fetchConnectionFrameworkData();
        if (deleteResult?.body?.Status == 'Success') {
          toast.custom(
            (t) => (
              <Success
                t={t}
                message={deleteResult?.body?.status?.message || 'Connection Deleted Successfully'}
              />
            ),
            {
              duration: 4000
            }
          );
        } else {
          toast.custom(
            (t) => (
              <ErrorNotify
                t={t}
                message={deleteResult?.body?.status?.message || 'Error While Deleting Connection'}
              />
            ),
            {
              duration: 4000
            }
          );
        }
        break;
      }
    }
  }

  return (
    <div className="ag-theme-alpine flex-1 px-[24px] p-[20px] mx-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col h-[100%]">
      <div className="text-[24px]">{title}</div>
      <div className="flex-1">
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          rowData={rowData}
          suppressCellFocus={true}
        />
      </div>
    </div>
  );
};

export default ConnectionFrameworkGrid;
