const getCodes = ({ arr, id }) => {
  if (arr.length == 0) {
    return '';
  }
  for (let i = 0; i < arr.length; i++) {
    const element = arr[i];
    if (element.dataObjectKeyId == id) {
      return element.code;
    }

    const res = getCodes({ arr: element.keys || [], id: id });

    if (res) {
      return res;
    }
  }
  return '';
};

function getSubmitPayload(data, newAccordianData, isEdit) {

  if (!data.length) return [];

  return data.map((element) => {
    const sourceCode = isEdit
      ? element.sourceName
      : getCodes({ arr: newAccordianData?.sources?.value, id: element.sourceId });
    const targetCode = isEdit
      ? element.targetName
      : getCodes({ arr: newAccordianData?.targets?.value, id: element.targetId });

    return {
      sourceObjectId: element.sourceId,
      targetObjectId: element.targetId,
      targetObjectCode: targetCode,
      sourceObjectCode: sourceCode,
      id: null,
      attributeCodes: getSubmitPayload(element.children || [], newAccordianData, isEdit)
    };
  });
}

export default getSubmitPayload;
