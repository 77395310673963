import React from 'react';
import cancel from '../../public/icons/cancel.png';

const ErrorNotify = ({ message, t }) => {
  return (
    <div className="w-[300px] bg-white shadow-lg flex absolute right-[20px] rounded-sm justify-start items-center p-2">
      <div className="w-[60px] flex justify-center">
        <img src={cancel} alt="" srcset="" />
      </div>
      <div className="ml-[12px] max-w-[240px] overflow-hidden text-ellipsis whitespace-nowrap hover:overflow-scroll">
        <div className="p-[12px]">
          <div className="font-semibold">
            {message}
          </div>
        </div>
        {/* <div className="absolute top-[0px] right-[-80px]" onClick={() => toast.dismiss(t.id)}>
          <Cancel />
        </div> */}
      </div>
    </div>
  );
};

export default ErrorNotify;
