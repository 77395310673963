const user_data = JSON.parse(localStorage.getItem('RW_AUTH_DATA'));

function login() {
  if (!user_data) {
    window.location.href = '/';
  }
}

function logout() {
  localStorage.removeItem('RW_AUTH_DATA');
  window.location.href = '/';
}

function getIfAuthenticated() {
  const user_data = JSON.parse(localStorage.getItem('RW_AUTH_DATA'));
  return !!user_data?.access_token;
}

function token() {
  const user_data = JSON.parse(localStorage.getItem('RW_AUTH_DATA'));
  return user_data?.access_token;
}

function getUserData() {
  return JSON.parse(localStorage.getItem('RW_AUTH_DATA'));
}

const keycloak = {
  token,
  authenticated: getIfAuthenticated(),
  getIfAuthenticated,
  login,
  logout,
  userData: getUserData(),
  getUserData
};

export default keycloak;
