import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Popover,
  Checkbox,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  Chip,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import keycloak from '../../Keycloak';
import SideBar from '../ApplicationV2/component/SideBar';
import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

const removeDuplicateArrays = (arr) => {
  return arr.filter((item, index) => {
    return index === arr.findIndex((other) => JSON.stringify(item) === JSON.stringify(other));
  });
};

export default function ExcelMapping() {
  const { app_code } = useParams();
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [dataObject, setDataObject] = useState('');
  console.log("dataaaaaaaaaaaa",dataObject)
  const [variableData, setVariableData] = useState([]);
  const [response, setResponse] = useState({
    primary: {
      columnHeaders: [],
      dataObjectColumns: [],
      mappingSuggestion: []
    },
    status: { code: '', message: '' }
  });
  const [columnMappings, setColumnMappings] = useState({});
  const [mandatoryFields, setMandatoryFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [viewMappingEnabled, setViewMappingEnabled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentHeader, setCurrentHeader] = useState(null);
  const [uniqueKeys, setUniqueKeys] = useState({});
  const [colorMap, setColorMap] = useState({});
  const [groupCounter, setGroupCounter] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [movingHeader, setMovingHeader] = useState(null);
  const [movingToHeader, setMovingToHeader] = useState(null);

  const [bulkProcessingName, setBulkProcessingName] = useState('');
  const [bulkProcessingCode, setBulkProcessingCode] = useState('');
  const [description, setDescription] = useState('');

  const colors = ['#FFC107', '#8BC34A', '#03A9F4', '#E91E63', '#9C27B0'];

  useEffect(() => {
    const fetchVariableGridData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
          {
            params: { appCode: app_code },
            headers: { Authorization: `Bearer ${keycloak.token()}` }
          }
        );
        const filteredData = response.data.dataObjects.filter(
          (obj) => obj.objectType === 'DATA_OBJECT'
        );
        setVariableData(filteredData);
        console.log('FilteredDATATATAT',filteredData)
        console.log('variableeredDATATATAT',variableData)
      } catch (err) {
        setError('Failed to fetch variable data: ' + err.message);
        console.error('Error fetching variable data:', err);
      }
    };
    fetchVariableGridData();
  }, [app_code]);

  useEffect(() => {
    if (response.primary.mappingSuggestion) {
      const initialMappings = {};
      response.primary.mappingSuggestion.forEach((sug) => {
        initialMappings[sug.excelColumn] = sug.dbColumn || '';
      });
      setColumnMappings(initialMappings);
    }
  }, [response.primary.mappingSuggestion]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setError('');
  };

  const handleMappingChange = (excelColumn, value) => {
    setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
  };

  const handleMandatoryFieldChange = (header, newValue) => {
    setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
  };

  const handleSubmit = async () => {
    if (!file) {
      setError('Please select a file to upload.');
      return;
    }

    setLoading(true);
    setViewMappingEnabled(false);

    const formData = new FormData();
    console.log('dataObjecttttttttttt',dataObject)
    formData.append('file', file);
    formData.append('objectId', dataObject.dataObjectId);
    formData.append('appCode', app_code);
    formData.append('excelValidation', '');
    // formData.append('token', keycloak.token());

    try {
      const response = await axios.post(`${process.env.REACT_APP_CONFIG_API}/bulkProcess/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${keycloak.token()}`
        }
      });
      setResponse(response.data);
      setViewMappingEnabled(true);
      console.log('File uploaded successfully', response.data);
    } catch (err) {
      setError(`Failed to upload file: ${err.message}`);
      console.error('Error uploading file:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDataObject = (e) => {
    setDataObject(e.target.value);
  };

  const handleClick = (event, header) => {
    setAnchorEl(event.currentTarget);
    setCurrentHeader(header);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentHeader(null);
  };

  const handleUniqueKeyChange = (header, selectedHeaders) => {
    const newUniqueKeys = { ...uniqueKeys };
    const newColorMap = { ...colorMap };
    let newGroup = false;
    let groupColor = colors[groupCounter % colors.length];

    selectedHeaders.forEach((selectedHeader) => {
      if (uniqueKeys[header]?.includes(selectedHeader)) return;

      Object.keys(uniqueKeys).forEach((key) => {
        if (key !== header && uniqueKeys[key].includes(selectedHeader)) {
          setMovingHeader(selectedHeader);
          setMovingToHeader(header);
          setOpenDialog(true);
        }
      });

      if (!newColorMap[selectedHeader]) {
        newGroup = true;
      }
    });

    if (newGroup) {
      groupColor = colors[groupCounter % colors.length];
      setGroupCounter((prev) => prev + 1);
    } else {
      groupColor = newColorMap[selectedHeaders[0]];
    }

    selectedHeaders.forEach((selectedHeader) => {
      newColorMap[selectedHeader] = groupColor;
    });

    selectedHeaders.forEach((selectedHeader) => {
      newUniqueKeys[selectedHeader] = selectedHeaders;
    });

    setUniqueKeys(newUniqueKeys);
    setColorMap(newColorMap);
  };

  const handleRemoveUniqueKey = (headerToRemove) => {
    const updatedUniqueKeys = { ...uniqueKeys };
    const updatedColorMap = { ...colorMap };

    // Remove the header from its unique group
    Object.keys(uniqueKeys).forEach((key) => {
      if (uniqueKeys[key].includes(headerToRemove)) {
        updatedUniqueKeys[key] = uniqueKeys[key].filter((item) => item !== headerToRemove);
        if (updatedUniqueKeys[key].length === 0) {
          delete updatedUniqueKeys[key];
        }
      }
    });

    // Remove the header from the color map
    delete updatedColorMap[headerToRemove];

    setUniqueKeys(updatedUniqueKeys);
    setColorMap(updatedColorMap);
  };

  const saveUniqueKeys = () => {
    handleClose();
  };

  const isNextButtonEnabled = () => {
    const mappings = Object.values(columnMappings);
    const hasEmptyMapping = mappings.includes('');
    const hasDuplicateMapping = new Set(mappings).size !== mappings.length;

    return !hasEmptyMapping && !hasDuplicateMapping;
  };

  const handleDialogClose = (confirm) => {
    if (confirm) {
      const updatedUniqueKeys = { ...uniqueKeys };
      const updatedColorMap = { ...colorMap };

      Object.keys(uniqueKeys).forEach((key) => {
        if (uniqueKeys[key].includes(movingHeader)) {
          updatedUniqueKeys[key] = uniqueKeys[key].filter((item) => item !== movingHeader);
        }
      });

      const groupColor = updatedColorMap[movingHeader];

      if (!groupColor) {
        const newGroupColor = colors[groupCounter % colors.length];
        updatedColorMap[movingHeader] = newGroupColor;
        setGroupCounter((prev) => prev + 1);
      }

      const newGroupHeaders = [...(updatedUniqueKeys[movingToHeader] || []), movingHeader];
      newGroupHeaders.forEach((header) => {
        updatedUniqueKeys[header] = newGroupHeaders;
        updatedColorMap[header] = updatedColorMap[movingToHeader] || groupColor;
      });

      setUniqueKeys(updatedUniqueKeys);
      setColorMap(updatedColorMap);
    }

    setMovingHeader(null);
    setMovingToHeader(null);
    setOpenDialog(false);
  };

  const renderUniqueGroups = () => {
    const groups = Object.values(uniqueKeys).reduce((acc, headers) => {
      const groupKey = headers.join(',');
      acc[groupKey] = headers;
      return acc;
    }, {});

    return Object.values(groups).map((group, index) => (
      <Box key={index} mb={2}>
        {group.map((header) => (
          <Chip
            key={header}
            label={header}
            style={{
              backgroundColor: colorMap[header],
              color: 'white',
              marginRight: '5px',
              borderRadius: '6px',
              marginBottom: '5px'
            }}
          />
        ))}
      </Box>
    ));
  };

  const onFormSubmit = async () => {
    setLoading(true);

    // Remove duplicate arrays from uniqueKeys
    const processedUniqueKeys = removeDuplicateArrays(Object.values(uniqueKeys));

    const payload = {
      appName: app_code,
      appCode: app_code,
      bulkProcessName: bulkProcessingName,
      bulkProcessCode: bulkProcessingCode,
      description: description,
      fileType: 'excel',
      fileName: file.name,
      dataObjectName: dataObject.name ,
      dataObjectId: dataObject.dataObjectId,
      excelHeaders: response.primary.columnHeaders,
      dbColumns: Object.values(columnMappings),
      mapping: columnMappings,
      validationRules: [
        {
          "validationType":"uniquekey",
          "fields":processedUniqueKeys
        },
        {
          "validationType":"mandatory",
          "fields":Object.keys(mandatoryFields).filter((key) => mandatoryFields[key] === 'Required')
        }
        // uniqueKeys: processedUniqueKeys,
        // mandatory: Object.keys(mandatoryFields).filter((key) => mandatoryFields[key] === 'Required')
      ]
    };

    console.log('Payload to be sent:', payload);

    try {
      const res = await axios.post(`${process.env.REACT_APP_CONFIG_API}/bulkProcess/createBulkProcess`, payload, {
        headers: {
          Authorization: `Bearer ${keycloak.token()}`
        }
      });
      setLoading(false);
      alert(`Bulk process created successfully! ProcessID: ${JSON.stringify(res.data.primary.processId)}`); 
           console.log('###############',res)
    } catch (error) {
      setLoading(false);
      setError('Failed to create bulk process: ' + error.message);
      console.error('Error creating bulk process:', error);

      if (error.response) {
        console.error('Server responded with status code', error.response.status);
        console.error('Response data:', error.response.data);
      } else if (error.request) {
        console.error('Request was made but no response received:', error.request);
      } else {
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  return (
    <Box display="flex">
      <SideBar page={'bulkProcessing'} />
      <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
        <Typography variant="h4" gutterBottom>
          Excel Mapping
        </Typography>
        <LadderStepForm
          elements={[
            {
              title: 'Bulk Processing Details',
              child: (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p={2}
                  sx={{ maxWidth: 800, margin: 'auto', marginBottom: 5 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        required
                        label="Bulk Processing Name"
                        value={bulkProcessingName}
                        onChange={(e) => setBulkProcessingName(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        required
                        label="Bulk Processing Code"
                        value={bulkProcessingCode}
                        onChange={(e) => setBulkProcessingCode(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
              ),
              isNextDisabled: !bulkProcessingName || !bulkProcessingCode,
              nextText: 'Next'
            },
            {
              title: 'Upload Excel File',
              child: (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p={2}
                  sx={{ maxWidth: 800, margin: 'auto', marginBottom: 5 }}>
                  <Box
                    sx={{
                      border: '2px dashed #ccc',
                      borderRadius: '8px',
                      padding: '40px',
                      width: '100%',
                      textAlign: 'center',
                      cursor: 'pointer',
                      mb: 2,
                      backgroundColor: '#fafafa'
                    }}
                    onClick={() => document.getElementById('file-input').click()}>
                    <input
                      id="file-input"
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    {!file && (
                      <Typography variant="h6" color="textSecondary">
                        Drop an Excel file here, or click to select a file
                      </Typography>
                    )}
                    {file && (
                      <Typography variant="body1" color="textPrimary" fontWeight={900}>
                        File: {file.name}
                      </Typography>
                    )}
                  </Box>
                  <FormControl sx={{ width: '100%', mb: 2 }}>
                    <InputLabel id="data-object-label">Data Object</InputLabel>
                    <Select
                      labelId="data-object-label"
                      id="data-object-select"
                      value={dataObject.dataObjectId}
                      label="Data Object"
                      onChange={handleDataObject}>
                      {variableData.map((item) => (
                        <MenuItem key={item.dataObjectId} value={item}>
                          {item.code}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!file || !dataObject || loading}
                    sx={{ width: 150, mb: 2 }}>
                    {loading ? <CircularProgress size={24} /> : 'Submit'}
                  </Button>
                  {error && (
                    <Typography variant="body2" color="error" mb={2}>
                      {error}
                    </Typography>
                  )}
                </Box>
              ),
              isNextDisabled: !viewMappingEnabled,
              nextText: 'View Mapping'
            },
            {
              title: 'Excel Header and Mapping',
              child: (
                <Grid container spacing={2} sx={{ width: '100%' }}>
                  <Grid item xs={3}>
                    <Typography variant="h6" gutterBottom>
                      Excel Header
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="h6" gutterBottom>
                      Mapping Suggestions
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="h6" gutterBottom>
                      Mandatory
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="h6" gutterBottom>
                      Unique
                    </Typography>
                  </Grid>
                  {response.primary.columnHeaders.map((header) => (
                    <React.Fragment key={header}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Typography variant="body1">{header}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl sx={{ width: '80%' }}>
                          <InputLabel>Mapping</InputLabel>
                          <Select
                            sx={{ height: '20%' }}
                            value={columnMappings[header] || ''}
                            onChange={(e) => handleMappingChange(header, e.target.value)}
                            label="Mapping">
                            {response.primary.dataObjectColumns.map((col) => (
                              <MenuItem key={`${header}-${col}`} value={col}>
                                {col}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <ToggleButtonGroup
                          color="primary"
                          value={mandatoryFields[header] || 'Required'}
                          exclusive
                          onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
                          aria-label="text alignment">
                          <ToggleButton value="Not Required" aria-label="not required">
                            Not Required
                          </ToggleButton>
                          <ToggleButton value="Required" aria-label="required">
                            Required
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                      <Grid item xs={3}>
                        <Box display="flex" alignItems="center">
                          <Button
                            variant="outlined"
                            onClick={(e) => handleClick(e, header)}
                            style={{
                              borderColor: colorMap[header] || 'inherit',
                              color: colorMap[header] || 'inherit'
                            }}>
                            Unique
                          </Button>
                        </Box>
                        <Popover
                          open={currentHeader === header}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                          }}>
                          <Box p={2}>
                            <Typography variant="h6">Composite Unique Keys?</Typography>
                            <List>
                              {response.primary.columnHeaders.map((headerOption) => (
                                <ListItem
                                  key={headerOption}
                                  secondaryAction={
                                    uniqueKeys[header]?.includes(headerOption) ? (
                                      <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => handleRemoveUniqueKey(headerOption)}>
                                        <DeleteIcon />
                                      </IconButton>
                                    ) : null
                                  }>
                                  <Checkbox
                                    checked={uniqueKeys[header]?.includes(headerOption)}
                                    onChange={(e) => {
                                      const newSelected = e.target.checked
                                        ? [...(uniqueKeys[header] || []), headerOption]
                                        : uniqueKeys[header].filter(
                                            (item) => item !== headerOption
                                          );

                                      if (uniqueKeys[headerOption]) {
                                        setMovingHeader(headerOption);
                                        setMovingToHeader(header);
                                        setOpenDialog(true);
                                      } else {
                                        handleUniqueKeyChange(header, newSelected);
                                      }
                                    }}
                                    style={{
                                      color: colorMap[headerOption] || 'inherit'
                                    }}
                                  />
                                  <ListItemText
                                    primary={headerOption}
                                    style={{
                                      opacity: uniqueKeys[headerOption] ? 0.5 : 1,
                                      color: colorMap[headerOption] || 'inherit'
                                    }}
                                  />
                                </ListItem>
                              ))}
                            </List>
                            <Box mt={2} display="flex" justifyContent="flex-end">
                              <Button variant="contained" onClick={saveUniqueKeys}>
                                Save
                              </Button>
                            </Box>
                          </Box>
                        </Popover>
                      </Grid>
                    </React.Fragment>
                  ))}
                  <Grid item xs={12}>
                    <Box mt={4}>
                      <Typography variant="h6">Unique Groups</Typography>
                      {renderUniqueGroups()}
                    </Box>
                  </Grid>
                </Grid>
              ),
              isNextDisabled: !isNextButtonEnabled(),
              // nextText: 'Next'
              nextText: 'Submit',
              submitForm: onFormSubmit,
            },
            // {
            //   title: 'Submit Connection',
            //   child: (
            //     <Box display="flex" justifyContent="center" p={2}>
            //       <Button
            //         variant="contained"
            //         color="primary"
            //         onClick={onFormSubmit}
            //         disabled={loading}
            //         sx={{ width: 150 }}>
            //         {loading ? <CircularProgress size={24} /> : 'Submit'}
            //       </Button>
            //     </Box>
            //   ),
            //   isNextDisabled: false
            // }
          ]}
        />
      </Box>
      <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
        <DialogTitle>Move Header</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The header "{movingHeader}" is already part of another group. Do you want to move it to
            the current group?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            No
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}



// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   CircularProgress,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   TextField,
//   ToggleButton,
//   ToggleButtonGroup,
//   Popover,
//   Checkbox,
//   ListItemText,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   List,
//   ListItem,
//   Chip,
//   IconButton
// } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';

// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

// const removeDuplicateArrays = (arr) => {
//   return arr.filter((item, index) => {
//     return index === arr.findIndex((other) => JSON.stringify(item) === JSON.stringify(other));
//   });
// };

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [viewMappingEnabled, setViewMappingEnabled] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [currentHeader, setCurrentHeader] = useState(null);
//   const [uniqueKeys, setUniqueKeys] = useState({});
//   const [colorMap, setColorMap] = useState({});
//   const [groupCounter, setGroupCounter] = useState(0);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [movingHeader, setMovingHeader] = useState(null);
//   const [movingToHeader, setMovingToHeader] = useState(null);

//   const [bulkProcessingName, setBulkProcessingName] = useState('');
//   const [bulkProcessingCode, setBulkProcessingCode] = useState('');
//   const [description, setDescription] = useState('');

//   const colors = ['#FFC107', '#8BC34A', '#03A9F4', '#E91E63', '#9C27B0'];

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     setLoading(true);
//     setViewMappingEnabled(false);

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('appCode', app_code);
//     formData.append('excelValidation', '');
//     // formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/bulkProcess/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       setViewMappingEnabled(true);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   const handleClick = (event, header) => {
//     setAnchorEl(event.currentTarget);
//     setCurrentHeader(header);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     setCurrentHeader(null);
//   };

//   const handleUniqueKeyChange = (header, selectedHeaders) => {
//     const newUniqueKeys = { ...uniqueKeys };
//     const newColorMap = { ...colorMap };
//     let newGroup = false;
//     let groupColor = colors[groupCounter % colors.length];

//     selectedHeaders.forEach((selectedHeader) => {
//       if (uniqueKeys[header]?.includes(selectedHeader)) return;

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (key !== header && uniqueKeys[key].includes(selectedHeader)) {
//           setMovingHeader(selectedHeader);
//           setMovingToHeader(header);
//           setOpenDialog(true);
//         }
//       });

//       if (!newColorMap[selectedHeader]) {
//         newGroup = true;
//       }
//     });

//     if (newGroup) {
//       groupColor = colors[groupCounter % colors.length];
//       setGroupCounter((prev) => prev + 1);
//     } else {
//       groupColor = newColorMap[selectedHeaders[0]];
//     }

//     selectedHeaders.forEach((selectedHeader) => {
//       newColorMap[selectedHeader] = groupColor;
//     });

//     selectedHeaders.forEach((selectedHeader) => {
//       newUniqueKeys[selectedHeader] = selectedHeaders;
//     });

//     setUniqueKeys(newUniqueKeys);
//     setColorMap(newColorMap);
//   };

//   const handleRemoveUniqueKey = (headerToRemove) => {
//     const updatedUniqueKeys = { ...uniqueKeys };
//     const updatedColorMap = { ...colorMap };

//     // Remove the header from its unique group
//     Object.keys(uniqueKeys).forEach((key) => {
//       if (uniqueKeys[key].includes(headerToRemove)) {
//         updatedUniqueKeys[key] = uniqueKeys[key].filter((item) => item !== headerToRemove);
//         if (updatedUniqueKeys[key].length === 0) {
//           delete updatedUniqueKeys[key];
//         }
//       }
//     });

//     // Remove the header from the color map
//     delete updatedColorMap[headerToRemove];

//     setUniqueKeys(updatedUniqueKeys);
//     setColorMap(updatedColorMap);
//   };

//   const saveUniqueKeys = () => {
//     handleClose();
//   };

//   const isNextButtonEnabled = () => {
//     const mappings = Object.values(columnMappings);
//     const hasEmptyMapping = mappings.includes('');
//     const hasDuplicateMapping = new Set(mappings).size !== mappings.length;

//     return !hasEmptyMapping && !hasDuplicateMapping;
//   };

//   const handleDialogClose = (confirm) => {
//     if (confirm) {
//       const updatedUniqueKeys = { ...uniqueKeys };
//       const updatedColorMap = { ...colorMap };

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (uniqueKeys[key].includes(movingHeader)) {
//           updatedUniqueKeys[key] = uniqueKeys[key].filter((item) => item !== movingHeader);
//         }
//       });

//       const groupColor = updatedColorMap[movingHeader];

//       if (!groupColor) {
//         const newGroupColor = colors[groupCounter % colors.length];
//         updatedColorMap[movingHeader] = newGroupColor;
//         setGroupCounter((prev) => prev + 1);
//       }

//       const newGroupHeaders = [...(updatedUniqueKeys[movingToHeader] || []), movingHeader];
//       newGroupHeaders.forEach((header) => {
//         updatedUniqueKeys[header] = newGroupHeaders;
//         updatedColorMap[header] = updatedColorMap[movingToHeader] || groupColor;
//       });

//       setUniqueKeys(updatedUniqueKeys);
//       setColorMap(updatedColorMap);
//     }

//     setMovingHeader(null);
//     setMovingToHeader(null);
//     setOpenDialog(false);
//   };

//   const renderUniqueGroups = () => {
//     const groups = Object.values(uniqueKeys).reduce((acc, headers) => {
//       const groupKey = headers.join(',');
//       acc[groupKey] = headers;
//       return acc;
//     }, {});

//     return Object.values(groups).map((group, index) => (
//       <Box key={index} mb={2}>
//         {group.map((header) => (
//           <Chip
//             key={header}
//             label={header}
//             style={{
//               backgroundColor: colorMap[header],
//               color: 'white',
//               marginRight: '5px',
//               borderRadius: '6px',
//               marginBottom: '5px'
//             }}
//           />
//         ))}
//       </Box>
//     ));
//   };

//   const onFormSubmit = async () => {
//     setLoading(true);

//     // Remove duplicate arrays from uniqueKeys
//     const processedUniqueKeys = removeDuplicateArrays(Object.values(uniqueKeys));

//     const payload = {
//       appName: app_code,
//       appCode: app_code,
//       bulkProcessName: bulkProcessingName,
//       bulkProcessCode: bulkProcessingCode,
//       description: description,
//       fileType: 'excel',
//       fileName: file.name,
//       dataObjectName: dataObject,
//       dataObjectId: dataObject,
//       excelHeaders: response.columnHeaders,
//       dbColumns: Object.values(columnMappings),
//       mapping: columnMappings,
//       validationRules: {
//         uniqueKeys: processedUniqueKeys,
//         mandatory: Object.keys(mandatoryFields).filter((key) => mandatoryFields[key] === 'Required')
//       }
//     };

//     console.log('Payload to be sent:', payload);

//     // axios
//     //   .post(
//     //     'http://192.168.1.83:3000/createBulkProcess',
//     //     payload ,
//     //     {
//     //       headers: {
//     //         // 'Content-Type': 'multipart/form-data',
//     //         // Authorization: `Bearer ${keycloak.token()}`
//     //       }
//     //     }
//     //   )
//     //   .then((res) => {
//     //     console.log('Response is ', res);
//     //     setLoading(false);
//     //   })
//     //   .catch((err) => {
//     //     console.log('Error is ', err);
//     //     setLoading(false);
//     //   });

//     try {
//       const res = await axios.post('http://192.168.1.83:3000/createBulkProcess', payload, {
//         headers: {
//           // Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setLoading(false);
//       alert('Bulk process created successfully!');
//     } catch (error) {
//       setLoading(false);
//       setError('Failed to create bulk process: ' + error.message);
//       console.error('Error creating bulk process:', error);

//       if (error.response) {
//         console.error('Server responded with status code', error.response.status);
//         console.error('Response data:', error.response.data);
//       } else if (error.request) {
//         console.error('Request was made but no response received:', error.request);
//       } else {
//         console.error('Error setting up the request:', error.message);
//       }
//     }
//   };

//   return (
//     <Box display="flex">
//       <SideBar page={'bulkProcessing'} />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Bulk Processing Details',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginBottom: 5 }}>
//                   <Grid container spacing={2}>
//                     <Grid item xs={4}>
//                       <TextField
//                         required
//                         label="Bulk Processing Name"
//                         value={bulkProcessingName}
//                         onChange={(e) => setBulkProcessingName(e.target.value)}
//                         fullWidth
//                       />
//                     </Grid>
//                     <Grid item xs={4}>
//                       <TextField
//                         required
//                         label="Bulk Processing Code"
//                         value={bulkProcessingCode}
//                         onChange={(e) => setBulkProcessingCode(e.target.value)}
//                         fullWidth
//                       />
//                     </Grid>
//                     <Grid item xs={4}>
//                       <TextField
//                         label="Description"
//                         value={description}
//                         onChange={(e) => setDescription(e.target.value)}
//                         fullWidth
//                       />
//                     </Grid>
//                   </Grid>
//                 </Box>
//               ),
//               isNextDisabled: !bulkProcessingName || !bulkProcessingCode,
//               nextText: 'Next'
//             },
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginBottom: 5 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '100%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '100%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject || loading}
//                     sx={{ width: 150, mb: 2 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !viewMappingEnabled,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Unique
//                     </Typography>
//                   </Grid>
//                   {response?.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Not Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Box display="flex" alignItems="center">
//                           <Button
//                             variant="outlined"
//                             onClick={(e) => handleClick(e, header)}
//                             style={{
//                               borderColor: colorMap[header] || 'inherit',
//                               color: colorMap[header] || 'inherit'
//                             }}>
//                             Unique
//                           </Button>
//                         </Box>
//                         <Popover
//                           open={currentHeader === header}
//                           anchorEl={anchorEl}
//                           onClose={handleClose}
//                           anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left'
//                           }}>
//                           <Box p={2}>
//                             <Typography variant="h6">Composite Unique Keys?</Typography>
//                             <List>
//                               {response?.columnHeaders.map((headerOption) => (
//                                 <ListItem
//                                   key={headerOption}
//                                   secondaryAction={
//                                     uniqueKeys[header]?.includes(headerOption) ? (
//                                       <IconButton
//                                         edge="end"
//                                         aria-label="delete"
//                                         onClick={() => handleRemoveUniqueKey(headerOption)}>
//                                         <DeleteIcon />
//                                       </IconButton>
//                                     ) : null
//                                   }>
//                                   <Checkbox
//                                     checked={uniqueKeys[header]?.includes(headerOption)}
//                                     onChange={(e) => {
//                                       const newSelected = e.target.checked
//                                         ? [...(uniqueKeys[header] || []), headerOption]
//                                         : uniqueKeys[header].filter(
//                                             (item) => item !== headerOption
//                                           );

//                                       if (uniqueKeys[headerOption]) {
//                                         setMovingHeader(headerOption);
//                                         setMovingToHeader(header);
//                                         setOpenDialog(true);
//                                       } else {
//                                         handleUniqueKeyChange(header, newSelected);
//                                       }
//                                     }}
//                                     style={{
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                   <ListItemText
//                                     primary={headerOption}
//                                     style={{
//                                       opacity: uniqueKeys[headerOption] ? 0.5 : 1,
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                 </ListItem>
//                               ))}
//                             </List>
//                             <Box mt={2} display="flex" justifyContent="flex-end">
//                               <Button variant="contained" onClick={saveUniqueKeys}>
//                                 Save
//                               </Button>
//                             </Box>
//                           </Box>
//                         </Popover>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                   <Grid item xs={12}>
//                     <Box mt={4}>
//                       <Typography variant="h6">Unique Groups</Typography>
//                       {renderUniqueGroups()}
//                     </Box>
//                   </Grid>
//                 </Grid>
//               ),
//               isNextDisabled: !isNextButtonEnabled(),
//               nextText: 'Next'
//             },
//             {
//               title: 'Submit Connection',
//               child: (
//                 <Box display="flex" justifyContent="center" p={2}>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={onFormSubmit}
//                     disabled={loading}
//                     sx={{ width: 150 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                 </Box>
//               ),
//               isNextDisabled: false
//             }
//           ]}
//         />
//       </Box>
//       <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
//         <DialogTitle>Move Header</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             The header "{movingHeader}" is already part of another group. Do you want to move it to
//             the current group?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => handleDialogClose(false)} color="primary">
//             No
//           </Button>
//           <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
//             Yes
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// 24 5:48
// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   CircularProgress,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   TextField,
//   ToggleButton,
//   ToggleButtonGroup,
//   Popover,
//   Checkbox,
//   ListItemText,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   List,
//   ListItem,
//   Chip,
//   IconButton
// } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [viewMappingEnabled, setViewMappingEnabled] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [currentHeader, setCurrentHeader] = useState(null);
//   const [uniqueKeys, setUniqueKeys] = useState({});
//   const [colorMap, setColorMap] = useState({});
//   const [groupCounter, setGroupCounter] = useState(0);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [movingHeader, setMovingHeader] = useState(null);
//   const [movingToHeader, setMovingToHeader] = useState(null);

//   const [bulkProcessingName, setBulkProcessingName] = useState('');
//   const [bulkProcessingCode, setBulkProcessingCode] = useState('');
//   const [description, setDescription] = useState('');

//   const colors = ['#FFC107', '#8BC34A', '#03A9F4', '#E91E63', '#9C27B0'];

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     setLoading(true);
//     setViewMappingEnabled(false);

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('excelValidation', '');
//     formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       setViewMappingEnabled(true);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   const handleClick = (event, header) => {
//     setAnchorEl(event.currentTarget);
//     setCurrentHeader(header);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     setCurrentHeader(null);
//   };

//   const handleUniqueKeyChange = (header, selectedHeaders) => {
//     const newUniqueKeys = { ...uniqueKeys };
//     const newColorMap = { ...colorMap };
//     let newGroup = false;
//     let groupColor = colors[groupCounter % colors.length];

//     selectedHeaders.forEach((selectedHeader) => {
//       if (uniqueKeys[header]?.includes(selectedHeader)) return;

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (key !== header && uniqueKeys[key].includes(selectedHeader)) {
//           setMovingHeader(selectedHeader);
//           setMovingToHeader(header);
//           setOpenDialog(true);
//         }
//       });

//       if (!newColorMap[selectedHeader]) {
//         newGroup = true;
//       }
//     });

//     if (newGroup) {
//       groupColor = colors[groupCounter % colors.length];
//       setGroupCounter((prev) => prev + 1);
//     } else {
//       groupColor = newColorMap[selectedHeaders[0]];
//     }

//     selectedHeaders.forEach((selectedHeader) => {
//       newColorMap[selectedHeader] = groupColor;
//     });

//     selectedHeaders.forEach((selectedHeader) => {
//       newUniqueKeys[selectedHeader] = selectedHeaders;
//     });

//     setUniqueKeys(newUniqueKeys);
//     setColorMap(newColorMap);
//   };

//   const handleRemoveUniqueKey = (headerToRemove) => {
//     const updatedUniqueKeys = { ...uniqueKeys };
//     const updatedColorMap = { ...colorMap };

//     // Remove the header from its unique group
//     Object.keys(uniqueKeys).forEach((key) => {
//       if (uniqueKeys[key].includes(headerToRemove)) {
//         updatedUniqueKeys[key] = uniqueKeys[key].filter((item) => item !== headerToRemove);
//         if (updatedUniqueKeys[key].length === 0) {
//           delete updatedUniqueKeys[key];
//         }
//       }
//     });

//     // Remove the header from the color map
//     delete updatedColorMap[headerToRemove];

//     setUniqueKeys(updatedUniqueKeys);
//     setColorMap(updatedColorMap);
//   };

//   const saveUniqueKeys = () => {
//     handleClose();
//   };

//   const isNextButtonEnabled = () => {
//     const mappings = Object.values(columnMappings);
//     const hasEmptyMapping = mappings.includes('');
//     const hasDuplicateMapping = new Set(mappings).size !== mappings.length;

//     return !hasEmptyMapping && !hasDuplicateMapping;
//   };

//   const handleDialogClose = (confirm) => {
//     if (confirm) {
//       const updatedUniqueKeys = { ...uniqueKeys };
//       const updatedColorMap = { ...colorMap };

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (uniqueKeys[key].includes(movingHeader)) {
//           updatedUniqueKeys[key] = uniqueKeys[key].filter((item) => item !== movingHeader);
//         }
//       });

//       const groupColor = updatedColorMap[movingHeader];

//       if (!groupColor) {
//         const newGroupColor = colors[groupCounter % colors.length];
//         updatedColorMap[movingHeader] = newGroupColor;
//         setGroupCounter((prev) => prev + 1);
//       }

//       const newGroupHeaders = [...(updatedUniqueKeys[movingToHeader] || []), movingHeader];
//       newGroupHeaders.forEach((header) => {
//         updatedUniqueKeys[header] = newGroupHeaders;
//         updatedColorMap[header] = updatedColorMap[movingToHeader] || groupColor;
//       });

//       setUniqueKeys(updatedUniqueKeys);
//       setColorMap(updatedColorMap);
//     }

//     setMovingHeader(null);
//     setMovingToHeader(null);
//     setOpenDialog(false);
//   };

//   const renderUniqueGroups = () => {
//     const groups = Object.values(uniqueKeys).reduce((acc, headers) => {
//       const groupKey = headers.join(',');
//       acc[groupKey] = headers;
//       return acc;
//     }, {});

//     return Object.values(groups).map((group, index) => (
//       <Box key={index} mb={2}>
//         {group.map((header) => (
//           <Chip
//             key={header}
//             label={header}
//             style={{
//               backgroundColor: colorMap[header],
//               color: 'white',
//               marginRight: '5px',
//               borderRadius: '6px',
//               marginBottom: '5px'
//             }}
//           />
//         ))}
//       </Box>
//     ));
//   };

//   return (
//     <Box display="flex">
//       <SideBar page={'bulkProcessing'} />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Bulk Processing Details',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginBottom: 5 }}>
//                   <Grid container spacing={2}>
//                     <Grid item xs={4}>
//                       <TextField
//                         required
//                         label="Bulk Processing Name"
//                         value={bulkProcessingName}
//                         onChange={(e) => setBulkProcessingName(e.target.value)}
//                         fullWidth
//                       />
//                     </Grid>
//                     <Grid item xs={4}>
//                       <TextField
//                         required
//                         label="Bulk Processing Code"
//                         value={bulkProcessingCode}
//                         onChange={(e) => setBulkProcessingCode(e.target.value)}
//                         fullWidth
//                       />
//                     </Grid>
//                     <Grid item xs={4}>
//                       <TextField
//                         label="Description"
//                         value={description}
//                         onChange={(e) => setDescription(e.target.value)}
//                         fullWidth
//                       />
//                     </Grid>
//                   </Grid>
//                 </Box>
//               ),
//               isNextDisabled: !bulkProcessingName || !bulkProcessingCode,
//               nextText: 'Next'
//             },
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginBottom: 5 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '100%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '100%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject || loading}
//                     sx={{ width: 150, mb: 2 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !viewMappingEnabled,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Unique
//                     </Typography>
//                   </Grid>
//                   {response.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Not Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Box display="flex" alignItems="center">
//                           <Button
//                             variant="outlined"
//                             onClick={(e) => handleClick(e, header)}
//                             style={{
//                               borderColor: colorMap[header] || 'inherit',
//                               color: colorMap[header] || 'inherit'
//                             }}>
//                             Unique
//                           </Button>
//                         </Box>
//                         <Popover
//                           open={currentHeader === header}
//                           anchorEl={anchorEl}
//                           onClose={handleClose}
//                           anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left'
//                           }}>
//                           <Box p={2}>
//                             <Typography variant="h6">Composite Unique Keys?</Typography>
//                             <List>
//                               {response.columnHeaders.map((headerOption) => (
//                                 <ListItem
//                                   key={headerOption}
//                                   secondaryAction={
//                                     uniqueKeys[header]?.includes(headerOption) ? (
//                                       <IconButton
//                                         edge="end"
//                                         aria-label="delete"
//                                         onClick={() => handleRemoveUniqueKey(headerOption)}>
//                                         <DeleteIcon />
//                                       </IconButton>
//                                     ) : null
//                                   }>
//                                   <Checkbox
//                                     checked={uniqueKeys[header]?.includes(headerOption)}
//                                     onChange={(e) => {
//                                       const newSelected = e.target.checked
//                                         ? [...(uniqueKeys[header] || []), headerOption]
//                                         : uniqueKeys[header].filter(
//                                             (item) => item !== headerOption
//                                           );

//                                       if (uniqueKeys[headerOption]) {
//                                         setMovingHeader(headerOption);
//                                         setMovingToHeader(header);
//                                         setOpenDialog(true);
//                                       } else {
//                                         handleUniqueKeyChange(header, newSelected);
//                                       }
//                                     }}
//                                     style={{
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                   <ListItemText
//                                     primary={headerOption}
//                                     style={{
//                                       opacity: uniqueKeys[headerOption] ? 0.5 : 1,
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                 </ListItem>
//                               ))}
//                             </List>
//                             <Box mt={2} display="flex" justifyContent="flex-end">
//                               <Button variant="contained" onClick={saveUniqueKeys}>
//                                 Save
//                               </Button>
//                             </Box>
//                           </Box>
//                         </Popover>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                   <Grid item xs={12}>
//                     <Box mt={4}>
//                       <Typography variant="h6">Unique Groups</Typography>
//                       {renderUniqueGroups()}
//                     </Box>
//                   </Grid>
//                 </Grid>
//               ),
//               isNextDisabled: !isNextButtonEnabled(),
//               nextText: 'Next'
//             }
//           ]}
//         />
//       </Box>
//       <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
//         <DialogTitle>Move Header</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             The header "{movingHeader}" is already part of another group. Do you want to move it to
//             the current group?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => handleDialogClose(false)} color="primary">
//             No
//           </Button>
//           <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
//             Yes
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   CircularProgress,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup,
//   Popover,
//   Checkbox,
//   ListItemText,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   List,
//   ListItem,
//   Chip
// } from '@mui/material';
// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [viewMappingEnabled, setViewMappingEnabled] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [currentHeader, setCurrentHeader] = useState(null);
//   const [uniqueKeys, setUniqueKeys] = useState({});
//   const [colorMap, setColorMap] = useState({});
//   const [groupCounter, setGroupCounter] = useState(0);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [movingHeader, setMovingHeader] = useState(null);
//   const [movingToHeader, setMovingToHeader] = useState(null);

//   const colors = ['#FFC107', '#8BC34A', '#03A9F4', '#E91E63', '#9C27B0'];

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     setLoading(true);
//     setViewMappingEnabled(false);

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('excelValidation', '');
//     formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       setViewMappingEnabled(true);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   const handleClick = (event, header) => {
//     setAnchorEl(event.currentTarget);
//     setCurrentHeader(header);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     setCurrentHeader(null);
//   };

//   const handleUniqueKeyChange = (header, selectedHeaders) => {
//     const newUniqueKeys = { ...uniqueKeys };
//     const newColorMap = { ...colorMap };
//     let newGroup = false;
//     let groupColor = colors[groupCounter % colors.length];

//     selectedHeaders.forEach((selectedHeader) => {
//       if (uniqueKeys[header]?.includes(selectedHeader)) return;

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (key !== header && uniqueKeys[key].includes(selectedHeader)) {
//           setMovingHeader(selectedHeader);
//           setMovingToHeader(header);
//           setOpenDialog(true);
//         }
//       });

//       if (!newColorMap[selectedHeader]) {
//         newGroup = true;
//       }
//     });

//     if (newGroup) {
//       groupColor = colors[groupCounter % colors.length];
//       setGroupCounter((prev) => prev + 1);
//     } else {
//       groupColor = newColorMap[selectedHeaders[0]];
//     }

//     selectedHeaders.forEach((selectedHeader) => {
//       newColorMap[selectedHeader] = groupColor;
//     });

//     selectedHeaders.forEach((selectedHeader) => {
//       newUniqueKeys[selectedHeader] = selectedHeaders;
//     });

//     setUniqueKeys(newUniqueKeys);
//     setColorMap(newColorMap);
//   };

//   const saveUniqueKeys = () => {
//     handleClose();
//   };

//   const isNextButtonEnabled = () => {
//     const mappings = Object.values(columnMappings);
//     const hasEmptyMapping = mappings.includes('');
//     const hasDuplicateMapping = new Set(mappings).size !== mappings.length;

//     return !hasEmptyMapping && !hasDuplicateMapping;
//   };

//   const handleDialogClose = (confirm) => {
//     if (confirm) {
//       const updatedUniqueKeys = { ...uniqueKeys };
//       const updatedColorMap = { ...colorMap };

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (uniqueKeys[key].includes(movingHeader)) {
//           updatedUniqueKeys[key] = uniqueKeys[key].filter((item) => item !== movingHeader);
//         }
//       });

//       const groupColor = updatedColorMap[movingHeader];

//       if (!groupColor) {
//         const newGroupColor = colors[groupCounter % colors.length];
//         updatedColorMap[movingHeader] = newGroupColor;
//         setGroupCounter((prev) => prev + 1);
//       }

//       const newGroupHeaders = [...(updatedUniqueKeys[movingToHeader] || []), movingHeader];
//       newGroupHeaders.forEach((header) => {
//         updatedUniqueKeys[header] = newGroupHeaders;
//         updatedColorMap[header] = updatedColorMap[movingToHeader] || groupColor;
//       });

//       setUniqueKeys(updatedUniqueKeys);
//       setColorMap(updatedColorMap);
//     }

//     setMovingHeader(null);
//     setMovingToHeader(null);
//     setOpenDialog(false);
//   };

//   const renderUniqueGroups = () => {
//     const groups = Object.values(uniqueKeys).reduce((acc, group) => {
//       const groupKey = group.sort().join(',');
//       if (!acc[groupKey]) {
//         acc[groupKey] = group;
//       }
//       return acc;
//     }, {});

//     return Object.values(groups).map((group, index) => (
//       <Box key={index} mb={2}>
//         {group.map((key) => (
//           <Chip
//             key={key}
//             label={key}
//             style={{
//               backgroundColor: colorMap[key],
//               color: 'white',
//               marginRight: '5px',
//               marginBottom: '5px'
//             }}
//           />
//         ))}
//       </Box>
//     ));
//   };

//   return (
//     <Box display="flex">
//       <SideBar page={'bulkProcessing'} />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginLeft: 20 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '150%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '150%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject || loading}
//                     sx={{ width: 30, mb: -10 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !viewMappingEnabled,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Unique
//                     </Typography>
//                   </Grid>
//                   {response.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Not Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Button
//                           variant="outlined"
//                           onClick={(e) => handleClick(e, header)}
//                           style={{
//                             borderColor: colorMap[header]
//                           }}>
//                           Unique
//                         </Button>
//                         <Popover
//                           open={currentHeader === header}
//                           anchorEl={anchorEl}
//                           onClose={handleClose}
//                           anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left'
//                           }}>
//                           <Box p={2}>
//                             <Typography variant="h6">Composite Unique Keys?</Typography>
//                             <List>
//                               {response.columnHeaders.map((headerOption) => (
//                                 <ListItem key={headerOption}>
//                                   <Checkbox
//                                     checked={uniqueKeys[header]?.includes(headerOption)}
//                                     onChange={(e) => {
//                                       const newSelected = e.target.checked
//                                         ? [...(uniqueKeys[header] || []), headerOption]
//                                         : uniqueKeys[header].filter(
//                                             (item) => item !== headerOption
//                                           );

//                                       if (uniqueKeys[headerOption]) {
//                                         setMovingHeader(headerOption);
//                                         setMovingToHeader(header);
//                                         setOpenDialog(true);
//                                       } else {
//                                         handleUniqueKeyChange(header, newSelected);
//                                       }
//                                     }}
//                                     style={{
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                   <ListItemText
//                                     primary={headerOption}
//                                     style={{
//                                       opacity: uniqueKeys[headerOption] ? 0.5 : 1,
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                 </ListItem>
//                               ))}
//                             </List>
//                             <Box mt={2} display="flex" justifyContent="flex-end">
//                               <Button variant="contained" onClick={saveUniqueKeys}>
//                                 Save
//                               </Button>
//                             </Box>
//                           </Box>
//                         </Popover>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                   <Grid item xs={12}>
//                     <Box mt={2}>
//                       <Typography variant="h6" gutterBottom>
//                         Unique Groups
//                       </Typography>
//                       <Box display="flex" flexWrap="wrap">
//                         {renderUniqueGroups()}
//                       </Box>
//                     </Box>
//                   </Grid>
//                 </Grid>
//               ),
//               isNextDisabled: !isNextButtonEnabled(),
//               nextText: 'Next'
//             }
//           ]}
//         />
//       </Box>
//       <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
//         <DialogTitle>Move Header</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             The header "{movingHeader}" is already part of another group. Do you want to move it to
//             the current group?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => handleDialogClose(false)} color="primary">
//             No
//           </Button>
//           <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
//             Yes
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// ------------------------------
// perfect down chips
// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   CircularProgress,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup,
//   Popover,
//   Checkbox,
//   ListItemText,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   List,
//   ListItem,
//   Chip
// } from '@mui/material';
// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [viewMappingEnabled, setViewMappingEnabled] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [currentHeader, setCurrentHeader] = useState(null);
//   const [uniqueKeys, setUniqueKeys] = useState({});
//   const [colorMap, setColorMap] = useState({});
//   const [groupCounter, setGroupCounter] = useState(0);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [movingHeader, setMovingHeader] = useState(null);
//   const [movingToHeader, setMovingToHeader] = useState(null);

//   const colors = ['#FFC107', '#8BC34A', '#03A9F4', '#E91E63', '#9C27B0'];

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     setLoading(true);
//     setViewMappingEnabled(false);

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('excelValidation', '');
//     formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       setViewMappingEnabled(true);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   const handleClick = (event, header) => {
//     setAnchorEl(event.currentTarget);
//     setCurrentHeader(header);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     setCurrentHeader(null);
//   };

//   const handleUniqueKeyChange = (header, selectedHeaders) => {
//     const newUniqueKeys = { ...uniqueKeys };
//     const newColorMap = { ...colorMap };
//     let newGroup = false;
//     let groupColor = colors[groupCounter % colors.length];

//     selectedHeaders.forEach((selectedHeader) => {
//       if (uniqueKeys[header]?.includes(selectedHeader)) return;

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (key !== header && uniqueKeys[key].includes(selectedHeader)) {
//           setMovingHeader(selectedHeader);
//           setMovingToHeader(header);
//           setOpenDialog(true);
//         }
//       });

//       if (!newColorMap[selectedHeader]) {
//         newGroup = true;
//       }
//     });

//     if (newGroup) {
//       groupColor = colors[groupCounter % colors.length];
//       setGroupCounter((prev) => prev + 1);
//     } else {
//       groupColor = newColorMap[selectedHeaders[0]];
//     }

//     selectedHeaders.forEach((selectedHeader) => {
//       newColorMap[selectedHeader] = groupColor;
//     });

//     selectedHeaders.forEach((selectedHeader) => {
//       newUniqueKeys[selectedHeader] = selectedHeaders;
//     });

//     setUniqueKeys(newUniqueKeys);
//     setColorMap(newColorMap);
//   };

//   const saveUniqueKeys = () => {
//     handleClose();
//   };

//   const isNextButtonEnabled = () => {
//     const mappings = Object.values(columnMappings);
//     const hasEmptyMapping = mappings.includes('');
//     const hasDuplicateMapping = new Set(mappings).size !== mappings.length;

//     return !hasEmptyMapping && !hasDuplicateMapping;
//   };

//   const handleDialogClose = (confirm) => {
//     if (confirm) {
//       const updatedUniqueKeys = { ...uniqueKeys };
//       const updatedColorMap = { ...colorMap };

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (uniqueKeys[key].includes(movingHeader)) {
//           updatedUniqueKeys[key] = uniqueKeys[key].filter((item) => item !== movingHeader);
//         }
//       });

//       const groupColor = updatedColorMap[movingHeader];

//       if (!groupColor) {
//         const newGroupColor = colors[groupCounter % colors.length];
//         updatedColorMap[movingHeader] = newGroupColor;
//         setGroupCounter((prev) => prev + 1);
//       }

//       const newGroupHeaders = [...(updatedUniqueKeys[movingToHeader] || []), movingHeader];
//       newGroupHeaders.forEach((header) => {
//         updatedUniqueKeys[header] = newGroupHeaders;
//         updatedColorMap[header] = updatedColorMap[movingToHeader] || groupColor;
//       });

//       setUniqueKeys(updatedUniqueKeys);
//       setColorMap(updatedColorMap);
//     }

//     setMovingHeader(null);
//     setMovingToHeader(null);
//     setOpenDialog(false);
//   };

//   const renderUniqueGroups = () => {
//     const groups = Object.values(uniqueKeys).reduce((acc, headers) => {
//       const groupKey = headers.join(',');
//       acc[groupKey] = headers;
//       return acc;
//     }, {});

//     return Object.values(groups).map((group, index) => (
//       <Box key={index} mb={2}>
//         {group.map((header) => (
//           <Chip
//             key={header}
//             label={header}
//             style={{
//               backgroundColor: colorMap[header],
//               color: 'white',
//               marginRight: '5px',
//               borderRadius: '6px',
//               marginBottom: '5px'
//             }}
//           />
//         ))}
//       </Box>
//     ));
//   };

//   return (
//     <Box display="flex">
//       <SideBar page={'bulkProcessing'} />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginLeft: 20 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '150%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '150%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject || loading}
//                     sx={{ width: 30, mb: -10 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !viewMappingEnabled,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Unique
//                     </Typography>
//                   </Grid>
//                   {response.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Not Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Box display="flex" alignItems="center">
//                           {/* <Button */}
//                           <Button
//                             variant="outlined"
//                             onClick={(e) => handleClick(e, header)}
//                             style={{
//                               borderColor: colorMap[header] || 'inherit',
//                               color: colorMap[header] || 'inherit'
//                             }}>
//                             Unique
//                           </Button>
//                         </Box>
//                         <Popover
//                           open={currentHeader === header}
//                           anchorEl={anchorEl}
//                           onClose={handleClose}
//                           anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left'
//                           }}>
//                           <Box p={2}>
//                             <Typography variant="h6">Composite Unique Keys?</Typography>
//                             <List>
//                               {response.columnHeaders.map((headerOption) => (
//                                 <ListItem key={headerOption}>
//                                   <Checkbox
//                                     checked={uniqueKeys[header]?.includes(headerOption)}
//                                     onChange={(e) => {
//                                       const newSelected = e.target.checked
//                                         ? [...(uniqueKeys[header] || []), headerOption]
//                                         : uniqueKeys[header].filter(
//                                             (item) => item !== headerOption
//                                           );

//                                       if (uniqueKeys[headerOption]) {
//                                         setMovingHeader(headerOption);
//                                         setMovingToHeader(header);
//                                         setOpenDialog(true);
//                                       } else {
//                                         handleUniqueKeyChange(header, newSelected);
//                                       }
//                                     }}
//                                     style={{
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                   <ListItemText
//                                     primary={headerOption}
//                                     style={{
//                                       opacity: uniqueKeys[headerOption] ? 0.5 : 1,
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                 </ListItem>
//                               ))}
//                             </List>
//                             <Box mt={2} display="flex" justifyContent="flex-end">
//                               <Button variant="contained" onClick={saveUniqueKeys}>
//                                 Save
//                               </Button>
//                             </Box>
//                           </Box>
//                         </Popover>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                   <Grid item xs={12}>
//                     <Box mt={4}>
//                       <Typography variant="h6">Unique Groups</Typography>
//                       {renderUniqueGroups()}
//                     </Box>
//                   </Grid>
//                 </Grid>
//               ),
//               isNextDisabled: !isNextButtonEnabled(),
//               nextText: 'Next'
//             }
//           ]}
//         />
//       </Box>
//       <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
//         <DialogTitle>Move Header</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             The header "{movingHeader}" is already part of another group. Do you want to move it to
//             the current group?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => handleDialogClose(false)} color="primary">
//             No
//           </Button>
//           <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
//             Yes
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// 3333333333333333333333333333333333333333333333333333333333333
// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   CircularProgress,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup,
//   Popover,
//   Checkbox,
//   ListItemText,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   List,
//   ListItem,
//   Chip
// } from '@mui/material';
// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [viewMappingEnabled, setViewMappingEnabled] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [currentHeader, setCurrentHeader] = useState(null);
//   const [uniqueKeys, setUniqueKeys] = useState({});
//   const [colorMap, setColorMap] = useState({});
//   const [groupCounter, setGroupCounter] = useState(0);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [movingHeader, setMovingHeader] = useState(null);
//   const [movingToHeader, setMovingToHeader] = useState(null);

//   const colors = ['#FFC107', '#8BC34A', '#03A9F4', '#E91E63', '#9C27B0'];

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     setLoading(true);
//     setViewMappingEnabled(false);

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('excelValidation', '');
//     formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       setViewMappingEnabled(true);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   const handleClick = (event, header) => {
//     setAnchorEl(event.currentTarget);
//     setCurrentHeader(header);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     setCurrentHeader(null);
//   };

//   const handleUniqueKeyChange = (header, selectedHeaders) => {
//     const newUniqueKeys = { ...uniqueKeys };
//     const newColorMap = { ...colorMap };
//     let newGroup = false;
//     let groupColor = colors[groupCounter % colors.length];

//     selectedHeaders.forEach((selectedHeader) => {
//       if (uniqueKeys[header]?.includes(selectedHeader)) return;

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (key !== header && uniqueKeys[key].includes(selectedHeader)) {
//           setMovingHeader(selectedHeader);
//           setMovingToHeader(header);
//           setOpenDialog(true);
//         }
//       });

//       if (!newColorMap[selectedHeader]) {
//         newGroup = true;
//       }
//     });

//     if (newGroup) {
//       groupColor = colors[groupCounter % colors.length];
//       setGroupCounter((prev) => prev + 1);
//     } else {
//       groupColor = newColorMap[selectedHeaders[0]];
//     }

//     selectedHeaders.forEach((selectedHeader) => {
//       newColorMap[selectedHeader] = groupColor;
//     });

//     selectedHeaders.forEach((selectedHeader) => {
//       newUniqueKeys[selectedHeader] = selectedHeaders;
//     });

//     setUniqueKeys(newUniqueKeys);
//     setColorMap(newColorMap);
//   };

//   const saveUniqueKeys = () => {
//     handleClose();
//   };

//   const isNextButtonEnabled = () => {
//     const mappings = Object.values(columnMappings);
//     const hasEmptyMapping = mappings.includes('');
//     const hasDuplicateMapping = new Set(mappings).size !== mappings.length;

//     return !hasEmptyMapping && !hasDuplicateMapping;
//   };

//   const handleDialogClose = (confirm) => {
//     if (confirm) {
//       const updatedUniqueKeys = { ...uniqueKeys };
//       const updatedColorMap = { ...colorMap };

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (uniqueKeys[key].includes(movingHeader)) {
//           updatedUniqueKeys[key] = uniqueKeys[key].filter((item) => item !== movingHeader);
//         }
//       });

//       const groupColor = updatedColorMap[movingHeader];

//       if (!groupColor) {
//         const newGroupColor = colors[groupCounter % colors.length];
//         updatedColorMap[movingHeader] = newGroupColor;
//         setGroupCounter((prev) => prev + 1);
//       }

//       const newGroupHeaders = [...(updatedUniqueKeys[movingToHeader] || []), movingHeader];
//       newGroupHeaders.forEach((header) => {
//         updatedUniqueKeys[header] = newGroupHeaders;
//         updatedColorMap[header] = updatedColorMap[movingToHeader] || groupColor;
//       });

//       setUniqueKeys(updatedUniqueKeys);
//       setColorMap(updatedColorMap);
//     }

//     setMovingHeader(null);
//     setMovingToHeader(null);
//     setOpenDialog(false);
//   };

//   const renderUniqueGroups = () => {
//     const groups = {};

//     Object.keys(uniqueKeys).forEach((key) => {
//       const group = uniqueKeys[key];
//       if (group && group.length > 0) {
//         const groupKey = group.sort().join('-');
//         groups[groupKey] = group;
//       }
//     });

//     return Object.keys(groups).map((groupKey) => (
//       <Box key={groupKey} display="flex" alignItems="center" mb={2}>
//         {groups[groupKey].map((key) => (
//           <Chip
//             key={key}
//             label={key}
//             style={{
//               backgroundColor: colorMap[key],
//               color: 'white',
//               marginRight: '5px'
//             }}
//           />
//         ))}
//       </Box>
//     ));
//   };

//   return (
//     <Box display="flex">
//       <SideBar page={'bulkProcessing'} />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginLeft: 20 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '150%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '150%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject || loading}
//                     sx={{ width: 30, mb: -10 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !viewMappingEnabled,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Unique
//                     </Typography>
//                   </Grid>
//                   {response.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Not Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Box display="flex" alignItems="center">
//                           <Button variant="outlined" onClick={(e) => handleClick(e, header)}>
//                             Unique
//                           </Button>
//                         </Box>
//                         <Popover
//                           open={currentHeader === header}
//                           anchorEl={anchorEl}
//                           onClose={handleClose}
//                           anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left'
//                           }}>
//                           <Box p={2}>
//                             <Typography variant="h6">Composite Unique Keys?</Typography>
//                             <List>
//                               {response.columnHeaders.map((headerOption) => (
//                                 <ListItem key={headerOption}>
//                                   <Checkbox
//                                     checked={uniqueKeys[header]?.includes(headerOption)}
//                                     onChange={(e) => {
//                                       const newSelected = e.target.checked
//                                         ? [...(uniqueKeys[header] || []), headerOption]
//                                         : uniqueKeys[header].filter(
//                                             (item) => item !== headerOption
//                                           );

//                                       if (uniqueKeys[headerOption]) {
//                                         setMovingHeader(headerOption);
//                                         setMovingToHeader(header);
//                                         setOpenDialog(true);
//                                       } else {
//                                         handleUniqueKeyChange(header, newSelected);
//                                       }
//                                     }}
//                                     style={{
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                   <ListItemText
//                                     primary={headerOption}
//                                     style={{
//                                       opacity: uniqueKeys[headerOption] ? 0.5 : 1,
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                 </ListItem>
//                               ))}
//                             </List>
//                             <Box mt={2} display="flex" justifyContent="flex-end">
//                               <Button variant="contained" onClick={saveUniqueKeys}>
//                                 Save
//                               </Button>
//                             </Box>
//                           </Box>
//                         </Popover>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                 </Grid>
//               ),
//               isNextDisabled: !isNextButtonEnabled(),
//               nextText: 'Next'
//             }
//           ]}
//         />
//         <Box mt={4}>
//           <Typography variant="h6">Unique Groups</Typography>
//           {renderUniqueGroups()}
//         </Box>
//       </Box>
//       <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
//         <DialogTitle>Move Header</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             The header "{movingHeader}" is already part of another group. Do you want to move it to
//             the current group?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => handleDialogClose(false)} color="primary">
//             No
//           </Button>
//           <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
//             Yes
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// 2222222222222222222222222222222222222222222222222222222222222
// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   CircularProgress,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup,
//   Popover,
//   Checkbox,
//   ListItemText,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   List,
//   ListItem,
//   Chip
// } from '@mui/material';
// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [viewMappingEnabled, setViewMappingEnabled] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [currentHeader, setCurrentHeader] = useState(null);
//   const [uniqueKeys, setUniqueKeys] = useState({});
//   const [colorMap, setColorMap] = useState({});
//   const [groupCounter, setGroupCounter] = useState(0);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [movingHeader, setMovingHeader] = useState(null);
//   const [movingToHeader, setMovingToHeader] = useState(null);

//   const colors = ['#FFC107', '#8BC34A', '#03A9F4', '#E91E63', '#9C27B0'];

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     setLoading(true);
//     setViewMappingEnabled(false);

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('excelValidation', '');
//     formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       setViewMappingEnabled(true);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   const handleClick = (event, header) => {
//     setAnchorEl(event.currentTarget);
//     setCurrentHeader(header);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     setCurrentHeader(null);
//   };

//   const handleUniqueKeyChange = (header, selectedHeaders) => {
//     const newUniqueKeys = { ...uniqueKeys };
//     const newColorMap = { ...colorMap };
//     let newGroup = false;
//     let groupColor = colors[groupCounter % colors.length];

//     selectedHeaders.forEach((selectedHeader) => {
//       if (uniqueKeys[header]?.includes(selectedHeader)) return;

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (key !== header && uniqueKeys[key].includes(selectedHeader)) {
//           setMovingHeader(selectedHeader);
//           setMovingToHeader(header);
//           setOpenDialog(true);
//         }
//       });

//       if (!newColorMap[selectedHeader]) {
//         newGroup = true;
//       }
//     });

//     if (newGroup) {
//       groupColor = colors[groupCounter % colors.length];
//       setGroupCounter((prev) => prev + 1);
//     } else {
//       groupColor = newColorMap[selectedHeaders[0]];
//     }

//     selectedHeaders.forEach((selectedHeader) => {
//       newColorMap[selectedHeader] = groupColor;
//     });

//     selectedHeaders.forEach((selectedHeader) => {
//       newUniqueKeys[selectedHeader] = selectedHeaders;
//     });

//     setUniqueKeys(newUniqueKeys);
//     setColorMap(newColorMap);
//   };

//   const saveUniqueKeys = () => {
//     handleClose();
//   };

//   const isNextButtonEnabled = () => {
//     const mappings = Object.values(columnMappings);
//     const hasEmptyMapping = mappings.includes('');
//     const hasDuplicateMapping = new Set(mappings).size !== mappings.length;

//     return !hasEmptyMapping && !hasDuplicateMapping;
//   };

//   const handleDialogClose = (confirm) => {
//     if (confirm) {
//       const updatedUniqueKeys = { ...uniqueKeys };
//       const updatedColorMap = { ...colorMap };

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (uniqueKeys[key].includes(movingHeader)) {
//           updatedUniqueKeys[key] = uniqueKeys[key].filter((item) => item !== movingHeader);
//         }
//       });

//       const groupColor = updatedColorMap[movingHeader];

//       if (!groupColor) {
//         const newGroupColor = colors[groupCounter % colors.length];
//         updatedColorMap[movingHeader] = newGroupColor;
//         setGroupCounter((prev) => prev + 1);
//       }

//       const newGroupHeaders = [...(updatedUniqueKeys[movingToHeader] || []), movingHeader];
//       newGroupHeaders.forEach((header) => {
//         updatedUniqueKeys[header] = newGroupHeaders;
//         updatedColorMap[header] = updatedColorMap[movingToHeader] || groupColor;
//       });

//       setUniqueKeys(updatedUniqueKeys);
//       setColorMap(updatedColorMap);
//     }

//     setMovingHeader(null);
//     setMovingToHeader(null);
//     setOpenDialog(false);
//   };

//   return (
//     <Box display="flex">
//       <SideBar page={'bulkProcessing'} />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginLeft: 20 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '150%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '150%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject || loading}
//                     sx={{ width: 30, mb: -10 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !viewMappingEnabled,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Unique
//                     </Typography>
//                   </Grid>
//                   {response.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Not Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Box display="flex" alignItems="center">
//                           <Button variant="outlined" onClick={(e) => handleClick(e, header)}>
//                             Unique
//                           </Button>
//                           <Box display="flex" flexWrap="wrap" ml={1}>
//                             {uniqueKeys[header]?.map((key) => (
//                               <Chip
//                                 key={key}
//                                 label={key}
//                                 style={{
//                                   backgroundColor: colorMap[key],
//                                   color: 'white',
//                                   marginRight: '5px',
//                                   marginBottom: '5px'
//                                 }}
//                               />
//                             ))}
//                           </Box>
//                         </Box>
//                         <Popover
//                           open={currentHeader === header}
//                           anchorEl={anchorEl}
//                           onClose={handleClose}
//                           anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left'
//                           }}>
//                           <Box p={2}>
//                             <Typography variant="h6">Composite Unique Keys?</Typography>
//                             <List>
//                               {response.columnHeaders.map((headerOption) => (
//                                 <ListItem key={headerOption}>
//                                   <Checkbox
//                                     checked={uniqueKeys[header]?.includes(headerOption)}
//                                     onChange={(e) => {
//                                       const newSelected = e.target.checked
//                                         ? [...(uniqueKeys[header] || []), headerOption]
//                                         : uniqueKeys[header].filter(
//                                             (item) => item !== headerOption
//                                           );

//                                       if (uniqueKeys[headerOption]) {
//                                         setMovingHeader(headerOption);
//                                         setMovingToHeader(header);
//                                         setOpenDialog(true);
//                                       } else {
//                                         handleUniqueKeyChange(header, newSelected);
//                                       }
//                                     }}
//                                     style={{
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                   <ListItemText
//                                     primary={headerOption}
//                                     style={{
//                                       opacity: uniqueKeys[headerOption] ? 0.5 : 1,
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                 </ListItem>
//                               ))}
//                             </List>
//                             <Box mt={2} display="flex" justifyContent="flex-end">
//                               <Button variant="contained" onClick={saveUniqueKeys}>
//                                 Save
//                               </Button>
//                             </Box>
//                           </Box>
//                         </Popover>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                 </Grid>
//               ),
//               isNextDisabled: !isNextButtonEnabled(),
//               nextText: 'Next'
//             }
//           ]}
//         />
//       </Box>
//       <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
//         <DialogTitle>Move Header</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             The header "{movingHeader}" is already part of another group. Do you want to move it to
//             the current group?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => handleDialogClose(false)} color="primary">
//             No
//           </Button>
//           <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
//             Yes
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// 1111111111111111111111111111111111111111111111111111111111
// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   CircularProgress,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup,
//   Popover,
//   Checkbox,
//   ListItemText,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   List,
//   ListItem
// } from '@mui/material';
// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [viewMappingEnabled, setViewMappingEnabled] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [currentHeader, setCurrentHeader] = useState(null);
//   const [uniqueKeys, setUniqueKeys] = useState({});
//   const [colorMap, setColorMap] = useState({});
//   const [groupCounter, setGroupCounter] = useState(0);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [movingHeader, setMovingHeader] = useState(null);
//   const [movingToHeader, setMovingToHeader] = useState(null);

//   const colors = ['#FFC107', '#8BC34A', '#03A9F4', '#E91E63', '#9C27B0'];

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     setLoading(true);
//     setViewMappingEnabled(false);

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('excelValidation', '');
//     formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       setViewMappingEnabled(true);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   const handleClick = (event, header) => {
//     setAnchorEl(event.currentTarget);
//     setCurrentHeader(header);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     setCurrentHeader(null);
//   };

//   const handleUniqueKeyChange = (header, selectedHeaders) => {
//     const newUniqueKeys = { ...uniqueKeys };
//     const newColorMap = { ...colorMap };
//     let newGroup = false;
//     let groupColor = colors[groupCounter % colors.length];

//     selectedHeaders.forEach((selectedHeader) => {
//       if (uniqueKeys[header]?.includes(selectedHeader)) return;

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (key !== header && uniqueKeys[key].includes(selectedHeader)) {
//           setMovingHeader(selectedHeader);
//           setMovingToHeader(header);
//           setOpenDialog(true);
//         }
//       });

//       if (!newColorMap[selectedHeader]) {
//         newGroup = true;
//       }
//     });

//     if (newGroup) {
//       groupColor = colors[groupCounter % colors.length];
//       setGroupCounter((prev) => prev + 1);
//     } else {
//       groupColor = newColorMap[selectedHeaders[0]];
//     }

//     selectedHeaders.forEach((selectedHeader) => {
//       newColorMap[selectedHeader] = groupColor;
//     });

//     selectedHeaders.forEach((selectedHeader) => {
//       newUniqueKeys[selectedHeader] = selectedHeaders;
//     });

//     setUniqueKeys(newUniqueKeys);
//     setColorMap(newColorMap);
//   };

//   const saveUniqueKeys = () => {
//     handleClose();
//   };

//   const isNextButtonEnabled = () => {
//     const mappings = Object.values(columnMappings);
//     const hasEmptyMapping = mappings.includes('');
//     const hasDuplicateMapping = new Set(mappings).size !== mappings.length;

//     return !hasEmptyMapping && !hasDuplicateMapping;
//   };

//   const handleDialogClose = (confirm) => {
//     if (confirm) {
//       const updatedUniqueKeys = { ...uniqueKeys };
//       const updatedColorMap = { ...colorMap };

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (uniqueKeys[key].includes(movingHeader)) {
//           updatedUniqueKeys[key] = uniqueKeys[key].filter((item) => item !== movingHeader);
//         }
//       });

//       const groupColor = updatedColorMap[movingHeader];

//       if (!groupColor) {
//         const newGroupColor = colors[groupCounter % colors.length];
//         updatedColorMap[movingHeader] = newGroupColor;
//         setGroupCounter((prev) => prev + 1);
//       }

//       const newGroupHeaders = [...(updatedUniqueKeys[movingToHeader] || []), movingHeader];
//       newGroupHeaders.forEach((header) => {
//         updatedUniqueKeys[header] = newGroupHeaders;
//         updatedColorMap[header] = updatedColorMap[movingToHeader] || groupColor;
//       });

//       setUniqueKeys(updatedUniqueKeys);
//       setColorMap(updatedColorMap);
//     }

//     setMovingHeader(null);
//     setMovingToHeader(null);
//     setOpenDialog(false);
//   };

//   return (
//     <Box display="flex">
//       <SideBar page={'bulkProcessing'} />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginLeft: 20 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '150%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '150%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject || loading}
//                     sx={{ width: 30, mb: -10 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !viewMappingEnabled,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Unique
//                     </Typography>
//                   </Grid>
//                   {response.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Not Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Button variant="outlined" onClick={(e) => handleClick(e, header)}>
//                           Unique
//                           {uniqueKeys[header] && uniqueKeys[header].length > 0 && (
//                             <Typography
//                               variant="body2"
//                               color="textSecondary"
//                               component="span"
//                               style={{ color: colorMap[header] }}>
//                               {` (${uniqueKeys[header].join(', ')})`}
//                             </Typography>
//                           )}
//                         </Button>
//                         <Popover
//                           open={currentHeader === header}
//                           anchorEl={anchorEl}
//                           onClose={handleClose}
//                           anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left'
//                           }}>
//                           <Box p={2}>
//                             <Typography variant="h6">Composite Unique Keys?</Typography>
//                             <List>
//                               {response.columnHeaders.map((headerOption) => (
//                                 <ListItem key={headerOption}>
//                                   <Checkbox
//                                     checked={uniqueKeys[header]?.includes(headerOption)}
//                                     onChange={(e) => {
//                                       const newSelected = e.target.checked
//                                         ? [...(uniqueKeys[header] || []), headerOption]
//                                         : uniqueKeys[header].filter(
//                                             (item) => item !== headerOption
//                                           );

//                                       if (uniqueKeys[headerOption]) {
//                                         setMovingHeader(headerOption);
//                                         setMovingToHeader(header);
//                                         setOpenDialog(true);
//                                       } else {
//                                         handleUniqueKeyChange(header, newSelected);
//                                       }
//                                     }}
//                                     style={{
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                   <ListItemText
//                                     primary={headerOption}
//                                     style={{
//                                       opacity: uniqueKeys[headerOption] ? 0.5 : 1,
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                 </ListItem>
//                               ))}
//                             </List>
//                             <Box mt={2} display="flex" justifyContent="flex-end">
//                               <Button variant="contained" onClick={saveUniqueKeys}>
//                                 Save
//                               </Button>
//                             </Box>
//                           </Box>
//                         </Popover>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                 </Grid>
//               ),
//               isNextDisabled: !isNextButtonEnabled(),
//               nextText: 'Next'
//             }
//           ]}
//         />
//       </Box>
//       <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
//         <DialogTitle>Move Header</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             The header "{movingHeader}" is already part of another group. Do you want to move it to
//             the current group?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => handleDialogClose(false)} color="primary">
//             No
//           </Button>
//           <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
//             Yes
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// ++++++++++++++++++++++++++++++++++++++++
// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   CircularProgress,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup,
//   Popover,
//   Checkbox,
//   ListItemText,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   List,
//   ListItem
// } from '@mui/material';
// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [viewMappingEnabled, setViewMappingEnabled] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [currentHeader, setCurrentHeader] = useState(null);
//   const [uniqueKeys, setUniqueKeys] = useState({});
//   const [colorMap, setColorMap] = useState({});
//   const [groupCounter, setGroupCounter] = useState(0);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [movingHeader, setMovingHeader] = useState(null);
//   const [movingToHeader, setMovingToHeader] = useState(null);

//   const colors = ['#FFC107', '#8BC34A', '#03A9F4', '#E91E63', '#9C27B0'];

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     setLoading(true);
//     setViewMappingEnabled(false);

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('excelValidation', '');
//     formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       setViewMappingEnabled(true);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   const handleClick = (event, header) => {
//     setAnchorEl(event.currentTarget);
//     setCurrentHeader(header);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     setCurrentHeader(null);
//   };

//   const handleUniqueKeyChange = (header, selectedHeaders) => {
//     const newUniqueKeys = { ...uniqueKeys };
//     const newColorMap = { ...colorMap };
//     let newGroup = false;
//     let groupColor = colors[groupCounter % colors.length];

//     selectedHeaders.forEach((selectedHeader) => {
//       Object.keys(uniqueKeys).forEach((key) => {
//         if (key !== header && uniqueKeys[key].includes(selectedHeader)) {
//           setMovingHeader(selectedHeader);
//           setMovingToHeader(header);
//           setOpenDialog(true);
//         }
//       });

//       if (!newColorMap[selectedHeader]) {
//         newGroup = true;
//       }
//     });

//     if (newGroup) {
//       groupColor = colors[groupCounter % colors.length];
//       setGroupCounter((prev) => prev + 1);
//     } else {
//       groupColor = newColorMap[selectedHeaders[0]];
//     }

//     selectedHeaders.forEach((selectedHeader) => {
//       newColorMap[selectedHeader] = groupColor;
//     });

//     selectedHeaders.forEach((selectedHeader) => {
//       newUniqueKeys[selectedHeader] = selectedHeaders;
//     });

//     setUniqueKeys(newUniqueKeys);
//     setColorMap(newColorMap);
//   };

//   const saveUniqueKeys = () => {
//     handleClose();
//   };

//   const isNextButtonEnabled = () => {
//     const mappings = Object.values(columnMappings);
//     const hasEmptyMapping = mappings.includes('');
//     const hasDuplicateMapping = new Set(mappings).size !== mappings.length;

//     return !hasEmptyMapping && !hasDuplicateMapping;
//   };

//   const handleDialogClose = (confirm) => {
//     if (confirm) {
//       const updatedUniqueKeys = { ...uniqueKeys };
//       const updatedColorMap = { ...colorMap };

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (uniqueKeys[key].includes(movingHeader)) {
//           updatedUniqueKeys[key] = uniqueKeys[key].filter((item) => item !== movingHeader);
//         }
//       });

//       const groupColor = updatedColorMap[movingHeader];

//       if (!groupColor) {
//         const newGroupColor = colors[groupCounter % colors.length];
//         updatedColorMap[movingHeader] = newGroupColor;
//         setGroupCounter((prev) => prev + 1);
//       }

//       const newGroupHeaders = [...(updatedUniqueKeys[movingToHeader] || []), movingHeader];
//       newGroupHeaders.forEach((header) => {
//         updatedUniqueKeys[header] = newGroupHeaders;
//         updatedColorMap[header] = updatedColorMap[movingToHeader] || groupColor;
//       });

//       setUniqueKeys(updatedUniqueKeys);
//       setColorMap(updatedColorMap);
//     }

//     setMovingHeader(null);
//     setMovingToHeader(null);
//     setOpenDialog(false);
//   };

//   return (
//     <Box display="flex">
//       <SideBar page={'bulkProcessing'} />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginLeft: 20 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '150%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '150%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject || loading}
//                     sx={{ width: 30, mb: -10 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !viewMappingEnabled,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Unique
//                     </Typography>
//                   </Grid>
//                   {response.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Not Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Button variant="outlined" onClick={(e) => handleClick(e, header)}>
//                           Unique
//                           {uniqueKeys[header] && uniqueKeys[header].length > 0 && (
//                             <Typography
//                               variant="body2"
//                               color="textSecondary"
//                               component="span"
//                               style={{ color: colorMap[header] }}>
//                               {` (${uniqueKeys[header].join(', ')})`}
//                             </Typography>
//                           )}
//                         </Button>
//                         <Popover
//                           open={currentHeader === header}
//                           anchorEl={anchorEl}
//                           onClose={handleClose}
//                           anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left'
//                           }}>
//                           <Box p={2}>
//                             <Typography variant="h6">Composite Unique Keys?</Typography>
//                             <List>
//                               {response.columnHeaders.map((headerOption) => (
//                                 <ListItem key={headerOption}>
//                                   <Checkbox
//                                     checked={uniqueKeys[header]?.includes(headerOption)}
//                                     onChange={(e) => {
//                                       const newSelected = e.target.checked
//                                         ? [...(uniqueKeys[header] || []), headerOption]
//                                         : uniqueKeys[header].filter(
//                                             (item) => item !== headerOption
//                                           );

//                                       handleUniqueKeyChange(header, newSelected);
//                                     }}
//                                     style={{
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                   <ListItemText
//                                     primary={headerOption}
//                                     style={{
//                                       opacity: uniqueKeys[headerOption] ? 0.5 : 1,
//                                       color: colorMap[headerOption] || 'inherit'
//                                     }}
//                                   />
//                                 </ListItem>
//                               ))}
//                             </List>
//                             <Box mt={2} display="flex" justifyContent="flex-end">
//                               <Button variant="contained" onClick={saveUniqueKeys}>
//                                 Save
//                               </Button>
//                             </Box>
//                           </Box>
//                         </Popover>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                 </Grid>
//               ),
//               isNextDisabled: !isNextButtonEnabled(),
//               nextText: 'Next'
//             }
//           ]}
//         />
//       </Box>
//       <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
//         <DialogTitle>Move Header</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             The header "{movingHeader}" is already part of another group. Do you want to move it to
//             the current group?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => handleDialogClose(false)} color="primary">
//             No
//           </Button>
//           <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
//             Yes
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   CircularProgress,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup
// } from '@mui/material';
// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [viewMappingEnabled, setViewMappingEnabled] = useState(false);

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     setLoading(true);
//     setViewMappingEnabled(false);

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('excelValidation', '');
//     formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       setViewMappingEnabled(true);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   const isNextButtonEnabled = () => {
//     const mappings = Object.values(columnMappings);
//     const hasEmptyMapping = mappings.includes('');
//     const hasDuplicateMapping = new Set(mappings).size !== mappings.length;

//     return !hasEmptyMapping && !hasDuplicateMapping;
//   };

//   return (
//     <Box display="flex">
//       <SideBar page={'bulkProcessing'} />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginLeft: 20 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '150%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '150%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject || loading}
//                     sx={{ width: 30, mb: -10 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !viewMappingEnabled,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={4}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={4}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={4}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   {response.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={4}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={4}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Not Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                 </Grid>
//               ),
//               isNextDisabled: !isNextButtonEnabled(),
//               nextText: 'Next'
//             }
//           ]}
//         />
//       </Box>
//     </Box>
//   );
// }

// // =================
// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   CircularProgress,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup,
//   Popover,
//   Checkbox,
//   ListItemText,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle
// } from '@mui/material';
// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [viewMappingEnabled, setViewMappingEnabled] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [currentHeader, setCurrentHeader] = useState(null);
//   const [uniqueKeys, setUniqueKeys] = useState({});
//   const [colorMap, setColorMap] = useState({});
//   const [groupCounter, setGroupCounter] = useState(0);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [movingHeader, setMovingHeader] = useState(null);
//   const [movingToHeader, setMovingToHeader] = useState(null);

//   const colors = ['#FFC107', '#8BC34A', '#03A9F4', '#E91E63', '#9C27B0'];

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     setLoading(true);
//     setViewMappingEnabled(false);

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('excelValidation', '');
//     formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       setViewMappingEnabled(true);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   const handleClick = (event, header) => {
//     setAnchorEl(event.currentTarget);
//     setCurrentHeader(header);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     setCurrentHeader(null);
//   };

//   const handleUniqueKeyChange = (header, selectedHeaders) => {
//     const newUniqueKeys = { ...uniqueKeys };
//     const newColorMap = { ...colorMap };
//     let newGroup = false;
//     let groupColor = colors[groupCounter % colors.length];

//     selectedHeaders.forEach((selectedHeader) => {
//       Object.keys(uniqueKeys).forEach((key) => {
//         if (key !== header && uniqueKeys[key].includes(selectedHeader)) {
//           setMovingHeader(selectedHeader);
//           setMovingToHeader(header);
//           setOpenDialog(true);
//         }
//       });

//       if (!newColorMap[selectedHeader]) {
//         newGroup = true;
//       }
//     });

//     if (newGroup) {
//       groupColor = colors[groupCounter % colors.length];
//       setGroupCounter((prev) => prev + 1);
//     } else {
//       groupColor = newColorMap[selectedHeaders[0]];
//     }

//     selectedHeaders.forEach((selectedHeader) => {
//       newColorMap[selectedHeader] = groupColor;
//     });

//     selectedHeaders.forEach((selectedHeader) => {
//       newUniqueKeys[selectedHeader] = selectedHeaders;
//     });

//     setUniqueKeys(newUniqueKeys);
//     setColorMap(newColorMap);
//   };

//   const saveUniqueKeys = () => {
//     handleClose();
//   };

//   const isNextButtonEnabled = () => {
//     const mappings = Object.values(columnMappings);
//     const hasEmptyMapping = mappings.includes('');
//     const hasDuplicateMapping = new Set(mappings).size !== mappings.length;

//     return !hasEmptyMapping && !hasDuplicateMapping;
//   };

//   const handleDialogClose = (confirm) => {
//     if (confirm) {
//       const updatedUniqueKeys = { ...uniqueKeys };
//       const updatedColorMap = { ...colorMap };

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (uniqueKeys[key].includes(movingHeader)) {
//           updatedUniqueKeys[key] = uniqueKeys[key].filter((item) => item !== movingHeader);
//         }
//       });

//       const groupColor = updatedColorMap[movingHeader];

//       if (!groupColor) {
//         const newGroupColor = colors[groupCounter % colors.length];
//         updatedColorMap[movingHeader] = newGroupColor;
//         setGroupCounter((prev) => prev + 1);
//       }

//       const newGroupHeaders = [...(updatedUniqueKeys[movingToHeader] || []), movingHeader];
//       newGroupHeaders.forEach((header) => {
//         updatedUniqueKeys[header] = newGroupHeaders;
//         updatedColorMap[header] = updatedColorMap[movingToHeader] || groupColor;
//       });

//       setUniqueKeys(updatedUniqueKeys);
//       setColorMap(updatedColorMap);
//     }

//     setMovingHeader(null);
//     setMovingToHeader(null);
//     setOpenDialog(false);
//   };

//   return (
//     <Box display="flex">
//       <SideBar page={'bulkProcessing'} />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginLeft: 20 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '150%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '150%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject || loading}
//                     sx={{ width: 30, mb: -10 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !viewMappingEnabled,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Unique
//                     </Typography>
//                   </Grid>
//                   {response.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Not Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Button variant="outlined" onClick={(e) => handleClick(e, header)}>
//                           Unique
//                           {uniqueKeys[header] && uniqueKeys[header].length > 0 && (
//                             <Typography
//                               variant="body2"
//                               color="textSecondary"
//                               component="span"
//                               style={{ color: colorMap[header] }}>
//                               {` (${uniqueKeys[header].join(', ')})`}
//                             </Typography>
//                           )}
//                         </Button>
//                         <Popover
//                           open={currentHeader === header}
//                           anchorEl={anchorEl}
//                           onClose={handleClose}
//                           anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left'
//                           }}>
//                           <Box p={2}>
//                             <Typography variant="h6">Composite Unique Keys?</Typography>
//                             <FormControl fullWidth>
//                               <Select
//                                 multiple
//                                 value={uniqueKeys[header] || []}
//                                 onChange={(e) => handleUniqueKeyChange(header, e.target.value)}
//                                 renderValue={(selected) => selected.join(', ')}>
//                                 {response.columnHeaders.map((headerOption) => (
//                                   <MenuItem
//                                     key={headerOption}
//                                     value={headerOption}
//                                     style={{
//                                       color: colorMap[headerOption] || 'inherit',
//                                       opacity: uniqueKeys[headerOption] ? 0.5 : 1
//                                     }}>
//                                     <Checkbox
//                                       checked={uniqueKeys[header]?.includes(headerOption)}
//                                       style={{
//                                         color: colorMap[headerOption] || 'inherit'
//                                       }}
//                                     />
//                                     <ListItemText primary={headerOption} />
//                                   </MenuItem>
//                                 ))}
//                               </Select>
//                             </FormControl>
//                             <Box mt={2} display="flex" justifyContent="flex-end">
//                               <Button variant="contained" onClick={saveUniqueKeys}>
//                                 Save
//                               </Button>
//                             </Box>
//                           </Box>
//                         </Popover>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                 </Grid>
//               ),
//               isNextDisabled: !isNextButtonEnabled(),
//               nextText: 'Next'
//             }
//           ]}
//         />
//       </Box>
//       <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
//         <DialogTitle>Move Header</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             The header "{movingHeader}" is already part of another group. Do you want to move it to
//             the current group?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => handleDialogClose(false)} color="primary">
//             No
//           </Button>
//           <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
//             Yes
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   CircularProgress,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup,
//   Popover,
//   Checkbox,
//   ListItemText,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle
// } from '@mui/material';
// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [viewMappingEnabled, setViewMappingEnabled] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [currentHeader, setCurrentHeader] = useState(null);
//   const [uniqueKeys, setUniqueKeys] = useState({});
//   const [colorMap, setColorMap] = useState({});
//   const [groupCounter, setGroupCounter] = useState(0);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [movingHeader, setMovingHeader] = useState(null);
//   const [movingToHeader, setMovingToHeader] = useState(null);

//   const colors = ['#FFC107', '#8BC34A', '#03A9F4', '#E91E63', '#9C27B0'];

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     setLoading(true);
//     setViewMappingEnabled(false);

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('excelValidation', '');
//     formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       setViewMappingEnabled(true);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   const handleClick = (event, header) => {
//     setAnchorEl(event.currentTarget);
//     setCurrentHeader(header);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     setCurrentHeader(null);
//   };

//   const handleUniqueKeyChange = (header, selectedHeaders) => {
//     const newUniqueKeys = { ...uniqueKeys };
//     const newColorMap = { ...colorMap };

//     selectedHeaders.forEach((selectedHeader) => {
//       Object.keys(uniqueKeys).forEach((key) => {
//         if (key !== header && uniqueKeys[key].includes(selectedHeader)) {
//           setMovingHeader(selectedHeader);
//           setMovingToHeader(header);
//           setOpenDialog(true);
//         }
//       });

//       if (!newColorMap[selectedHeader]) {
//         const groupColor = colors[groupCounter % colors.length];
//         newColorMap[selectedHeader] = groupColor;
//         setGroupCounter((prev) => prev + 1);
//       }
//     });

//     newUniqueKeys[header] = selectedHeaders;
//     setUniqueKeys(newUniqueKeys);
//     setColorMap(newColorMap);
//   };

//   const saveUniqueKeys = () => {
//     handleClose();
//   };

//   const isNextButtonEnabled = () => {
//     const mappings = Object.values(columnMappings);
//     const hasEmptyMapping = mappings.includes('');
//     const hasDuplicateMapping = new Set(mappings).size !== mappings.length;

//     return !hasEmptyMapping && !hasDuplicateMapping;
//   };

//   const handleDialogClose = (confirm) => {
//     if (confirm) {
//       const updatedUniqueKeys = { ...uniqueKeys };
//       const updatedColorMap = { ...colorMap };

//       Object.keys(uniqueKeys).forEach((key) => {
//         if (uniqueKeys[key].includes(movingHeader)) {
//           updatedUniqueKeys[key] = uniqueKeys[key].filter((item) => item !== movingHeader);
//         }
//       });

//       if (!updatedColorMap[movingHeader]) {
//         const groupColor = colors[groupCounter % colors.length];
//         updatedColorMap[movingHeader] = groupColor;
//         setGroupCounter((prev) => prev + 1);
//       }

//       updatedUniqueKeys[movingToHeader] = [
//         ...(updatedUniqueKeys[movingToHeader] || []),
//         movingHeader
//       ];
//       setUniqueKeys(updatedUniqueKeys);
//       setColorMap(updatedColorMap);
//     }

//     setMovingHeader(null);
//     setMovingToHeader(null);
//     setOpenDialog(false);
//   };

//   return (
//     <Box display="flex">
//       <SideBar page={'bulkProcessing'} />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginLeft: 20 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '150%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '150%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject || loading}
//                     sx={{ width: 30, mb: -10 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !viewMappingEnabled,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Unique
//                     </Typography>
//                   </Grid>
//                   {response.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Not Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Button variant="outlined" onClick={(e) => handleClick(e, header)}>
//                           Unique
//                           {uniqueKeys[header] && uniqueKeys[header].length > 0 && (
//                             <Typography variant="body2" color="textSecondary" component="span">
//                               {` (${uniqueKeys[header].join(', ')})`}
//                             </Typography>
//                           )}
//                         </Button>
//                         <Popover
//                           open={currentHeader === header}
//                           anchorEl={anchorEl}
//                           onClose={handleClose}
//                           anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left'
//                           }}>
//                           <Box p={2}>
//                             <Typography variant="h6">Composite Unique Keys?</Typography>
//                             <FormControl fullWidth>
//                               <Select
//                                 multiple
//                                 value={uniqueKeys[header] || []}
//                                 onChange={(e) => handleUniqueKeyChange(header, e.target.value)}
//                                 renderValue={(selected) => selected.join(', ')}>
//                                 {response.columnHeaders.map((headerOption) => (
//                                   <MenuItem
//                                     key={headerOption}
//                                     value={headerOption}
//                                     style={{
//                                       color: colorMap[headerOption] || 'inherit',
//                                       opacity: uniqueKeys[headerOption] ? 0.5 : 1
//                                     }}>
//                                     <Checkbox
//                                       checked={uniqueKeys[header]?.includes(headerOption)}
//                                       style={{
//                                         color: colorMap[headerOption] || 'inherit'
//                                       }}
//                                     />
//                                     <ListItemText primary={headerOption} />
//                                   </MenuItem>
//                                 ))}
//                               </Select>
//                             </FormControl>
//                             <Box mt={2} display="flex" justifyContent="flex-end">
//                               <Button variant="contained" onClick={saveUniqueKeys}>
//                                 Save
//                               </Button>
//                             </Box>
//                           </Box>
//                         </Popover>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                 </Grid>
//               ),
//               isNextDisabled: !isNextButtonEnabled(),
//               nextText: 'Next'
//             }
//           ]}
//         />
//       </Box>
//       <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
//         <DialogTitle>Move Header</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             The header "{movingHeader}" is already part of another group. Do you want to move it to
//             the current group?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => handleDialogClose(false)} color="primary">
//             No
//           </Button>
//           <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
//             Yes
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   CircularProgress,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup,
//   Popover,
//   Checkbox,
//   ListItemText
// } from '@mui/material';
// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';
// import { v4 as uuidv4 } from 'uuid';
// import { red, blue, green, purple, orange } from '@mui/material/colors';

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [viewMappingEnabled, setViewMappingEnabled] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [currentHeader, setCurrentHeader] = useState(null);
//   const [uniqueKeys, setUniqueKeys] = useState({});
//   const [groups, setGroups] = useState({});
//   const colors = [red[500], blue[500], green[500], purple[500], orange[500]];

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     setLoading(true);
//     setViewMappingEnabled(false);

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('excelValidation', '');
//     formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       setViewMappingEnabled(true);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   const handleClick = (event, header) => {
//     setAnchorEl(event.currentTarget);
//     setCurrentHeader(header);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     setCurrentHeader(null);
//   };

//   const handleUniqueKeyChange = (header, selectedHeaders) => {
//     setUniqueKeys((prev) => ({ ...prev, [header]: selectedHeaders }));
//   };

//   const saveUniqueKeys = () => {
//     const currentGroupHeaders = uniqueKeys[currentHeader];
//     const newGroupID = uuidv4();
//     const newGroups = { ...groups };
//     let removeFromGroups = [];

//     // Remove the currentHeader from all groups
//     Object.keys(newGroups).forEach((groupID) => {
//       newGroups[groupID] = newGroups[groupID].filter((item) => item !== currentHeader);
//       if (newGroups[groupID].length === 0) removeFromGroups.push(groupID);
//     });

//     // Remove empty groups
//     removeFromGroups.forEach((groupID) => delete newGroups[groupID]);

//     // Add the current header and selected headers to a new group
//     if (currentGroupHeaders.length > 0) {
//       newGroups[newGroupID] = currentGroupHeaders;
//     }

//     setGroups(newGroups);

//     // Reflect the changes across all selected headers
//     const updatedUniqueKeys = { ...uniqueKeys };
//     currentGroupHeaders.forEach((header) => {
//       updatedUniqueKeys[header] = currentGroupHeaders;
//     });

//     setUniqueKeys(updatedUniqueKeys);

//     handleClose();
//   };

//   const handleHeaderSelection = (headerOption) => {
//     const newHeaders = uniqueKeys[currentHeader] || [];
//     const headerInOtherGroup = Object.keys(groups).find((groupID) =>
//       groups[groupID].includes(headerOption)
//     );

//     if (headerInOtherGroup) {
//       if (
//         window.confirm(
//           `${headerOption} is already in a different group. Do you want to move it to the new group?`
//         )
//       ) {
//         const updatedGroups = { ...groups };
//         updatedGroups[headerInOtherGroup] = updatedGroups[headerInOtherGroup].filter(
//           (item) => item !== headerOption
//         );

//         if (updatedGroups[headerInOtherGroup].length === 0) {
//           delete updatedGroups[headerInOtherGroup];
//         }

//         setGroups(updatedGroups);

//         setUniqueKeys((prev) => ({
//           ...prev,
//           [currentHeader]: [...newHeaders, headerOption]
//         }));
//       }
//     } else {
//       setUniqueKeys((prev) => ({
//         ...prev,
//         [currentHeader]: [...newHeaders, headerOption]
//       }));
//     }
//   };

//   const isNextButtonEnabled = () => {
//     const mappings = Object.values(columnMappings);
//     const hasEmptyMapping = mappings.includes('');
//     const hasDuplicateMapping = new Set(mappings).size !== mappings.length;

//     return !hasEmptyMapping && !hasDuplicateMapping;
//   };

//   return (
//     <Box display="flex">
//       <SideBar page={'bulkProcessing'} />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginLeft: 20 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '150%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '150%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject || loading}
//                     sx={{ width: 30, mb: -10 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !viewMappingEnabled,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Unique
//                     </Typography>
//                   </Grid>
//                   {response.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Not Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Button variant="outlined" onClick={(e) => handleClick(e, header)}>
//                           Unique
//                           {uniqueKeys[header] && uniqueKeys[header].length > 0 && (
//                             <Typography
//                               variant="body2"
//                               color="textSecondary"
//                               component="span"
//                               style={{
//                                 color: Object.keys(groups).find((groupID) =>
//                                   groups[groupID].includes(header)
//                                 )
//                                   ? colors[
//                                       Object.keys(groups).indexOf(
//                                         Object.keys(groups).find((groupID) =>
//                                           groups[groupID].includes(header)
//                                         )
//                                       ) % colors.length
//                                     ]
//                                   : 'inherit'
//                               }}>
//                               {` (${uniqueKeys[header].join(', ')})`}
//                             </Typography>
//                           )}
//                         </Button>
//                         <Popover
//                           open={currentHeader === header}
//                           anchorEl={anchorEl}
//                           onClose={handleClose}
//                           anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left'
//                           }}>
//                           <Box p={2}>
//                             <Typography variant="h6">Composite Unique Keys?</Typography>
//                             <FormControl fullWidth>
//                               <Select
//                                 multiple
//                                 value={uniqueKeys[currentHeader] || []}
//                                 onChange={(e) =>
//                                   handleUniqueKeyChange(currentHeader, e.target.value)
//                                 }
//                                 renderValue={(selected) => selected.join(', ')}>
//                                 {response.columnHeaders.map((headerOption) => {
//                                   const groupID = Object.keys(groups).find((groupID) =>
//                                     groups[groupID].includes(headerOption)
//                                   );
//                                   const color = groupID
//                                     ? colors[Object.keys(groups).indexOf(groupID) % colors.length]
//                                     : 'inherit';
//                                   return (
//                                     <MenuItem
//                                       key={headerOption}
//                                       value={headerOption}
//                                       style={{
//                                         color: groupID ? color : 'inherit',
//                                         opacity: groupID && groupID !== currentHeader ? 0.5 : 1
//                                       }}
//                                       disabled={groupID && groupID !== currentHeader}>
//                                       <Checkbox
//                                         checked={
//                                           uniqueKeys[currentHeader]?.includes(headerOption) || false
//                                         }
//                                       />
//                                       <ListItemText primary={headerOption} />
//                                     </MenuItem>
//                                   );
//                                 })}
//                               </Select>
//                             </FormControl>
//                             <Box mt={2} display="flex" justifyContent="flex-end">
//                               <Button variant="contained" onClick={saveUniqueKeys}>
//                                 Save
//                               </Button>
//                             </Box>
//                           </Box>
//                         </Popover>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                 </Grid>
//               ),
//               isNextDisabled: !isNextButtonEnabled(),
//               nextText: 'Next'
//             }
//           ]}
//         />
//       </Box>
//     </Box>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   CircularProgress,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup,
//   Popover,
//   Checkbox,
//   ListItemText
// } from '@mui/material';
// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

// const groupColors = ['#FF5733 ', ' #FFFF00', '#00FF00', '	#800080'];

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [viewMappingEnabled, setViewMappingEnabled] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [currentHeader, setCurrentHeader] = useState(null);
//   const [uniqueKeys, setUniqueKeys] = useState({});
//   const [headerGroups, setHeaderGroups] = useState([]);

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     setLoading(true);
//     setViewMappingEnabled(false);

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('excelValidation', '');
//     formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       setViewMappingEnabled(true);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   const handleClick = (event, header) => {
//     setAnchorEl(event.currentTarget);
//     setCurrentHeader(header);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     setCurrentHeader(null);
//   };

//   const handleUniqueKeyChange = (header, selectedHeaders) => {
//     setUniqueKeys((prev) => ({ ...prev, [header]: selectedHeaders }));

//     // Create a new group or update an existing group
//     setHeaderGroups((prevGroups) => {
//       const existingGroupIndex = prevGroups.findIndex((group) => group.includes(header));

//       if (existingGroupIndex !== -1) {
//         // Update the existing group
//         const updatedGroups = [...prevGroups];
//         updatedGroups[existingGroupIndex] = selectedHeaders;
//         return updatedGroups;
//       } else {
//         // Create a new group
//         return [...prevGroups, selectedHeaders];
//       }
//     });
//   };

//   const saveUniqueKeys = () => {
//     handleClose();
//   };

//   const isNextButtonEnabled = () => {
//     const mappings = Object.values(columnMappings);
//     const hasEmptyMapping = mappings.includes('');
//     const hasDuplicateMapping = new Set(mappings).size !== mappings.length;

//     return !hasEmptyMapping && !hasDuplicateMapping;
//   };

//   return (
//     <Box display="flex">
//       <SideBar page={'bulkProcessing'} />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginLeft: 20 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '150%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '150%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject || loading}
//                     sx={{ width: 30, mb: -10 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !viewMappingEnabled,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Unique
//                     </Typography>
//                   </Grid>
//                   {response.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Not Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Button variant="outlined" onClick={(e) => handleClick(e, header)}>
//                           Unique
//                           {headerGroups
//                             .filter((group) => group.includes(header))
//                             .flat()
//                             .join(', ')}
//                         </Button>
//                         <Popover
//                           open={currentHeader === header}
//                           anchorEl={anchorEl}
//                           onClose={handleClose}
//                           anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left'
//                           }}>
//                           <Box p={2}>
//                             <Typography variant="h6">Composite Unique Keys?</Typography>
//                             <FormControl fullWidth>
//                               <Select
//                                 multiple
//                                 value={uniqueKeys[header] || []}
//                                 onChange={(e) => handleUniqueKeyChange(header, e.target.value)}
//                                 renderValue={(selected) => selected.join(', ')}>
//                                 {response.columnHeaders.map((headerOption) => (
//                                   <MenuItem key={headerOption} value={headerOption}>
//                                     <Checkbox
//                                       checked={uniqueKeys[header]?.includes(headerOption)}
//                                       sx={{
//                                         color:
//                                           headerGroups.findIndex((group) =>
//                                             group.includes(headerOption)
//                                           ) >= 0
//                                             ? groupColors[
//                                                 headerGroups.findIndex((group) =>
//                                                   group.includes(headerOption)
//                                                 )
//                                               ]
//                                             : 'default'
//                                       }}
//                                     />
//                                     <ListItemText primary={headerOption} />
//                                   </MenuItem>
//                                 ))}
//                               </Select>
//                             </FormControl>
//                             <Box mt={2} display="flex" justifyContent="flex-end">
//                               <Button variant="contained" onClick={saveUniqueKeys}>
//                                 Save
//                               </Button>
//                             </Box>
//                           </Box>
//                         </Popover>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                 </Grid>
//               ),
//               isNextDisabled: !isNextButtonEnabled(),
//               nextText: 'Next'
//             }
//           ]}
//         />
//       </Box>
//     </Box>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   CircularProgress,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup,
//   Popover,
//   Checkbox,
//   ListItemText
// } from '@mui/material';
// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [viewMappingEnabled, setViewMappingEnabled] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [currentHeader, setCurrentHeader] = useState(null);
//   const [uniqueKeys, setUniqueKeys] = useState({});

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     setLoading(true);
//     setViewMappingEnabled(false);

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('excelValidation', '');
//     formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       setViewMappingEnabled(true);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   const handleClick = (event, header) => {
//     setAnchorEl(event.currentTarget);
//     setCurrentHeader(header);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     setCurrentHeader(null);
//   };

//   const handleUniqueKeyChange = (header, selectedHeaders) => {
//     setUniqueKeys((prev) => ({ ...prev, [header]: selectedHeaders }));
//   };

//   const saveUniqueKeys = () => {
//     handleClose();
//   };

//   const isNextButtonEnabled = () => {
//     const mappings = Object.values(columnMappings);
//     const hasEmptyMapping = mappings.includes('');
//     const hasDuplicateMapping = new Set(mappings).size !== mappings.length;

//     return !hasEmptyMapping && !hasDuplicateMapping;
//   };

//   return (
//     <Box display="flex">
//       <SideBar page={'bulkProcessing'} />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginLeft: 20 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '150%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '150%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject || loading}
//                     sx={{ width: 30, mb: -10 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !viewMappingEnabled,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <Typography variant="h6" gutterBottom>
//                       Unique
//                     </Typography>
//                   </Grid>
//                   {response.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Not Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Button variant="outlined" onClick={(e) => handleClick(e, header)}>
//                           Unique
//                           {uniqueKeys[header] && uniqueKeys[header].length > 0 && (
//                             <Typography variant="body2" color="textSecondary" component="span">
//                               {` (${uniqueKeys[header].join(', ')})`}
//                             </Typography>
//                           )}
//                         </Button>
//                         <Popover
//                           open={currentHeader === header}
//                           anchorEl={anchorEl}
//                           onClose={handleClose}
//                           anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left'
//                           }}>
//                           <Box p={2}>
//                             <Typography variant="h6">Composite Unique Keys?</Typography>
//                             <FormControl fullWidth>
//                               <Select
//                                 multiple
//                                 value={uniqueKeys[header] || []}
//                                 onChange={(e) => handleUniqueKeyChange(header, e.target.value)}
//                                 renderValue={(selected) => selected.join(', ')}>
//                                 {response.columnHeaders.map((headerOption) => (
//                                   <MenuItem key={headerOption} value={headerOption}>
//                                     <Checkbox
//                                       checked={uniqueKeys[header]?.includes(headerOption)}
//                                     />
//                                     <ListItemText primary={headerOption} />
//                                   </MenuItem>
//                                 ))}
//                               </Select>
//                             </FormControl>
//                             <Box mt={2} display="flex" justifyContent="flex-end">
//                               <Button variant="contained" onClick={saveUniqueKeys}>
//                                 Save
//                               </Button>
//                             </Box>
//                           </Box>
//                         </Popover>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                 </Grid>
//               ),
//               isNextDisabled: !isNextButtonEnabled(),
//               nextText: 'Next'
//             }
//           ]}
//         />
//       </Box>
//     </Box>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   CircularProgress,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup
// } from '@mui/material';
// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [viewMappingEnabled, setViewMappingEnabled] = useState(false);

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     setLoading(true);
//     setViewMappingEnabled(false);

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('excelValidation', '');
//     formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       setViewMappingEnabled(true);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   return (
//     <Box display="flex">
//       <SideBar
//         page={'bulkProcessing'}
//         // onMenuItemClick={onMenuItemClick}
//         // currentContext={{ features: '' }}
//       />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginLeft: 20 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '150%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '150%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject || loading}
//                     sx={{ width: 30, mb: -10 }}>
//                     {loading ? <CircularProgress size={24} /> : 'Submit'}
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !viewMappingEnabled,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={4}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={4}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={4}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   {response.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={4}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={4}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Not Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                 </Grid>
//               ),
//               isNextDisabled: response.columnHeaders.length === 0,
//               nextText: 'Next'
//             }
//           ]}
//         />
//       </Box>
//     </Box>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import {
//   Button,
//   TextField,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup
// } from '@mui/material';
// import keycloak from '../../Keycloak';
// import SideBar from '../ApplicationV2/component/SideBar';
// import LadderStepForm from '../stepForms/ladderStepForm/components/LadderStepForm';

// export default function ExcelMapping() {
//   const { app_code } = useParams();
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState('');
//   const [dataObject, setDataObject] = useState('');
//   const [variableData, setVariableData] = useState([]);
//   const [response, setResponse] = useState({
//     columnHeaders: [],
//     dataObjectColumns: [],
//     mappingSuggestion: []
//   });
//   const [columnMappings, setColumnMappings] = useState({});
//   const [mandatoryFields, setMandatoryFields] = useState({});

//   useEffect(() => {
//     const fetchVariableGridData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`,
//           {
//             params: { appCode: app_code },
//             headers: { Authorization: `Bearer ${keycloak.token()}` }
//           }
//         );
//         const filteredData = response.data.dataObjects.filter(
//           (obj) => obj.objectType === 'DATA_OBJECT'
//         );
//         setVariableData(filteredData);
//       } catch (err) {
//         setError('Failed to fetch variable data: ' + err.message);
//         console.error('Error fetching variable data:', err);
//       }
//     };
//     fetchVariableGridData();
//   }, [app_code]);

//   useEffect(() => {
//     if (response.mappingSuggestion) {
//       const initialMappings = {};
//       response.mappingSuggestion.forEach((sug) => {
//         initialMappings[sug.excelColumn] = sug.dbColumn || '';
//       });
//       setColumnMappings(initialMappings);
//     }
//   }, [response.mappingSuggestion]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setError('');
//   };

//   const handleMappingChange = (excelColumn, value) => {
//     setColumnMappings((prev) => ({ ...prev, [excelColumn]: value }));
//   };

//   const handleMandatoryFieldChange = (header, newValue) => {
//     setMandatoryFields((prev) => ({ ...prev, [header]: newValue }));
//   };

//   const handleSubmit = async () => {
//     if (!file) {
//       setError('Please select a file to upload.');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('objectId', dataObject);
//     formData.append('excelValidation', '');
//     formData.append('token', keycloak.token());

//     try {
//       const response = await axios.post('http://192.168.1.83:3000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${keycloak.token()}`
//         }
//       });
//       setResponse(response.data);
//       console.log('File uploaded successfully', response.data);
//     } catch (err) {
//       setError(`Failed to upload file: ${err.message}`);
//       console.error('Error uploading file:', err);
//     }
//   };

//   const handleDataObject = (e) => {
//     setDataObject(e.target.value);
//   };

//   return (
//     <Box display="flex">
//       <SideBar
//         page={'bulkProcessing'}
//         // onMenuItemClick={onMenuItemClick}
//         // currentContext={{ features: '' }}
//       />
//       <Box flex="1" p={10} bgcolor={'#F4F3FA'}>
//         <Typography variant="h4" gutterBottom>
//           Excel Mapping
//         </Typography>
//         <LadderStepForm
//           elements={[
//             {
//               title: 'Upload Excel File',
//               child: (
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   p={2}
//                   sx={{ maxWidth: 800, margin: 'auto', marginLeft: 20 }}>
//                   <Box
//                     sx={{
//                       border: '2px dashed #ccc',
//                       borderRadius: '8px',
//                       padding: '40px',
//                       width: '150%',
//                       textAlign: 'center',
//                       cursor: 'pointer',
//                       mb: 2,
//                       backgroundColor: '#fafafa'
//                     }}
//                     onClick={() => document.getElementById('file-input').click()}>
//                     <input
//                       id="file-input"
//                       type="file"
//                       onChange={handleFileChange}
//                       style={{ display: 'none' }}
//                     />
//                     {!file && (
//                       <Typography variant="h6" color="textSecondary">
//                         Drop an Excel file here, or click to select a file
//                       </Typography>
//                     )}
//                     {file && (
//                       <Typography variant="body1" color="textPrimary" fontWeight={900}>
//                         File: {file.name}
//                       </Typography>
//                     )}
//                   </Box>
//                   <FormControl sx={{ width: '150%', mb: 2 }}>
//                     <InputLabel id="data-object-label">Data Object</InputLabel>
//                     <Select
//                       labelId="data-object-label"
//                       id="data-object-select"
//                       value={dataObject}
//                       label="Data Object"
//                       onChange={handleDataObject}>
//                       {variableData.map((item) => (
//                         <MenuItem key={item.dataObjectId} value={item.dataObjectId}>
//                           {item.code}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSubmit}
//                     disabled={!file || !dataObject}
//                     sx={{ width: 30, mb: -10 }}>
//                     Submit
//                   </Button>
//                   {error && (
//                     <Typography variant="body2" color="error" mb={2}>
//                       {error}
//                     </Typography>
//                   )}
//                 </Box>
//               ),
//               isNextDisabled: !file || !dataObject,
//               nextText: 'View Mapping'
//             },
//             {
//               title: 'Excel Header and Mapping',
//               child: (
//                 <Grid container spacing={2} sx={{ width: '100%' }}>
//                   <Grid item xs={4}>
//                     <Typography variant="h6" gutterBottom>
//                       Excel Header
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={4}>
//                     <Typography variant="h6" gutterBottom>
//                       Mapping Suggestions
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={4}>
//                     <Typography variant="h6" gutterBottom>
//                       Mandatory
//                     </Typography>
//                   </Grid>
//                   {response.columnHeaders.map((header) => (
//                     <React.Fragment key={header}>
//                       <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Typography variant="body1">{header}</Typography>
//                       </Grid>
//                       <Grid item xs={4}>
//                         <FormControl sx={{ width: '80%' }}>
//                           <InputLabel>Mapping</InputLabel>
//                           <Select
//                             sx={{ height: '20%' }}
//                             value={columnMappings[header] || ''}
//                             onChange={(e) => handleMappingChange(header, e.target.value)}
//                             label="Mapping">
//                             {response.dataObjectColumns.map((col) => (
//                               <MenuItem key={`${header}-${col}`} value={col}>
//                                 {col}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </Grid>
//                       <Grid item xs={4}>
//                         <ToggleButtonGroup
//                           color="primary"
//                           value={mandatoryFields[header] || 'Required'}
//                           exclusive
//                           onChange={(e, newValue) => handleMandatoryFieldChange(header, newValue)}
//                           aria-label="text alignment">
//                           <ToggleButton value="Required" aria-label="required">
//                             Required
//                           </ToggleButton>
//                           <ToggleButton value="Not Required" aria-label="not required">
//                             Not Required
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </Grid>
//                     </React.Fragment>
//                   ))}
//                 </Grid>
//               ),
//               isNextDisabled: response.columnHeaders.length === 0,
//               nextText: 'Next'
//             }
//           ]}
//         />
//       </Box>
//     </Box>
//   );
// }
