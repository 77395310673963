import request from 'superagent';
import keycloak from '../Keycloak';

const fetchSecondaryMappedData = async  (payload) => {
    const res = await request
      .post(`${process.env.REACT_APP_GENRIC_API}/object-mapping/mapping`)
      .send(payload)
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };

export default fetchSecondaryMappedData;