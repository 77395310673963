import React from 'react';
import SideBar from '../../ApplicationV2/component/SideBar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dashboardContext } from '../../dashboard/slices/dashboardSlice';
import HlfExplorerGridContainer from './HlfExplorerGridContainer';
import GenericModalContainer from '../../modals/genericModal/container';
import DigitIdModalContainer from '../../auditLog/containers/DigitIdModalContainer';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import BlockHashModalContainer from './BlockHashModalContainer';
import HlfTxnModalContainer from './HlfTxnModalContainer';

const HlfExplorerContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  dispatch(dashboardContext({ contextName: 'HLF Networks', context: 'HLF' }));
  let currentContext = useSelector((store) => store.store.dashboard.currentContext);

  const onMenuItemClick = ({ contextName, title }) => {
    switch (title) {
      case 'HLF Networks': {
        navigate('/hlf');
        break;
      }
      case 'Application': {
        navigate('/dashboard');
        break;
      }
      case 'New Network': {
        navigate('/hlf/new');
        break;
      }
      case 'HLF Explorer': {
        navigate('/hlfExplorer');
        break;
      }
    }
  };

  currentContext = {
    ...currentContext,
    HLF: 'HLF Explorer'
  };

  const onBlockHashClick = (data) => {
    console.log(data, 'pppppppppppppppppppppp');
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.blockHashModal,
        isOpen: true,
        modalDataKey: 'blockHashModalData',
        modalData: data
      })
    );
  };

  const onHlfTxnClick = (data) => {
    console.log(data, 'pppppppppppppppppppppp');
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.hlfTxnModal,
        isOpen: true,
        modalDataKey: 'hlfTxnModalData',
        modalData: data
      })
    );
  };

  const onCloseModal = () => {
    console.log('hhheeeeee')
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.blockHashModal,
        isOpen: false
      })
    );
  };

  return (
    <div className="w-full min-h-full flex bg-[#F4F3FA]">
      <SideBar page="HLF" onMenuItemClick={onMenuItemClick} currentContext={currentContext} />
      <HlfExplorerGridContainer onBlockHashClick={onBlockHashClick} onHlfTxnClick={onHlfTxnClick} />
      <GenericModalContainer modalKey={ModalKeys.blockHashModal} bgClr={`bg-white`}>
        <BlockHashModalContainer onCloseModal={onCloseModal} />
      </GenericModalContainer>
      <GenericModalContainer modalKey={ModalKeys.hlfTxnModal} bgClr={`bg-white`}>
        <HlfTxnModalContainer onCloseModal={onCloseModal} />
      </GenericModalContainer>
    </div>
  );
};

export default HlfExplorerContainer;
