import React, { useState, useRef, useCallback, useMemo } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  MiniMap,
  removeElements,
  getOutgoers,
  EdgeText,
  MarkerType
} from 'reactflow';
import 'reactflow/dist/style.css';

import '../style.css';
import Toolbox from '../component/Toolbox';
import UnderDevelopmentPage from '../../../utils/UnderDevelopmentPage';
import CanvasLeftNode from '../component/CanvasLeftNode';
import RightSideBar from './RightSideBar';
import CustomInputNode from './CustomInputNode';
import { AppBar, Box, Dialog, Toolbar, Typography } from '@mui/material';
import { getConnectedEdges, getIncomers } from 'react-flow-renderer';
import { useLocation } from 'react-router-dom';
import CustomHandle from '../component/CustomHandle';
import CustomEdge from '../component/CustomEdge';
import FormSection from './FormSection';
import { removeDeletedNode

 } from './FlowSlice';
 import { useDispatch } from 'react-redux';

const initialNodes = [];

// const LeftCustomNode = ({ data }) => {
//   return <CanvasLeftNode data={data} />;
// };

const nodeTypes = { leftCustom: CustomHandle };
const edgeTypes = {
  custom: CustomEdge
};

const DnDFlow = () => {
  const reactFlowWrapper = useRef(null);
  const [elements, setElements] = useState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const location = useLocation();
  const payload = location.state;
  const dispatch = useDispatch()

  // const nodeTypes = useMemo(() => ({ textUpdater:CustomHandle  }), []);

  const [showStart, setShowStart] = useState(false);

  const onConnect = useCallback(
    (params) => {
      const edge = { ...params, type: 'custom' };
      setEdges((eds) => addEdge(edge, eds));
    },
    [setEdges]
  );

  // const onElementsRemove = (elementsToRemove) => {
  //   setElements((els) => removeElements(elementsToRemove, els));
  // };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);
  let id = 0;
  const getId = (nodeId) => `${nodeId}${id++}`;

  const onNodesDelete = useCallback(
    (deleted) => {
      console.log(deleted)
      dispatch(removeDeletedNode(deleted));
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      // Parse the node data from the dropped item
      const nodeData = JSON.parse(event.dataTransfer.getData('application/node'));

      // Log the node label for debugging
    

      if (nodes.length == 0 && nodeData.label.toLowerCase() !== 'start') {
        alert('WorkFlow First Node should be Start.');
      }

      // Check if 'showStart' is true and the dropped node is a 'start' node
      else if (showStart && nodeData.label.toLowerCase() === 'start') {
        // If a 'start' node is already present, show an alert
        alert('Only one Start node is allowed');
      } else {
        // Check if the dropped node is a 'start' node and update 'showStart' accordingly
        if (nodeData.label.toLowerCase() === 'start') {
          setShowStart(true);
        }

        // Get the type of the dropped node
        const type = event.dataTransfer.getData('application/reactflow');
        console.log("Type of dropped node is ...",type)

        // If the type is undefined or empty, return
        if (typeof type === 'undefined' || !type) {
          return;
        }

        // Convert the screen position to flow position
        const position = reactFlowInstance.screenToFlowPosition({
          x: event.clientX,
          y: event.clientY
        });

        // Create a new node object
        const newNode = {
          id: getId(nodeData.data.id),
          type,
          position,
          data: nodeData
        };

        // Update the nodes array with the new node
        setNodes((nds) => nds.concat(newNode));
      }
    },
    [reactFlowInstance, showStart]
  );

  

  const jsonData = {
    nodeData: nodes,
    edgeData: edges
  };

  const downloadJson = () => {
    const jsonString = JSON.stringify(jsonData, null, 2);

    const blob = new Blob([jsonString], { type: 'application/json' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = 'data.json';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const [selectedNodes, setSelectNodes] = useState({});
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#4c6fff',
          width: '100vw',
          height: '50px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
        {/* <Toolbar> */}
        <Typography sx={{ color: 'white', ml: 5, fontWeight: 'bold' }}>
          Workflow Name : {payload.metaData.metaname ? payload.metaData.metaname : 'New WorkFlow'}
        </Typography>
        {/* </Toolbar> */}
      </Box>
      <Box className="dndflow">
        <ReactFlowProvider>
          <Toolbox />
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onNodesDelete={onNodesDelete}
              onDrop={onDrop}
              onDragOver={onDragOver}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              connectionLineType={'smoothstep'}
              onNodeClick={(event, node) => {
                console.log('node', node);
                setOpen(true); setSelectNodes(node);
              }}
              fitView>
              <Controls />
              <EdgeText />
              <Background color="#ccc" variant="cross" />
            </ReactFlow>
          </div>
          {/* <div>
            <RightSideBar edges={edges} data={jsonData} type={'new'} pay={payload} />
          </div> */}
          {open && (
            <div style={{ width: '400px' }}>
              <FormSection selectedNodes={selectedNodes} />
            </div>
          )}
        </ReactFlowProvider>
      </Box>
    </>
  );
};

export default DnDFlow;
