import React from 'react';
import SubheaderElement from './SubheaderElement';

const Subheader = ({ subHeaderSchema = [] }) => {
  return (
    <div className="flex mt-4 w-[400px] bg-[#E4E4E5] justify-between text-[24px] rounded-[12px] shadow-lg ml-8">
      {subHeaderSchema.map((element) => {
        return <SubheaderElement subheaderData={element} />;
      })}
    </div>
  );
};

export default Subheader;
