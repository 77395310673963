import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import AuditLogObjectsGrid from '../components/AuditLogObjectsGrid';
import { setObjectAuditLogGridData } from '../slices/AuditLogSlice';
import { fetchObjectAuditLogData } from '../../../services/auditLogData';
import { noop } from 'lodash';
import { useParams } from 'react-router-dom';

const ObjectAuditLogGridContainer = ({ onDigitIdClick = noop, onObjectClick = noop }) => {
  const dispatch = useDispatch();
  const { app_code = '' } = useParams();
  const storeObjectTimeFilterData =
    useSelector((store) => store?.store?.auditLog?.objectTimeFilter) || {};
  const { startDate = '', startTime = '', endDate = '', endTime } = storeObjectTimeFilterData;
  function combineDateAndTime(dateInput = '', timeInput = '') {
    const combinedDateTime = `${dateInput}T${timeInput}:00.000Z`;
    if (dateInput && timeInput) {
      return new Date(combinedDateTime).toISOString();
    }
    return '';
  }
  const starting = combineDateAndTime(startDate, startTime);
  const ending = combineDateAndTime(endDate, endTime);
  const { refetch } = useQuery(
    'object-audit-data',
    fetchObjectAuditLogData({
      type: 'creation',
      app_code: app_code,
      startDate: starting,
      endDate: ending,
      subType: 'dataobject'
    }),
    {
      enabled: false,
      refetchOnWindowFocus: true
    }
  );
  const storeObjectAuditData = useSelector((store) => store?.store?.auditLog?.objectGridData);

  const fetchObjectAuditData = async () => {
    const refetchData = await refetch();
    const { data, isSuccess } = refetchData;
    if (isSuccess) {
      dispatch(setObjectAuditLogGridData({ auditData: data?.body?.data?.auditData }));
    }
    return data?.body?.featureObjects;
  };

  return (
    <AuditLogObjectsGrid
      fetchObjectAuditData={fetchObjectAuditData}
      objectAuditData={storeObjectAuditData}
      onDigitIdClick={onDigitIdClick}
      onObjectClick={onObjectClick}
    />
  );
};

export default ObjectAuditLogGridContainer;
