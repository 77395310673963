import FormContainer from '../../common/form/container';
import { isEmpty, noop } from 'lodash';
import formKeys from '../../common/form/constants/formKeys';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import modalKeys from '../../modals/genericModal/constants/GenericModalKeys';

import {
  initializeFormData,
  updateFormData,
  updateFormState
} from '../../common/form/slices/formSlice';
import queryString from 'query-string';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import fetchTargetObjectListData from '../../../services/fetchSourceObjectListData';
import fetchNewFormData from '../../../services/fetchNewFormData';
import newObjectMappingSchema from '../../../schema/form_schema/newObjectMappingSchema';
import NewFormMapping from './NewFormMapping';
import NewFormAccordian from '../../objectMappingV2/components/NewFormAccordian';
import getNewAccordianData from '../utils/getNewAccordianData';
import fetchNestedDataForMap from '../../../services/fetchNestedDataForMap';
import submitNewObjectMapping from '../../../services/submitNewObjectMapping';
import SideBar from '../../ApplicationV2/component/SideBar';
import ApplicationTilesContainer from '../container/ApplicationTiles';
import submitNewChildObjectMapping from '../../../services/submitNewChildObjectMapping';
import ConformationContainer from '../../common/conformationModal/containers';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import getSubmitPayload from '../utils/getSubmitPayload';
import getApplicationTopNavigationSchema from '../../../utils/navigationUtils/getApplicationTopNavigationSchema';
import fetchApplicationCount from '../../../services/fetchApplicationCount';
import formatFeatureCountData from '../../../utils/formatFeatureCountData';
import getExtensionSubheaderSchema from '../../../utils/navigationUtils/getExtensionSubheaderSchema';
import TopCardSubheader from '../../../layouts/TopCardSubheader';
import fetcheNestedDataByCode from '../../../services/fetchNestedDataByCode';

const NewObjectMappingForm = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  const navigate = useNavigate();

  const { app_code = '', edit_id = '' } = useParams();
  const submitEditChildForm = useMutation(submitNewChildObjectMapping);

  const { isSuccess, data: newFormData } = useQuery({
    queryKey: ['new-form-data'],
    queryFn: fetchNewFormData(),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (isSuccess) {
      const { targetObjectType } = newFormData;
      const sourceObjectTypeOptions = [
        {
          item_id: 'DATA_OBJECT',
          item_text: 'Data object'
        },
        {
          item_id: 'SCHEMA_OBJECT',
          item_text: 'Schema Object'
        }
      ];

      dispatch(
        initializeFormData({
          formKey: formKeys.newObjectMappingForm,
          formData: {
            targetObjectType: {
              value: targetObjectType
            },
            sourceObjectType: {
              value: sourceObjectTypeOptions
            }
          }
        })
      );
    }
  }, [isSuccess]);

  const formStore = useSelector((store) => store.store.form[formKeys.newObjectMappingForm]) || {};

  const newObjectformData = formStore.formData || {};

  const formError = formStore.errorState || {};

  const formState = formStore.formState || 'stale';

  const formStoreData = useSelector((store) => store.store.form?.formData);

  const { isEdited } = formStoreData || {};

  const handleChange = async (event) => {
    if (formStore.formState !== 'stale') {
      dispatch(
        updateFormState({
          formKey: formKeys.newObjectMappingForm,
          formState: 'stale'
        })
      );
    }

    console.log("jaiii",newSourceId)

    const { name, value } = event.target;
    console.log(event.target);

    switch (name) {
      case 'Target Object Type': {
        const fetchedData = await fetchTargetObjectListData({ app_code, type: value });
        dispatch(
          updateFormData({
            formKey: formKeys.newObjectMappingForm,
            formField: name,
            formData: value
          })
        );
        dispatch(
          updateFormData({
            formKey: formKeys.newObjectMappingForm,
            formField: 'targetObjectListData',
            formData: fetchedData.body.dataObjects
          })
        );
        break;
      }
      case 'Source Object Type': {
        const fetchedData = await fetchTargetObjectListData({ app_code, type: value });
        dispatch(
          updateFormData({
            formKey: formKeys.newObjectMappingForm,
            formField: name,
            formData: value
          })
        );
        dispatch(
          updateFormData({
            formKey: formKeys.newObjectMappingForm,
            formField: 'sourceObjectListData',
            formData: fetchedData.body.dataObjects
          })
        );
        break;
      }

      case 'Source Object List': {
        console.log("-------123----");
        let sourcecode = formStore?.formData?.sourceObjectListData?.value?.find(
          (element) => element.dataObjectId == value
        )?.code;
        console.log("app code",sourcecode)
        
        const fetchedData = await fetcheNestedDataByCode({appcode:app_code,code:sourcecode  }); 
        // sourceData = await fetcheNestedDataByCode({appcode:app_code,code:sourceCode  });

        const { body: { dataObjects = [] } = {} } = fetchedData;

        dispatch(
          updateFormData({
            formKey: formKeys.newObjectMappingForm,
            formField: 'sourceObjectResult',
            formData: dataObjects
          })
        );

        dispatch(
          updateFormData({
            formKey: formKeys.newObjectMappingForm,
            formField: name,
            formData: value
          })
        );

        break;
      }

      case 'Target Object List': {
        let TargetCode = formStore?.formData?.targetObjectListData?.value?.find(
          (element) => element.dataObjectId == value
        )?.code;
        console.log(TargetCode,"234567890-jhbv ghji")
        const fetchedData = await fetcheNestedDataByCode({appcode:app_code,code:TargetCode});

        const { body: { dataObjects = [] } = {} } = fetchedData;

        dispatch(
          updateFormData({
            formKey: formKeys.newObjectMappingForm,
            formField: 'targetObjectResult',
            formData: dataObjects
          })
        );

        dispatch(
          updateFormData({
            formKey: formKeys.newObjectMappingForm,
            formField: name,
            formData: value
          })
        );
        break;
      }

      default: {
        dispatch(
          updateFormData({
            formKey: formKeys.newObjectMappingForm,
            formField: name,
            formData: value
          })
        );
      }
    }
  };

  // const sourceName = formStore?.formData?.['Source Object List']?.value

  const newAccordianData = {
    sources: formStore?.formData?.sourceObjectResult || [],
    targets: formStore?.formData?.targetObjectResult || []
  };

  let newSourceId = formStore?.formData?.['Source Object List']?.value;
  let newTargetId = formStore?.formData?.['Target Object List']?.value;

  let newSourceName = formStore?.formData?.sourceObjectListData?.value?.find(
    (element) => element.dataObjectId == newSourceId
  )?.code;
  let newTargetName = formStore?.formData?.targetObjectListData?.value?.find(
    (element) => element.dataObjectId == newTargetId
  )?.code;
  const onFormSubmit = async ({ mappingData }) => {
    mappingData = getSubmitPayload(mappingData, newAccordianData);

    const result = await submitEditChildForm.mutateAsync({
      payload: {
        applicationCode: app_code,
        sourceObjectId: newSourceId,
        targetObjectId: newTargetId,
        targetObjectCode: newTargetName,
        sourceObjectCode: newSourceName,
        attributeCodes: mappingData
      }
    });
  };

  const confirmModal = (data) => {
    setFormData(data);
    dispatch(
      genericModalStateUpdate({
        modalKey: modalKeys.newMappingObjectFormSubmit,
        isOpen: true
      })
    );
  };

  const onMenuItemClick = ({ contextName, title }) => {
    switch (title) {
      case 'New Object Mapping': {
        navigate(`/${app_code}/object-mapping/new`);
        break;
      }
      case 'Object Mapping': {
        if (isEdited) {
          dispatch(
            genericModalStateUpdate({
              modalKey: modalKeys.stopNavigation,
              isOpen: true
            })
          );
        } else {
          navigateToHlfObj();
        }
      }
    }
  };

  const navigateToHlfObj = () => {
    navigate(`/apps/${app_code}/objectMapping`);
  };

  return (
    <div className="flex flex-col flex-1 bg-[#F4F3FA]">
      {isEmpty(newObjectformData) ? null : (
        <>
          <div className="flex-1 flex">
            <SideBar
              page="objectMapping"
              onMenuItemClick={onMenuItemClick}
              currentContext={{ objectMapping: 'New Object Mapping' }}
            />
            <div className="flex-1 flex flex-col">
              {/* {isEdited ? (
                <ApplicationTilesContainer askNavigationCheck={true} />
              ) : (
                <ApplicationTilesContainer />
              )} */}
              <TopCardSubheader
                topCardNavigationSchema={getApplicationTopNavigationSchema({
                  objectMappings: true
                })}
                getCountService={fetchApplicationCount(app_code)}
                formatCountData={formatFeatureCountData}
                subheaderNavigationSchema={getExtensionSubheaderSchema({ application: true })}>
                <div className="mx-[24px]">
                  <FormContainer
                    variant="original"
                    className="mt-4 shadow-none"
                    handleChange={handleChange}
                    submitForm={noop}
                    schema={newObjectMappingSchema(newObjectformData)}
                    formData={newObjectformData}
                    formError={formError}
                    formKey={formKeys.newObjectMappingForm}
                    formState={formState}
                  />
                  <NewFormAccordian
                    newSourceName={newSourceName}
                    newTargetName={newTargetName}
                    onFormSubmit={confirmModal}
                    type="new"
                    data={newAccordianData}
                  />
                </div>
              </TopCardSubheader>
            </div>
            {/* <Card /> */}
            {/* <NewFormMapping newObjectformData={newObjectformData} app_code={app_code} /> */}
          </div>
        </>
      )}
      <ConformationContainer
        type="submit"
        formData={formData}
        modalId={modalKeys.newMappingObjectFormSubmit}
        cancelObject={{ text: 'Cancel', callback: noop }}
        conformObject={{
          text: 'Submit Mapping',
          callback: onFormSubmit
        }}
        modalText={{
          header: 'Submit Object Mapping',
          desc: 'Submit object mapping will add a new object mapping the application'
        }}
      />
      <ConformationContainer
        modalId={modalKeys.stopNavigation}
        cancelObject={{ text: 'Cancel', callback: noop }}
        conformObject={{
          text: 'Discard Changes',
          callback: navigateToHlfObj
        }}
        modalText={{
          header: 'Discard Changes',
          desc: 'Your Data is Unsaved, do you really want to discard changes !'
        }}
      />
    </div>
  );
};

export default NewObjectMappingForm;
