import React, { useState } from 'react';
import FieldsComponents from './FieldsComponents';
import FieldContainer from '../container/FieldContainer';

const GetFormComponents = ({ fieldData, restrictRerender, form, path, isFormDisabled }) => {
  const { type, name } = form;
  switch (type) {
    case 'Fields': {
      return (
        <FieldsComponents
          restrictRerender={restrictRerender}
          isFormDisabled={isFormDisabled}
          form={form}
          path={path}
        />
      );
    }
    default: {
      return (
        <FieldContainer
          fieldData={fieldData}
          restrictRerender={restrictRerender}
          isFormDisabled={isFormDisabled}
          form={form}
          path={[...path, name]}
        />
      );
    }
  }
};

export default GetFormComponents;
