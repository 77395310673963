import Joi from 'joi';

function checkIPValidation(input) {
  return Joi.alternatives()
    .try(
      Joi.string()
        .regex(
          /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
        )
        .required(), // Validates if the text is a valid email
      Joi.string()
        .regex(
          /^([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}$|^([0-9a-fA-F]{1,4}:){1,7}:|^([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}$|^([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}$|^([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}$|^([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}$|^([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}$|^[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})$/
        )
        .required(),
      Joi.string()
        .regex(/^[$][a-zA-Z_][a-zA-Z0-9_]*$/)
        .required() // Validates if the text starts with "$"
    )
    .validate(input).error;
}

export default checkIPValidation;
