import React, { useEffect, useState } from 'react';
import SideBar from '../../ApplicationV2/component/SideBar';
import { useNavigate, useParams } from 'react-router-dom';
import ApplicationTilesContainer from '../../Application/container/ApplicationTiles';
import ObjectReview from './object/ObjectReview';
import ObjectApproved from './object/ObjectApproved';
import ObjectRejected from './object/ObjectRejected';
import { MenuItem, Select } from '@mui/material';
import ObjectContainer from '../container/object/ObjectContainer';
import TopCardsLayout from '../../../layouts/TopCardsLayout';
import TopCardSubheader from '../../../layouts/TopCardSubheader';
import getReviewTopNavigationSchema from '../../../utils/navigationUtils/getReviewTopNavigationSchema';
import getExtensionSubheaderSchema from '../../../utils/navigationUtils/getExtensionSubheaderSchema';
import fetchAllStateObjectsData from '../../../services/fetchAllStateObjectsData';
import formatFetchStateData from '../utils/formatFetchStateData';
import { noop } from 'lodash';
import fetchReviewCount from '../../../services/fetchReviewCount';

const Review = () => {
  const navigate = useNavigate();

  const [currentReviewState, setCurrentReviewState] = useState('review');

  const { review, app_code } = useParams();

  const [triggerOnChange, setTriggerOnChange] = useState(false);

  useEffect(() => {
    setTriggerOnChange(!triggerOnChange);
  }, [review, currentReviewState]);

  const onMenuItemClick = ({ title }) => {
    switch (title) {
      case 'Objects': {
        navigate(`/apps/${app_code}/review`);
        break;
      }
      case 'Object Mappings': {
        navigate(`/apps/${app_code}/reviewObjectMapping`);
        break;
      }
    }
  };

  const updatedName = {
    review: 'Objects',
    reviewObjectMapping: 'Object Mappings'
  };

  const currentSelectedObject = {
    review: currentReviewState == 'review',
    rejected: currentReviewState == 'rejected',
    approved: currentReviewState == 'approved'
  };
  // {process.env.REACT_APP_CONFIG_API}
  const countUrl = {
    review: `${process.env.REACT_APP_CONFIG_API}/data-objects/reviewCount`,
    reviewObjectMapping: `${process.env.REACT_APP_CONFIG_API}/object-mapping/reviewCount`
  };

  const formatReviewCountData = (data) => {
    const { reviewCount, approvedCount, rejectedCount } = data.body.objectCount;
    return {
      review: reviewCount,
      rejected: rejectedCount,
      approved: approvedCount
    };
  };

  return (
    <div className="flex bg-[#F4F3FA]">
      <SideBar
        page={'review'}
        onMenuItemClick={onMenuItemClick}
        currentContext={{ review: updatedName[review] }}
      />
      <div className="flex-1 flex flex-col bg-[#F4F3FA]">
        {/* <ApplicationTilesContainer context="review" /> */}
        <TopCardSubheader
          subheaderNavigationSchema={getExtensionSubheaderSchema({ extension: true })}
          topCardNavigationSchema={getReviewTopNavigationSchema(
            currentSelectedObject,
            setCurrentReviewState
          )}
          triggerFetch={triggerOnChange}
          getCountService={fetchReviewCount(countUrl[review], app_code)}
          formatCountData={formatReviewCountData}>
          <div className="ml-8 flex flex-col flex-1 h-[100%]">
            {/* <Select
              className="my-8 w-[200px]"
              value={currentReviewState}
              onChange={(e) => setCurrentReviewState(e.target.value)}>
              <MenuItem value={'review'}>Review</MenuItem>
              <MenuItem value={'approved'}>Approved</MenuItem>
              <MenuItem value={'rejected'}>Rejected</MenuItem>
            </Select> */}
            <div className="flex-1">
              <ObjectContainer
                currentReviewContext={currentReviewState}
                setCurrentReviewState={setCurrentReviewState}
                reviewType={review}
              />
            </div>
          </div>
        </TopCardSubheader>
      </div>
    </div>
  );
};

export default Review;
