import { useDispatch, useSelector } from 'react-redux';
import DefaultDashboard from '../../common/layout/container/DefaultDashboard';
import { dashboardContext } from '../../dashboard/slices/dashboardSlice';
import HLFForm from './HLFForm';
import { useState } from 'react';
import { Button } from '@mui/material';
import HLFGridContainer from './HLFGridContainer';
import { useNavigate } from 'react-router-dom';
import SideBar from '../../ApplicationV2/component/SideBar';
import OrganisationCreation from '../../Organisation/CreateOrganisation';
import CreateParties from '../../Organisation/CreateParties';
import CreateSubOrganisation from '../../Organisation/CreateSubOrganisation';
import CreateUser from '../../Organisation/CreateUser';
const Organization = ({pageContext}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    dispatch(dashboardContext({ contextName: 'Organisation', context: 'OrgManagement' }));
    let currentContext = useSelector((store) => store.store.dashboard.currentContext);
    const [selectedMenuTitle,setSelectedMenuTitle] = useState('Organisation')
    console.log(currentContext,"currentContext")
    // const  = ({ contextName, title }) => {
    //     console.log(title,"title")
    //     setSelectedMenuTitle(title)
    //     switch (title) {
    //         case 'Organisation': {
    //             navigate('/organisation');
    //             break;
    //         }
    //         case 'PartnerType': {
    //             navigate('/organisation/PartnerType');
    //             break;
    //         }
    //         case 'Partner': {
    //             navigate('/hlf/new');
    //             break;
    //         }
    //         case 'HLF Explorer': {
    //             navigate('/hlfExplorer');
    //             break;
    //         }
    //     }
    // };
console.log(pageContext);
    switch(pageContext) {
        case 'partnerType': {
            return <CreateParties />;
          }
          case 'partner': {
            return <CreateSubOrganisation />;
          }
          case 'user': {
            return <CreateUser />;
          }
          default: {
            return <OrganisationCreation />;
          }
    }

    currentContext = {
        ...currentContext,
        OrgManagement: 'Organisation'
    };

    return (
        <div className="w-full min-h-full flex bg-[#F4F3FA]">
            {/* <SideBar page="OrgManagement" onMenuItemClick={onMenuItemClick} currentContext={currentContext} /> */}
            {/* <OrganisationCreation/> */}
            {/* <HLFGridContainer /> {currentContext} */}
            {/* {currentContext} */}
            {/* {/* {currentContext.title} */}
            {/* {selectedMenuTitle} */}
            {/* {selectedMenuTitle === 'Organisation' ? <OrganisationCreation /> : selectedMenuTitle === 'PartnerType' ?  <CreateParties/> : ''}  */}
            
           
            
        </div>
    );
};

export default Organization;