import React from 'react';
import { useQuery } from 'react-query';
import fetchApplication from '../../../services/fetchApplicationData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { successfulApplicationDataFetch } from '../slices/applicationSlice';
import Application from '../component/Application.jsx';
import modalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import formKeys from '../../common/form/constants/formKeys';
import ApplicationCardsContainer from '../../ApplicationV2/container/ApplicationCardsContainer';
import { clearFormState } from '../../common/form/slices/formSlice.js';

const ApplicationContainer = () => {
  const { data: applicationResponse = {}, refetch } = useQuery('application', fetchApplication, {
    enabled: false
  });

  const dispatch = useDispatch();
  const applicationData = useSelector((store) => store.store.application.data);
  const formData = useSelector((store) => store.store.form[formKeys.newApplicationForm]) || {};
  const editFromData = useSelector((store) => store.store.form[formKeys.editApplicationForm]) || {};
  const applicationState = useSelector((store) => store.store.application.applicationState);

  useEffect(() => {
    const refetchData = refetch();

    refetchData.then((data) => {
      const { data: refetchData, isSuccess } = data;
      if (isSuccess) {
        const applicationData = refetchData.body.applicationObjects;
        dispatch(successfulApplicationDataFetch({ data: applicationData }));
      }
    });
  }, [formData.formState, applicationState, editFromData.formState]);

  const openDashboardGenericModal = () => {
    dispatch(
      clearFormState({
        formKey: formKeys.newApplicationForm,
        formType: 'application'
      })
    );
    dispatch(
      genericModalStateUpdate({
        modalKey: modalKeys.dashboardModal,
        isOpen: true
      })
    );
  };

  return (
    <ApplicationCardsContainer
      openDashboardGenericModal={openDashboardGenericModal}
      isLoading={applicationResponse.isLoading}
      applicationData={applicationData}
    />
  );
};

export default ApplicationContainer;
