import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import { noop } from 'lodash';
import toast from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';
import BlockHashModal from '../component/BlockHashModal';
import HlfTxnModal from '../component/HlfTxnModal';

const HlfTxnModalContainer = ({ onCloseModal = noop }) => {
  const [hlfTxnData, setHlfTxnData] = useState(null);
  const modalStore = useSelector((store) => store?.store?.genericModal?.modalKeys);
  const modalData = modalStore?.hlfTxnModalData;
  console.log(modalData, 'mmmmmmmmmmmmmmmmmmm');
  const { transactions = '', channelName = '' } = modalData;
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await request
          .get(`${process.env.REACT_APP_CONFIG_API}/hlfExplorer/getTransactionById`)
          .query({ transactionId: transactions, channelName: channelName })
          .set('Authorization', `Bearer ${keycloak.token()}`);

        console.log(res, 'oooooooooooooooooooo');
        if (res?.body?.status == 'success') {
          toast.custom((t) => <Success t={t} message={res?.body?.message} />, {
            duration: 4000
          });
          setHlfTxnData(res?.body?.data);
        } else {
          toast.custom((t) => <ErrorNotify t={t} message={'Block Info fetch failed'} />, {
            duration: 4000
          });
          onCloseModal();
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [transactions]);

  return hlfTxnData ? (
    <div className="bg-white">
      <HlfTxnModal onCloseModal={onCloseModal} hlfTxnData={hlfTxnData} />
    </div>
  ) : null;
};

export default HlfTxnModalContainer;
