import request from 'superagent';
import { fetchNetworkDataUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const fetchNetworkData = ({ networkName }) => {
  const updatedFetchNetworkDataUrl = `${fetchNetworkDataUrl}`;
  return async () => {
    const res = await request
      .get(updatedFetchNetworkDataUrl)
      .query({
        id: networkName
      })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

export default fetchNetworkData;
