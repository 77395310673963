import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConformationContainer from '../../conformationModal/containers';
import { noop } from 'lodash';
import classNames from 'classnames';

const Card = ({ cardData, count = 0, cardsCount }) => {
  const navigate = useNavigate();
  const { app_code } = useParams();
  const width = 100 / cardsCount;

  const cardClassName = classNames(
    `cursor-pointer flex h-full transition-all delay-100 p-4 rounded-lg relative`,
    {
      'bg-white shadow-lg': cardData.isSelected,
      'border-r-2 border-[#D8D8D9] !rounded-r-none last:border-none last:rounded-r-lg':
        !cardData.isSelected
    }
  );

  return (
    <div
      onClick={() => cardData.onClick(cardData, navigate, app_code)}
      style={{ width: `${width}%` }}
      className={cardClassName}>
      <div>
        <img height={'75px'} width={'75px'} src={cardData.imageUrl} alt="" srcset="" />
      </div>
      <div className="flex flex-col pt-2 pl-4">
        {cardData.hideCount ? null : <div className="text-[28px] font-semibold">{count}</div>}
        <div className="text-3xl font-medium">{cardData.name}</div>
      </div>
    </div>
  );
};
{
  /* <ConformationContainer
        modalId={tile.name}
        cancelObject={{ text: 'Cancel', callback: noop }}
        conformObject={{
          text: 'Discard Changes',
          callback: () => {
            changeContext(
              tile.name,
              () => navigate(`/apps/${app_code}/${tileNameInCamelCase}`),
              true
            );
          }
        }}
        modalText={{
          header: 'Discard Changes',
          desc: 'Your Data is Unsaved, do you really want to discard changes !'
        }}
      /> */
}

export default Card;
