import React, { useEffect, useState } from 'react';
import request from 'superagent';
import keycloak from '../../Keycloak';
import { AgGridReact } from 'ag-grid-react';
import { FileDownload } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { genericModalStateUpdate } from '../modals/slices/genericModalSlice';

const FileStatus = ({ isModal = false, modalKey = '' }) => {
  const [fileStatusData, setFileStatusData] = useState(null);
  const dispatch = useDispatch();
  const columnDefs = [
    { headerName: 'APP CODE', field: 'APP CODE' },
    { headerName: 'BASE CODE', field: 'BASE CODE' },
    { headerName: 'ORGANIZATION', field: 'ORGANIZATION' },
    { headerName: 'TYPE', field: 'TYPE' },
    { headerName: 'FILE NAME', field: 'FILE NAME' },
    { headerName: 'DATE', field: 'date' },
    {
      headerName: 'ACTION',
      field: 'FILE PATH',
      cellRenderer: (data) => {
        if (data?.data?.TYPE == 'IMPORT') {
          return <>NA</>;
        }
        return <FileDownload />;
      }
    },
    { headerName: 'STATUS', field: 'STATUS' }
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function downloadFile(fileName, url, headers) {
    fetch(url, {
      headers: headers
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };
  let gridApi;

  async function onGridReady(params) {
    gridApi = params?.api;
    params?.api?.showLoadingOverlay();
    const fetchData = request
      .get(`${process.env.REACT_APP_CONFIG_API}/file/get_status`)
      .set('Authorization', `Bearer ${keycloak.token()}`)
      .then((data) => {
        setFileStatusData(data.body.primary);
      });
  }

  const rowData = fileStatusData?.map((feature) => {
    console.log(feature, 'ggggg');
    return {
      'APP CODE': feature.app_code,
      'BASE CODE': feature.base_code,
      ORGANIZATION: feature.org_id,
      'FILE NAME': feature.file_name || 'NA',
      'FILE PATH': '',
      STATUS: feature?.auditInfo?.status,
      TYPE: feature.type,
      date: feature.timeStamps.createdDate
    };
  });

  async function cellClicked(param) {
    const { data, colDef: { field } = {} } = param;
    data;

    switch (field) {
      case 'FILE PATH': {
        const { TYPE } = data;
        if (TYPE !== 'IMPORT') {
          const headers = {
            Authorization: keycloak.token()
          };

          downloadFile(
            data['FILE NAME'],
            `${process.env.REACT_APP_CONFIG_API}/static/file_download/${data['FILE NAME']}`,
            headers
          );
        }
      }
    }
  }

  return (
    <div className="ag-theme-alpine h-[100vh] w-[1200px] m-auto flex justify-center items-center">
      <div className="w-[100%] h-[600px] bg-white mt-[20px] p-[40px] border-2 border-black rounded-lg">
        <div
          className="w-auto float-right cursor-pointer relative top-[-24px] "
          onClick={() =>
            dispatch(
              genericModalStateUpdate({
                modalKey: modalKey,
                isOpen: false
              })
            )
          }>
          <CloseIcon />
        </div>
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          onCellClicked={cellClicked}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          rowData={rowData}
        />
      </div>
    </div>
  );
};

export default FileStatus;
