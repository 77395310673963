import request from 'superagent';
import { connectionCountUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';
import { useParams } from 'react-router-dom';

const fetchConnectionCount = (app_code) => {
  return async () => {
    const res = await request
      .post(connectionCountUrl)
      .set('Authorization', `Bearer ${keycloak.token()}`)
      .send({ appcode: app_code });

    return res;
  };
};

export default fetchConnectionCount;
