import { createSlice } from '@reduxjs/toolkit';

export const applicationSlice = createSlice({
  name: 'moreApplication',
  initialState: { features: {} },
  reducers: {
    successfulMoreApplicationData: (state, action) => {
      state.data[action.payload.selected] = action.payload.data;
    },
    applicationCountDataAction: (state, action) => {
      state.applicationCountData = action.payload.applicationCountData;
    },
    fetchObjectMappingAction: (state, action) => {
      state.objectMappingData = action.payload.objectMappingData;
    },
    fetchFeatureDataAction: (state, action) => {
      state.featureData = action.payload.featureData;
    },
    fetchOrchestrationDataAction: (state, action) => {
      state.orchestrationData = action.payload.orchestrationData;
    },
    fetchDataObjectDataAction: (state, action) => {
      state.dataObjectData = action.payload.fetchDataObjectData;
    },
    alreadyMappedDetailsAction: (state, action) => {
      state.objectMappingResponseItem = action.payload.objectMappingResponseItem;
    },
    objectDataAction: (state, action) => {
      state.objectData = action.payload.objectData;
    },
    targetObjectDataAction: (state, action) => {
      state.targetObjectData = action.payload.targetObjectData;
    },
    targetObjectDataListAction: (state, action) => {
      state.targetObjectDataListData = action.payload.targetObjectDataList;
    },
    fetchHLFDataAction: (state, action) => {
      state.HLFData = action.payload.HLFData;
    },
    fetchConnectionLogDataAction: (state, action) => {
      state.connectionLogsData = action.payload.connectionLogsData;
    },
    fetchHLFExplorerDataAction: (state, action) => {
      state.HLFExplorerData = action.payload.HLFExplorerData;
    },
  }
});

export const {
  fetchHLFDataAction,
  successfulMoreApplicationData,
  fetchOrchestrationDataAction,
  fetchDataObjectDataAction,
  applicationCountDataAction,
  fetchFeatureDataAction,
  fetchObjectMappingAction,
  alreadyMappedDetailsAction,
  objectDataAction,
  targetObjectDataAction,
  targetObjectDataListAction,
  fetchConnectionLogDataAction,
  fetchHLFExplorerDataAction
} = applicationSlice.actions;

export default applicationSlice.reducer;
