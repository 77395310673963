const getTextFromArray = (items = []) => {
  const data = [];
  items.forEach((item) => {
    data.push({
      name: item.item_text,
      value: item.item_text
    });
  });
  return data;
};

const getDataObjectsName = (items = []) => {
  const data = [];
  items.forEach((item) => {
    data.push({
      name: item.name,
      value: item.dataObjectId
    });
  });
  return data;
};

const getRequestObjectsName = (items = []) => {
  const data = [];
  items.forEach((item) => {
    data.push({
      name: item.dataObjectName,
      value: item.dataObjectId
    });
  });
  return data;
};

const getResponseObjectsName = (items = []) => {
  const data = [];
  items.forEach((item) => {
    data.push({
      name: item.name,
      value: item.dataObjectId
    });
  });
  return data;
};

const getFeatureListName = (items = []) => {
  const data = [];
  items.forEach((item) => {
    data.push({
      name: item.featureName,
      value: item.featureCode
    });
  });
  return data;
};

const getNetworkListName = (items = []) => {
  const data = [];
  items.forEach((item) => {
    data.push({
      name: item.network,
      value: item.network
    });
  });
  return data;
};

const getKeyColumnsOptionsName = (items = []) => {
  const data = [];
  items.forEach((item) => {
    data.push({
      name: item.name,
      value: item.code
    });
  });
  return data;
};

const getSourceObjectOptions = (items = []) => {
  const data = [];
  items.forEach((item) => {
    data.push({
      name: item.dataObjectName,
      value: item.dataObjectId
    });
  });
  return data;
};

const getSourceActionOptions = (items = []) => {
  const data = [];
  items.forEach((item) => {
    data.push({
      name: item.item_text,
      value: item.item_id
    });
  });
  return data;
};

const getDataObjectTypes = (items = []) => {
  const data = [];
  items.forEach((item) => {
    data.push({
      name: item.item_text,
      value: item.item_id
    });
  });
  return data;
};

const getAclGroupsOptions = (items = []) => {
  const data = [];
  items.forEach((item) => {
    data.push({
      name: `${item.name} `,
      value: item.name
    });
  });
  return data;
};

const getDataTypes = (items = []) => {
  const data = [];
  items.forEach((item) => {
    data.push({
      name: item.name,
      value: item.dataObjectId
    });
  });
  return data;
};

const getHlfChannelOptions = (items = []) => {
  const data = [];
  items.forEach((item) => {
    data.push({
      name: item.name,
      value: item.name
    });
  });
  return data;
};

const getHlfChainCodeOptions = (items = []) => {
  const data = [];
  items.forEach((item) => {
    data.push({
      name: item,
      value: item
    });
  });
  return data;
};

const getCommonObjectsOptions = (items = []) => {
  const data = [];
  items.forEach((item) => {
    data.push({
      name: item.dataObjectName,
      value: item.dataObjectId
    });
  });
  return data;
};
//

export {
  getTextFromArray,
  getDataObjectsName,
  getRequestObjectsName,
  getResponseObjectsName,
  getFeatureListName,
  getNetworkListName,
  getKeyColumnsOptionsName,
  getSourceObjectOptions,
  getSourceActionOptions,
  getDataObjectTypes,
  getHlfChannelOptions,
  getHlfChainCodeOptions,
  getDataTypes,
  getCommonObjectsOptions,
  getAclGroupsOptions
};
