import { useState } from 'react';
import ReactFlow, { useOnSelectionChange } from 'reactflow';
import FormSection from './FormSection';
import { Box, Button, Dialog } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import ViewFormSection from './ViewFormSection';
// import FormSection from './FormSection.1';

const RightSideBar = (props) => {
  console.log('Props Of RightsSideBar...', props);
  const [selectedNodes, setSelectedNodes] = useState();
  const [selectedEdges, setSelecteEdges] = useState();

  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('lg');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      console.log('clicked node is ......', nodes, edges);
      handleClickOpen();
      setSelectedNodes(nodes);
      const connectedEdges = props.edges.filter((edge) =>
        nodes.some((node) => node.data.id === edge.source || node.data.id === edge.target)
      );
      setSelecteEdges(connectedEdges);
    }
  });

  console.log('You have selected this node.....', selectedNodes);

  return (
    // <Dialog
    //   fullWidth={fullWidth}
    //   maxWidth={maxWidth}
    //   open={open}
    //   onClose={handleClose}

    //   >
    <Box sx={{ width: '400px' }}>
      {/* <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}} >
        <Button  onClick={handleClose}>
        <HighlightOffOutlinedIcon/>
        </Button>
        </div> */}
      {props.type == 'edit' ? (
        <ViewFormSection selectedNodes={selectedNodes} selectedEdges={props} payload={props.payload} data={selectedNodes} />
      ) : (
        <FormSection selectedNodes={selectedNodes} selectedEdges={props} pay={props.payload}/>
      )}
    </Box>
    // </Dialog>
  );
};

export default RightSideBar;
