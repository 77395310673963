import request from 'superagent';
import { editOrchestrationDataUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const editOrchestrationData = (query) => {
  return async () => {
    const res = await request
      .get(editOrchestrationDataUrl)
      .query(query)
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};
export default editOrchestrationData;
