import { createTheme } from "@mui/material";
import {teal} from "@mui/material/colors";

const theme = createTheme({
  palette: {
    peace: {
      main: teal
    }
  }
});

export default theme;
