import request from 'superagent';
import { auditLogUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const fetchObjectAuditLogData = ({
  type = '',
  app_code = '',
  startDate = '',
  endDate = '',
  subType = ''
}) => {
  return async () => {
    const res = await request
      .get(auditLogUrl)
      .query({
        type: type,
        app_code: app_code,
        startDate: startDate,
        endDate: endDate,
        subType: subType
      })
      .set('Authorization', `Bearer ${keycloak.token()}`);
    return res;
  };
};

const fetchTransformationAuditLogData = ({
  type = '',
  app_code = '',
  startDate = '',
  endDate = ''
}) => {
  return async () => {
    const res = await request
      .get(auditLogUrl)
      .query({ type: type, app_code: app_code, startDate: startDate, endDate: endDate })
      .set('Authorization', `Bearer ${keycloak.token()}`);
    return res;
  };
};

export { fetchObjectAuditLogData, fetchTransformationAuditLogData };
