import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Orchestration from '../component/Orchestration';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { useNavigate, useParams } from 'react-router-dom';

const OrchestrationsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { app_code = '' } = useParams();

  const handleNewBtnClick = () => {
    navigate(`/apps/${app_code}/orchestration/new`);
  };

  const deleteOrchestrationClick = (data, name) => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.deleteOrchestrationModal,
        isOpen: true,
        modalDataKey: 'deleteOrchestrationModalData',
        modalData: { id: data, name: name }
      })
    );
  };

  return (
    <Orchestration
      handleNewOrchestrationClick={handleNewBtnClick}
      deleteOrchestrationClick={deleteOrchestrationClick}
    />
  );
};

export default OrchestrationsContainer;
