import applicationTypes from '../../../../constants/applicationTypes';
import application from '../../../../public/icons/application.svg';
import hlfLogo from '../../../../public/icons/hlfLogo.svg';
import editHlfLogo from '../../../../public/icons/edit.svg';
import newHlfLogo from '../../../../public/icons/newLogo.svg';
import deliveryLogo from '../../../../public/icons/delivery.svg';
import alarmLogo from '../../../../public/icons/alarm.svg';
import connection from '../../../../public/icons/connection.png';
import newConnections from '../../../../public/icons/newConnections.png';
import transformation from '../../../../public/icons/transformation.png';
import objectMapping from '../../../../public/icons/objectMapping.png';
import searchCriteria from '../../../../public/icons/searchCriteria.png';
import variable from '../../../../public/icons/variables.png';
import organisation from '../../../../public/icons/organisation.svg';
import connectionLog from '../../../../public/icons/connection-log.png';

const sidebar_component = {
  Application: [
    {
      title: applicationTypes.features,
      contextName: 'Application',
      imageComponent: <></>
    },
    {
      title: applicationTypes.orchestration,
      contextName: 'Application',
      imageComponent: <></>
    },
    {
      title: applicationTypes.dataObject,
      contextName: 'Application',
      imageComponent: <></>
    },
    {
      title: applicationTypes.objectMapping,
      contextName: 'Application',
      imageComponent: <></>
    }
  ],
  Dashboard: [
    {
      title: 'Applications',
      contextName: 'Dashboard',
      imageComponent: application
    },
    {
      title: 'HLF Networks',
      contextName: 'Dashboard',
      imageComponent: hlfLogo
    }
  ],
  HLF: [
    {
      title: 'HLF Networks',
      contextName: 'HLF',
      imageComponent: hlfLogo
    },
    {
      title: 'New Network',
      contextName: 'New HLF',
      imageComponent: newHlfLogo
    },
    {
      title: 'HLF Explorer',
      contextName: 'HLF Explorer',
      imageComponent: newHlfLogo
    }
  ],
  objectMapping: [
    {
      title: 'Object Mapping',
      contextName: 'Object Mapping',
      imageComponent: hlfLogo
    },
    {
      title: 'New Object Mapping',
      contextName: 'New Object Mapping',
      imageComponent: hlfLogo
    }
  ],
  orchestration: [
    {
      title: 'Orchestration',
      contextName: 'Orchestration',
      imageComponent: hlfLogo
    },
    {
      title: 'New Orchestration',
      contextName: 'Orchestration',
      imageComponent: hlfLogo
    }
  ],
  features: [
    {
      title: 'Features',
      contextName: 'Features',
      imageComponent: hlfLogo
    },
    { title: 'New Feature', contextName: 'Features', imageComponent: hlfLogo },
    { title: 'Variable Management', contextName: 'Features', imageComponent: variable },
    { title: 'Bulk Processing', contextName: 'Features', imageComponent: variable }
  ],

  object: [
    {
      title: 'Objects',
      contextName: 'object',
      imageComponent: hlfLogo
    },
    {
      title: 'New Object',
      contextName: 'object',
      imageComponent: hlfLogo
    }
  ],
  trace: [
    {
      title: 'Analysis',
      contextName: 'analysis',
      imageComponent: alarmLogo
    },
    {
      title: 'Trace',
      contextName: 'trace',
      imageComponent: deliveryLogo
    },
    {
      title: 'Search Criteria',
      contextName: 'searchCriteria',
      imageComponent: searchCriteria
    }
  ],
  connectionFramework: [
    {
      title: 'Connections',
      contextName: 'connections',
      imageComponent: connection
    },
    {
      title: 'New Connection',
      contextName: 'newConnections',
      imageComponent: newConnections
    },
    {
      title: 'Connection Logs',
      contextName: 'connectionLogs',
      imageComponent: connectionLog
    },
    {
      title: 'Variable Management',
      contextName: 'variables',
      imageComponent: variable
    }
  ],
  auditLog: [
    {
      title: 'Objects',
      contextName: 'objects',
      imageComponent: connection
    },
    {
      title: 'Object Mappings',
      contextName: 'objectMappings',
      imageComponent: objectMapping
    },
    {
      title: 'Transformation',
      contextName: 'transformation',
      imageComponent: transformation
    },
    {
      title: 'Audit Explorer',
      contextName: 'auditExplorer',
      imageComponent: hlfLogo
    }
  ],
  review: [
    {
      title: 'Objects',
      contextName: 'objects',
      imageComponent: connection
    },
    {
      title: 'Object Mappings',
      contextName: 'ObjectMappings',
      imageComponent: objectMapping
    }
  ],
  variables: [
    {
      title: 'Variables',
      contextName: 'variables',
      imageComponent: variable
    }
  ],
  bulkProcessing: [
    {
      title: 'Bulk Processing',
      contextName: 'bulkProcessing',
      imageComponent: variable
    }
  ],
  OrgManagement: [
    {
      title: 'Organisation',
      contextName: 'Organisation',
      imageComponent: organisation
    },
    {
      title: 'PartnerType',
      contextName: 'PartnerType',
      imageComponent: organisation
    },
    {
      title: 'Partner',
      contextName: 'Partner',
      imageComponent: organisation
    },
    {
      title: 'User',
      contextName: 'User',
      imageComponent: organisation
    }
  ]
};

export default sidebar_component;
