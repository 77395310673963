import { createSlice } from '@reduxjs/toolkit';

export const tableSlice = createSlice({
  name: 'table',
  initialState: { tables: {} },
  reducers: {
    successfulTableDataFetch: (state, action) => {
      state.tables = { ...state.tables, [action.payload.tableName]: action.payload.tableData };
    }
  }
});

export const { successfulTableDataFetch } = tableSlice.actions;

export default tableSlice.reducer;
