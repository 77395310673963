import React, { useEffect, useState } from 'react';
import GetFormComponents from './GetFormComponents';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { deleteFormObjectByKey } from '../../form/slices/formSlice';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore, PlusOne } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ConformationContainer from '../../conformationModal/containers';
import ModalKeys from '../../../modals/genericModal/constants/GenericModalKeys';
import { noop } from 'lodash';
import { genericModalStateUpdate } from '../../../modals/slices/genericModalSlice';

const FieldsComponents = ({ restrictRerender, form, path, isFormDisabled }) => {
  const dispatch = useDispatch();
  const {
    name,
    data,
    varient,
    duplicate,
    addFieldBtnTxt = 'Add Field',
    options = [],
    duplicateBtnRqd = true,
    hideTitle = false,
    defaultSchema = null,
    accordianName = null,
    hideMe = false,
    addIconName = '',
    removeIconName = ''
  } = form;

  const [currentFieldNumber, setCurrentFieldNumber] = useState(Object.keys(data || {}).length);
  const [expanded, setExpanded] = useState(false);
  const [addSchema, setAddSchema] = useState(defaultSchema);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [fieldData, setFieldData] = useState({ ...data });

  useEffect(() => {
    setFieldData({ ...data });
  }, [JSON.stringify(data)]);

  const fieldsClass = classNames(
    'flex flex-col w-[100%]',
    { 'w-[100%] mt-[34px] border': varient == 'full' },
    {
      'rounded-[8px] mr-[8px] my-[8px]': varient !== 'full'
    }
  );
  const fieldsHeader = classNames('flex justify-between', {
    'w-[100%] mt-[20px] px-4 mb-4': varient == 'full'
  });
  const fieldChildClasses = classNames('flex my-2 mx-2 flex-wrap', {
    'shadow-lg p-4 my-4': varient == 'full'
  });

  const addField = () => {
    const newFieldNumber = Object.keys(fieldData || {}).length + 1;

    const newData = [...data[0]].map((element) => {
      const newEle = JSON.parse(JSON.stringify(element));
      if (newEle.data) {
        const inside = newEle.data[0][0];
        delete inside.disabled;
        delete inside.defaultValue;
        delete inside.isRemoveOff;
        return newEle;
      } else {
        delete newEle.disabled;
        delete newEle.defaultValue;
        delete newEle.isRemoveOff;
        return newEle;
      }
    });

    setCurrentFieldNumber(newFieldNumber + 1);

    try {
      setFieldData({
        ...fieldData,
        [newFieldNumber]: addSchema || newData
      });
    } catch (err) {
      console.log(err);
    }
  };

  const removeField = ({ name, fieldNumber, path }) => {
    const updatedFields = { ...fieldData };
    const keyName = `${name}-${fieldNumber}`;
    dispatch(deleteFormObjectByKey({ formKey: path, keyName: keyName }));
    delete updatedFields[fieldNumber];
    setFieldData(updatedFields);
  };

  return (
    <div className={fieldsClass}>
      {varient == 'full' && duplicate ? (
        <Accordion
          className="!bg-inherit !shadow-none min-w-[500px] !w-[100%]"
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
            id="panel1bh-header">
            {accordianName || (name && <div>{accordianName || name}</div>)}
          </AccordionSummary>
          <AccordionDetails>
            {duplicate && duplicateBtnRqd && (
              <Button
                className="!normal-case !shadow-none !float-right !mx-0 !mb-2"
                onClick={addField}
                variant="contained"
                color="success">
                {addIconName ? addIconName : <AddIcon />}
              </Button>
            )}
            <div className="flex flex-col">
              {Object.keys(fieldData).map((key) => {
                const uniqueModalKey = `${ModalKeys.deleteForm}${name}${key}${path}`;
                return (
                  <>
                    <div key={key} className={fieldChildClasses}>
                      {fieldData[key]?.map((element, secKey) => {
                        const FormComponent = GetFormComponents({
                          form: element,
                          path: [...path, `${name}-${key}`],
                          isFormDisabled,
                          options: { options },
                          restrictRerender: restrictRerender,
                          fieldData: { fieldData }
                        });
                        return <div key={`${name}-${key}-${secKey}`}>{FormComponent}</div>;
                      })}
                      {duplicate && duplicateBtnRqd && (
                        <div className="self-center !mt-[24px] flex-1">
                          {!fieldData[key].isRemoveOff ? (
                            <Button
                              className="!normal-case h-[40px] float-right !shadow-none"
                              variant="contained"
                              color="error"
                              onClick={() =>
                                dispatch(
                                  genericModalStateUpdate({
                                    modalKey: uniqueModalKey,
                                    isOpen: true
                                  })
                                )
                              }>
                              {removeIconName ? removeIconName : <RemoveIcon />}
                            </Button>
                          ) : null}

                          <ConformationContainer
                            doNotEmptyState={true}
                            modalId={uniqueModalKey}
                            cancelObject={{ text: 'Retain', callback: noop }}
                            conformObject={{
                              text: 'Remove',
                              callback: () => removeField({ name, fieldNumber: key, path })
                            }}
                            modalText={{
                              header: `Remove ${name}`,
                              desc: 'Your Data is Unsaved, do you really want to Remove!'
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      ) : (
        <div className={hideMe}>
          <div className={fieldsHeader}>
            {!hideTitle && name && <div className="text-[24px]">{name}</div>}
            {duplicate && duplicateBtnRqd && (
              <div className="flex-1">
                <Button
                  className="!normal-case !shadow-none !mr-[8px] !float-right "
                  onClick={addField}
                  variant="contained"
                  color="success">
                  {addIconName ? addIconName : <AddIcon />}
                </Button>
              </div>
            )}
          </div>
          <div className="flex flex-col">
            {Object.keys(fieldData).map((key) => {
              const uniqueModalKey = `${ModalKeys.deleteForm}${name}${key}${path}`;
              return (
                <>
                  <div key={key} className={fieldChildClasses}>
                    {fieldData[key]?.map((element, secKey) => {
                      const FormComponent = GetFormComponents({
                        form: element,
                        path: [...path, `${name}-${key}`],
                        isFormDisabled,
                        options: { options },
                        restrictRerender: restrictRerender,
                        fieldData: { fieldData }
                      });
                      return <div key={`${name}-${key}-${secKey}`}>{FormComponent}</div>;
                    })}
                    {duplicate && duplicateBtnRqd && (
                      <div className="self-center !mt-[24px] flex-1">
                        {!fieldData[key].isRemoveOff ? (
                          <Button
                            className="!normal-case h-[40px] float-right !shadow-none"
                            variant="contained"
                            color="error"
                            onClick={() =>
                              dispatch(
                                genericModalStateUpdate({
                                  modalKey: uniqueModalKey,
                                  isOpen: true
                                })
                              )
                            }>
                            {removeIconName ? removeIconName : <RemoveIcon />}
                          </Button>
                        ) : null}

                        <ConformationContainer
                          doNotEmptyState={true}
                          modalId={uniqueModalKey}
                          cancelObject={{ text: 'Retain', callback: noop }}
                          conformObject={{
                            text: 'Remove',
                            callback: () => removeField({ name, fieldNumber: key, path })
                          }}
                          modalText={{
                            header: `Remove ${name}`,
                            desc: 'Your Data is Unsaved, do you really want to Remove!'
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default FieldsComponents;
