import request from 'superagent';
import { getUserListUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const fetchUserList = async (app_code = '') => {
  const res = await request
    .get(getUserListUrl)
    .query({ app_code: app_code })
    .set('Authorization', `Bearer ${keycloak.token()}`);

  return res;
};

export default fetchUserList;
