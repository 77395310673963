import { noop } from 'lodash';
import { getAclGroupsOptions, getDataObjectTypes, getDataTypes } from '../utils/getTextFromArray';

const newObjectSchema = ({
  dataObjectTypes = [],
  onDataObjectSelect = noop,
  dataType,
  showAcl,
  onAclRequiredSelect = noop,
  aclSelected = false,
  objectSpecificSelected = false,
  onObjectSpecificSelect = noop,
  aclGroups = [],
  onValidateBtnClick = noop,
  showValidateBtn,
  showValidationRequired,
  onDataTypeSelect = noop,
  showSequenceFields = false
}) => {
  const dataObjectTypeOptions = getDataObjectTypes(dataObjectTypes);
  
  const aclGroupsOptions = getAclGroupsOptions(aclGroups);
  var dataTypeOptions = [];
  if (dataType) {
    dataTypeOptions = getDataTypes(dataType);
  }
  return {
    title: 'New Object',
    isFormDisabled: false,
    formSchema: [
      {
        name: 'Definition',
        type: 'Fields',
        duplicate: false,
        varient: 'full',
        data: {
          0: [
            {
              name: 'Code',
              label: 'Enter Code',
              type: 'text',
              required: false
            },
            {
              name: 'Name',
              label: 'Enter Name',
              type: 'text',
              required: false
            },
            {
              name: 'Type',
              label: 'Select Type',
              type: 'dropdown',
              options: dataObjectTypeOptions,
              overloadOnchange: onDataObjectSelect,
              required: false
            },
            {
              name: 'Description',
              label: 'Enter Description',
              type: 'text',
              required: false
            },
            {
              name: 'ACL Required',
              label: 'ACL Required',
              type: 'toggle',
              options: ['YES', 'NO'],
              overloadOnchange: onAclRequiredSelect,
              required: false,
              showThisField:showAcl,
            },
            {
              name: 'Object Specific',
              label: 'Object Specific',
              type: 'toggle',
              options: ['YES', 'NO'],
              overloadOnchange: onObjectSpecificSelect,
              required: false,
              showThisField: aclSelected
            },
            {
              name: 'Validation Required',
              label: 'Validation Required',
              type: 'toggle',
              options: ['YES', 'NO'],
              required: false,
              showThisField: showValidationRequired
            }
          ]
        }
      },
      {
        name: 'Access Control Policy',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        showThisForm: objectSpecificSelected,
        accordianOpen: 'panel1',
        data: {
          0: [
            {
              name: 'Groups',
              label: 'Select Groups',
              type: 'multiSelect',
              options: aclGroupsOptions,
              required: false
            },
            {
              name: 'Allow',
              label: 'Allow or Deny',
              type: 'toggle',
              options: ['ALLOW', 'DENY'],
              required: false
            },
            {
              name: 'Actions',
              label: 'Actions',
              type: 'multiSelect',
              options: [
                {
                  name: 'READ',
                  value: 'read'
                },
                {
                  name: 'WRITE',
                  value: 'write'
                },
                {
                  name: 'MODIFY',
                  value: 'modify'
                }
              ],
              required: false
            }
          ]
        }
      },
      {
        name: 'Properties',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        addFieldBtnTxt: 'Add',
        data: {
          0: [
            {
              name: 'Code',
              label: 'Enter Code',
              type: 'text',
              required: false
            },
            {
              name: 'Name',
              label: 'Enter Name',
              type: 'text',
              required: false
            },
            {
              name: 'isArray',
              label: 'IsArray',
              options: ['YES', 'NO'],
              type: 'toggle',
              defaultValue: 'NO',
              required: false
            },
            {
              name: 'Data Type',
              label: 'Select Data Type',
              type: 'dropdown',
              options: dataTypeOptions,
              overloadOnchange: onDataTypeSelect,
              required: false
            },
            {
              type: 'button',
              options: dataTypeOptions,
              required: false,
              classname: '!h-[40px]',
              onBtnClick: onValidateBtnClick,
              showThisField: showValidateBtn
            },
            {
              name: 'Optional',
              label: 'Optional',
              type: 'checkbox',
              required: true,
              classname: 'mt-8'
            },
            {
              name: 'Description',
              label: 'Enter Description',
              type: 'text',
              required: false
            },
            {
              name: 'sequencePrefix',
              label: 'sequencePrefix',
              type: 'text',
              required: false,
              showThisField: showSequenceFields
            },
            {
              name: 'sequenceStart',
              label: 'sequenceStart',
              type: 'text',
              required: false,
              showThisField: showSequenceFields
            },
          ]
        }
      }
    ]
  };
};

const editObjectSchema = ({
  dataObjectTypes = [],
  onDataObjectSelect = noop,
  dataType,
  editDataObjectsData = {},
  onAclRequiredSelect = noop,
  aclSelected = false,
  objectSpecificSelected = false,
  onObjectSpecificSelect = noop,
  aclGroups = [],
  showValidateBtn,
  onValidateBtnClick = noop,
  showValidationRequired = false
}) => {
  const {
    code = '',
    objectType = '',
    name = '',
    description = '',
    applicationCode = [],
    aclData = {},
    validationFlag = false,
    editable = false
  } = editDataObjectsData;
  const dataObjectTypeOptions = getDataObjectTypes(dataObjectTypes);
  const aclGroupsOptions = getAclGroupsOptions(aclGroups);
  
  const { groups = [], aclFlag = 0, fieldSpecific = 1 } = aclData;
  const formatValidationsEdiData = (obj) => {
    const result = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[key] = {
          checked: true,
          value: obj[key]
        };
      }
    }

    return result;
  };
  var dataTypeOptions = [];
  if (dataType) {
    dataTypeOptions = getDataTypes(dataType);
  }
  const propertiesData = () => {
    var data = {};
    var arr = [];
    applicationCode.map((ele, index) => {
      const {
        code = '',
        dataObjectTypeId = '',
        name = '',
        description,
        isArray = 0,
        isOptional = 0,
        validationsRule = {}
      } = ele;
      var isArrayData, isOptionalData;
      if (isArray == 1) {
        isArrayData = 'YES';
      } else {
        isArrayData = 'NO';
      }

      if (isOptional == 1) {
        isOptionalData = true;
      } else {
        isOptionalData = false;
      }
      arr.push([
        {
          name: 'Code',
          label: 'Enter Code',
          type: 'text',
          required: false,
          defaultValue: code,
          disabled: true
        },
        {
          name: 'Name',
          label: 'Enter Name',
          type: 'text',
          required: false,
          defaultValue: name
        },
        {
          name: 'isArray',
          label: 'IsArray',
          options: ['YES', 'NO'],
          type: 'toggle',
          defaultValue: isArrayData,
          required: false,
          disabled: !editable
        },
        {
          name: 'Data Type',
          label: 'Select Data Type',
          type: 'dropdown',
          options: dataTypeOptions,
          required: false,
          defaultValue: dataObjectTypeId,
          disabled: !editable
        },
        {
          name: 'Validation Btn',
          type: 'button',
          options: dataTypeOptions,
          required: false,
          classname: '!h-[40px]',
          onBtnClick: onValidateBtnClick,
          showThisField: showValidateBtn,
          validationsEditData: formatValidationsEdiData(validationsRule)
        },
        {
          name: 'Optional',
          label: 'Optional',
          type: 'checkbox',
          defaultValue: isOptionalData,
          classname: 'mt-8'
        },
        {
          name: 'Description',
          label: 'Enter Description',
          type: 'text',
          required: false,
          defaultValue: description
        }
      ]);
    });
    arr.map((ele, index) => {
      data[index] = ele;
    });
    return data;
  };
  const groupsData = () => {
    var data = {};
    var arr = [];
    console.log(groups,"jui")
    groups.map((ele, index) => {
      const { actions = [], allow = 0, codes = [] } = ele;
      var isAllowData;
      if (allow == 1) {
        isAllowData = 'ALLOW';
      } else {
        isAllowData = 'DENY';
      }
      arr.push([
        {
          name: 'Groups',
          label: 'Select Groups',
          type: 'multiSelect',
          options: aclGroupsOptions,
          required: false,
          defaultValue: codes
        },
        {
          name: 'Allow',
          label: 'Allow or Deny',
          type: 'toggle',
          options: ['ALLOW', 'DENY'],
          required: false,
          defaultValue: isAllowData
        },
        {
          name: 'Actions',
          label: 'Actions',
          type: 'multiSelect',
          options: [
            {
              name: 'READ',
              value: 'read'
            },
            {
              name: 'WRITE',
              value: 'write'
            },
            {
              name: 'MODIFY',
              value: 'modify'
            }
          ],
          required: false,
          defaultValue: actions
        }
      ]);
    });
    arr.map((ele, index) => {
      data[index] = ele;
    });
    // return data;
    if(Object.keys(data).length == 0)
    {
      data = {
        0: [
          {
            name: 'Groups',
            label: 'Select Groups',
            type: 'multiSelect',
            options: aclGroupsOptions,
            required: false
          },
          {
            name: 'Allow',
            label: 'Allow or Deny',
            type: 'toggle',
            options: ['ALLOW', 'DENY'],
            required: false
          },
          {
            name: 'Actions',
            label: 'Actions',
            type: 'multiSelect',
            options: [
              {
                name: 'READ',
                value: 'read'
              },
              {
                name: 'WRITE',
                value: 'write'
              },
              {
                name: 'MODIFY',
                value: 'modify'
              }
            ],
            required: false
          }
        ]
      }
      return data;
    }
    else 
    {
      return data
    }
  };
  const aclFlagData = aclFlag == 1 ? 'YES' : 'NO';
  const fieldSpecificData = fieldSpecific == 1 ? 'NO' : 'YES';
  return {
    title: 'Edit Object',
    isFormDisabled: false,
    formSchema: [
      {
        name: 'Definition',
        type: 'Fields',
        duplicate: false,
        varient: 'full',
        data: {
          0: [
            {
              name: 'Code',
              label: 'Enter Code',
              type: 'text',
              required: false,
              defaultValue: code,
              disabled: true
            },
            {
              name: 'Name',
              label: 'Enter Name',
              type: 'text',
              required: false,
              defaultValue: name,
              disabled: true
            },
            {
              name: 'Type',
              label: 'Select Type',
              type: 'dropdown',
              options: dataObjectTypeOptions,
              overloadOnchange: onDataObjectSelect,
              required: false,
              defaultValue: objectType
            },
            {
              name: 'Description',
              label: 'Enter Description',
              type: 'text',
              required: false,
              defaultValue: description
            },
            {
              name: 'ACL Required',
              label: 'ACL Required',
              type: 'toggle',
              options: ['YES', 'NO'],
              overloadOnchange: onAclRequiredSelect,
              required: false,
              defaultValue: aclFlagData
            },
            {
              name: 'Object Specific',
              label: 'Object Specific',
              type: 'toggle',
              options: ['YES', 'NO'],
              overloadOnchange: onObjectSpecificSelect,
              required: false,
              showThisField: aclSelected,
              defaultValue: fieldSpecificData
            },
            {
              name: 'Validation Required',
              label: 'Validation Required',
              type: 'toggle2',
              options: ['YES', 'NO'],
              required: false,
              showThisField: showValidationRequired,
              defaultValue: validationFlag == true ? 'YES' : 'NO'
            }
          ]
        }
      },
      {
        name: 'Access Control Policy',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        showThisForm: objectSpecificSelected,
        accordianOpen: 'panel1',
        data: groupsData()
      },
      {
        name: 'Properties',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        addFieldBtnTxt: 'Add',
        data: propertiesData()
      }
    ]
  };
};
const viewObjectSchema = ({
  dataObjectTypes = [],
  onDataObjectSelect = noop,
  dataType,
  editDataObjectsData = {},
  onAclRequiredSelect = noop,
  aclSelected = false,
  objectSpecificSelected = false,
  onObjectSpecificSelect = noop,
  aclGroups = [],
  isSubActionReview,
  showValidateBtn,
  onValidateBtnClick = noop,
  showValidationRequired = false
}) => {
  const {
    code = '',
    objectType = '',
    name = '',
    description = '',
    applicationCode = [],
    aclData = {},
    validationFlag
  } = editDataObjectsData;
  const dataObjectTypeOptions = getDataObjectTypes(dataObjectTypes);
  const aclGroupsOptions = getAclGroupsOptions(aclGroups);
  const { groups = [], aclFlag = 0, fieldSpecific = 1 } = aclData;
  const formatValidationsEdiData = (obj) => {
    const result = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[key] = {
          checked: true,
          value: obj[key]
        };
      }
    }

    return result;
  };
  var dataTypeOptions = [];
  if (dataType) {
    dataTypeOptions = getDataTypes(dataType);
  }
  const propertiesData = () => {
    var data = {};
    var arr = [];
    applicationCode.map((ele, index) => {
      const {
        code = '',
        dataObjectTypeId = '',
        name = '',
        description,
        isArray = 0,
        isOptional = 0,
        validationsRule = {}
      } = ele;
      var isArrayData, isOptionalData;
      if (isArray == 1) {
        isArrayData = 'YES';
      } else {
        isArrayData = 'NO';
      }

      if (isOptional == 1) {
        isOptionalData = true;
      } else {
        isOptionalData = false;
      }
      arr.push([
        {
          name: 'Code',
          label: 'Enter Code',
          type: 'text',
          required: false,
          defaultValue: code
        },
        {
          name: 'Name',
          label: 'Enter Name',
          type: 'text',
          required: false,
          defaultValue: name
        },
        {
          name: 'isArray',
          label: 'IsArray',
          options: ['YES', 'NO'],
          type: 'toggle',
          defaultValue: isArrayData,
          required: false
        },
        {
          name: 'Data Type',
          label: 'Select Data Type',
          type: 'dropdown',
          options: dataTypeOptions,
          required: false,
          defaultValue: dataObjectTypeId
        },
        {
          name: 'Validation Btn',
          type: 'button',
          options: dataTypeOptions,
          required: false,
          classname: '!h-[40px]',
          onBtnClick: onValidateBtnClick,
          showThisField: showValidateBtn,
          validationsEditData: formatValidationsEdiData(validationsRule)
        },
        {
          name: 'Optional',
          label: 'Optional',
          type: 'checkbox',
          defaultValue: isOptionalData,
          classname: 'mt-8'
        },
        {
          name: 'Description',
          label: 'Enter Description',
          type: 'text',
          required: false,
          defaultValue: description
        }
      ]);
    });
    arr.map((ele, index) => {
      data[index] = ele;
    });
    return data;
  };
  const groupsData = () => {
    var data = {};
    var arr = [];
    groups.map((ele, index) => {
      const { actions = [], allow = 0, codes = [] } = ele;
      var isAllowData;
      if (allow == 1) {
        isAllowData = 'ALLOW';
      } else {
        isAllowData = 'DENY';
      }
      arr.push([
        {
          name: 'Groups',
          label: 'Select Groups',
          type: 'multiSelect',
          options: aclGroupsOptions,
          required: false,
          defaultValue: codes
        },
        {
          name: 'Allow',
          label: 'Allow or Deny',
          type: 'toggle',
          options: ['ALLOW', 'DENY'],
          required: false,
          defaultValue: isAllowData
        },
        {
          name: 'Actions',
          label: 'Actions',
          type: 'multiSelect',
          options: [
            {
              name: 'READ',
              value: 'read'
            },
            {
              name: 'WRITE',
              value: 'write'
            },
            {
              name: 'MODIFY',
              value: 'modify'
            }
          ],
          required: false,
          defaultValue: actions
        }
      ]);
    });
    arr.map((ele, index) => {
      data[index] = ele;
    });
    return data;
  };
  const aclFlagData = aclFlag == 1 ? 'YES' : 'NO';
  const fieldSpecificData = fieldSpecific == 1 ? 'NO' : 'YES';
  return {
    title: `${name}`,
    isFormDisabled: false,
    disableFullForm: true,
    hideTitle: isSubActionReview,
    formSchema: [
      {
        name: 'Definition',
        type: 'Fields',
        duplicate: false,
        varient: 'full',
        data: {
          0: [
            {
              name: 'Code',
              label: 'Enter Code',
              type: 'text',
              required: false,
              defaultValue: code
            },
            {
              name: 'Name',
              label: 'Enter Name',
              type: 'text',
              required: false,
              defaultValue: name
            },
            {
              name: 'Type',
              label: 'Select Type',
              type: 'dropdown',
              options: dataObjectTypeOptions,
              overloadOnchange: onDataObjectSelect,
              required: false,
              defaultValue: objectType
            },
            {
              name: 'Description',
              label: 'Enter Description',
              type: 'text',
              required: false,
              defaultValue: description
            },
            {
              name: 'ACL Required',
              label: 'ACL Required',
              type: 'toggle2',
              options: ['YES', 'NO'],
              overloadOnchange: onAclRequiredSelect,
              required: false,
              defaultValue: aclFlagData
            },
            {
              name: 'Object Specific',
              label: 'Object Specific',
              type: 'toggle',
              options: ['YES', 'NO'],
              overloadOnchange: onObjectSpecificSelect,
              required: false,
              showThisField: aclSelected,
              defaultValue: fieldSpecificData
            },
            {
              name: 'Validation Required',
              label: 'Validation Required',
              type: 'toggle2',
              options: ['YES', 'NO'],
              required: false,
              showThisField: showValidationRequired,
              defaultValue: validationFlag == true ? 'YES' : 'NO'
            }
          ]
        }
      },
      {
        name: 'Access Control Policy',
        type: 'Fields',
        duplicate: false,
        varient: 'full',
        showThisForm: objectSpecificSelected,
        accordianOpen: 'panel1',
        data: groupsData()
      },
      {
        name: 'Properties',
        type: 'Fields',
        duplicate: false,
        varient: 'full',
        addFieldBtnTxt: 'Add',
        data: propertiesData()
      }
    ]
  };
};

const duplicateObjectSchema = ({
  dataObjectTypes = [],
  onDataObjectSelect = noop,
  dataType,
  editDataObjectsData = {},
  onAclRequiredSelect = noop,
  aclSelected = false,
  objectSpecificSelected = false,
  onObjectSpecificSelect = noop,
  aclGroups = [],
  showValidateBtn,
  onValidateBtnClick = noop,
  showValidationRequired = false
}) => {
  const {
    code = '',
    objectType = '',
    name = '',
    description = '',
    applicationCode = [],
    aclData = {},
    validationFlag = false
  } = editDataObjectsData;
  const dataObjectTypeOptions = getDataObjectTypes(dataObjectTypes);
  const aclGroupsOptions = getAclGroupsOptions(aclGroups);
  const { groups = [], aclFlag = 0, fieldSpecific = 1 } = aclData;
  const formatValidationsEdiData = (obj) => {
    const result = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[key] = {
          checked: true,
          value: obj[key]
        };
      }
    }

    return result;
  };
  var dataTypeOptions = [];
  if (dataType) {
    dataTypeOptions = getDataTypes(dataType);
  }
  const propertiesData = () => {
    var data = {};
    var arr = [];
    applicationCode.map((ele, index) => {
      const {
        code = '',
        dataObjectTypeId = '',
        name = '',
        description,
        isArray = 0,
        isOptional = 0,
        validationsRule = {}
      } = ele;
      var isArrayData, isOptionalData;
      if (isArray == 1) {
        isArrayData = 'YES';
      } else {
        isArrayData = 'NO';
      }

      if (isOptional == 1) {
        isOptionalData = true;
      } else {
        isOptionalData = false;
      }
      arr.push([
        {
          name: 'Code',
          label: 'Enter Code :',
          type: 'text',
          required: false,
          defaultValue: code
        },
        {
          name: 'Name',
          label: 'Enter Name :',
          type: 'text',
          required: false,
          defaultValue: name
        },
        {
          name: 'isArray',
          label: 'IsArray',
          options: ['YES', 'NO'],
          type: 'toggle',
          defaultValue: isArrayData,
          required: false
        },
        {
          name: 'Data Type',
          label: 'Select Data Type',
          type: 'dropdown',
          options: dataTypeOptions,
          required: false,
          defaultValue: dataObjectTypeId
        },
        {
          name: 'Validation Btn',
          type: 'button',
          options: dataTypeOptions,
          required: false,
          classname: '!h-[40px]',
          onBtnClick: onValidateBtnClick,
          showThisField: showValidateBtn,
          validationsEditData: formatValidationsEdiData(validationsRule)
        },
        {
          name: 'Optional',
          label: 'Optional',
          type: 'checkbox',
          defaultValue: isOptionalData,
          classname: 'mt-8'
        },
        {
          name: 'Description',
          label: 'Enter Description :',
          type: 'text',
          required: false,
          defaultValue: description
        }
      ]);
    });
    arr.map((ele, index) => {
      data[index] = ele;
    });
    return data;
  };
  const groupsData = () => {
    var data = {};
    var arr = [];
    groups.map((ele, index) => {
      const { actions = [], allow = 0, codes = [] } = ele;
      var isAllowData;
      if (allow == 1) {
        isAllowData = 'ALLOW';
      } else {
        isAllowData = 'DENY';
      }
      arr.push([
        {
          name: 'Groups',
          label: 'Select Groups',
          type: 'multiSelect',
          options: aclGroupsOptions,
          required: false,
          defaultValue: codes
        },
        {
          name: 'Allow',
          label: 'Allow or Deny',
          type: 'toggle',
          options: ['ALLOW', 'DENY'],
          required: false,
          defaultValue: isAllowData
        },
        {
          name: 'Actions',
          label: 'Actions',
          type: 'multiSelect',
          options: [
            {
              name: 'READ',
              value: 'read'
            },
            {
              name: 'WRITE',
              value: 'write'
            },
            {
              name: 'MODIFY',
              value: 'modify'
            }
          ],
          required: false,
          defaultValue: actions
        }
      ]);
    });
    arr.map((ele, index) => {
      data[index] = ele;
    });
    return data;
  };
  const aclFlagData = aclFlag == 1 ? 'YES' : 'NO';
  const fieldSpecificData = fieldSpecific == 1 ? 'NO' : 'YES';
  return {
    title: 'Duplicate Data Object',
    isFormDisabled: false,
    formSchema: [
      {
        name: 'Definition',
        type: 'Fields',
        duplicate: false,
        varient: 'full',
        data: {
          0: [
            {
              name: 'Code',
              label: 'Enter Code',
              type: 'text',
              required: false
            },
            {
              name: 'Name',
              label: 'Enter Name',
              type: 'text',
              required: false
            },
            {
              name: 'Type',
              label: 'Select Type',
              type: 'dropdown',
              options: dataObjectTypeOptions,
              overloadOnchange: onDataObjectSelect,
              required: false,
              defaultValue: objectType
            },
            {
              name: 'Description',
              label: 'Enter Description',
              type: 'text',
              required: false,
              defaultValue: description
            },
            {
              name: 'ACL Required',
              label: 'ACL Required',
              type: 'toggle',
              options: ['YES', 'NO'],
              overloadOnchange: onAclRequiredSelect,
              required: false,
              defaultValue: aclFlagData
            },
            {
              name: 'Object Specific',
              label: 'Object Specific',
              type: 'toggle',
              options: ['YES', 'NO'],
              overloadOnchange: onObjectSpecificSelect,
              required: false,
              showThisField: aclSelected,
              defaultValue: fieldSpecificData
            },
            {
              name: 'Validation Required',
              label: 'Validation Required',
              type: 'toggle2',
              options: ['YES', 'NO'],
              required: false,
              showThisField: showValidationRequired,
              defaultValue: validationFlag == true ? 'YES' : 'NO'
            }
          ]
        }
      },
      {
        name: 'Access Control Policy',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        showThisForm: objectSpecificSelected,
        accordianOpen: 'panel1',
        data: groupsData()
      },
      {
        name: 'Properties',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        addFieldBtnTxt: 'Add',
        data: propertiesData()
      }
    ]
  };
};

export { newObjectSchema, editObjectSchema, duplicateObjectSchema, viewObjectSchema };
