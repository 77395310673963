import request from 'superagent';
import { addNewUserUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const addNewUser = async (payload) => {
  const res = await request
    .post(addNewUserUrl)
    .send(payload)
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${keycloak.token()}`);

  if (res.body.status.code == 'SUCCESS') {
    console.log('User Data submitted successfully');
  } else {
    console.log(res.body.status.message);
  }

  return res;
};

export default addNewUser;
