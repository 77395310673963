import { createSlice } from '@reduxjs/toolkit';

export const genericModalSlice = createSlice({
  name: 'genericModal',
  initialState: { modalKeys: {} },
  reducers: {
    genericModalStateUpdate: (state, action) => {
      state.modalKeys[action.payload.modalKey] = action.payload.isOpen;

      if (action.payload.modalDataKey) {
        state.modalKeys[action.payload.modalDataKey] = action.payload.modalData;
      }

      if (action.payload.formData) {
        state.modalKeys[action.payload.modalKey] = action.payload.formData;
      }
    }
  }
});

export const { genericModalStateUpdate } = genericModalSlice.actions;

export default genericModalSlice.reducer;
