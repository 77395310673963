import { noop } from 'lodash';
import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DataObjectModalGrid from './DataObjectModalGrid';

const ObjectModal = ({ objectMappingData = [], onCloseModal = noop }) => {

  if (!objectMappingData) {
    return null;
  }

  const { keys = [], name, code, description, data_object_id, sub_type } = objectMappingData;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-x-hidden overflow-y-auto">
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div className="relative z-50 bg-white shadow-lg rounded-lg border-4 border-blue-500 p-6">
        <div className="flex justify-end">
          <button onClick={onCloseModal}>
            <HighlightOffIcon className="!text-3xl !w-8 !h-8" />
          </button>
        </div>
        <div className='flex flex-col'>
          {name && (
            <p className="text-lg">
              <strong className='mr-[10px] text-blue-500'>Name:</strong> {name}
            </p>
          )}
          {code && (
            <p className="text-lg">
              <strong className='mr-[10px] text-blue-500'>Code:</strong> {code}
            </p>
          )}
          {description && (
            <p className="text-lg">
              <strong className='mr-[10px] text-blue-500'>Description:</strong> {description}
            </p>
          )}
          {data_object_id && (
            <p className="text-lg">
              <strong className='mr-[10px] text-blue-500'>Data Object Id:</strong> {data_object_id}
            </p>
          )}
          {sub_type && (
            <p className="text-lg">
              <strong className='mr-[10px] text-blue-500'>Sub Type:</strong> {sub_type}
            </p>
          )}
        </div>

        <DataObjectModalGrid data={keys} />
      </div>
    </div>
  );
};

export default ObjectModal;
