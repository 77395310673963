import { noop } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

const ReviewErrorModal = ({ modalKey, onCloseErrorModalClick = noop }) => {
  const modalData = useSelector(
    (store) => store?.store?.genericModal?.modalKeys?.['reviewErrorData']
  );
  const getKeys = (data) => {
    return data.reduce((keys, item) => {
      Object.keys(item).forEach((key) => {
        if (!keys.includes(key)) {
          keys.push(key);
        }
      });
      return keys;
    }, []);
  };

  const keys = getKeys(modalData?.objectMappings);
  return (
    <div className="h-[30vh] bg-white w-[30vw] m-auto mt-[10%] border-none overflow-auto outline-none flex flex-col justify-between items-center p-5">
      <div className="text-center flex items-center m-auto flex-col">
        <div className="">
          <svg
            className="h-12 w-12 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
        </div>
        <h2 className="mt-4 text-xl font-semibold text-gray-800">{modalData?.message}</h2>
      </div>

      <div className="container mx-auto p-4">
        <table className="table-auto w-full">
          <thead>
            <tr>
              {keys.map((key) => (
                <th key={key} className="border px-4 py-2">
                  {key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {modalData?.objectMappings.map((item, index) => (
              <tr key={index}>
                {keys.map((key) => (
                  <td key={key} className="border px-4 py-2">
                    {item[key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-6">
        <button
          onClick={onCloseErrorModalClick}
          className="bg-red-500 text-white px-4 py-2 rounded focus:outline-none">
          Close
        </button>
      </div>
    </div>
  );
};

export default ReviewErrorModal;
