import LeftNode from '../../common/customNode/LeftNode';
import NormalLeftNode from '../../common/customNode/NormalLeftNode';
import NormalRightNode from '../../common/customNode/NormalRightNode';
import RightNode from '../../common/customNode/RightNode';

const LeftCustomNode = ({ data }) => {
  return <LeftNode data={data} />;
};

const RightCustomNode = ({ data }) => {
  return <RightNode data={data} />;
};

const NormalLeftCustomNode = ({ data }) => {
  return <NormalLeftNode data={data} />;
};

const NormalRightCustomNode = ({ data }) => {
  return <NormalRightNode data={data} />;
};
export default {
  leftNode: LeftCustomNode,
  rightNode: RightCustomNode,
  normalRightNode: NormalRightCustomNode,
  normalLeftNode: NormalLeftCustomNode
};
