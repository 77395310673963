import * as React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useQuery, useMutation } from 'react-query';
import axios from 'axios';
import { createparty, getparties } from '../dashboard/constants/applicationRequest';
import keycloak from '../../Keycloak';
import getTenets from '../../services/getTenets';
import createParty from '../../services/createParty';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Pagination from '@mui/material/Pagination';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

export default function CreateParties() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      party: [
        {

          party: '',
          party_description: ''
        }
      ]
    }
  });

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const { fields: party } = useFieldArray({ control, name: 'party' });
  const {
    data: tenets,
    isLoading: tenetsLoading,
    error: tenetsError
  } = useQuery('tenets', getTenets);

  const mutation = useMutation(createParty);
  const [age, setAge] = React.useState('');
  const [partyList, setPartyList] = React.useState([]);
  const rowsPerPage = 10;
  const [page, setPage] = React.useState(1);
  const [status, setStatus] = React.useState('add');
  const token = keycloak.token();
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: {}
  }
  const handleClickOpen = () => {
    setOpen(true);
    reset()
  };

  const handleClose = () => {
    setOpen(false);
    if (status === 'edit') {
      setStatus('add')
    } else {

    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChange = (event) => {
    setAge(event.target.value);
    getPartnerTypeData(event.target.value)
  };
  const getPartnerTypeData = (event) => {
    const token = keycloak.token();
    if (token) {
      axios
        .get(getparties, {
          params: {
            tenantId: event ? event : tenets[0].org_id
          },
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          console.log(res.data.list);
          if (res.data.list.length > 0) {
            setPartyList(res.data.list[0].party);
          } else {
            setPartyList([]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log('Token not available');
    }
  }

  const onSubmit = (data, e, type) => {
    e.preventDefault();
    console.log(status,"create status")
    const payload = {
      tenantId: age,
      operation: status,
      party: {
        name: data.party[0].party,
        description: data.party[0].party_description
      }
    };
    try {
      mutation
        .mutateAsync({ payload })
        .then((response) => {
          if (response.body.status.code === 'SUCCESS') {
            alert(response.body.status.message);
            console.log(response, "create parties");
            handleClose()
            console.log(age, "selected dropdown")
            getPartnerTypeData(age)
          } else {
            console.log(response.body);
            handleClose()
          }
        })
        .catch((err) => {
          console.log('Error:', err);
          handleClose()
        });
    } catch (err) {
      console.log('Error:', err);
    }
  };

  const update = (row, type) => {
    setOpen(true)
    console.log(row, "selected row")
    setValue(`party.${0}.party`, row.name);
    setValue(`party.${0}.party_description`, row.description);
    setStatus(type)
    // const payload = {
    //   tenantId: age,
    //   operation: type,
    //   party: {
    //     name: row.name,
    //     description:row.description
    //   }
    // };
    // console.log(payload)
    // try {
    //   mutation
    //     .mutateAsync({ payload })
    //     .then((response) => {
    //       if (response.body.status.code === 'SUCCESS') {
    //         alert(response.staus);
    //         console.log(response);
    //       } else {
    //         console.log(response.body);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log('Error:', err);
    //     });
    // } catch (err) {
    //   console.log('Error:', err);
    // }
  };
  const deletePartner = (row, type) => {
    setStatus(type)
    console.log(row,"delete row")
    const deletePayload = {
      tenantId: age,
      operation: 'remove',
      party: {
        name: row.name,
        description:row.description
      }
    };
    try {
      console.log(row, "rowwww")
      axios.put(createparty, deletePayload, Api_Config).then((response) => {
        console.log(response,"final resp")
        if (response.data.status.code === 'SUCCESS') {
          alert(response.status.message);
          console.log(response);
          getPartnerTypeData(age)
        } else {
          console.log(response.data);
        }
      })
      // mutation
      //   .mutateAsync({ deletePayload })
      //   .then((response) => {
      //     if (response.body.status.code === 'SUCCESS') {
      //       alert(response.staus);
      //       console.log(response);
      //       getPartnerTypeData(age)
      //     } else {
      //       console.log(response.body);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log('Error:', err);
      //   });
    } catch (err) {
      console.log('Error:', err);
    }
  }
  if (tenetsLoading) {
    return <div>Loading...</div>;
  }

  if (tenetsError) {
    return <div>Error: {tenetsError.message}</div>;
  }

  return (
    <Box className="flex-1 px-[24px] mt-[50px] p-[20px] m-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      {/* <Paper elevation={3} sx={{ py: 3, px: 3 }}> */}
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 3 }}>
        <Typography sx={{ fontFamily: 'roboto', fontWeight: 'bold' }}>Partner List</Typography>
        <Box>
          <FormControl>
            <InputLabel id="party-select-label">Organization Code</InputLabel>
            <Select
              labelId="party-select-label"
              id="party-select"
              value={age}
              label="Organization Code"
              sx={{ width: 223, mr: 2 }}
              size={'small'}
              onChange={handleChange}>
              {tenets.map((data) => (
                <MenuItem key={data.org_id} value={data.org_id}>
                  {data.org_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton aria-label="fingerprint" color="secondary" onClick={handleClickOpen}>
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Partner</TableCell>
              <TableCell align="right">Partner Description</TableCell>

              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partyList.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.description}</TableCell>

                <TableCell align="right">
                  <Button onClick={() => update(row, "edit")}>Edit</Button>
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => deletePartner(row, "remove")}>Remove</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        style={{ alignSelf: 'flex-end', marginTop: '10px' }}
        count={Math.ceil(partyList.length / rowsPerPage)}
        page={page}
        onChange={handleChangePage}
      />
      {/* </Paper> */}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: '1.0rem',
            textTransform: 'uppercase',
            letterSpacing: '0.4',
            // pl: 5
          }}>
          {status === 'edit' ? 'Edit' : 'Add'} Partner
        </DialogTitle>
        <Box sx={{ p: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {party.map((field, index) => (
              <Box key={field.id} sx={{ display: 'flex', flexDirection: 'row', mb: 3 }}>

                <TextField
                  label="Partner Type *"
                  variant="outlined"
                  {...register(`party.${index}.party`)}
                  size="small"
                  sx={{ marginRight: 2 }}
                  disabled = {status === 'edit' ? true : false}
                />
                <TextField
                  label="Partner Description *"
                  variant="outlined"
                  {...register(`party.${index}.party_description`)}
                  size="small"
                  sx={{ marginRight: 2 }}
                />
              </Box>
            ))}
            <Button type="submit" variant="contained" >
              {status != 'edit' ? 'Submit' : 'Update'}
            </Button>
            <Button type="button" variant="contained" sx={{ mx: 4 }} onClick={handleClose}>
              Close
            </Button>
          </form>
        </Box>
      </Dialog>
    </Box>
  );
}
