import { Button, Checkbox, TextField } from '@mui/material';
import { noop } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsEditForm, updateField } from '../../common/form/slices/formSlice';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const ValidationRuleModal = ({ onValidationRuleModalClose = noop }) => {
  const modalData = useSelector((store) => store?.store?.genericModal);
  const paramIndex = modalData?.modalKeys?.paramIndex;

  const dispatch = useDispatch();

  const formStore = useSelector((store) => store?.store?.form?.formData);

  const { action = '' } = useParams();

  function transformObject(inputObject) {
    const transformedObject = {};
    for (const key in inputObject) {
      if (typeof inputObject[key] === 'object' && inputObject[key] != null) {
        return inputObject;
      }
    }

    for (const key in inputObject) {
      if (inputObject.hasOwnProperty(key)) {
        transformedObject[key] = {
          checked: true,
          data: inputObject[key]
        };
      }
    }

    return transformedObject;
  }

  var transformed;

  useEffect(() => {
    if (action == 'edit' || action == 'view' || action == 'copy') {
      let validationData = formStore?.[`validationRule-${paramIndex}`];
      transformed = transformObject(validationData);
      dispatch(
        updateField({
          path: [`validationRule-${paramIndex}`],
          value: transformed
        })
      );
      setValidationRuleIndex(transformed);
    }
  }, []);
  const [validationRuleIndex, setValidationRuleIndex] = useState(
    formStore?.[`validationRule-${paramIndex}`]
  );

  var selectElement = document.getElementById(`type_id-${paramIndex}`);
  var selectedValue = selectElement?.value;

  const dataTypeValue =
    useSelector((store) => store?.store?.form?.formData?.[`type_id-${paramIndex}`]) ||
    selectedValue;

  var validationList = [];
  var errorMessage = '';

  switch (dataTypeValue) {
    case 'DOI000001': {
      validationList.push(
        'minimum',
        'exclusiveMinimum',
        'maximum',
        'exclusiveMaximum',
        'multipleOf'
      );
      break;
    }
    case 'DOI000002': {
      validationList.push('minLength', 'maxLength', 'pattern');
      break;
    }
    default: {
      errorMessage = 'Please Select Data type as Number or String to add Validation Rules';
      break;
    }
  }
  const [ruleStates, setRuleStates] = useState(validationList.map(() => false));

  const toggleInput = (index, rule) => {
    const newRuleStates = [...ruleStates];
    newRuleStates[index] = !newRuleStates[index];
    setRuleStates(newRuleStates);
    const path = [`validationRule-${paramIndex}`];
    setValidationRuleIndex({
      ...validationRuleIndex,
      [rule]: {
        ...(validationRuleIndex?.[rule] || {}),
        checked: newRuleStates[index]
      }
    });
    dispatch(
      updateField({
        path,
        value: {
          ...validationRuleIndex,
          [rule]: {
            ...(validationRuleIndex?.[rule] || {}),
            checked: newRuleStates[index]
          }
        }
      })
    );
    if (!newRuleStates[index]) {
      setValidationRuleIndex({
        ...validationRuleIndex,
        [rule]: {
          ...(validationRuleIndex?.[rule] || {}),
          data: '',
          checked: newRuleStates[index]
        }
      });
      dispatch(
        updateField({
          path,
          value: {
            ...validationRuleIndex,
            [rule]: {
              ...(validationRuleIndex?.[rule] || {}),
              data: '',
              checked: newRuleStates[index]
            }
          }
        })
      );
    }
  };

  const handleInputChange = (index, event, rule) => {
    const { value } = event.target;

    const path = [`validationRule-${paramIndex}`];

    setValidationRuleIndex({
      ...validationRuleIndex,
      [rule]: {
        ...(validationRuleIndex?.[rule] || {}),
        data: value
      }
    });
    dispatch(
      updateField({
        path,
        value: {
          ...validationRuleIndex,
          [rule]: {
            ...(validationRuleIndex?.[rule] || {}),
            data: value
          }
        }
      })
    );

    if (value !== '') {
      dispatch(setIsEditForm(true));
    }
  };

  switch (action) {
    case 'view': {
      return (
        <div className="items-center h-screen m-auto !w-[550px] align-middle !mt-[12%]">
          <div className="bg-white rounded-lg shadow-lg p-6 mx-auto">
            <div className="bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-t-lg py-2 px-4 mb-4">
              <h2 className="text-[24px] font-semibold">Validation Rules:</h2>
            </div>
            <div>
              {validationList.map((rule, index) => {
                const showTextField =
                  formStore?.[`validationRule-${paramIndex}`]?.[`${rule}`]?.checked ||
                  ruleStates[index]
                    ? true
                    : false;
                return (
                  <div key={index} className="flex w-[100%] justify-between mb-2">
                    <div>
                      <label className="text-[20px]">
                        <Checkbox
                          onChange={() => toggleInput(index, rule)}
                          checked={
                            formStore?.[`validationRule-${paramIndex}`]?.[`${rule}`]?.checked ||
                            ruleStates[index]
                          }
                          disabled
                        />
                        {rule}
                      </label>
                    </div>
                    {showTextField && (
                      <div className="float-right">
                        <TextField
                          onChange={(e) => handleInputChange(index, e, rule)}
                          defaultValue={
                            formStore?.[`validationRule-${paramIndex}`]?.[`${rule}`]?.data || ''
                          }
                          disabled
                          key={`${rule}`}
                          className="!ml-5 float-right"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {errorMessage && <h1 className="mb-4">{errorMessage}</h1>}
            <div className="flex justify-between">
              <Button variant={'contained'} onClick={onValidationRuleModalClose}>
                Close
              </Button>
            </div>
          </div>
        </div>
      );
    }
    default: {
      return (
        <div className="items-center h-screen m-auto !w-[550px] align-middle !mt-[12%]">
          <div className="bg-white rounded-lg shadow-lg p-6 mx-auto">
            <div className="bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-t-lg py-2 px-4 mb-4">
              <h2 className="text-[24px] font-semibold">Validation Rules:</h2>
            </div>
            <div>
              {validationList.map((rule, index) => {
                const showTextField =
                  formStore?.[`validationRule-${paramIndex}`]?.[`${rule}`]?.checked ||
                  ruleStates[index]
                    ? true
                    : false;
                return (
                  <div key={index} className="flex w-[100%] justify-between mb-2">
                    <div>
                      <label className="text-[20px]">
                        <Checkbox
                          onChange={() => toggleInput(index, rule)}
                          checked={
                            formStore?.[`validationRule-${paramIndex}`]?.[`${rule}`]?.checked ||
                            ruleStates[index]
                          }
                          // defaultChecked={!!defaultValue}
                          // disabled={disabled || isFormDisabled || disableFullForm}
                        />
                        {rule}
                      </label>
                    </div>
                    {showTextField && (
                      <div className="float-right">
                        <TextField
                          // disabled={disabled || isFormDisabled || disableFullForm}
                          onChange={(e) => handleInputChange(index, e, rule)}
                          // defaultValue={tableFieldsData[`${ele.key}-${row.index}`]}
                          defaultValue={
                            formStore?.[`validationRule-${paramIndex}`]?.[`${rule}`]?.data || ''
                          }
                          key={`${rule}`}
                          className="!ml-5 float-right"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {errorMessage && <h1 className="mb-4">{errorMessage}</h1>}
            <div className="flex justify-between">
              {!errorMessage && (
                <Button variant={'contained'} onClick={onValidationRuleModalClose}>
                  Submit
                </Button>
              )}
              <Button variant={'contained'} onClick={onValidationRuleModalClose}>
                Close
              </Button>
            </div>
          </div>
        </div>
      );
    }
  }
};

export default ValidationRuleModal;
