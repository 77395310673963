import React, { useState } from 'react';
import UserGrid from '../component/UserGrid';
import fetchUsersList from '../../../services/fetchUsersList';
import { useQuery } from 'react-query';

const UserListingContainer = () => {
  const { refetch } = useQuery('User-Data', fetchUsersList(), {
    enabled: false
  });

  const [usersData, setUsersData] = useState([]);
  const fetchUsersData = async () => {
    const refetchUsersData = await refetch();
    const { data, isSuccess } = refetchUsersData;

    if (isSuccess) {
      setUsersData(data?.body?.userObjects);
    }
    return data?.body;
  };

  return <UserGrid title="Users" refetchUsersData={fetchUsersData} usersData={usersData} />;
};

export default UserListingContainer;
