import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import fetchHLFListData from '../../../services/fetchHLFListData';
import { fetchHLFDataAction } from '../../Application/slices/applicationSlice';
import HLFGrid from '../component/HLFGrid';

const HLFGridContainer = () => {
  const dispatch = useDispatch();
  const { refetch } = useQuery('HLF-Data', fetchHLFListData(), {
    enabled: false
  });
  const HLFStoreData = useSelector((store) => store.store.moreApplication.HLFData) || [];

  const fetchHLFData = async () => {
    const refetchData = await refetch();
    const { data, isSuccess } = refetchData;

    if (isSuccess) {
      dispatch(fetchHLFDataAction({ HLFData: data?.body?.hlfNetworkObjects }));
    }
    return data?.body;
  };

  return <HLFGrid title="HLF Networks" fetchHLFData={fetchHLFData} HLFData={HLFStoreData} />;
};

export default HLFGridContainer;
