import { noop } from "lodash";

function getEditChannelSchema(onJoinClick) {
  return {
    title: 'channels',
    disableSubmit: true,
    hideTitle: true,
    formSchema: [
      {
        name: 'Join',
        label: 'join',
        type: 'button',
        onButtonClick: onJoinClick
      }
    ]
  };
}


export default getEditChannelSchema;
