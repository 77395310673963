const testSchema = ({ formData }) => {
  const {
    name = '',
    description = '',
    version = '',
    channels = [],
    organizations = [],
    orderers = [],
    peers = [],
    certificateAuthorities = []
  } = formData || {};

  const orderersSchema = ({ orderers }) => {
    const orderersData = orderers.map((order) => {
      return [{ name: 'Name', label: 'Name :', type: 'text', required: true, defaultValue: order }];
    });

    return Object.assign({}, orderersData);
  };

  const chaincodeSchema = ({ chaincodes }) => {
    const chaincodesData = chaincodes.map((chain) => {
      return [{ name: 'Name', label: 'Name :', type: 'text', required: true, defaultValue: chain }];
    });

    return Object.assign({}, chaincodesData);
  };

  const peerSchema = ({ peers }) => {
    const peersData = peers.map((peer) => {
      const { chaincodeQuery, endorsingPeer, eventSource, ledgerQuery, _nameKey } = peer;

      return [
        { name: 'Name', label: 'Name :', type: 'text', required: true, defaultValue: _nameKey },
        {
          name: 'Endorsing Peer',
          label: 'Endorsing Peer',
          type: 'checkbox',
          required: true,
          defaultValue: endorsingPeer
        },
        {
          name: 'Chain Code Query',
          label: 'Chain Code Query',
          type: 'checkbox',
          required: true,
          defaultValue: chaincodeQuery
        },
        {
          name: 'Ledger Query',
          label: 'Ledger Query',
          type: 'checkbox',
          required: true,
          defaultValue: ledgerQuery
        },
        {
          name: 'Event Source',
          label: 'Event Source',
          type: 'checkbox',
          required: true,
          defaultValue: eventSource
        }
      ];
    });

    return Object.assign({}, peersData);
  };

  const clientSchema = ({ clientConfig = [] }) => {
    const {
      organizationName,
      peerMspPath,
      peerSslTargetNameOverride,
      peerUrl,
      userName,
      peerName
    } = clientConfig;

    const clientConfigData = [
      [
        {
          name: 'peer name',
          label: 'Enter Peer Name',
          type: 'text',
          required: true,
          defaultValue: peerName
        },
        {
          name: 'peer url',
          label: 'Enter Peer Url',
          type: 'text',
          required: true,
          defaultValue: peerUrl
        },
        {
          name: 'peer msp path',
          label: 'Enter Peer MSP Path',
          type: 'text',
          required: true,
          defaultValue: peerMspPath
        },
        {
          name: 'peer ssl target name override',
          label: 'Peer SSL Target Name Override',
          type: 'text',
          defaultValue: peerSslTargetNameOverride,
          required: true
        },
        {
          name: 'user name',
          label: 'Enter User Name',
          type: 'text',
          required: true,
          defaultValue: userName
        },
        {
          name: 'organization name',
          label: 'Enter Organization Name',
          type: 'text',
          defaultValue: organizationName,
          required: true
        }
      ]
    ];

    return Object.assign({}, clientConfigData);
  };

  const channelSchema = () => {
    const channelData = channels.map((channel) => {
      const { _nameKey, orderers, chaincodes, clientConfig, peers } = channel;

      return [
        { name: 'Name', label: 'Name :', type: 'text', required: true, defaultValue: _nameKey },
        {
          name: 'Orderers',
          type: 'Fields',
          duplicate: true,
          data: orderersSchema({ orderers })
        },
        {
          name: 'Chain Code',
          type: 'Fields',
          duplicate: true,
          data: chaincodeSchema({ chaincodes })
        },
        {
          name: 'Peers',
          type: 'Fields',
          duplicate: true,
          data: peerSchema({ peers })
        },
        {
          name: 'Client Config',
          type: 'Fields',
          data: clientSchema({ clientConfig })
        }
      ];
    });

    return Object.assign({}, channelData);
  };

  const certificateAuthoritySchema = ({ certificateAuthorities }) => {
    const certificateAuthorityData = certificateAuthorities.map((certificate) => {
      return [
        { name: 'Name', label: 'Name :', type: 'text', required: true, defaultValue: certificate }
      ];
    });

    return Object.assign({}, certificateAuthorityData);
  };

  const peersSchema = ({ peers }) => {
    const peersData = peers.map((peer) => {
      return [{ name: 'Name', label: 'Name :', type: 'text', required: true, defaultValue: peer }];
    });

    return Object.assign({}, peersData);
  };

  const organizationSchema = () => {
    const organizationData = organizations.map((organization) => {
      const {
        mspid,
        _nameKey,
        certificateAuthorities,
        peers,
        adminPrivateKey: { path: adminPath } = {},
        signedCert: { path: signedPath }
      } = organization;

      return [
        {
          name: 'name',
          label: 'Enter Name',
          type: 'text',
          require: false,
          defaultValue: _nameKey
        },
        {
          name: 'MSPID',
          label: 'Enter MSPID',
          type: 'text',
          require: false,
          defaultValue: mspid
        },
        {
          name: 'Peers',
          type: 'Fields',
          duplicate: true,
          data: peersSchema({ peers })
        },
        {
          name: 'Certificate Authority',
          type: 'Fields',
          duplicate: true,
          data: certificateAuthoritySchema({ certificateAuthorities })
        },
        {
          name: 'admin private key path',
          label: 'Admin Private Key Path',
          type: 'text',
          defaultValue: adminPath,
          require: false
        },
        {
          name: 'signed cart path',
          label: 'Signed Cart Path',
          type: 'text',
          defaultValue: signedPath,
          require: false
        }
      ];
    });

    return Object.assign({}, organizationData);
  };

  const ordererSchema = () => {
    const ordererData = orderers.map((order) => {
      const {
        url,
        grpcOptions,
        tlsCACerts: { path: tlsCACertsPath },
        eventUrl,
        _nameKey
      } = order;

      return [
        {
          name: 'name',
          label: 'Enter Name',
          type: 'text',
          require: false,
          defaultValue: _nameKey
        },
        {
          name: 'url',
          label: 'Enter Url',
          type: 'text',
          require: false,
          defaultValue: url
        },
        {
          name: 'Event Url',
          label: 'Enter Url',
          type: 'text',
          require: false,
          defaultValue: eventUrl
        },
        {
          name: 'TLSCA Cert Path',
          label: 'TLSCA Cert Path',
          type: 'text',
          require: false,
          defaultValue: tlsCACertsPath
        },
        {
          name: 'Grpc Options',
          type: 'Fields',
          duplicate: false,
          data: {
            0: [
              {
                name: 'grpc.http2.max_pings_without_data',
                label: 'grpc.http2.max_pings_without_data',
                type: 'text',
                defaultValue: grpcOptions['grpc.http2.max_pings_without_data'],
                require: false
              },
              {
                name: 'grpc.http2.min_time_between_pings_ms',
                label: 'grpc.http2.min_time_between_pings_ms',
                type: 'text',
                defaultValue: grpcOptions['grpc.http2.min_time_between_pings_ms'],
                require: false
              },
              {
                name: 'grpc.keepalive_permit_without_calls',
                label: 'grpc.keepalive_permit_without_calls',
                defaultValue: grpcOptions['grpc.keepalive_permit_without_calls'],
                type: 'text',
                require: false
              },
              {
                name: 'grpc.keepalive_time_ms',
                label: 'grpc.keepalive_time_ms',
                defaultValue: grpcOptions['grpc.keepalive_time_ms'],
                type: 'text',
                require: false
              },
              {
                name: 'grpc.keepalive_timeout_ms',
                label: 'grpc.keepalive_timeout_ms',
                defaultValue: grpcOptions['grpc.keepalive_timeout_ms'],
                type: 'text',
                require: false
              },
              {
                name: 'grpc.max_receive_message_length',
                label: 'grpc.max_receive_message_length',
                defaultValue: grpcOptions['grpc.max_receive_message_length'],
                type: 'text',
                require: false
              },
              {
                name: 'grpc.max_send_message_length',
                label: 'grpc.max_send_message_length',
                defaultValue: grpcOptions['grpc.max_send_message_length'],
                type: 'text',
                require: false
              },
              {
                name: 'ssl-target-name-override',
                defaultValue: grpcOptions['ssl-target-name-override'],
                label: 'ssl-target-name-override',
                type: 'text',
                require: false
              }
            ]
          }
        }
      ];
    });

    return Object.assign({}, ordererData);
  };

  const peerPeerSchema = () => {
    const peersData = peers.map(function (peer) {
      const { eventUrl, grpcOptions, tlsCACerts, url, _nameKey } = peer || {};

      return [
        {
          name: 'Name',
          defaultValue: _nameKey,
          label: 'Name',
          type: 'text',
          require: false
        },
        {
          name: 'URL',
          defaultValue: url,
          label: 'URL',
          type: 'text',
          require: false
        },
        {
          name: 'Event URL',
          defaultValue: eventUrl,
          label: 'Event URL',
          type: 'text',
          require: false
        },
        {
          name: 'HSCA Cert Path',
          defaultValue: tlsCACerts?.path,
          label: 'HSCA Cert Path',
          type: 'text',
          require: false
        },
        {
          name: 'Grpc Options',
          type: 'Fields',
          duplicate: false,
          varient: 'full',
          data: {
            0: [
              {
                name: 'grpc.http2.max_pings_without_data',
                label: 'grpc.http2.max_pings_without_data',
                type: 'text',
                defaultValue: grpcOptions['grpc.http2.max_pings_without_data'],
                require: false
              },
              {
                name: 'grpc.http2.min_time_between_pings_ms',
                label: 'grpc.http2.min_time_between_pings_ms',
                type: 'text',
                defaultValue: grpcOptions['grpc.http2.min_time_between_pings_ms'],
                require: false
              },
              {
                name: 'grpc.keepalive_permit_without_calls',
                label: 'grpc.keepalive_permit_without_calls',
                defaultValue: grpcOptions['grpc.keepalive_permit_without_calls'],
                type: 'text',
                require: false
              },
              {
                name: 'grpc.keepalive_time_ms',
                label: 'grpc.keepalive_time_ms',
                defaultValue: grpcOptions['grpc.keepalive_time_ms'],
                type: 'text',
                require: false
              },
              {
                name: 'grpc.keepalive_timeout_ms',
                label: 'grpc.keepalive_timeout_ms',
                defaultValue: grpcOptions['grpc.keepalive_timeout_ms'],
                type: 'text',
                require: false
              },
              {
                name: 'grpc.max_receive_message_length',
                label: 'grpc.max_receive_message_length',
                defaultValue: grpcOptions['grpc.max_receive_message_length'],
                type: 'text',
                require: false
              },
              {
                name: 'grpc.max_send_message_length',
                label: 'grpc.max_send_message_length',
                defaultValue: grpcOptions['grpc.max_send_message_length'],
                type: 'text',
                require: false
              },
              {
                name: 'ssl-target-name-override',
                defaultValue: grpcOptions['ssl-target-name-override'],
                label: 'ssl-target-name-override',
                type: 'text',
                require: false
              }
            ]
          }
        }
      ];
    });

    return Object.assign({}, peersData);
  };

  const certificateAuthority = () => {
    const certificateAuthority = certificateAuthorities.map(function (certificate) {
      const { caName, httpOptions, tlsCACerts, url, _nameKey } = certificate;

      return [
        {
          name: 'Name',
          defaultValue: _nameKey,
          label: 'Name',
          type: 'text',
          require: false
        },
        {
          name: 'URL',
          defaultValue: url,
          label: 'URL',
          type: 'text',
          require: false
        },
        {
          name: 'Http Options',
          label: 'Http Options',
          type: 'Fields',
          data: {
            0: [
              {
                name: 'Verify',
                defaultValue: httpOptions?.verify,
                label: 'Verify',
                type: 'checkbox',
                require: false
              }
            ]
          },
          require: false
        },
        {
          name: 'TLSCA Cert Path',
          defaultValue: tlsCACerts?.path,
          label: 'TLSCA Cert Path',
          type: 'text',
          require: false
        },
        {
          name: 'Ca Name',
          defaultValue: caName,
          label: 'Ca Name',
          type: 'text',
          require: false
        }
      ];
    });

    return Object.assign({}, certificateAuthority);
  };

  return {
    title: 'HLF NETWORK',
    formSchema: [
      {
        name: 'Common Details',
        type: 'Fields',
        duplicate: false,
        varient: 'full',
        data: {
          0: [
            { name: 'Name', label: 'Name :', type: 'text', required: true, defaultValue: name },
            {
              name: 'Description',
              label: 'Description',
              type: 'text',
              required: true,
              defaultValue: description
            },
            {
              name: 'Version',
              label: 'Version :',
              type: 'text',
              required: true,
              disabled: true,
              defaultValue: version
            }
          ]
        }
      },
      {
        name: 'Channels',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        data: channelSchema()
      },
      {
        name: 'Organizations',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        data: organizationSchema()
      },
      {
        name: 'Orderers',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        data: ordererSchema()
      },
      {
        name: 'Peers',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        data: peerPeerSchema()
      },
      {
        name: 'Certificate Authorities',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        data: certificateAuthority()
      }
    ]
  };
};

export default testSchema;
