const editApplicationSchema = (code, name, description, baseApplication) => {
  return {
    title: 'Edit Application',
    formSchema: [
      {
        disabled: true,
        defaultValue: code,
        name: 'Code',
        label: 'Enter Code',
        type: 'text',
        required: true,
        validation: {
          type: 'string',
          min: 3
        }
      },
      { name: 'Name', defaultValue: name, label: 'Enter Name', type: 'text', required: true },
      {
        name: 'Description',
        defaultValue: description,
        label: 'Enter Description',
        type: 'text',
        required: true
      },
      {
        name: 'Base Application',
        disabled: true,
        defaultValue: baseApplication,
        label: 'Select Base Application',
        type: 'dropdown',
        options: [{ name: baseApplication, value: baseApplication }],
        required: false
      }
    ]
  };
};

export default editApplicationSchema;
