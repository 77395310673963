import request from 'superagent';
import keycloak from '../Keycloak';

const editOrchestration = async (payload) => {
  const { orchestrationId } = payload;
  const res = await request
    .put(`${process.env.REACT_APP_CONFIG_API}/orchestration/${orchestrationId}`)
    .send(payload)
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${keycloak.token()}`);

  if (res.body.status.code == 'SUCCESS') {
    console.log('Feature data submitted successfully');
  } else {
    console.log(res.body.status.message);
  }

  return res;
};

export default editOrchestration;
