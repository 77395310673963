import { noop } from 'lodash';
import {
  getCommonObjectsOptions,
  getDataObjectsName,
  getFeatureListName,
  getHlfChainCodeOptions,
  getHlfChannelOptions,
  getKeyColumnsOptionsName,
  getNetworkListName,
  getRequestObjectsName,
  getResponseObjectsName,
  getTextFromArray
} from '../utils/getTextFromArray';
//
const newFeatureFormSchema = ({
  featureList,
  onSwitchChange,
  onDataObjectSelect = noop,
  advOptionsFormSelect = noop,
  onHlfNetworkSelect = noop,
  onHlfChannelsSelect = noop,
  queryTypes,
  dataObjects,
  networkList,
  hlfChannelsOptions,
  hlfChainCodeOptions,
  dataObjectKeyColumnsData = [],
  advOptionFeature = 'NORMAL',
  requestObjects,
  responseObjects,
  requestMethods,
  formData,
  action,
  onPathValidate = noop,
  tableData,
  commonObjectsList = [],
  validationRuleClick = noop
}) => {
  const queryTypesOptions = getTextFromArray(queryTypes);
  const requestMethodsOptions = getTextFromArray(requestMethods);
  const dataObjectOptions = getDataObjectsName(dataObjects);
  const requestObjectsOptions = getRequestObjectsName(requestObjects);
  const responseObjectsOptions = getResponseObjectsName(responseObjects);
  const dataObjectKeyColumnsOptions = getKeyColumnsOptionsName(dataObjectKeyColumnsData);
  const featureListOptions = getFeatureListName(featureList);
  const networkListOptions = getNetworkListName(networkList);
  const hlfChannelListOptions = getHlfChannelOptions(hlfChannelsOptions);
  const hlfChainCodeListOptions = getHlfChainCodeOptions(hlfChainCodeOptions);

  const commonObjectsOptions = getCommonObjectsOptions(commonObjectsList);


  console.log("data is ....",action,advOptionFeature)

  switch (action) {
    case 'edit':
      var form_dj;
      if (formData?.featureObjects?.[0]) {
        form_dj = formData?.featureObjects?.[0];
      } else {
        return {};
      }
      var {
        id,
        code,
        name,
        accessType,
        dataObjectName,
        dataObjectId,
        dataObjectCode,
        description,
        responseObjectName,
        responseObjectCode,
        requestObjectName,
        requestObjectCode,
        queryTypeCode,
        requestMethod,
        customResponseObject,
        attributionKeys,
        keyColumns,
        staticParameters,
        featureType,
        featureCondition,
        trueFeatureCode,
        falseFeatureCode,
        transformationFromRequestToDataObjectRequired,
        applicationCode,
        dbOperationRequired,
        iterativeCountExpression,
        iterativeFeatureCode,
        iterativeFeatureName,
        externalId,
        externalMethod,
        externalHost,
        externalPort,
        externalPath,
        externalQuery,
        externalHeaders,
        externalPayload,
        externalAuthType,
        externalAuthCode,
        externalProtocol,
        hlfId,
        hlfNetworkName,
        hlfChannelName,
        hlfChaincodeName,
        hlfChaincodeFunctionName,
        hlfTransientArgs,
        hlfNonTransientArgs,
        requestObjectId,
        responseObjectId,
        preChecks,
        customResponseObject,
        path,
        params
      } = form_dj;
      var trnfrmReq =
        transformationFromRequestToDataObjectRequired == true ? 'REQUIRED' : 'NOT REQUIRED';

      var dbOpReq = dbOperationRequired == true ? 'REQUIRED' : 'NOT REQUIRED';
      const editPreCheckData = () => {
        var arr = [];
        var data = {};
        preChecks.map((ele) => {
          const { featureCode, viewData, priority, status_enabled } = ele;
          arr.push([
            {
              name: 'Feature List',
              label: 'Feature',
              type: 'dropdown',
              options: featureListOptions,
              required: false,
              defaultValue: viewData?.featureCode,
              notValidate: true
            },
            {
              name: 'expected expression',
              label: 'Expected Expression',
              type: 'text',
              required: true,
              defaultValue: viewData?.expected,
              notValidate: true
            },
            {
              name: 'error message',
              label: 'Error Message',
              type: 'text',
              required: true,
              defaultValue: viewData?.errorMessage,
              notValidate: true
            },
            {
              name: 'priority',
              label: 'Priority',
              type: 'numbers',
              required: true,
              defaultValue: priority,
              notValidate: true
            },
            {
              name: 'precheck switch',
              defaultValue: status_enabled,
              label: 'precheck switch',
              type: 'switch',
              required: true,
              overloadOnchange: onSwitchChange,
              notValidate: true
            }
          ]);
        });
        arr.map((ele, index) => {
          data[index] = ele;
        });
        if (Object.keys(data).length) {
          return data;
        } else {
          return {
            0: [
              {
                name: 'Feature List',
                label: 'Feature',
                type: 'dropdown',
                options: featureListOptions,
                required: false,
                notValidate: true
              },
              {
                name: 'expected expression',
                label: 'Expected Expression',
                type: 'text',
                required: true,
                notValidate: true
              },
              {
                name: 'error message',
                label: 'Error Message',
                type: 'text',
                required: true,
                notValidate: true
              },
              {
                name: 'priority',
                label: 'Priority',
                type: 'text',
                required: true,
                notValidate: true
              },
              {
                name: 'precheck switch',
                defaultValue: true,
                label: 'precheck switch',
                type: 'switch',
                required: true,
                overloadOnchange: onSwitchChange,
                notValidate: true
              }
            ]
          };
        }
      };
      switch (advOptionFeature) {
        case 'NORMAL':
          return {
            title: `Edit ${code}`,
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true,
                            defaultValue: code,
                            disabled: true
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true,
                            defaultValue: name,
                            disabled: true
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableEdit',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                
                               
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                },
                                // {
                                //   name: 'Param Type 2',
                                //   type: 'toggle',
                                //   options: ['CLIENT', 'PRIVATE'],
                                //   key: 'param_type_1'
                                // },
                               
                                // {
                                //   name: 'Optional',
                                //   key:  'isoptional',
                                //   type: 'checkbox',
                                  
                                // },
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions,
                              defaultData: params
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            defaultValue: attributionKeys,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            defaultValue: staticParameters,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: editPreCheckData()
              }
            ]
          };
        case 'CONDITIONAL':
          return {
            title: `Edit ${code}`,
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true,
                            defaultValue: code
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true,
                            defaultValue: name
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableEdit',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions,
                              defaultData: params
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            defaultValue: attributionKeys,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            defaultValue: staticParameters,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Condition',
                            label: 'Enter Feature Condition',
                            type: 'text',
                            required: false,
                            defaultValue: featureCondition
                          },
                          {
                            name: 'Feature Condition True',
                            label: 'Select True Feature Condition',
                            type: 'dropdown',
                            options: featureListOptions,
                            required: false,
                            defaultValue: trueFeatureCode
                          },
                          {
                            name: 'Feature Condition False',
                            label: 'Select False Feature Condition',
                            type: 'dropdown',
                            options: featureListOptions,
                            required: false,
                            defaultValue: falseFeatureCode
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: editPreCheckData()
              }
            ]
          };
        case 'ITERATIVE':
          return {
            title: `Edit ${code}`,
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true,
                            defaultValue: code
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true,
                            defaultValue: name
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableEdit',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions,
                              defaultData: params
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            defaultValue: attributionKeys,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            defaultValue: staticParameters,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Count Expression',
                            label: 'Enter Count Expression',
                            type: 'text',
                            required: false,
                            defaultValue: iterativeCountExpression
                          },
                          {
                            name: 'Iterative Feature',
                            label: 'Select Iterative Feature',
                            type: 'dropdown',
                            options: featureListOptions,
                            required: false,
                            defaultValue: iterativeFeatureCode
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: editPreCheckData()
              }
            ]
          };
        case 'EXTERNAL_API_CALL':
          return {
            title: `Edit ${code}`,
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true,
                            defaultValue: code
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true,
                            defaultValue: name
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableEdit',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions,
                              defaultData: params
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            defaultValue: attributionKeys,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            defaultValue: staticParameters,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Method',
                            label: 'Select Method',
                            type: 'dropdown',
                            options: [
                              { name: 'GET', value: 'GET' },
                              { name: 'PUT', value: 'PUT' },
                              { name: 'POST', value: 'POST' },
                              { name: 'DELETE', value: 'DELETE' }
                            ],
                            required: false,
                            defaultValue: externalMethod
                          },
                          {
                            name: 'Protocol',
                            label: 'Select Protocol',
                            type: 'dropdown',
                            options: [
                              { name: 'http', value: 'http' },
                              { name: 'https', value: 'https' }
                            ],
                            required: false,
                            defaultValue: externalProtocol
                          },
                          {
                            name: 'Host',
                            label: 'Enter Host',
                            type: 'text',
                            required: false,
                            defaultValue: externalHost
                          },
                          {
                            name: 'Port',
                            label: 'Enter Port',
                            type: 'text',
                            required: false,
                            defaultValue: externalPort
                          },
                          {
                            name: 'Path',
                            label: 'Enter Path',
                            type: 'text',
                            required: false,
                            defaultValue: externalPath
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Query',
                            label: 'Enter Query',
                            type: 'text',
                            required: false,
                            defaultValue: externalQuery,
                            editor: true,
                          },
                          {
                            name: 'Header',
                            label: 'Enter Header',
                            type: 'text',
                            required: false,
                            defaultValue: externalHeaders,
                            editor: true,
                          },
                          {
                            name: 'Payload',
                            label: 'Enter Payload',
                            type: 'text',
                            required: false,
                            defaultValue: externalPayload,
                            editor: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Authorization Type',
                            label: 'Select Authorization Type',
                            type: 'dropdown',
                            options: [
                              { name: 'No Authorization', value: 'NO_AUTH' },
                              { name: 'Basic', value: 'BASIC' },
                              { name: 'Bearer', value: 'BEARER' }
                            ],
                            required: false,
                            defaultValue: externalAuthType
                          },
                          {
                            name: 'Authorization Code',
                            label: 'Enter Authorization Code',
                            type: 'text',
                            required: false,
                            defaultValue: externalAuthCode
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: editPreCheckData()
              }
            ]
          };
        case 'HLF_CC_FUNCTION':
          return {
            title: `Edit ${code}`,
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true,
                            defaultValue: code
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true,
                            defaultValue: name
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            required: true,
                            defaultValue: accessType
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableEdit',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions,
                              defaultData: params
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            editor: true,
                            defaultValue: attributionKeys,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            editor: true,
                            defaultValue: staticParameters,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Network',
                            label: 'Select Network',
                            type: 'dropdown',
                            options: networkListOptions,
                            overloadOnchange: onHlfNetworkSelect,
                            required: false,
                            defaultValue: hlfNetworkName
                          },
                          {
                            name: 'Channel',
                            label: 'Select Channel',
                            type: 'dropdown',
                            options: hlfChannelListOptions,
                            // overloadOnchange: onHlfChannelsSelect,
                            required: false,
                            defaultValue: hlfChannelName
                          },
                          {
                            name: 'Chain Code',
                            label: 'Select Chain Code',
                            type: 'text',
                            // options: hlfChainCodeListOptions,
                            required: false,
                            defaultValue: hlfChaincodeName
                          },
                          {
                            name: 'Chain Code Function Name',
                            label: 'Chain Code Function Name',
                            type: 'text',
                            required: false,
                            defaultValue: hlfChaincodeFunctionName
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Transient Orgs',
                            label: 'Enter Transient Orgs',
                            type: 'text',
                            required: false,
                            defaultValue: hlfTransientArgs,
                            editor: true
                          },
                          {
                            name: 'Non Transient Orgs',
                            label: 'Enter Non Transient Orgs',
                            type: 'text',
                            required: false,
                            defaultValue: hlfNonTransientArgs,
                            editor: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: editPreCheckData()
              }
            ]
          };
        default:
          return {
            title: `Edit ${code}`,
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true,
                            defaultValue: code
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true,
                            defaultValue: name
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableEdit',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions,
                              defaultData: params
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            defaultValue: attributionKeys,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            defaultValue: staticParameters,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: editPreCheckData()
              }
            ]
          };
      }
    case 'copy':
      var form_dj;
      if (formData?.featureObjects?.[0]) {
        form_dj = formData?.featureObjects?.[0];
      } else {
        return {};
      }
      var {
        id,
        code,
        name,
        accessType,
        dataObjectName,
        dataObjectId,
        dataObjectCode,
        description,
        responseObjectName,
        responseObjectCode,
        requestObjectName,
        requestObjectCode,
        queryTypeCode,
        requestMethod,
        customResponseObject,
        attributionKeys,
        keyColumns,
        staticParameters,
        featureType,
        featureCondition,
        trueFeatureCode,
        trueFeatureName,
        falseFeatureCode,
        falseFeatureName,
        transformationFromRequestToDataObjectRequired,
        applicationCode,
        dbOperationRequired,
        iterativeCountExpression,
        iterativeFeatureCode,
        iterativeFeatureName,
        externalId,
        externalMethod,
        externalHost,
        externalPort,
        externalPath,
        externalQuery,
        externalHeaders,
        externalPayload,
        externalAuthType,
        externalAuthCode,
        externalProtocol,
        hlfId,
        hlfNetworkName,
        hlfChannelName,
        hlfChaincodeName,
        hlfChaincodeFunctionName,
        hlfTransientArgs,
        hlfNonTransientArgs,
        requestObjectId,
        responseObjectId,
        preChecks,
        customResponseObject,
        path,
        params
      } = form_dj;
      var trnfrmReq =
        transformationFromRequestToDataObjectRequired == true ? 'REQUIRED' : 'NOT REQUIRED';

      var dbOpReq = dbOperationRequired == true ? 'REQUIRED' : 'NOT REQUIRED';
      const copyPreCheckData = () => {
        var arr = [];
        var data = {};
        preChecks.map((ele) => {
          const { featureCode, viewData, priority, status_enabled } = ele;
          arr.push([
            {
              name: 'Feature List',
              label: 'Feature',
              type: 'dropdown',
              options: featureListOptions,
              required: false,
              defaultValue: viewData?.featureCode,
              notValidate: true
            },
            {
              name: 'expected expression',
              label: 'Expected Expression',
              type: 'text',
              required: true,
              defaultValue: viewData?.expected,
              notValidate: true
            },
            {
              name: 'error message',
              label: 'Error Message',
              type: 'text',
              required: true,
              defaultValue: viewData?.errorMessage,
              notValidate: true
            },
            {
              name: 'priority',
              label: 'Priority',
              type: 'numbers',
              required: true,
              defaultValue: priority,
              notValidate: true
            },
            {
              name: 'precheck switch',
              defaultValue: status_enabled,
              label: 'precheck switch',
              type: 'switch',
              required: true,
              overloadOnchange: onSwitchChange,
              notValidate: true
            }
          ]);
        });
        arr.map((ele, index) => {
          data[index] = ele;
        });
        if (Object.keys(data).length) {
          return data;
        } else {
          return {
            0: [
              {
                name: 'Feature List',
                label: 'Feature',
                type: 'dropdown',
                options: featureListOptions,
                required: false,
                notValidate: true
              },
              {
                name: 'expected expression',
                label: 'Expected Expression',
                type: 'text',
                required: true,
                notValidate: true
              },
              {
                name: 'error message',
                label: 'Error Message',
                type: 'text',
                required: true,
                notValidate: true
              },
              {
                name: 'priority',
                label: 'Priority',
                type: 'text',
                required: true,
                notValidate: true
              },
              {
                name: 'precheck switch',
                defaultValue: true,
                label: 'precheck switch',
                type: 'switch',
                required: true,
                overloadOnchange: onSwitchChange,
                notValidate: true
              }
            ]
          };
        }
      };
      switch (advOptionFeature) {
        case 'NORMAL':
          return {
            title: 'Duplicate Feature',
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableEdit',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions,
                              defaultData: params
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            defaultValue: attributionKeys,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            defaultValue: staticParameters,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: copyPreCheckData()
              }
            ]
          };
        case 'CONDITIONAL':
          return {
            title: 'Duplicate Feature',
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableEdit',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions,
                              defaultData: params
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            defaultValue: attributionKeys,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            defaultValue: staticParameters,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Condition',
                            label: 'Enter Feature Condition',
                            type: 'text',
                            required: false
                          },
                          {
                            name: 'Feature Condition True',
                            label: 'Select True Feature Condition',
                            type: 'dropdown',
                            options: featureListOptions,
                            required: false
                          },
                          {
                            name: 'Feature Condition False',
                            label: 'Select False Feature Condition',
                            type: 'dropdown',
                            options: featureListOptions,
                            required: false
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: copyPreCheckData()
              }
            ]
          };
        case 'ITERATIVE':
          return {
            title: 'Duplicate Feature',
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableEdit',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions,
                              defaultData: params
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            defaultValue: attributionKeys,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            defaultValue: staticParameters,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Count Expression',
                            label: 'Enter Count Expression',
                            type: 'text',
                            required: false
                          },
                          {
                            name: 'Iterative Feature',
                            label: 'Select Iterative Feature',
                            type: 'dropdown',
                            options: featureListOptions,
                            required: false
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: copyPreCheckData()
              }
            ]
          };
        case 'EXTERNAL_API_CALL':
          return {
            title: 'Duplicate Feature',
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableEdit',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions,
                              defaultData: params
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            defaultValue: attributionKeys,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            defaultValue: staticParameters,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Method',
                            label: 'Select Method',
                            type: 'dropdown',
                            options: [
                              { name: 'GET', value: 'GET' },
                              { name: 'PUT', value: 'PUT' },
                              { name: 'POST', value: 'POST' },
                              { name: 'DELETE', value: 'DELETE' }
                            ],
                            required: false,
                            defaultValue: externalMethod
                          },
                          {
                            name: 'Protocol',
                            label: 'Select Protocol',
                            type: 'dropdown',
                            options: [
                              { name: 'http', value: 'http' },
                              { name: 'https', value: 'https' }
                            ],
                            required: false,
                            defaultValue: externalProtocol
                          },
                          {
                            name: 'Host',
                            label: 'Enter Host',
                            type: 'text',
                            required: false,
                            defaultValue: externalHost
                          },
                          {
                            name: 'Port',
                            label: 'Enter Port',
                            type: 'text',
                            required: false,
                            defaultValue: externalPort
                          },
                          {
                            name: 'Path',
                            label: 'Enter Path',
                            type: 'text',
                            required: false,
                            defaultValue: externalPath
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Query',
                            label: 'Enter Query',
                            type: 'text',
                            required: false,
                            defaultValue: externalQuery,
                            editor: true
                          },
                          {
                            name: 'Header',
                            label: 'Enter Header',
                            type: 'text',
                            required: false,
                            defaultValue: externalHeaders,
                            editor: true
                          },
                          {
                            name: 'Payload',
                            label: 'Enter Payload',
                            type: 'text',
                            required: false,
                            defaultValue: externalPayload,
                            editor: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Authorization Type',
                            label: 'Select Authorization Type',
                            type: 'dropdown',
                            options: [
                              { name: 'No Authorization', value: 'NO_AUTH' },
                              { name: 'Basic', value: 'BASIC' },
                              { name: 'Bearer', value: 'BEARER' }
                            ],
                            required: false,
                            defaultValue: externalAuthType
                          },
                          {
                            name: 'Authorization Code',
                            label: 'Enter Authorization Code',
                            type: 'text',
                            required: false,
                            defaultValue: externalAuthCode
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: copyPreCheckData()
              }
            ]
          };
        case 'HLF_CC_FUNCTION':
          return {
            title: 'Duplicate Feature',
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text'
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            notValidate: true,
                            defaultValue: description
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableEdit',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions,
                              defaultData: params
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: attributionKeys
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: staticParameters
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Network',
                            label: 'Select Network',
                            type: 'dropdown',
                            options: networkListOptions,
                            overloadOnchange: onHlfNetworkSelect,
                            required: false,
                            defaultValue: hlfNetworkName
                          },
                          {
                            name: 'Channel',
                            label: 'Select Channel',
                            type: 'dropdown',
                            options: hlfChannelListOptions,
                            // overloadOnchange: onHlfChannelsSelect,
                            required: false,
                            defaultValue: hlfChannelName
                          },
                          {
                            name: 'Chain Code',
                            label: 'Select Chain Code',
                            type: 'text',
                            // options: hlfChainCodeListOptions,
                            required: false,
                            defaultValue: hlfChaincodeName
                          },
                          {
                            name: 'Chain Code Function Name',
                            label: 'Chain Code Function Name',
                            type: 'text',
                            required: false,
                            defaultValue: hlfChaincodeFunctionName
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Transient Orgs',
                            label: 'Enter Transient Orgs',
                            type: 'text',
                            required: false,
                            defaultValue: hlfTransientArgs,
                            editor: true
                          },
                          {
                            name: 'Non Transient Orgs',
                            label: 'Enter Non Transient Orgs',
                            type: 'text',
                            required: false,
                            defaultValue: hlfNonTransientArgs,
                            editor: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: copyPreCheckData()
              }
            ]
          };
        default:
          return {
            title: 'Duplicate Feature',
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableEdit',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions,
                              defaultData: params
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            defaultValue: attributionKeys,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            defaultValue: staticParameters,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: copyPreCheckData()
              }
            ]
          };
      }
    case 'view':
      var form_dj;
      if (formData?.featureObjects?.[0]) {
        form_dj = formData?.featureObjects?.[0];
      } else {
        return {};
      }
      var {
        id,
        code,
        name,
        accessType,
        dataObjectName,
        dataObjectId,
        dataObjectCode,
        description,
        responseObjectName,
        responseObjectCode,
        requestObjectName,
        requestObjectCode,
        queryTypeCode,
        requestMethod,
        customResponseObject,
        attributionKeys,
        keyColumns,
        staticParameters,
        featureType,
        featureCondition,
        trueFeatureCode,
        trueFeatureName,
        falseFeatureCode,
        falseFeatureName,
        transformationFromRequestToDataObjectRequired,
        applicationCode,
        dbOperationRequired,
        iterativeCountExpression,
        iterativeFeatureCode,
        iterativeFeatureName,
        externalId,
        externalMethod,
        externalHost,
        externalPort,
        externalPath,
        externalQuery,
        externalHeaders,
        externalPayload,
        externalAuthType,
        externalAuthCode,
        externalProtocol,
        hlfId,
        hlfNetworkName,
        hlfChannelName,
        hlfChaincodeName,
        hlfChaincodeFunctionName,
        hlfTransientArgs,
        hlfNonTransientArgs,
        requestObjectId,
        responseObjectId,
        preChecks,
        customResponseObject,
        path,
        params
      } = form_dj;

      var trnfrmReq =
        transformationFromRequestToDataObjectRequired == true ? 'REQUIRED' : 'NOT REQUIRED';

      var dbOpReq = dbOperationRequired == true ? 'REQUIRED' : 'NOT REQUIRED';
      const viewPreCheckData = () => {
        var arr = [];
        var data = {};
        preChecks.map((ele) => {
          const { featureCode, viewData, priority, status_enabled } = ele;
          arr.push([
            {
              name: 'Feature List',
              label: 'Feature',
              type: 'dropdown',
              options: featureListOptions,
              required: false,
              defaultValue: viewData?.featureCode,
              notValidate: true
            },
            {
              name: 'expected expression',
              label: 'Expected Expression',
              type: 'text',
              required: true,
              defaultValue: viewData?.expected,
              notValidate: true
            },
            {
              name: 'error message',
              label: 'Error Message',
              type: 'text',
              required: true,
              defaultValue: viewData?.errorMessage,
              notValidate: true
            },
            {
              name: 'priority',
              label: 'Priority',
              type: 'numbers',
              required: true,
              defaultValue: priority,
              notValidate: true
            },
            {
              name: 'precheck switch',
              defaultValue: status_enabled,
              label: 'precheck switch',
              type: 'switch',
              required: true,
              overloadOnchange: onSwitchChange,
              notValidate: true
            }
          ]);
        });
        arr.map((ele, index) => {
          data[index] = ele;
        });
        if (Object.keys(data).length) {
          return data;
        } else {
          return {
            0: [
              {
                name: 'Feature List',
                label: 'Feature',
                type: 'dropdown',
                options: featureListOptions,
                required: false,
                notValidate: true
              },
              {
                name: 'expected expression',
                label: 'Expected Expression',
                type: 'text',
                required: true,
                notValidate: true
              },
              {
                name: 'error message',
                label: 'Error Message',
                type: 'text',
                required: true,
                notValidate: true
              },
              {
                name: 'priority',
                label: 'Priority',
                type: 'text',
                required: true,
                notValidate: true
              },
              {
                name: 'precheck switch',
                defaultValue: true,
                label: 'precheck switch',
                type: 'switch',
                required: true,
                overloadOnchange: onSwitchChange,
                notValidate: true
              }
            ]
          };
        }
      };
      switch (advOptionFeature) {
        case 'NORMAL':
          return {
            title: `${code}-Feature`,
            isFormDisabled: true,
            disableFullForm: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true,
                            defaultValue: code
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true,
                            defaultValue: name
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableView',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            defaultValue: attributionKeys,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            defaultValue: staticParameters,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: viewPreCheckData()
              }
            ]
          };
        case 'CONDITIONAL':
          return {
            title: `${code}-Feature`,
            isFormDisabled: true,
            disableFullForm: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true,
                            defaultValue: code
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true,
                            defaultValue: name
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableView',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            defaultValue: attributionKeys,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            defaultValue: staticParameters,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Condition',
                            label: 'Enter Feature Condition',
                            type: 'text',
                            required: false
                          },
                          {
                            name: 'Feature Condition True',
                            label: 'Select True Feature Condition',
                            type: 'dropdown',
                            options: featureListOptions,
                            required: false
                          },
                          {
                            name: 'Feature Condition False',
                            label: 'Select False Feature Condition',
                            type: 'dropdown',
                            options: featureListOptions,
                            required: false
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: viewPreCheckData()
              }
            ]
          };
        case 'ITERATIVE':
          return {
            title: `${code}-Feature`,
            isFormDisabled: true,
            disableFullForm: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true,
                            defaultValue: code
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true,
                            defaultValue: name
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableView',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            defaultValue: attributionKeys,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            defaultValue: staticParameters,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Count Expression',
                            label: 'Enter Count Expression',
                            type: 'text',
                            required: false
                          },
                          {
                            name: 'Iterative Feature',
                            label: 'Select Iterative Feature',
                            type: 'dropdown',
                            options: featureListOptions,
                            required: false
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: viewPreCheckData()
              }
            ]
          };
        case 'EXTERNAL_API_CALL':
          return {
            title: `${code}-Feature`,
            isFormDisabled: true,
            disableFullForm: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true,
                            defaultValue: code
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true,
                            defaultValue: name
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableView',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            defaultValue: attributionKeys,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            defaultValue: staticParameters,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Method',
                            label: 'Select Method',
                            type: 'dropdown',
                            options: [
                              { name: 'GET', value: 'GET' },
                              { name: 'PUT', value: 'PUT' },
                              { name: 'POST', value: 'POST' },
                              { name: 'DELETE', value: 'DELETE' }
                            ],
                            required: false,
                            defaultValue: externalMethod
                          },
                          {
                            name: 'Protocol',
                            label: 'Select Protocol',
                            type: 'dropdown',
                            options: [
                              { name: 'http', value: 'http' },
                              { name: 'https', value: 'https' }
                            ],
                            required: false,
                            defaultValue: externalProtocol
                          },
                          {
                            name: 'Host',
                            label: 'Enter Host',
                            type: 'text',
                            required: false,
                            defaultValue: externalHost
                          },
                          {
                            name: 'Port',
                            label: 'Enter Port',
                            type: 'text',
                            required: false,
                            defaultValue: externalPort
                          },
                          {
                            name: 'Path',
                            label: 'Enter Path',
                            type: 'text',
                            required: false,
                            defaultValue: externalPath
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Query',
                            label: 'Enter Query',
                            type: 'text',
                            required: false,
                            defaultValue: externalQuery,
                            editor: true
                          },
                          {
                            name: 'Header',
                            label: 'Enter Header',
                            type: 'text',
                            required: false,
                            defaultValue: externalHeaders,
                            editor: true
                          },
                          {
                            name: 'Payload',
                            label: 'Enter Payload',
                            type: 'text',
                            required: false,
                            defaultValue: externalPayload,
                            editor: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Authorization Type',
                            label: 'Select Authorization Type',
                            type: 'dropdown',
                            options: [
                              { name: 'No Authorization', value: 'NO_AUTH' },
                              { name: 'Basic', value: 'BASIC' },
                              { name: 'Bearer', value: 'BEARER' }
                            ],
                            required: false,
                            defaultValue: externalAuthType
                          },
                          {
                            name: 'Authorization Code',
                            label: 'Enter Authorization Code',
                            type: 'text',
                            required: false,
                            defaultValue: externalAuthCode
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: viewPreCheckData()
              }
            ]
          };
        case 'HLF_CC_FUNCTION':
          return {
            title: `${code}-Feature`,
            isFormDisabled: true,
            disableFullForm: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true,
                            defaultValue: code
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true,
                            defaultValue: name
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            notValidate: true,
                            defaultValue: description
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            required: true,
                            defaultValue: accessType
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableView',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: attributionKeys
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: staticParameters
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Network',
                            label: 'Select Network',
                            type: 'dropdown',
                            options: networkListOptions,
                            overloadOnchange: onHlfNetworkSelect,
                            required: false,
                            defaultValue: hlfNetworkName
                          },
                          {
                            name: 'Channel',
                            label: 'Select Channel',
                            type: 'dropdown',
                            options: hlfChannelListOptions,
                            // overloadOnchange: onHlfChannelsSelect,
                            required: false,
                            defaultValue: hlfChannelName
                          },
                          {
                            name: 'Chain Code',
                            label: 'Select Chain Code',
                            type: 'text',
                            // options: hlfChainCodeListOptions,
                            required: false,
                            defaultValue: hlfChaincodeName
                          },
                          {
                            name: 'Chain Code Function Name',
                            label: 'Chain Code Function Name',
                            type: 'text',
                            required: false,
                            defaultValue: hlfChaincodeFunctionName
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Transient Orgs',
                            label: 'Enter Transient Orgs',
                            type: 'text',
                            required: false,
                            defaultValue: hlfTransientArgs,
                            editor: true
                          },
                          {
                            name: 'Non Transient Orgs',
                            label: 'Enter Non Transient Orgs',
                            type: 'text',
                            required: false,
                            defaultValue: hlfNonTransientArgs,
                            editor: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: viewPreCheckData()
              }
            ]
          };
        default:
          return {
            title: `${code}--Feature`,
            isFormDisabled: true,
            disableFullForm: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true,
                            defaultValue: code
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true,
                            defaultValue: name
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            defaultValue: description,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true,
                            defaultValue: requestMethod
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: accessType,
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true,
                            defaultValue: queryTypeCode
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true,
                            defaultValue: requestObjectId
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect,
                            defaultValue: dataObjectId
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true,
                            defaultValue: responseObjectId
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true,
                            defaultValue: keyColumns
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            defaultValue: path,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'tableView',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true,
                            defaultValue: customResponseObject
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            defaultValue: attributionKeys,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            defaultValue: staticParameters,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: featureType,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: trnfrmReq,
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: dbOpReq,
                            required: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: viewPreCheckData()
              }
            ]
          };
      }
    default:
      switch (advOptionFeature) {
        case 'NORMAL':
          return {
            title: 'New Feature',
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: 'CLIENT',
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'table',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                },
                               
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            defaultValue: advOptionFeature,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: 'REQUIRED',
                            required: false
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: 'REQUIRED',
                            required: false
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                isFormDisabled: true,
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: 'Feature List',
                      label: 'Feature',
                      type: 'dropdown',
                      options: featureListOptions,
                      required: false,
                      notValidate: true
                    },
                    {
                      name: 'expected expression',
                      label: 'Expected Expression',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'error message',
                      label: 'Error Message',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'priority',
                      label: 'Priority',
                      type: 'numbers',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'precheck switch',
                      defaultValue: true,
                      label: 'precheck switch',
                      type: 'switch',
                      required: true,
                      overloadOnchange: onSwitchChange,
                      notValidate: true
                    }
                  ]
                }
              }
            ]
          };
        case 'CONDITIONAL':
          return {
            title: 'New Feature',
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: 'CLIENT',
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'table',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: 'REQUIRED',
                            required: false
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: 'REQUIRED',
                            required: false
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Condition',
                            label: 'Enter Feature Condition',
                            type: 'text',
                            required: false
                          },
                          {
                            name: 'Feature Condition True',
                            label: 'Select True Feature Condition',
                            type: 'dropdown',
                            options: featureListOptions,
                            required: false
                          },
                          {
                            name: 'Feature Condition False',
                            label: 'Select False Feature Condition',
                            type: 'dropdown',
                            options: featureListOptions,
                            required: false
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: 'Feature List',
                      label: 'Feature',
                      type: 'dropdown',
                      options: featureListOptions,
                      required: false,
                      notValidate: true
                    },
                    {
                      name: 'expected expression',
                      label: 'Expected Expression',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'error message',
                      label: 'Error Message',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'priority',
                      label: 'Priority',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'precheck switch',
                      defaultValue: true,
                      label: 'precheck switch',
                      type: 'switch',
                      required: true,
                      overloadOnchange: onSwitchChange,
                      notValidate: true
                    }
                  ]
                }
              }
            ]
          };
        case 'ITERATIVE':
          return {
            title: 'New Feature',
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: 'CLIENT',
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'table',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: 'REQUIRED',
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: 'REQUIRED',
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Count Expression',
                            label: 'Enter Count Expression',
                            type: 'text',
                            required: false
                          },
                          {
                            name: 'Iterative Feature',
                            label: 'Select Iterative Feature',
                            type: 'dropdown',
                            options: featureListOptions,
                            required: false
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: 'Feature List',
                      label: 'Feature',
                      type: 'dropdown',
                      options: featureListOptions,
                      required: false,
                      notValidate: true
                    },
                    {
                      name: 'expected expression',
                      label: 'Expected Expression',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'error message',
                      label: 'Error Message',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'priority',
                      label: 'Priority',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'precheck switch',
                      defaultValue: true,
                      label: 'precheck switch',
                      type: 'switch',
                      required: true,
                      overloadOnchange: onSwitchChange,
                      notValidate: true
                    }
                  ]
                }
              }
            ]
          };
        case 'EXTERNAL_API_CALL':
          return {
            title: 'New Feature',
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: 'CLIENT',
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'table',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: 'REQUIRED',
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: 'REQUIRED',
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Method',
                            label: 'Select Method',
                            type: 'dropdown',
                            options: [
                              { name: 'GET', value: 'GET' },
                              { name: 'PUT', value: 'PUT' },
                              { name: 'POST', value: 'POST' },
                              { name: 'DELETE', value: 'DELETE' }
                            ],
                            required: false
                          },
                          {
                            name: 'Protocol',
                            label: 'Select Protocol',
                            type: 'dropdown',
                            options: [
                              { name: 'http', value: 'http' },
                              { name: 'https', value: 'https' }
                            ],
                            required: false
                          },
                          {
                            name: 'Host',
                            label: 'Enter Host',
                            type: 'text',
                            required: false
                          },
                          {
                            name: 'Port',
                            label: 'Enter Port',
                            type: 'text',
                            required: false
                          },
                          {
                            name: 'Path',
                            label: 'Enter Path',
                            type: 'text',
                            required: false
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Query',
                            label: 'Enter Query',
                            type: 'text',
                            required: false,
                            editor: true
                          },
                          {
                            name: 'Header',
                            label: 'Enter Header',
                            type: 'text',
                            required: false,
                            editor: true
                          },
                          {
                            name: 'Payload',
                            label: 'Enter Payload',
                            type: 'text',
                            required: false,
                            editor: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Authorization Type',
                            label: 'Select Authorization Type',
                            type: 'dropdown',
                            options: [
                              { name: 'No Authorization', value: 'NO_AUTH' },
                              { name: 'Basic', value: 'BASIC' },
                              { name: 'Bearer', value: 'BEARER' }
                            ],
                            required: false
                          },
                          {
                            name: 'Authorization Code',
                            label: 'Enter Authorization Code',
                            type: 'text',
                            required: false
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: 'Feature List',
                      label: 'Feature',
                      type: 'dropdown',
                      options: featureListOptions,
                      required: false,
                      notValidate: true
                    },
                    {
                      name: 'expected expression',
                      label: 'Expected Expression',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'error message',
                      label: 'Error Message',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'priority',
                      label: 'Priority',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'precheck switch',
                      defaultValue: true,
                      label: 'precheck switch',
                      type: 'switch',
                      required: true,
                      overloadOnchange: onSwitchChange,
                      notValidate: true
                    }
                  ]
                }
              }
            ]
          };
        case 'HLF_CC_FUNCTION':
          return {
            title: 'New Feature',
            isFormDisabled: true,
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: 'CLIENT',
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'table',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: 'REQUIRED',
                            required: true
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: 'REQUIRED',
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Network',
                            label: 'Select Network',
                            type: 'dropdown',
                            options: networkListOptions,
                            overloadOnchange: onHlfNetworkSelect,
                            required: false
                          },
                          {
                            name: 'Channel',
                            label: 'Select Channel',
                            type: 'dropdown',
                            options: hlfChannelListOptions,
                            // overloadOnchange: onHlfChannelsSelect,
                            required: false
                          },
                          {
                            name: 'Chain Code',
                            label: 'Select Chain Code',
                            type: 'text',
                            // options: hlfChainCodeListOptions,
                            required: false
                          },
                          {
                            name: 'Chain Code Function Name',
                            label: 'Chain Code Function Name',
                            type: 'text',
                            required: false
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Transient Orgs',
                            label: 'Enter Transient Orgs',
                            type: 'text',
                            required: false,
                            editor: true
                          },
                          {
                            name: 'Non Transient Orgs',
                            label: 'Enter Non Transient Orgs',
                            type: 'text',
                            required: false,
                            editor: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: 'Feature List',
                      label: 'Feature',
                      type: 'dropdown',
                      options: featureListOptions,
                      required: false,
                      notValidate: true
                    },
                    {
                      name: 'expected expression',
                      label: 'Expected Expression',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'error message',
                      label: 'Error Message',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'priority',
                      label: 'Priority',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'precheck switch',
                      defaultValue: true,
                      label: 'precheck switch',
                      type: 'switch',
                      required: true,
                      overloadOnchange: onSwitchChange,
                      notValidate: true
                    }
                  ]
                }
              }
            ]
          };
        default:
          return {
            title: 'New Feature',
            formSchema: [
              {
                name: 'Definitions',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Unique Code',
                            label: 'Code',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Name',
                            label: 'Name',
                            type: 'text',
                            required: true
                          },
                          {
                            name: 'Description',
                            label: 'Description',
                            type: 'text',
                            notValidate: true
                          },
                          {
                            name: 'Request Method',
                            label: 'Request Method',
                            type: 'dropdown',
                            options: requestMethodsOptions,
                            required: true
                          },
                          {
                            name: 'Access',
                            label: 'Access',
                            options: ['CLIENT', 'PRIVATE'],
                            type: 'toggle',
                            defaultValue: 'CLIENT',
                            required: true
                          },
                          {
                            name: 'Query Type',
                            label: 'Query Type',
                            type: 'dropdown',
                            options: queryTypesOptions,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Request Object',
                            label: 'Request Object',
                            type: 'dropdown',
                            options: requestObjectsOptions,
                            required: true
                          },
                          {
                            name: 'Data Object',
                            label: 'Data Object',
                            type: 'dropdown',
                            options: dataObjectOptions,
                            required: true,
                            overloadOnchange: onDataObjectSelect
                          },
                          {
                            name: 'Response Object',
                            label: 'Response Object',
                            type: 'dropdown',
                            options: responseObjectsOptions,
                            required: true
                          },
                          {
                            name: 'Data Object Key Columns',
                            label: 'Data Object Key Columns',
                            type: 'dropdown',
                            options: dataObjectKeyColumnsOptions,
                            required: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Relative URL Path',
                            label: 'Relative URL Path',
                            type: 'btn',
                            required: true,
                            multiline: false,
                            btnFunctionality: onPathValidate,
                            notValidate: true
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: false,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'params table',
                            label: '',
                            type: 'table',
                            required: true,
                            tableData: {
                              tableColumnsData: [
                                {
                                  name: 'Param Type',
                                  type: 'static',
                                  key: 'param_type'
                                },
                                {
                                  name: 'Name',
                                  type: 'static',
                                  key: 'name'
                                },
                                {
                                  name: 'Description',
                                  type: 'text',
                                  key: 'description'
                                },
                                {
                                  name: 'Type',
                                  type: 'dropdown',
                                  key: 'type_id'
                                },
                                {
                                  name: 'Validation Rule',
                                  type: 'staticBtn',
                                  key: 'validationRule',
                                  onClick: validationRuleClick,
                                  btnTxt: 'Validations'
                                }
                              ],
                              tableRowsData: tableData,
                              dataTypeOptions: commonObjectsOptions
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Custom Response Object',
                            label: 'Custom Response Object',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Attribute Keys',
                            label: 'Attribute Keys',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          },
                          {
                            name: 'Static Parameters',
                            label: 'Static Parameters',
                            type: 'text',
                            required: true,
                            editor: true,
                            notValidate: true
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: 'Advanced Options',
                type: 'Fields',
                duplicate: false,
                varient: 'full',
                data: {
                  0: [
                    {
                      name: '',
                      type: 'Fields',
                      duplicate: true,
                      duplicateBtnRqd: false,
                      data: {
                        0: [
                          {
                            name: 'Feature Type',
                            label: 'Feature Type',
                            type: 'dropdown',
                            options: [
                              { name: 'NORMAL', value: 'NORMAL' },
                              { name: 'CONDITIONAL', value: 'CONDITIONAL' },
                              { name: 'ITERATIVE', value: 'ITERATIVE' },
                              { name: 'EXTERNAL_API_CALL', value: 'EXTERNAL_API_CALL' },
                              { name: 'HLF_CC_FUNCTION', value: 'HLF_CC_FUNCTION' }
                            ],
                            overloadOnchange: advOptionsFormSelect,
                            required: false
                          },
                          {
                            name: 'Transformation: Request->Data Object Required',
                            label: 'Transformation: Request->Data Object',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: 'REQUIRED',
                            required: false
                          },
                          {
                            name: 'DataBase Operation Required',
                            label: 'DataBase Operation',
                            type: 'toggle',
                            options: ['REQUIRED', 'NOT REQUIRED'],
                            defaultValue: 'REQUIRED',
                            required: false
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                isFormDisabled: true,
                name: 'Pre Check',
                type: 'Fields',
                duplicate: true,
                varient: 'full',
                addFieldBtnTxt: 'Add Pre Checks',
                data: {
                  0: [
                    {
                      name: 'Feature List',
                      label: 'Feature',
                      type: 'dropdown',
                      options: featureListOptions,
                      required: false,
                      notValidate: true
                    },
                    {
                      name: 'expected expression',
                      label: 'Expected Expression',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'error message',
                      label: 'Error Message',
                      type: 'text',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'priority',
                      label: 'Priority',
                      type: 'numbers',
                      required: true,
                      notValidate: true
                    },
                    {
                      name: 'precheck switch',
                      defaultValue: true,
                      label: 'precheck switch',
                      type: 'switch',
                      required: true,
                      overloadOnchange: onSwitchChange,
                      notValidate: true
                    }
                  ]
                }
              }
            ]
          };
      }
  }
};

export default newFeatureFormSchema;
