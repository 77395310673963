import SideNavLayout from '../layouts/SideNavLayout';
import EditObjectMappingForm from '../modules/Application/component/EditObjectMappingForm';
import NewObjectMappingForm from '../modules/Application/component/NewObjectMappingForm';
import DataObjectContainer from '../modules/Application/container/DataObjectContainer';
import FeatureFormPreCheckContainer from '../modules/Application/container/FeatureFormPreCheckContainer';
import NewObjectFormContainer from '../modules/Application/container/NewObjectFormContainer';
import ObjectMappingContainer from '../modules/Application/container/ObjectMappingContainer';
import OrchestrationsContainer from '../modules/Application/container/OrchestrationsContainer';
import ApplicationV2 from '../modules/ApplicationV2/component/Application';
import ConnectionFrameworkForm from '../modules/ConnectionFramework/components/ConnectionFrameworkForm';
import HLFFormContainer from '../modules/HyperledgerFabricNetworks/container/HLFFormContainer';
import TraceContainer from '../modules/RealTrace/containers/trace.container';
import Review from '../modules/Reviews/container/Review';
import ObjectAuditLog from '../modules/auditLog/containers/ObjectAuditLog';
import ObjectMappingAuditLog from '../modules/auditLog/containers/ObjectMappingAuditLog';
import TransformationAuditLog from '../modules/auditLog/containers/TransformationAuditLog';
import OrchestrationFormContainer from '../modules/orchestrationFormV2/containers/OrchestrationFormContainer';
import { OrchestrationFormDecider } from '../modules/orchestrationFormV2/containers/OrchestrationFormDecider';
import Application from '../pages/Application';
import Variables from '../pages/Variables';
import ConnectionFramework from '../pages/ConnectionFramework';
import HyperledgerFabricNetworks from '../pages/HyperledgerFabricNetworks';
import Login from '../pages/Login/container/login';
import User from '../pages/Users';
import PrivateRoute from '../utils/PrivateRoute';
import getDashboardNavigationSchema from '../utils/navigationUtils/getDashboardNavigationSchema';
import getUserNavigationSchema from '../utils/navigationUtils/getUserNavigationSchema';
import getOrganisationNavigationSchema from '../utils/navigationUtils/getOrganisationNavigationSchema';
import ConnectionLogContainer from '../modules/ConnectionFramework/containers/ConnectionLogContainer';
import ConnectionLogInfoContainer from '../modules/ConnectionFramework/containers/ConnectionLogInfoContainer';
import Canvas from '../modules/Canvas/container/Canvas';
import showCanvas from '../modules/Canvas/container/ShowCanvas';
import HlfExplorerContainer from '../modules/HlfExplorer/container/HlfExplorerContainer';
import OrgTenet from '../modules/Organisation/OrgTenet';
import OrganisationCreation from '../modules/Organisation/CreateOrganisation';
import Organization from '../modules/HyperledgerFabricNetworks/container/Organization';
import CreateParties from '../modules/Organisation/CreateParties';
import getCanvasNavigationSchema from '../utils/navigationUtils/getCanvasNavigationSchema';
import ShowCanvas from '../modules/Canvas/container/ShowCanvas';
import ExcelMapping from '../modules/BulkUploader.js/ExcelMapping';
import { ViewCanvas } from '../modules/Canvas/container/ViewCanvas';
import BulkUploadList from '../modules/BulkUploader.js/BulkUploadList';

const homeRoutes = [
  {
    path: '/',
    element: <Login />
  },
  {
    path: '/dashboard',
    element: (
      <PrivateRoute>
        <SideNavLayout sideNavigationSchema={getDashboardNavigationSchema()}>
          <ApplicationV2 />
        </SideNavLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/users',
    element: (
      <PrivateRoute>
        <SideNavLayout sideNavigationSchema={getUserNavigationSchema({ users: true })}>
          <User />
        </SideNavLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/users/new',
    element: (
      <PrivateRoute>
        <SideNavLayout sideNavigationSchema={getUserNavigationSchema({ newUser: true })}>
          <User pageContext="new" />
        </SideNavLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/features',
    element: (
      <PrivateRoute>
        <Application />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/variables/:app_code',
    element: (
      <PrivateRoute>
        <Variables />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/bulkUploadList/:app_code',
    element: (
      <PrivateRoute>
        <BulkUploadList />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/excelMapping/:app_code',
    element: (
      <PrivateRoute>
        <ExcelMapping />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/features/:action',
    element: (
      <PrivateRoute>
        <FeatureFormPreCheckContainer />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/connections',
    element: (
      <PrivateRoute>
        <ConnectionFramework />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/connections/:action',
    element: (
      <PrivateRoute>
        <ConnectionFrameworkForm />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/connections/:action/:actionId',
    element: (
      <PrivateRoute>
        <ConnectionFrameworkForm />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/auditLog',
    element: (
      <PrivateRoute>
        <ObjectAuditLog />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/auditLog/objectMappings',
    element: (
      <PrivateRoute>
        <ObjectMappingAuditLog />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/auditLog/transformation',
    element: (
      <PrivateRoute>
        <TransformationAuditLog />
      </PrivateRoute>
    )
  },
  {
    path: '/hlf/:action/:networkName',
    element: (
      <PrivateRoute>
        <HLFFormContainer />
      </PrivateRoute>
    )
  },
  {
    path: '/hlf/:action',
    element: (
      <PrivateRoute>
        <HLFFormContainer />
      </PrivateRoute>
    )
  },
  {
    path: '/hlf',
    element: (
      <PrivateRoute>
        <HyperledgerFabricNetworks />
      </PrivateRoute>
    )
  },
  {
    path: '/:app_code/object-mapping/:action/:edit_id',
    element: (
      <PrivateRoute>
        <EditObjectMappingForm />
      </PrivateRoute>
    )
  },
  {
    path: '/:app_code/object-mapping/:action',
    element: (
      <PrivateRoute>
        <NewObjectMappingForm />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/features/:action/:featureId',
    element: (
      <PrivateRoute>
        <FeatureFormPreCheckContainer />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/dataObject/:action',
    element: (
      <PrivateRoute>
        <NewObjectFormContainer />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/dataObject/:action/:dataObjectId',
    element: <NewObjectFormContainer />
  },
  {
    path: '/apps/:app_code/orchestration',
    element: (
      <PrivateRoute>
        <OrchestrationsContainer />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/orchestration/:action',
    element: (
      <PrivateRoute>
        <OrchestrationFormContainer />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/orchestration/:action/:orchestrationId',
    element: (
      <PrivateRoute>
        <OrchestrationFormDecider />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/dataObject',
    element: (
      <PrivateRoute>
        <DataObjectContainer />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/objectMapping',
    element: (
      <PrivateRoute>
        <ObjectMappingContainer />
      </PrivateRoute>
    )
  },
  {
    path: '/trace/:app_code',
    element: (
      <PrivateRoute>
        <TraceContainer />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/trace',
    element: (
      <PrivateRoute>
        <TraceContainer />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/:review',
    element: (
      <PrivateRoute>
        <Review />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/connectionLogs',
    element: (
      <PrivateRoute>
        <ConnectionLogContainer />
      </PrivateRoute>
    )
  },
  {
    path: '/apps/:app_code/connectionLogs/:connectionLogId',
    element: (
      <PrivateRoute>
        <ConnectionLogInfoContainer />
      </PrivateRoute>
    )
  },
  // {
  //   path: '/organisation',
  //   element: (
  //     <PrivateRoute>
  //       <Organization/>
  //     </PrivateRoute>
  //   )
  // },
  {
    path: '/organisation/newOrg',
    element: (
      <PrivateRoute>
        <OrganisationCreation />
      </PrivateRoute>
    )
  },
  {
    path: '/organisation',
    element: (
      <PrivateRoute>
        <SideNavLayout
          sideNavigationSchema={getOrganisationNavigationSchema({ organisation: true })}>
          <Organization />
        </SideNavLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/organisation/partnerType',
    element: (
      <PrivateRoute>
        <SideNavLayout
          sideNavigationSchema={getOrganisationNavigationSchema({ partnerType: true })}>
          <Organization pageContext="partnerType" />
        </SideNavLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/organisation/partner',
    element: (
      <PrivateRoute>
        <SideNavLayout sideNavigationSchema={getOrganisationNavigationSchema({ partner: true })}>
          <Organization pageContext="partner" />
        </SideNavLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/organisation/user',
    element: (
      <PrivateRoute>
        <SideNavLayout sideNavigationSchema={getOrganisationNavigationSchema({ user: true })}>
          <Organization pageContext="user" />
        </SideNavLayout>
      </PrivateRoute>
    )
  },

  {
    path: '/canvas',
    element: (
      <PrivateRoute>
        <SideNavLayout sideNavigationSchema={getCanvasNavigationSchema({ canvas: true })}>
          <Canvas />
        </SideNavLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/canvas/showCanvas',
    element: (
      <PrivateRoute>
        <SideNavLayout sideNavigationSchema={getCanvasNavigationSchema({ showCanvas: true })}>
          <ShowCanvas />
        </SideNavLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/showCanvas',
    element: (
      <PrivateRoute>
        <SideNavLayout sideNavigationSchema={getCanvasNavigationSchema({showCanvas:true})}>
        <ShowCanvas/>
        </SideNavLayout>
      </PrivateRoute>
    )
  }, 
  {
    path: '/viewCanvas',
    element: (
      <PrivateRoute>
        {/* <SideNavLayout sideNavigationSchema={getCanvasNavigationSchema({showCanvas:true})}> */}
        <ViewCanvas />
        {/* </SideNavLayout> */}
      </PrivateRoute>
    )
  },
  {
    path: '/hlfExplorer',
    element: (
      <PrivateRoute>
        <HlfExplorerContainer />
      </PrivateRoute>
    )
  },
  {
    path: '/*',
    element: (
      <div styles={{ width: '100%', height: '0', paddingBottom: '145%', position: 'relative' }}>
        <iframe
          src="https://giphy.com/embed/8L0Pky6C83SzkzU55a"
          width="100%"
          height="100%"
          style={{ position: 'absolute' }}
          frameBorder="0"
          class="giphy-embed"
          allowFullScreen></iframe>
      </div>
    )
  }
];

export default homeRoutes;
