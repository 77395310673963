import React from 'react';
import SideBar from '../../sidebar/containers';

const DefaultDashboard = ({ children, page, hideSideBar }) => {
  return (
    <div className="flex h-[100%] ml-[50px]">
      <SideBar hideSideBar={hideSideBar} page={page}>{children}</SideBar>
    </div>
  );
};

export default DefaultDashboard;
