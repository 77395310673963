import React from 'react';
import DataObject from '../component/DataObject';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';

const DataObjectContainer = () => {
  const { app_code = '' } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNewDataObjectClick = () => {
    navigate(`/apps/${app_code}/dataObject/new`);
  };

  const deleteDataObjectClick = (data, name) => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.deleteDataObjectModal,
        isOpen: true,
        modalDataKey: 'deleteDataObjectModalData',
        modalData: { id: data, name: name }
      })
    );
  };

  return (
    <DataObject
      handleNewDataObjectClick={handleNewDataObjectClick}
      deleteDataObjectClick={deleteDataObjectClick}
    />
  );
};

export default DataObjectContainer;
