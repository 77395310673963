import classNames from 'classnames';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const SubheaderElement = ({ subheaderData }) => {
  const navigate = useNavigate();
  const { app_code } = useParams();

  const subHeaderClassName = (val) =>
    classNames('w-[50%] text-center py-4 px-2 rounded-[12px] cursor-pointer font-semibold', {
      'bg-white shadow-lg': val,
      '': !val
    });

  return (
    <div
      className={subHeaderClassName(subheaderData?.isSelected)}
      onClick={() => subheaderData.onClick(subheaderData, navigate, app_code)}>
      {subheaderData?.name}
    </div>
  );
};

export default SubheaderElement;

{
  /* <div
className={subHeaderClassName(getIfSubheader({ currentContext: context }))}
onClick={() => {
  navigateSubHeader('connectionFramework');
}}>
Extensions
</div> */
}
