import React from 'react';

const AccordianDiv = ({ type = '', children, className = '' }) => {
  switch (type) {
    case 'header': {
      return (
        <div
          className={`w-[100%] text-[18px] bg-white rounded-[10px] shadow-lg h-[48px] text-center pt-[12px] ${className}`}>
          {children}
        </div>
      );
    }
    case 'common': {
      return <div className="w-[100%] text-[18px]">{children}</div>;
    }
  }
};

export default AccordianDiv;
