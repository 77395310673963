const fetchDataObject = `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`;
const applicationDataUrl = `${process.env.REACT_APP_CONFIG_API}/dashboard/applications`;
const moreApplicationDataUrl = `${process.env.REACT_APP_CONFIG_API}/features/appCode`;
const createNewAppUrl = `${process.env.REACT_APP_CONFIG_API}/application`;
const deleteAppUrl = `${process.env.REACT_APP_CONFIG_API}/application/delete`;
const editApplicationUrl = `${createNewAppUrl}`;
const submitObjectMapping = `${process.env.REACT_APP_CONFIG_API}/object-mapping`;
const submitObjectChildMapping = `${process.env.REACT_APP_CONFIG_API}/object-mapping/nestedMapping`;
const updateApplicationUrl = `${process.env.REACT_APP_CONFIG_API}/application`;
const applicationCountUrl = `${process.env.REACT_APP_CONFIG_API}/application/count`;
const featureDataUrl = `${process.env.REACT_APP_CONFIG_API}/features/appCode`;
const objectMappingUrl = `${process.env.REACT_APP_CONFIG_API}/object-mapping/appCode`;
const fetchMatchDataUrl = `${process.env.REACT_APP_CONFIG_API}/object-mapping/detailedNestedObjects`;
const fetchObjectDataUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`;
const fetchTargetObjectDataUrl = `${process.env.REACT_APP_CONFIG_API}/static/get_data_object_types`;
const fetchTargetObjectListDataUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`;
const deleteObjectMappingPropertyUrl = `${process.env.REACT_APP_CONFIG_API}/object-mapping/delete`;
const createNewFeatureUrl = `${process.env.REACT_APP_CONFIG_API}/features`;
const getQueryTypesUrl = `${process.env.REACT_APP_CONFIG_API}/static/get_query_types`;
const getRequestMethodsUrl = `${process.env.REACT_APP_CONFIG_API}/static/get_request_methods`;
const getDataObjectsUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`;
const getRequestObjectsUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects/code`;
const getResponseObjectsUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects`;
const getFeatureListUrl = `${process.env.REACT_APP_CONFIG_API}/features/list`;
const getNetworksUrl = `${process.env.REACT_APP_CONFIG_API}/hlf`;
const orchestrationDataObjectsUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects/code`;
const getSourceActionsUrl = `${process.env.REACT_APP_CONFIG_API}/static/get_source_actions`;
const getDataObjectTypesUrl = `${process.env.REACT_APP_CONFIG_API}/static/get_data_object_types`;
const fetchOrchestration = `${process.env.REACT_APP_CONFIG_API}/orchestration/appCode`;
const fetchHLFListDataUrl = `${process.env.REACT_APP_CONFIG_API}/hlf/networks`;
const fetchNetworkDataUrl = `${process.env.REACT_APP_CONFIG_API}/hlf/id`;
const submitNewObjectMappingUrl = `${process.env.REACT_APP_CONFIG_API}/object-mapping`;
const getUserListUrl = `${process.env.REACT_APP_CONFIG_API}/dashboard/users_list_in_assign_application`;
const addNewUserUrl = `${process.env.REACT_APP_CONFIG_API}/dashboard/assign_user`;
// const fetchNestedDataForMapUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects/detailed`;
// const fetchNestedDataForMapURL = `${process.env.REACT_APP_CONFIG_API}/data-objects/detailedByCode`;
const fetchNestedDataForMapUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects/detailed`;
const fetchNestedDataForMapURL = `${process.env.REACT_APP_CONFIG_API}/data-objects/detailedByCode`;
const getFeatureByIdUrl = `${process.env.REACT_APP_CONFIG_API}/features/id`;
const deleteFeatureUrl = `${process.env.REACT_APP_CONFIG_API}/features/delete`;
const createNewOrchestrationUrl = `${process.env.REACT_APP_CONFIG_API}/orchestration`;
const getDataTypesUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects/appCode`;
const createNewDataObjectUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects`;
const deleteOrchestrationUrl = `${process.env.REACT_APP_CONFIG_API}/orchestration/delete`;
const editOrchestrationDataUrl = `${process.env.REACT_APP_CONFIG_API}/orchestration/id`;
const deleteDataObjectUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects/delete`;
const editObjectUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects/id`;
const commonObjectsUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects/commonObjectList`;
const connectionGridDataUrl = `${process.env.REACT_APP_CONFIG_API}/type/connectors/connections/getAllConnections`;
const fetchGroupsUrl = `${process.env.REACT_APP_CONFIG_API}/roles/groups`;
const auditLogUrl = `${process.env.REACT_APP_CONFIG_API}/auditLog/auditLogCreationTransaction`;
const connectionCountUrl = `${process.env.REACT_APP_CONFIG_API}/type/connectors/connections/countbyAppcode`;
const connectionLogUrl = `${process.env.REACT_APP_CONFIG_API}/type/connectors/connectorlogs/list`;
const connectionLogInfoUrl = `${process.env.REACT_APP_CONFIG_API}/type/connectors/connectorlogs/view`;
const connectionLogRecordInfoUrl = `${process.env.REACT_APP_CONFIG_API}/type/connectors/connectorlogs/record`;
const fetchUsersUrl = `${process.env.REACT_APP_CONFIG_API}/dashboard/all_users`;

//User Management urls

const orgcreation=`${process.env.REACT_APP_CONFIG_API}/organization/tenant`
const deleteOrgCreation = `${process.env.REACT_APP_CONFIG_API}/organization/status`
const suborgcreation=`${process.env.REACT_APP_CONFIG_API}/organization/suborg`
const suborgDelete=`${process.env.REACT_APP_CONFIG_API}/organization/status`
const orgusercreation=`${process.env.REACT_APP_CONFIG_API}/organization/tenant`
const tenatlist=`${process.env.REACT_APP_CONFIG_API}/organization/tenant`
const createparty=`${process.env.REACT_APP_CONFIG_API}/party/update`
const getparties=`${process.env.REACT_APP_CONFIG_API}/party/list`
// const getOrgList =`${process.env.REACT_APP_CONFIG_API}/organization/tenant`
const getUser = `${process.env.REACT_APP_CONFIG_API}/users`
export {
  // user management url
  orgcreation,
  suborgcreation,
  orgusercreation,
  tenatlist,
  deleteOrgCreation,
  createparty,
  getparties,
  suborgDelete,
  getUser,
  fetchNetworkDataUrl,
  fetchHLFListDataUrl,
  submitNewObjectMappingUrl,
  submitObjectMapping,
  fetchOrchestration,
  applicationCountUrl,
  applicationDataUrl,
  moreApplicationDataUrl,
  createNewAppUrl,
  deleteAppUrl,
  updateApplicationUrl,
  editApplicationUrl,
  fetchDataObject,
  featureDataUrl,
  objectMappingUrl,
  fetchMatchDataUrl,
  fetchObjectDataUrl,
  fetchTargetObjectDataUrl,
  fetchTargetObjectListDataUrl,
  deleteObjectMappingPropertyUrl,
  createNewFeatureUrl,
  getQueryTypesUrl,
  getRequestMethodsUrl,
  getDataObjectsUrl,
  getRequestObjectsUrl,
  getResponseObjectsUrl,
  getFeatureListUrl,
  getNetworksUrl,
  orchestrationDataObjectsUrl,
  getSourceActionsUrl,
  getDataObjectTypesUrl,
  submitObjectChildMapping,
  getUserListUrl,
  addNewUserUrl,
  fetchNestedDataForMapUrl,
  fetchNestedDataForMapURL,
  getFeatureByIdUrl,
  deleteFeatureUrl,
  createNewOrchestrationUrl,
  getDataTypesUrl,
  createNewDataObjectUrl,
  deleteOrchestrationUrl,
  editOrchestrationDataUrl,
  deleteDataObjectUrl,
  editObjectUrl,
  commonObjectsUrl,
  connectionGridDataUrl,
  fetchGroupsUrl,
  auditLogUrl,
  fetchUsersUrl,
  connectionCountUrl,
  connectionLogUrl,
  connectionLogInfoUrl,
  connectionLogRecordInfoUrl
};
