
function onSideNavigationClick(componentData, navigate) {
    switch (componentData.value) {
      case 'organisation': {
        navigate('/organisation');
        break;
      }
      case 'partnerType': {
        navigate('/organisation/partnerType');
        break;
      }
      case 'partner': {
        navigate('/organisation/partner');
        break;
      }
      case 'user': {
        navigate('/organisation/user');
        break;
      }
    }
  }
  
  function getOrganisationNavigationSchema({ organisation = false, partnerType = false, partner= false,user = false,canvas=false } = {}) {
    return [
      {
        name: 'Organisation',
        value: 'organisation',
        onClick: onSideNavigationClick,
        isSelected: organisation,
        isDisabled: false,
        imageUrl: 'https://img.icons8.com/ios-glyphs/100/group.png'
      },
      {
        name: 'Partner Type',
        value: 'partnerType',
        onClick: onSideNavigationClick,
        isSelected: partnerType,
        isDisabled: false,
        imageUrl: 'https://img.icons8.com/ios/100/new--v1.png'
      },
      {
        name: 'Partner',
        value: 'partner',
        onClick: onSideNavigationClick,
        isSelected: partner,
        isDisabled: false,
        imageUrl: 'https://img.icons8.com/ios/100/new--v1.png'
      },
      {
        name: 'User',
        value: 'user',
        onClick: onSideNavigationClick,
        isSelected: user,
        isDisabled: false,
        imageUrl: 'https://img.icons8.com/ios/100/new--v1.png'
      },
      {
        name: 'Canvas',
        value: 'canvas',
        onClick: onSideNavigationClick,
        isSelected: user,
        isDisabled: false,
        imageUrl: 'https://img.icons8.com/ios/100/new--v1.png'
      }
    ];
  }
  
  export default getOrganisationNavigationSchema;
  