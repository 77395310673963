import { noop } from 'lodash';
import FormContainer from '../../common/form/container';
import { useMutation } from 'react-query';
import createNewApp from '../../../services/createNewApp';
import { useDispatch, useSelector } from 'react-redux';
import formKeys from '../../common/form/constants/formKeys';
import importIcon from '../../../public/icons/import.svg';
import {
  clearFormState,
  updateFormData,
  updateFormError,
  updateFormState
} from '../../common/form/slices/formSlice';
import DropZone from '../dragndrop/DropZone';
import { toast } from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';

const NewApplicationFormContainer = ({
  check,
  schema,
  onCloseModal = noop,
  modalKey = '',
  isModal = false,
  handleFileChange,
  handleUpload
}) => {
  const mutation = useMutation(createNewApp);

  const dispatch = useDispatch();

  const formStore = useSelector((store) => store.store.form[formKeys.newApplicationForm]) || {};

  const formData = formStore.formData || {};

  const formError = formStore.errorState || {};

  const formState = formStore.formState || 'stale';

  const handleChange = (event) => {
    if (formData.formState !== 'stale') {
      dispatch(
        updateFormState({
          formKey: formKeys.newApplicationForm,
          formState: 'stale'
        })
      );
    }

    const { name, value } = event.target;
    const fieldValue = value;
    dispatch(
      updateFormData({
        formKey: formKeys.newApplicationForm,
        formField: name,
        formData: fieldValue
      })
    );
  };

  const submitForm = async (formData) => {
    const payload = {
      code: formData.Code.value,
      name: formData.Name.value,
      description: formData.Description.value,
      baseCode: formData['Base Application']?.value
    };

    try {
      dispatch(
        updateFormState({
          formKey: formKeys.newApplicationForm,
          formState: 'pending'
        })
      );
      const response = await mutation.mutateAsync(payload);
      if (response.body.status.code == 'SUCCESS') {
        toast.custom((t) => <Success t={t} message={'Application created successfully'} />, {
          duration: 4000
        });
        dispatch(
          updateFormState({
            formKey: formKeys.newApplicationForm,
            formState: 'success'
          })
        );
        onCloseModal();
        dispatch(
          clearFormState({
            formKey: formKeys.newApplicationForm
          })
        );
      } else {
        toast.custom((t) => <ErrorNotify t={t} message={'Application creation failed'} />, {
          duration: 4000
        });
        dispatch(
          updateFormState({
            formKey: formKeys.newApplicationForm,
            formState: 'failed'
          })
        );
        dispatch(
          updateFormError({
            formKey: formKeys.newApplicationForm,
            errorMessage: response.body.status.message
          })
        );
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (
    <div className="w-[60%] mt-[1%] justify-center flex flex-col m-auto align-middle bg-white">
      <div>
        <FormContainer
          isModal={isModal}
          modalKey={modalKey}
          handleChange={handleChange}
          submitForm={submitForm}
          schema={schema}
          formData={formData}
          formError={formError}
          formKey={formKeys.newApplicationForm}
          formState={formState}
          variant="import"
        />
      </div>
      <div className="flex bg-gray-300 mx-[24px] px-4 py-2 rounded-md text-black items-center justify-between">
        <div>You can also drag and drop your json files</div>
        <div>
          <img
            width="28"
            height="28"
            src="https://img.icons8.com/color/48/error--v1.png"
            alt="error--v1"
          />
        </div>
      </div>
      <DropZone />
    </div>
  );
};

export default NewApplicationFormContainer;
