import React from 'react';
import ApplicationTilesContainer from '../container/ApplicationTiles';
import DataObjectGridContainer from '../container/DataObjectsGridContainer';
import SideBar from '../../ApplicationV2/component/SideBar';
import { useNavigate, useParams } from 'react-router-dom';
import GenericModalContainer from '../../modals/genericModal/container';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import DeleteDataObjectModalContainer from '../container/DeleteDataObjectModalContainer';
import { useDispatch } from 'react-redux';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ConformationContainer from '../../common/conformationModal/containers';
import { noop } from 'lodash';
import TopCardSubheader from '../../../layouts/TopCardSubheader';
import getApplicationTopNavigationSchema from '../../../utils/navigationUtils/getApplicationTopNavigationSchema';
import fetchApplicationCount from '../../../services/fetchApplicationCount';
import formatFeatureCountData from '../../../utils/formatFeatureCountData';
import getExtensionSubheaderSchema from '../../../utils/navigationUtils/getExtensionSubheaderSchema';

const DataObject = ({ deleteDataObjectClick }) => {
  const { app_code = '' } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onMenuItemClick = ({ title }) => {
    switch (title) {
      case 'Objects': {
        navigate(`/apps/${app_code}/dataObject`);
        break;
      }
      case 'New Object': {
        navigate(`/apps/${app_code}/dataObject/new`);
        break;
      }
    }
  };

  return (
    <div className="flex">
      <SideBar
        page={'object'}
        onMenuItemClick={onMenuItemClick}
        currentContext={{ object: 'Objects' }}
      />
      <div className="flex-1 flex flex-col bg-[#F4F3FA]">
        <TopCardSubheader
          topCardNavigationSchema={getApplicationTopNavigationSchema({ objects: true })}
          getCountService={fetchApplicationCount(app_code)}
          formatCountData={formatFeatureCountData}
          subheaderNavigationSchema={getExtensionSubheaderSchema({ application: true })}>
          {/* <ApplicationTilesContainer /> */}
          <DataObjectGridContainer deleteDataObjectClick={deleteDataObjectClick} />
        </TopCardSubheader>
      </div>
      <GenericModalContainer modalKey={ModalKeys.deleteDataObjectModal}>
        <DeleteDataObjectModalContainer />
      </GenericModalContainer>
    </div>
  );
};

export default DataObject;
