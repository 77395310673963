import featureImage from '../../../public/icons/user.png';
import orchestrationImage from '../../../public/icons/orchestration.png';
import dataObjectImage from '../../../public/icons/dataObject2.png';
import dataMappingImage from '../../../public/icons/dataMapping.png';
import applicationTypes from '../../../constants/applicationTypes';
import connectionFrameworkTypes from '../../../constants/connectionFrameworkTypes';
import reviewTypes from '../../../constants/reviewTypes';

const applicationTilesData = [
  { name: applicationTypes.features, image: featureImage },
  { name: applicationTypes.orchestration, image: orchestrationImage },
  { name: applicationTypes.dataObject, image: dataObjectImage },
  { name: applicationTypes.objectMapping, image: dataMappingImage }
];

const connectionFrameworkData = [
  { name: connectionFrameworkTypes.connections, image: featureImage },
  { name: connectionFrameworkTypes.review, image: orchestrationImage },
  { name: connectionFrameworkTypes.trace, image: dataObjectImage, noCount: true },
  { name: connectionFrameworkTypes.auditLogExplorer, image: dataObjectImage, noCount: true }
];

const reviewFrameworkData = [
  { name: reviewTypes.objects, image: featureImage },
  { name: reviewTypes.objectMapping, image: featureImage }
];

export { applicationTilesData, connectionFrameworkData, reviewFrameworkData };
