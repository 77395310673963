import request from 'superagent';
import { applicationCountUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const fetchApplicationCount = (app_code) => {
  return async () => {
    const res = await request
      .get(applicationCountUrl)
      .query({ appCode: app_code })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

export default fetchApplicationCount;
