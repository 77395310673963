

const testSchema = () => {
  return {
    title: 'HLF NETWORK',
    formSchema: [
      {
        name: 'Common Details',
        type: 'Fields',
        duplicate: false,
        varient: 'full',
        data: {
          0: [
            { name: 'Name', label: 'Name :', type: 'text', required: true, validation: { min: 2 } },
            {
              name: 'Description',
              label: 'Description',
              type: 'text',
              required: true
            },
            {
              name: 'Version',
              label: 'Version :',
              type: 'text',
              required: true,
              disabled: false
            }
          ]
        }
      },
      {
        name: 'Channels',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        data: {
          0: [
            { name: 'Name', label: 'Name :', type: 'text', required: true },
            {
              name: 'Orderers',
              type: 'Fields',
              duplicate: true,
              data: {
                0: [
                  {
                    name: 'Name',
                    label: 'Name :',
                    type: 'text',
                    required: true
                  }
                ]
              }
            },
            {
              name: 'Chain Code',
              type: 'Fields',
              duplicate: true,
              data: {
                0: [
                  {
                    name: 'Name',
                    label: 'Name :',
                    type: 'text',
                    required: true
                  }
                ]
              }
            },
            {
              name: 'Peers',
              type: 'Fields',
              duplicate: true,
              data: {
                0: [
                  {
                    name: 'Name',
                    label: 'Name :',
                    type: 'text',
                    required: true
                  },
                  {
                    name: 'Endorsing Peer',
                    label: 'Endorsing Peer',
                    type: 'checkbox',
                    required: true
                  },
                  {
                    name: 'Chain Code Query',
                    label: 'Chain Code Query',
                    type: 'checkbox',
                    required: true
                  },
                  {
                    name: 'Ledger Query',
                    label: 'Ledger Query',
                    type: 'checkbox',
                    required: true
                  },
                  {
                    name: 'Event Source',
                    label: 'Event Source',
                    type: 'checkbox',
                    required: true
                  }
                ]
              }
            },
            {
              name: 'Client Config',
              type: 'Fields',
              data: {
                0: [
                  {
                    name: 'peer name',
                    label: 'Enter Peer Name',
                    type: 'text',
                    required: true
                  },
                  {
                    name: 'peer url',
                    label: 'Enter Peer Url',
                    type: 'text',
                    required: true
                  },
                  {
                    name: 'peer msp path',
                    label: 'Enter Peer MSP Path',
                    type: 'text',
                    required: true
                  },
                  {
                    name: 'peer ssl target name override',
                    label: 'Peer SSL Target Name Override',
                    type: 'text',
                    required: true
                  },
                  {
                    name: 'user name',
                    label: 'Enter User Name',
                    type: 'text',
                    required: true
                  },
                  {
                    name: 'organization name',
                    label: 'Enter Organization Name',
                    type: 'text',
                    required: true
                  }
                ]
              }
            }
          ]
        }
      },
      {
        name: 'Organizations',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        data: {
          0: [
            {
              name: 'name',
              label: 'Enter Name',
              type: 'text',
              require: false
            },
            {
              name: 'MSPID',
              label: 'Enter MSPID',
              type: 'text',
              require: false
            },
            {
              name: 'Peers',
              type: 'Fields',
              duplicate: true,
              data: {
                0: [
                  {
                    name: 'Name',
                    label: 'Name :',
                    type: 'text',
                    required: true
                  }
                ]
              }
            },
            {
              name: 'Certificate Authority',
              type: 'Fields',
              duplicate: true,
              data: {
                0: [
                  {
                    name: 'Name',
                    label: 'Name :',
                    type: 'text',
                    required: true
                  }
                ]
              }
            },
            {
              name: 'admin private key path',
              label: 'Admin Private Key Path',
              type: 'text',
              require: false
            },
            {
              name: 'signed cart path',
              label: 'Signed Cart Path',
              type: 'text',
              require: false
            }
          ]
        }
      },
      {
        name: 'Orderers',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        data: {
          0: [
            {
              name: 'name',
              label: 'Enter Name',
              type: 'text',
              require: false
            },
            {
              name: 'url',
              label: 'Enter Url',
              type: 'text',
              require: false
            },
            {
              name: 'Event Url',
              label: 'Enter Url',
              type: 'text',
              require: false
            },
            {
              name: 'TLSCA Cert Path',
              label: 'TLSCA Cert Path',
              type: 'text',
              require: false
            },
            {
              name: 'Grpc Options',
              type: 'Fields',
              duplicate: false,
              data: {
                0: [
                  {
                    name: 'grpc.http2.max_pings_without_data',
                    label: 'grpc.http2.max_pings_without_data',
                    type: 'text',
                    require: false
                  },
                  {
                    name: 'grpc.http2.min_time_between_pings_ms',
                    label: 'grpc.http2.min_time_between_pings_ms',
                    type: 'text',
                    require: false
                  },
                  {
                    name: 'grpc.keepalive_permit_without_calls',
                    label: 'grpc.keepalive_permit_without_calls',
                    type: 'text',
                    require: false
                  },
                  {
                    name: 'grpc.keepalive_time_ms',
                    label: 'grpc.keepalive_time_ms',
                    type: 'text',
                    require: false
                  },
                  {
                    name: 'grpc.keepalive_timeout_ms',
                    label: 'grpc.keepalive_timeout_ms',
                    type: 'text',
                    require: false
                  },
                  {
                    name: 'grpc.max_receive_message_length',
                    label: 'grpc.max_receive_message_length',
                    type: 'text',
                    require: false
                  },
                  {
                    name: 'grpc.max_send_message_length',
                    label: 'grpc.max_send_message_length',
                    type: 'text',
                    require: false
                  },
                  {
                    name: 'ssl-target-name-override',
                    label: 'ssl-target-name-override',
                    type: 'text',
                    require: false
                  }
                ]
              }
            }
          ]
        }
      },
      {
        name: 'Peers',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        data: {
          0: [
            {
              name: 'Name',
              label: 'Name',
              type: 'text',
              require: false
            },
            {
              name: 'URL',
              label: 'URL',
              type: 'text',
              require: false
            },
            {
              name: 'Event URL',
              label: 'Event URL',
              type: 'text',
              require: false
            },
            {
              name: 'HSCA Cert Path',
              label: 'HSCA Cert Path',
              type: 'text',
              require: false
            },
            {
              name: 'Grpc Options',
              type: 'Fields',
              duplicate: false,
              varient: 'full',
              data: {
                0: [
                  {
                    name: 'grpc.http2.max_pings_without_data',
                    label: 'grpc.http2.max_pings_without_data',
                    type: 'text',
                    require: false
                  },
                  {
                    name: 'grpc.http2.min_time_between_pings_ms',
                    label: 'grpc.http2.min_time_between_pings_ms',
                    type: 'text',
                    require: false
                  },
                  {
                    name: 'grpc.keepalive_permit_without_calls',
                    label: 'grpc.keepalive_permit_without_calls',
                    type: 'text',
                    require: false
                  },
                  {
                    name: 'grpc.keepalive_time_ms',
                    label: 'grpc.keepalive_time_ms',
                    type: 'text',
                    require: false
                  },
                  {
                    name: 'grpc.keepalive_timeout_ms',
                    label: 'grpc.keepalive_timeout_ms',
                    type: 'text',
                    require: false
                  },
                  {
                    name: 'grpc.max_receive_message_length',
                    label: 'grpc.max_receive_message_length',
                    type: 'text',
                    require: false
                  },
                  {
                    name: 'grpc.max_send_message_length',
                    label: 'grpc.max_send_message_length',
                    type: 'text',
                    require: false
                  },
                  {
                    name: 'ssl-target-name-override',
                    label: 'ssl-target-name-override',
                    type: 'text',
                    require: false
                  }
                ]
              }
            }
          ]
        }
      },
      {
        name: 'Certificate Authorities',
        type: 'Fields',
        duplicate: true,
        varient: 'full',
        data: {
          0: [
            {
              name: 'Name',
              label: 'Name',
              type: 'text',
              require: false
            },
            {
              name: 'URL',
              label: 'URL',
              type: 'text',
              require: false
            },
            {
              name: 'Http Options',
              label: 'Http Options',
              type: 'Fields',
              data: {
                0: [
                  {
                    name: 'Verify',
                    label: 'Verify',
                    type: 'checkbox',
                    require: false
                  }
                ]
              },
              require: false
            },
            {
              name: 'TLSCA Cert Path',
              label: 'TLSCA Cert Path',
              type: 'text',
              require: false
            },
            {
              name: 'Ca Name',
              label: 'Ca Name',
              type: 'text',
              require: false
            }
          ]
        }
      }
    ]
  };
};


export default testSchema;