import request from 'superagent';
import { suborgcreation } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const CreateSubOrg = async ({ payload, query }) => {
  const res = await request
    .post(suborgcreation)
    .query(query)
    .send(payload)
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${keycloak.token()}`);

  if (res.body.status.code == 'SUCCESS') {
    console.log('Form data edited successfully');
  } else {
    console.log(res.body.status.message);
  }

  return res;
};

export default CreateSubOrg;
