import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { deleteFormObjectByKey } from '../../form/slices/formSlice';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ConformationContainer from '../../conformationModal/containers';
import ModalKeys from '../../../modals/genericModal/constants/GenericModalKeys';
import { noop } from 'lodash';
import { genericModalStateUpdate } from '../../../modals/slices/genericModalSlice';
import GetFormComponentsV3 from './GetFormComponentsV3';

const FieldsComponentsV3 = ({
  restrictRerender = false,
  form,
  path,
  disableFullForm = {},
  showTable
}) => {
  const dispatch = useDispatch();
  const {
    name,
    data,
    varient,
    duplicate,
    addFieldBtnTxt = 'Add Field',
    options = [],
    duplicateBtnRqd = true,
    isFormDisabled,
    accordianOpen = false,
    onBtnClick
  } = form;
  const [currentFieldNumber, setCurrentFieldNumber] = useState(Object.keys(data || {}).length);
  const [expanded, setExpanded] = useState(accordianOpen);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [fieldData, setFieldData] = useState({ ...data });
  useEffect(() => {
    setFieldData({ ...data });
    setCurrentFieldNumber(Object.keys(data || {}).length);
  }, [JSON.stringify(data)]);

  const fieldsClass = classNames(
    'flex flex-col',
    { 'w-[100%] border-b-8 last:border-b-0': varient == 'full' },
    {
      '': varient !== 'full'
    }
  );
  const fieldsHeader = classNames('flex justify-between bg-[#F9F9F9]', {
    'w-[100%]': varient == 'full'
  });
  const fieldChildClasses = classNames('flex mx-2 flex-wrap items-center my-2', {
    '': varient == 'full'
  });

  const addField = () => {
    const newFieldNumber = currentFieldNumber + 2;

    let newDataArray = {
      0: data[0].map((item) => {
        if (item.name === 'sequencePrefix' || item.name === 'sequenceStart') {
          return { ...item, showThisField: false };
        }
        return item;
      })
    };

    const newData = [...newDataArray[0]].map((element) => {
      if (element.type == 'button') {
        var { onBtnClick } = element;
      }
      var newEle = JSON.parse(JSON.stringify(element));
      if (element.type == 'button') {
        newEle['onBtnClick'] = onBtnClick;
      }
      if (newEle.data) {
        const inside = newEle.data[0][0];
        delete inside.disabled;
        delete inside.defaultValue;
        delete inside.isRemoveOff;

        return newEle;
      } else {
        delete newEle.disabled;
        delete newEle.defaultValue;
        delete newEle.isRemoveOff;
        return newEle;
      }
    });

    setCurrentFieldNumber(newFieldNumber + 1);

    try {
      setFieldData({
        ...fieldData,
        [newFieldNumber]: newData
      });
    } catch (err) {
      console.log(err);
    }
  };

  const removeField = ({ name, fieldNumber, path }) => {
    const updatedFields = { ...fieldData };
    const keyName = `${name}-${fieldNumber}`;
    dispatch(deleteFormObjectByKey({ formKey: path, keyName: keyName }));
    delete updatedFields[fieldNumber];
    setFieldData(updatedFields);
  };

  return (
    <>
      <div className={fieldsClass}>
        {varient == 'full' && duplicate ? (
          <Accordion
            className="!shadow-none"
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}>
            <div className="border-b-2 border-gray-400">
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="!bg-[#F9F9F9]"
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                {name && (
                  <div className="text-[28px] font-bold text-[#264E7F]">{name.toUpperCase()}</div>
                )}
              </AccordionSummary>
            </div>
            <AccordionDetails>
              {duplicate && duplicateBtnRqd && (
                <Button
                  className="!normal-case !shadow-none !float-right !mx-0 !my-2 h-12 !rounded-full"
                  onClick={addField}
                  variant="outlined"
                  color="success">
                  <AddIcon />
                </Button>
              )}
              <div className="flex flex-col">
                {Object.keys(fieldData).map((key) => {
                  const uniqueModalKey = `${ModalKeys.deleteForm}${name}${key}${path}`;
                  return (
                    <div key={key} className={fieldChildClasses}>
                      {fieldData[key]?.map((element, secKey) => {
                        const FormComponent = GetFormComponentsV3({
                          form: element,
                          path: [...path, `${name}-${key}`],
                          isFormDisabled,
                          options: { options },
                          restrictRerender: restrictRerender,
                          fieldData: { fieldData },
                          disableFullForm: disableFullForm,
                          showTable: showTable
                        });
                        return <div key={`${name}-${key}-${secKey}`}>{FormComponent}</div>;
                      })}
                      {duplicate && (
                        <div className="self-center !mt-[24px] flex-1">
                          <Button
                            className="float-right h-12 !rounded-full !p-0"
                            variant="outlined"
                            color="error"
                            onClick={() =>
                              dispatch(
                                genericModalStateUpdate({
                                  modalKey: uniqueModalKey,
                                  isOpen: true
                                })
                              )
                            }>
                            <RemoveIcon />
                          </Button>
                          <ConformationContainer
                            modalId={uniqueModalKey}
                            doNotEmptyState={true}
                            cancelObject={{ text: 'Retain', callback: noop }}
                            conformObject={{
                              text: 'Remove',
                              callback: () => removeField({ name, fieldNumber: key, path })
                            }}
                            modalText={{
                              header: `Remove ${name} Form`,
                              desc: 'Your Data is Unsaved, do you really want to Remove!'
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        ) : (
          <>
            <div className={fieldsHeader}>
              {name && (
                <div className="text-[28px] py-4 w-[100%] font-bold pl-4 text-[#264E7F] border-b border-gray-400">
                  {name.toUpperCase()}
                </div>
              )}
              {duplicate && duplicateBtnRqd && (
                <Button
                  className="!normal-case !shadow-none !mr-[8px]"
                  onClick={addField}
                  variant="contained"
                  color="success">
                  <AddIcon />
                </Button>
              )}
            </div>
            <div className="flex flex-col">
              {Object.keys(fieldData).map((key) => {
                const uniqueModalKey = `${ModalKeys.deleteForm}${name}${key}${path}`;
                return (
                  <>
                    <div key={key} className={fieldChildClasses}>
                      {fieldData[key]?.map((element, secKey) => {
                        const FormComponent = GetFormComponentsV3({
                          form: element,
                          path: [...path, `${name}-${key}`],
                          isFormDisabled,
                          options: { options },
                          restrictRerender: restrictRerender,
                          fieldData: { fieldData },
                          disableFullForm: disableFullForm,
                          showTable: showTable
                        });
                        return <div key={`${name}-${key}-${secKey}`}>{FormComponent}</div>;
                      })}
                      {duplicate && duplicateBtnRqd && (
                        <div className="self-center !mt-[24px] flex-1">
                          <Button
                            className="h-[40px] !w-[40px] float-right !shadow-none"
                            variant="contained"
                            color="error"
                            onClick={() =>
                              dispatch(
                                genericModalStateUpdate({
                                  modalKey: uniqueModalKey,
                                  isOpen: true
                                })
                              )
                            }>
                            <RemoveIcon />
                          </Button>
                          <ConformationContainer
                            modalId={uniqueModalKey}
                            cancelObject={{ text: 'Retain', callback: noop }}
                            conformObject={{
                              text: 'Remove',
                              callback: () => removeField({ name, fieldNumber: key, path })
                            }}
                            modalText={{
                              header: `Remove ${name} Form`,
                              desc: 'Your Data is Unsaved, do you really want to Remove!'
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FieldsComponentsV3;
