import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from '../routes';

const index = () => {
  const pages = useRoutes(routes);
  return <div className='h-[100%]' >{pages}</div>;
};

export default index;
