import React from 'react';
import SideNavigationContainer from '../modules/sideNavigation/container/SideNavigationContainer';

const SideNavLayout = ({ children, sideNavigationSchema }) => {
  return (
    <div className="w-[100%] flex">
      <SideNavigationContainer schema={sideNavigationSchema} />
      <div className="flex-1 bg-[#F4F3FA]">{children}</div>
    </div>
  );
};

export default SideNavLayout;
