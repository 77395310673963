// import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react';
// import ReactFlow, {
//   addEdge,
//   Controls,
//   Background,
//   ReactFlowProvider,
//   useNodesState,
//   useEdgesState,
//   useReactFlow,
//   getOutgoers,
//   getIncomers,
//   getConnectedEdges
// } from 'reactflow';

// import { useLocation } from 'react-router-dom';
// import Toolbox from '../component/Toolbox';
// import CustomHandle from '../component/CustomHandle';
// import 'reactflow/dist/style.css';
// import ELK from 'elkjs/lib/elk.bundled.js';
// import ViewFormSection from './ViewFormSection';

// import { SpreadArray, pushEditFlowData, pushFlowData, removeEditDeletedNode } from './FlowSlice';
// import { useDispatch } from 'react-redux';



// export const ViewCanvas = () => {
//   const [nodes, setNodes,onNodesChange] = useNodesState([]);
//   const [edges, setEdges,onEdgesChange] = useEdgesState([]);
//   const [reactFlowInstance, setReactFlowInstance] = useState(null);
//   const location = useLocation();
//   const payload = location.state || [];
//   const dispatch = useDispatch();
  
//   console.log(payload)

//   const nodeTypes = { leftCustom: CustomHandle };

//   useEffect(() => {
//     console.log("i am useffect of viewcanvas..")
//     dispatch(SpreadArray(payload));
//   }, [dispatch, payload]);
 
  
//   const reactFlowWrapper = useRef(null);
//   const [initialized, setInitialized] = useState(false);
//   const [selectedNode, setSelectedNode] = useState(null);
//   const [open, setOpen] = useState(false);
//   const [showStart, setShowStart] = useState(false);

//   const onDrop = useCallback(
//     (event) => {
//       event.preventDefault();

//       const nodeData = event.dataTransfer.getData('application/node');
//       if (!nodeData) {
//         console.error('No node data found on drop.');
//         return;
//       }

//       const parsedNodeData = JSON.parse(nodeData);

//       if (nodes.length === 0 && parsedNodeData.label.toLowerCase() !== 'start') {
//         alert('Workflow First Node should be Start.');
//       } else if (showStart && parsedNodeData.label.toLowerCase() === 'start') {
//         alert('Only one Start node is allowed');
//       } else {
//         if (parsedNodeData.label.toLowerCase() === 'start') {
//           setShowStart(true);
//         }

//         const type = event.dataTransfer.getData('application/reactflow');
//         if (!type) {
//           console.error('No type found for dropped node.');
//           return;
//         }

//         const position = reactFlowInstance.project({
//           x: event.clientX,
//           y: event.clientY
//         });

//         const newNode = {
//           id: parsedNodeData.data.id,
//           type,
//           position,
//           data: parsedNodeData
//         };

//         setNodes((prevNodes) => prevNodes.concat(newNode));
//       }
//     },
//     [reactFlowInstance, nodes, setNodes, showStart]
//   );

 
//   const payloadGenerator = useCallback(() => {
//     const generatedNodes = payload.map((activity) => {
//       console.log("Activity:", activity); // Debugging
//       return {
//         id: activity.id,     
//         type: 'leftCustom', 
//         data: {
//           data: {
//             label: activity.type === 'leaf' ? 'End' : activity.type,
//             type: activity.type,
//             location: 'default',
//           },
//           label: activity.type === 'leaf' ? 'End' : activity.type,
//           type: activity.type,
//           location: 'default',
//         },
//         location: 'source',
//         handles: [
//           { position: 'left', handleType: 'target', isConnectable: 100 },
//           { position: 'right', handleType: 'source', isConnectable: 100 }
//         ],
//         position: activity.position,
//       };
//     });
  
//     const generatedEdges = payload.flatMap((activity) => {
//       if (!Array.isArray(activity?.sequence)) {
//         return [];
//       }
//       return activity.sequence
//         .filter((seq) => seq.node)
//         .map((seq) => ({
//           id: `e${activity.id}-${seq.node}`,
//           source: activity.id,
//           target: seq.node,
//           type: 'smoothstep',
//           label: seq.edgeDescription,
//           style: seq.result === 'error' ? { stroke: 'red', strokeWidth: 2 } : { stroke: 'green', strokeWidth: 2 }
//         }));
//     });
  
//     console.log("Generated Nodes and Edges:", generatedNodes, generatedEdges);
//     setNodes(generatedNodes);
//     setEdges(generatedEdges);
//   }, [payload, setNodes, setEdges]);
  
//   useEffect(() => {
//     if (!initialized) {
//       payloadGenerator();
//       setInitialized(true);
//     }
//   }, [initialized, payloadGenerator]);

//   const onConnect = useCallback(
//     (params) => {
//       setEdges((prevEdges) => addEdge(params, prevEdges));
//     },
//     [setEdges]
//   );

//   const onDragOver = useCallback((event) => {
//     event.preventDefault();
//     event.dataTransfer.dropEffect = 'move';
//   }, []);

 

//   const onNodeClick = useCallback((event, node) => {
//     console.log("SelectedNode before update:", selectedNode);
//     console.log("Clicked Node:", node);

   
//     const isSameNode = selectedNode && selectedNode.id === node.id;
//     if (!isSameNode) {
//       console.log("Updating selected node and opening form");
//       setOpen(true);
//       setSelectedNode(node);
//     } else {
//       console.log("Node is already selected, no state update needed");
//     }
//   }, [selectedNode]);

//   // Determine if the ViewFormSection should be displayed
//   const shouldDisplayForm = useMemo(() => open && selectedNode, [open, selectedNode]);

//   console.log('View Canvas is Looping........');
//   const onNodesDelete = useCallback(
//     (deleted) => {
//       console.log(deleted)
//       dispatch(removeEditDeletedNode(deleted));
//       setEdges(
//         deleted.reduce((acc, node) => {
//           const incomers = getIncomers(node, nodes, edges);
//           const outgoers = getOutgoers(node, nodes, edges);
//           const connectedEdges = getConnectedEdges([node], edges);

//           const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

//           const createdEdges = incomers.flatMap(({ id: source }) =>
//             outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target }))
//           );

//           return [...remainingEdges, ...createdEdges];
//         }, edges)
//       );
//     },
//     [nodes, edges]
//   );

//   return (
//     <div className="dndflow">
//       <Toolbox />
//       <div className="reactflow-wrapper" ref={reactFlowWrapper}>
//         <ReactFlowProvider>
//           <ReactFlow
//             nodeTypes={nodeTypes}
//             colorMode="dark"
//             onNodesChange={onNodesChange}
//             onEdgesChange={onEdgesChange}
//             // onNodesChange={on}
//             onNodesDelete={onNodesDelete}
//             nodes={nodes}
//             edges={edges}
//             onConnect={onConnect}
//             onDrop={onDrop}
//             onDragOver={onDragOver}
//             onInit={setReactFlowInstance}
//             onNodeClick={onNodeClick}
//             style={{ width: '100%', height: '100vh' }}>
//             <Controls />
//             <Background color="#ccc" variant="cross" />
//           </ReactFlow>
//         </ReactFlowProvider>
//       </div>
//       {shouldDisplayForm && (
//         <div style={{ width: '400px' }}>
//           {console.log("i am Loopingggg.....")}
//           <ViewFormSection selectedNodes={selectedNode} />
//         </div>
//       )}
//     </div>
//   );
// };


import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react';
import ReactFlow, {
  addEdge,
  Controls,
  Background,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  useReactFlow,
  getOutgoers,
  getIncomers,
  getConnectedEdges
} from 'reactflow';
import { useLocation } from 'react-router-dom';
import Toolbox from '../component/Toolbox';
import CustomHandle from '../component/CustomHandle';
import 'reactflow/dist/style.css';
import ELK from 'elkjs/lib/elk.bundled.js';
import ViewFormSection from './ViewFormSection';
import { SpreadArray, removeEditDeletedNode } from './FlowSlice';
import { useDispatch } from 'react-redux';

const nodeTypes = { leftCustom: CustomHandle };

export const ViewCanvas = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const location = useLocation();
  const {payload,action} = location.state || [];
  console.log(location.state)
  const dispatch = useDispatch();

  const reactFlowWrapper = useRef(null);
  const [initialized, setInitialized] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [open, setOpen] = useState(false);
  const [showStart, setShowStart] = useState(false);

  useEffect(() => {
    console.log("Initializing with payload..");
    dispatch(SpreadArray(payload));
  }, [dispatch, payload]);

  

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const nodeData = event.dataTransfer.getData('application/node');
      if (!nodeData) {
        console.error('No node data found on drop.');
        return;
      }

      const parsedNodeData = JSON.parse(nodeData);

      if (nodes.length === 0 && parsedNodeData.label.toLowerCase() !== 'start') {
        alert('Workflow First Node should be Start.');
      } else if (showStart && parsedNodeData.label.toLowerCase() === 'start') {
        alert('Only one Start node is allowed');
      } else {
        if (parsedNodeData.label.toLowerCase() === 'start') {
          setShowStart(true);
        }

        const type = event.dataTransfer.getData('application/reactflow');
        if (!type) {
          console.error('No type found for dropped node.');
          return;
        }

        const position = reactFlowInstance.project({
          x: event.clientX,
          y: event.clientY
        });

        const newNode = {
          id: parsedNodeData.data.id,
          type,
          position,
          data: parsedNodeData
        };

        setNodes((prevNodes) => prevNodes.concat(newNode));
      }
    },
    [reactFlowInstance, nodes, showStart]
  );

  const payloadGenerator = useCallback(() => {
    const generatedNodes = payload.map((activity) => {
      return {
        id: activity.id,
        type: 'leftCustom',
        data: {
          data: {
            label: activity.type === 'leaf' ? 'End' : activity.type,
            type: activity.type,
            location: 'default',
          },
          label: activity.type === 'leaf' ? 'End' : activity.type,
          type: activity.type,
          location: 'default',
        },
        position: activity.position,
      };
    });

    const generatedEdges = payload.flatMap((activity) => {
      if (!Array.isArray(activity?.sequence)) {
        return [];
      }
      return activity.sequence
        .filter((seq) => seq.node)
        .map((seq) => ({
          id: `e${activity.id}-${seq.node}`,
          source: activity.id,
          target: seq.node,
          type: 'smoothstep',
          label: seq.edgeDescription,
          style: seq.result === 'error' ? { stroke: 'red', strokeWidth: 2 } : { stroke: 'green', strokeWidth: 2 }
        }));
    });

    setNodes(generatedNodes);
    setEdges(generatedEdges);
  }, [payload]);

  useEffect(() => {
    if (!initialized) {
      payloadGenerator();
      setInitialized(true);
    }
  }, [initialized, payloadGenerator]);

  const onConnect = useCallback(
    (params) => {
      setEdges((prevEdges) => addEdge(params, prevEdges));
    },
    [setEdges]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onNodeClick = useCallback((event, node) => {
    const isSameNode = selectedNode && selectedNode.id === node.id;
    if (!isSameNode) {
      setOpen(true);
      setSelectedNode(node);
    }
  }, [selectedNode]);

  const shouldDisplayForm = useMemo(() => open && selectedNode, [open, selectedNode]);

  const onNodesDelete = useCallback(
    (deleted) => {
      dispatch(removeEditDeletedNode(deleted));
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges, dispatch]
  );

  return (
    <div className="dndflow">
      <Toolbox />
      <div className="reactflow-wrapper" ref={reactFlowWrapper}>
        <ReactFlowProvider>
          <ReactFlow
            nodeTypes={nodeTypes}
            colorMode="dark"
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodesDelete={onNodesDelete}
            nodes={nodes}
            edges={edges}
            onConnect={onConnect}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onInit={setReactFlowInstance}
            onNodeClick={onNodeClick}
            style={{ width: '100%', height: '100vh' }}>
            <Controls />
            <Background color="#ccc" variant="cross" />
          </ReactFlow>
        </ReactFlowProvider>
      </div>
      {shouldDisplayForm && (
        <div style={{ width: '400px' }}>
          <ViewFormSection selectedNodes={selectedNode} action={action} />
        </div>
      )}
    </div>
  );
};


