import React, { useEffect, useState } from 'react';
import TopCards from '../component/TopCards';
import { useQuery } from 'react-query';
import { noop } from 'lodash';

const TopCardsContainer = ({
  schema = [],
  formatCountData = noop,
  getCountService = noop,
  triggerFetch = false
}) => {
  const [countData, setCountData] = useState({});

  useEffect(() => {
    (async function () {
      const data = await getCountService();
      const formattedData = formatCountData(data);
      setCountData(formattedData);
    })();
  }, [triggerFetch]);

  return <TopCards countData={countData} TopCardsSchema={schema} />;
};

export default TopCardsContainer;
