import { useQuery } from 'react-query';
import { default as fetchFeatureDataCall } from '../../../services/fetchFeatureData';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { useEffect, useState } from 'react';
import ConnectionGrid from '../components/ConnectionLogGrid';
import { fetchConnectionLogDataAction } from '../../Application/slices/applicationSlice';
import { getConnectionLogs, getConnectionLogsInfo } from '../../../services/connectionLogData';
import { connectionLogInfoUrl } from '../../dashboard/constants/applicationRequest';
import keycloak from '../../../Keycloak';
import request from 'superagent';

const ConnectionLogGridContainer = () => {
  const dispatch = useDispatch();
  const { app_code = '' } = useParams();
  const { refetch, isFetchedAfterMount } = useQuery(
    'connection-log-data',
    getConnectionLogs({ appCode: app_code }),
    {
      enabled: false,
      refetchOnWindowFocus: true
    }
  );
  const storeConnectionLogsData = useSelector(
    (store) => store.store.moreApplication.connectionLogsData
  );

  const fetchConnectionsData = async () => {
    const refetchData = await refetch();
    const { data, isSuccess } = refetchData;
    if (isSuccess) {
      dispatch(fetchConnectionLogDataAction({ connectionLogsData: data?.body?.result }));
    }
    return refetchData;
  };

  const { refetch: refetchConnectionInfo } = useQuery(
    'connection-log-data',
    () => getConnectionLogsInfo(connectorLogId),
    {
      enabled: false,
      refetchOnWindowFocus: true
    }
  );

  const onViewClick = async (connectorLogId) => {
    console.log('here2');
    const refetchData = await request
      .post(connectionLogInfoUrl)
      .send({ connectorLogId: connectorLogId })
      .set('Authorization', `Bearer ${keycloak.token()}`);
    return refetchData;
  };

  return (
    <ConnectionGrid
      onViewClick={onViewClick}
      fetchConnectionsData={fetchConnectionsData}
      connectionLogsData={storeConnectionLogsData}
    />
  );
};

export default ConnectionLogGridContainer;
