import { Delete, ViewAgenda } from '@mui/icons-material';
import { CircularProgress, TextField } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import request from 'superagent';
import {
  setCurrentHLFId,
  setCurrentHLFNetwork
} from '../../../HyperledgerFabricNetworks/slices/hlfSlice';
import Tabs from '../../../Tabs/components/Tabs';
import ConformationContainer from '../../../common/conformationModal/containers';
import MenuContainer from '../../../common/menu/containers/MenuContainer';
import ModalKeys from '../../../modals/genericModal/constants/GenericModalKeys';
import GenericModalContainer from '../../../modals/genericModal/container';
import { genericModalStateUpdate } from '../../../modals/slices/genericModalSlice';
import ErrorNotify from '../../../notify/ErrorNotify';
import Success from '../../../notify/Success';

const RejectedGrid = ({
  rejectedData,
  fetchRejectedData,
  title = '',
  setCurrentReviewState,
  reviewType
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentClickedData, setCurrentClickedData] = useState({});
  const [searchText, setSearchText] = useState();

  let columnDefs;

  useEffect(() => {
    fetchRejectedData();
  }, [reviewType]);

  if (reviewType == 'reviewObjectMapping') {
    columnDefs = [
      { headerName: 'source code', field: 'sourceCode' },
      { headerName: 'target code', field: 'targetCode' },
      { headerName: 'Created By', field: 'createdBy' },
      { headerName: 'Created On', field: 'createdOn' },
      { headerName: 'Version', field: 'version' },
      { headerName: 'Rejected On', field: 'rejectedOn' },
      { headerName: 'Reason', field: 'reason' }
    ];
  } else {
    columnDefs = [
      { headerName: 'Name', field: 'name' },
      { headerName: 'Type', field: 'type' },
      { headerName: 'Created By', field: 'createdBy' },
      { headerName: 'Created On', field: 'createdOn' },
      { headerName: 'Version', field: 'version' },
      { headerName: 'Rejected On', field: 'rejectedOn' },
      { headerName: 'Reason', field: 'reason' }
    ];
  }

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function onShowLoading() {
    gridOptions.api.showLoadingOverlay();
  }

  function onHide() {
    gridOptions.api.hideOverlay();
  }

  let gridApi;

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };

  let rowData;

  if (reviewType == 'reviewObjectMapping') {
    rowData = rejectedData?.map((element) => {
      return {
        sourceCode: element.sourceCode,
        targetCode: element.targetCode,
        createdOn: element.createdOn,
        createdBy: element.createdBy,
        version: element.version,
        id: element.id,
        rejectedOn: element.rejectedAt,
        reason: element.reason || ''
      };
    });
  } else {
    rowData = rejectedData?.map((element) => {
      return {
        name: element.code,
        type: element.type,
        createdBy: element.createdBy,
        createdOn: element.createdAt,
        version: element.version,
        rejectedOn: element.rejectedAt,
        reason: element.reason || ''
      };
    });
  }

  // const rowData = rejectedData?.map((element) => {
  //   return {
  //     name: element.appCode,
  //     type: element.type,
  //     createdBy: '',
  //     createdOn: element.createdAt,
  //     version: element.version,
  //     rejectedOn: '',
  //     reason: element.reason || ''
  //   };
  // });

  async function onGridReady(params) {
    gridApi = params?.api;
    params?.api?.showLoadingOverlay();
    const fetchData = await fetchRejectedData();
  }

  const onExportClick = () => {
    gridApi = params?.api;
    gridApi?.exportDataAsCsv();
  };

  const onDeleteClick = async (data) => {
    const deleteResult = await request
      .put(`${process.env.REACT_APP_CONFIG_API}/hlf/delete`)
      .query({
        id: data.id
      })
      .send({});
    await fetchHLFData();
    if (deleteResult?.body?.status?.code == 'SUCCESS') {
      toast.custom((t) => <Success t={t} message={deleteResult?.body?.status?.message} />, {
        duration: 4000
      });
    } else {
      toast.custom((t) => <ErrorNotify t={t} message={deleteResult?.body?.status?.message} />, {
        duration: 4000
      });
      console.log('Data Object Deletion failed');
    }
  };

  async function cellClicked(param, field) {
    const { data } = param;

    switch (field) {
      case 'reject': {
        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.searchBy,
            isOpen: true
          })
        );
        break;
      }

      case 'approve': {
        setCurrentReviewState('approved');
        break;
      }

      case 'copy': {
        navigate(`/hlf/copy/${data.networkName}`);
        break;
      }
      case 'edit': {
        navigate(`/hlf/edit/${data.id}`);
        break;
      }
      case 'delete': {
        setCurrentClickedData(data);
        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.deleteHlfModal,
            isOpen: true
          })
        );
        break;
      }
      case 'view': {
        dispatch(
          setCurrentHLFId({
            id: data.id
          })
        );
        dispatch(
          setCurrentHLFNetwork({
            network: data.network
          })
        );
        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.viewHlf,
            isOpen: true
          })
        );
      }
    }
  }

  const customComponent = (
    <div>
      <TextField value={searchText} onChange={(e) => setSearchText(e.target.value)} />
    </div>
  );

  return (
    <div className="ag-theme-alpine flex-1 px-[24px] my-[50px] p-[20px] mr-[20px]  border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      <div className="text-[24px]">{title}</div>
      <div className="flex-1">
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={20}
          rowData={rowData}
          suppressCellFocus={true}
        />
      </div>
      <GenericModalContainer modalKey={ModalKeys.viewHlf}>
        <Tabs />
      </GenericModalContainer>
      <ConformationContainer
        formData={currentClickedData}
        doNotEmptyState={true}
        modalId={ModalKeys.deleteHlfModal}
        conformObject={{
          text: 'Delete Connection',
          callback: onDeleteClick
        }}
        cancelObject={{ text: 'Cancel', callback: noop }}
        modalText={{
          header: `Delete Connection`,
          desc: 'Do you really want to Remove!'
        }}
      />
      <ConformationContainer
        type="submit"
        formData={{}}
        modalId={ModalKeys.searchBy}
        cancelObject={{ text: 'Cancel', callback: noop }}
        conformObject={{
          text: 'Reject',
          callback: () => {
            setCurrentReviewState('rejected');
          }
        }}
        customComponent={customComponent}
        modalText={{
          header: `Reason`,
          desc: `Please share the reason for rejecting this request`
        }}
      />
    </div>
  );
};

export default RejectedGrid;
