function getAllOrganizations(data) {
  const result = Object.entries(data).reduce((acc, [key, value]) => {
    if (key.startsWith('organizations-')) {
      return {
        ...acc,
        [key]: {
          organizationName: data[key].organizationName,
          ...Object.keys(data[key]).reduce((acc, value) => {
            if (value.startsWith('peers-')) {
              return {
                ...acc,
                [value]: data[key][value].peers
              };
            } else {
              return acc;
            }
          }, {})
        }
      };
    } else {
      return acc;
    }
  }, {});

  const answer = [];

  Object.keys(result).map((key) => {
    return Object.keys(result[key]).map((secondKey) => {
      if (secondKey.startsWith('organizationName')) {
        answer.push(result[key][secondKey]);
      }
    });
  });

  return answer;
}

export default getAllOrganizations;
