import React from 'react';
import Modal from '@mui/material/Modal';

const GenericModal = ({
  children,
  isModalOpen,
  closeModal,
  bgClr = 'bg-gray-700'
}) => {
  return (
    <Modal
      className={`w-[100%] min-h-[100vh] h-[100%] ${bgClr} bg-opacity-50`}
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      {React.cloneElement(children, { onCloseModal: closeModal })}
    </Modal>
  );
};

export default GenericModal;
