import request from 'superagent';
import { applicationDataUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const fetchApplication = async () => {
  const userId = keycloak.name;
  const res = await request
    .get(applicationDataUrl)
    .query({ userId: userId })
    .set('Authorization', `Bearer ${keycloak.token()}`);

  return res;
};

export default fetchApplication;
