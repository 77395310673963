function getGenericInfoSchema(formData, inEditMode) {
  const { customer = '', network = '', description = '' } = formData || {};

  return {
    title: 'genericForm',
    disableSubmit: true,
    hideTitle: true,
    formSchema: [
      {
        name: 'customerName',
        label: 'Customer Name',
        type: 'text',
        disabled: inEditMode,
        require: false,
        defaultValue: customer
      },
      {
        name: 'networkName',
        label: 'Network Name',
        disabled: inEditMode,
        type: 'text',
        require: false,
        defaultValue: network
      },
      {
        name: 'description',
        label: 'Description',
        type: 'text',
        require: false,
        disabled: inEditMode,
        defaultValue: description,
        notValidate: true
      }
    ]
  };
}

export default getGenericInfoSchema;
