import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { default as React, default as React, useCallback, useMemo, useRef, useState } from 'react';

const UserGrid = ({ refetchUsersData, usersData = [], title }) => {
  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([
    // group cell renderer needed for expand / collapse icons
    { headerName: 'Username', field: 'username', cellRenderer: 'agGroupCellRenderer' },
    { headerName: 'Organization Id', field: 'organizationId' },
    { headerName: 'Email', field: 'email' }
  ]);

  const rowData = (usersData || [])?.map((element) => {
    return {
      username: element?.name,
      organizationId: element?.organizationCode,
      email: element?.organizationName,
      userRoles: element?.userRoles.map((userRoleData) => {
        return {
          appCode: userRoleData?.app_code,
          roleCode: (userRoleData?.roles || []).join(',')
        };
      })
    };
  });

  const defaultColDef = useMemo(() => {
    return {
      flex: 1
    };
  }, []);
  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: [
          { headerName: 'App Code', field: 'appCode' },
          { headerName: 'Role Code', field: 'roleCode' }
        ],
        defaultColDef: {
          flex: 1
        }
      },
      getDetailRowData: function (params) {
        params.successCallback(params.data.userRoles);
      }
    };
  }, []);

  const onGridReady = useCallback(async (params) => {
    const response = await refetchUsersData();
  }, []);

  return (
    <div className="h-[90%] ag-theme-alpine flex-1 px-[24px] mt-[50px] p-[20px] m-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      <div className="text-[24px] ml-4 font-semibold">{title}</div>
      <div className="flex-1">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          masterDetail={true}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          detailCellRendererParams={detailCellRendererParams}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
};
export default UserGrid;
