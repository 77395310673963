import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import FormField from '../component/index';
import Box from '@mui/material/Box';
import Joi from 'joi';
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { updateFormError, updateFormFieldError } from '../slices/formSlice';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { noop } from 'lodash';
import DuplicateForm from './DuplicateForm';
import { genericModalStateUpdate } from '../../../modals/slices/genericModalSlice';

const FormRenderer = (props) => {
  const {
    handleChange,
    className = '',
    submitForm = noop,
    schema = {},
    formData = {},
    formState = {},
    formError = {},
    formKey = '',
    variant = '',
    showFormTitle = true,
    isModal = true,
    modalKey = ''
  } = props;

  const {
    formSchema,
    title: formTitle,
    makeFormDuplicate = false,
    removeSubmitButton = false
  } = schema;

  const { errorMessage: formErrorMessage } = formError;
  const showLoader = formState == 'pending' ? true : false;
  const [duplicateForms, setDuplicateForms] = useState([]);
  const [formCount, setFormCount] = useState(0);
  const [duplicateGroupObject, setDuplicateGroupObject] = useState({});

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    submitForm(formData);
  };

  const addGroup = (name) => {
    const currentGroupObject = duplicateGroupObject[name] || [];
    currentGroupObject.fields = [
      ...(currentGroupObject.fields || []),
      currentGroupObject.count || 0
    ];
    currentGroupObject.count = (currentGroupObject.count || 0) + 1;
    setDuplicateGroupObject({ ...duplicateGroupObject, [name]: currentGroupObject });
  };

  const handleFormDuplicate = () => {
    setDuplicateForms([...duplicateForms, formCount + 1]);
    setFormCount(formCount + 1);
  };

  const removeDuplicateForm = (currentFormNumber) => {
    setDuplicateForms(duplicateForms.filter((element) => element !== currentFormNumber));
  };

  const validationInstances = {};

  var formClassName;

  switch (variant) {
    case 'center':
      formClassName = classNames(
        'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border-2 border-black shadow-lg',
        className
      );
      break;

    case 'original':
      formClassName = classNames('shadow-lg', className);
      break;

    case 'import':
      formClassName = classNames('bg-white', className);
      break;

    default:
      formClassName = classNames(
        'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg rounded-lg',
        className
      );
  }

  const fieldValidation = (validation = {}) => {
    const { type, min = 0, max = 1000 } = validation;
    var validationInstance = Joi;

    switch (type) {
      case 'string':
        validationInstance = validationInstance.string();
        break;
      case 'integer':
        validationInstance = validationInstance.number();
        break;
      default:
        validationInstance = validationInstance.string();
    }

    validationInstance = validationInstance.min(min);
    validationInstance = validationInstance.max(max);

    return validationInstance;
  };

  const closeModal = ({ modalKey }) => {
    dispatch(
      genericModalStateUpdate({
        modalKey: modalKey,
        isOpen: false
      })
    );
  };

  const overrideHandleChange = ({ formData, formKey, field, event }) => {
    const { name, validation } = field;
    if (!validationInstances[name]) {
      const fieldValidationInstance = fieldValidation(validation);
      validationInstances[name] = fieldValidationInstance;
    }
    const fieldValidationInstance = validationInstances[name];
    const fieldValue = event.target.value;
    const validated = fieldValidationInstance.validate(fieldValue);
    const errorMessage = validated.error ? validated.error.message : '';
    const { errorMessage: fieldErrorMessage = '' } = formData[name]?.errorState || {};
    if (fieldErrorMessage || errorMessage) {
      const updateError = dispatch(
        updateFormFieldError({ formKey: formKey, errorMessage: errorMessage, fieldName: name })
      );
    }
    if (formErrorMessage) {
      dispatch(
        updateFormError({
          formKey: formKey,
          errorMessage: ''
        })
      );
    }

    const onChangeContainer = handleChange(event, formKey);
  };

  const initialDuplicateFieldObject = {};

  formSchema?.map((element) => {
    if (element.makeFieldDuplicate) {
      initialDuplicateFieldObject[element.name] = {
        fields: [],
        count: 0
      };
    }
  });

  const getFieldComponent = (field, duplicateFormName, isFormDisabled) => {
    const type = field.type;

    switch (type) {
      case 'group': {
        const fields = field.group.fields || [];
        const groupName = field.group.groupName;
        return (
          <>
            <div className="flex flex-col">
              <div className="flex items-center">
                <div className="text-lg font-bold text-red-500">{field.group.groupName}</div>
                {field.group.makeGroupDuplicate && (
                  <Button onClick={() => addGroup(field.group.groupName)}>Add</Button>
                )}
              </div>
              <div className="flex mt-2">
                {fields.map((element) => {
                  const updatedFieldName = `${groupName}_${element.name}`;
                  const updatedField = { ...element, name: updatedFieldName };
                  return (
                    <FormField
                      className="!mr-2"
                      isFormDisabled={isFormDisabled}
                      value={formData[duplicateFormName] || ''}
                      key={duplicateFormName}
                      field={updatedField}
                      formFieldData={formData[duplicateFormName]}
                      handleChange={(event) => {
                        overrideHandleChange({
                          formData,
                          field: updatedField,
                          formKey: formKey,
                          event
                        });
                      }}
                    />
                  );
                })}
              </div>
            </div>
            {duplicateGroupObject[groupName]?.fields?.map((duplicateGroup) => {
              return (
                <div className="flex flex-col">
                  <div className="flex items-center">
                    {field.group.makeGroupDuplicate && (
                      <Button onClick={() => addGroup(field.group.groupName)}>Remove</Button>
                    )}
                  </div>
                  <div className="flex mt-2">
                    {fields.map((element) => {
                      const updatedFieldName = `${groupName}${duplicateGroup}_${element.name}`;
                      const updatedField = { ...element, name: updatedFieldName };
                      return (
                        <FormField
                          className="!mr-2"
                          isFormDisabled={isFormDisabled}
                          value={formData[duplicateFormName] || ''}
                          key={duplicateFormName}
                          field={updatedField}
                          formFieldData={formData[duplicateFormName]}
                          handleChange={(event) =>
                            overrideHandleChange({
                              formData,
                              field: updatedField,
                              formKey: formKey,
                              event
                            })
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </>
        );
      }
      default: {
        return (
          <FormField
            isFormDisabled={isFormDisabled}
            value={formData[duplicateFormName] || ''}
            key={duplicateFormName}
            field={field}
            formFieldData={formData[duplicateFormName]}
            handleChange={(event) =>
              overrideHandleChange({ formData, field, formKey: formKey, event })
            }
          />
        );
      }
    }
  };

  return (
    <>
      <Box className={formClassName}>
        <Container className="!p-0 remove-min">
          <form onSubmit={handleSubmit}>
            {showFormTitle ? (
              <h2 className="bg-blue-400 py-4 px-4 text-lg font-bold text-white rounded-t-lg">
                {isModal ? (
                  <div
                    className="w-auto float-right cursor-pointer"
                    onClick={() => closeModal({ modalKey: modalKey })}>
                    <CloseIcon />
                  </div>
                ) : null}
                {formTitle}
                {makeFormDuplicate && (
                  <Button
                    color="success"
                    className="!ml-4"
                    onClick={handleFormDuplicate}
                    variant="contained">
                    {'Add'}
                  </Button>
                )}
              </h2>
            ) : null}

            {formErrorMessage ? <Alert severity="error">{formErrorMessage}</Alert> : null}
            <div className="p-4 flex flex-wrap">
              {formSchema?.map((field) => {
                const { name, isFormDisabled } = field;
                const formName = name;
                const balanceClassName = classNames('m-2', {
                  'mt-[52px]': field.type !== 'group'
                });
                return (
                  <div className={balanceClassName}>
                    {getFieldComponent(field, formName, isFormDisabled)}
                  </div>
                );
              })}
            </div>
            {removeSubmitButton ? null : (
              <Button
                className="!mx-4 !mb-4 !h-[40px] !w-[80px]"
                type="submit"
                variant="contained"
                color="primary">
                {showLoader ? (
                  <div className="w-[100%] h-[100%] flex justify-center items-center">
                    <CircularProgress color="peace" size={20} />
                  </div>
                ) : (
                  'Submit'
                )}
              </Button>
            )}
          </form>
        </Container>
      </Box>
      {duplicateForms.map((duplicateForm) => {
        const extendedFormKey = `${formKey}_${duplicateForm}`;

        return (
          <DuplicateForm
            removeDuplicateForm={removeDuplicateForm}
            currentFormNumber={duplicateForm}
            formClassName={formClassName}
            handleSubmit={handleSubmit}
            formErrorMessage={formErrorMessage}
            formSchema={formSchema}
            formData={formData}
            formKey={extendedFormKey}
            removeSubmitButton={removeSubmitButton}
            showLoader={showLoader}
            initialDuplicateFieldObject={initialDuplicateFieldObject}
            overrideHandleChange={overrideHandleChange}
          />
        );
      })}
    </>
  );
};

export default FormRenderer;
