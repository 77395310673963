import { useState } from 'react';
import { noop } from 'lodash';
import React, { useEffect } from 'react';
import ObjectMappingModalGrid from './ObjectMappingModalGrid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const ObjectMappingModal = ({ objectMappingData = [], onCloseModal = noop }) => {

  if (!objectMappingData) {
    return null;
  }
  const [copiedIcons, setCopiedIcons] = useState({
    digitId: false,
    hash: false,
    txnId: false
  });

  const { mapping = [], source, target, description, id, sub_type } = objectMappingData;

  const copyToClipboard = (text, field) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopiedIcons({ ...copiedIcons, [field]: true });
          setTimeout(() => {
            setCopiedIcons({ ...copiedIcons, [field]: false });
          }, 2000);
        })
        .catch((err) => {
          console.error('Error copying to clipboard: ', err);
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);

      setCopiedIcons({ ...copiedIcons, [field]: true });

      // Reset the copied icon after 2 seconds
      setTimeout(() => {
        setCopiedIcons({ ...copiedIcons, [field]: false });
      }, 2000);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout();
    };
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-x-hidden overflow-y-auto">
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div className="relative z-50 bg-white shadow-lg rounded-lg border-4 border-blue-500">
        <div className="flex justify-between p-4 bg-slate-300">
          <div>
            <h1 className="text-2xl font-bold ml-4">Object Mapping</h1>
          </div>
          <div>
            <button onClick={onCloseModal}>
              <HighlightOffIcon className="!text-3xl !w-8 !h-8" />
            </button>
          </div>
        </div>
        <div className="bg-white p-4 m-6 rounded-lg shadow-md">
          <div className="grid grid-cols-2 gap-4">
            {id && (
              <>
                <div className="text-lg font-semibold text-blue-500">Object Mapping Id:</div>
                <div className="text-lg">{id}</div>
              </>
            )}
            {source && (
              <>
                <div className="text-lg font-semibold text-blue-500">Source Code:</div>
                <div className="text-lg">{source?.source_code}</div>
              </>
            )}
            {target && (
              <>
                <div className="text-lg font-semibold text-blue-500">Target Code:</div>
                <div className="text-lg">{target?.target_code}</div>
              </>
            )}
          </div>
        </div>

        <div className="p-6">
          <ObjectMappingModalGrid data={mapping} />
        </div>
      </div>
    </div>
  );
};

export default ObjectMappingModal;
