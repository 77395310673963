import React from 'react';
import gif from './mmm.gif'

const UnderDevelopmentPage = () => {
  return (
    <div className="flex justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4 mt-16">Under Development</h1>
        <p className="text-gray-600">
          Work In progress
        </p>
        <iframe
          title="Under Development GIF"
          src={gif}
          width="100%"
          height="1000"
          frameBorder="0"
          className="mt-8"></iframe>
      </div>
    </div>
  );
};

export default UnderDevelopmentPage;
