import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordianDiv from './AccordianDiv';
import { noop } from 'lodash';
import expandIcon from '../../../public/icons/expandIcon.svg';
import mappedIcon from '../../../public/icons/mappedIcon.svg';
import greenBadge from '../../../public/icons/greenBadge.svg';
import { useDrag, useDrop } from 'react-dnd';
import {
  getOldNewMergedData,
  mapOnParentDrop,
  mergeJSONObjects,
  findParentWithGivenId,
  mapTheSigularSourceAndTarget,
  completeMapObject
} from '../utils/getParentData';
import { toast } from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';

export default function GenerateAccordians({
  accordianSchema,
  accordianType,
  location,
  accept,
  sourceData,
  targetData,
  getMappingStyledOutput,
  parents,
  setMapSchema,
  mapSchema,
  currentObject,
  setCurrentObject,
  current,
  currentObjectMapping,
  setCurrentObjectMapping,
  isPresentInMapping = false,
  inReview
}) {
  console.log("42 accordianSchema :- ",accordianSchema)
  const [expanded, setExpanded] = React.useState(false);

  const [{ isDragging }, drag] = useDrag({
    type: accept,
    item: { data: accordianSchema, location: location },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const [{ isMatchDragging }, matchDrag] = useDrag({
    type: 'delete',
    item: { data: accordianSchema, location: location },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'data',
    drop: (item, monitor) => {
      switch (item.location) {
        case 'source': {
          const clientOffset = monitor.getClientOffset();
          const dropTargetElement = document.elementFromPoint(clientOffset.x, clientOffset.y);

          // it will get us the target Element from where we goona pick , the current , which the number
          // of the mapped item number and the id of the source in this case.

          const presentElement =
            dropTargetElement.querySelector('div[type="node"]') || dropTargetElement;
          // this will give the source

          const currentId =
            presentElement?.getAttribute('value') || dropTargetElement?.getAttribute('value');

          // this will give the current to get the already mapped object
          console.log("79 presentElement:- ",presentElement)
          // const targetId = presentElement?.getAttribute('targetId');
          const targetId = presentElement?.getAttribute('id');

          // it will five the sourceId of the source in this case

          const newElement = findParentWithGivenId({
            data: sourceData,
            // id: item.data.sourceId,
            id: item.data.id,
            type: 'source'
          });
          console.log("90 newElement :- ",newElement)
          // this should get back the parent with corresponding parent

          // getting the source

          const targetElement = findParentWithGivenId({
            data: targetData,
            id: targetId,
            type: 'target'
          });
          console.log("100 targetElement :- ",targetElement)
          // if (!targetElement || !newElement) {
          //   toast.custom(
          //     (t) => <ErrorNotify t={t} message={'Please Drag Drop at specified location'} />,
          //     {
          //       duration: 4000
          //     }
          //   );
          //   break;
          // }

          // this should get back the parent with corresponding parent
          const mappedResult =
            mapTheSigularSourceAndTarget({
              source: newElement,
              target: targetElement
            }) || newElement;

          // this will add two things, targetId and targetName

          const PreviousElement = currentObjectMapping[currentId];

          const completeMergeMapObject = completeMapObject({
            older: PreviousElement,
            younger: mappedResult,
            type: 'target'
          });
          console.log("completeMergeMapObject :-",completeMergeMapObject);
          // this will map both target object and the source object

          const updatedMapSchema = mapSchema.filter((element) => element.current != currentId);

          // get all the schema of the mapped objects other then the think which is now mapped

          const previousMappedSchema = mapSchema.find((element) => element.current == currentId);

          // get the previously mapped object
          const newMapWithObj = mapSchema.map((e)=> {
            if(e.current == currentId)
            {
              return completeMergeMapObject
            }
            else 
            {
              return e;
            }
          })
          console.log("149 newMapWithObj :- ",newMapWithObj)

          completeMergeMapObject.current = previousMappedSchema.current;
          const mapWithObj = [...updatedMapSchema, completeMergeMapObject];
          console.log("153 mapWithobj :- ",mapWithObj);
          setCurrentObjectMapping({ ...currentObjectMapping, [currentId]: completeMergeMapObject });
          // setMapSchema(mapWithObj);
          setMapSchema(newMapWithObj);
          break;
        }
        case 'target': {
          const clientOffset = monitor.getClientOffset();
          const dropTargetElement = document.elementFromPoint(clientOffset.x, clientOffset.y);
          // it will get us the target Element from where we goona pick , the current , which the number
          // of the mapped item number and the id of the source in this case.

          const presentElement =
            dropTargetElement.querySelector('div[type="node"]') || dropTargetElement;
          // this will give the source

          const currentId =
            presentElement?.getAttribute('value') || dropTargetElement?.getAttribute('value');

          // this will give the current to get the already mapped object
          console.log("172 presentElement:- ",presentElement)
          // const sourceId = presentElement?.getAttribute('sourceId');
          const sourceId = presentElement?.getAttribute('id');

          // it will five the sourceId of the source in this case

          const newElement = findParentWithGivenId({
            data: targetData,
            // id: item.data.targetId,
            id: item.data.id,
            type: 'target'
          });
          console.log("184 newElement :- ",newElement)
          // getting the source

          const sourceElement = findParentWithGivenId({
            data: sourceData,
            id: sourceId,
            type: 'source'
          });
          console.log("192 sourceElement :- ",sourceElement)
          // if (!sourceElement || !newElement) {
          //   toast.custom(
          //     (t) => <ErrorNotify t={t} message={'Please Drag Drop at specified location'} />,
          //     {
          //       duration: 4000
          //     }
          //   );
          //   break;
          // }

          // this should get back the parent with corresponding parent
          const mappedResult =
            mapTheSigularSourceAndTarget({
              source: sourceElement,
              target: newElement
            }) || newElement;
          console.log("209 mappedResult :- ",mappedResult);
          // this will add two things, targetId and targetName

          const PreviousElement = currentObjectMapping[currentId];
          console.log("213 PreviousElement :- ",PreviousElement);
          const completeMergeMapObject = completeMapObject({
            older: PreviousElement,
            younger: mappedResult,
            type: 'source'
          });
          // this will map both target object and the source object

          const updatedMapSchema = mapSchema.filter((element) => element.current != currentId);
          console.log("223 updatedMapSchema:- ",updatedMapSchema);
          // get all the schema of the mapped objects other then the think which is now mapped

          const previousMappedSchema = mapSchema.find((element) => element.current == currentId);
          console.log("227 previousMappedSchema :- ",previousMappedSchema);
          // get the previously mapped object

          completeMergeMapObject.current = previousMappedSchema.current;
          console.log("231 completeMergeMapObject :- ",completeMergeMapObject)
          console.log("232 mapSchema :- ",mapSchema)
          const newMapWithObj = mapSchema.map((e)=> {
            if(e.current == currentId)
            {
              return completeMergeMapObject
            }
            else 
            {
              return e;
            }
          })
          console.log("242 newMapWithObj :- ",newMapWithObj)

          const mapWithObj = [...updatedMapSchema, completeMergeMapObject];
          console.log("245 mapWithObj :- ",mapWithObj);
          console.log("246 setCurrentObjMapping :- ",{ ...currentObjectMapping, [currentId]: completeMergeMapObject })
          setCurrentObjectMapping({ ...currentObjectMapping, [currentId]: completeMergeMapObject });
          // setMapSchema(mapWithObj);
          setMapSchema(newMapWithObj);
          break;
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  const [{ isOver: isChildDropOver }, childDrop] = useDrop({
    accept: 'data',
    drop: (item, monitor) => {
      switch (item.location) {
        case 'source': {
          const clientOffset = monitor.getClientOffset();

          // getting data of the node

          const newElement = findParentWithGivenId({
            data: sourceData,
            // id: item?.data?.sourceId,
            id: item?.data?.id,
            type: 'source'
          });

          // get the parent and the child which is been called

          const dropTargetElement = document.elementFromPoint(clientOffset.x, clientOffset.y);

          // get the node data at given element

          const presentElement = dropTargetElement.querySelector('div[type="node"]');

          // get element with type='node' it does not matter which one but the first one he always get

          const currentId =
            presentElement?.getAttribute('value') || dropTargetElement?.getAttribute('value');

          // get the current value

          const updatedMapSchema = mapSchema.find((element) => element.current == currentId);

          // get the data from the already mapped data schema

          const newMapSchema = mapOnParentDrop({
            oldObj: updatedMapSchema,
            newObj: newElement,
            type: 'source'
          });

          // this will get the data merged

          const newlyUpdatedMapSchema = mapSchema.filter((element) => element.current != currentId);

          // this will get the mapping which is not selected
          
          const newMapWithObj = mapSchema.map((e)=> {
            if(e.current == currentId)
            {
              return newMapSchema
            }
            else 
            {
              return e;
            }
          })
          console.log("316 newMapWithObj :- ",newMapWithObj)

          const mapWithObj = [...newlyUpdatedMapSchema, newMapSchema];

          setCurrentObjectMapping({ ...currentObjectMapping, [currentId]: newMapSchema });

          // setMapSchema(mapWithObj);
          setMapSchema(newMapWithObj);
          
          setCurrentObject(currentObject + 1);

          break;
        }
        case 'target': {
          const clientOffset = monitor.getClientOffset();

          // getting data of the node

          const newElement = findParentWithGivenId({
            data: targetData,
            // id: item?.data?.targetId,
            id: item?.data?.id,
            type: 'target'
          });

          // get the parent and the child which is been called

          const dropTargetElement = document.elementFromPoint(clientOffset.x, clientOffset.y);

          // get the node data at given element

          const presentElement = dropTargetElement.querySelector('div[type="node"]');

          // get element with type='node' it does not matter which one but the first one he always get

          const currentId =
            presentElement?.getAttribute('value') || dropTargetElement?.getAttribute('value');

          // get the current value

          const updatedMapSchema = mapSchema.find((element) => element.current == currentId);

          // get the data from the already mapped data schema

          const newMapSchema = mapOnParentDrop({
            oldObj: updatedMapSchema,
            newObj: newElement,
            type: 'target'
          });

          // this will get the data merged

          const newlyUpdatedMapSchema = mapSchema.filter((element) => element.current != currentId);

          // this will get the mapping which is not selected
          const newMapWithObj = mapSchema.map((e)=> {
            if(e.current == currentId)
            {
              return newMapSchema
            }
            else 
            {
              return e;
            }
          })
          console.log("381 newMapWithObj :- ",newMapWithObj)

          const mapWithObj = [...newlyUpdatedMapSchema, newMapSchema];

          setCurrentObjectMapping({ ...currentObjectMapping, [currentId]: newMapSchema });

          // setMapSchema(mapWithObj);
          setMapSchema(newMapWithObj);

          setCurrentObject(currentObject + 1);

          break;
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  switch (accordianType) {
    case 'source-target': {
      return (
        <div>
          <Accordion
            className="!w-[100%] !mt-[20px] !rounded-[10px]"
            expanded={expanded === 'panel1'}
            onChange={accordianSchema.children ? handleChange('panel1') : noop}>
            {accordianSchema.children ? (
              <AccordionSummary
                expandIcon={accordianSchema.children ? <img src={expandIcon} /> : null}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                <AccordianDiv type="common">
                  <div className="flex">
                    <div className="mr-[12px] flex-1 overflow-hidden">
                      {accordianSchema.sourceName || accordianSchema.targetName}
                    </div>
                    {isPresentInMapping ? <img className="h-[28px]" src={greenBadge} /> : null}
                  </div>
                </AccordianDiv>
              </AccordionSummary>
            ) : (
              <AccordionSummary
                ref={!inReview ? drag : null}
                expandIcon={accordianSchema.children ? <img src={expandIcon} /> : null}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                <AccordianDiv type="common">
                  <div className="flex justify-between">
                    <div className="mr-[12px] flex-1 overflow-hidden">
                      {accordianSchema.sourceName || accordianSchema.targetName}
                    </div>
                    {isPresentInMapping ? <img className="h-[28px]" src={greenBadge} /> : null}
                  </div>
                </AccordianDiv>
              </AccordionSummary>
            )}
            <AccordionDetails>
              {accordianSchema?.children?.map((element) => {
                return (
                  <GenerateAccordians
                    currentObjectMapping={currentObjectMapping}
                    setCurrentObjectMapping={setCurrentObjectMapping}
                    setMapSchema={setMapSchema}
                    mapSchema={mapSchema}
                    location={location}
                    accordianSchema={element}
                    accept={accept}
                    accordianType={accordianType}
                    sourceData={sourceData}
                    targetData={targetData}
                    getMappingStyledOutput={getMappingStyledOutput}
                    parents={parents}
                    currentObject={currentObject}
                    setCurrentObject={setCurrentObject}
                  />
                );
              })}
            </AccordionDetails>
          </Accordion>
        </div>
      );
    }

    case 'mapping': {
      return (
        <div>
          <Accordion
            value={current}
            className="!w-[100%] !mt-[20px] !rounded-[10px]"
            expanded={expanded === 'panel1'}
            onChange={accordianSchema?.children ? handleChange('panel1') : noop}>
            <AccordionSummary
              value={current}
              expandIcon={accordianSchema?.children ? <img src={expandIcon} /> : null}
              aria-controls="panel1bh-content"
              id="panel1bh-header">
              <AccordianDiv type="common" value={current}>
                <div ref={!inReview ? matchDrag : null}>
                  <div
                    value={current}
                    ref={accordianSchema?.children ? childDrop : drop}
                    className={`px-[12px] flex justify-around pb-[40px] truncate ${
                      isOver || isChildDropOver ? 'border-green-400 border-2' : ''
                    }`}>
                    <div
                      className="w-[50%] overflow-hidden"
                      value={current}
                      type="node"
                      targetId={accordianSchema?.targetId}
                      sourceId={accordianSchema?.sourceId}
                      id = {accordianSchema?.id}>
                      {accordianSchema?.sourceName}
                    </div>
                    <div
                      className="w-[50%] text-right overflow-hidden"
                      value={current}
                      type="node"
                      targetId={accordianSchema?.targetId}
                      sourceId={accordianSchema?.sourceId}
                      id={accordianSchema?.id}>
                      {accordianSchema?.targetName}
                    </div>
                  </div>
                </div>
              </AccordianDiv>
            </AccordionSummary>
            <AccordionDetails>
              {accordianSchema?.children?.map((element) => {
                return (
                  <GenerateAccordians
                    currentObjectMapping={currentObjectMapping}
                    setCurrentObjectMapping={setCurrentObjectMapping}
                    setMapSchema={setMapSchema}
                    current={current}
                    mapSchema={mapSchema}
                    accept={accept}
                    location={location}
                    accordianSchema={element}
                    accordianType={accordianType}
                    sourceData={sourceData}
                    targetData={targetData}
                    getMappingStyledOutput={getMappingStyledOutput}
                    parents={parents}
                    currentObject={currentObject}
                    setCurrentObject={setCurrentObject}
                  />
                );
              })}
            </AccordionDetails>
          </Accordion>
        </div>
      );
    }
  }
}
