import React, { useEffect, useState } from "react";
import axios from 'axios';
import keycloak from '../../Keycloak';
import { getUser, suborgcreation } from "../dashboard/constants/applicationRequest";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { useQuery, useMutation } from 'react-query';
import { useForm, SubmitHandler, Controller, useFieldArray } from 'react-hook-form';
import {
  Button,
  Paper,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  AppBar,
  Toolbar,
  Typography
} from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Pagination from '@mui/material/Pagination';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import getTenets from "../../services/getTenets";

export default function CreateUser() {
  const token = keycloak.token();
  const [userList, setUserList] = React.useState([]);
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [suborgList,setSuborgList] = React.useState([])
  const rowsPerPage = 10;
  const [page, setPage] = React.useState(1);
  const [status, setStatus] = React.useState('');
  const [selectedId, setSelectedId] = React.useState(0)
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      Organisation: [
        {
          name: '',
          email: '',
          org_id: null,
          mobile:'',
          sub_org_id:''

        }
      ],

    }
  });

  const { fields: data } = useFieldArray({
    control,
    name: 'Organisation'
  });
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: {}
  }

  useEffect(() => {
    getUserData()
  }, [])
  const getUserData = () => {
    if (token) {
      axios
        .get(getUser, {
          params: {

          },
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          console.log(res.data, "response");
          if (res.data.userObjects.length > 0) {
            setUserList(res.data.userObjects);
          } else {
            setUserList([]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log('Token not available');
    }

  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChangePage = (event, newPage) => {
    console.log(userList.length,rowsPerPage,"pageee")
    setPage(newPage);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onsubmit = (data, e) => {
    console.log("createOrganiston", data,selectedId)
    setLoading(true)
    let actionType = status != 'edit' ? 'post' : 'put'
    if (status === 'edit') {
      data.Organisation[0].id = selectedId;
    }
    axios[actionType](getUser, data.Organisation[0], Api_Config).then((res) => {
      console.log(res.data, "creation respooo")
      try {
        if (res.data.status.code === 'SUCCESS') {
          alert(res.data.status.message)
          setLoading(false)
          handleClose()
          getUserData()
        } else {
          console.log(response.body)
          alert(res.data.status.message)
          setLoading(false)
          handleClose()
        }
      } catch (err) {
        console.log("error is ...", err)
        setLoading(false)
        alert(res.data.status.message)
        handleClose()
      }

    })
  }
  const handleChangePartnerOrganisation = (event) => {
    console.log(event.target.value, "#event")
    getSubOrganizationData(event.target.value)
  }
  const getSubOrganizationData = (event) => {
    if (token) {
      axios
        .get(suborgcreation, {
          params: {
            tenantId: event ? event : tenets[0].org_id
          },
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          console.log(res.data.organizationObjects, "response");
          if (res.data.organizationObjects.length > 0) {
            setSuborgList(res.data.organizationObjects);
          } else {
            setSuborgList([]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log('Token not available');
    }
  }
  const {
    data: tenets,
    isLoading: tenetsLoading,
    error: tenetsError
  } = useQuery('tenets', getTenets);
  if (tenetsLoading) {
    return <div>Loading...</div>;
  }

  if (tenetsError) {
    return <div>Error: {tenetsError.message}</div>;
  }
  const update = (row, type) => {
    setOpen(true)
    console.log(row, "selected row")
    setValue(`Organisation.${0}.name`, row.name);
    setValue(`Organisation.${0}.email`, row.email);
    setValue(`Organisation.${0}.mobile`, row.mobile);
    setValue(`Organisation.${0}.org_id`, row.org_id);
    setValue(`Organization.${0}.sub_org_id`, row.sub_org_id);
    console.log(tenets, "tenets")
    // const indexToUpdate = data.findIndex(org => org.org_id === row.org_id);

    // setValue(`Organization.${indexToUpdate}.parent_org_id`, row.parent_org_id);
    // // setValue(`Organization.${0}.party_type`, row.party_type);
    // getCurrentTypeData(row.org_name)
    setSelectedId(row.id)
    setStatus(type)
   }
   const deleteUser = (row, type) =>{
    let deletePayload = {
      id: row.id,
      status: 'DELETED'
    }
    try {
      setLoading(true)
      axios.put(suborgcreation, deletePayload, Api_Config).then((res) => {
        setLoading(false)
        console.log(res, "delete resp")
        if (res.data.status.code === 'SUCCESS') {
          alert(res.data.status.message)
          getUserData()
        }
      })
    } catch (err) {
      console.log("error is ...", err)
      setLoading(false)
      alert(res.data.status.message)
      handleClose()
    }
   }
  return (
    <Box className="flex-1 px-[24px] mt-[50px] p-[20px] m-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.0rem', textTransform: 'uppercase', letterSpacing: '0.4' }}>
          User Details
        </Typography>
        <IconButton aria-label="fingerprint" color="secondary" onClick={handleClickOpen}>
          <AddCircleOutlineOutlinedIcon />
        </IconButton>
      </Box>
      <TableContainer >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">User Name</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Mobile Number</TableCell>
              <TableCell align="right">Parent Organisation</TableCell>
              <TableCell align="right">Sub Organisation</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.mobile}</TableCell>
                <TableCell align="right">{row.org_id}</TableCell>
                <TableCell align="right">{row.sub_org_id}</TableCell>

                <TableCell align="right">
                  <Button onClick={() => update(row, "edit")}>Edit</Button>
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => deleteUser(row, "remove")}>Remove</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        style={{ alignSelf: 'flex-end', marginTop: '10px' }}
        count={Math.ceil(userList.length / rowsPerPage)}
        page={page}
        onChange={handleChangePage}
      />
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        {/* <DialogTitle
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: '1.0rem',
            textTransform: 'uppercase',
            letterSpacing: '0.4',
            pl: 5
          }}>
          Add Organisation
        </DialogTitle> */}
        <Box sx={{ p: 2 }}>
          <form onSubmit={handleSubmit(onsubmit)}>
            <Paper elevation={0} sx={{  px: 3, mb: 3 }}>
              <Typography sx={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.0rem', textTransform: 'uppercase', letterSpacing: '0.4' }}>
                User Details
              </Typography>
              {data.map((field, index) => (
                <Box key={field.id} sx={{my:2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField
                      label="User Name"
                      variant="outlined"
                      {...register(`Organisation.${index}.name`)}
                      size="small"
                      sx={{ marginRight: 2 }}
                      disabled={status === 'edit'? true: false}
                    />
                    <TextField
                      label="Email"
                      variant="outlined"
                      {...register(`Organisation.${index}.email`)}
                      size="small"
                      sx={{ marginRight: 2 }}
                    />
                    <TextField
                      label="Mobile Number"
                      variant="outlined"
                      {...register(`Organisation.${index}.mobile`)}
                      size="small"
                      sx={{ marginRight: 2 }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      mb: 4,
                      mt: 2
                    }}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">Parent Organization</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        {...register(`Organisation.${index}.org_id`)}
                        label="Parent Organisation"
                        size="small"
                        sx={{ width: 223, marginRight: 2 }}
                        onChange={handleChangePartnerOrganisation}>
                        {tenets.map((data) => (
                          <MenuItem value={data.org_id}>
                            {data.org_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">Sub Organization</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        {...register(`Organisation.${index}.sub_org_id`)}
                        //   value={age}
                        size="small"
                        label="Parent Organisation"
                        sx={{ width: 210 }}
                      //   onChange={handleChange}>
                      >
                        {suborgList.map((data)=>(
                          <MenuItem value={data.org_id}>{data.org_name}</MenuItem>
                        ))}
                        
                        
                      </Select>
                    </FormControl>

                  </Box>



                  {/* </Box> */}
                </Box>
              ))}
            </Paper>
            {/* <Paper elevation={3} sx={{ py: 3, px: 3, mb: 3 }}>
              <Typography sx={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.0rem', textTransform: 'uppercase', letterSpacing: '0.4' }}>
                HLF User mapping
              </Typography>
              {hlf.map((field, index) => (
                <Box key={index} sx={{ margin: 2 }}>
                  <TextField
                    label="HLF User"
                    variant="outlined"
                    {...register(`HlfDetails.${index}.hlfUser`)}
                    size="small"
                    sx={{ marginRight: 2 }}
                  />

                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Networks</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      {...register(`Organization.${index}.network`)}
                      label="Networks"
                      size="small"
                      sx={{ width: 223, marginRight: 2 }}
                    >
                      <MenuItem value={10}>A</MenuItem>
                      <MenuItem value={20}>B</MenuItem>
                      <MenuItem value={30}>C</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Channels</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      {...register(`Organization.${index}.channels`)}
                      label="Channels"
                      size="small"
                      sx={{ width: 223 }}
                    >
                      <MenuItem value={10}>A</MenuItem>
                      <MenuItem value={20}>B</MenuItem>
                      <MenuItem value={30}>C</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              ))}
            </Paper> */}
            {/* <Paper elevation={3} sx={{ py: 3, px: 3, mb: 3 }}>
              <Typography sx={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.0rem', textTransform: 'uppercase', letterSpacing: '0.4' }}>
                Config user mapping
              </Typography>
              {config.map((field, index) => (
                <Box key={field.id} sx={{ margin: 2 }}>
                  <TextField
                    label="Config User"
                    variant="outlined"
                    {...register(`ConfigDetails.${index}.configUser`)}
                    size="small"
                    sx={{ marginRight: 2 }}
                  />

                  <FormControl>
                    <InputLabel id="demo-simple-select-label">App Code</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      {...register(`ConfigDetails.${index}.appCode`)}
                      label="App Code"
                      size="small"
                      sx={{ width: 223, marginRight: 2 }}
                    >
                      <MenuItem value={10}>A</MenuItem>
                      <MenuItem value={20}>B</MenuItem>
                      <MenuItem value={30}>C</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl>
                    <InputLabel id="demo-simple-select-label">User Roles</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      {...register(`ConfigDetails.${index}.userRoles`)}
                      label="User Role"
                      size="small"
                      sx={{ width: 223, marginRight: 2 }}
                    >
                      <MenuItem value={10}>A</MenuItem>
                      <MenuItem value={20}>B</MenuItem>
                      <MenuItem value={30}>C</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              ))}
            </Paper> */}

            <Button type="submit" variant="contained" sx={{mx:4}}>
              {status != 'edit' ? 'Submit' : 'Update'}
            </Button>
          </form>
        </Box>
      </Dialog>

    </Box>
  )
}