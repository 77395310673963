import React from 'react';
import ReviewGridContainer from '../../container/object/ReviewGridContainer';

const ObjectReview = ({ setCurrentReviewState, reviewType }) => {
  return (
    <div className='h-[100%] flex flex-col'>
      <ReviewGridContainer reviewType={reviewType} setCurrentReviewState={setCurrentReviewState} />
    </div>
  );
};

export default ObjectReview;
