import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearSuccess, pushInSuccess, updateCurrent } from '../slice/stepFormSlice';

const LadderStepForm = ({ elements, id, defaultOpen, newForm = false }) => {
  const stepData = useSelector((store) => store.store?.stepForm?.[id]) || {};
  const dispatch = useDispatch();
  let { current: currentStoreData = 1, successList = [] } = stepData;
  const [current, setCurrentData] = useState(newForm ? 1 : Number(defaultOpen || currentStoreData));

  React.useEffect(() => {
    dispatch(updateCurrent({ id: id, current: 1 }));
    dispatch(clearSuccess({ id: id }));

    return () => {
      dispatch(updateCurrent({ id: id, current: 1 }));
      dispatch(clearSuccess({ id: id }));
      setCurrentData(1);
    };
  }, [newForm]);

  return elements.map((element, index) => {
    const { isNextDisabled, submitForm = null, noNext } = element;

    const roundedIcon = classNames(
      'h-[40px] w-[40px] rounded-full border-solid border-black bg-gray-500 mr-4 flex justify-center items-center text-white',
      {
        '!bg-blue-500': index + 1 == current,
        '!bg-green-500': successList.find((ele) => index + 1 == ele)
      }
    );

    const nextButtonClassName = classNames('h-[50px] ml-4 mt-4 rounded-[4px]  text-white px-2', {
      'bg-gray-500': isNextDisabled,
      'bg-orange-500': !isNextDisabled
    });

    const onNextClick = () => {
      dispatch(pushInSuccess({ id, current }));
      dispatch(updateCurrent({ id: id, current: current + 1 }));
      setCurrentData(current + 1);
    };

    const onBackClick = () => {
      dispatch(updateCurrent({ id: id, current: current - 1 }));
      setCurrentData(current - 1);
    };

    const onAccordianClick = ({ index = 1 }) => {
      if (elements.length !== index + 1 && defaultOpen) {
        dispatch(updateCurrent({ id: id, current: index + 1 }));
        setCurrentData(index + 1);
      }
    };
    return (
      <Accordion defaultExpanded={current == index + 1} expanded={current == index + 1}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => onAccordianClick({ index: index })}>
          <div className="text-[24px] font-semibold flex items-center">
            <div className={roundedIcon}>
              <div>{index + 1}</div>
            </div>
            <div className="mr-4">{element.title}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="!max-h-[500px] overflow-auto !pb-0">
          <div className={`flex my-4 ${element.additionalComponentClass}`}>
            {element.additionalComponent ? element.additionalComponent : <></>}
            {element.child}
          </div>
          <div className='sticky bottom-0 bg-white pb-4 mt-[80px]'>
            {index ? (
              <button
                onClick={onBackClick}
                className="w-[200px] h-[50px] ml-4 mt-4 rounded-[4px]  text-white bg-orange-500">
                Back
              </button>
            ) : null}
            {!noNext ? (
              <button
                onClick={submitForm || onNextClick}
                disabled={isNextDisabled}
                className={nextButtonClassName}>
                {element.nextText || 'Next'}
              </button>
            ) : null}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  });
};
export default LadderStepForm;
