import {
  editOrchestrationActionsSchema,
  orchestrationActionsSchema,
  viewOrchestrationActionsSchema
} from '../../../schema/form_schema/newOrchestrationFormSchema';
import { noop } from 'lodash';
import FormV2Container from '../../common/formV2/container/FormV2Container';
import { useParams } from 'react-router-dom';

const OrchestrationActionsContainer = ({ orchestrationData }) => {
  const { action = '' } = useParams();
  var schema;
  if (action == 'edit') {
    schema = editOrchestrationActionsSchema(orchestrationData);
  } else if (action == 'view') {
    schema = viewOrchestrationActionsSchema(orchestrationData);
  } else {
    schema = orchestrationActionsSchema();
  }

  return (
    <FormV2Container
      className={'bg-white'}
      variant="formV3"
      restrictRerender={false}
      schema={schema}
      onSubmitForm={noop}
      disableSubmit={true}
    />
  );
};

export default OrchestrationActionsContainer;
