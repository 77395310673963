import request from 'superagent';
import { deleteAppUrl } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const deleteApplication = async (queryData) => {
  const res = await request
    .put(deleteAppUrl)
    .query(queryData)
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${keycloak.token()}`);

  if (res.body.status.code == 'SUCCESS') {
    console.log('Application deleted successfully');
  } else {
    console.log(res.body.status.message);
  }

  return res;
};

export default deleteApplication;
