import React, { useCallback } from 'react';
import CustomHandle from './CustomHandle';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import db from '../../../public/flowIcons/db.png';
import http from '../../../public/flowIcons/http.png';
import leaf from '../../../public/flowIcons/leaf.png';
import split from '../../../public/flowIcons/split.png';
import script from '../../../public/flowIcons/script.png';
import start from '../../../public/flowIcons/start.png';
import subflow from '../../../public/flowIcons/subflow.png';
import sync from '../../../public/flowIcons/sync.png';
import value from '../../../public/flowIcons/value.png';
import wait from '../../../public/flowIcons/wait.png';
import condition from '../../../public/flowIcons/condition.png';
import iterative from '../../../public/flowIcons/iterative.png';

const CanvasLeftNode = ({ data }) => {
  const icons = {
    Start: start,
    Split: split,
    Script: script,
    End: leaf,
    Sync: sync,
    wait: wait,
    Http: http,
    SubFlow: subflow,
    Db: db,
    dt: value,
    Condition: condition,
    IterativeFlow: iterative
  };

  console.log('data is .... ', data);

  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  const dat = {
    label: data.label
  };

  return (
    <>
      {/* <div className="border bottom-2 bg-green-200 text-center flex items-center justify-center"> */}
      {/* {icons[data?.label] && (
          <img
            src={icons[data.label]}
            alt={data.label}
            width={24}
            height={24}
            className="mr-2"
          />
        )}
        <label>{data?.label}</label>
      </div> */}
      {data?.data?.handles &&
        Array.isArray(data.data.handles) &&
        data.data.handles.map((pos, index) => (
          <CustomHandle
            data={dat}
            key={index}
            type={pos?.handleType}
            position={pos?.position}
            isConnectable={pos?.isConnectable || false}
          />
        ))}
    </>
  );
};

export default CanvasLeftNode;
