import React, { useEffect } from 'react';
import ApplicationTilesContainer from '../container/ApplicationTiles';
import { useQuery } from 'react-query';
import fetchObjectMappingData from '../../../services/fetchObjectMappingData';
import { fetchObjectMappingAction } from '../slices/applicationSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ViewObjectMapping from '../../objectMappingV2/components/ViewObjectMapping';
import SideBar from '../../ApplicationV2/component/SideBar';
import { noop } from 'lodash';
import TopCardSubheader from '../../../layouts/TopCardSubheader';
import getApplicationTopNavigationSchema from '../../../utils/navigationUtils/getApplicationTopNavigationSchema';
import fetchApplicationCount from '../../../services/fetchApplicationCount';
import formatFeatureCountData from '../../../utils/formatFeatureCountData';
import getExtensionSubheaderSchema from '../../../utils/navigationUtils/getExtensionSubheaderSchema';

const ObjectMapping = () => {
  const { app_code, } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: applicationCountData,
    isLoading,
    isSuccess,
    isFetchedAfterMount,
    refetch
  } = useQuery({
    queryKey: ['object-mapping'],
    queryFn: fetchObjectMappingData({ appCode: app_code }),
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true
  });

  const storeObjectMappingData = useSelector(
    (store) => store.store.moreApplication.objectMappingData
  );

  const refetchOnDelete = async () => {
    const refetchData = await refetch();
    const { data, isSuccess } = refetchData;
    if (isSuccess) {
      dispatch(
        fetchObjectMappingAction({
          objectMappingData: data.body.objectMappingResponseItem
        })
      );
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        fetchObjectMappingAction({
          objectMappingData: applicationCountData.body.objectMappingResponseItem
        })
      );
    }
  }, [isLoading, isFetchedAfterMount]);

  const onMenuItemClick = ({ contextName, title }) => {
    switch (title) {
      case 'New Object Mapping': {
        navigate(`/${app_code}/object-mapping/new`);
        break;
      }
      case 'Object Mapping': {
        navigate(`/apps/${app_code}/objectMapping`);
      }
    }
  };

  return (
    <div className="flex">
      <SideBar
        page={'objectMapping'}
        onMenuItemClick={onMenuItemClick}
        currentContext={{ objectMapping: 'Object Mapping' }}
      />
      <div className="flex-1 flex flex-col bg-[#F4F3FA]">
        <TopCardSubheader
          topCardNavigationSchema={getApplicationTopNavigationSchema({ objectMappings: true })}
          getCountService={fetchApplicationCount(app_code)}
          formatCountData={formatFeatureCountData}
          subheaderNavigationSchema={getExtensionSubheaderSchema({ application: true })}
          >
          <ViewObjectMapping
            refetchOnDelete={refetchOnDelete}
            objectMappingData={storeObjectMappingData}
          />
        </TopCardSubheader>
      </div>
    </div>
  );
};

export default ObjectMapping;
