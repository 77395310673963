import React, { useState } from 'react';
import leftArrow from '../../../public/icons/left.png';
import rightArrow from '../../../public/icons/right.png';
import edit from '../../../public/icons/edit.svg';
import { useNavigate, useParams } from 'react-router-dom';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import { toast } from 'react-hot-toast';
import Success from '../../notify/Success';
import ErrorNotify from '../../notify/ErrorNotify';
import ConformationContainer from '../../common/conformationModal/containers';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { map, noop } from 'lodash';
import { useDispatch } from 'react-redux';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import MenuContainer from '../../common/menu/containers/MenuContainer';
import {
  DeleteOutline,
  EditAttributes,
  VisibilityOffOutlined,
  VisibilityOutlined
} from '@material-ui/icons';

function paginateArray(array, elementsPerPage, pageNumber) {
  const totalElements = array.length;
  const totalPages = Math.ceil(totalElements / elementsPerPage);

  if (pageNumber < 1 || pageNumber > totalPages) {
    return {
      totalPages: totalPages,
      elementsReturned: 0,
      paginatedArray: []
    };
  }

  const startIndex = (pageNumber - 1) * elementsPerPage;
  const endIndex = Math.min(startIndex + elementsPerPage, totalElements);

  const paginatedArray = array.slice(startIndex, endIndex);

  return {
    totalPages: totalPages,
    elementsReturned: paginatedArray.length,
    paginatedArray: paginatedArray
  };
}

const ViewCards = ({ sourceData, childrens = [], refetchOnDelete }) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const onIncrease = () => {
    if (childrens.length > page * 4) setPage(page + 1);
  };

  const { app_code = '' } = useParams();
  const navigate = useNavigate();

  const onEditClick = ({ editId, sourceId, sourceName, targetId, targetName }) => {
    navigate(
      `/${app_code}/object-mapping/edit/${editId}?sourceId=${sourceId}&sourceName=${sourceName}&targetId=${targetId}&targetName=${targetName}`
    );
  };

  const onViewClick = ({ editId, sourceId, sourceName, targetId, targetName }) => {
    navigate(
      `/${app_code}/object-mapping/view/${editId}?sourceId=${sourceId}&sourceName=${sourceName}&targetId=${targetId}&targetName=${targetName}`
    );
  };

  const onDeleteClick = async ({ editId, sourceId, sourceName, targetId, targetName }) => {
    const response = await request
      .put(`${process.env.REACT_APP_CONFIG_API}/object-mapping/mapdelete`)
      .query({
        souId: sourceId,
        tarId: targetId,
        appCode: app_code,
        id: editId
      })
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${keycloak.token()}`);

    if (response.body.status == '200') {
      toast.custom((t) => <Success t={t} message={'Mapping Deleted Successfully'} />, {
        duration: 4000
      });
      refetchOnDelete();
    } else {
      toast.custom((t) => <ErrorNotify t={t} message={'Failed Deleting Mapping'} />, {
        duration: 4000
      });
    }
  };

  const showSureDeleteModal = (key) => {
    dispatch(
      genericModalStateUpdate({
        modalKey: key,
        isOpen: true
      })
    );
  };

  const onDecrease = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const pageData = paginateArray(childrens, 4, page);
  const cardElements = pageData.paginatedArray;
  const pages = pageData.totalPages;
  let badgeClassName;
  let menuData;
  return (
    <>
      <div className="mt-[32px] flex-1 overflow-auto">
        {cardElements.map((map) => {
          switch (map.status) {
            case 'REVIEW': {
              badgeClassName =
                'bg-gray-500 text-white h-[26px] px-2 rounded-md min-w-[96px] text-center';
              menuData = [
                {
                  name: 'View',
                  callback: () =>
                    onViewClick({
                      editId: map?.mappingId,
                      sourceId: sourceData?.sourceId,
                      sourceName: sourceData?.sourceName,
                      targetId: map?.targetId,
                      targetName: map?.targetName
                    }),
                  icon: <VisibilityOutlined fontSize="small" />
                }
              ];
              break;
            }
            case 'APPROVED': {
              badgeClassName =
                'bg-green-500 text-white h-[26px] px-2 rounded-md min-w-[96px] text-center';
              menuData = [
                {
                  name: 'View',
                  callback: () =>
                    onViewClick({
                      editId: map?.mappingId,
                      sourceId: sourceData?.sourceId,
                      sourceName: sourceData?.sourceName,
                      targetId: map?.targetId,
                      targetName: map?.targetName
                    }),
                  icon: <VisibilityOutlined fontSize="small" />
                },
                {
                  name: 'Edit',
                  callback: () =>
                    onEditClick({
                      editId: map?.mappingId,
                      sourceId: sourceData?.sourceId,
                      sourceName: sourceData?.sourceName,
                      targetId: map?.targetId,
                      targetName: map?.targetName
                    }),
                  icon: <EditAttributes fontSize="small" />
                },
                {
                  name: 'Delete',
                  callback: () =>
                    showSureDeleteModal(`${ModalKeys.deleteMapping}${map?.mappingId}`),
                  icon: <DeleteOutline fontSize="small" />
                }
              ];
              break;
            }
            default: {
              badgeClassName =
                'bg-red-500 text-white h-[26px] px-2 rounded-md min-w-[96px] text-center';
              menuData = [
                {
                  name: 'View',
                  callback: () =>
                    onViewClick({
                      editId: map?.mappingId,
                      sourceId: sourceData?.sourceId,
                      sourceName: sourceData?.sourceName,
                      targetId: map?.targetId,
                      targetName: map?.targetName
                    }),
                  icon: <VisibilityOutlined fontSize="small" />
                },
                {
                  name: 'Edit',
                  callback: () =>
                    onEditClick({
                      editId: map?.mappingId,
                      sourceId: sourceData?.sourceId,
                      sourceName: sourceData?.sourceName,
                      targetId: map?.targetId,
                      targetName: map?.targetName
                    }),
                  icon: <EditAttributes fontSize="small" />
                },
                {
                  name: 'Delete',
                  callback: () =>
                    showSureDeleteModal(`${ModalKeys.deleteMapping}${map?.mappingId}`),
                  icon: <DeleteOutline fontSize="small" />
                }
              ];
              break;
            }
          }

          return (
            <div className="flex justify-between my-[8px] h-[54px] w-full border-b-2 border-[#EEEEEE]">
              <div className="truncate w-[70%]">{map?.targetName}</div>
              <div className='relative right-4'>{map?.version}</div>
              <div className="flex w-[30%] justify-between">
                <div className={badgeClassName}>{map.status}</div>
                <MenuContainer menuData={menuData} />
                {/* <div
                  className="cursor-pointer"
                  onClick={() =>
                    onViewClick({
                      editId: map?.mappingId,
                      sourceId: sourceData?.sourceId,
                      sourceName: sourceData?.sourceName,
                      targetId: map?.targetId,
                      targetName: map?.targetName
                    })
                  }>
                  <img
                    height={'20px'}
                    width={'20px'}
                    src={'https://img.icons8.com/material-outlined/24/view.png'}
                    alt=""
                    srcset=""
                  />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    onEditClick({
                      editId: map?.mappingId,
                      sourceId: sourceData?.sourceId,
                      sourceName: sourceData?.sourceName,
                      targetId: map?.targetId,
                      targetName: map?.targetName
                    })
                  }>
                  <img height={'20px'} width={'20px'} src={edit} alt="" srcset="" />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    showSureDeleteModal(`${ModalKeys.deleteMapping}${map?.mappingId}`)
                  }>
                  <img
                    height={'20px'}
                    width={'20px'}
                    src={'https://img.icons8.com/material-rounded/24/filled-trash.png'}
                    alt=""
                    srcset=""
                  />
                </div> */}
                <ConformationContainer
                  modalId={`${ModalKeys.deleteMapping}${map?.mappingId}`}
                  cancelObject={{ text: 'Cancel', callback: noop }}
                  conformObject={{
                    text: 'Delete Mapping',
                    callback: () =>
                      onDeleteClick({
                        editId: map?.mappingId,
                        sourceId: sourceData?.sourceId,
                        sourceName: sourceData?.sourceName,
                        targetId: map?.targetId,
                        targetName: map?.targetName
                      })
                  }}
                  modalText={{
                    header: 'Delete Object Mapping',
                    desc: 'Delete object mapping will delete a object mapping the application'
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-between items-center mt-[8px]">
        <div className="flex justify-between items-center">
          <div className="text-[10px] text-[#818A94] mr-[8px]">
            {page} of {pages}
          </div>
          <button onClick={onDecrease}>
            <img src={leftArrow} alt="" srcset="" />
          </button>
          <button onClick={onIncrease}>
            <img src={rightArrow} alt="" srcset="" />
          </button>
        </div>
      </div>
    </>
  );
};

export default ViewCards;
