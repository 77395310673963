import request from 'superagent';
import {  fetchOrchestration } from '../modules/dashboard/constants/applicationRequest';
import keycloak from '../Keycloak';

const fetchOrchestrationData = ({ appCode = '' }) => {
  return async () => {
    const res = await request
      .get(fetchOrchestration)
      .query({ appCode: appCode })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    return res;
  };
};

export default fetchOrchestrationData;
