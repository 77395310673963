import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    console.error(error); 
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="w-[100%] flex justify-center pb-[100px]">
          <div className="flex flex-col items-center">
            <div className="text-center text-[50px] mt-[200px]">Page Under Development</div>
            <iframe
              src="https://giphy.com/embed/QX15lZJbifeQPzcNDt"
              width="384"
              height="480"
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen></iframe>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
