import { FormControl, MenuItem, Select } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const FetchDropdown = ({
  getData,
  formatData,
  triggerMe = '',
  classname,
  label,
  isFormDisabled,
  disabled,
  defaultValue,
  value,
  name,
  onChange: handleChange,
  multiple = false
}) => {
  const [data, setData] = useState([{ name: 'Loading...', value: '' }]);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const fetchDropdownData = useCallback(
    () =>
      getData().then((fetchedData) => {
        setData(formatData(fetchedData));
      }),
    [triggerMe]
  );

  useEffect(() => {
    if (triggerMe) {
      setIsDataFetched(true);
    }
  }, [triggerMe]);

  const localOnOpen = (e) => {
    if (!isDataFetched) {
      fetchDropdownData();
    }
  };

  return multiple ? (
    <div className={`flex flex-col max-w-[200px] mx-[20px] align-middle mt-[8px] ${classname}`}>
      <FormControl className="!min-w-[200px]">
        <div className="overflow-hidden whitespace-nowrap">{label}</div>
        <Select
          className="max-w-[200px]"
          onOpen={localOnOpen}
          multiple
          disabled={isFormDisabled}
          value={value || []}
          key={name}
          onChange={(e) => handleChange(e, '', 'multiSelect')}
          name={name}
          autoWidth>
          {data.map((option) => {
            console.log(option, '88888');
            const { name, value } = option;
            return (
              <MenuItem
                disabled={(defaultValue || []).find((ele) => name == ele)}
                key={name}
                value={value}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  ) : (
    <div className={`flex flex-col max-w-[200px] mx-[20px] align-middle mt-2 ${classname}`}>
      <FormControl className="!min-w-[200px]">
        <div>{label}</div>
        <Select
          disabled={isFormDisabled || disabled}
          defaultValue={defaultValue}
          value={value}
          key={name}
          onChange={handleChange}
          onOpen={localOnOpen}
          name={name}
          autoWidth>
          {data.map((option) => {
            const { name, value } = option;
            return (
              <MenuItem key={name} value={value}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default FetchDropdown;
