import navigationSchemaConstant from '../constants/navigationSchemaConstant';

function formatFeatureCountData(data) {
  const countData = data ? data?.body?.applicationCountObjects?.[0] : {};
  return {
    [navigationSchemaConstant?.features]: countData?.featuresCount || 0,
    [navigationSchemaConstant?.orchestrations]: countData?.orchestrationCount || 0,
    [navigationSchemaConstant?.objects]: countData?.objectsCount || 0,
    [navigationSchemaConstant?.objectMappings]: countData?.objectMappingCount || 0
  };
}

export default formatFeatureCountData;
