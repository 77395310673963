import {
  TextField,
  Checkbox,
  Switch,
  FormControl,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Button
} from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { noop } from 'lodash';
import Editor from '@monaco-editor/react';

const FieldComponentV3 = ({
  label,
  type,
  name,
  showSwitchName,
  handleChange,
  disabled,
  defaultValue,
  value,
  isFormDisabled,
  disableForm,
  options,
  errorData,
  multiline,
  disableFullForm = {},
  handleFormSpecBtn,
  showTable = false,
  tableData = {},
  onTableFieldDataChange,
  tableFieldsData,
  multilineRows,
  showThisField,
  editor = false,
  defaultLanguage = 'json',
  classname = '',
  onBtnClick,
  path
}) => {
  const dropdownClass = classNames(
    'h-[40px] w-[235px] bg-white border-solid border-[rgba(0, 0, 0, 0.87)] border-[1px] rounded-md p-2',
    {
      '!bg-gray-100 text-gray-500': isFormDisabled || disableFullForm
    }
  );
  console.log(showThisField);

  switch (type) {
    case 'checkbox':
      return showThisField ? (
        <div className={`flex max-w-[300px] mx-[20px] align-middle ${classname}`}>
          <div className="pt-[8px] text-[#2965B3]">{label}</div>
          <Checkbox
            key={name}
            name={name}
            onChange={(e) => {
              handleChange({ target: { name: name, value: e.target.checked } });
            }}
            checked={!!value}
            defaultChecked={!!defaultValue}
            disabled={disabled || isFormDisabled || disableFullForm}
          />
        </div>
      ) : null;

    case 'switcher':
      return (
        <>
          <p>hi</p>
        </>
      );

    case 'text':
      if (multiline) {
        return showThisField ? (
          <div className="flex flex-col w-[500px] mx-[20px] my-2">
            <div className="overflow-hidden whitespace-nowrap text-lg text-[#2965B3] mb-2">
              {label} :
            </div>
            <TextField
              error={!!errorData}
              label={!!errorData ? errorData : ''}
              disabled={disabled || isFormDisabled || disableFullForm}
              onChange={handleChange}
              defaultValue={defaultValue}
              value={value}
              key={name}
              multiline
              rows={multilineRows}
              // style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}
            />
          </div>
        ) : null;
      } else if (editor) {
        return showThisField ? (
          <div className="flex flex-col w-[500px] mx-[20px] my-2">
            <div className="overflow-hidden whitespace-nowrap text-lg text-[#2965B3] mb-2">
              {label} :
            </div>
            <Editor
              className="border-2 !border-gray-200 border-solid rounded-md"
              height={'200px'}
              error={!!errorData}
              label={!!errorData ? errorData : ''}
              disabled={disabled || isFormDisabled || disableFullForm}
              onChange={(data) => {
                handleChange({ target: { name: name, value: data } });
              }}
              defaultValue={defaultValue}
              value={value}
              key={name}
              multiline
              rows={multilineRows}
              defaultLanguage={defaultLanguage}
            />
          </div>
        ) : null;
      } else {
        return showThisField ? (
          <div className="flex flex-col max-w-[300px] mx-[20px] my-2">
            <div className="overflow-hidden whitespace-nowrap text-lg text-[#2965B3] mb-2">
              {label} :
            </div>
            <TextField
              error={!!errorData}
              label={!!errorData ? errorData : ''}
              disabled={disabled || isFormDisabled || disableFullForm}
              onChange={handleChange}
              defaultValue={defaultValue}
              value={value}
              key={name}
              size="small"
              // style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}
            />
          </div>
        ) : null;
      }
    case 'switch':
      return showThisField ? (
        <div class="flex mt-7 mx-[20px]">
          {showSwitchName ? <div class="mr-4 flex items-center">{name}</div> : null}
          <Switch
            key={name}
            name={name}
            onChange={(e) => {
              handleChange({ target: { name: name, value: e.target.checked } }, disableForm);
            }}
            checked={!!value}
            defaultChecked={!!defaultValue}
            disabled={disabled || disableFullForm}
          />
        </div>
      ) : null;
    case 'dropdown': {
      return showThisField ? (
        <div className="flex flex-col min-w-[233.33px] mx-[20px] align-middle my-4">
          <div className="text-lg text-[#2965B3] mb-2">{label}</div>
          <select
            placeholder="hello"
            className={dropdownClass}
            onChange={handleChange}
            disabled={isFormDisabled || disableFullForm || disabled}
            value={value || ''}>
            <option disabled hidden key={'random'} value="">
              Select
            </option>
            {options.map((option, index) => {
              const { name, value } = option;
              return (
                <option key={`${name}${index}`} value={value}>
                  {name}
                </option>
              );
            })}
          </select>
        </div>
      ) : null;
    }
    case 'multiSelect': {
      return showThisField ? (
        <div className="flex flex-col mx-[20px] align-middle">
          <FormControl className="!min-w-[233.33px]">
            <div className="text-lg text-[#2965B3] mb-2">{label}</div>
            <Select
              className={dropdownClass}
              multiple
              disabled={isFormDisabled || disableFullForm}
              value={value || []}
              key={name}
              onChange={(e) => handleChange(e, '', 'multiSelect')}
              name={name}
              autoWidth>
              {options.map((option) => {
                const { name, value } = option;
                return (
                  <MenuItem
                    disabled={(defaultValue || []).find((ele) => name == ele)}
                    key={name}
                    value={value}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      ) : null;
    }
    case 'toggle': {
      if (options == ['YES', 'NO']) {
        var breakEvenData = 'NO';
      }
      return showThisField ? (
        <div class="flex flex-col mx-4">
          <div class="flex items-center text-[#2965B3] justify-center text-lg mb-2">{label}</div>
          <div className="flex justify-center">
            <ToggleButtonGroup
              style={{ height: '40px' }}
              disabled={isFormDisabled || disableFullForm || disabled}
              defaultValue={defaultValue}
              color="primary"
              exclusive
              onChange={handleChange}
              key={name}
              name={name}
              size="small"
              value={defaultValue || value || breakEvenData || ''}
              aria-label="Platform">
              {options.map((option, index) => {
                return (
                  <ToggleButton className="!text-[18px]" name={name} value={option}>
                    {option}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
        </div>
      ) : null;
    }
    case 'toggle2': {
      return showThisField ? (
        <div class="flex flex-col mx-4">
          <div class="flex items-center text-[#2965B3] justify-center text-lg mb-2">{label}</div>
          <div className="flex justify-center">
            <ToggleButtonGroup
              style={{ height: '40px' }}
              disabled={isFormDisabled || disableFullForm || disabled}
              defaultValue={defaultValue}
              color="primary"
              exclusive
              onChange={handleChange}
              key={name}
              name={name}
              size="small"
              value={defaultValue || value || 'NO'}
              aria-label="Platform">
              {options.map((option, index) => {
                return (
                  <ToggleButton className="!text-[18px]" name={name} value={option}>
                    {option}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
        </div>
      ) : null;
    }

    case 'textV2':
      if (multiline) {
        return showThisField ? (
          <div className="flex flex-col w-[500px] mx-[20px] my-2">
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{
                className: 'py-2 px-3 focus:outline-none focus:ring focus:border-blue-300'
              }}
              error={!!errorData}
              label={!!errorData ? errorData : label}
              disabled={disabled || isFormDisabled || disableFullForm}
              onChange={handleChange}
              defaultValue={defaultValue}
              value={value}
              key={name}
              multiline
              rows={5}
            />
          </div>
        ) : null;
      } else {
        return showThisField ? (
          <div className="flex flex-col max-w-[200px] mx-[20px] my-2">
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{
                className: 'py-2 px-3 focus:outline-none focus:ring focus:border-blue-300'
              }}
              error={!!errorData}
              label={!!errorData ? errorData : label}
              disabled={disabled || isFormDisabled || disableFullForm}
              onChange={handleChange}
              defaultValue={defaultValue}
              value={value}
              key={name}
            />
          </div>
        ) : null;
      }
    case 'numbers':
      if (multiline) {
        return showThisField ? (
          <div className="flex flex-col w-[500px] mx-[20px] my-2">
            <div className="overflow-hidden whitespace-nowrap text-lg text-[#2965B3] mb-2">
              {label} :
            </div>
            <TextField
              error={!!errorData}
              label={!!errorData ? errorData : ''}
              disabled={disabled || isFormDisabled || disableFullForm}
              onChange={handleChange}
              defaultValue={defaultValue}
              value={value}
              key={name}
              multiline
              rows={5}
              type="number"
              // style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}
            />
          </div>
        ) : null;
      } else {
        return showThisField ? (
          <div className="flex flex-col max-w-[300px] mx-[20px] my-2">
            <div className="overflow-hidden whitespace-nowrap text-lg text-[#2965B3] mb-2">
              {label} :
            </div>
            <TextField
              error={!!errorData}
              label={!!errorData ? errorData : ''}
              disabled={disabled || isFormDisabled || disableFullForm}
              onChange={handleChange}
              defaultValue={defaultValue}
              value={value}
              key={name}
              size="small"
              type="number"
              // style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}
            />
          </div>
        ) : null;
      }

    case 'btn':
      return showThisField ? (
        <div className="flex flex-col mx-[20px] my-2 w-[100%]">
          <div className="overflow-hidden whitespace-nowrap text-lg text-[#2965B3] mb-2">
            {label} :
          </div>
          <div className="flex">
            <TextField
              error={!!errorData}
              label={!!errorData ? errorData : ''}
              disabled={disabled || isFormDisabled || disableFullForm}
              onChange={handleChange}
              defaultValue={defaultValue}
              value={value}
              key={name}
              size="small"
              className="!w-[700px]"
            />
            <Button
              onClick={() => handleFormSpecBtn(value)}
              value={value}
              className="!bg-blue-400 !flex !items-center !space-x-2 !px-4 !py-2 !rounded-r-lg !rounded-l-none !relative left-[-10px]">
              <ArrowForwardIosIcon className="text-white" />
            </Button>
          </div>
        </div>
      ) : null;
    case 'table': {
      const { tableColumnsData = [], tableRowsData, dataTypeOptions } = tableData;
      const [data, setData] = useState([]);
      useEffect(() => {
        setData(tableRowsData);
      }, [JSON.stringify(tableRowsData)]);
      if (tableRowsData && dataTypeOptions) {
        var tableColumns = [];
        tableColumnsData.map((ele, index) => {
          const { type, name, onClick = noop, btnTxt = '' } = ele;
          switch (type) {
            case 'static': {
              tableColumns.push({
                Header: ele.name,
                accessor: ele.key
              });
              break;
            }
            case 'text': {
              tableColumns.push({
                Header: ele.name,
                accessor: ele.key,
                Cell: ({ row }) => {
                  return (
                    <TextField
                      disabled={disabled || isFormDisabled || disableFullForm}
                      onChange={(e) => onTableFieldDataChange(e, `${ele.key}-${row.index}`)}
                      // defaultValue={tableFieldsData[`${ele.key}-${row.index}`] || ''}
                      // value={tableFieldsData[`${ele.key}-${row.index}`]}
                      key={`${ele.key}-${row.index}`}
                      className="w-[100%]"
                      id={`${ele.key}-${row.index}`}
                    />
                  );
                }
              });
              break;
            }
            case 'dropdown': {
              tableColumns.push({
                Header: ele.name,
                accessor: ele.key,
                Cell: ({ row }) => {
                  return (
                    <select
                      className={`${dropdownClass} !w-[100%]`}
                      id={`${ele.key}-${row.index}`}
                      onChange={(e) => onTableFieldDataChange(e, `${ele.key}-${row.index}`)}>
                      <option key={'random'} value="">
                        Select
                      </option>
                      {dataTypeOptions.map((option) => {
                        return (
                          <option key={`${option.name}-${row.index}`} value={option.value}>
                            {option.name}
                          </option>
                        );
                      })}
                    </select>
                  );
                }
              });
              break;
            }

            case 'staticBtn': {
              tableColumns.push({
                Header: ele.name,
                accessor: ele.key,
                Cell: ({ row }) => {
                  return (
                    <Button onClick={() => onClick(row.index)}>{btnTxt || 'Validations'}</Button>
                  );
                }
              });
              break;
            }
          }
        });
        const columns = useMemo(() => tableColumns, []);

        const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
          columns,
          data
        });

        return (
          <div className={showTable ? `!w-[100%] ml-[20px]` : `hidden`}>
            <table {...getTableProps()} className="border-collapse border w-[1000px]">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="p-2 border border-gray-300 text-[18px] text-[#2965B3]">
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="p-1 border border-gray-300 text-center text-[16px]">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      } else {
        return <></>;
      }
    }
    case 'tableEdit': {
      const { tableColumnsData = [], tableRowsData, dataTypeOptions, defaultData } = tableData;
      const [data, setData] = useState([]);
      useEffect(() => {
        setData(tableRowsData);
        console.log("data is......525",tableRowsData)
      }, [JSON.stringify(tableRowsData)]);
      if (tableRowsData && dataTypeOptions) {
        var tableColumns = [];
        tableColumnsData.map((ele, index) => {
          const { type, name, onClick = noop, btnTxt = '' } = ele;
          switch (type) {
            case 'static': {
              tableColumns.push({
                Header: ele.name,
                accessor: ele.key
              });
              break;
            }
            case 'text': {
              tableColumns.push({
                Header: ele.name,
                accessor: ele.key,
                Cell: ({ row }) => {
                  return (
                    <TextField
                      disabled={disabled || isFormDisabled || disableFullForm}
                      onChange={(e) => onTableFieldDataChange(e, `${ele.key}-${row.index}`)}
                      defaultValue={row?.original?.description}
                      // value={tableFieldsData[`${ele.key}-${row.index}`]}
                      key={`${ele.key}-${row.index}`}
                      className="w-[100%]"
                      id={`${ele.key}-${row.index}`}
                    />
                  );
                }
              });
              break;
            }
            case 'dropdown': {
              tableColumns.push({
                Header: ele.name,
                accessor: ele.key,
                Cell: ({ row }) => {
                  return (
                    <select
                      defaultValue={row?.original?.type_id}
                      className={`${dropdownClass} !w-[100%]`}
                      id={`${ele.key}-${row.index}`}
                      onChange={(e) => onTableFieldDataChange(e, `${ele.key}-${row.index}`)}>
                      <option key={'random'} value="">
                        Select
                      </option>
                      {dataTypeOptions.map((option) => {
                        return (
                          <option key={`${option.name}-${row.index}`} value={option.value}>
                            {option.name}
                          </option>
                        );
                      })}
                    </select>
                  );
                }
              });
              break;
            }
            // case 'checkbox': {
            //   tableColumns.push({
            //     Header: ele.name,
            //     accessor: ele.key,
            //     Cell: ({ row }) => {
            //       console.log("row data is ",row)
            //       return (
            //         <Checkbox
            //           key={name}
            //           name={name}
            //           onChange={(e) => onTableFieldDataChange(e, `${ele.key}-${row.index}`)}
                   
            //           disabled={row?.original?.param_type=="path"?true:false}
            //           defaultChecked={row?.original?.param_type=="path"?true:false}
            //           // disabled={ele.param_type=="path"?true:false}
            //         />
            //       );
            //     }
            //   });
            //   break;
            // }

            case 'staticBtn': {
              tableColumns.push({
                Header: ele.name,
                accessor: ele.key,
                Cell: ({ row }) => {
                  return (
                    <Button onClick={() => onClick(row.index)}>{btnTxt || 'Validations'}</Button>
                  );
                }
              });
              break;
            }
          }
        });
        const columns = useMemo(() => tableColumns, []);

        const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
          columns,
          data
        });

        return (
          <div className={showTable ? `!w-[100%] ml-[20px]` : `hidden`}>
            <table {...getTableProps()} className="border-collapse border w-[1000px]">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="p-2 border border-gray-300 text-[18px] text-[#2965B3]">
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="p-1 border border-gray-300 text-center text-[16px]">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      } else {
        return <></>;
      }
    }
    case 'tableView': {
      const { tableColumnsData = [], tableRowsData, dataTypeOptions, defaultData } = tableData;
      const [data, setData] = useState([]);
      useEffect(() => {
        setData(tableRowsData);
      }, [JSON.stringify(tableRowsData)]);
      if (tableRowsData && dataTypeOptions) {
        var tableColumns = [];
        tableColumnsData.map((ele, index) => {
          const { type, name, onClick = noop, btnTxt = '' } = ele;
          switch (type) {
            case 'static': {
              tableColumns.push({
                Header: ele.name,
                accessor: ele.key
              });
              break;
            }
            case 'text': {
              tableColumns.push({
                Header: ele.name,
                accessor: ele.key,
                Cell: ({ row }) => {
                  return (
                    <TextField
                      disabled={disabled || isFormDisabled || disableFullForm}
                      onChange={(e) => onTableFieldDataChange(e, `${ele.key}-${row.index}`)}
                      defaultValue={row?.original?.description}
                      // value={tableFieldsData[`${ele.key}-${row.index}`]}
                      key={`${ele.key}-${row.index}`}
                      className="w-[100%]"
                    />
                  );
                }
              });
              break;
            }
            case 'dropdown': {
              tableColumns.push({
                Header: ele.name,
                accessor: ele.key,
                Cell: ({ row }) => {
                  return (
                    <select
                      disabled
                      defaultValue={row?.original?.type_id}
                      className={`${dropdownClass} !w-[100%]`}
                      onChange={(e) => onTableFieldDataChange(e, `${ele.key}-${row.index}`)}>
                      <option key={'random'} value="">
                        Select
                      </option>
                      {dataTypeOptions.map((option) => {
                        return (
                          <option key={`${option.name}-${row.index}`} value={option.value}>
                            {option.name}
                          </option>
                        );
                      })}
                    </select>
                  );
                }
              });
              break;
            }
            case 'staticBtn': {
              tableColumns.push({
                Header: ele.name,
                accessor: ele.key,
                Cell: ({ row }) => {
                  return (
                    <Button onClick={() => onClick(row.index)}>{btnTxt || 'Validations'}</Button>
                  );
                }
              });
              break;
            }
          }
        });
        const columns = useMemo(() => tableColumns, []);

        const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
          columns,
          data
        });

        return (
          <div className={showTable ? `!w-[100%] ml-[20px]` : `hidden`}>
            <table {...getTableProps()} className="border-collapse border w-[1000px]">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="p-2 border border-gray-300 text-[18px] text-[#2965B3]">
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="p-1 border border-gray-300 text-center text-[16px]">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      } else {
        return <></>;
      }
    }
    case 'button': {
      return showThisField ? (
        <div className="flex flex-col mx-[20px] mt-[33px] w-[100%]">
          <div className="flex">
            <Button
              onClick={onBtnClick}
              value={path[1]}
              className={`!bg-blue-400 !flex !items-center !space-x-2 !px-4 !py-2 ${classname} !text-white`}>
              Validations
            </Button>
          </div>
        </div>
      ) : null;
    }
  }
};

export default FieldComponentV3;
