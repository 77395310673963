import { useEffect, useState } from 'react';

function GetEditChannelSchemaHook(data, allOrganizations, setCurrentChannelState) {
  const [updatedData, setUpdatedData] = useState(data);
  const [updatedOrgs, setUpdatedOrgs] = useState(allOrganizations);

  function updateSchemaOnJoinClick() {
    setCurrentChannelState(false);
    const newSchema = { ...initialSchema };
    newSchema.formSchema = newSchema.formSchema.slice(2);
    newSchema.formSchema.push({
      name: 'channels',
      label: 'channels',
      disableSubmit: true,
      require: false,
      type: 'Fields',
      hideTitle: true,
      data: {
        ...updatedData?.channels?.map((item) => {
          const editData = [
            {
              name: 'channelName',
              label: 'Channel Name',
              type: 'text',
              require: false,
              defaultValue: item?.name,
              disabled: true
            },
            {
              name: 'organizations',
              label: 'Organizations',
              type: 'multiSelect',
              require: false,
              options: updatedOrgs?.map((element) => {
                return { name: element, value: element };
              }),
              defaultValue: item?.orgNames,
              disabled: true
            }
          ];

          editData.isRemoveOff = true;

          return editData;
        })
      }
    });
    newSchema.formSchema.push({
      name: 'Back',
      label: 'Back',
      type: 'button',
      hideTitle: true,
      onButtonClick: initializeSchema,
      classname: '!h-[40px] !mt-2'
    });
    setChannelSchema(newSchema);
  }

  function updateSchemaOnNewChannel() {
    setCurrentChannelState(true);
    const newSchema = { ...initialSchema };
    newSchema.formSchema = newSchema.formSchema.slice(2);
    newSchema.formSchema.push({
      name: 'channels',
      label: 'channels',
      disableSubmit: true,
      require: false,
      type: 'Fields',
      duplicate: true,
      addIconName: 'Add Channel',
      removeIconName: 'Remove Channel',
      hideTitle: true,
      data: {
        0: [
          {
            name: 'channelName',
            label: 'Channel Name',
            type: 'text',
            require: false
          },
          {
            name: 'organizations',
            label: 'Organizations',
            type: 'multiSelect',
            require: false,
            options: updatedOrgs?.map((element) => {
              return { name: element, value: element };
            })
          }
        ]
      }
    });
    newSchema.formSchema.push({
      name: 'Back',
      label: 'back',
      type: 'button',
      onButtonClick: initializeSchema,
      classname: '!h-[40px] !mt-2'
    });
    setChannelSchema(newSchema);
  }

  const initialSchema = {
    title: 'channels',
    disableSubmit: true,
    hideTitle: true,
    formSchema: [
      {
        name: 'Join Channel',
        label: 'join',
        type: 'button',
        onButtonClick: updateSchemaOnJoinClick
      },
      {
        name: 'New Channel',
        label: 'New Channel',
        type: 'button',
        onButtonClick: updateSchemaOnNewChannel
      }
    ]
  };

  const initializeSchema = () => {
    setChannelSchema(initialSchema);
  };

  const [channelSchema, setChannelSchema] = useState(initialSchema);

  useEffect(() => {
    setChannelSchema(initialSchema);
  }, [JSON.stringify(updatedData), JSON.stringify(updatedOrgs)]);

  return [channelSchema, setChannelSchema, setUpdatedData, setUpdatedOrgs];
}

export default GetEditChannelSchemaHook;
