import React from 'react';
import DefaultDashboard from '../components/DefaultDashboard';

const DefaultDashboardContainer = ({ page = '', hideSideBar, children }) => {
  return (
    <DefaultDashboard hideSideBar={hideSideBar} page={page}>
      {children}
    </DefaultDashboard>
  );
};

export default DefaultDashboardContainer;
