import { Delete, Edit, ViewAgenda } from '@mui/icons-material';
import { Delete, Edit, FileUploadSharp, ViewAgenda } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { noop } from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import request from 'superagent';
import Tabs from '../../Tabs/components/Tabs';
import ConformationContainer from '../../common/conformationModal/containers';
import MenuContainer from '../../common/menu/containers/MenuContainer';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import GenericModalContainer from '../../modals/genericModal/container';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ErrorNotify from '../../notify/ErrorNotify';
import Success from '../../notify/Success';
import { setCurrentHLFId, setCurrentHLFNetwork } from '../slices/hlfSlice';

const HLFGrid = ({
  fetchDataObjectData = noop,
  dataObjectData = [],
  fetchHLFData,
  HLFData,
  title
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentClickedData, setCurrentClickedData] = useState({});
  const [currentType, setCurrentType] = useState('');

  const columnDefs = [
    { headerName: 'Customer', field: 'customer' },
    { headerName: 'Network', field: 'network' },
    { headerName: 'Description', field: 'description' },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: (data) => {
        return (
          <div
            className={`${
              data.data.status == 'Uploaded' ? 'text-green-700' : 'text-red-700'
            }`}>
            {data.data.status}
          </div>
        );
      }
    },
    {
      headerName: 'Actions',
      field: 'Actions',
      cellRenderer: (data) => (
        <MenuContainer
          menuData={[
            {
              name: 'View',
              callback: () => cellClicked(data, 'view'),
              icon: <ViewAgenda fontSize="small" />
            },
            {
              name: 'Manage Files',
              callback: () => cellClicked(data, 'manageFiles'),
              icon: <FileUploadSharp fontSize="small" />
            },
            {
              name: 'Delete',
              callback: () => cellClicked(data, 'delete'),
              icon: <Delete fontSize="small" />
            },
            {
              name: 'Edit',
              callback: () => cellClicked(data, 'edit'),
              icon: <Edit fontSize="small" />
            }
          ]}
        />
      )
    }
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  };

  function onShowLoading() {
    gridOptions.api.showLoadingOverlay();
  }

  function onHide() {
    gridOptions.api.hideOverlay();
  }

  let gridApi;

  const CustomLoader = CircularProgress;

  const gridOptions = {
    // other grid options
    overlayLoadingTemplate: '<div></div>',
    overlayNoRowsTemplate: '<div></div>',
    frameworkComponents: {
      customLoader: CustomLoader
    },
    loadingOverlayComponent: 'customLoader',
    loadingOverlayComponentParams: {}
  };

  const rowData = HLFData.map((feature, index) => {
    return {
      customer: feature.customer,
      description: feature.description,
      status: feature.upload_status,
      network: feature.network,
      id: feature.id
    };
  });

  async function onGridReady(params) {
    gridApi = params?.api;
    params?.api?.showLoadingOverlay();
    const fetchData = await fetchHLFData();
  }

  const onExportClick = () => {
    gridApi = params?.api;
    gridApi?.exportDataAsCsv();
  };

  const onDeleteClick = async (data) => {
    const deleteResult = await request
      .put(`${process.env.REACT_APP_CONFIG_API}/hlf/delete`)
      .query({
        id: data.id
      })
      .send({});
    await fetchHLFData();
    if (deleteResult?.body?.status?.code == 'SUCCESS') {
      toast.custom((t) => <Success t={t} message={deleteResult?.body?.status?.message} />, {
        duration: 4000
      });
    } else {
      toast.custom((t) => <ErrorNotify t={t} message={deleteResult?.body?.status?.message} />, {
        duration: 4000
      });
      console.log('Data Object Deletion failed');
    }
  };

  async function cellClicked(param, field) {
    const { data } = param;

    switch (field) {
      case 'copy': {
        navigate(`/hlf/copy/${data.networkName}`);
        break;
      }
      case 'edit': {
        navigate(`/hlf/edit/${data.id}`);
        break;
      }
      case 'delete': {
        setCurrentClickedData(data);
        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.deleteHlfModal,
            isOpen: true
          })
        );
        break;
      }
      case 'view': {
        setCurrentType('view');
        dispatch(
          setCurrentHLFId({
            id: data.id
          })
        );
        dispatch(
          setCurrentHLFNetwork({
            network: data.network
          })
        );
        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.viewHlf,
            isOpen: true
          })
        );
        break;
      }
      case 'manageFiles': {
        setCurrentType('manage');
        dispatch(
          setCurrentHLFId({
            id: data.id
          })
        );
        dispatch(
          setCurrentHLFNetwork({
            network: data.network
          })
        );
        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.viewHlf,
            isOpen: true
          })
        );
        break;
      }
    }
  }

  return (
    <div className="ag-theme-alpine flex-1 px-[24px] mt-[50px] p-[20px] m-[20px] border-2 border-[#E6EDFF] rounded-[10px] bg-white flex flex-col">
      <div className="text-[24px]">{title}</div>
      <div className="flex-1">
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={20}
          rowData={rowData}
          suppressCellFocus={true}
        />
      </div>
      <GenericModalContainer modalKey={ModalKeys.viewHlf}>
        <Tabs fetchHLFData={fetchHLFData} currentType={currentType} />
      </GenericModalContainer>
      <ConformationContainer
        formData={currentClickedData}
        doNotEmptyState={true}
        modalId={ModalKeys.deleteHlfModal}
        conformObject={{
          text: 'Delete Connection',
          callback: onDeleteClick
        }}
        cancelObject={{ text: 'Cancel', callback: noop }}
        modalText={{
          header: `Delete Connection`,
          desc: 'Do you really want to Remove!'
        }}
      />
    </div>
  );
};

export default HLFGrid;
