const connectionFrameworkNotifyInfoSchema = () => {
  return {
    title: 'Notify-Kafka',
    hideTitle: true,
    formSchema: [
      {
        name: 'kafkaBroker',
        label: 'Kafka Broker',
        type: 'text',
        required: true
      },
      {
        name: 'topicName',
        label: 'Topic Name',
        type: 'text',
        required: true
      }
    ]
  };
};

export default connectionFrameworkNotifyInfoSchema;
