import React from 'react';
import ReactJson from 'react-json-view';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { noop } from 'lodash';

const RecordInfoModal = ({ onRecordsModalClose = noop }) => {
  const modalData = useSelector((store) => store?.store?.genericModal?.modalKeys?.recordData);
  const logInfo = modalData[0]?.message;
  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="bg-white p-4 rounded-lg shadow-lg h-[400px] flex flex-col">
        <div className="flex justify-end mb-4">
          <button onClick={onRecordsModalClose}>
            <CloseIcon className="text-5xl w-9 h-9" />
          </button>
        </div>
        <div className="overflow-auto">
          <div className="p-6">
            <ReactJson src={logInfo} style={{ fontSize: '18px', display: 'block' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordInfoModal;
