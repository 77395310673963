function getGenericInfoSchema() {
  return {
    title: 'organization',
    disableSubmit: true,
    hideTitle: true,
    hideName: true,
    formSchema: [
      {
        name: 'organizations',
        label: 'Organizations',
        disableSubmit: true,
        require: false,
        hideName: true,
        type: 'Fields',
        duplicate: true,
        hideTitle: true,
        addIconName: 'Add Organization',
        removeIconName: 'Remove Organization',
        data: {
          0: [
            {
              name: 'organizationName',
              label: 'Name',
              type: 'text',
              require: false
            },
            {
              name: 'peers',
              label: 'peers',
              disableSubmit: true,
              require: false,
              hideName: true,
              type: 'Fields',
              duplicate: true,
              addIconName: 'Add Peers',
              removeIconName: 'Remove Peers',
              varient: 'full',
              data: {
                0: [
                  {
                    name: 'peers',
                    label: 'peers',
                    type: 'text',
                    require: false,
                    hideTitle: true
                  }
                ]
              }
            }
          ]
        }
      }
    ]
  };
}

export default getGenericInfoSchema;
