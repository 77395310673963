import React from 'react';
import { useParams } from 'react-router-dom';
import OrchestrationEditFormContainer from './OrchestrationEditFormContainer';
import OrchestrationFormContainer from './OrchestrationFormContainer';
import OrchestrationViewFormContainer from './OrchestrationViewFormContainer';

export const OrchestrationFormDecider = () => {
  const { action = '' } = useParams();
  switch (action) {
    case 'edit': {
      return <OrchestrationEditFormContainer />;
    }
    case 'view': {
      return <OrchestrationViewFormContainer />;
    }
    default: {
      return <OrchestrationFormContainer />;
    }
  }
};
