import { range } from 'lodash';

function findParentWithGivenId({ data, id, type }) {
  console.log("4 data is :- ",data,id,type)
  for (const item of data) {
    console.log("5 item is :- ",item)
    switch (type) {
      case 'source': {
        // if (item.sourceId == id) {
          if (item.id == id) {
          return item;
        } else if (item.children) {
          const foundItem = findParentWithGivenId({ data: item.children, id, type });
          if (foundItem) {
            return { ...item, children: [foundItem] };
          }
        }
        break;
      }
      case 'target': {
        // if (item.targetId == id) {
        if (item.id == id) {
          return item;
        } else if (item.children) {
          const foundItem = findParentWithGivenId({ data: item.children, id, type });
          if (foundItem) {
            return { ...item, children: [foundItem] };
          }
        }
        break;
      }
    }
  }
}

function getMappingStyledOutput(obj, type) {
  if (!obj) {
    return null;
  }

  switch (type) {
    case 'source': {
      obj = { ...obj, sourceId: obj.dataObjectKeyId, sourceName: obj.name };
      break;
    }
    case 'target': {
      obj = { ...obj, targetId: obj.dataObjectKeyId, targetName: obj.name };
      break;
    }
  }

  return {
    ...obj,
    children: obj?.keys?.map((element) => getMappingStyledOutput(element, type))
  };
}

function mergeObjectsWithMatchingProperties(arr) {
  const mergedObjects = {};

  arr.forEach((obj) => {
    const {
      dataObjectKeyId,
      code,
      name,
      description,
      isArray,
      objectTypeCode,
      objectCode,
      isOptional,
      dataObjectId,
      dataObjectTypeId,
      sourceName
    } = obj;
    const key = `${dataObjectKeyId}-${code}-${name}-${description}-${isArray}-${objectTypeCode}-${objectCode}-${isOptional}-${dataObjectId}-${dataObjectTypeId}-${sourceName}`;

    if (mergedObjects[key]) {
      mergedObjects[key].children.push(...obj.children);
    } else {
      mergedObjects[key] = { ...obj };
    }
  });

  return Object.values(mergedObjects);
}

function mapOnParentDrop({ oldObj, newObj, type }) {
  switch (type) {
    case 'source': {
      if (oldObj?.sourceId == newObj?.sourceId || oldObj?.targetId == newObj?.targetId) {
        const newObjChildren = newObj?.children?.[0] || newObj?.keys?.[0];

        const otherElements = oldObj?.children?.filter(
          (element) => element.sourceId != newObjChildren?.sourceId
        );
        const foundElement = oldObj?.children?.find(
          (element) => element.sourceId == newObjChildren?.sourceId
        );

        if (foundElement?.length) {
          return {
            ...oldObj,
            children: [
              ...otherElements,
              mapOnParentDrop({ oldObj: foundElement, newObj: newObjChildren, type })
            ]
          };
        } else {
          return {
            ...oldObj,
            children: [...otherElements, newObjChildren]
          };
        }
      } else {
        return oldObj;
      }
    }

    case 'target': {
      if (oldObj?.targetId == newObj?.targetId) {
        const newObjChildren = newObj?.children?.[0] || newObj?.keys?.[0];

        const otherElements = oldObj?.children?.filter(
          (element) => element.targetId != newObjChildren?.targetId
        );
        const foundElement = oldObj?.children?.find(
          (element) => element.targetId == newObjChildren?.targetId
        );

        if (foundElement?.length) {
          return {
            ...oldObj,
            children: [
              ...otherElements,
              mapOnParentDrop({ oldObj: foundElement, newObj: newObjChildren, type })
            ]
          };
        } else {
          return {
            ...oldObj,
            children: [...otherElements, newObjChildren]
          };
        }
      } else {
        return oldObj;
      }
    }
  }
}

function mergeJSONObjects(target, source, sourceId, targetId) {
  if (target?.id == targetId && source?.id == sourceId) {
    return {
      sourceName: source.name,
      targetName: target.name,
      sourceId: source.id,
      targetId: target.id
    };
  }

  if (source?.children && target?.children) {
    return {
      sourceName: source?.name,
      targetName: target?.name,
      sourceId: source?.id,
      targetId: target?.id,
      children: source.children.map((src) =>
        mergeJSONObjects(target.children[0], src, sourceId, targetId)
      )
    };
  } else {
    return source;
  }
}

function mapTheSigularSourceAndTarget({ source, target }) {
  if (!source || !target) {
    return null;
  }

  const childrenData = mapTheSigularSourceAndTarget({
    source: source?.children?.[0],
    target: target?.children?.[0]
  });

  return {
    sourceId: source.sourceId,
    sourceName: source.sourceName,
    targetId: target.targetId,
    targetName: target.targetName,
    ...(childrenData ? { children: [childrenData] } : {})
  };
}

function completeMapObject({ older, younger, type }) {
  console.log("196 older,younger,type:- ",older,younger,type)
  if (!older || !younger) {
    return [];
  }

  switch (type) {
    case 'source': {
      const youngChild = younger?.children?.[0];
      const olderChild = older?.children?.find((child) => child.sourceId == youngChild.sourceId);
      const otherChilds = older?.children?.filter(
        (child) => child.sourceId !== youngChild.sourceId
      );
      console.log("208 youngChild,olderChild,otherChilds:- ",youngChild,olderChild,otherChilds)
      const result = completeMapObject({ older: olderChild, younger: youngChild, type: type });
      console.log("210 result:- ",result)
      let newChildren = older?.children?.map((e)=>{
        if(e?.sourceId == result?.sourceId)
        {
          return result;
        }
        else {
          return e;
        }
      })
      if(newChildren == undefined) newChildren =[]
      console.log("221 newChildren:-",newChildren);

      const children = [...(otherChilds || []), result];
      console.log("212 children :- ",children)
      // return {
      //   sourceId: older.sourceId || younger.sourceId,
      //   sourceName: older.sourceName || younger.sourceName,
      //   targetName: younger.targetName || older.targetName,
      //   targetId: younger.targetId || older.targetId,
      //   ...(Array.isArray(result) ? {} : { children: children })
      // };

      return {
        sourceId: older.sourceId || younger.sourceId,
        sourceName: older.sourceName || younger.sourceName,
        targetName: younger.targetName || older.targetName,
        targetId: younger.targetId || older.targetId,
        ...(Array.isArray(result) ? {} : { children: newChildren })
      };
    }

    case 'target': {
      const youngChild = younger?.children?.[0];
      const olderChild = older?.children?.find((child) => child.targetId == youngChild.targetId);
      const otherChilds = older?.children?.filter(
        (child) => child.targetId !== youngChild.targetId
      );
      console.log("248 youngChild,olderChild,otherChilds:- ",youngChild,olderChild,otherChilds)
      const result = completeMapObject({ older: olderChild, younger: youngChild, type: type });
      console.log("250 result :- ",result);
      let newChildren = older?.children?.map((e)=>{
        if(e?.targetId == result?.targetId)
        {
          return result;
        }
        else {
          return e;
        }
      })
      if(newChildren == undefined) newChildren =[]
      console.log("261 newChildren:-",newChildren);

      const children = [...(otherChilds || []), result];
      console.log("264 children :- ",children)
      // return {
      //   sourceId: younger.sourceId || older.sourceId,
      //   sourceName: younger.sourceName || older.sourceName,
      //   targetName: older.targetName || younger.targetName,
      //   targetId: older.targetId || younger.targetId,
      //   ...(Array.isArray(result) ? {} : { children: children })
      // };

      return {
        sourceId: younger.sourceId || older.sourceId,
        sourceName: younger.sourceName || older.sourceName,
        targetName: older.targetName || younger.targetName,
        targetId: older.targetId || younger.targetId,
        ...(Array.isArray(result) ? {} : { children: newChildren })
      };
    }
  }
}

function makeDataFavourable({ data = [], type }) {
  switch (type) {
    case 'source': {
      return data?.map((element) => {
        return {
          sourceName: element?.name,
          sourceId: element?.dataObjectKeyId,
          id : element?.id,
          ...(element?.keys && element?.keys?.length
            ? {
                children: makeDataFavourable({ data: element?.keys, type: type })
              }
            : {})
        };
      });
    }
    case 'target': {
      return data?.map((element) => {
        return {
          targetName: element?.name,
          targetId: element?.dataObjectKeyId,
          id : element?.id,
          ...(element?.keys && element?.keys?.length
            ? {
                children: makeDataFavourable({ data: element?.keys, type: type })
              }
            : {})
        };
      });
    }
  }
}

export {
  findParentWithGivenId,
  getMappingStyledOutput,
  mergeObjectsWithMatchingProperties,
  mergeJSONObjects,
  mapOnParentDrop,
  getOldNewMergedData,
  mapTheSigularSourceAndTarget,
  completeMapObject,
  makeDataFavourable
};
