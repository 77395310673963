import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getFieldData from '../../../HyperledgerFabricNetworks/container/getFieldData';
import { setIsEditForm, updateField } from '../../form/slices/formSlice';
import Joi from 'joi';
import FieldComponentV3 from '../component/FieldComponentV3';
import { noop } from 'lodash';

const fieldValidation = () => {
  var validationInstance = Joi;

  validationInstance = validationInstance.string().required();
  return validationInstance;
};

const FieldContainerV3 = (props) => {
  const {
    restrictRerender,
    form,
    path,
    isFormDisabled: disableForm = false,
    disableFullForm = {},
    showTable
  } = props;

  const {
    defaultValue = '',
    label = '',
    disabled = false,
    name,
    showSwitchName = false,
    type,
    overloadOnchange,
    options = [],
    validation,
    multiline = false,
    btnFunctionality,
    tableData = {},
    notValidate,
    multilineRows = 5,
    showThisField = true,
    editor = false,
    defaultLanguage = 'json',
    classname = '',
    onBtnClick = noop,
    validationsEditData = {}
  } = form;
  let formStore = useSelector((store) => store.store.form?.formData) || {};
  let dispatch = useDispatch();
  const errorPath = [...path.slice(0, path.length - 1), 'error', ...path.slice(-1)];

  const disablePath = [...path];
  disablePath.pop();
  disablePath.push('isFormDisabled');

  const fieldData = getFieldData({ path, data: formStore });
  const errorData = getFieldData({ path: errorPath, data: formStore });
  const isFormDisabled = getFieldData({ path: disablePath, data: formStore });

  const { tableColumnsData = [], tableRowsData = [] } = tableData;
  var tableDataPath = [];

  const onFieldDataChange = (event, isDisableForm) => {
    const { value } = event.target;
    if (value !== '') {
      dispatch(setIsEditForm(true));
    }

    if (overloadOnchange) {
      overloadOnchange(event);
    }
    dispatch(updateField({ path, value: value }));
    if (isDisableForm === true) {
      dispatch(updateField({ path: disablePath, value: !value }));
    }

    const fieldValidationInstance = fieldValidation(validation);
    const validated = fieldValidationInstance.validate(value);
    const errorMessage = validated.error && !notValidate ? validated.error.message : '';
    dispatch(updateField({ path: errorPath, value: errorMessage }));
  };

  const handleFormSpecBtn = (event) => {
    if (btnFunctionality) {
      btnFunctionality(event, formStore);
    }
  };
  const onTableFieldDataChange = (event, key, isDisableForm) => {
    const { value } = event.target;
    if (value !== '') {
      dispatch(setIsEditForm(true));
    }
    dispatch(updateField({ path: [key], value: value }));
    if (isDisableForm === true) {
      dispatch(updateField({ path: [key], value: !value }));
    }
  };

  useEffect(() => {
    if (tableRowsData.length) {
      tableRowsData.map((data, index) => {
        tableColumnsData.map((ele) => {
          tableDataPath = [`${ele.key}-${index}`];
          const tableFieldsData = getFieldData({ path: tableDataPath, data: formStore });
          var selectElement = document.getElementById(`${ele.key}-${index}`);
          var selectedValue = selectElement?.value;
          dispatch(
            updateField({
              path: tableDataPath,
              value: data[ele.key] || tableFieldsData || selectedValue || ''
            })
          );
        });
      });
    } else {
      if (!restrictRerender) {
        dispatch(updateField({ path, value: defaultValue || fieldData || '' }));
      } else {
        console.log('running no change');
      }
    }
    if (validationsEditData && path[2] == 'Validation Btn') {
      dispatch(
        updateField({ path: [`validationRule-${path[1]}`], value: validationsEditData || '' })
      );
    }
    return () => {
      dispatch(setIsEditForm(false));
    };
  }, [JSON.stringify(form)]);

  return (
    <FieldComponentV3
      label={label}
      name={name}
      showSwitchName={showSwitchName}
      handleChange={onFieldDataChange}
      disabled={disabled}
      defaultValue={fieldData}
      value={fieldData}
      type={type}
      errorData={errorData}
      options={options}
      overloadOnchange={overloadOnchange}
      isFormDisabled={isFormDisabled}
      disableForm={disableForm}
      multiline={multiline}
      disableFullForm={disableFullForm}
      handleFormSpecBtn={handleFormSpecBtn}
      showTable={showTable}
      tableData={tableData}
      onTableFieldDataChange={onTableFieldDataChange}
      tableFieldsData={formStore}
      multilineRows={multilineRows}
      showThisField={showThisField}
      editor={editor}
      defaultLanguage={defaultLanguage}
      classname={classname}
      onBtnClick={onBtnClick}
      path={path}
    />
  );
};

export default FieldContainerV3;