import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import keycloak from '../../../../Keycloak';

import { useNavigate } from 'react-router-dom';

const pages = ['Login'];
const settings = ['Logout'];

function ResponsiveAppBar() {
  // eslint-disable-next-line no-unused-vars
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const getMenuItem = (setting) => {
    switch (setting) {
      case 'Account':
        return (
          !! keycloak.getIfAuthenticated() && (
            <MenuItem key={setting} onClick={handleCloseUserMenu}>
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          )
        );
      case 'Dashboard':
        return (
          !! keycloak.getIfAuthenticated() && (
            <MenuItem key={setting} onClick={handleCloseUserMenu}>
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          )
        );
      case 'Profile':
        return (
          !! keycloak.getIfAuthenticated() && (
            <MenuItem key={setting} onClick={handleCloseUserMenu}>
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          )
        );
      case 'Logout':
        return (
          !! keycloak.getIfAuthenticated() && (
            <MenuItem
              key={setting}
              onClick={() => {
                keycloak.logout();
                handleCloseNavMenu();
              }}>
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          )
        );
      case 'Login':
        return (
          ! keycloak.getIfAuthenticated() && (
            <MenuItem
              key={setting}
              onClick={() => {
                keycloak.login();
                handleCloseNavMenu();
              }}>
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          )
        );

      default:
        return null;
    }
  };

  return (
    <AppBar
      sx={{ flexGrow: '1', backgroundColor: 'rgb(25,118,210)', boxShadow: 'none', width: 'auto' }}
      position="static">
      <Container maxWidth="xl">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: 'flex',
              justifyContent: 'space-between',
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          />
          {! keycloak.getIfAuthenticated() ? (
            <Box sx={{ display: 'flex' }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => {
                    keycloak.login();
                    handleCloseNavMenu();
                  }}
                  sx={{
                    my: 2,
                    color: 'white',
                    display: 'block',
                    fontWeight: 'bold'
                  }}>
                  {page}
                </Button>
              ))}
            </Box>
          ) : null}

          { keycloak.getIfAuthenticated() ? (
            <Box sx={{ flexGrow: 0 }}>
              <Button
                onClick={() => {
                  window.open('/', '_self');
                }}
                variant="contained"
                color="warning"
                className="!mr-4">
                Home
              </Button>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                {settings.map((setting) => {
                  const menuItem = getMenuItem(setting);
                  return menuItem;
                })}
              </Menu>
            </Box>
          ) : null}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
