function onSideNavigationClick(componentData, navigate) {
    switch (componentData.value) {
      case 'canvas': {
        navigate('/canvas');
        break;
      }
      case 'showCanvas': {
        navigate('/canvas/showCanvas');
        break;
      }
      
    }
  }
function getCanvasNavigationSchema({canvas=false,showCanvas=false}={}){
    return [
        // {
        //   name: 'New Business Process',
        //   value: 'canvas',
        //   onClick: onSideNavigationClick,
        //   isSelected: canvas,
        //   isDisabled: false,
        //   imageUrl: 'https://img.icons8.com/ios-glyphs/100/group.png'
        // },
        {
          name: 'Business Process',
          value: 'showCanvas',
          onClick: onSideNavigationClick,
          isSelected: showCanvas,
          isDisabled: false,
          imageUrl: 'https://img.icons8.com/ios/100/new--v1.png'
        },
       
      ];
}
export default getCanvasNavigationSchema;