import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Features from '../component/Feature';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';

const ApplicationContainer = () => {
  const dispatch = useDispatch();

  const deleteFeatureClick = (data) => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.deleteFeatureModal,
        isOpen: true,
        modalDataKey: 'deleteFeatureModalData',
        modalData: data
      })
    );
  };
  return <Features deleteFeatureClick={deleteFeatureClick} />;
};

export default ApplicationContainer;
