import request from 'superagent';
import ReviewGrid from '../../component/object/ReviewGrid';
import keycloak from '../../../../Keycloak';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

function ReviewGridContainer({ setCurrentReviewState, reviewType }) {
  const { app_code } = useParams();
  const [reviewData, setReviewData] = useState([]);

  const fetchReviewData = async function () {
    let requestUrl = '';

    switch (reviewType) {
      case 'review': {
        requestUrl = `${process.env.REACT_APP_CONFIG_API}/data-objects/review`;
        break;
      }
      case 'reviewObjectMapping': {
        requestUrl = `${process.env.REACT_APP_CONFIG_API}/object-mapping/status`;
        break;
      }
    }

    const response = await request
      .get(requestUrl)
      .query({ appCode: app_code, flag: 'Review' })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    if (reviewType == 'review') {
      setReviewData(response.body.dataObjects);
    } else {
      setReviewData(response.body.objectMappingReviewResponseItem);
    }
  };

  return (
    <ReviewGrid
      reviewData={reviewData}
      fetchReviewData={fetchReviewData}
      reviewType={reviewType}
      setCurrentReviewState={setCurrentReviewState}
    />
  );
}

export default ReviewGridContainer;
