import { useState } from 'react';
import { noop } from 'lodash';
import React, { useEffect } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const DigitIdModal = ({ digitIdData = [], onCloseModal = noop }) => {
  console.log(digitIdData, '676767676767676');
  const [copiedIcons, setCopiedIcons] = useState({
    digitId: false,
    hash: false,
    txnId: false
  });

  const copyToClipboard = (text, field) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopiedIcons({ ...copiedIcons, [field]: true });
          setTimeout(() => {
            setCopiedIcons({ ...copiedIcons, [field]: false });
          }, 2000);
        })
        .catch((err) => {
          console.error('Error copying to clipboard: ', err);
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);

      setCopiedIcons({ ...copiedIcons, [field]: true });
      setTimeout(() => {
        setCopiedIcons({ ...copiedIcons, [field]: false });
      }, 2000);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout();
    };
  }, []);

  if (!digitIdData) {
    onCloseModal()
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-x-hidden overflow-y-auto">
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div className="relative z-50 bg-white shadow-lg rounded-lg border-4 border-blue-500 p-6">
        <div className="flex justify-end">
          <button onClick={onCloseModal}>
            <HighlightOffIcon className="!text-3xl !w-8 !h-8" />
          </button>
        </div>
        <div className="flex flex-col p-4 space-y-4">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <p className="font-bold text-xl  text-blue-500">DIGIT ID</p>
              <p>
                {digitIdData?.digitId}
                <div className="float-right ml-4">
                  <button
                    onClick={() => copyToClipboard(digitIdData?.digitId, 'digitId')}
                    disabled={copiedIcons.digitId}>
                    {copiedIcons.digitId ? 'Copied' : <ContentCopyIcon />}
                  </button>
                </div>
              </p>
            </div>
            <div>
              <p className="font-bold  text-blue-500">HASH</p>
              <p>
                {digitIdData?.hash}
                <div className="float-right ml-4">
                  <button
                    onClick={() => copyToClipboard(digitIdData?.hash, 'hash')}
                    disabled={copiedIcons.hash}>
                    {copiedIcons.hash ? 'Copied' : <ContentCopyIcon />}
                  </button>
                </div>
              </p>
            </div>
            <div>
              <p className="font-bold  text-blue-500">Transaction Id</p>
              <p>
                {digitIdData?.txnId}
                <div className="float-right ml-4">
                  <button
                    onClick={() => copyToClipboard(digitIdData?.txnId, 'txnId')}
                    disabled={copiedIcons.txnId}>
                    {copiedIcons.txnId ? 'Copied' : <ContentCopyIcon />}
                  </button>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitIdModal;
